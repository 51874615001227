import React, { forwardRef } from 'react';

import { withFormContext } from '../../context/FormContext';
import Autocomplete from './Autocomplete';

const Entity = forwardRef(
  (
    {
      form,
      labelledBy,
      identifier,
      type,
      value,
      required,
      errors,
      onChange,
      onBlur,
    },
    ref,
  ) => (
    <div className="col-12 col-lg-6 mt-15">
      <Autocomplete
        ref={ref}
        labelledby={labelledBy ?? identifier}
        errors={errors}
        value={value ?? ''}
        onChange={(e, newValue) =>
          onChange({ target: { value: newValue, name: identifier, type } })
        }
        onBlur={(e) => {
          const newValue = e.target.value;
          if (newValue) {
            form.entities.setEntity(identifier, newValue);
          }
          const event = {
            target: { value: e.target.value, name: identifier, type },
          };

          onChange(event);
          onBlur(event);
        }}
        options={Object.values(form.entities.entities).map(
          (entity) => entity.name,
        )}
        required={required || false}
      />
    </div>
  ),
);

export default withFormContext(Entity);
