import { createGlobalStyle } from 'styled-components/macro';

import { TypographyStyles } from './Typography';

export const GlobalStyle = createGlobalStyle`
    @import url('${({ theme }) => theme.primaryFont?.url || null}');
    @import url('${({ theme }) => theme.secondaryFont?.url || null}');

    body {
      ${TypographyStyles.FontFamily}
    }

    .rally-typography {
      ${TypographyStyles.Color}
    }

    .rally-typography-h1 {
      ${TypographyStyles.H1}
    }

    .rally-typography-h2 {
      ${TypographyStyles.H2}
    }

    .rally-typography-h3 {
      ${TypographyStyles.H3}
    }

    .rally-typography-h4 {
      ${TypographyStyles.H4}
    }

    .rally-typography-h5 {
      ${TypographyStyles.H5}
    }

    .rally-typography-h6 {
      ${TypographyStyles.H6}
    }

    .rally-typography-p {
      ${TypographyStyles.P}
    }
  `;
