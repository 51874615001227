import { gql, useMutation, useQuery } from '@apollo/client';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import equal from 'fast-deep-equal/es6';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';
import { handleException } from 'utils/ErrorUtils';

import { FormContextProvider } from '../../../../context/FormContext';
import OrgManager from '../../../../services/OrgManager';
import { userIsClient, userIsRallyAdmin } from '../../../../services/Utilities';
import TemplateGeneratedField from '../../../contracts/TemplateGeneratedField';
import BottomBar from '../../../forms/BottomBar';
import Button from '../../../standard/Button';
import ClipboardImage from '../../../standard/ClipboardImage';
import { DeleteCustomFieldDialog } from '../../DeleteCustomFieldDialog';
import DataContainer from '../components/DataContainer';
import DataContent from '../components/DataContent';
import DataHeader from '../components/DataHeader';

const FieldContainer = styled.div`
  position: relative;
`;

const TrashIcon = styled(Button)`
  &&& {
    position: absolute;
    right: 0;
    top: 0;
  }
`;

const FIRM_CUSTOM_FIELD_VALUES = gql`
  fragment FirmCustomFieldValues on Organization {
    _id
    firmCustomFieldValues(firm: $firmId) {
      fieldSpec {
        _id
        name
        type
        helper
      }
      value
    }
  }
`;

const FIRM_GET_ORG_CUSTOM_FIELDS = gql`
  query firmGetOrgCustomFields(
    $userId: String!
    $clientId: MongoID!
    $firmId: MongoID!
  ) {
    firmUser(id: $userId) {
      id
      firm(id: $firmId) {
        _id
        client(org: $clientId) {
          ...FirmCustomFieldValues
        }
      }
    }
  }

  ${FIRM_CUSTOM_FIELD_VALUES}
`;

const CLIENT_GET_ORG_CUSTOM_FIELDS = gql`
  query clientGetOrgCustomFields($userId: MongoID!, $firmId: MongoID!) {
    client(_id: $userId) {
      _id
      organizationDetails {
        ...FirmCustomFieldValues
      }
    }
  }

  ${FIRM_CUSTOM_FIELD_VALUES}
`;

const DELETE_CUSTOM_FIELD = gql`
  mutation deleteDataField($dataFieldId: MongoID!) {
    deleteDataField(dataFieldId: $dataFieldId) {
      recordId
      record {
        _id
        name
      }
    }
  }
`;

const updateValueReducer = (values, { type, fieldId, fieldValue }) => {
  switch (type) {
    case 'update':
      return {
        ...values,
        [fieldId]: fieldValue,
      };
    case 'resetValue': {
      const { [fieldId]: fieldToReset, ...remainingValues } = values;
      return remainingValues;
    }
    case 'reset':
      return {};
    default:
      console.error('unknown update value event', { type });
      return values;
  }
};

function sanitizeDataField(dataFieldValue) {
  function removeContactFields(_key, value) {
    return value?._contactId ? { _contactId: value._contactId } : value;
  }
  return JSON.parse(JSON.stringify(dataFieldValue), removeContactFields);
}

function areDataFieldsEqual(dataFieldValue1, dataFieldValue2) {
  return equal(
    sanitizeDataField(dataFieldValue1),
    sanitizeDataField(dataFieldValue2),
  );
}

export default function DataFields() {
  const { clientId } = useParams();
  const userId = localStorage.getItem('userId');
  const firmId = localStorage.getItem('firmId');

  const [isSaving, setIsSaving] = useState(false);
  const [orgManager, setOrgManager] = useState(
    clientId ? new OrgManager({ _id: clientId }) : null,
  );
  const [orgId, setOrgId] = useState(clientId);
  const [dataFieldSpec, setDataFieldSpec] = useState();
  const [initialValues, setInitialValues] = useState({});
  const [newValues, updateValues] = useReducer(updateValueReducer, {});
  const [showDeleteCustomFieldDialog, setShowDeleteCustomFieldDialog] =
    useState(false);
  const [customFieldToDelete, setCustomFieldToDelete] = useState();

  const { refetch, loading: getCustomFieldsLoading } = useQuery(
    userIsClient() ? CLIENT_GET_ORG_CUSTOM_FIELDS : FIRM_GET_ORG_CUSTOM_FIELDS,
    {
      fetchPolicy: 'cache-and-network',
      variables: userIsClient()
        ? {
            userId: userId.replace(/^.*\|/, ''),
            firmId,
          }
        : { userId, firmId, clientId },
      onCompleted: (data) => {
        const org = userIsClient()
          ? data?.client?.organizationDetails?.[0]
          : data?.firmUser?.firm?.client;

        const customFields = org?.firmCustomFieldValues || [];

        setOrgId(org?._id);

        setDataFieldSpec(customFields.map(({ fieldSpec }) => fieldSpec));
        setInitialValues(
          Object.fromEntries(
            customFields.map(({ fieldSpec, value }) => [fieldSpec._id, value]),
          ),
        );
        updateValues({ type: 'reset' });
      },
    },
  );

  useEffect(() => {
    setOrgManager(orgId ? new OrgManager({ _id: orgId }) : null);
  }, [orgId]);

  const updateValueFromEvent = useCallback(
    (e) => {
      const { name: fieldId, value: fieldValue } = e.target;
      const initialValue = initialValues[fieldId];
      if (
        (!fieldValue && !initialValues[fieldId]) ||
        areDataFieldsEqual(fieldValue, initialValue)
      ) {
        updateValues({ type: 'resetValue', fieldId });
        setInitialValues({
          ...initialValues,
          [fieldId]: fieldValue,
        });
      } else {
        updateValues({ type: 'update', fieldId, fieldValue });
      }
    },
    [updateValues, initialValues],
  );

  const saveDataFieldValues = async () => {
    setIsSaving(true);
    try {
      const values = Object.entries(newValues).map(([id, value]) => ({
        dataField: id,
        value,
      }));
      await axios.put(`/api/v1/datafieldvalues/${orgId}/batch`, {
        values,
      });
      await refetch();
      toast.success('Client information saved');
    } catch (err) {
      handleException(err);
      toast.error('Failed to save client information');
    } finally {
      setIsSaving(false);
    }
  };

  const hasChanges = !!Object.keys(newValues).length;

  const [deleteDataField, { loading: deleteCustomFieldLoading }] = useMutation(
    DELETE_CUSTOM_FIELD,
    {
      onCompleted: async () => {
        setShowDeleteCustomFieldDialog(false);
        setCustomFieldToDelete(undefined);
        await refetch();
        toast.success('Custom field deleted');
      },
      onError: (error) => {
        toast.error(
          'Oops! Something went wrong deleting the custom field. Please try again later.',
        );
        handleException(error);
      },
    },
  );

  return (
    <>
      <DataContainer>
        <DataHeader title="Custom Fields" />
        <DataContent
          loading={
            getCustomFieldsLoading || deleteCustomFieldLoading || !dataFieldSpec
          }
          isDirty={hasChanges}
          fullWidth
        >
          {!!dataFieldSpec?.length && (
            <FormContextProvider org={orgManager} autoUpdateContacts>
              {dataFieldSpec.map((dataField) => (
                <FieldContainer key={dataField._id}>
                  <TemplateGeneratedField
                    key={dataField._id}
                    identifier={dataField._id}
                    elementId={dataField._id}
                    type={dataField.type}
                    name={dataField.name}
                    helper={dataField.helper}
                    value={
                      newValues[dataField._id] ?? initialValues[dataField._id]
                    }
                    errors={[]}
                    onChange={updateValueFromEvent}
                    onBlur={updateValueFromEvent}
                    required={false}
                    hidden={false}
                    marginBottom="4em"
                  />
                  {userIsRallyAdmin() && (
                    <TrashIcon
                      data-testid={`${dataField._id}-delete-df-button`}
                      type="button"
                      onClick={() => {
                        setCustomFieldToDelete(dataField._id);
                        setShowDeleteCustomFieldDialog(true);
                      }}
                      className="btn btn-pure btn-danger"
                    >
                      <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                    </TrashIcon>
                  )}
                </FieldContainer>
              ))}
            </FormContextProvider>
          )}
          {dataFieldSpec && !dataFieldSpec.length && (
            <div className="offset-xl-3 col-xl-6 mt-60">
              <div className="card-body align-items-center text-center">
                <ClipboardImage />
                <h5 className="mb-15">
                  You have not created any Custom Fields. If you are interested
                  in learning more about this feature, get in touch!
                </h5>
                <Button className="d-inline-block intercom-launcher">
                  Get in touch
                </Button>
              </div>
            </div>
          )}
        </DataContent>
        <BottomBar
          nextButton={{
            copy: 'Save',
            props: {
              onClick: saveDataFieldValues,
              disabled: isSaving || getCustomFieldsLoading || !hasChanges,
            },
          }}
        />
        <DeleteCustomFieldDialog
          open={showDeleteCustomFieldDialog}
          onClose={() => {
            setShowDeleteCustomFieldDialog(false);
            setCustomFieldToDelete(undefined);
          }}
          onConfirm={() => {
            deleteDataField({
              variables: { dataFieldId: customFieldToDelete },
            });
          }}
        />
      </DataContainer>
    </>
  );
}
