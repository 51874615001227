import axios from 'axios';
import React, { forwardRef, memo, useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { DashboardCard } from '../dashboard/DashboardStyles';
import SmallExplorer from '../filerepo/SmallExplorer';
import { ContentUploader } from '../proxy-box';
import Spinner from '../standard/Spinner';

const ErrorMessage = styled.span`
  font-size: 16px;
  padding: 15px;
`;

const StyledCard = styled(DashboardCard)`
  margin-top: 15px;
  padding: 0;
`;

const StyledUploader = styled(ContentUploader)`
  &&& {
    .bcu-footer-left > .btn {
      display: none;
    }
    .bcu-footer {
      background: white;
    }
    .btn-group > .btn.btn-primary:last-of-type {
      border-right-color: ${(props) => props.theme.palette.primary.main};
    }
    .btn {
      background-color: transparent;
      color: ${(props) => props.theme.palette.primary.main};
      font-weight: 400;
      font-size: 14px;
      padding: 5px 16px;
      line-height: inherit;
      border-radius: 2px;
      outline: none !important;
      transition: 0.15s linear;

      width: ${(props) => props.width || 'auto'};
      text-align: ${(props) => props.textAlign || 'center'};
      white-space: nowrap;
      border: 1px solid transparent;
      margin-top: ${(props) => props.marginTop};

      &:hover {
        background-color: transparent;
        border-color: transparent;
        cursor: pointer;
        color: ${(props) => props.theme.palette.primary.dark};
      }

      &:active {
        background-color: transparent;
        border-color: transparent;
        color: ${(props) => props.theme.palette.primary.dark};
      }

      &:focus {
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
        color: ${(props) => props.theme.palette.primary.dark};
      }

      &:disabled {
        background-color: transparent;
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
    .btn.btn-primary {
      background-color: ${(props) => props.theme.palette.primary.main};
      border-color: ${(props) => props.theme.palette.primary.main};
      color: ${(props) => props.theme.palette.primary.contrastText};
    }
    .btn.btn-primary:hover {
      background-color: ${(props) => props.theme.palette.primary.light};
      border-color: ${(props) => props.theme.palette.primary.light};
      border-right-color: ${(props) => props.theme.palette.primary.light};
      color: ${(props) => props.theme.palette.primary.contrastText};
    }
    .bcu-upload-state {
      .fill-color {
        fill: ${(props) => props.theme.palette.primary.main};
      }
      .stroke-color {
        stroke: ${(props) => props.theme.palette.primary.main};
      }
    }
    .be-input-link {
      color: ${(props) => props.theme.palette.primary.main};
    }
    && .bcu-progress {
      background: ${(props) => props.theme.palette.primary.main};
    }
    && .crawler div {
      background-color: ${(props) => props.theme.palette.primary.main};
    }
  }
`;

const queryFolderStatus = async (contractId, elementId, setFolderStatus) => {
  const { data } = await axios.get(
    `/api/v1/templatedContracts/${contractId}/fileuploadstatus/${elementId}`,
  );

  setFolderStatus(data);
  return data;
};

const FileUpload = (
  {
    contractId,
    elementId,
    identifier,
    value,
    onChange = (f) => f,
    onBlur = (f) => f,
  },
  ref,
) => {
  const [{ folderId, accessToken, isEmpty }, setFolderStatus] = useState({});

  useEffect(() => {
    if (contractId) {
      queryFolderStatus(contractId, elementId, setFolderStatus);
    }
  }, [contractId, elementId, setFolderStatus]);

  useEffect(() => {
    const newValue = !isEmpty;
    if (isEmpty != null) {
      const event = {
        target: {
          name: identifier,
          value: newValue,
        },
      };

      onChange(event);

      // value is undefined on first load. Don't blur (validate) until the
      // element is touched and the value is changed.
      if (value != null && value !== newValue) {
        onBlur(event);
      }
    }
  }, [identifier, isEmpty, value, onChange, onBlur]);

  if (!contractId || !elementId) {
    return (
      <StyledCard ref={ref}>
        <ErrorMessage>
          Questionnaire file uploads can only be initiated by a client. Firm
          users may upload files to the matter workspace after the questionnaire
          is submitted.
        </ErrorMessage>
      </StyledCard>
    );
  }

  if (!accessToken) {
    return (
      <>
        <div ref={ref} />
        <Spinner />
      </>
    );
  }

  return isEmpty ? (
    <StyledCard ref={ref}>
      <StyledUploader
        aria-labelledby={identifier}
        token={accessToken}
        rootFolderId={folderId}
        onComplete={() => {
          queryFolderStatus(contractId, elementId, setFolderStatus);
        }}
      />
    </StyledCard>
  ) : (
    <StyledCard ref={ref}>
      <SmallExplorer
        aria-labelledby={identifier}
        token={accessToken}
        rootFolderId={folderId}
        showHeader={false}
        onUpload={() =>
          queryFolderStatus(contractId, elementId, setFolderStatus)
        }
        onDelete={() =>
          queryFolderStatus(contractId, elementId, setFolderStatus)
        }
      />
    </StyledCard>
  );
};

export default memo(forwardRef(FileUpload));
