import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useUser } from '../context/UserContext';
import { getStrippedUserId, isE2ETest } from '../services/Utilities';
import CommandBarAnalytics from './commandbar/CommandBarAnalytics';
import CommandBarCategoryRecords from './commandbar/CommandBarCategoryRecords';
import CommandBarClientRecords from './commandbar/CommandBarClientRecords';
import CommandBarContactRecords from './commandbar/CommandBarContactRecords';
import CommandBarMatterRecords from './commandbar/CommandBarMatterRecords';
import CommandBarNewMatterArguments from './commandbar/CommandBarNewMatterArguments';
import CommandBarTemplateRecords from './commandbar/CommandBarTemplateRecords';
import CommandBarTheme from './commandbar/CommandBarTheme';

export default () => {
  const history = useHistory();
  const enableCommandBar = !isE2ETest();
  const [user] = useUser();

  useEffect(() => {
    const userId = getStrippedUserId();
    if (enableCommandBar && userId) {
      window.CommandBar.boot(userId);

      const { userType, email, organization, firm } = user;
      window.CommandBar.addMetadata('userId', userId);
      window.CommandBar.addMetadata('userType', userType);
      window.CommandBar.addMetadata('email', email);
      window.CommandBar.addMetadata('organizationId', organization?._id);
      window.CommandBar.addMetadata('organizationName', organization?.name);
      window.CommandBar.addMetadata('firmId', firm?._id);
      window.CommandBar.addMetadata('firmName', firm?.name);

      window.CommandBar.generateDetailPreview((data) => data?.preview);
    } else {
      window.CommandBar.shutdown();
    }
    return () => window.CommandBar.shutdown();
  }, [user, enableCommandBar]);

  useEffect(() => {
    const routerFunc = (newUrl) => history.push(newUrl);
    window.CommandBar.addRouter(routerFunc);
  }, [history]);

  if (!enableCommandBar) return null;
  return (
    <>
      <CommandBarAnalytics />
      <CommandBarTheme />
      <CommandBarClientRecords />
      <CommandBarContactRecords />
      <CommandBarMatterRecords />
      <CommandBarTemplateRecords />
      <CommandBarCategoryRecords />
      <CommandBarNewMatterArguments />
    </>
  );
};
