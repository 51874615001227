import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { handleException } from 'utils/ErrorUtils';

import Spinner from '../standard/Spinner';

const HelloSignRedirect = () => {
  const { search } = useLocation();

  useEffect(() => {
    const { state } = queryString.parse(search);
    const jsonState = atob(state);

    try {
      const { callback } = JSON.parse(jsonState);
      window.location.replace(callback + search);
    } catch (err) {
      handleException(err);
      const { protocol, hostname } = window.location;
      window.location.replace(`${protocol}//${hostname}`);
    }
  }, [search]);

  return <Spinner />;
};

export default HelloSignRedirect;
