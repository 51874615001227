import { faEdit } from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { userIsClient } from '../../../../services/Utilities';
import { PopperMenuItem } from '../../../standard/PopperMenu';

function EditQuestionnaireAction() {
  const { url } = useRouteMatch();
  const history = useHistory();

  return (
    <PopperMenuItem
      title="Edit Questionnaire"
      icon={faEdit}
      onClick={() => {
        history.push(`${url}/${userIsClient() ? `questionnaire` : `edit`}`);
      }}
    />
  );
}

export default EditQuestionnaireAction;
