import { Popper } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';

import { trackCloseMatter } from '../../../services/Analytics';
import { MatterState } from '../../../utils/RallyPropTypes';
import Button from '../../standard/Button';
import MatterStateBadge from './MatterStateBadge';

const CHANGEABLE_STATES = [
  'open',
  'clientReview',
  'closed',
  'finalized',
  'finalizedFirmOnly',
];
const OPEN_STATES = ['open', 'clientReview'];

const StyledChangeStatusMenu = styled.div`
  background: ${(props) => props.theme.palette.brand.primary_white};
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.09);
  min-width: 153px;
  margin-left: 8px;
`;

const ChangeStatusLabel = styled.div`
  color: ${(props) => props.theme.palette.shade.gray_3};
  margin-bottom: 4px;
`;

const ChangeStatusButton = styled(Button)`
  font-weight: inherit;
  font-size: inherit;
`;

const ChangeStatusMenu = ({ state, setOpen, toggleMatterState }) => {
  const isClosing = OPEN_STATES.includes(state);
  return (
    <StyledChangeStatusMenu>
      <ChangeStatusLabel>Change status</ChangeStatusLabel>
      <ChangeStatusButton
        pure
        onClick={(event) => {
          event.stopPropagation();
          setOpen(false);
          if (isClosing) {
            trackCloseMatter();
          }
          toggleMatterState();
        }}
      >
        <MatterStateBadge state={isClosing ? 'closed' : 'open'} />
      </ChangeStatusButton>
    </StyledChangeStatusMenu>
  );
};

const StyledBadgeContainer = styled.div`
  padding: 8px 48px 8px 8px;
  border: 0.25px solid transparent;
  :hover {
    cursor: pointer;
    border: 0.25px solid ${(props) => props.theme.palette.shade.gray_4};
    border-radius: 5px;
  }
`;

const MatterStateSelector = ({ state, toggleMatterState }) => {
  const [open, setOpen] = useState(false);
  const badgeRef = useRef();

  if (!CHANGEABLE_STATES.includes(state)) {
    return <MatterStateBadge state={state} />;
  }
  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpen(false);
      }}
    >
      <StyledBadgeContainer
        onClick={() => {
          setOpen(true);
        }}
      >
        <div ref={badgeRef}>
          <MatterStateBadge state={state} />
        </div>
        {open && (
          <Popper open anchorEl={badgeRef.current} placement="right">
            <ChangeStatusMenu
              state={state}
              setOpen={setOpen}
              toggleMatterState={toggleMatterState}
            />
          </Popper>
        )}
      </StyledBadgeContainer>
    </ClickAwayListener>
  );
};

MatterStateSelector.propTypes = {
  state: MatterState,
  toggleMatterState: PropTypes.func,
};

export default MatterStateSelector;
