import { gql, useQuery } from '@apollo/client';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { handleException } from 'utils/ErrorUtils';

import Spinner from '../components/standard/Spinner';
import { useBroadcastChannel } from '../hooks/useBroadcastChannel';
import AuthUtility from '../services/AuthUtility';
import { stripTypenames, userIsClient } from '../services/Utilities';

const DEFAULT_FIRM = {
  settings: {},
};

const UNAUTHENTICATED_USER = {
  firm: DEFAULT_FIRM,
};

const UserContext = createContext({
  user: UNAUTHENTICATED_USER,
  refreshUser: () => {},
});

const FIRM_DATA = `
        _id
        name
        settings {
          payBeforeService
          defaultOrgTeamAccessPolicy
          showClientCustomFields
          allowClientEditMatterName
          showEntityInformation
          showCategories
        }
`;

export const GET_FIRM_USER_DATA = gql`
  query getFirmUserData($userId: String!) {
    firmUser(id: $userId) {
      id
      firms {
        ${FIRM_DATA}
      }
    }
    allFlags {
      id
      allFlags
    }
  }
`;

export const GET_CLIENT_USER_DATA = gql`
  query getClientUserData($userId: MongoID!) {
    client(_id: $userId) {
      _id
      organizationDetails {
        _id
        name
        clientFirms {
          ${FIRM_DATA}
        }
        subscriptions {
          _id
          product
          status
        }
        stripe {
          region
        }
      }
    }
    allFlags {
      id
      allFlags
    }
  }
`;

export function UserContextProvider({ children }) {
  const userId = localStorage.getItem('userId');
  const userType = localStorage.getItem('userType');
  const email = localStorage.getItem('email');

  const [isAuthenticated, setIsAuthenticated] = useState(
    AuthUtility.isAuthenticated(),
  );
  const [user, setUser] = useBroadcastChannel('user-context-user');

  useEffect(() => {
    const refreshUser = () => {
      if (AuthUtility.isAuthenticated()) {
        if (!isAuthenticated) {
          setIsAuthenticated(true);
        }
      } else if (isAuthenticated || !user) {
        setIsAuthenticated(false);
        setUser(UNAUTHENTICATED_USER);
      }
    };
    refreshUser();
    const intervalId = setInterval(refreshUser, 1000);
    return () => clearInterval(intervalId);
  }, [isAuthenticated, setUser, user]);

  const clientQueryResult = useQuery(GET_CLIENT_USER_DATA, {
    variables: { userId: userId?.replace(/^.*\|/, '') },
    queryOptions: {
      fetchPolicy: 'cache-and-network',
    },
    onCompleted: (data) => {
      // TODO: Remove these items from localStorage
      const strippedData = stripTypenames(data);

      const organization = strippedData?.client?.organizationDetails?.[0] ?? {};
      localStorage.setItem('orgId', organization._id);
      localStorage.setItem('orgName', organization.name);

      const firm = organization.clientFirms?.[0] ?? DEFAULT_FIRM;
      localStorage.setItem('firmId', firm._id);
      localStorage.setItem('firmName', firm.name);

      const initialFlags = strippedData?.allFlags.allFlags;

      setUser({ userId, userType, email, firm, organization, initialFlags });
    },
    onError: (error) => handleException(error),
    skip: !isAuthenticated || !userIsClient(),
  });

  const firmQueryResult = useQuery(GET_FIRM_USER_DATA, {
    variables: { userId },
    queryOptions: {
      fetchPolicy: 'cache-first',
    },
    onCompleted: (data) => {
      // TODO: Remove these items from localStorage
      const strippedData = stripTypenames(data);

      const firm = strippedData?.firmUser?.firms?.[0] ?? DEFAULT_FIRM;
      localStorage.setItem('firmId', firm._id);
      localStorage.setItem('firmName', firm.name);

      const initialFlags = strippedData?.allFlags.allFlags;

      setUser({ userId, userType, email, firm, initialFlags });
    },
    onError: (error) => handleException(error),
    skip: !isAuthenticated || userIsClient(),
  });

  if (!user) {
    return <Spinner />;
  }

  let queryResult = {};
  if (isAuthenticated) {
    queryResult = userIsClient() ? clientQueryResult : firmQueryResult;
  }

  return (
    <UserContext.Provider value={{ user, queryResult }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  const { user, queryResult } = useContext(UserContext);
  return [user, queryResult];
}

export function useFirmSettings() {
  const [{ firm: { settings } = DEFAULT_FIRM }, queryResult] = useUser();
  return [settings, queryResult];
}
