import { useFeatureToggle } from '@flopflip/react-broadcast';
import React from 'react';

import { useFirmSettings } from '../../../context/UserContext';
import { flagNames } from '../../../featureFlags';
import { Categories } from '../../client/categories/CategoryList';
import Header from '../../standard/Header';
import { MatterContacts } from './MatterContacts';
import MatterStateBadge from './MatterStateBadge';
import MatterStateSelector from './MatterStateSelector';
import ReviewersSubheaderRow from './ReviewersSubheaderRow';
import { SignatureStatusSubheaderRow } from './SignatureStatusSubheaderRow';
import {
  StateCell,
  SubheaderCell,
  SubheaderRow,
  SubheaderWrapper,
  TagsCell,
} from './SubheaderStyles';

export default function ClientMatterViewHeader({
  matter,
  actions,
  allowMatterManagement,
  toggleMatterState,
  updateMatterName,
  addMatterCategory,
  removeMatterCategory,
  addMatterContact,
  removeMatterContact,
  setDisplaySendForSigning = () => {},
  setDisplayAddReviewersModal = () => {},
  onSignatureReminder,
  onCancelSignatureRequest,
}) {
  const {
    organization,
    name,
    legacyState,
    categories,
    contacts,
    signatureRequests,
    reviewRequests,
  } = matter;

  const [{ showCategories }] = useFirmSettings();

  const enableSignatureStatus = useFeatureToggle(
    flagNames.ENABLE_SIGNATURE_STATUS,
  );
  const enableMatterReviews = useFeatureToggle(flagNames.MATTER_REVIEWS);

  return (
    <>
      <Header
        title={name}
        updateTitle={updateMatterName}
        isEditable={!!updateMatterName}
        right={actions}
        rightSideAlign="top"
      >
        {categories && showCategories && (
          <SubheaderWrapper>
            <SubheaderRow>
              <SubheaderCell>Categories:</SubheaderCell>
              <TagsCell>
                <Categories
                  categories={categories}
                  addCategory={addMatterCategory}
                  removeCategory={removeMatterCategory}
                />
              </TagsCell>
            </SubheaderRow>
          </SubheaderWrapper>
        )}
        <SubheaderWrapper>
          <SubheaderRow>
            {organization && (
              <>
                <SubheaderCell>Associated Contacts:</SubheaderCell>
                <TagsCell>
                  <MatterContacts
                    contacts={contacts}
                    organization={organization}
                    addMatterContact={addMatterContact}
                    removeMatterContact={removeMatterContact}
                  />
                </TagsCell>
              </>
            )}
          </SubheaderRow>
        </SubheaderWrapper>
        {!!legacyState && (
          <SubheaderWrapper>
            <SubheaderRow>
              <SubheaderCell>Status:</SubheaderCell>
              <StateCell>
                {!!allowMatterManagement && !!toggleMatterState ? (
                  <MatterStateSelector
                    state={legacyState}
                    toggleMatterState={toggleMatterState}
                  />
                ) : (
                  <MatterStateBadge state={legacyState} />
                )}
              </StateCell>
            </SubheaderRow>
          </SubheaderWrapper>
        )}
        {enableSignatureStatus && (
          <SignatureStatusSubheaderRow
            signatureRequests={signatureRequests}
            setDisplaySendForSigning={setDisplaySendForSigning}
            onSignatureReminder={onSignatureReminder}
            onCancelSignatureRequest={onCancelSignatureRequest}
          />
        )}
        {enableMatterReviews && (
          <ReviewersSubheaderRow
            reviewRequests={reviewRequests}
            setDisplayAddReviewersModal={setDisplayAddReviewersModal}
          />
        )}
      </Header>
    </>
  );
}
