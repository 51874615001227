import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import styled from 'styled-components/macro';

import MenuOfServicesCard from '../MenuOfServicesCard';
import AddServiceCard from './AddServiceCard';

const ServiceListContainer = styled.div`
  margin-top: 5em;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  grid-gap: 3em 1em;
  @media (max-width: 1460px) {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }
  @media (max-width: 1265px) {
    grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
    grid-gap: 1em;
  }
  justify-items: center;
  margin-bottom: 5em;
  grid-auto-rows: 1fr;
`;

const SortableMenuOfServicesCard = SortableElement(MenuOfServicesCard);
const SortableAddServiceCard = SortableElement(AddServiceCard);

const ServiceList = ({
  category = {},
  errors = {},
  onUpdate = (f) => f,
  onAddServiceClick = (f) => f,
}) => {
  const { categoryId, templates = [] } = category;

  return (
    <ServiceListContainer>
      {templates.map((template, index) => {
        const {
          _id: templateId,
          name,
          description,
          price,
          displayPrice,
          status: [status],
          version: { id: templateVersionId },
        } = template;

        // Can't use `.filter()` because we need to make sure index is correct
        // so that ordering works.
        if (status !== 'active') {
          return null;
        }

        return (
          <SortableMenuOfServicesCard
            key={templateId}
            id={templateId}
            index={index}
            name={name}
            description={description}
            price={price}
            displayPrice={displayPrice}
            onDelete={() => {
              const newCategory = {
                ...category,
                templateVersions: category.templateVersions.filter(
                  ({ versionId }) => versionId !== templateVersionId,
                ),
                templates: templates.filter(({ _id }) => _id !== templateId),
              };

              onUpdate(newCategory);
            }}
            isEditing
            isDraggable
          />
        );
      })}
      <SortableAddServiceCard
        index={templates.length}
        onClick={() => onAddServiceClick(categoryId)}
        errorMessage={!templates.length && errors.templateVersions}
        disabled
      />
    </ServiceListContainer>
  );
};

export default SortableContainer(ServiceList);
