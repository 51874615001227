import React, { useCallback, useContext, useEffect, useState } from 'react';
import { components } from 'react-select';
import styled, { ThemeContext } from 'styled-components/macro';

import { TagSelect } from '../../standard/TagSelect';
import { useCategories } from './hooks';

const StyledOption = styled(components.Option)`
  &&.Select__option {
    padding-left: ${({ value: { parent } }) =>
      parent.name === 'root' ? '' : '2.5rem'};
    cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
    color: ${({ isDisabled, appTheme }) =>
      isDisabled ? appTheme.palette.text.disabled : appTheme.palette.gray.dark};
  }
`;

function getCategoryOption(category) {
  return {
    label: category.name,
    value: category,
  };
}

function Option(props) {
  const theme = useContext(ThemeContext);
  const { value } = props;

  return (
    <StyledOption key={value.id} value={value} appTheme={theme} {...props}>
      {value.name}
    </StyledOption>
  );
}

export function Categories({
  categories,
  addCategory,
  removeCategory,
  isDisabled = false,
}) {
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [fetchedCategories] = useCategories({
    isDisabled,
    flatten: true,
  });

  useEffect(() => {
    setCategoryOptions(
      fetchedCategories?.map((category) => ({
        _id: category.id,
        name: category.name,
        parent: category.parent
          ? { _id: category.parent.id, name: category.parent.name }
          : {},
      })) ?? [],
    );
  }, [fetchedCategories]);

  const onChange = useCallback(
    async (_categories, event) => {
      switch (event.action) {
        case 'pop-value':
        case 'remove-value': {
          const { removedValue } = event;
          await removeCategory(removedValue.value);
          break;
        }
        case 'select-option': {
          const { option } = event;
          await addCategory(option.value);
          break;
        }
        default: {
          break;
        }
      }
    },
    [addCategory, removeCategory],
  );

  const customFilter = (option, searchText) =>
    option.value.name.toLowerCase().includes(searchText.toLowerCase()) ||
    option.value.parent.name.toLowerCase().match(searchText.toLowerCase());

  return (
    <TagSelect
      components={{
        Option,
      }}
      name=""
      isMulti
      isDisabled={isDisabled || !categoryOptions}
      isClearable={false}
      value={categories?.map(getCategoryOption)}
      onChange={onChange}
      isValidNewOption={() => false}
      backgroundColor="white"
      borderColor="#ebebeb"
      isOptionDisabled={(option) =>
        option.value.name === 'Minute Book' ||
        categories.some((category) => category._id === option.value._id)
      }
      options={categoryOptions
        ?.filter(
          ({ _id: optionId }) =>
            !categories?.some(({ _id: categoryId }) => categoryId === optionId),
        )
        .map(getCategoryOption)}
      filterOption={customFilter}
      placeholder="+ Add Category"
    />
  );
}
