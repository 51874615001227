import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dinero from 'dinero.js';
import React from 'react';
import styled, { css } from 'styled-components/macro';

import { DashboardCard } from '../dashboard/DashboardStyles';

export const ClickableCard = styled(DashboardCard)`
  display: grid;
  grid-template-columns: 0.25fr 9.5fr 0.25fr;
  grid-template-rows: 2fr 6fr 2fr;

  width: 370px;
  height: 19rem;
  ${(props) => !!props.onClick && 'cursor: pointer;'}
  border-top: 10px solid transparent;
  padding-top: 26px;
  padding-bottom: 12px;
  @media (max-width: 1460px) {
    width: 320px;
  }
  @media (max-width: 1265px) {
    width: 370px;
  }
  @media (max-width: 425px) {
    width: 100%;
    max-height: 23em;
  }

  ${(props) =>
    !!props.isDraggable &&
    css`
      pointer-events: auto !important;
    `}

  :hover {
    ${(props) =>
      props.isDraggable
        ? css`
            cursor: grab;
          `
        : css`
            cursor: pointer;
          `}
    ${(props) =>
      !!props.onClick &&
      `border-top: 10px solid ${props.theme.palette.primary.main};`}
  }

  &.isDragging {
    :hover {
      cursor: grabbing;
    }

    box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.5);
  }
`;

const Top = styled.div`
  display: flex;
  grid-column: 2;
  grid-row: 1;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

const TrashIcon = styled.button`
  && {
    position: absolute;
    right: 0;
    margin-top: -5px;
  }
`;

const CardTitle = styled.div`
  font-size: 19px;
  font-weight: bold;
  color: #4d5259;
`;

const DescriptionContainer = styled.div`
  margin-top: 16px;
  font-size: 14px;
  grid-column: 2;
  grid-row: 2;
  overflow: hidden;
  margin-bottom: 16px;
  color: ${(props) => props.theme.palette.gray.main};

  span {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

const PriceContainer = styled.div`
  text-align: center;
`;

const Bottom = styled.div`
  grid-column: 2;
  grid-row: 3;
`;

const Price = styled.span`
  font-size: 19px;
  font-weight: bold;
  color: ${(props) => props.theme.palette.primary.main};
`;

const HorizontalBar = styled.hr`
  border-top: 1px solid #edeff1;
  width: 100%;
  margin: 5% auto;
`;

const MenuOfServicesCard = ({
  name,
  description,
  price,
  displayPrice,
  onClick,
  onDelete,
  innerRef,
  isDraggable = false,
  isEditing = false,
  ...props
}) => {
  const dineroPrice = price
    ? Dinero({ amount: Number.parseInt(price, 10) })
    : null;
  const hasPrice = displayPrice || dineroPrice;
  return (
    <ClickableCard
      role="button"
      ref={innerRef}
      onClick={onClick}
      isDraggable={isDraggable}
      {...props}
    >
      {isEditing && !!onDelete && (
        <TrashIcon
          data-testid={`${name}-delete-button`}
          type="button"
          onClick={onDelete}
          className="btn btn-pure btn-danger"
        >
          <FontAwesomeIcon icon={faTrashAlt} size="lg" />
        </TrashIcon>
      )}
      <Top>
        <CardTitle>{name}</CardTitle>
      </Top>
      <DescriptionContainer title={description}>
        <span>{description}</span>
      </DescriptionContainer>
      {hasPrice && (
        <Bottom>
          <HorizontalBar />
          <PriceContainer>
            <Price>
              {displayPrice ||
                dineroPrice.toFormat(
                  !dineroPrice.hasSubUnits() ? '$0,0' : '$0,0.00',
                )}
            </Price>
          </PriceContainer>
        </Bottom>
      )}
    </ClickableCard>
  );
};

export default MenuOfServicesCard;
