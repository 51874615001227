import dateFormat from 'dateformat';

export function convertInputValueToRallyValue(matterField, value) {
  const resolvedType = matterField?.type?.type ?? matterField?.type;

  switch (resolvedType) {
    // We don't store dates as actual dates or as YYYY-MM-DD like the date input
    // wants. Convert from something that can construct a Date object to the
    // formatted version we use.
    case 'Date': {
      return value
        ? dateFormat(
            new Date(
              new Date(value).toLocaleString('en-US', { timeZone: 'UTC' }),
            ),
            matterField.type.noYear ? 'mmmm d' : 'mmmm d, yyyy',
          )
        : value;
    }
    default: {
      return value;
    }
  }
}
