const { protocol, hostname } = window.location;

export const backendHost = `${protocol}//${
  process.env.REACT_APP_BACKEND_HOST || hostname
}`;
export const wordProcessorServerHost = `${protocol}//${
  process.env.REACT_APP_WORD_PROCESSOR_SERVER_HOST || hostname
}`;
export const auth0Client = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
export const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;

// Safeguard to ensure we do not allow local auth in production.
export const useLocalAuth =
  (process.env.NODE_ENV !== 'production' ||
    process.env.REACT_APP_ENVIRONMENT === 'review') &&
  process.env.REACT_APP_USE_LOCAL_AUTH === 'true';
