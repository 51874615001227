import { ReconfigureFlopFlip } from '@flopflip/react-broadcast';
import axios from 'axios';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components/macro';

import MenuOfServicesScreen from '../scenes/LoginAndRegister/services/MenuOfServicesScreen';
import ServiceDetailScreen from '../scenes/LoginAndRegister/services/ServiceDetailScreen';
import ServiceRequestScreen from '../scenes/LoginAndRegister/services/ServiceRequestScreen';
import AuthUtility from '../services/AuthUtility';
import { userIsClient } from '../services/Utilities';
import CommandBar from './CommandBar';
import TopBar from './TopBar';

const Main = styled.main`
  margin: 0 10%;
`;

const ServiceMenuWorkspace = ({ match }) => {
  const {
    path,
    params: { firmId },
  } = match;

  const userId = localStorage.getItem('userId') || `public-firm-${firmId}`;
  const { clientCustomCode } = useContext(ThemeContext);

  if (AuthUtility.isAuthenticated()) {
    const accessToken = localStorage.getItem('access_token');
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }

  return (
    <>
      <Helmet>{clientCustomCode}</Helmet>
      <TopBar />
      <ReconfigureFlopFlip
        user={{ key: userId, custom: { firm: firmId, userType: 'client' } }}
      />
      <CommandBar />
      <Main>
        <Switch>
          <Route
            exact
            path={`${path}/servicemenu`}
            component={MenuOfServicesScreen}
          />
          <Route
            exact
            path={`${path}/service/:serviceId`}
            component={ServiceDetailScreen}
          />
          {userIsClient() && (
            <Route
              exact
              path="/firm/:firmId/service/:serviceId/request"
              component={ServiceRequestScreen}
            />
          )}
          <Redirect to="/" />
        </Switch>
      </Main>
    </>
  );
};

export default ServiceMenuWorkspace;
