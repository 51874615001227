function blobToBase64(blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => resolve(reader.result);
  });
}

export async function getDocxBase64(editor) {
  const docxBlob = await editor.saveAsBlob('Docx');
  if (!docxBlob) {
    return null;
  }
  let docx = await blobToBase64(docxBlob);
  docx = docx.substr(docx.indexOf(',') + 1);
  return docx;
}
