import axios from 'axios';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { handleException } from 'utils/ErrorUtils';

import Spinner from '../standard/Spinner';

const HubspotOAuth = () => {
  const { search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const showError = (error) => {
      history.push(`/client/settings?error=${encodeURIComponent(error)}`);
    };
    const showSuccess = (success) => {
      history.push(`/client/settings?success=${encodeURIComponent(success)}`);
    };

    const authorize = async () => {
      const { code } = queryString.parse(search);
      if (!code) showError('Unexpected error connecting to HubSpot.');

      try {
        const { status } = await axios.post('/hubspot/oauth', { code });
        if (status === 200) {
          showSuccess('Successfully connected to HubSpot.');
        } else {
          showError('Unexpected error connecting to HubSpot.');
        }
      } catch (error) {
        handleException(error);
        showError('Unexpected error connecting to HubSpot.');
      }
    };
    authorize();
  }, [search, history]);

  return <Spinner />;
};

export default HubspotOAuth;
