import Dinero from 'dinero.js';
import React, { memo } from 'react';
import styled from 'styled-components/macro';

import { DashboardCard } from '../dashboard/DashboardStyles';

const Card = styled(DashboardCard)`
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 20px;
  height: 331px;
  width: 522px;
  flex-grow: 0;
  flex-shrink: 0;
  transition: border-top 0.2s;
  padding-bottom: 12px;
  border-left: 10px solid ${(props) => props.theme.palette.primary.main};
  @media (max-width: 1024px) {
    width: 435px;
  }
  @media (max-width: 768px) {
    max-width: 93vw;
  }
  @media (min-width: 320px) and (max-width: 425px) {
    height: unset;
    padding-bottom: 25px;
  }
`;

const CardTitle = styled.div`
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
  color: #4d5259;
`;

const CardSubTitle = styled.div`
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 12px;
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.palette.gray.main};
`;

const HorizontalBar = styled.hr`
  border-top: 1px solid #edeff1;
  width: 100%;
  margin: 5% auto;
`;

const TotalLine = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
`;

const SummaryCard = ({ service }) => {
  const { name, description, price } = service;
  const formattedPrice = service.price
    ? Dinero({ amount: Number.parseInt(price, 10) }).toFormat()
    : null;

  return (
    <Card>
      <CardTitle className="row mb-15">
        <div className="col">Service Summary</div>
      </CardTitle>
      <CardSubTitle className="row mb-15">
        <div className="col-6">{name}</div>
        <div className="col-6 col-sm-3 offset-sm-3">{formattedPrice}</div>
      </CardSubTitle>
      <Description className="row">
        <div className="col">{description}</div>
      </Description>
      <HorizontalBar />
      <TotalLine className="row">
        <div className="col-6 col-sm-3">Total</div>
        <div className="col-6 col-sm-3 offset-sm-6">{formattedPrice}</div>
      </TotalLine>
    </Card>
  );
};

export default memo(SummaryCard);
