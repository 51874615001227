import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components/macro';

const Logo = styled.img.attrs(({ theme }) => ({
  src: theme.logo.dark,
  alt: 'loco-icon',
}))`
  height: ${({ theme }) => theme.logo.topBarHeight};
`;

const SmallScreenLogo = () => {
  const theme = useContext(ThemeContext);
  const LogoContainer = styled.div`
    z-index: 999;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(77, 82, 89, 0.07);
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  return (
    <LogoContainer className="d-md-none">
      <Logo theme={theme} />
    </LogoContainer>
  );
};

export default SmallScreenLogo;
