import Seventy1LegalFavicon from './71legal/favicon.ico';
import Seventy1LegalSplashImage from './71legal/loginSplashImage.png';
import Seventy1LegalLogoDark from './71legal/logoDark.png';
import AccessioDocsFavicon from './accessiodocs/favicon.ico';
import AccessioDocsSplashImage from './accessiodocs/loginSplashImage.png';
import AccessioDocsLogoDark from './accessiodocs/logoDark.png';
import AkiwowoFavicon from './akiwowolawgroup/favicon.ico';
import AkiwowoSplashImage from './akiwowolawgroup/loginSplashImage.png';
import AkiwowoLogoDark from './akiwowolawgroup/logoDark.png';
import AlxLegalFavicon from './alxlegal/favicon.ico';
import AlxLegalSplashImage from './alxlegal/loginSplashImage.png';
import AlxLegalLogo from './alxlegal/logo.png';
import AmberBoydFavicon from './amberboydlaw/favicon.ico';
import AmberBoydSplashImage from './amberboydlaw/loginSplashImage.png';
import AmberBoydLogoDark from './amberboydlaw/logoDark.png';
import AsburyFavicon from './asburylaw/favicon.ico';
import AsburySplashImage from './asburylaw/loginSplashImage.png';
import AsburyLogoDark from './asburylaw/logoDark.png';
import AtlasBizDocsGroupFavicon from './atlasbizdocs/favicon.ico';
import AtlasBizDocsSplashImage from './atlasbizdocs/loginSplashImage.png';
import AtlasBizDocsGroupLogoDark from './atlasbizdocs/logoDark.png';
import MinaHaqueFavicon from './attorneymina/favicon.ico';
import MinaHaqueSplashImage from './attorneymina/loginSplashImage.png';
import MinaHaqueLogoDark from './attorneymina/logoDark.png';
import AyahFavicon from './ayahvirtualservices/favicon.ico';
import AyahSplashImage from './ayahvirtualservices/loginSplashImage.png';
import AyahLogoDark from './ayahvirtualservices/logoDark.png';
import BizCorpNationFavicon from './bizcorpnation/favicon.ico';
import BizCorpNationSplashImage from './bizcorpnation/loginSplashImage.png';
import BizCorpNationLogoDark from './bizcorpnation/logoDark.png';
import StankoFavicon from './brandonstanko/favicon.ico';
import StankoSplashImage from './brandonstanko/loginSplashImage.png';
import StankoLogoDark from './brandonstanko/logoDark.png';
import BrevityFavicon from './brevity/favicon.ico';
import BrevitySplashImage from './brevity/loginSplashImage.png';
import BrevityLogoDark from './brevity/logoDark.png';
import BSloanFavicon from './bsloanlaw/favicon.ico';
import BSloanSplashImage from './bsloanlaw/loginSplashImage.png';
import BSloanLogoDark from './bsloanlaw/logoDark.png';
import CaravelLawFavicon from './caravellaw/favicon.ico';
import CaravelLawSplashImage from './caravellaw/loginSplashImage.png';
import CaravelLawLogoDark from './caravellaw/logoDark.png';
import CaravelLawLogoLight from './caravellaw/logoLight.png';
import CarsonFavicon from './carsonlaw/favicon.ico';
import CarsonSplashImage from './carsonlaw/loginSplashImage.png';
import CarsonLogoDark from './carsonlaw/logoDark.png';
import CASFavicon from './cas/favicon.ico';
import CASSplashImage from './cas/loginSplashImage.png';
import CASLogoDark from './cas/logoDark.png';
import CogerFavicon from './cogerlaw/favicon.ico';
import CogerSplashImage from './cogerlaw/loginSplashImage.png';
import CogerLogoDark from './cogerlaw/logoDark.png';
import CohenHighleyFavicon from './cohenhighley/favicon.ico';
import CohenHighleySplashImage from './cohenhighley/loginSplashImage.png';
import CohenHighleyLogoDark from './cohenhighley/logoDark.png';
import CollateralBaseFavicon from './collateralbase/favicon.ico';
import CollateralBaseSplashImage from './collateralbase/loginSplashImage.png';
import CollateralBaseLogoDark from './collateralbase/logoDark.png';
import ColoradoFavicon from './coloradoparalegalgroup/favicon.ico';
import ColoradoSplashImage from './coloradoparalegalgroup/loginSplashImage.png';
import ColoradoLogoDark from './coloradoparalegalgroup/logoDark.png';
import ComLawFavicon from './comlaw/favicon.ico';
import ComLawSplashImage from './comlaw/loginSplashImage.png';
import ComLawLogoDark from './comlaw/logoDark.png';
import CounselExpressFavicon from './counselexpress/favicon.ico';
import CounselExpressSplashImage from './counselexpress/loginSplashImage.png';
import CounselExpressLogoDark from './counselexpress/logoDark.png';
import CoxAndPalmerFavicon from './coxandpalmer/favicon.ico';
import CoxAndPalmerSplashImage from './coxandpalmer/loginSplashImage.png';
import CoxAndPalmerLogoDark from './coxandpalmer/logoDark.png';
import D2Favicon from './d2law/favicon.ico';
import D2SplashImage from './d2law/loginSplashImage.png';
import D2LogoDark from './d2law/logoDark.png';
import DavidAustinFavicon from './davidaustinlaw/favicon.ico';
import DavidAustinSplashImage from './davidaustinlaw/loginSplashImage.png';
import DavidAustinLogoDark from './davidaustinlaw/logoDark.png';
import DavidIPFavicon from './davidiplaw/favicon.ico';
import DavidIPSplashImage from './davidiplaw/loginSplashImage.png';
import DavidIPLogoDark from './davidiplaw/logoDark.png';
import DefaultFavicon from './default/favicon.ico';
import DefaultLoginSplashImage from './default/loginSplashImage.png';
import DefaultLogoDark from './default/logoDark.png';
import DefaultLogoLight from './default/logoLight.png';
import DemoLoginSplashImage from './demo/loginSplashImage.png';
import DemoLogoDark from './demo/logoDark.png';
import DeSaFavicon from './desalegal/favicon.ico';
import DeSaSplashImage from './desalegal/loginSplashImage.png';
import DeSaLogoDark from './desalegal/logoDark.png';
import DocGovernFavicon from './docgovern/favicon.ico';
import DocGovernSplashImage from './docgovern/loginSplashImage.png';
import DocGovernLogoDark from './docgovern/logoDark.png';
import EliteMediationFavicon from './elitemediation/favicon.ico';
import EliteMediationSplashImage from './elitemediation/loginSplashImage.png';
import EliteMediationLogoDark from './elitemediation/logoDark.png';
import ElliottDaleFavicon from './elliottdale/favicon.ico';
import ElliottDaleSplashImage from './elliottdale/loginSplashImage.png';
import ElliottDaleLogoDark from './elliottdale/logoDark.png';
import EmeraldFavicon from './emerald/favicon.ico';
import EmeraldLoginSplashImage from './emerald/loginSplashImage.png';
import EmeraldLogo from './emerald/logoDark.png';
import EmergeFavicon from './emerge/favicon.ico';
import EmergeLoginSplashImage from './emerge/loginSplashImage.png';
import EmergeLogo from './emerge/logoDark.png';
import EmeryFavicon from './emerylawfirm/favicon.ico';
import EmerySplashImage from './emerylawfirm/loginSplashImage.png';
import EmeryLogoDark from './emerylawfirm/logoDark.png';
import EmilyHicksFavicon from './emilyhickslaw/favicon.ico';
import EmilyHicksSplashImage from './emilyhickslaw/loginSplashImage.png';
import EmilyHicksLogoDark from './emilyhickslaw/logoDark.png';
import espace71Favicon from './espace71/favicon.ico';
import espace71SplashImage from './espace71/loginSplashImage.png';
import espace71LogoDark from './espace71/logoDark.png';
import EstateConnectionFavicon from './estateconnection/favicon.ico';
import EstateConnectionSplashImage from './estateconnection/loginSplashImage.png';
import EstateConnectionLogoDark from './estateconnection/logoDark.png';
import EVFavicon from './evlawgroup/favicon.ico';
import EVSplashImage from './evlawgroup/loginSplashImage.png';
import EVLogoDark from './evlawgroup/logoDark.png';
import FabianiLawSplashImage from './fabianilaw/loginSplashImage.png';
import FabianiLawLogoDark from './fabianilaw/logoDark.png';
import FarooFavicon from './faroolegal/favicon.ico';
import FarooSplashImage from './faroolegal/loginSplashImage.png';
import FarooLogoDark from './faroolegal/logoDark.png';
import FiveValleysFavicon from './fivevalleyslaw/favicon.ico';
import FiveValleysSplashImage from './fivevalleyslaw/loginSplashImage.png';
import FiveValleysLogoDark from './fivevalleyslaw/logoDark.png';
import GallettaFavicon from './gallettalaw/favicon.ico';
import GallettaSplashImage from './gallettalaw/loginSplashImage.png';
import GallettaLogoDark from './gallettalaw/logoDark.png';
import GaluppoFavicon from './galuppolaw/favicon.ico';
import GaluppoSplashImage from './galuppolaw/loginSplashImage.png';
import GaluppoLogoDark from './galuppolaw/logoDark.png';
import GHRFavicon from './ghrlawyers/favicon.ico';
import GHRSplashImage from './ghrlawyers/loginSplashImage.png';
import GHRLogoDark from './ghrlawyers/logoDark.png';
import GiroFavicon from './girolaw/favicon.ico';
import GiroSplashImage from './girolaw/loginSplashImage.png';
import GiroLogoDark from './girolaw/logoDark.png';
import GoldinFavicon from './goldinlaw/favicon.ico';
import GoldinSplashImage from './goldinlaw/loginSplashImage.png';
import GoldinLogoDark from './goldinlaw/logoDark.png';
import GoldmanFavicon from './goldmanlawatx/favicon.ico';
import GoldmanSplashImage from './goldmanlawatx/loginSplashImage.png';
import GoldmanLogoDark from './goldmanlawatx/logoDark.png';
import GovDoxFavicon from './govdox/favicon.ico';
import GovDoxSplashImage from './govdox/loginSplashImage.png';
import GovDoxLogoDark from './govdox/logoDark.png';
import GowlingFavicon from './gowlingwlg/favicon.ico';
import GowlingSplashImage from './gowlingwlg/loginSplashImage.png';
import GowlingLogoDark from './gowlingwlg/logoDark.png';
import GundersonFavicon from './gunderson/favicon.ico';
import GundersonSplashImage from './gunderson/loginSplashImage.png';
import GundersonLogoDark from './gunderson/logoDark.png';
import HamiltonLawFavicon from './hamiltonlaw/favicon.ico';
import HamiltonLawSplashImage from './hamiltonlaw/loginSplashImage.png';
import HamiltonLawLogoDark from './hamiltonlaw/logoDark.png';
import HanchettFavicon from './hanchettlawfirm/favicon.ico';
import HanchettSplashImage from './hanchettlawfirm/loginSplashImage.png';
import HanchettLogoDark from './hanchettlawfirm/logoDark.png';
import HarrisAccountingFavicon from './harrisaccounting/favicon.ico';
import HarrisAccountingSplashImage from './harrisaccounting/loginSplashImage.png';
import HarrisAccountingLogoDark from './harrisaccounting/logoDark.png';
import HastingsLawFavicon from './hastingsnclaw/favicon.ico';
import HastingsLawSplashImage from './hastingsnclaw/loginSplashImage.png';
import HastingsLawLogoDark from './hastingsnclaw/logoDark.png';
import HiattFavicon from './hiattlawaz/favicon.ico';
import HiattSplashImage from './hiattlawaz/loginSplashImage.png';
import HiattLogoDark from './hiattlawaz/logoDark.png';
import HigginbothamFavicon from './higginbotham/favicon.ico';
import HigginbothamSplashImage from './higginbotham/loginSplashImage.png';
import HigginbothamLogoDark from './higginbotham/logoDark.png';
import HillLittleFavicon from './hillandlittle/favicon.ico';
import HillLittleSplashImage from './hillandlittle/loginSplashImage.png';
import HillLittleLogoDark from './hillandlittle/logoDark.png';
import HowellSplashImage from './howelllegal/loginSplashImage.png';
import HowellLogoDark from './howelllegal/logoDark.png';
import HrutkayFavicon from './hrutkaylaw/favicon.ico';
import HrutkaySplashImage from './hrutkaylaw/loginSplashImage.png';
import HrutkayLogoDark from './hrutkaylaw/logoDark.png';
import IndusFavicon from './induscounsel/favicon.ico';
import IndusSplashImage from './induscounsel/loginSplashImage.png';
import IndusLogoDark from './induscounsel/logoDark.png';
import IngenioFavicon from './ingeniolaw/favicon.ico';
import IngenioSplashImage from './ingeniolaw/loginSplashImage.png';
import IngenioLogoDark from './ingeniolaw/logoDark.png';
import InsightLegalFavicon from './insightlegal/favicon.ico';
import InsightLegalSplashImage from './insightlegal/loginSplashImage.png';
import InsightLegalLogoDark from './insightlegal/logoDark.png';
import JeffSchickFavicon from './jeff/favicon.ico';
import JeffSchickSplashImage from './jeff/loginSplashImage.png';
import JeffSchickLogoDark from './jeff/logoDark.png';
import JennaCommFavicon from './jennacomm/favicon.ico';
import JennaCommSplashImage from './jennacomm/loginSplashImage.png';
import JennaCommLogoDark from './jennacomm/logoDark.png';
import FowlerFavicon from './jflclaw/favicon.ico';
import FowlerSplashImage from './jflclaw/loginSplashImage.png';
import FowlerLogoDark from './jflclaw/logoDark.png';
import JKCoFavicon from './jkcoconsulting/favicon.ico';
import JKCoSplashImage from './jkcoconsulting/loginSplashImage.png';
import JKCoLogoDark from './jkcoconsulting/logoDark.png';
import JohnnieJamesFavicon from './johnniejameslaw/favicon.ico';
import JohnnieJamesSplashImage from './johnniejameslaw/loginSplashImage.png';
import JohnnieJamesLogoDark from './johnniejameslaw/logoDark.png';
import JustCallJodiFavicon from './justcalljodi/favicon.ico';
import JustCallJodiSplashImage from './justcalljodi/loginSplashImage.png';
import JustCallJodiLogoDark from './justcalljodi/logoDark.png';
import VastiLawFavicon from './jvastilaw/favicon.ico';
import VastiLawSplashImage from './jvastilaw/loginSplashImage.png';
import VastiLawLogoDark from './jvastilaw/logoDark.png';
import KnaackFavicon from './knaacklaw/favicon.ico';
import KnaackSplashImage from './knaacklaw/loginSplashImage.png';
import KnaackLogoDark from './knaacklaw/logoDark.png';
import KongLawFavicon from './konglaw/favicon.ico';
import KongLawSplashImage from './konglaw/loginSplashImage.png';
import KongLawLogoDark from './konglaw/logoDark.png';
import LawhornFavicon from './lawhornlaw/favicon.ico';
import LawhornSplashImage from './lawhornlaw/loginSplashImage.png';
import LawhornLogoDark from './lawhornlaw/logoDark.png';
import LawtekFavicon from './lawtek/favicon.ico';
import LawtekSplashImage from './lawtek/loginSplashImage.png';
import LawtekLogoDark from './lawtek/logoDark.png';
import LBFFavicon from './lbflawfirm/favicon.ico';
import LBFSplashImage from './lbflawfirm/loginSplashImage.png';
import LBFLogoDark from './lbflawfirm/logoDark.png';
import LegalDirectFavicon from './legaldirectinc/favicon.ico';
import LegalDirectSplashImage from './legaldirectinc/loginSplashImage.png';
import LegalDirectLogoDark from './legaldirectinc/logoDark.png';
import LegalGuruFavicon from './legalguru/favicon.ico';
import LegalGuruSplashImage from './legalguru/loginSplashImage.png';
import LegalGuruLogoDark from './legalguru/logoDark.png';
import LeopoldusLawFavicon from './leopolduslaw/favicon.ico';
import LeopoldusLawSplashImage from './leopolduslaw/loginSplashImage.png';
import LeopoldusLawLogoDark from './leopolduslaw/logoDark.png';
import LisaSutherlandFavicon from './lisasutherlandlaw/favicon.ico';
import LisaSutherlandSplashImage from './lisasutherlandlaw/loginSplashImage.png';
import LisaSutherlandLogoDark from './lisasutherlandlaw/logoDark.png';
import GrayLegalFavicon from './lockedandlawyered/favicon.ico';
import GrayLegalSplashImage from './lockedandlawyered/loginSplashImage.png';
import GrayLegalLogoDark from './lockedandlawyered/logoDark.png';
import ManriqueFavicon from './manriquelawgroup/favicon.ico';
import ManriqueSplashImage from './manriquelawgroup/loginSplashImage.png';
import ManriqueLogoDark from './manriquelawgroup/logoDark.png';
import MarshallLawFavicon from './marshalllaw/favicon.ico';
import MarshallLawSplashImage from './marshalllaw/loginSplashImage.png';
import MarshallLawLogoDark from './marshalllaw/logoDark.png';
import KinneyFavicon from './michellekinneylaw/favicon.ico';
import KinneySplashImage from './michellekinneylaw/loginSplashImage.png';
import KinneyLogoDark from './michellekinneylaw/logoDark.png';
import MissionCounselFavicon from './missioncounsel/favicon.ico';
import MissionCounselSplashImage from './missioncounsel/loginSplashImage.png';
import MissionCounselLogoDark from './missioncounsel/logoDark.png';
import MKMFavicon from './mkm/favicon.ico';
import MKMSplashImage from './mkm/loginSplashImage.png';
import MKMLogoDark from './mkm/logoDark.png';
import MarsicanoFavicon from './mlesquire/favicon.ico';
import MarsicanoSplashImage from './mlesquire/loginSplashImage.png';
import MarsicanoLogoDark from './mlesquire/logoDark.png';
import MomentumFavicon from './momentumlaw/favicon.ico';
import MomentumSplashImage from './momentumlaw/loginSplashImage.png';
import MomentumLogoDark from './momentumlaw/logoDark.png';
import MortellaroFavicon from './mortellarolaw/favicon.ico';
import MortellaroSplashImage from './mortellarolaw/loginSplashImage.png';
import MortellaroLogoDark from './mortellarolaw/logoDark.png';
import MtFavicon from './mt/favicon.ico';
import MtFormWaiver from './mt/FormWaiver';
import MtLoginSplashImage from './mt/loginSplashImage.png';
import MtLogoDark from './mt/logoDark.png';
import MtLogoLight from './mt/logoLight.png';
import MtTerms from './mt/Terms';
import NanoFavicon from './nanolaw/favicon.ico';
import NanoSplashImage from './nanolaw/loginSplashImage.png';
import NanoLogoDark from './nanolaw/logoDark.png';
import NantzLawFavicon from './nantzlaw/favicon.ico';
import NantzLawLoginSplashImage from './nantzlaw/loginSplashImage.png';
import NantzLawLogoDark from './nantzlaw/logoDark.png';
import NasserFavicon from './nasserlaw/favicon.ico';
import NasserSplashImage from './nasserlaw/loginSplashImage.png';
import NasserLogoDark from './nasserlaw/logoDark.png';
import NaturallyLegalFavicon from './naturallylegal/favicon.ico';
import NaturallyLegalSplashImage from './naturallylegal/loginSplashImage.png';
import NaturallyLegalLogoDark from './naturallylegal/logoDark.png';
import NortonEstateFavicon from './nortonestateplanning/favicon.ico';
import NortonEstateSplashImage from './nortonestateplanning/loginSplashImage.png';
import NortonEstateLogoDark from './nortonestateplanning/logoDark.png';
import NortonRoseFavicon from './nortonrosefulbright/favicon.ico';
import NortonRoseSplashImage from './nortonrosefulbright/loginSplashImage.png';
import NortonRoseLogoDark from './nortonrosefulbright/logoDark.png';
import NRWealthFavicon from './nrwealthgroup/favicon.ico';
import NRWealthSplashImage from './nrwealthgroup/loginSplashImage.png';
import NRWealthLogoDark from './nrwealthgroup/logoDark.png';
import OneLawFavicon from './onelawpc/favicon.ico';
import OneLawSplashImage from './onelawpc/loginSplashImage.png';
import OneLawLogoDark from './onelawpc/logoDark.png';
import OwlsightFavicon from './owlsight/favicon.ico';
import OwlsightSplashImage from './owlsight/loginSplashImage.png';
import OwlsightLogoDark from './owlsight/logoDark.png';
import PPTaxFavicon from './paramountpropertytaxappeal/favicon.ico';
import PPTaxSplashImage from './paramountpropertytaxappeal/loginSplashImage.png';
import PPTaxLogoDark from './paramountpropertytaxappeal/logoDark.png';
import PaytonFavicon from './paytonlegal/favicon.ico';
import PaytonSplashImage from './paytonlegal/loginSplashImage.png';
import PaytonLogoDark from './paytonlegal/logoDark.png';
import PhantomFavicon from './phantomcompliance/favicon.ico';
import PhantomSplashImage from './phantomcompliance/loginSplashImage.png';
import PhantomLogoDark from './phantomcompliance/logoDark.png';
import PixelLawFavicon from './pixellaw/favicon.ico';
import PixelLawSplashImage from './pixellaw/loginSplashImage.png';
import PixelLawLogoDark from './pixellaw/logoDark.png';
import MonahanLawFirmFavicon from './pjmfirm/favicon.ico';
import MonahanLawFirmSplashImage from './pjmfirm/loginSplashImage.png';
import MonahanLawFirmLogoDark from './pjmfirm/logoDark.png';
import PlanWellFavicon from './planwelllegal/favicon.ico';
import PlanWellSplashImage from './planwelllegal/loginSplashImage.png';
import PlanWellLogoDark from './planwelllegal/logoDark.png';
import PrattAycockFavicon from './prattaycock/favicon.ico';
import PrattAycockSplashImage from './prattaycock/loginSplashImage.png';
import PrattAycockLogoDark from './prattaycock/logoDark.png';
import ProvantageFavicon from './provantagecapital/favicon.ico';
import ProvantageLoginSplashImage from './provantagecapital/loginSplashImage.png';
import ProvantageLogoDark from './provantagecapital/logoDark.png';
import SobleFavicon from './provenresource/favicon.ico';
import SobleSplashImage from './provenresource/loginSplashImage.png';
import SobleLogoDark from './provenresource/logoDark.png';
import RandallFavicon from './raaslaw/favicon.ico';
import RandallSplashImage from './raaslaw/loginSplashImage.png';
import RandallLogoDark from './raaslaw/logoDark.png';
import RadiusLawFavicon from './radiuslaw/favicon.ico';
import RadiusLawLoginSplashImage from './radiuslaw/loginSplashImage.png';
import RadiusLawLogoDark from './radiuslaw/logoDark.png';
import RatelleFavicon from './ratellelaw/favicon.ico';
import RatelleSplashImage from './ratellelaw/loginSplashImage.png';
import RatelleLogoDark from './ratellelaw/logoDark.png';
import ReadySetFavicon from './readysetlegal/favicon.ico';
import ReadySetSplashImage from './readysetlegal/loginSplashImage.png';
import ReadySetLogoDark from './readysetlegal/logoDark.png';
import ReedmanLawFavicon from './reedmanlaw/favicon.ico';
import ReedmanLawLoginSplashImage from './reedmanlaw/loginSplashImage.png';
import ReedmanLawLogoDark from './reedmanlaw/logoDark.png';
import ReedmanLawLogoLight from './reedmanlaw/logoLight.png';
import RennocoFavicon from './rennoco/favicon.ico';
import RennocoLoginSplashImage from './rennoco/loginSplashImage.png';
import RennocoLogoDark from './rennoco/logoDark.png';
import RennocoLogoLight from './rennoco/logoLight.png';
import ResoluteFavicon from './resolutelegalservices/favicon.ico';
import ResoluteSplashImage from './resolutelegalservices/loginSplashImage.png';
import ResoluteLogoDark from './resolutelegalservices/logoDark.png';
import RightLegalFavicon from './rightlegal/favicon.ico';
import RightLegalSplashImage from './rightlegal/loginSplashImage.png';
import RightLegalLogoDark from './rightlegal/logoDark.png';
import RKLawFavicon from './rklaw/favicon.ico';
import RKLawSplashImage from './rklaw/loginSplashImage.png';
import RKLawLogoDark from './rklaw/logoDark.png';
import RobertSciglimpagliaFavicon from './robertsciglimpaglia/favicon.ico';
import RobertSciglimpagliaSplashImage from './robertsciglimpaglia/loginSplashImage.png';
import RobertSciglimpagliaLogoDark from './robertsciglimpaglia/logoDark.png';
import SehgalFavicon from './sehgallawpc/favicon.ico';
import SehgalSplashImage from './sehgallawpc/loginSplashImage.png';
import SehgalLogoDark from './sehgallawpc/logoDark.png';
import SoroyaSplashImage from './sgarnerlaw/loginSplashImage.png';
import SoroyaLogoDark from './sgarnerlaw/logoDark.png';
import ShantellFavicon from './shantelllaw/favicon.ico';
import ShantellSplashImage from './shantelllaw/loginSplashImage.png';
import ShantellLogoDark from './shantelllaw/logoDark.png';
import SilkFavicon from './silklegal/favicon.ico';
import SilkSplashImage from './silklegal/loginSplashImage.png';
import SilkLogoDark from './silklegal/logoDark.png';
import SimpleLivingFavicon from './simplelivingtrusts/favicon.ico';
import SimpleLivingLoginSplashImage from './simplelivingtrusts/loginSplashImage.png';
import SimpleLivingLogoDark from './simplelivingtrusts/logoDark.png';
import SnydermanFavicon from './snyderman/favicon.ico';
import SnydermanSplashImage from './snyderman/loginSplashImage.png';
import SnydermanLogoDark from './snyderman/logoDark.png';
import SpaansFavicon from './spaans-spaans/favicon.ico';
import SpaansSplashImage from './spaans-spaans/loginSplashImage.png';
import SpaansLogoDark from './spaans-spaans/logoDark.png';
import SparkFavicon from './spark/favicon.ico';
import SparkLoginSplashImage from './spark/loginSplashImage.png';
import SparkLogoDark from './spark/logoDark.png';
import SparkLogoLight from './spark/logoLight.png';
import SpavinsFavicon from './spavinslegal/favicon.ico';
import SpavinsSplashImage from './spavinslegal/loginSplashImage.png';
import SpavinsLogoDark from './spavinslegal/logoDark.png';
import SpringLegalFavicon from './springlegal/favicon.ico';
import SpringLegalSplashImage from './springlegal/loginSplashImage.png';
import SpringLegalLogoDark from './springlegal/logoDark.svg';
import StartupProgramFavicon from './startupprogram/favicon.ico';
import StartupProgramLoginSplashImage from './startupprogram/loginSplashImage.png';
import StartupProgramLogoDark from './startupprogram/logoDark.png';
import SullivanLTFavicon from './sullivanlt/favicon.ico';
import SullivanLTSplashImage from './sullivanlt/loginSplashImage.png';
import SullivanLTLogoDark from './sullivanlt/logoDark.png';
import TankelFavicon from './tankellawgroup/favicon.ico';
import TankelSplashImage from './tankellawgroup/loginSplashImage.png';
import TankelLogoDark from './tankellawgroup/logoDark.png';
import TexasFavicon from './texascommerciallaw/favicon.ico';
import TexasSplashImage from './texascommerciallaw/loginSplashImage.png';
import TexasLogoDark from './texascommerciallaw/logoDark.png';
import AndersonFavicon from './theandersonlawgroup/favicon.ico';
import AndersonSplashImage from './theandersonlawgroup/loginSplashImage.png';
import AndersonLogoDark from './theandersonlawgroup/logoDark.png';
import BPLawGroupFavicon from './thebplawgroup/favicon.ico';
import BPLawGroupSplashImage from './thebplawgroup/loginSplashImage.png';
import BPLawGroupLogoDark from './thebplawgroup/logoDark.png';
import LawShopFavicon from './thelawshop/favicon.ico';
import LawShopSplashImage from './thelawshop/loginSplashImage.png';
import LawShopLogoDark from './thelawshop/logoDark.png';
import LegalKnowFavicon from './thelegalknow/favicon.ico';
import LegalKnowSplashImage from './thelegalknow/loginSplashImage.png';
import LegalKnowLogoDark from './thelegalknow/logoDark.png';
import TOSLawyerFavicon from './toslawyer/favicon.ico';
import TOSLawyerLogoDark from './toslawyer/logoDark.png';
import TOSLawyerLogoLight from './toslawyer/logoLight.png';
import TrustLegalGroupFavicon from './trustlegalgroup/favicon.ico';
import TrustLegalGroupSplashImage from './trustlegalgroup/loginSplashImage.png';
import TrustLegalGroupLogoDark from './trustlegalgroup/logoDark.png';
import UmbrellaFavicon from './umbrellalaw/favicon.ico';
import UmbrellaSplashImage from './umbrellalaw/loginSplashImage.png';
import UmbrellaLogoDark from './umbrellalaw/logoDark.png';
import UnifiedFavicon from './unifiedlaw/favicon.ico';
import UnifiedSplashImage from './unifiedlaw/loginSplashImage.png';
import UnifiedLogoDark from './unifiedlaw/logoDark.png';
import VelnFavicon from './veln/favicon.ico';
import VelnSplashImage from './veln/loginSplashImage.png';
import VelnLogoDark from './veln/logoDark.png';
import WallsGroupFavicon from './wallslegalservices/favicon.ico';
import WallsGroupSplashImage from './wallslegalservices/loginSplashImage.png';
import WallsGroupLogoDark from './wallslegalservices/logoDark.png';
import WeDefendYouFavicon from './wedefendyou/favicon.ico';
import WeDefendYouSplashImage from './wedefendyou/loginSplashImage.png';
import WeDefendYouLogoDark from './wedefendyou/logoDark.png';
import WickFavicon from './wicklegal/favicon.ico';
import WickSplashImage from './wicklegal/loginSplashImage.png';
import WickLogoDark from './wicklegal/logoDark.png';
import WillsNLFavicon from './willsnl/favicon.ico';
import WillsNLSplashImage from './willsnl/loginSplashImage.png';
import WillsNLLogoDark from './willsnl/logoDark.png';
import WishartFavicon from './wishartlaw/favicon.ico';
import WishartSplashImage from './wishartlaw/loginSplashImage.png';
import WishartLogoDark from './wishartlaw/logoDark.png';
import PenachoFavicon from './yodeldelosopenacho/favicon.ico';
import PenachoSplashImage from './yodeldelosopenacho/loginSplashImage.png';
import PenachoLogoDark from './yodeldelosopenacho/logoDark.png';

const themes = {
  default: {
    palette: {
      base: {
        white: '#fffffe',
        gray: '#f8f8f8',
      },
      shade: {
        black: '#2D282C',
        gray_1: '#465161',
        gray_2: '#8B95A5',
        gray_3: '#B2B8C1',
        gray_4: '#CFD2D9',
        gray_5: '#F2F5FA',
      },
      feedback: {
        success: {
          dark: '#4A7256',
          medium: '#63A25D',
          light: '#D0EECD',
          extralight: '#E1FFDC',
        },
        warning: {
          dark: '#D77E19',
          medium: '#EBB474',
          light: '#FBDBB7',
        },
        error: {
          dark: '#D33333',
          medium: '#E45555',
          light: '#FDA1A1',
          extralight: '#FFDBDB',
        },
        info: {
          dark: '#2FA1C1',
          medium: '#5BC4E1',
          light: '#C4EAF4',
        },
      },
      brand: {
        primary_main: '#24c0eb',
        primary_white: '#FEFEFE',
      },
      primary: {
        light: '#49cbee',
        main: '#24c0eb',
        dark: '#13aad3',
        contrastText: '#fff',
      },
      secondary: {
        light: '#576e98',
        main: '#4c6085',
        dark: '#465161',
        contrastText: '#fff',
      },
      gray: {
        light: '#9F9F9F',
        main: '#8b95a5',
        dark: '#465161',
      },
      success: {
        main: '#13C4A3',
      },
      destructive: {
        light: '#DB2763',
        main: '#DB2763',
        dark: '#b11e4f',
        contrastText: '#fff',
      },
      inactive: {
        main: '#4d5259',
      },
      text: {
        primary: '#4d5259',
        secondary: '#8b95a5',
        disabled: '#a5b3c7',
      },
    },
    splash: {
      image: DefaultLoginSplashImage,
      logoContainerStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    logo: {
      light: DefaultLogoLight,
      dark: DefaultLogoDark,
      width: '280px',
      topBarHeight: '40px',
    },
    stylesheet: {},
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    favicon: DefaultFavicon,
    title: 'Rally',
    showPoweredByRally: true,
    registrationSubheading:
      'This will allow us to better collaborate with you.',
  },
  app: {
    showPoweredByRally: false,
  },
  my: {
    clientIntercomId: 'ix18c51m',
    showPoweredByRally: false,
    registrationSubheading:
      'Sign up to gain access to automated workflows, eSigning, file storage, and more.',
  },
  firm: {
    title: 'Local-Firm',
  },
  firm2: {
    title: 'Local-Firm-2',
  },
  client: {
    title: 'Local-Client',
  },
  client2: {
    title: 'Local-Client-2',
  },
  mt: {
    palette: {
      primary: {
        light: '#90d2ed',
        main: '#6EC4E8',
        dark: '#7ccaea',
        contrastText: '#fff',
      },
      secondary: {
        light: '#2E2925',
        main: '#2E2925',
        dark: '#2E2925',
        contrastText: '#fff',
      },
      gray: {
        light: '#8b95a5',
        main: '#8b95a5',
        dark: '#4d5259',
      },
      destructive: {
        light: '#F05341',
        main: '#F05341',
        dark: '#F05341',
        contrastText: '#fff',
      },
    },
    splash: {
      image: MtLoginSplashImage,
      backgroundSize: 'contain',
      backgroundColor: '#413735',
      backgroundPosition: 'right',
      logoContainerStyle: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingBottom: '45px',
      },
    },
    logo: {
      width: '400px',
      light: MtLogoLight,
      dark: MtLogoDark,
      topBarHeight: '18px',
    },
    formWaiver: {
      body: MtFormWaiver,
      confirmation:
        'I have read and agreed to the terms and conditions above. ',
    },
    registrationTerms: MtTerms,
    stylesheet: {},
    clientSubmissionConfirmation: `
      <style>
        a:hover {
          border-bottom-color: unset !important;
          color: unset !important;
        }
      </style>
      <p>We will contact you within 2 business days to discuss next steps.<br/>If you have any further questions, please <a href = "mailto:support-amplifi@millerthomson.com" target="_blank" rel="noopener noreferrer"> contact us</a>.</p>`,
    favicon: MtFavicon,
    title: 'MT Amplifi',
  },
  demo: {
    palette: {
      primary: {
        light: '#49cbee',
        main: '#24c0eb',
        dark: '#13aad3',
        contrastText: '#fff',
      },
      secondary: {
        light: '#576e98',
        main: '#465161',
        dark: '#415272',
        contrastText: '#fff',
      },
      gray: {
        light: '#8b95a5',
        main: '#8b95a5',
        dark: '#4d5259',
      },
    },
    splash: {
      image: DemoLoginSplashImage,
    },
    logo: {
      light: '',
      width: '200px',
      topBarHeight: '40px',
      dark: DemoLogoDark,
    },
    clientSubmissionConfirmation: `
    <p>Please book a 30 minute call with us <a href="https://calendly.com/rally-llp" target="_blank rel="noopener noreferrer"">here</a>.</p>`,
    stylesheet: {},
    favicon: DefaultFavicon,
    title: 'Your Firm',
    showPoweredByRally: false,
    calendlyLink: 'https://calendly.com/rally-llp',
    customNavItem: {
      label: 'Resources',
      href: 'https://www.notion.so/Legal-Resources-b8432faf30764a3881834d68047213b8',
    },
  },
  rennoco: {
    domains: ['www.rennoco.app', 'rennoco.app'],
    palette: {
      primary: {
        light: '#304886',
        main: '#304886',
        dark: '#304886',
        contrastText: '#fff',
      },
      secondary: {
        light: '#F2F2F2',
        main: '#686868',
        dark: '#686868',
        contrastText: '#fff',
      },
      gray: {
        light: '#686868',
        main: '#686868',
        dark: '#4d5259',
      },
    },
    splash: {
      image: RennocoLoginSplashImage,
      backgroundSize: 'contain',
      backgroundColor: '#F0F6FC',
      logoContainerStyle: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '-15px',
      },
    },
    logo: {
      dark: RennocoLogoDark,
      light: RennocoLogoLight,
      width: '60px',
      topBarHeight: '40px',
    },
    stylesheet: {},
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    favicon: RennocoFavicon,
    title: 'Renno & Co',
    primaryFont: {
      url: 'https://fonts.googleapis.com/css?family=Inria+Sans',
      family: 'Inria Sans, sans-serif',
    },
    secondaryFont: {
      url: 'https://fonts.googleapis.com/css?family=Inria+Sans',
      family: 'Inria Sans, sans-serif',
    },
    gtm: {
      gtmId: 'GTM-K76BJZZ',
    },
    showPoweredByRally: false,
  },
  spark: {
    palette: {
      primary: {
        light: '#1076BC',
        main: '#1076BC',
        dark: '#1076BC',
        contrastText: '#fff',
      },
      secondary: {
        light: '#F4C455',
        main: '#EEBB44',
        dark: '#EEBB44',
        contrastText: '#fff',
      },
      gray: {
        light: '#54595f',
        main: '#54595f',
        dark: '#4d5259',
      },
    },
    splash: {
      image: SparkLoginSplashImage,
      backgroundSize: 'cover',
      logoContainerStyle: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        paddingBottom: '49px',
        marginRight: '24px',
      },
    },
    logo: {
      dark: SparkLogoDark,
      light: SparkLogoLight,
      width: '200px',
      topBarHeight: '60px',
    },
    stylesheet: {},
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    favicon: SparkFavicon,
    title: 'Spark Law',
    showPoweredByRally: false,
  },
  emerge: {
    palette: {
      primary: {
        light: '#000',
        main: '#000',
        dark: '#000',
        contrastText: '#fff',
      },
      secondary: {
        light: '#FF5A4E',
        main: '#FF5A4E',
        dark: '#FF5A4E',
        contrastText: '#fff',
      },
      gray: {
        light: '#8E8E8E',
        main: '#8E8E8E',
        dark: '#4d5259',
      },
    },
    splash: {
      image: EmergeLoginSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: EmergeLogo,
      width: '200px',
      topBarHeight: '20px',
    },
    stylesheet: {},
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    favicon: EmergeFavicon,
    title: 'Emerge',
  },
  emeraldlaw: {
    palette: {
      primary: {
        light: '#0FA153',
        main: '#088B45',
        dark: '#088B45',
        contrastText: '#fff',
      },
      secondary: {
        light: '#FF5A4E',
        main: '#FF5A4E',
        dark: '#FF5A4E',
        contrastText: '#fff',
      },
      gray: {
        light: '#8E8E8E',
        main: '#8E8E8E',
        dark: '#4d5259',
      },
    },
    splash: {
      image: EmeraldLoginSplashImage,
      backgroundSize: 'contain',
    },
    logo: {
      dark: EmeraldLogo,
      width: '200px',
      topBarHeight: '40px',
    },
    stylesheet: {},
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    favicon: EmeraldFavicon,
    title: 'Emerald Law',
    showPoweredByRally: false,
  },
  toslawyer: {
    palette: {
      primary: {
        light: '#cc0021',
        main: '#cc0021',
        dark: '#cc0021',
        contrastText: '#fff',
      },
      secondary: {
        light: '#f7ca18',
        main: '#f7ca18',
        dark: '#f7ca18',
        contrastText: '#fff',
      },
      gray: {
        light: '#8E8E8E',
        main: '#8E8E8E',
        dark: '#4d5259',
      },
    },
    splash: {
      backgroundColor: 'rgba(0, 0, 0, 0.85)',
      backgroundSize: 'cover',
      logoContainerStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '45px',
      },
    },
    logo: {
      dark: TOSLawyerLogoDark,
      light: TOSLawyerLogoLight,
      topBarHeight: '30px',
    },
    stylesheet: {},
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    favicon: TOSLawyerFavicon,
    title: 'TOS Lawyer',
    showPoweredByRally: false,
  },
  reedmanlaw: {
    palette: {
      primary: {
        light: '#333',
        main: '#333',
        dark: '#333',
        contrastText: '#fff',
      },
      secondary: {
        light: '#333',
        main: '#333',
        dark: '#333',
        contrastText: '#fff',
      },
      gray: {
        light: '#8E8E8E',
        main: '#8E8E8E',
        dark: '#4d5259',
      },
    },
    splash: {
      image: ReedmanLawLoginSplashImage,
      backgroundSize: 'cover',
      logoContainerStyle: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '45px',
      },
    },
    logo: {
      dark: ReedmanLawLogoDark,
      light: ReedmanLawLogoLight,
      topBarHeight: '40px',
      width: '280px',
    },
    stylesheet: {},
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    favicon: ReedmanLawFavicon,
    title: 'Reedman Law',
  },
  startupprogram: {
    palette: {
      primary: {
        light: '#0D3D54',
        main: '#0D3D54',
        dark: '#0D3D54',
        contrastText: '#fff',
      },
      secondary: {
        light: '#E88100',
        main: '#E88100',
        dark: '#E88100',
        contrastText: '#fff',
      },
      gray: {
        light: '#8E8E8E',
        main: '#8E8E8E',
        dark: '#4d5259',
      },
    },
    splash: {
      image: StartupProgramLoginSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: StartupProgramLogoDark,
      topBarHeight: '40px',
      width: '280px',
    },
    stylesheet: {},
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    favicon: StartupProgramFavicon,
    title: 'Startup Program',
    showPoweredByRally: false,
    customNavItem: {
      label: 'SUP Academy',
      href: 'https://startupprogram.thinkific.com/enrollments',
    },
    clientIntercomId: 'dk99zxqw',
  },
  provantagecapital: {
    palette: {
      primary: {
        light: '#1A2E46',
        main: '#1A2E46',
        dark: '#1A2E46',
        contrastText: '#fff',
      },
      secondary: {
        light: '#0086EB',
        main: '#0086EB',
        dark: '#0086EB',
        contrastText: '#fff',
      },
      gray: {
        light: '#8E8E8E',
        main: '#8E8E8E',
        dark: '#4d5259',
      },
    },
    splash: {
      image: ProvantageLoginSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: ProvantageLogoDark,
      topBarHeight: '50px',
    },
    stylesheet: {},
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    favicon: ProvantageFavicon,
    title: 'Provantage Capital',
    showPoweredByRally: false,
  },
  simplelivingtrusts: {
    palette: {
      primary: {
        light: '#FF3B3F',
        main: '#FF3B3F',
        dark: '#FF3B3F',
        contrastText: '#fff',
      },
      secondary: {
        light: '#324158',
        main: '#324158',
        dark: '#324158',
        contrastText: '#fff',
      },
      gray: {
        light: '#8F8F8F',
        main: '#8F8F8F',
        dark: '#606060',
      },
    },
    splash: {
      image: SimpleLivingLoginSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: SimpleLivingLogoDark,
      topBarHeight: '60px',
    },
    stylesheet: {},
    clientSubmissionConfirmation:
      'We will email you within two business days to notify you once your documents are uploaded into your Client Portal. If you would like to return to our main site, a tab should already be open, or you can go to <a target="_blank" href="https://www.SimpleLivingTrusts.com">www.SimpleLivingTrusts.com</a>. If you have any questions, please feel free to email us at <a href="mailto:CustomerService@SimpleLivingTrusts.com">CustomerService@SimpleLivingTrusts.com</a>.',
    favicon: SimpleLivingFavicon,
    title: 'Simple Living Trusts',
    showPoweredByRally: false,
  },
  nantzlaw: {
    palette: {
      primary: {
        light: '#EB1D24',
        main: '#EB1D24',
        dark: '#EB1D24',
        contrastText: '#fff',
      },
      secondary: {
        light: '#000000',
        main: '#000000',
        dark: '#000000',
        contrastText: '#fff',
      },
      gray: {
        light: '#8F8F8F',
        main: '#8F8F8F',
        dark: '#606060',
      },
    },
    splash: {
      image: NantzLawLoginSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: NantzLawLogoDark,
      topBarHeight: '30px',
    },
    stylesheet: {},
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    favicon: NantzLawFavicon,
    title: 'Nantz Law',
    showPoweredByRally: false,
    gtm: {
      gtmId: 'GTM-59MRXH8',
    },
  },
  radiuslaw: {
    palette: {
      primary: {
        light: '#346B9E',
        main: '#346B9E',
        dark: '#346B9E',
        contrastText: '#fff',
      },
      secondary: {
        light: '#00a0da',
        main: '#00a0da',
        dark: '#00a0da',
        contrastText: '#fff',
      },
      gray: {
        light: '#8F8F8F',
        main: '#8F8F8F',
        dark: '#606060',
      },
    },
    splash: {
      image: RadiusLawLoginSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: RadiusLawLogoDark,
      topBarHeight: '35px',
    },
    stylesheet: {},
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    favicon: RadiusLawFavicon,
    title: 'Radius Law',
    showPoweredByRally: false,
  },
  planwell: {
    palette: {
      primary: {
        light: '#3F92E0',
        main: '#3F92E0',
        dark: '#3F92E0',
        contrastText: '#fff',
      },
      secondary: {
        light: '#3F92E0',
        main: '#3F92E0',
        dark: '#3F92E0',
        contrastText: '#fff',
      },
    },
    splash: {
      image: PlanWellSplashImage,
      backgroundSize: 'contain',
      backgroundColor: '#D9ECFF',
    },
    logo: {
      dark: PlanWellLogoDark,
      topBarHeight: '45px',
    },
    stylesheet: {},
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    favicon: PlanWellFavicon,
    title: 'Plan Well Legal',
    showPoweredByRally: false,
  },
  trustlegalgroup: {
    palette: {
      primary: {
        light: '#ED1566',
        main: '#ED1566',
        dark: '#ED1566',
        contrastText: '#fff',
      },
      secondary: {
        light: '#00305B',
        main: '#00305B',
        dark: '#00305B',
        contrastText: '#fff',
      },
      gray: {
        light: '#8F8F8F',
        main: '#8F8F8F',
        dark: '#606060',
      },
    },
    splash: {
      image: TrustLegalGroupSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: TrustLegalGroupLogoDark,
      topBarHeight: '30px',
    },
    stylesheet: {},
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    favicon: TrustLegalGroupFavicon,
    title: 'Trust Legal Group',
    showPoweredByRally: false,
  },
  atlasbizdocs: {
    palette: {
      primary: {
        light: '#1F4E70',
        main: '#1F4E70',
        dark: '#1F4E70',
        contrastText: '#fff',
      },
      secondary: {
        light: '#5997A6',
        main: '#5997A6',
        dark: '#5997A6',
        contrastText: '#fff',
      },
    },
    splash: {
      image: AtlasBizDocsSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: AtlasBizDocsGroupLogoDark,
      topBarHeight: '50px',
    },
    stylesheet: {},
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    favicon: AtlasBizDocsGroupFavicon,
    title: 'Atlas Biz Docs',
    showPoweredByRally: false,
  },
  jvastilaw: {
    palette: {
      primary: {
        light: '#1A43A7',
        main: '#1A43A7',
        dark: '#1A43A7',
        contrastText: '#fff',
      },
      secondary: {
        light: '#75172e',
        main: '#75172e',
        dark: '#75172e',
        contrastText: '#fff',
      },
    },
    splash: {
      image: VastiLawSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: VastiLawLogoDark,
      topBarHeight: '50px',
    },
    stylesheet: {},
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    favicon: VastiLawFavicon,
    title: 'Vasti Law',
    showPoweredByRally: false,
  },
  coxandpalmer: {
    palette: {
      primary: {
        light: '#e9292f',
        main: '#e9292f',
        dark: '#e9292f',
        contrastText: '#fff',
      },
      secondary: {
        light: '#555',
        main: '#555',
        dark: '#555',
        contrastText: '#fff',
      },
    },
    splash: {
      image: CoxAndPalmerSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: CoxAndPalmerLogoDark,
      topBarHeight: '25px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    favicon: CoxAndPalmerFavicon,
    title: 'Cox & Palmer',
  },
  hastingsnclaw: {
    palette: {
      primary: {
        light: '#499ef2',
        main: '#499ef2',
        dark: '#499ef2',
        contrastText: '#fff',
      },
      secondary: {
        light: '#333059',
        main: '#333059',
        dark: '#333059',
        contrastText: '#fff',
      },
    },
    splash: {
      image: HastingsLawSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: HastingsLawLogoDark,
      topBarHeight: '45px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    favicon: HastingsLawFavicon,
    title: 'Hastings Law',
  },
  comlaw: {
    palette: {
      primary: {
        light: '#8D734A',
        main: '#8D734A',
        dark: '#8D734A',
        contrastText: '#fff',
      },
      secondary: {
        light: '#8D734A',
        main: '#8D734A',
        dark: '#8D734A',
        contrastText: '#fff',
      },
    },
    splash: {
      image: ComLawSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: ComLawLogoDark,
      topBarHeight: '45px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    favicon: ComLawFavicon,
    title: 'Com Law',
    calendlyLink: 'https://calendly.com/comlaw/initial-call',
  },
  wedefendyou: {
    palette: {
      primary: {
        light: '#23135c',
        main: '#23135c',
        dark: '#23135c',
        contrastText: '#fff',
      },
      secondary: {
        light: '#857140',
        main: '#857140',
        dark: '#857140',
        contrastText: '#fff',
      },
    },
    splash: {
      image: WeDefendYouSplashImage,
      backgroundSize: 'cover',
      logoContainerStyle: {
        display: 'flex',
        flexDirection: 'column-reverse',
        paddingBottom: '25px',
        width: '60%',
        minWidth: '440px',
      },
    },
    logo: {
      dark: WeDefendYouLogoDark,
      light: WeDefendYouLogoDark,
      topBarHeight: '40px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    favicon: WeDefendYouFavicon,
    title: 'We Defend You',
  },
  velnlegal: {
    palette: {
      primary: {
        light: '#FF0000',
        main: '#FF0000',
        dark: '#FF0000',
        contrastText: '#fff',
      },
      secondary: {
        light: '#232323',
        main: '#232323',
        dark: '#232323',
        contrastText: '#fff',
      },
    },
    splash: {
      image: VelnSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: VelnLogoDark,
      topBarHeight: '55px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    favicon: VelnFavicon,
    title: 'VELN',
  },
  caravellaw: {
    palette: {
      primary: {
        light: '#009ecb',
        main: '#009ecb',
        dark: '#009ecb',
        contrastText: '#fff',
      },
      secondary: {
        light: '#374054',
        main: '#374054',
        dark: '#374054',
        contrastText: '#fff',
      },
    },
    splash: {
      image: CaravelLawSplashImage,
      backgroundSize: 'cover',
      logoContainerStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    logo: {
      dark: CaravelLawLogoDark,
      light: CaravelLawLogoLight,
      topBarHeight: '20px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    favicon: CaravelLawFavicon,
    title: 'Caravel Law',
  },
  cohenhighley: {
    palette: {
      primary: {
        light: '#CA4700',
        main: '#CA4700',
        dark: '#CA4700',
        contrastText: '#fff',
      },
      secondary: {
        light: '#003354',
        main: '#003354',
        dark: '#003354',
        contrastText: '#fff',
      },
    },
    splash: {
      image: CohenHighleySplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: CohenHighleyLogoDark,
      topBarHeight: '45px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    favicon: CohenHighleyFavicon,
    title: 'Cohen Highley Law',
  },
  pjmfirm: {
    palette: {
      primary: {
        light: '#9C1616',
        main: '#9C1616',
        dark: '#9C1616',
        contrastText: '#fff',
      },
      secondary: {
        light: '#1C335A',
        main: '#1C335A',
        dark: '#1C335A',
        contrastText: '#fff',
      },
    },
    splash: {
      image: MonahanLawFirmSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: MonahanLawFirmLogoDark,
      topBarHeight: '60px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    favicon: MonahanLawFirmFavicon,
    title: 'Monahan Law Firm',
  },
  counselexpress: {
    palette: {
      primary: {
        light: '#c11b1b',
        main: '#c11b1b',
        dark: '#c11b1b',
        contrastText: '#fff',
      },
      secondary: {
        light: '#1d1d1e',
        main: '#1d1d1e',
        dark: '#1d1d1e',
        contrastText: '#fff',
      },
    },
    splash: {
      image: CounselExpressSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: CounselExpressLogoDark,
      topBarHeight: '45px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    favicon: CounselExpressFavicon,
    title: 'Counsel Express',
  },
  fabianilaw: {
    palette: {
      primary: {
        light: '#5C258D',
        main: '#5C258D',
        dark: '#5C258D',
        contrastText: '#fff',
      },
      secondary: {
        light: '#4389A2',
        main: '#4389A2',
        dark: '#4389A2',
        contrastText: '#fff',
      },
    },
    splash: {
      image: FabianiLawSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: FabianiLawLogoDark,
      topBarHeight: '40px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    favicon: 'none',
    title: 'Fabiani Law Counsel',
  },
  hamiltonlaw: {
    palette: {
      primary: {
        light: '#7f1515',
        main: '#7f1515',
        dark: '#7f1515',
        contrastText: '#fff',
      },
      secondary: {
        light: '#0384d7',
        main: '#0384d7',
        dark: '#0384d7',
        contrastText: '#fff',
      },
      gray: {
        light: '#54595f',
        main: '#54595f',
        dark: '#4d5259',
      },
    },
    splash: {
      image: HamiltonLawSplashImage,
      backgroundSize: 'cover',
      logoContainerStyle: {
        display: 'flex',
        flexDirection: 'column-reverse',
        paddingBottom: '25px',
        width: '60%',
        minWidth: '440px',
      },
    },
    logo: {
      dark: HamiltonLawLogoDark,
      light: HamiltonLawLogoDark,
      topBarHeight: '50px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Hamilton Law, PLC',
    favicon: HamiltonLawFavicon,
  },
  snydermanlawgroup: {
    palette: {
      primary: {
        light: '#ed703e',
        main: '#ed703e',
        dark: '#ed703e',
        contrastText: '#fff',
      },
      secondary: {
        light: '#353535',
        main: '#353535',
        dark: '#353535',
        contrastText: '#fff',
      },
    },
    splash: {
      image: SnydermanSplashImage,
      backgroundSize: 'cover',
      logoContainerStyle: {
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        minWidth: '380px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '-30px',
      },
    },
    logo: {
      dark: SnydermanLogoDark,
      light: SnydermanLogoDark,
      topBarHeight: '55px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Snyderman Law Group',
    favicon: SnydermanFavicon,
  },
  gundersonfirm: {
    palette: {
      primary: {
        light: '#47A0D8',
        main: '#47A0D8',
        dark: '#47A0D8',
        contrastText: '#fff',
      },
      secondary: {
        light: '#222842',
        main: '#222842',
        dark: '#222842',
        contrastText: '#fff',
      },
    },
    splash: {
      image: GundersonSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: GundersonLogoDark,
      topBarHeight: '40px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'The Gunderson Law Firm',
    favicon: GundersonFavicon,
  },
  brevitylegal: {
    palette: {
      primary: {
        light: '#495e79',
        main: '#495e79',
        dark: '#495e79',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ac3e6a',
        main: '#ac3e6a',
        dark: '#ac3e6a',
        contrastText: '#fff',
      },
    },
    splash: {
      image: BrevitySplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: BrevityLogoDark,
      topBarHeight: '40px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Brevity Legal',
    favicon: BrevityFavicon,
  },
  estateconnection: {
    palette: {
      primary: {
        light: '#009e8d',
        main: '#009e8d',
        dark: '#009e8d',
        contrastText: '#fff',
      },
      secondary: {
        light: '#4080E0',
        main: '#4080E0',
        dark: '#4080E0',
        contrastText: '#fff',
      },
    },
    splash: {
      image: EstateConnectionSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: EstateConnectionLogoDark,
      topBarHeight: '45px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Estate Connection',
    favicon: EstateConnectionFavicon,
  },
  paramountpropertytaxappeal: {
    palette: {
      primary: {
        light: '#154174',
        main: '#154174',
        dark: '#154174',
        contrastText: '#fff',
      },
      secondary: {
        light: '#4080E0',
        main: '#4080E0',
        dark: '#4080E0',
        contrastText: '#fff',
      },
    },
    splash: {
      image: PPTaxSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: PPTaxLogoDark,
      topBarHeight: '45px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Paramount Property Tax Appeal',
    favicon: PPTaxFavicon,
  },
  nano: {
    palette: {
      primary: {
        light: '#256ac8',
        main: '#256ac8',
        dark: '#256ac8',
        contrastText: '#fff',
      },
      secondary: {
        light: '#fdb412',
        main: '#fdb412',
        dark: '#fdb412',
        contrastText: '#fff',
      },
    },
    splash: {
      image: NanoSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: NanoLogoDark,
      topBarHeight: '45px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Nano Law',
    favicon: NanoFavicon,
  },
  provenresource: {
    palette: {
      primary: {
        light: '#081D3D',
        main: '#081D3D',
        dark: '#081D3D',
        contrastText: '#fff',
      },
      secondary: {
        light: '#2B87DA',
        main: '#2B87DA',
        dark: '#2B87DA',
        contrastText: '#fff',
      },
    },
    splash: {
      image: SobleSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: SobleLogoDark,
      topBarHeight: '55px',
    },
    clientSubmissionConfirmation:
      'Thank you for submitting the questionnaire for your request.  An attorney will review your information and your requested document before releasing it back to you for your use.<br/><br/>Your expected turnaround time is no greater than 5 hours from the time of your submission.  Submissions after 5 p.m. EST are reviewed and released on the next business day.',
    title: 'Soble Law',
    favicon: SobleFavicon,
  },
  justcalljodiinc: {
    palette: {
      primary: {
        light: '#7f40b0',
        main: '#7f40b0',
        dark: '#7f40b0',
        contrastText: '#fff',
      },
      secondary: {
        light: '#2B87DA',
        main: '#2B87DA',
        dark: '#2B87DA',
        contrastText: '#fff',
      },
    },
    splash: {
      image: JustCallJodiSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: JustCallJodiLogoDark,
      topBarHeight: '60px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Just Call Jodi Inc.',
    favicon: JustCallJodiFavicon,
  },
  bsloanlaw: {
    palette: {
      primary: {
        light: '#4f2c86',
        main: '#4f2c86',
        dark: '#4f2c86',
        contrastText: '#fff',
      },
      secondary: {
        light: '#4f2c86',
        main: '#4f2c86',
        dark: '#4f2c86',
        contrastText: '#fff',
      },
    },
    splash: {
      image: BSloanSplashImage,
      backgroundSize: 'cover',
      logoContainerStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    logo: {
      dark: BSloanLogoDark,
      light: BSloanLogoDark,
      topBarHeight: '55px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'B Sloan Law',
    favicon: BSloanFavicon,
  },
  howelllegal: {
    palette: {
      primary: {
        light: '#001a71',
        main: '#001a71',
        dark: '#001a71',
        contrastText: '#fff',
      },
      secondary: {
        light: '#009ade',
        main: '#009ade',
        dark: '#009ade',
        contrastText: '#fff',
      },
    },
    splash: {
      image: HowellSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: HowellLogoDark,
      topBarHeight: '50px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Howell Legal',
  },
  jennacomm: {
    palette: {
      primary: {
        light: '#fe644e',
        main: '#fe644e',
        dark: '#fe644e',
        contrastText: '#fff',
      },
      secondary: {
        light: '#4c4c4c',
        main: '#4c4c4c',
        dark: '#4c4c4c',
        contrastText: '#fff',
      },
    },
    splash: {
      image: JennaCommSplashImage,
      backgroundSize: 'cover',
      backgroundColor: 'black',
    },
    logo: {
      dark: JennaCommLogoDark,
      topBarHeight: '55px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Jenna Communications',
    favicon: JennaCommFavicon,
  },
  shantelllaw: {
    palette: {
      primary: {
        light: '#955E2A',
        main: '#955E2A',
        dark: '#955E2A',
        contrastText: '#fff',
      },
      secondary: {
        light: '#955E2A',
        main: '#955E2A',
        dark: '#955E2A',
        contrastText: '#fff',
      },
    },
    splash: {
      image: ShantellSplashImage,
      backgroundSize: 'cover',
      backgroundColor: 'black',
    },
    logo: {
      dark: ShantellLogoDark,
      topBarHeight: '70px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Shantell Law',
    favicon: ShantellFavicon,
  },
  lbflawfirm: {
    palette: {
      primary: {
        light: '#2056AC',
        main: '#2056AC',
        dark: '#2056AC',
        contrastText: '#fff',
      },
      secondary: {
        light: '#955E2A',
        main: '#955E2A',
        dark: '#955E2A',
        contrastText: '#fff',
      },
    },
    splash: {
      image: LBFSplashImage,
      backgroundSize: 'cover',
      backgroundColor: 'black',
    },
    logo: {
      dark: LBFLogoDark,
      topBarHeight: '75px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'L&F Brown P.C.',
    favicon: LBFFavicon,
  },
  resolutelegal: {
    palette: {
      primary: {
        light: '#3545a5',
        main: '#3545a5',
        dark: '#3545a5',
        contrastText: '#fff',
      },
      secondary: {
        light: '#3545a5',
        main: '#3545a5',
        dark: '#3545a5',
        contrastText: '#fff',
      },
    },
    splash: {
      image: ResoluteSplashImage,
      backgroundSize: 'cover',
      backgroundColor: 'black',
    },
    logo: {
      dark: ResoluteLogoDark,
      topBarHeight: '55px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Resolute Legal Services',
    favicon: ResoluteFavicon,
  },
  sgarnerlaw: {
    palette: {
      primary: {
        light: '#46107D',
        main: '#46107D',
        dark: '#46107D',
        contrastText: '#fff',
      },
      secondary: {
        light: '#46107D',
        main: '#46107D',
        dark: '#46107D',
        contrastText: '#fff',
      },
    },
    splash: {
      image: SoroyaSplashImage,
      backgroundSize: 'cover',
      backgroundColor: 'black',
    },
    logo: {
      dark: SoroyaLogoDark,
      topBarHeight: '55px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Soroya Garner Law, LLC',
    calendlyLink: 'https://calendly.com/sgarnerlaw/consultation',
  },
  manriquelawgroup: {
    palette: {
      primary: {
        light: '#0E2547',
        main: '#0E2547',
        dark: '#0E2547',
        contrastText: '#fff',
      },
      secondary: {
        light: '#e54a35',
        main: '#e54a35',
        dark: '#e54a35',
        contrastText: '#fff',
      },
    },
    splash: {
      image: ManriqueSplashImage,
      backgroundSize: 'cover',
      backgroundColor: 'black',
    },
    logo: {
      dark: ManriqueLogoDark,
      topBarHeight: '55px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Manrique Law Group',
    favicon: ManriqueFavicon,
  },
  momentumlaw: {
    palette: {
      primary: {
        light: '#72A329',
        main: '#72A329',
        dark: '#72A329',
        contrastText: '#fff',
      },
      secondary: {
        light: '#5c5c5c',
        main: '#5c5c5c',
        dark: '#5c5c5c',
        contrastText: '#fff',
      },
    },
    splash: {
      image: MomentumSplashImage,
      backgroundSize: 'cover',
      backgroundColor: 'black',
    },
    logo: {
      dark: MomentumLogoDark,
      topBarHeight: '55px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Momentum Law',
    favicon: MomentumFavicon,
    meetingLink:
      'https://outlook.office365.com/owa/calendar/MomentumBusinessLaw@momentumlaw.onmicrosoft.com/bookings/',
  },
  hanchettlawfirm: {
    palette: {
      primary: {
        light: '#00305b',
        main: '#00305b',
        dark: '#00305b',
        contrastText: '#fff',
      },
      secondary: {
        light: '#00305b',
        main: '#00305b',
        dark: '#00305b',
        contrastText: '#fff',
      },
    },
    splash: {
      image: HanchettSplashImage,
      backgroundSize: 'cover',
      backgroundColor: 'black',
    },
    logo: {
      dark: HanchettLogoDark,
      topBarHeight: '55px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Hanchett Law Firm',
    favicon: HanchettFavicon,
    calendlyLink: 'https://calendly.com/paulhanchett',
  },
  ingeniolaw: {
    palette: {
      primary: {
        light: '#009478',
        main: '#009478',
        dark: '#009478',
        contrastText: '#fff',
      },
      secondary: {
        light: '#8d3a73',
        main: '#8d3a73',
        dark: '#8d3a73',
        contrastText: '#fff',
      },
    },
    splash: {
      image: IngenioSplashImage,
      backgroundSize: 'cover',
      backgroundColor: 'black',
    },
    logo: {
      dark: IngenioLogoDark,
      topBarHeight: '30px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Ingenio Law',
    favicon: IngenioFavicon,
  },
  docgovern: {
    palette: {
      primary: {
        light: '#5576ba',
        main: '#5576ba',
        dark: '#5576ba',
        contrastText: '#fff',
      },
      secondary: {
        light: '#5576ba',
        main: '#5576ba',
        dark: '#5576ba',
        contrastText: '#fff',
      },
    },
    splash: {
      image: DocGovernSplashImage,
      backgroundSize: 'cover',
      backgroundColor: 'black',
    },
    logo: {
      dark: DocGovernLogoDark,
      topBarHeight: '35px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'DocGovern',
    favicon: DocGovernFavicon,
  },
  akiwowolawgroup: {
    palette: {
      primary: {
        light: '#d92027',
        main: '#d92027',
        dark: '#d92027',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff9234',
        main: '#ff9234',
        dark: '#ff9234',
        contrastText: '#fff',
      },
    },
    splash: {
      image: AkiwowoSplashImage,
      backgroundSize: 'cover',
      backgroundColor: 'black',
    },
    logo: {
      dark: AkiwowoLogoDark,
      topBarHeight: '35px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Akiwowo Law Group',
    favicon: AkiwowoFavicon,
  },
  yodeldelosopenacho: {
    palette: {
      primary: {
        light: '#1D2541',
        main: '#1D2541',
        dark: '#1D2541',
        contrastText: '#fff',
      },
      secondary: {
        light: '#C29B59',
        main: '#C29B59',
        dark: '#C29B59',
        contrastText: '#fff',
      },
    },
    splash: {
      image: PenachoSplashImage,
      backgroundSize: 'cover',
      logoContainerStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    logo: {
      dark: PenachoLogoDark,
      light: PenachoLogoDark,
      topBarHeight: '35px',
      width: '75%',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Deloso Penacho Law',
    favicon: PenachoFavicon,
  },
  missioncounsel: {
    palette: {
      primary: {
        light: '#ba551f',
        main: '#ba551f',
        dark: '#ba551f',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ba551f',
        main: '#ba551f',
        dark: '#ba551f',
        contrastText: '#fff',
      },
    },
    splash: {
      image: MissionCounselSplashImage,
      backgroundSize: 'cover',
      backgroundColor: 'black',
    },
    logo: {
      dark: MissionCounselLogoDark,
      topBarHeight: '50px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Mission Counsel',
    favicon: MissionCounselFavicon,
    calendlyLink: 'https://calendly.com/terry-253/subscription-call',
  },
  sehgallawpc: {
    palette: {
      primary: {
        light: '#0094B4',
        main: '#0094B4',
        dark: '#0094B4',
        contrastText: '#fff',
      },
      secondary: {
        light: '#0094B4',
        main: '#0094B4',
        dark: '#0094B4',
        contrastText: '#fff',
      },
    },
    splash: {
      image: SehgalSplashImage,
      backgroundSize: 'cover',
      backgroundColor: 'black',
    },
    logo: {
      dark: SehgalLogoDark,
      topBarHeight: '35px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Sehgal Law PC',
    favicon: SehgalFavicon,
    calendlyLink: 'https://calendly.com/sehgallawpc',
  },
  gowlingwlg: {
    palette: {
      primary: {
        light: '#401C42',
        main: '#401C42',
        dark: '#401C42',
        contrastText: '#fff',
      },
      secondary: {
        light: '#E61716',
        main: '#E61716',
        dark: '#E61716',
        contrastText: '#fff',
      },
    },
    splash: {
      image: GowlingSplashImage,
      backgroundSize: 'cover',
      backgroundColor: 'black',
    },
    logo: {
      dark: GowlingLogoDark,
      topBarHeight: '55px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Gowling WLG',
    favicon: GowlingFavicon,
  },
  knaacklaw: {
    palette: {
      primary: {
        light: '#304055',
        main: '#304055',
        dark: '#304055',
        contrastText: '#fff',
      },
      secondary: {
        light: '#828e40',
        main: '#828e40',
        dark: '#828e40',
        contrastText: '#fff',
      },
    },
    splash: {
      image: KnaackSplashImage,
      backgroundSize: 'cover',
      backgroundColor: 'black',
    },
    logo: {
      dark: KnaackLogoDark,
      topBarHeight: '50px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Knaack Law',
    favicon: KnaackFavicon,
  },
  jkcoconsulting: {
    palette: {
      primary: {
        light: '#0176ff',
        main: '#0176ff',
        dark: '#0176ff',
        contrastText: '#fff',
      },
      secondary: {
        light: '#0176ff',
        main: '#0176ff',
        dark: '#0176ff',
        contrastText: '#fff',
      },
    },
    splash: {
      image: JKCoSplashImage,
      backgroundSize: 'cover',
      backgroundColor: 'black',
    },
    logo: {
      dark: JKCoLogoDark,
      topBarHeight: '50px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'JK & Co. Consulting',
    favicon: JKCoFavicon,
  },
  umbrellalaw: {
    palette: {
      primary: {
        light: '#EA3224',
        main: '#EA3224',
        dark: '#EA3224',
        contrastText: '#fff',
      },
      secondary: {
        light: '#EA3224',
        main: '#EA3224',
        dark: '#EA3224',
        contrastText: '#fff',
      },
    },
    splash: {
      image: UmbrellaSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: UmbrellaLogoDark,
      topBarHeight: '60px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Umbrella Law',
    favicon: UmbrellaFavicon,
  },
  prattaycock: {
    palette: {
      primary: {
        light: '#0D2F76',
        main: '#0D2F76',
        dark: '#0D2F76',
        contrastText: '#fff',
      },
      secondary: {
        light: '#8DC8FF',
        main: '#8DC8FF',
        dark: '#8DC8FF',
        contrastText: '#fff',
      },
    },
    splash: {
      image: PrattAycockSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: PrattAycockLogoDark,
      topBarHeight: '60px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Pratt Aycock',
    favicon: PrattAycockFavicon,
  },
  rklaw: {
    palette: {
      primary: {
        light: '#ED1C24',
        main: '#ED1C24',
        dark: '#ED1C24',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ED1C24',
        main: '#ED1C24',
        dark: '#ED1C24',
        contrastText: '#fff',
      },
    },
    splash: {
      image: RKLawSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: RKLawLogoDark,
      topBarHeight: '60px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'RK Law',
    favicon: RKLawFavicon,
  },
  coloradoparalegalgroup: {
    palette: {
      primary: {
        light: '#1C244E',
        main: '#1C244E',
        dark: '#1C244E',
        contrastText: '#fff',
      },
      secondary: {
        light: '#1C244E',
        main: '#1C244E',
        dark: '#1C244E',
        contrastText: '#fff',
      },
    },
    splash: {
      image: ColoradoSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: ColoradoLogoDark,
      topBarHeight: '60px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Colorado Paralegal Group',
    favicon: ColoradoFavicon,
  },
  emerylawfirm: {
    palette: {
      primary: {
        light: '#13394A',
        main: '#13394A',
        dark: '#13394A',
        contrastText: '#fff',
      },
      secondary: {
        light: '#13394A',
        main: '#13394A',
        dark: '#13394A',
        contrastText: '#fff',
      },
    },
    splash: {
      image: EmerySplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: EmeryLogoDark,
      topBarHeight: '40px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Emery Law Firm',
    favicon: EmeryFavicon,
    meetingLink: 'https://cozycal.com/emery-law-firm',
  },
  texascommerciallaw: {
    palette: {
      primary: {
        light: '#1565C0',
        main: '#1565C0',
        dark: '#1565C0',
        contrastText: '#fff',
      },
      secondary: {
        light: '#1565C0',
        main: '#1565C0',
        dark: '#1565C0',
        contrastText: '#fff',
      },
    },
    splash: {
      image: TexasSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: TexasLogoDark,
      topBarHeight: '50px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Texas Commercial Law',
    favicon: TexasFavicon,
  },
  thelawshop: {
    palette: {
      primary: {
        light: '#282459',
        main: '#282459',
        dark: '#282459',
        contrastText: '#fff',
      },
      secondary: {
        light: '#AE2C2B',
        main: '#AE2C2B',
        dark: '#AE2C2B',
        contrastText: '#fff',
      },
    },
    splash: {
      image: LawShopSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: LawShopLogoDark,
      topBarHeight: '30px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'The Law Shop',
    favicon: LawShopFavicon,
  },
  cas: {
    palette: {
      primary: {
        light: '#545E64',
        main: '#545E64',
        dark: '#545E64',
        contrastText: '#fff',
      },
      secondary: {
        light: '#545E64',
        main: '#545E64',
        dark: '#545E64',
        contrastText: '#fff',
      },
    },
    splash: {
      image: CASSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: CASLogoDark,
      topBarHeight: '55px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'CAS Law Firm',
    favicon: CASFavicon,
  },
  hillandlittle: {
    palette: {
      primary: {
        light: '#272727',
        main: '#272727',
        dark: '#272727',
        contrastText: '#fff',
      },
      secondary: {
        light: '#272727',
        main: '#272727',
        dark: '#272727',
        contrastText: '#fff',
      },
    },
    splash: {
      image: HillLittleSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: HillLittleLogoDark,
      topBarHeight: '60px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Hill & Little',
    favicon: HillLittleFavicon,
  },
  evlawgroup: {
    palette: {
      primary: {
        light: '#D45737',
        main: '#D45737',
        dark: '#D45737',
        contrastText: '#fff',
      },
      secondary: {
        light: '#D45737',
        main: '#D45737',
        dark: '#D45737',
        contrastText: '#fff',
      },
    },
    splash: {
      image: EVSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: EVLogoDark,
      topBarHeight: '55px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Evolutionary Ventures Law Group',
    favicon: EVFavicon,
  },
  accessiodocs: {
    palette: {
      primary: {
        light: '#5436CA',
        main: '#5436CA',
        dark: '#5436CA',
        contrastText: '#fff',
      },
      secondary: {
        light: '#5436CA',
        main: '#5436CA',
        dark: '#5436CA',
        contrastText: '#fff',
      },
    },
    splash: {
      image: AccessioDocsSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: AccessioDocsLogoDark,
      topBarHeight: '40px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'AccessioDocs',
    favicon: AccessioDocsFavicon,
  },
  mytrustcase: {
    palette: {
      primary: {
        light: '#8785A0',
        main: '#8785A0',
        dark: '#8785A0',
        contrastText: '#fff',
      },
      secondary: {
        light: '#8785A0',
        main: '#8785A0',
        dark: '#8785A0',
        contrastText: '#fff',
      },
    },
    splash: {
      image: LisaSutherlandSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: LisaSutherlandLogoDark,
      topBarHeight: '50px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Lisa Sutherland Law',
    favicon: LisaSutherlandFavicon,
  },
  konglaw: {
    palette: {
      primary: {
        light: '#c12325',
        main: '#c12325',
        dark: '#c12325',
        contrastText: '#fff',
      },
      secondary: {
        light: '#c12325',
        main: '#c12325',
        dark: '#c12325',
        contrastText: '#fff',
      },
    },
    splash: {
      image: KongLawSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: KongLawLogoDark,
      topBarHeight: '50px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Kong Law',
    favicon: KongLawFavicon,
    meetingLink: 'https://www.kong-law.com/calendar',
  },
  onelawpc: {
    palette: {
      primary: {
        light: '#8e8e8e',
        main: '#8e8e8e',
        dark: '#8e8e8e',
        contrastText: '#fff',
      },
      secondary: {
        light: '#8e8e8e',
        main: '#8e8e8e',
        dark: '#8e8e8e',
        contrastText: '#fff',
      },
    },
    splash: {
      image: OneLawSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: OneLawLogoDark,
      topBarHeight: '25px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'One Law PC',
    favicon: OneLawFavicon,
    calendlyLink: 'https://calendly.com/onelawlit',
  },
  hrutkaylaw: {
    palette: {
      primary: {
        light: '#8e3f54',
        main: '#8e3f54',
        dark: '#8e3f54',
        contrastText: '#fff',
      },
      secondary: {
        light: '#8e3f54',
        main: '#8e3f54',
        dark: '#8e3f54',
        contrastText: '#fff',
      },
    },
    splash: {
      image: HrutkaySplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: HrutkayLogoDark,
      topBarHeight: '35px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Hrutkay Law',
    favicon: HrutkayFavicon,
  },
  mkm: {
    palette: {
      primary: {
        light: '#5A325F',
        main: '#5A325F',
        dark: '#5A325F',
        contrastText: '#fff',
      },
      secondary: {
        light: '#8A8C8E',
        main: '#8A8C8E',
        dark: '#8A8C8E',
        contrastText: '#fff',
      },
    },
    splash: {
      image: MKMSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: MKMLogoDark,
      topBarHeight: '55px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'MKM Law',
    favicon: MKMFavicon,
    calendlyLink: 'https://calendly.com/josh_marcus',
  },
  ayahvirtualservices: {
    palette: {
      primary: {
        light: '#03a5b8',
        main: '#03a5b8',
        dark: '#03a5b8',
        contrastText: '#fff',
      },
      secondary: {
        light: '#caf3f5',
        main: '#caf3f5',
        dark: '#caf3f5',
        contrastText: '#fff',
      },
    },
    splash: {
      image: AyahSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: AyahLogoDark,
      topBarHeight: '25px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Ayah Virtual Services',
    favicon: AyahFavicon,
  },
  elitelegal: {
    palette: {
      primary: {
        light: '#9D7C48',
        main: '#9D7C48',
        dark: '#9D7C48',
        contrastText: '#fff',
      },
      secondary: {
        light: '#9D7C48',
        main: '#9D7C48',
        dark: '#9D7C48',
        contrastText: '#fff',
      },
    },
    splash: {
      image: EliteMediationSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: EliteMediationLogoDark,
      topBarHeight: '60px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Elite Mediation',
    favicon: EliteMediationFavicon,
    calendlyLink: 'https://calendly.com/elitelegal/15min',
  },
  sullivanlt: {
    palette: {
      primary: {
        light: '#004890',
        main: '#004890',
        dark: '#004890',
        contrastText: '#fff',
      },
      secondary: {
        light: '#004890',
        main: '#004890',
        dark: '#004890',
        contrastText: '#fff',
      },
    },
    splash: {
      image: SullivanLTSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: SullivanLTLogoDark,
      topBarHeight: '45px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Sullivan Law & Tax',
    favicon: SullivanLTFavicon,
  },
  nortonrosefulbright: {
    palette: {
      primary: {
        light: '#dd2720',
        main: '#dd2720',
        dark: '#dd2720',
        contrastText: '#fff',
      },
      secondary: {
        light: '#dd2720',
        main: '#dd2720',
        dark: '#dd2720',
        contrastText: '#fff',
      },
    },
    splash: {
      image: NortonRoseSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: NortonRoseLogoDark,
      topBarHeight: '25px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Norton Rose Fulbright',
    favicon: NortonRoseFavicon,
  },
  paytonlegal: {
    palette: {
      primary: {
        light: '#BE1E2D',
        main: '#BE1E2D',
        dark: '#BE1E2D',
        contrastText: '#fff',
      },
      secondary: {
        light: '#BE1E2D',
        main: '#BE1E2D',
        dark: '#BE1E2D',
        contrastText: '#fff',
      },
    },
    splash: {
      image: PaytonSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: PaytonLogoDark,
      topBarHeight: '60px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Payton Jones',
    favicon: PaytonFavicon,
  },
  thebplawgroup: {
    palette: {
      primary: {
        light: '#BE9C47',
        main: '#BE9C47',
        dark: '#BE9C47',
        contrastText: '#fff',
      },
      secondary: {
        light: '#BE9C47',
        main: '#BE9C47',
        dark: '#BE9C47',
        contrastText: '#fff',
      },
    },
    splash: {
      image: BPLawGroupSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: BPLawGroupLogoDark,
      topBarHeight: '50px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'BP Law Group',
    favicon: BPLawGroupFavicon,
  },
  wallslegalservices: {
    palette: {
      primary: {
        light: '#7a2117',
        main: '#7a2117',
        dark: '#7a2117',
        contrastText: '#fff',
      },
      secondary: {
        light: '#7a2117',
        main: '#7a2117',
        dark: '#7a2117',
        contrastText: '#fff',
      },
    },
    splash: {
      image: WallsGroupSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: WallsGroupLogoDark,
      topBarHeight: '25px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Walls Legal Services',
    favicon: WallsGroupFavicon,
  },
  harrisaccounting: {
    palette: {
      primary: {
        light: '#E83646',
        main: '#E83646',
        dark: '#E83646',
        contrastText: '#fff',
      },
      secondary: {
        light: '#E83646',
        main: '#E83646',
        dark: '#E83646',
        contrastText: '#fff',
      },
    },
    splash: {
      image: HarrisAccountingSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: HarrisAccountingLogoDark,
      topBarHeight: '55px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Harris Accounting',
    favicon: HarrisAccountingFavicon,
  },
  insightlegal: {
    palette: {
      primary: {
        light: '#034F9A',
        main: '#034F9A',
        dark: '#034F9A',
        contrastText: '#fff',
      },
      secondary: {
        light: '#034F9A',
        main: '#034F9A',
        dark: '#034F9A',
        contrastText: '#fff',
      },
    },
    splash: {
      image: InsightLegalSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: InsightLegalLogoDark,
      topBarHeight: '50px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Insight Legal',
    favicon: InsightLegalFavicon,
    calendlyLink: 'https://calendly.com/insightlegal',
  },
  d2law: {
    palette: {
      primary: {
        light: '#6FA333',
        main: '#6FA333',
        dark: '#6FA333',
        contrastText: '#fff',
      },
      secondary: {
        light: '#6FA333',
        main: '#6FA333',
        dark: '#6FA333',
        contrastText: '#fff',
      },
    },
    splash: {
      image: D2SplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: D2LogoDark,
      topBarHeight: '25px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'D2Law',
    favicon: D2Favicon,
    calendlyLink: 'https://calendly.com/anatoly-1/meeting-with-anatoly',
  },
  jflclaw: {
    palette: {
      primary: {
        light: '#0075C1',
        main: '#0075C1',
        dark: '#0075C1',
        contrastText: '#fff',
      },
      secondary: {
        light: '#0075C1',
        main: '#0075C1',
        dark: '#0075C1',
        contrastText: '#fff',
      },
    },
    splash: {
      image: FowlerSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: FowlerLogoDark,
      topBarHeight: '70px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Jonathan Fowler Law Group',
    favicon: FowlerFavicon,
  },
  lockedandlawyered: {
    palette: {
      primary: {
        light: '#C0A76E',
        main: '#C0A76E',
        dark: '#C0A76E',
        contrastText: '#fff',
      },
      secondary: {
        light: '#C0A76E',
        main: '#C0A76E',
        dark: '#C0A76E',
        contrastText: '#fff',
      },
    },
    splash: {
      image: GrayLegalSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: GrayLegalLogoDark,
      topBarHeight: '45px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Gray Legal',
    favicon: GrayLegalFavicon,
  },
  goldmanlawatx: {
    palette: {
      primary: {
        light: '#a1c29d',
        main: '#a1c29d',
        dark: '#a1c29d',
        contrastText: '#fff',
      },
      secondary: {
        light: '#a1c29d',
        main: '#a1c29d',
        dark: '#a1c29d',
        contrastText: '#fff',
      },
    },
    splash: {
      image: GoldmanSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: GoldmanLogoDark,
      topBarHeight: '65px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Goldman Law Group',
    favicon: GoldmanFavicon,
    calendlyLink: 'https://calendly.com/goldmanlaw',
  },
  davidiplaw: {
    palette: {
      primary: {
        light: '#152F54',
        main: '#152F54',
        dark: '#152F54',
        contrastText: '#fff',
      },
      secondary: {
        light: '#152F54',
        main: '#152F54',
        dark: '#152F54',
        contrastText: '#fff',
      },
    },
    splash: {
      image: DavidIPSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: DavidIPLogoDark,
      topBarHeight: '40px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'David IP Law',
    favicon: DavidIPFavicon,
    calendlyLink: 'https://calendly.com/davidiplaw',
  },
  readysetlegal: {
    palette: {
      primary: {
        light: '#1F628E',
        main: '#1F628E',
        dark: '#1F628E',
        contrastText: '#fff',
      },
      secondary: {
        light: '#1F628E',
        main: '#1F628E',
        dark: '#1F628E',
        contrastText: '#fff',
      },
    },
    splash: {
      image: ReadySetSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: ReadySetLogoDark,
      topBarHeight: '40px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Ready Set Legal',
    favicon: ReadySetFavicon,
  },
  galuppolaw: {
    palette: {
      primary: {
        light: '#224899',
        main: '#224899',
        dark: '#224899',
        contrastText: '#fff',
      },
      secondary: {
        light: '#224899',
        main: '#224899',
        dark: '#224899',
        contrastText: '#fff',
      },
    },
    splash: {
      image: GaluppoSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: GaluppoLogoDark,
      topBarHeight: '55px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Galuppo Law',
    favicon: GaluppoFavicon,
  },
  ratellelaw: {
    palette: {
      primary: {
        light: '#DD3726',
        main: '#DD3726',
        dark: '#DD3726',
        contrastText: '#fff',
      },
      secondary: {
        light: '#DD3726',
        main: '#DD3726',
        dark: '#DD3726',
        contrastText: '#fff',
      },
    },
    splash: {
      image: RatelleSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: RatelleLogoDark,
      topBarHeight: '55px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Ratelle Law',
    favicon: RatelleFavicon,
  },
  emilyhickslaw: {
    palette: {
      primary: {
        light: '#27AAE1',
        main: '#27AAE1',
        dark: '#27AAE1',
        contrastText: '#fff',
      },
      secondary: {
        light: '#27AAE1',
        main: '#27AAE1',
        dark: '#27AAE1',
        contrastText: '#fff',
      },
    },
    splash: {
      image: EmilyHicksSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: EmilyHicksLogoDark,
      topBarHeight: '40px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Emily Hicks Law',
    favicon: EmilyHicksFavicon,
  },
  goldinlaw: {
    palette: {
      primary: {
        light: '#214F7A',
        main: '#214F7A',
        dark: '#214F7A',
        contrastText: '#fff',
      },
      secondary: {
        light: '#214F7A',
        main: '#214F7A',
        dark: '#214F7A',
        contrastText: '#fff',
      },
    },
    splash: {
      image: GoldinSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: GoldinLogoDark,
      topBarHeight: '65px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Goldin Law',
    favicon: GoldinFavicon,
  },
  '1800lawyer0': {
    palette: {
      primary: {
        light: '#3B85DF',
        main: '#3B85DF',
        dark: '#3B85DF',
        contrastText: '#fff',
      },
      secondary: {
        light: '#3B85DF',
        main: '#3B85DF',
        dark: '#3B85DF',
        contrastText: '#fff',
      },
    },
    splash: {
      image: RightLegalSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: RightLegalLogoDark,
      topBarHeight: '72px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: '1-800-Lawyer-0',
    favicon: RightLegalFavicon,
    calendlyLink: 'https://calendly.com/1800lawyer0/1-800-lawyer-0-contact',
  },
  silklegal: {
    palette: {
      primary: {
        light: '#196BA0',
        main: '#196BA0',
        dark: '#196BA0',
        contrastText: '#fff',
      },
      secondary: {
        light: '#408779',
        main: '#408779',
        dark: '#408779',
        contrastText: '#fff',
      },
    },
    splash: {
      image: SilkSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: SilkLogoDark,
      topBarHeight: '55px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Silk Legal',
    favicon: SilkFavicon,
  },
  mlesquire: {
    palette: {
      primary: {
        light: '#386F9B',
        main: '#386F9B',
        dark: '#386F9B',
        contrastText: '#fff',
      },
      secondary: {
        light: '#386F9B',
        main: '#386F9B',
        dark: '#386F9B',
        contrastText: '#fff',
      },
    },
    splash: {
      image: MarsicanoSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: MarsicanoLogoDark,
      topBarHeight: '55px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Marsicano + Leyva',
    favicon: MarsicanoFavicon,
  },
  pixellaw: {
    palette: {
      primary: {
        light: '#B469E0',
        main: '#B469E0',
        dark: '#B469E0',
        contrastText: '#fff',
      },
      secondary: {
        light: '#FF635A',
        main: '#FF635A',
        dark: '#FF635A',
        contrastText: '#fff',
      },
    },
    splash: {
      image: PixelLawSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: PixelLawLogoDark,
      topBarHeight: '30px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Pixel Law',
    favicon: PixelLawFavicon,
  },
  wicklegal: {
    palette: {
      primary: {
        light: '#517CA4',
        main: '#517CA4',
        dark: '#517CA4',
        contrastText: '#fff',
      },
      secondary: {
        light: '#517CA4',
        main: '#517CA4',
        dark: '#517CA4',
        contrastText: '#fff',
      },
    },
    splash: {
      image: WickSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: WickLogoDark,
      topBarHeight: '75px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Wick Legal',
    favicon: WickFavicon,
    calendlyLink: 'https://calendly.com/paw-2/15min',
  },
  gallettalaw: {
    palette: {
      primary: {
        light: '#001425',
        main: '#001425',
        dark: '#001425',
        contrastText: '#fff',
      },
      secondary: {
        light: '#001425',
        main: '#001425',
        dark: '#001425',
        contrastText: '#fff',
      },
    },
    splash: {
      image: GallettaSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: GallettaLogoDark,
      topBarHeight: '45px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Galletta Law',
    favicon: GallettaFavicon,
  },
  tankellawgroup: {
    palette: {
      primary: {
        light: '#263C82',
        main: '#263C82',
        dark: '#263C82',
        contrastText: '#fff',
      },
      secondary: {
        light: '#263C82',
        main: '#263C82',
        dark: '#263C82',
        contrastText: '#fff',
      },
    },
    splash: {
      image: TankelSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: TankelLogoDark,
      topBarHeight: '45px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Tankel Law Group',
    favicon: TankelFavicon,
    calendlyLink: 'https://calendly.com/bobtankel',
  },
  phantomcompliance: {
    palette: {
      primary: {
        light: '#000000',
        main: '#000000',
        dark: '#000000',
        contrastText: '#fff',
      },
      secondary: {
        light: '#000000',
        main: '#000000',
        dark: '#000000',
        contrastText: '#fff',
      },
    },
    splash: {
      image: PhantomSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: PhantomLogoDark,
      topBarHeight: '65px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Phantom Compliance',
    favicon: PhantomFavicon,
  },
  cogerlaw: {
    palette: {
      primary: {
        light: '#564789',
        main: '#564789',
        dark: '#564789',
        contrastText: '#fff',
      },
      secondary: {
        light: '#564789',
        main: '#564789',
        dark: '#564789',
        contrastText: '#fff',
      },
    },
    splash: {
      image: CogerSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: CogerLogoDark,
      topBarHeight: '35px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Coger Law',
    favicon: CogerFavicon,
    calendlyLink: 'https://calendly.com/cogerlawfirm',
  },
  desalegal: {
    palette: {
      primary: {
        light: '#115086',
        main: '#115086',
        dark: '#115086',
        contrastText: '#fff',
      },
      secondary: {
        light: '#115086',
        main: '#115086',
        dark: '#115086',
        contrastText: '#fff',
      },
    },
    splash: {
      image: DeSaSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: DeSaLogoDark,
      topBarHeight: '40px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'De Sa & Associates',
    favicon: DeSaFavicon,
  },
  trent: {
    calendlyLink: 'https://calendly.com/trent-skanes/advisory-call-meeting',
  },
  lawhornlaw: {
    palette: {
      primary: {
        light: '#0D1C3B',
        main: '#0D1C3B',
        dark: '#0D1C3B',
        contrastText: '#fff',
      },
      secondary: {
        light: '#0D1C3B',
        main: '#0D1C3B',
        dark: '#0D1C3B',
        contrastText: '#fff',
      },
    },
    splash: {
      image: LawhornSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: LawhornLogoDark,
      topBarHeight: '50px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Lawhorn & Malouf PLLC',
    favicon: LawhornFavicon,
  },
  michellekinneylaw: {
    palette: {
      primary: {
        light: '#296069',
        main: '#296069',
        dark: '#296069',
        contrastText: '#fff',
      },
      secondary: {
        light: '#296069',
        main: '#296069',
        dark: '#296069',
        contrastText: '#fff',
      },
    },
    splash: {
      image: KinneySplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: KinneyLogoDark,
      topBarHeight: '65px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Michelle Kinney Law',
    favicon: KinneyFavicon,
  },
  attorneymina: {
    palette: {
      primary: {
        light: '#153658',
        main: '#153658',
        dark: '#153658',
        contrastText: '#fff',
      },
      secondary: {
        light: '#153658',
        main: '#153658',
        dark: '#153658',
        contrastText: '#fff',
      },
    },
    splash: {
      image: MinaHaqueSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: MinaHaqueLogoDark,
      topBarHeight: '50px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Law Office of Mina Haque',
    favicon: MinaHaqueFavicon,
  },
  jeff: {
    palette: {
      primary: {
        light: '#003D79',
        main: '#003D79',
        dark: '#003D79',
        contrastText: '#fff',
      },
      secondary: {
        light: '#003D79',
        main: '#003D79',
        dark: '#003D79',
        contrastText: '#fff',
      },
    },
    splash: {
      image: JeffSchickSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: JeffSchickLogoDark,
      topBarHeight: '40px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Jeff Schick Legal PLLC',
    favicon: JeffSchickFavicon,
    meetingLink: 'https://jeffschick.com/client-login-page',
  },
  naturallylegal: {
    palette: {
      primary: {
        light: '#7C736D',
        main: '#7C736D',
        dark: '#7C736D',
        contrastText: '#fff',
      },
      secondary: {
        light: '#7C736D',
        main: '#7C736D',
        dark: '#7C736D',
        contrastText: '#fff',
      },
    },
    splash: {
      image: NaturallyLegalSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: NaturallyLegalLogoDark,
      topBarHeight: '65px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Naturally Legal',
    favicon: NaturallyLegalFavicon,
  },
  springlegal: {
    palette: {
      primary: {
        light: '#4B677E',
        main: '#4B677E',
        dark: '#4B677E',
        contrastText: '#fff',
      },
      secondary: {
        light: '#4B677E',
        main: '#4B677E',
        dark: '#4B677E',
        contrastText: '#fff',
      },
    },
    splash: {
      image: SpringLegalSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: SpringLegalLogoDark,
      topBarHeight: '24px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Spring Legal',
    favicon: SpringLegalFavicon,
    calendlyLink: 'https://calendly.com/springlegal',
    showPoweredByRally: false,
  },
  leopolduslaw: {
    palette: {
      primary: {
        light: '#0c3e64',
        main: '#0c3e64',
        dark: '#0c3e64',
        contrastText: '#fff',
      },
      secondary: {
        light: '#0c3e64',
        main: '#0c3e64',
        dark: '#0c3e64',
        contrastText: '#fff',
      },
    },
    splash: {
      image: LeopoldusLawSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: LeopoldusLawLogoDark,
      topBarHeight: '35px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Leopoldus Law',
    favicon: LeopoldusLawFavicon,
  },
  legaldirectinc: {
    palette: {
      primary: {
        light: '#11A6EA',
        main: '#11A6EA',
        dark: '#11A6EA',
        contrastText: '#fff',
      },
      secondary: {
        light: '#11A6EA',
        main: '#11A6EA',
        dark: '#11A6EA',
        contrastText: '#fff',
      },
    },
    splash: {
      image: LegalDirectSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: LegalDirectLogoDark,
      topBarHeight: '50px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Legal Direct Inc.',
    favicon: LegalDirectFavicon,
  },
  bizcorpnation: {
    palette: {
      primary: {
        light: '#010080',
        main: '#010080',
        dark: '#010080',
        contrastText: '#fff',
      },
      secondary: {
        light: '#010080',
        main: '#010080',
        dark: '#010080',
        contrastText: '#fff',
      },
    },
    splash: {
      image: BizCorpNationSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: BizCorpNationLogoDark,
      topBarHeight: '50px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Biz Corp Nation',
    favicon: BizCorpNationFavicon,
    calendlyLink: 'https://calendly.com/bizcorpnationonly',
  },
  marshalllaw: {
    palette: {
      primary: {
        light: '#A32C2C',
        main: '#A32C2C',
        dark: '#A32C2C',
        contrastText: '#fff',
      },
      secondary: {
        light: '#A32C2C',
        main: '#A32C2C',
        dark: '#A32C2C',
        contrastText: '#fff',
      },
    },
    splash: {
      image: MarshallLawSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: MarshallLawLogoDark,
      topBarHeight: '35px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Marshall Law',
    favicon: MarshallLawFavicon,
  },
  govdox: {
    splash: {
      image: GovDoxSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: GovDoxLogoDark,
      topBarHeight: '30px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Gov Dox',
    favicon: GovDoxFavicon,
  },
  wishartlaw: {
    palette: {
      primary: {
        light: '#003C61',
        main: '#003C61',
        dark: '#003C61',
        contrastText: '#fff',
      },
      secondary: {
        light: '#003C61',
        main: '#003C61',
        dark: '#003C61',
        contrastText: '#fff',
      },
    },
    splash: {
      image: WishartSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: WishartLogoDark,
      topBarHeight: '35px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Wishart Law',
    favicon: WishartFavicon,
  },
  elliottdale: {
    palette: {
      primary: {
        light: '#1881B8',
        main: '#1881B8',
        dark: '#1881B8',
        contrastText: '#fff',
      },
      secondary: {
        light: '#1881B8',
        main: '#1881B8',
        dark: '#1881B8',
        contrastText: '#fff',
      },
    },
    splash: {
      image: ElliottDaleSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: ElliottDaleLogoDark,
      topBarHeight: '25px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Elliott Dale',
    favicon: ElliottDaleFavicon,
  },
  legalguru: {
    palette: {
      primary: {
        light: '#9BC09D',
        main: '#9BC09D',
        dark: '#9BC09D',
        contrastText: '#fff',
      },
      secondary: {
        light: '#9BC09D',
        main: '#9BC09D',
        dark: '#9BC09D',
        contrastText: '#fff',
      },
    },
    splash: {
      image: LegalGuruSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: LegalGuruLogoDark,
      topBarHeight: '70px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Legal Guru',
    favicon: LegalGuruFavicon,
    calendlyLink: 'https://calendly.com/legalguru',
    customNavItem: {
      label: 'Resources',
      href: 'https://www.notion.so/invite/43dff6bdd22ac5dcede0f595256fba4a5ea77baf',
    },
  },
  robertsciglimpaglia: {
    palette: {
      primary: {
        light: '#1D4972',
        main: '#1D4972',
        dark: '#1D4972',
        contrastText: '#fff',
      },
      secondary: {
        light: '#1D4972',
        main: '#1D4972',
        dark: '#1D4972',
        contrastText: '#fff',
      },
    },
    splash: {
      image: RobertSciglimpagliaSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: RobertSciglimpagliaLogoDark,
      topBarHeight: '55px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Robert Sciglimpaglia',
    favicon: RobertSciglimpagliaFavicon,
  },
  spavinslegal: {
    palette: {
      primary: {
        light: '#852819',
        main: '#852819',
        dark: '#852819',
        contrastText: '#fff',
      },
      secondary: {
        light: '#852819',
        main: '#852819',
        dark: '#852819',
        contrastText: '#fff',
      },
    },
    splash: {
      image: SpavinsSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: SpavinsLogoDark,
      topBarHeight: '60px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Spavins Legal',
    favicon: SpavinsFavicon,
  },
  nrwealthgroup: {
    palette: {
      primary: {
        light: '#BC44BC',
        main: '#BC44BC',
        dark: '#BC44BC',
        contrastText: '#fff',
      },
      secondary: {
        light: '#BC44BC',
        main: '#BC44BC',
        dark: '#BC44BC',
        contrastText: '#fff',
      },
    },
    splash: {
      image: NRWealthSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: NRWealthLogoDark,
      topBarHeight: '55px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'NR Wealth Group',
    favicon: NRWealthFavicon,
  },
  faroolegal: {
    palette: {
      primary: {
        light: '#000',
        main: '#000',
        dark: '#000',
        contrastText: '#fff',
      },
      secondary: {
        light: '#000',
        main: '#000',
        dark: '#000',
        contrastText: '#fff',
      },
    },
    splash: {
      image: FarooSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: FarooLogoDark,
      topBarHeight: '35px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Faroo Legal',
    favicon: FarooFavicon,
    calendlyLink: 'https://calendly.com/faroolegal/30min',
  },
  induscounsel: {
    palette: {
      primary: {
        light: '#A7AB74',
        main: '#A7AB74',
        dark: '#A7AB74',
        contrastText: '#fff',
      },
      secondary: {
        light: '#A7AB74',
        main: '#A7AB74',
        dark: '#A7AB74',
        contrastText: '#fff',
      },
    },
    splash: {
      image: IndusSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: IndusLogoDark,
      topBarHeight: '65px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Indus Counsel',
    favicon: IndusFavicon,
    calendlyLink: 'https://calendly.com/induscounsel-snehal/30min',
  },
  nortonestateplanning: {
    palette: {
      primary: {
        light: '#8D558D',
        main: '#8D558D',
        dark: '#8D558D',
        contrastText: '#fff',
      },
      secondary: {
        light: '#8D558D',
        main: '#8D558D',
        dark: '#8D558D',
        contrastText: '#fff',
      },
    },
    splash: {
      image: NortonEstateSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: NortonEstateLogoDark,
      topBarHeight: '45px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Norton Estate Planning & Elder Law',
    favicon: NortonEstateFavicon,
  },
  theandersonlawgroup: {
    palette: {
      primary: {
        light: '#7C0317',
        main: '#7C0317',
        dark: '#7C0317',
        contrastText: '#fff',
      },
      secondary: {
        light: '#7C0317',
        main: '#7C0317',
        dark: '#7C0317',
        contrastText: '#fff',
      },
    },
    splash: {
      image: AndersonSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: AndersonLogoDark,
      topBarHeight: '45px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'The Anderson Law Group',
    favicon: AndersonFavicon,
  },
  thelegalknow: {
    palette: {
      primary: {
        light: '#7B003A',
        main: '#7B003A',
        dark: '#7B003A',
        contrastText: '#fff',
      },
      secondary: {
        light: '#7B003A',
        main: '#7B003A',
        dark: '#7B003A',
        contrastText: '#fff',
      },
    },
    splash: {
      image: LegalKnowSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: LegalKnowLogoDark,
      topBarHeight: '50px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'The Legal Know',
    favicon: LegalKnowFavicon,
    showPoweredByRally: false,
  },
  collateralbase: {
    palette: {
      primary: {
        light: '#12006B',
        main: '#12006B',
        dark: '#12006B',
        contrastText: '#fff',
      },
      secondary: {
        light: '#12006B',
        main: '#12006B',
        dark: '#12006B',
        contrastText: '#fff',
      },
    },
    splash: {
      image: CollateralBaseSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: CollateralBaseLogoDark,
      topBarHeight: '35px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Collateral Base',
    favicon: CollateralBaseFavicon,
    calendlyLink:
      'https://calendly.com/cannabis-industry-lawyer/cannabisconsult',
  },
  amberboydlaw: {
    palette: {
      primary: {
        light: '#D80001',
        main: '#D80001',
        dark: '#D80001',
        contrastText: '#fff',
      },
      secondary: {
        light: '#D80001',
        main: '#D80001',
        dark: '#D80001',
        contrastText: '#fff',
      },
    },
    splash: {
      image: AmberBoydSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: AmberBoydLogoDark,
      topBarHeight: '50px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Amber Boyd Law',
    favicon: AmberBoydFavicon,
  },
  davidaustinlaw: {
    palette: {
      primary: {
        light: '#255162',
        main: '#255162',
        dark: '#255162',
        contrastText: '#fff',
      },
      secondary: {
        light: '#255162',
        main: '#255162',
        dark: '#255162',
        contrastText: '#fff',
      },
    },
    splash: {
      image: DavidAustinSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: DavidAustinLogoDark,
      topBarHeight: '40px',
    },
    primaryFont: {
      url: `${process.env.PUBLIC_URL}/fonts/IdealSans.css`,
      family: 'IdealSans, sans-serif',
    },
    secondaryFont: {
      family: 'IdealSans, sans-serif',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'David Austin Law',
    favicon: DavidAustinFavicon,
    calendlyLink: 'https://calendly.com/davidaustinlaw',
    clientIntercomId: 'bofx4var',
    customNavItem: {
      label: 'Knowledge Base',
      href: 'https://davidaustinlaw.notion.site/David-E-Austin-Attorney-at-Law-LLLC-d5f2165603f146999905754c7b4ee5e3',
    },
  },
  johnniejameslaw: {
    palette: {
      primary: {
        light: '#896D48',
        main: '#896D48',
        dark: '#896D48',
        contrastText: '#fff',
      },
      secondary: {
        light: '#896D48',
        main: '#896D48',
        dark: '#896D48',
        contrastText: '#fff',
      },
    },
    splash: {
      image: JohnnieJamesSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: JohnnieJamesLogoDark,
      topBarHeight: '40px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Johnnie James Law',
    favicon: JohnnieJamesFavicon,
  },
  owlsight: {
    palette: {
      primary: {
        light: '#D7A45B',
        main: '#D7A45B',
        dark: '#D7A45B',
        contrastText: '#fff',
      },
      secondary: {
        light: '#D7A45B',
        main: '#D7A45B',
        dark: '#D7A45B',
        contrastText: '#fff',
      },
    },
    splash: {
      image: OwlsightSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: OwlsightLogoDark,
      topBarHeight: '55px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Owlsight',
    favicon: OwlsightFavicon,
  },
  raaslaw: {
    palette: {
      primary: {
        light: '#1151C8',
        main: '#1151C8',
        dark: '#1151C8',
        contrastText: '#fff',
      },
      secondary: {
        light: '#1151C8',
        main: '#1151C8',
        dark: '#1151C8',
        contrastText: '#fff',
      },
    },
    splash: {
      image: RandallSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: RandallLogoDark,
      topBarHeight: '55px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Randall & Associates',
    favicon: RandallFavicon,
    calendlyLink: 'https://calendly.com/raaslaw',
  },
  unifiedlaw: {
    palette: {
      primary: {
        light: '#32804A',
        main: '#32804A',
        dark: '#32804A',
        contrastText: '#fff',
      },
      secondary: {
        light: '#32804A',
        main: '#32804A',
        dark: '#32804A',
        contrastText: '#fff',
      },
    },
    splash: {
      image: UnifiedSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: UnifiedLogoDark,
      topBarHeight: '25px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Unified Law Services',
    favicon: UnifiedFavicon,
    calendlyLink: 'https://calendly.com/unifiedlaw',
  },
  espace71: {
    palette: {
      primary: {
        light: '#EE5A42',
        main: '#EE5A42',
        dark: '#EE5A42',
        contrastText: '#fff',
      },
      secondary: {
        light: '#EE5A42',
        main: '#EE5A42',
        dark: '#EE5A42',
        contrastText: '#fff',
      },
    },
    splash: {
      image: espace71SplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: espace71LogoDark,
      topBarHeight: '60px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'espace 71',
    favicon: espace71Favicon,
  },
  asburylaw: {
    palette: {
      primary: {
        light: '#2A6AA6',
        main: '#2A6AA6',
        dark: '#2A6AA6',
        contrastText: '#fff',
      },
      secondary: {
        light: '#2A6AA6',
        main: '#2A6AA6',
        dark: '#2A6AA6',
        contrastText: '#fff',
      },
    },
    splash: {
      image: AsburySplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: AsburyLogoDark,
      topBarHeight: '60px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Asbury Law',
    favicon: AsburyFavicon,
  },
  girolaw: {
    palette: {
      primary: {
        light: '#7c2b20',
        main: '#7c2b20',
        dark: '#7c2b20',
        contrastText: '#fff',
      },
      secondary: {
        light: '#7c2b20',
        main: '#7c2b20',
        dark: '#7c2b20',
        contrastText: '#fff',
      },
    },
    splash: {
      image: GiroSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: GiroLogoDark,
      topBarHeight: '45px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Giro & Associates LLC',
    favicon: GiroFavicon,
  },
  'spaans-spaans': {
    palette: {
      primary: {
        light: '#12415C',
        main: '#12415C',
        dark: '#12415C',
        contrastText: '#fff',
      },
      secondary: {
        light: '#12415C',
        main: '#12415C',
        dark: '#12415C',
        contrastText: '#fff',
      },
    },
    splash: {
      image: SpaansSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: SpaansLogoDark,
      topBarHeight: '45px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Spaans&Spaans V.O.F.',
    favicon: SpaansFavicon,
    calendlyLink: 'https://calendly.com/spaansspaansjuristen',
  },
  '71legal': {
    palette: {
      primary: {
        light: '#EE5A42',
        main: '#EE5A42',
        dark: '#EE5A42',
        contrastText: '#fff',
      },
      secondary: {
        light: '#EE5A42',
        main: '#EE5A42',
        dark: '#EE5A42',
        contrastText: '#fff',
      },
    },
    splash: {
      image: Seventy1LegalSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: Seventy1LegalLogoDark,
      topBarHeight: '60px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: '71 Legal',
    favicon: Seventy1LegalFavicon,
  },
  nasserlaw: {
    palette: {
      primary: {
        light: '#B03030',
        main: '#B03030',
        dark: '#B03030',
        contrastText: '#fff',
      },
      secondary: {
        light: '#B03030',
        main: '#B03030',
        dark: '#B03030',
        contrastText: '#fff',
      },
    },
    splash: {
      image: NasserSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: NasserLogoDark,
      topBarHeight: '30px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Nasser Law',
    favicon: NasserFavicon,
  },
  fivevalleyslaw: {
    palette: {
      primary: {
        light: '#BA551F',
        main: '#BA551F',
        dark: '#BA551F',
        contrastText: '#fff',
      },
      secondary: {
        light: '#BA551F',
        main: '#BA551F',
        dark: '#BA551F',
        contrastText: '#fff',
      },
    },
    splash: {
      image: FiveValleysSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: FiveValleysLogoDark,
      topBarHeight: '30px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Five Valleys Law',
    favicon: FiveValleysFavicon,
  },
  lawtek: {
    palette: {
      primary: {
        light: '#24C0EB',
        main: '#24C0EB',
        dark: '#24C0EB',
        contrastText: '#fff',
      },
      secondary: {
        light: '#24C0EB',
        main: '#24C0EB',
        dark: '#24C0EB',
        contrastText: '#fff',
      },
    },
    splash: {
      image: LawtekSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: LawtekLogoDark,
      topBarHeight: '30px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Lawtek',
    favicon: LawtekFavicon,
  },
  mortellarolaw: {
    palette: {
      primary: {
        light: '#000',
        main: '#000',
        dark: '#000',
        contrastText: '#fff',
      },
      secondary: {
        light: '#000',
        main: '#000',
        dark: '#000',
        contrastText: '#fff',
      },
    },
    splash: {
      image: MortellaroSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: MortellaroLogoDark,
      topBarHeight: '50px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Mortellaro Law',
    favicon: MortellaroFavicon,
  },
  hiattlawaz: {
    palette: {
      primary: {
        light: '#ec8b5e',
        main: '#ec8b5e',
        dark: '#ec8b5e',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ec8b5e',
        main: '#ec8b5e',
        dark: '#ec8b5e',
        contrastText: '#fff',
      },
    },
    splash: {
      image: HiattSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: HiattLogoDark,
      topBarHeight: '50px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Hiatt Law',
    favicon: HiattFavicon,
  },
  ghrlawyers: {
    palette: {
      primary: {
        light: '#5C8727',
        main: '#5C8727',
        dark: '#5C8727',
        contrastText: '#fff',
      },
      secondary: {
        light: '#5C8727',
        main: '#5C8727',
        dark: '#5C8727',
        contrastText: '#fff',
      },
    },
    splash: {
      image: GHRSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: GHRLogoDark,
      topBarHeight: '70px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Garrett Hemann Robertson P.C.',
    favicon: GHRFavicon,
  },
  brandonstanko: {
    palette: {
      primary: {
        light: '#89c1d8',
        main: '#89c1d8',
        dark: '#89c1d8',
        contrastText: '#fff',
      },
      secondary: {
        light: '#89c1d8',
        main: '#89c1d8',
        dark: '#89c1d8',
        contrastText: '#fff',
      },
    },
    splash: {
      image: StankoSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: StankoLogoDark,
      topBarHeight: '45px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Brandon Stanko',
    favicon: StankoFavicon,
  },
  higginbotham: {
    palette: {
      primary: {
        light: '#143A61',
        main: '#143A61',
        dark: '#143A61',
        contrastText: '#fff',
      },
      secondary: {
        light: '#143A61',
        main: '#143A61',
        dark: '#143A61',
        contrastText: '#fff',
      },
    },
    splash: {
      image: HigginbothamSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: HigginbothamLogoDark,
      topBarHeight: '50px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'HIG Captive Insurance Group',
    favicon: HigginbothamFavicon,
  },
  carsonlaw: {
    palette: {
      primary: {
        light: '#16365D',
        main: '#16365D',
        dark: '#16365D',
        contrastText: '#fff',
      },
      secondary: {
        light: '#16365D',
        main: '#16365D',
        dark: '#16365D',
        contrastText: '#fff',
      },
    },
    splash: {
      image: CarsonSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: CarsonLogoDark,
      topBarHeight: '40px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Carson Law',
    favicon: CarsonFavicon,
  },
  willsnl: {
    palette: {
      primary: {
        light: '#021544',
        main: '#021544',
        dark: '#021544',
        contrastText: '#fff',
      },
      secondary: {
        light: '#021544',
        main: '#021544',
        dark: '#021544',
        contrastText: '#fff',
      },
    },
    splash: {
      image: WillsNLSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: WillsNLLogoDark,
      topBarHeight: '74px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'Wills NL',
    favicon: WillsNLFavicon,
  },
  alxlegal: {
    splash: {
      image: AlxLegalSplashImage,
      backgroundSize: 'cover',
    },
    logo: {
      dark: AlxLegalLogo,
      topBarHeight: '74px',
    },
    clientSubmissionConfirmation:
      'Someone from our team will be in touch with you shortly.',
    title: 'ALX Legal',
    favicon: AlxLegalFavicon,
  },
};

const DOMAIN_MAP = (() => {
  const domainMap = {};
  Object.entries(themes).forEach(([key, { domains = [] }]) => {
    domains.forEach((domain) => {
      domainMap[domain] = key;
    });
  });
  return domainMap;
})();

const getThemeKey = (hostname) =>
  DOMAIN_MAP[hostname] || hostname.split('.')[0];

export function getThemeByKey(themeKey) {
  const customTheme = themes[themeKey] || { palette: {} };
  const theme = { ...themes.default, ...customTheme };
  theme.palette = { ...themes.default.palette, ...customTheme.palette };
  theme.showPoweredByRally = theme.showPoweredByRally !== false;
  return theme;
}

export function getThemeByDomain() {
  const themeKey = getThemeKey(window.location.hostname);
  return getThemeByKey(themeKey);
}

export default {
  getThemeByDomain,
  getThemeByKey,
  themes,
};
