import { faCircleDot } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faCircle } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React from 'react';

import { MatterState } from '../../../utils/RallyPropTypes';
import { StateBadge } from './StateBadge';

const STATE_VIEW_MAP = {
  qSent: {
    iconClass: 'pending',
    icon: faCircle,
    copy: 'Questionnaire Not Started',
  },
  qInProgress: {
    iconClass: 'pending',
    icon: faCircle,
    copy: 'Questionnaire Started',
  },
  awaitingPayment: {
    iconClass: 'pending',
    icon: faCircle,
    copy: 'Awaiting Payment',
  },
  open: {
    iconClass: 'pending',
    icon: faCircle,
    copy: 'In Progress',
  },
  clientReview: {
    iconClass: 'pending',
    icon: faCircle,
    copy: 'In Progress',
  },
  finalized: {
    iconClass: 'success',
    icon: faCheck,
    copy: 'Complete',
  },
  finalizedFirmOnly: {
    iconClass: 'success',
    icon: faCheck,
    copy: 'Complete',
  },
  closed: {
    iconClass: 'success',
    icon: faCheck,
    copy: 'Complete',
  },
};

const SIGNATURE_STATE_VIEW_MAP = {
  ...STATE_VIEW_MAP,
  AWAITING_SIGNATURES: {
    iconClass: 'pending',
    icon: faCircle,
    copy: 'Awaiting Signatures',
  },
  COMPLETE: {
    iconClass: 'success',
    icon: faCircleDot,
    copy: 'Signed',
  },
};

const MatterStateBadge = ({ state, isActive = true, signatureStatus }) => {
  const badgeProps = signatureStatus
    ? SIGNATURE_STATE_VIEW_MAP[signatureStatus ?? state]
    : STATE_VIEW_MAP[state] || {};

  const copy = badgeProps.copy || 'Unknown State';
  const { icon } = badgeProps;
  const iconClass = isActive ? badgeProps.iconClass : 'inactive';

  return <StateBadge icon={icon} copy={copy} iconClass={iconClass} />;
};

MatterStateBadge.propTypes = {
  state: MatterState,
  isActive: PropTypes.bool,
};

export default MatterStateBadge;
