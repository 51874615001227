import { faEllipsisV } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClickAwayListener, Popper } from '@material-ui/core';
import React, { useState } from 'react';
import styled, { css, withTheme } from 'styled-components/macro';

const linkStyle = {
  cursor: 'pointer',
};

const StyledDropdown = styled.div`
  :hover {
    border-radius: 50%;
    background-color: #f5f6fa;
    border-color: #f5f6fa;
  }
`;

export const DropdownMenuContainer = styled.div`
  background-color: white;
  border-color: #f1f2f3;
  border-radius: 2px;
  box-shadow: 0 0 4px rgb(0 0 0 / 6%);
  padding: 1px;
`;

const StyledAction = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  color: ${(props) => props.theme.palette.gray.main};

  ${({ variant }) => {
    if (variant === 'small') {
      return css`
        font-size: 16px;
      `;
    }
    return css`
      font-size: 24px;
      width: 35px;
      height: 35px;
    `;
  }}
`;

const StyledPopper = styled(Popper)`
  // more z-index hacks to ensure that the popper menu appears on top of
  // list items when hovered.
  z-index: 999;
`;

const StyledIcon = styled.span`
  margin-right: 0.5rem;
`;

function DropdownMenu({
  id,
  menuItems = [],
  icon = faEllipsisV,
  disablePortal = false,
  placement,
  variant,
  dropdownMenuRef,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = !!anchorEl;
  if (!menuItems?.length) {
    return null;
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        menuItems.forEach((item) => item.onClickAway?.());
        setAnchorEl(null);
      }}
    >
      <StyledDropdown>
        <StyledAction
          ref={dropdownMenuRef}
          variant={variant}
          data-testid={`${id}-menu`}
          onClick={(event) => {
            event.stopPropagation();
            setAnchorEl(anchorEl ? null : event.currentTarget);
          }}
          aria-haspopup="menu"
        >
          <FontAwesomeIcon icon={icon} />
        </StyledAction>
        <StyledPopper
          role="menu"
          open={open}
          anchorEl={anchorEl}
          disablePortal={disablePortal}
          placement={placement}
        >
          <DropdownMenuContainer>
            {menuItems.map((item, i) => (
              <ClickAwayListener
                key={`${id}-${item.key ?? item.label}`}
                onClickAway={() => item.onClickAway?.()}
              >
                <div
                  role="menuitem"
                  data-testid={`${id}-${item.key ?? item.label}`}
                  tabIndex={i}
                  className="dropdown-item"
                  style={linkStyle}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!item.keepOpen) {
                      setAnchorEl(null);
                      item.onClickAway?.();
                    }
                    item.onClick(e, id);
                  }}
                >
                  {!!item.labelIcon && (
                    <StyledIcon>{item.labelIcon}</StyledIcon>
                  )}
                  {item.label}
                </div>
              </ClickAwayListener>
            ))}
          </DropdownMenuContainer>
        </StyledPopper>
      </StyledDropdown>
    </ClickAwayListener>
  );
}

export default withTheme(DropdownMenu);
