import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Modal from '../standard/Modal';
import { OrganizationDropdown } from './OrganizationDropdown';

const OrganizationModal = ({
  display,
  onClose,
  updateOrg,
  organization: currentOrganization,
  portal,
}) => {
  const [organization, setOrganization] = useState(currentOrganization);
  useEffect(() => setOrganization(currentOrganization), [currentOrganization]);

  return (
    <Modal
      title="Assign This Document to a Client"
      display={display}
      onCancel={onClose}
      onAccept={async (e) => {
        if (organization !== undefined) {
          await updateOrg(organization);
          await onClose(e);
        }
      }}
      showCancel
      acceptText="Assign"
      portal={portal}
    >
      <OrganizationDropdown
        organizationId={organization}
        setOrganizationId={setOrganization}
        showErrors
      />
    </Modal>
  );
};

OrganizationModal.propTypes = {
  display: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  updateOrg: PropTypes.func.isRequired,
  organization: PropTypes.string,
};

export default OrganizationModal;
