import ReactPixel from 'react-facebook-pixel';

import { isE2ETest } from './Utilities';

const facebookPixelId = process.env.REACT_APP_FACEBOOK_PIXEL_ID;
const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

export const initializeFacebookPixel = () => {
  if (isE2ETest()) {
    return;
  }
  ReactPixel.init(facebookPixelId, advancedMatching, options);
};

export const facebookPageView = () => {
  ReactPixel.pageView();
};

export const facebookTrackCompleteRegistration = () => {
  ReactPixel.track('CompleteRegistration', {
    content_name: 'Business Registration',
  });
};
