import React from 'react';

import Button from '../standard/Button';

const SORT_MAP = {
  date: {
    label: 'Sorted by Last Modified',
    documentBy: 'DATEMODIFIED_DESC',
    matterBy: 'UPDATED_AT_ID_DESC',
    clientBy: 'DATE_MODIFIED_ID_DESC',
  },
  name: {
    label: 'Sorted by Name',
    documentBy: 'NAME_ASC',
    matterBy: 'NAME_ID_ASC',
    clientBy: 'NAME_ID_ASC',
  },
};

const SortButton = ({ sort = 'date', setSort }) => (
  <>
    <Button
      paletteType="gray"
      pure
      className="dropdown-toggle float-right pr-0"
      data-toggle="dropdown"
    >
      {SORT_MAP[sort].label}
    </Button>
    <div className="dropdown-menu dropdown-menu-left">
      <p
        className="dropdown-item"
        id="LastModified"
        style={{ cursor: 'pointer' }}
        onClick={() => setSort('date')}
      >
        Sort By Last Modified
      </p>
      <p
        className="dropdown-item"
        id="Name"
        style={{ cursor: 'pointer' }}
        onClick={() => setSort('name')}
      >
        Sort By Name
      </p>
    </div>
  </>
);

export const getDocumentSortKey = (sort) =>
  SORT_MAP[sort]?.documentBy || SORT_MAP.date.documentBy;

export const getMatterSortKey = (sort) =>
  SORT_MAP[sort]?.matterBy || SORT_MAP.date.matterBy;

export const getClientSortKey = (sort) =>
  SORT_MAP[sort]?.clientBy || SORT_MAP.date.clientBy;

export default SortButton;
