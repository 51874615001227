import React, { forwardRef, useEffect } from 'react';

import { withFormContext } from '../../context/FormContext';
import Address from './Address';

const CompanyAddress = forwardRef(
  (
    { form, name, onChange, onBlur, required, address, errors, ...props },
    ref,
  ) => {
    // Initialize value with Company address if there is no data.
    useEffect(() => {
      if (!address)
        onChange({ target: { name, value: form.org.getMailingAddress() } });
    }, [onChange, form.org, name, address]);

    return (
      <Address
        name={name}
        onBlur={onBlur}
        onChange={(e) => {
          form.org.updateMailingAddress(e.target.value);
          onChange(e);
        }}
        ref={ref}
        required={required}
        address={address}
        errors={errors}
        {...props}
      />
    );
  },
);

export default withFormContext(CompanyAddress);
