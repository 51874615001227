import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { handleException } from 'utils/ErrorUtils';

import Spinner from '../standard/Spinner';
import MenuOfServicesBuilder from './builder/MenuOfServicesBuilder';
import EmptyMenuOfServices from './EmptyMenuOfServices';
import MenuOfServices from './MenuOfServices';

const fetchServiceMenu = async (firmId) => {
  try {
    const response = await axios.get(`/api/v1/firm/${firmId}/servicemenu`);

    if (response.data.length) {
      const [firstServiceMenu] = response.data;
      return firstServiceMenu;
    }
  } catch (error) {
    handleException(error);
  }

  return null;
};

const MenuOfServicesContent = ({
  firmId,
  isEditing = false,
  setIsEditing = (f) => f,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [serviceMenu, setServiceMenu] = useState(null);

  useEffect(() => {
    const loadMenuOfServices = async () => {
      try {
        setIsLoading(true);

        const fetchedServiceMenu = await fetchServiceMenu(firmId);
        if (fetchedServiceMenu) {
          setServiceMenu(fetchedServiceMenu);
        }
      } catch (error) {
        handleException(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!serviceMenu) {
      loadMenuOfServices();
    }
  }, [firmId, serviceMenu]);

  if (isLoading) {
    return <Spinner />;
  }

  if (isEditing && serviceMenu) {
    return (
      <MenuOfServicesBuilder
        firmId={firmId}
        serviceMenu={serviceMenu}
        onUpdate={(newServiceMenu) => setServiceMenu(newServiceMenu)}
        closeBuilder={() => {
          setIsEditing(false);
        }}
      />
    );
  }

  if (!isEditing && serviceMenu) {
    return <MenuOfServices firmId={firmId} serviceMenu={serviceMenu} />;
  }

  return (
    <EmptyMenuOfServices
      firmId={firmId}
      onServiceMenuCreated={(newServiceMenu) => {
        setServiceMenu(newServiceMenu);
        setIsEditing(true);
      }}
    />
  );
};

export default MenuOfServicesContent;
