import { gql, useMutation } from '@apollo/client';
import axios from 'axios';
import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { handleException } from 'utils/ErrorUtils';

import { ConfirmationDialog } from '../standard/ConfirmationDialog';

const ARCHIVE_MATTER = gql`
  mutation archiveTeamMatter($matterId: MongoID!) {
    archiveMatter(input: { matterId: $matterId }) {
      recordId
      record {
        _id
        name
      }
    }
  }
`;

export default function DeleteMatterModal({
  matterToDelete,
  onClose,
  onDelete,
}) {
  const matterVersion = matterToDelete?.matterVersion ?? 1;
  const matterId = matterToDelete?._id ?? matterToDelete?.matterId;

  const [archiveMatter] = useMutation(ARCHIVE_MATTER);

  const onAccept = useCallback(async () => {
    try {
      if (matterVersion === 2) {
        await archiveMatter({
          variables: { matterId },
        });
      } else {
        const res = await axios.delete(
          `/api/v1/templatedContracts/${matterId}`,
        );
        if (res.data.success !== 1) {
          throw new Error(`error deleting matter "${matterId}"`);
        }
      }
      onDelete();
      toast.success('Matter deleted successfully');
    } catch (error) {
      toast.error(
        error?.response?.status === 403
          ? 'Only your service provider may delete the matter at this time.'
          : 'Oops! Something went wrong deleting the matter. Please try again',
      );
      handleException(error);
    } finally {
      onClose();
    }
  }, [matterId, matterVersion, onClose, onDelete, archiveMatter]);

  return (
    <ConfirmationDialog
      open={!!matterId}
      onClose={onClose}
      onConfirm={onAccept}
      title="Confirm Delete"
      message="Are you sure you want to delete this matter? If you delete it, it will not be recoverable!"
      confirmText="Delete"
      confirmButtonPalette="destructive"
    />
  );
}
