import { useFeatureToggle } from '@flopflip/react-broadcast';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from 'react-router-dom';
import { handleException } from 'utils/ErrorUtils';

import { flagNames } from '../../featureFlags';
import PaymentScreen from '../lawpay/PaymentScreen';
import MatterViewScreenPaywall from '../matter/view/MatterViewScreenPaywall';
import Spinner from '../standard/Spinner';

const MatterViewScreen = React.lazy(() =>
  import(
    '../matter/view/MatterViewScreen' /* webpackChunkName: "matterView" */
  ),
);
const Questionnaire = React.lazy(() =>
  import('../client/Questionnaire' /* webpackChunkName: "questionnaire" */),
);

const ClientMatterViewport = ({ match }) => {
  const { matterId } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [matter, setMatter] = useState();

  const enableClientMatterManagement = useFeatureToggle(
    flagNames.ENABLE_CLIENT_MATTER_MANAGEMENT,
  );
  const accessMatterWorkspace = useFeatureToggle(
    flagNames.ACCESS_MATTER_WORKSPACE,
  );

  useEffect(() => {
    setIsLoading(true);
    const getMatter = async () => {
      try {
        const response = await axios.get(
          `/api/v1/templatedContracts/${matterId}`,
        );
        setMatter(response.data[0]);
        setIsLoading(false);
      } catch (err) {
        if (err.response?.status !== 403) {
          handleException(err);
          history.replace('/client/home');
        }
        history.replace('/client/thankyou');
      }
    };
    getMatter();
  }, [matterId, history]);

  if (isLoading) return <Spinner />;

  const {
    state: [state],
    requiresPayment,
  } = matter;

  const defaultRedirect =
    state === 'awaitingPayment' && requiresPayment
      ? `${match.path}/payment`
      : `${match.path}/questionnaire`;

  switch (state) {
    case 'qSent':
    case 'qInProgress':
    case 'awaitingPayment':
      return (
        <Switch>
          <Route
            exact
            path={`${match.url}/questionnaire`}
            render={() => <Questionnaire matter={matter} />}
          />
          {requiresPayment && (
            <Route
              exact
              path={`${match.url}/payment`}
              render={() => <PaymentScreen matter={matter} />}
            />
          )}
          <Redirect to={defaultRedirect} />
        </Switch>
      );
    case 'open':
    case 'finalizedFirmOnly':
      return <Redirect to="/client/thankyou" />;
    default:
      return (
        <Switch>
          {enableClientMatterManagement && (
            <Route
              exact
              path={`${match.url}/questionnaire`}
              render={() => <Questionnaire matter={matter} />}
            />
          )}
          <Route>
            {accessMatterWorkspace ? (
              <MatterViewScreen />
            ) : (
              <MatterViewScreenPaywall
                matterId={matter?._id}
                templateId={matter?.template?._id}
                templateName={matter?.template?.name}
              />
            )}
          </Route>
        </Switch>
      );
  }
};

export default ClientMatterViewport;
