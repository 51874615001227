import React, { useState } from 'react';
import styled from 'styled-components/macro';

import ExternalLink from '../../../components/standard/ExternalLink';
import Modal from '../../../components/standard/Modal';

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  background-color: #f9fafb;
`;

const Button = styled.button`
  background-color: transparent;
  border: none;
  color: #8b95a5;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
`;

const PoweredByRally = () => {
  const [displayModal, setDisplayModal] = useState(false);

  return (
    <Footer>
      <div className="px-80 py-1 text-center">
        <Button onClick={() => setDisplayModal(true)}>
          {'Powered by '}
          <b>Rally</b>
        </Button>
      </div>
      <Modal
        display={displayModal}
        title="About Rally"
        cancelText="Close"
        hideAccept
        showCancel
        onCancel={() => setDisplayModal(false)}
      >
        <p>
          {`This service provider works with Rally to deliver a next-generation
          experience through efficient delivery of services and seamless
          collaboration. `}
          <ExternalLink
            href="https://rallylegal.com"
            target="_blank"
            rel="noopener
          noreferrer"
          >
            Learn more about Rally
          </ExternalLink>
          .
        </p>
      </Modal>
    </Footer>
  );
};

export default PoweredByRally;
