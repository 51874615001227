import { faPlus, faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import styled from 'styled-components/macro';

import { fakMatterFields } from '../../../../icons';
import { SCREEN_SIZES } from '../../../LayoutConstants';
import Button, { TextButton } from '../../../standard/Button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogFooterControl,
  DialogHeader,
  DialogTitle,
} from '../../../standard/Dialog';
import Dropdown from '../../../templates/editor/Dropdown';
import { TypographyStyles } from '../../../Typography';
import { MatterFieldValueInput } from './MatterFieldValueInput';
import { convertInputValueToRallyValue } from './utils';

const FooterButton = styled(Button)`
  margin-left: 0.5rem;
`;

const AddMatterFieldButton = styled(TextButton).attrs({
  className: 'rally-typography rally-typography-p',
})`
  grid-area: value;
  margin-top: 0.5rem;
`;

const RemoveMatterFieldButton = styled(TextButton).attrs({
  className: 'rally-typography rally-typography-p',
})`
  && {
    color: ${({ theme }) => theme.palette.shade.gray_1};
    margin-left: 1rem;

    grid-area: control;
    place-self: center;
  }
`;

const AddMatterFieldFormWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-right: 5rem;
  @media ${SCREEN_SIZES.SMALL} {
    margin-right: 0;
  }
`;

const AddMatterFieldLabel = styled.label`
  ${TypographyStyles.FontFamily}
  ${TypographyStyles.Color}

  font-weight: 500;
  font-size: 0.875rem;
  line-height: 17px;

  margin-right: 0.5rem;
  margin-bottom: 0;

  grid-area: label;
`;

const AddMatterFieldTypeDropdown = styled(Dropdown)`
  & {
    .rally-dropdown-button {
      width: 100%;
    }

    grid-area: input;
  }
`;

const HeaderIcon = styled(FontAwesomeIcon)`
  margin-right: 1rem;
`;

const OverflowDialog = styled(Dialog)`
  .MuiDialog-paper {
    overflow-y: visible;
  }
`;

const StyledDialogFooterControl = styled(DialogFooterControl)`
  padding: 0 30px 16px 30px;
`;

const InputGroup = styled.div`
  :not(:last-child) {
    margin-bottom: 2.5rem;
  }
`;

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(min-content, 5.625rem) 1fr 1.5rem;
  grid-template-rows: auto;

  grid-template-areas: ' label input control ';
  align-items: center;

  :first-child {
    margin-bottom: 1.5rem;
  }
`;

const InputCell = styled.div`
  grid-area: input;

  input,
  textarea {
    padding: 0 0.5rem;
    width: 100%;

    border: 0.5px solid ${({ theme }) => theme.palette.shade.gray_3};
    border-radius: 4px;

    :hover {
      border-color: ${({ theme }) => theme.palette.primary.main};
    }

    :focus,
    :focus-visible {
      outline-color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

export function AddMatterFieldValuesModal({
  display,
  setDisplay,
  addMatterFieldValues,
  availableMatterFields,
  ...props
}) {
  const { register, control, handleSubmit, reset, watch } = useForm({
    defaultValues: { matterFieldValues: [{ matterFieldId: '', value: '' }] },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'matterFieldValues',
    rules: {
      required: true,
      minLength: 1,
    },
  });

  const watchMatterFieldValues = watch('matterFieldValues', []);
  const usedMatterFieldIds = watchMatterFieldValues
    .map(({ matterFieldId }) => matterFieldId)
    .filter((v) => !!v);

  const typeOptions = useMemo(
    () =>
      availableMatterFields
        .filter((matterField) => !usedMatterFieldIds.includes(matterField._id))
        .map((matterField) => ({
          value: matterField._id,
          label: matterField.name,
        })),
    [usedMatterFieldIds, availableMatterFields],
  );

  const onClose = useCallback(() => {
    setDisplay(false);
    reset({ matterFieldValues: [{ matterFieldId: '', value: '' }] });
  }, [setDisplay, reset]);

  const onSubmit = useCallback(
    async (data) => {
      await addMatterFieldValues({
        ...data,
        matterFieldValues: data.matterFieldValues.map((mfv) => {
          const matterField = availableMatterFields.find(
            (mf) => mf._id === mfv.matterFieldId,
          );
          return {
            matterFieldId: mfv.matterFieldId,
            value: convertInputValueToRallyValue(matterField, mfv.value),
          };
        }),
      });
      onClose();
    },
    [availableMatterFields, addMatterFieldValues, onClose],
  );

  return (
    <OverflowDialog
      open={display}
      onClose={onClose}
      maxWidth="sm"
      hideHeaderSeparator
      fullWidth
      scroll="body"
      {...props}
    >
      <DialogHeader>
        <DialogTitle>
          <HeaderIcon icon={fakMatterFields} />
          Create Matter Field
        </DialogTitle>
      </DialogHeader>
      <DialogContent>
        <form id="add-matter-field-form" onSubmit={handleSubmit(onSubmit)}>
          <AddMatterFieldFormWrapper>
            {fields.map((field, index) => (
              <InputGroup key={field.id}>
                <InputContainer>
                  <AddMatterFieldLabel
                    htmlFor={`matter-field-id-${field.id}-toggle-button`}
                  >
                    Type
                  </AddMatterFieldLabel>
                  <Controller
                    name={`matterFieldValues.${index}.matterFieldId`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange } }) => (
                      <AddMatterFieldTypeDropdown
                        items={typeOptions}
                        itemToString={(item) => item.label}
                        initialSelectedItem={{
                          value: '',
                          label: 'Select from list',
                        }}
                        onChange={(item) => onChange(item.value)}
                        id={`matter-field-id-${field.id}`}
                      />
                    )}
                  />
                  {fields.length > 1 && (
                    <RemoveMatterFieldButton
                      type="button"
                      onClick={() => remove(index)}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </RemoveMatterFieldButton>
                  )}
                </InputContainer>
                <InputContainer>
                  <AddMatterFieldLabel
                    htmlFor={`matter-field-value-${field.id}`}
                  >
                    Value
                  </AddMatterFieldLabel>
                  <InputCell>
                    <MatterFieldValueInput
                      id={`matter-field-value-${field.id}`}
                      matterField={availableMatterFields.find(
                        (mf) =>
                          mf._id ===
                          watchMatterFieldValues?.[index]?.matterFieldId,
                      )}
                      register={register}
                      control={control}
                      name={`matterFieldValues.${index}.value`}
                      rules={{ required: true }}
                    />
                  </InputCell>
                </InputContainer>
                {index === fields.length - 1 && !!typeOptions.length && (
                  <InputContainer>
                    <InputCell>
                      <AddMatterFieldButton
                        type="button"
                        onClick={() => append({ matterFieldId: '', value: '' })}
                      >
                        <FontAwesomeIcon icon={faPlus} /> Matter Field
                      </AddMatterFieldButton>
                    </InputCell>
                  </InputContainer>
                )}
              </InputGroup>
            ))}
          </AddMatterFieldFormWrapper>
        </form>
      </DialogContent>
      <DialogFooter>
        <StyledDialogFooterControl>
          <FooterButton type="button" onClick={onClose} outline>
            Cancel
          </FooterButton>
          <FooterButton type="submit" form="add-matter-field-form">
            Save
          </FooterButton>
        </StyledDialogFooterControl>
      </DialogFooter>
    </OverflowDialog>
  );
}
