import hexRgb from 'hex-rgb';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import CreatableSelect from 'react-select/creatable';
import styled, { css, ThemeContext } from 'styled-components/macro';

import { border } from '../forms/FormStyles';
import { SCREEN_SIZES } from '../LayoutConstants';
import { ChipLabelStyles } from './Chip';

const Select = styled(CreatableSelect).attrs({
  classNamePrefix: 'Select',
})`
  & {
    .Select__control {
      min-width: 200px;
      box-shadow: none;
      ${border}
      border-color: white;
      :hover {
        cursor: pointer;
        border: 0.25px solid ${({ theme }) => theme.palette.shade.gray_4};
      }
    }
    .Select__indicators {
      display: none;
    }
    .Select__multi-value {
      border-radius: 1.5rem;
      ${({ borderColor }) =>
        borderColor &&
        css`
          border: 1px solid ${borderColor};
        `}
      ${({ backgroundColor }) =>
        backgroundColor &&
        css`
          background-color: ${backgroundColor};
        `}
      padding: 0.25rem;
      margin: 0.25rem 0.5rem 0.25rem 0;
      color: ${({ theme }) => theme.palette.shade.gray_1};
    }
    .Select__multi-value__label {
      ${ChipLabelStyles}
    }
    .Select__multi-value__remove {
      display: none;
      color: ${({ theme }) => theme.palette.gray.dark};
      margin-bottom: 0;
      border-radius: 10px;
      padding: 0 0 0.2px 0;
      :hover {
        background-color: ${({ backgroundColor }) =>
          backgroundColor ?? 'white'};
        color: ${({ theme }) => theme.palette.gray.dark};
      }
    }
    .Select__menu {
      min-width: 300px;
      z-index: 1004;
      border: 1px solid ${({ theme }) => theme.palette.base.gray};
      @media ${SCREEN_SIZES.X_SMALL} {
        min-width: 65vw;
      }
    }
    .Select__menu-list {
      padding: 0;
    }
    .Select__placeholder {
      color: ${({ theme }) => theme.palette.primary.main};
    }
    .Select__option {
      padding: 0.5rem 1.5rem;
      margin-bottom: 0;
      cursor: pointer;
      background-color: ${({ theme }) => theme.palette.base.white};
      color: ${({ theme }) => theme.palette.shade.gray_1};
    }
  }
  && {
    .Select__control--is-focused {
      ${border}
      cursor: auto;
      background-color: ${({ theme }) => theme.palette.base.white};
      .Select__placeholder {
        visibility: hidden;
      }
      .Select__multi-value__label {
        padding-right: 0.125rem;
      }
      .Select__multi-value__remove {
        display: flex;
        :hover {
          cursor: pointer;
        }
      }
    }
  }
`;

export const TagSelect = ({
  isMulti,
  value,
  placeholder,
  onChange,
  onCreateOption,
  options,
  isValidNewOption,
  backgroundColor,
  ...props
}) => {
  const theme = useContext(ThemeContext);

  return (
    <Select
      isMulti={isMulti}
      value={value}
      placeholder={placeholder}
      options={options}
      onChange={onChange}
      onCreateOption={onCreateOption}
      isValidNewOption={isValidNewOption}
      backgroundColor={backgroundColor}
      styles={{
        option: (_, optionProps) => ({
          color:
            // eslint-disable-next-line no-underscore-dangle
            optionProps?.data?.__isNew__
              ? `${theme.palette.primary.main} !important`
              : theme.palette.shade.gray_1,
          '&:hover': {
            backgroundColor: hexRgb(theme.palette.primary.main, {
              format: 'css',
              alpha: 0.1,
            }),
          },
        }),
      }}
      {...props}
    />
  );
};

TagSelect.propTypes = {
  isMulti: PropTypes.bool,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.object,
    }),
  ),
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.object,
    }),
  ),
  onChange: PropTypes.func,
  onCreateOption: PropTypes.func,
  isValidNewOption: PropTypes.func,
  backgroundColor: PropTypes.string,
};
