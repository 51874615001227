import React from 'react';
import { withTheme } from 'styled-components/macro';

// eslint-disable-next-line import/no-cycle
import TemplateGeneratedField from './TemplateGeneratedField';

const TemplateGeneratedFields = ({
  templateParams,
  errors,
  onChange,
  onBlur,
  applicability,
  paramSpec,
  marginBottom,
  inModal,
  contract,
}) => {
  const fieldList = paramSpec.map((param) => (
    <TemplateGeneratedField
      key={param.elementId}
      identifier={param.identifier}
      elementId={param.elementId}
      type={param.type}
      name={param.name}
      helper={param.helper}
      tooltip={param.tooltip}
      value={templateParams[param.identifier]}
      errors={errors[param._id] || []}
      onChange={onChange}
      onBlur={onBlur}
      required={applicability[param._id] ? param.required : false}
      hidden={!applicability[param._id]}
      marginBottom={marginBottom}
      inModal={inModal || false}
      entityIdentifier={param.entityIdentifier}
      contract={contract}
    />
  ));

  return <React.Fragment>{fieldList}</React.Fragment>;
};

export default withTheme(TemplateGeneratedFields);
