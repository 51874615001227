export function promisify(functionToPromisify) {
  return function promisifiedFuction(...args) {
    return new Promise((resolve, reject) => {
      function callback(err, ...results) {
        if (err) {
          reject(err);
        } else {
          resolve(...results);
        }
      }
      args.push(callback);
      functionToPromisify.call(this, ...args);
    });
  };
}
