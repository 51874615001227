import mixpanel from 'mixpanel-browser';

import { userIsRallyAdmin } from './Utilities';

let IS_INITIALIZED = false;

if (process.env.REACT_APP_MIXPANEL) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL, {
    api_host: 'https://insights.rallynow.io',
  });
  IS_INITIALIZED = true;
}

const trackEvent = (...args) => {
  if (!IS_INITIALIZED) {
    console.log('mock track event', args);
  } else if (!userIsRallyAdmin()) {
    mixpanel.track(...args);
  }
};

export function registerAnalyticsProperties(properties) {
  if (!IS_INITIALIZED) {
    console.log('registering analytics properties', properties);
  } else {
    mixpanel.register(properties);
  }
}

export function identifyUser({
  userId,
  userType,
  email,
  firm,
  organization,
  initialFlags: {
    'entitle-access-matter-workspace': canAccessMatterWorkspace,
  } = {},
}) {
  if (!IS_INITIALIZED) {
    console.log('mock identify analytics user', {
      userId,
      userType,
      email,
      firm,
      organization,
      canAccessMatterWorkspace,
    });
    return;
  }

  mixpanel.identify(userId);
  mixpanel.people.set({
    $email: email,
    $name: email,
    user_id: userId,
    firm: firm?._id,
    firmName: firm?.name,
    client: organization?._id,
    clientName: organization?.name,
    userType,
    canAccessMatterWorkspace,
  });

  // Add user to groups
  mixpanel.set_group('firm', [firm?._id]);
  mixpanel.get_group('firm', firm?._id).set({ $name: firm?.name });

  if (userType === 'client') {
    mixpanel.set_group('client_firm', [firm?._id]);
    mixpanel.get_group('client_firm', firm?._id).set({ $name: firm?.name });

    mixpanel.set_group('client', [organization?._id]);
    mixpanel
      .get_group('client', organization?._id)
      .set({ $name: organization?.name });
  } else {
    mixpanel.set_group('firm_firm', [firm?._id]);
    mixpanel.get_group('firm_firm', firm?._id).set({ $name: firm?.name });
  }
}

function resetUser() {
  if (!IS_INITIALIZED) {
    console.log('clearing mock analytics user');
    return;
  }
  mixpanel.reset();
}

export const trackLogin = (user) => {
  identifyUser(user);
  trackEvent('Log In');
};

export const trackLogout = () => {
  trackEvent('Log Out');
  resetUser();
};

export const trackInviteUser = (type, email) => {
  trackEvent('Invite User', {
    type, // 'client' or 'primary contact'
    email,
  });
};

export const trackSendQuestionnaire = (type) => {
  trackEvent('Send Questionanire', {
    type, // Only one type now 'firm to client'
  });
};

export const trackRegistration = (type, email, isNewOrg, orgId, firmId) => {
  trackEvent('User Registration', {
    type, // 'client' or 'firm'
    email, // User email
    isNewOrg, // true or false
    orgId,
    firmId,
  });
};

export const trackSaveProgress = () => {
  trackEvent('Save Progress');
};

export const trackShareWithClient = () => {
  trackEvent('Share With Client');
};

export const trackRevokeClientAccess = () => {
  trackEvent('Revoke Client Access');
};

export const trackMatterInitialized = ({
  type,
  source,
  matterId,
  templateName,
  templateId,
}) => {
  trackEvent('Matter Initialized', {
    type, // 'blank matter' or 'templated contract',
    source, // 'client service request' or 'hubspot'
    matterId,
    templateName,
    templateId,
  });
};

export const trackViewQuestionnaire = ({
  matterId,
  templateName,
  templateId,
  section,
}) => {
  trackEvent('View Questionnaire', {
    templateName,
    templateId,
    matterId,
    section,
  });
};

export const trackMatterOpened = ({ type, templateName, templateId }) => {
  trackEvent('Matter Opened', {
    type, // 'blank matter' or 'submitted questionnaire',
    templateName,
    templateId,
  });
};

export const trackViewRfbCheckout = ({
  source,
  matterId,
  templateId,
  templateName,
  priceId,
} = {}) => {
  trackEvent('View RFB Checkout', {
    source, // 'matter' or 'settings'
    matterId,
    templateId,
    templateName,
    priceId,
  });
};

export const trackRequestReview = () => {
  trackEvent('Request Review');
};

export const trackFileUpload = (type) => {
  trackEvent('File Upload', {
    type, // 'File Repo', 'Dashboard File Repo', 'Matter Workspace' or 'Questionnaire'
  });
};

export const trackFileDownload = (type) => {
  trackEvent('File Download', {
    type, // 'File Repo', 'Dashboard File Repo', 'Matter Workspace', 'Matter Workspace (All)', or 'Questionnaire'
  });
};

export const trackStartMOSQuestionnaire = ({
  templateId,
  isNewUser,
  firm,
  templateName,
}) => {
  trackEvent('Start MoS Questionnaire', {
    templateId,
    isNewUser,
    firm,
    templateName,
  });
};

export const trackPayment = (success, source, type) => {
  trackEvent('Payment', {
    success, // boolean
    source, // 'Questionnaire'
    type, // 'Flat Fee'
  });
};

export const trackCloseMatter = () => {
  trackEvent('Close Matter');
};

export const trackSendForSigning = () => {
  trackEvent('Send for Signing');
};

export const trackSentForSigning = () => {
  trackEvent('Sent for Signing');
};

export const trackAddTemplate = () => {
  trackEvent('Add Template');
};

export const trackEditTemplate = () => {
  trackEvent('Edit Template');
};

export const trackViewCheckout = () => {
  trackEvent('View Checkout');
};

export const trackOpenBeamer = () => {
  trackEvent('Open Beamer');
};

export const trackKnowledgeBase = () => {
  trackEvent('Knowledge Base');
};

export const trackCopyServiceLink = () => {
  trackEvent('Copy Service Link');
};

export const trackAddClient = (type) => {
  trackEvent('Add Client', {
    type, // 'Modal', 'Org Dropdown'
  });
};

export const trackViewContacts = () => {
  trackEvent('View Contacts');
};

export const trackAddContact = () => {
  trackEvent('Add Contact');
};

export const trackSubscribe = () => {
  trackEvent('Subscribe');
};

export const trackCommandBar = (eventName, eventData) => {
  trackEvent('CommandBar', { eventName, ...eventData });
};

export const trackHubspotConnect = () => {
  trackEvent('Hubspot Connection Initialized');
};

export const trackSalesforceConnect = () => {
  trackEvent('Salesforce Connection Initialized');
};

export function trackMatterReviewRequest({
  matterId,
  reviewRequestId,
  action,
}) {
  trackEvent('Matter Review Request', { matterId, reviewRequestId, action });
}

export const trackNotificationSetting = (notificationId, state) => {
  trackEvent('Notification Setting Changed', { notificationId, state });
};
