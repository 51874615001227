import React, { createContext, forwardRef } from 'react';

import EntityManager from '../services/EntityManager';
import OrgManager from '../services/OrgManager';

const defaultEntities = new EntityManager();
const defaultOrg = new OrgManager();

export const FormContext = createContext({
  org: defaultOrg,
  entities: defaultEntities,
  isFormUpdating: false,
  setIsFormUpdating: () => {},
  autoUpdateContacts: false,
});

export const withFormContext = (WrappedComponent) =>
  forwardRef((props, ref) => (
    <FormContext.Consumer>
      {(context) => <WrappedComponent form={context} ref={ref} {...props} />}
    </FormContext.Consumer>
  ));

export const FormContextProvider = ({
  entities = defaultEntities,
  org = defaultOrg,
  isFormUpdating = false,
  setIsFormUpdating = () => {},
  autoUpdateContacts = false,
  children,
}) => (
  <FormContext.Provider
    value={{
      entities,
      org,
      isFormUpdating,
      setIsFormUpdating,
      autoUpdateContacts,
    }}
  >
    {children}
  </FormContext.Provider>
);
