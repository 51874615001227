import { faChevronCircleLeft } from '@fortawesome/pro-light-svg-icons';
import React, { memo } from 'react';
import styled, { withTheme } from 'styled-components/macro';

import { CardIcon } from '../dashboard/DashboardStyles';
import Button from '../standard/Button';
import { PROGRESS_BAR_HEIGHT } from './ProgressBar';

const BottomButton = styled(Button)`
  font-size: 16px;
  width: 100%;
  border-radius: 4px;
`;

const BottomButtonPure = styled(BottomButton)`
  padding-left: 0;
  padding-right: 0;
`;

const StyledBottomBar = styled.header`
  && {
    box-shadow: 0 -3px 12px 0 rgba(0, 0, 0, 0.05);
    position: ${(props) =>
      props.position === 'notFixed' ? 'absolute' : 'fixed'};
    display: flex;
    justify-content: space-between;
    width: 100vw;
    bottom: ${(props) => (props.hasProgressBar ? PROGRESS_BAR_HEIGHT : '0')};
    left: 0;
    height: 64px;
    margin-top: ${(props) => (props.position === 'notFixed' ? 'none' : '64px')};
    padding: 15px;
    background-color: #fff;
    z-index: 1000;
    transition: margin-left 0.3s ease;
  }
`;

const BottomBar = ({
  className,
  position,
  backButton,
  nextButton,
  secondaryButton,
  hasProgressBar,
}) => (
  <StyledBottomBar
    className={className}
    position={position}
    hasProgressBar={hasProgressBar}
  >
    <div className="container-fluid">
      <div className="row">
        <div className="col-6 offset-sm-1 col-sm-2">
          {backButton && (
            <BottomButtonPure
              pure
              paletteType="gray"
              textAlign="left"
              style={{ marginLeft: '-2px' }}
              {...backButton.props}
            >
              {backButton.copy || (
                <>
                  <CardIcon icon={faChevronCircleLeft} />
                  Back
                </>
              )}
            </BottomButtonPure>
          )}
        </div>
        <div className="d-none d-sm-block offset-sm-2 offset-xl-4 col-sm-3 col-xl-2">
          {secondaryButton && (
            <BottomButtonPure pure {...secondaryButton.props}>
              {secondaryButton.copy}
            </BottomButtonPure>
          )}
        </div>
        <div className="col-6 col-sm-3 col-xl-2">
          {nextButton && (
            <BottomButton {...nextButton.props}>{nextButton.copy}</BottomButton>
          )}
        </div>
      </div>
    </div>
  </StyledBottomBar>
);

export default memo(withTheme(BottomBar));
