import { faUserCircle } from '@fortawesome/pro-light-svg-icons';
import React, { useState } from 'react';

import OrganizationModal from '../../../contracts/OrganizationModal';
import { PopperMenuItem } from '../../../standard/PopperMenu';

function AssignToClient({ updateMatterOrganization }) {
  const [displayModal, setDisplayModal] = useState(false);

  return (
    <>
      <PopperMenuItem
        title="Assign to Client"
        icon={faUserCircle}
        onClick={() => setDisplayModal(true)}
      />
      <OrganizationModal
        display={displayModal}
        onClose={() => setDisplayModal(false)}
        updateOrg={updateMatterOrganization}
        portal={document.body}
      />
    </>
  );
}

export default AssignToClient;
