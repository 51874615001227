import { Line } from 'rc-progress';
import React from 'react';
import styled, { withTheme } from 'styled-components/macro';

export const PROGRESS_BAR_HEIGHT = '10px';

const StyledProgressBar = styled(Line)`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1001;
  width: 100vw;
  height: ${PROGRESS_BAR_HEIGHT};
`;

const ProgressBar = ({ percent, theme }) => {
  const { palette } = theme;
  return (
    <StyledProgressBar
      percent={percent}
      strokeColor={palette.primary.main}
      trailColor="#ebebeb"
      strokeLinecap="square"
    />
  );
};

export default withTheme(ProgressBar);
