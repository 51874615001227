import { gql, NetworkStatus, useQuery } from '@apollo/client';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import Badge from '../standard/Badge';
import ListView from '../standard/ListView';
import ListViewItem from '../standard/ListViewItem';
import Spinner from '../standard/Spinner';
import LawPayConnect from './LawPayConnect';

const AccountListView = styled(ListView)`
  width: 100%;
`;

const GET_FIRM_LAWPAY_DATA = gql`
  query getFirmLawPayData($userId: String!) {
    firmUser(id: $userId) {
      id
      firms {
        _id
        lawpay {
          defaultAccount
          accounts {
            name
            _id: id
          }
        }
      }
    }
  }
`;

const LawPaySettings = () => {
  const userId = localStorage.getItem('userId');
  const [isLoading, setIsLoading] = useState(false);

  const { data, networkStatus, refetch } = useQuery(GET_FIRM_LAWPAY_DATA, {
    fetchPolicy: 'cache-and-network',
    variables: { userId },
  });

  useEffect(() => {
    setIsLoading(networkStatus === NetworkStatus.loading);
  }, [networkStatus]);

  const firm = data?.firmUser?.firms?.[0] ?? { lawpay: { accounts: [] } };
  const {
    lawpay: { accounts, defaultAccount },
  } = firm;

  const hasAccounts = !!accounts?.length;

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && !hasAccounts && <LawPayConnect />}
      {!isLoading && hasAccounts && (
        <AccountListView>
          {accounts.map((account) => (
            <ListViewItem
              id={account._id}
              key={account._id}
              title={account.name}
              badge={
                account._id === defaultAccount && (
                  <Badge badgeStyle="primary">Default</Badge>
                )
              }
              menuItems={[
                {
                  label: 'Make Default',
                  onClick: async (e) => {
                    e.stopPropagation();
                    setIsLoading(true);
                    await Axios.post(
                      `/api/v1/lawpay/${account._id}/makedefault`,
                    );
                    await refetch();
                    setIsLoading(false);
                  },
                },
                {
                  label: 'Delete',
                  onClick: async (e) => {
                    e.stopPropagation();
                    setIsLoading(true);
                    await Axios.delete(`/api/v1/lawpay/${account._id}`);
                    await refetch();
                    setIsLoading(false);
                  },
                },
              ]}
            />
          ))}
        </AccountListView>
      )}
    </>
  );
};

export default LawPaySettings;
