import React, { Component } from 'react';
import { withTheme } from 'styled-components/macro';

import PublicMessageScreen from '../../components/standard/PublicMessageScreen';

class SenderEmailVerificationScreen extends Component {
  constructor(props) {
    super(props);

    // Logs client out
    localStorage.clear();
  }

  render() {
    const { theme, match } = this.props;
    const { result } = match.params;

    if (result === 'success') {
      return (
        <PublicMessageScreen
          iconClass="pe-7s-check"
          iconColor="hsl(142, 64%, 48%)"
          heading="Thank You"
          message="Thank you. Your email address has been confimed."
        />
      );
    }
    return (
      <PublicMessageScreen
        iconClass="pe-7s-close"
        iconColor={theme.palette.destructive.main}
        heading="Error"
        message="An error has occured with your email verification. Please report this to support@rallynow.io."
      />
    );
  }
}

export default withTheme(SenderEmailVerificationScreen);
