import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 48px;

  @media (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const HeaderTitle = styled.h2`
  margin-top: 10px;
  margin-bottom: 1px;
`;

const Input = styled.input`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  max-width: 100%;
  ${({ value }) =>
    value?.length > 20
      ? css`
          width: ${value.length}ch;
        `
      : ``}

  border: 0.25px solid white;
  border-radius: 3px;
  padding: 2px 6px;
  margin-left: -7px;
  color: #4d5259;
  outline: none;
  :hover,
  :focus {
    border: 0.25px solid ${({ theme }) => theme.palette.shade.gray_4};
  }
`;

const Subtitle = styled.small`
  font-size: 16px;
  display: block;
  font-weight: 300;
  line-height: 1.7;
`;

const LeftContainer = styled.div`
  max-width: 78%;
  padding-right: 10px;

  @media (max-width: 575px) {
    max-width: 95%;
  }
`;

const RightContainer = styled.div`
  min-width: 20%;
  ${({ align }) =>
    align === 'top' &&
    css`
      align-self: flex-start;
    `}

  @media (max-width: 575px) {
    margin-top: 24px;
    margin-left: 0;
    min-width: 100%;
  }
`;

const Header = ({
  title,
  subtitle,
  right,
  children,
  className,
  updateTitle,
  isEditable = false,
  rightSideAlign = 'center',
}) => {
  const [headerTitle, setHeaderTitle] = useState(title);

  const handleHeaderBlur = () => {
    const trimmedHeaderTitle = headerTitle.trim();
    setHeaderTitle(trimmedHeaderTitle);
    if (trimmedHeaderTitle !== title) {
      updateTitle(trimmedHeaderTitle);
    }
  };

  return (
    <header>
      <HeaderContainer className={className} data-testid="header-container">
        <LeftContainer>
          <HeaderTitle data-testid="header-title">
            {isEditable ? (
              <Input
                value={headerTitle}
                onChange={(e) => setHeaderTitle(e.target.value)}
                onBlur={handleHeaderBlur}
                readOnly={!isEditable}
                title={title}
                data-testid="editable-header-title"
              />
            ) : (
              title
            )}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </HeaderTitle>
          {children}
        </LeftContainer>
        {right && (
          <RightContainer align={rightSideAlign}>{right}</RightContainer>
        )}
      </HeaderContainer>
    </header>
  );
};

export default Header;
