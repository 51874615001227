import { faSlidersV } from '@fortawesome/pro-light-svg-icons';
import omitBy from 'lodash/omitBy';
import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { CardIcon } from '../dashboard/DashboardStyles';
import Button from '../standard/Button';
import Checkbox from '../standard/Checkbox';
import { OrganizationDropdown } from './OrganizationDropdown';

const FilterOption = styled.span`
  font-size: 14px;
  font-weight: 400;
`;
const TemplateFilters = ({ filters, onFilterChange }) => {
  const [ownedByMe, setOwnedByMe] = useState(filters.ownedByMe || false);
  const [organization, setOrganization] = useState(filters.organization);

  return (
    <React.Fragment>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const returnFilters = omitBy({ ownedByMe, organization }, (i) => !i);
          onFilterChange(returnFilters);
        }}
        className="w-100"
        style={{ marginTop: '-18px' }}
      >
        <h3>
          <small>
            <CardIcon icon={faSlidersV} />
            Filters
          </small>
        </h3>
        <div className="row">
          <div className="col-md-10 col-lg-7">
            <p className="mt-15 mb-1">
              <small className="text-uppercase">Owned By</small>
            </p>
            <div className="custom-controls-stacked">
              <label className="custom-control custom-checkbox">
                <Checkbox
                  palette="secondary"
                  noLabelPadding
                  label={
                    <FilterOption className="custom-control-description">
                      Me
                    </FilterOption>
                  }
                  checked={ownedByMe}
                  onChange={(e) => {
                    setOwnedByMe(e.target.checked);
                  }}
                />
              </label>
            </div>
            <React.Fragment>
              <p className="mt-15 mb-1">
                <small className="text-uppercase">Client</small>
              </p>
              <OrganizationDropdown
                organizationId={organization}
                setOrganizationId={setOrganization}
                smallDropdown
              />
            </React.Fragment>
          </div>
        </div>
        <div className="flexbox mt-25">
          <Button type="submit">Apply Filters</Button>
        </div>
      </form>
      <button type="button" className="aside-toggler" />
    </React.Fragment>
  );
};

export default TemplateFilters;
