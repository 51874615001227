import { faShare } from '@fortawesome/pro-light-svg-icons';
import React from 'react';

import { PopperMenuItem } from '../../../standard/PopperMenu';

function ShareWithClientAction({
  matter,
  setDisplayShareModal,
  revokeClientAccess,
}) {
  const { clientAccessible, legacyState } = matter;

  const handleClick = () =>
    clientAccessible ? revokeClientAccess() : setDisplayShareModal(true);

  const getTitle = () => {
    if (clientAccessible) {
      if (legacyState === 'awaitingPayment') {
        return 'Cancel Payment';
      }
      return 'Revoke Client Access';
    }
    return 'Share with Client';
  };

  return (
    <>
      <PopperMenuItem icon={faShare} title={getTitle()} onClick={handleClick} />
    </>
  );
}

export default ShareWithClientAction;
