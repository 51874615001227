import React from 'react';
import { Link } from 'react-router-dom';

const AdminDashboard = () => (
  <div className="col-lg-10 mt-30">
    <div className="card">
      <h4 className="card-title">
        <strong>Admin: Dashboard</strong>
      </h4>
      <div className="card-body">
        <Link to="/forgotpassword">Send Password Reset Link</Link>
      </div>
      <div className="card-body">
        <Link to="/admin/edit-categories">Edit Matter Categories</Link>
      </div>
    </div>
  </div>
);

export default AdminDashboard;
