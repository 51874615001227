import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect } from 'react';

import { useUser } from '../../context/UserContext';
import { getStrippedUserId } from '../../services/Utilities';

const GET_CLIENT_TEMPLATES = gql`
  query getClientTemplates(
    $userId: MongoID!
    $public: Boolean
    $searchQuery: String
    $limit: Int
  ) {
    client(_id: $userId) {
      _id
      organizationDetails {
        templates(
          filter: {
            public: $public
            nameRegEx: $searchQuery
            archived: false
            statuses: [active, draft]
          }
          limit: $limit
          sort: NAME_ASC
        ) {
          _id
          name
        }
      }
    }
  }
`;

const GET_FIRM_TEMPLATES = gql`
  query getFirmTemplates($userId: String!, $searchQuery: String) {
    firmUser(id: $userId) {
      id
      firms {
        _id
        name
        allTemplates(
          sort: NAME_ASC
          filter: {
            nameRegEx: $searchQuery
            archived: false
            statuses: [active, draft]
          }
        ) {
          _id
          name
          organizationDetails {
            _id
            name
          }
        }
      }
    }
  }
`;

export default () => {
  const userId = getStrippedUserId();
  const [user] = useUser();

  useQuery(GET_CLIENT_TEMPLATES, {
    variables: {
      userId,
      public: localStorage.getItem('hidePublicTemplates') !== 'true',
      limit: 10,
    },
    skip: user.userType !== 'client',
    onCompleted: (data) => {
      const templates = data?.client?.organizationDetails?.[0]?.templates.map(
        ({ _id: id, name: label }) => ({
          id,
          label,
          url: `/client/home?open=true&page=1&templateId=${id}&type=template`,
        }),
      );
      if (templates?.length) {
        window.CommandBar.addRecords('templates', templates);
      }
    },
  });

  const [getClientTemplates] = useLazyQuery(GET_CLIENT_TEMPLATES, {
    variables: {
      userId,
      public: localStorage.getItem('hidePublicTemplates') !== 'true',
    },
  });

  const [getFirmTemplates] = useLazyQuery(GET_FIRM_TEMPLATES, {
    variables: {
      userId,
      public: localStorage.getItem('hidePublicTemplates') !== 'true',
    },
  });

  useEffect(() => {
    if (user.userType === 'client') {
      window.CommandBar.addRecords('templates', [], {
        onInputChange: async (searchQuery) => {
          const { data } = await getClientTemplates({
            variables: { searchQuery },
          });

          return data?.client?.organizationDetails?.[0]?.templates.map(
            ({ _id: id, name: label }) => ({
              id,
              label,
              url: `/client/home?open=true&page=1&templateId=${id}&type=template`,
            }),
          );
        },
      });
    }
    if (user.userType === 'firm') {
      window.CommandBar.addRecords('templates', [], {
        onInputChange: async (searchQuery) => {
          const { data } = await getFirmTemplates({
            variables: { searchQuery },
          });
          const firmName = data?.firmUser?.firms?.[0]?.name;
          return data?.firmUser?.firms?.[0]?.allTemplates.map(
            ({ _id: id, name: label, organizationDetails }) => {
              const orgName = organizationDetails
                ? organizationDetails.name
                : firmName;
              let url = `/new-contract/${id}`;
              if (organizationDetails) {
                url += `?organizationId=${organizationDetails._id}`;
              }
              return {
                id,
                label,
                description: orgName,
                url,
              };
            },
          );
        },
      });
    }
  }, [getClientTemplates, getFirmTemplates, user.userType]);

  return null;
};
