import styled from 'styled-components/macro';

export const SubheaderWrapper = styled.div`
  margin-top: 6px;
`;

export const SubheaderRow = styled.div`
  color: ${(props) => props.theme.palette.text.secondary};
  display: flex;
  align-items: center;
  height: 36px;
`;

export const SubheaderCell = styled.div``;

export const TagsCell = styled.div`
  padding-left: 6px;
`;

export const StateCell = styled.div`
  display: flex;
  padding: 8px 13px;
  align-items: center;
`;
