import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { handleException } from 'utils/ErrorUtils';

import Modal from '../../../standard/Modal';
import { PopperMenuItem } from '../../../standard/PopperMenu';

function DeleteMatterAction({ deleteMatter }) {
  const [displayModal, setDisplayModal] = useState(false);
  const history = useHistory();

  return (
    <>
      <PopperMenuItem
        icon={faTrashAlt}
        title="Delete Matter"
        data-toggle="modal"
        onClick={() => setDisplayModal(true)}
        palette="destructive"
      />
      <Modal
        display={displayModal}
        title="Confirm Delete"
        message="Are you sure you want to delete this matter? If you delete it, it will not be recoverable!"
        acceptText="Delete"
        cancelText="Cancel"
        showCancel
        onCancel={() => setDisplayModal(false)}
        onAccept={async () => {
          try {
            await deleteMatter();
            history.push('/contracts/');
          } catch (error) {
            handleException(error);
            toast.error(
              error?.response?.status === 403
                ? 'Only your service provider may delete the matter at this time.'
                : 'Oops! Something went wrong deleting the matter. Please try again',
            );
          }
        }}
        acceptPaletteType="destructive"
        portal={document.body}
      />
    </>
  );
}

export default DeleteMatterAction;
