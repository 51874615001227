import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components/macro';

import { Autocomplete, FloatingTextField } from '../../forms/FormStyles';
import Button from '../../standard/Button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../standard/Dialog';

const FooterControl = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 30px;
`;

const FooterButton = styled(Button)`
  font-size: 18px;
  min-width: 120px;
  height: 50px;
`;

export function MoveMatterCategoryDialog({
  open,
  categories = [],
  onClose,
  onSubmit,
  categoryId,
  name,
}) {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, isSubmitSuccessful },
  } = useForm({
    mode: 'onChange',
    defaultValues: { categoryId },
  });

  const [options, setOptions] = useState([]);

  const unrollCategoryTree = useCallback(
    (node, flatCategories = []) => {
      if (node.archived || node.id === categoryId) {
        return;
      }

      if (!node.children.some((child) => child.id === categoryId)) {
        // don't add your direct parent
        flatCategories.push(node);
      }

      node.children.forEach((child) => {
        unrollCategoryTree(child, flatCategories);
      });
    },
    [categoryId],
  );

  useEffect(() => {
    const flatCategories = [
      { id: undefined, name: 'None (Root)', children: [] },
    ];
    categories.forEach((category) =>
      unrollCategoryTree(category, flatCategories),
    );

    setOptions(
      flatCategories.map((category) => ({
        id: category.id,
        name: category.name,
      })),
    );
  }, [categories, unrollCategoryTree]);

  useEffect(() => {
    reset({ categoryId });
  }, [reset, categoryId]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <Dialog open={open} maxWidth="sm" onClose={onClose} fullWidth>
      <DialogHeader>
        <DialogTitle>{`Select A New Parent For "${name}"`}</DialogTitle>
      </DialogHeader>
      <DialogContent>
        <form
          id="change-parent-category-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input type="hidden" {...register('categoryId')} />
          <Controller
            name="parentId"
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <Autocomplete
                name="parentId"
                options={options}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <FloatingTextField
                    label="New Parent"
                    autoFocus
                    inputRef={ref}
                    {...params}
                  />
                )}
                onChange={(_, v) => onChange(v?.id)}
                value={options.find((option) => option.name === value)}
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogFooter>
        <FooterControl>
          <FooterButton pure onClick={onClose} paletteType="gray">
            Cancel
          </FooterButton>
          <FooterButton
            type="submit"
            form="change-parent-category-form"
            disabled={!isValid || !isDirty}
          >
            Set New Parent
          </FooterButton>
        </FooterControl>
      </DialogFooter>
    </Dialog>
  );
}
