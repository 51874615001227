import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';

import Button from '../standard/Button';
import Card from '../standard/Card';

export const DashboardCard = styled(Card)`
  margin-bottom: 0;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.09);
  padding: ${(props) => (props.noPadding ? '0' : '31px 36px 23px 36px')};
  overflow: ${(props) => (props.noPadding ? 'hidden' : 'visible')};
  border-radius: 10px;
`;

export const CardTitle = styled.h4`
  margin-bottom: 20px;
`;

export const CardInfo = styled.div`
  line-height: 2;
  margin-bottom: 24px;
`;

export const CardIcon = styled(FontAwesomeIcon)`
  margin-right: 6px;
`;

export const EditIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 36px;
  color: ${(props) => props.theme.palette.primary.main};
  cursor: pointer;
`;

export const ButtonLink = styled.button`
  color: ${(props) => props.theme.palette.primary.main};
  cursor: pointer;
  background: none !important;
  border: none;
  padding: 0 !important;
  &:focus {
    outline: none;
  }
`;

export const StyledButton = styled(Button)`
  font-size: 18px;
  min-width: 120px;
  height: 50px;
`;
