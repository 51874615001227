import { useFeatureToggle } from '@flopflip/react-broadcast';
import React, { Suspense, useEffect } from 'react';
import { toast } from 'react-toastify';

import { flagNames } from '../../featureFlags';
import { useQueryParams } from '../../hooks/useQueryParam';
import { getStrippedUserId, userIsClient } from '../../services/Utilities';
import { SalesforceConnect } from '../salesforce/SalesforceConnect';
import Header from '../standard/Header';
import Spinner from '../standard/Spinner';
import { SettingsContainer, SettingsItem } from './Settings';

const NotificationApiPreferences = React.lazy(
  () =>
    import(
      '../notificationapi/NotificationApiPreferences'
    ) /* webpackChunkName: "notificationapipreferences" */,
);

export function ClientProfile() {
  const salesforceConnectionEnabled = useFeatureToggle(
    flagNames.SALESFORCE_CONNECTION,
  );
  const [{ error, success }] = useQueryParams(['error', 'success']);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success(success);
    }
  }, [error, success]);

  return (
    <>
      <Header
        title="Profile Settings"
        subtitle="Configure your profile settings."
      />
      <SettingsContainer>
        {salesforceConnectionEnabled && userIsClient() && (
          <SettingsItem
            name="Salesforce"
            helper="Connect your Rally account to Salesforce."
          >
            <SalesforceConnect />
          </SettingsItem>
        )}
        {userIsClient() && (
          <SettingsItem
            name="Email Notifications"
            helper="Get notified of activity from Rally."
          >
            <Suspense fallback={<Spinner />}>
              <NotificationApiPreferences userId={getStrippedUserId()} />
            </Suspense>
          </SettingsItem>
        )}
      </SettingsContainer>
    </>
  );
}
