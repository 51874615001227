import React from 'react';
import { withTheme } from 'styled-components/macro';

const SplashImage = ({ className, theme }) => (
  <div
    className={
      className || 'col-md-6 col-lg-7 col-xl-8 d-none d-md-block bg-img'
    }
    style={{
      backgroundImage: `url(${theme.splash.image})`,
      backgroundSize: theme.splash.backgroundSize,
      height: '100vh',
      backgroundColor: theme.splash.backgroundColor,
      backgroundPosition: theme.splash.backgroundPosition,
    }}
    data-overlay={theme.splash.blackOverlay}
  >
    {theme.logo.light && (
      <div
        className="row h-100 pr-50 pt-50 pl-50"
        style={theme.splash.logoContainerStyle}
      >
        <img
          src={theme.logo.light}
          style={{ width: theme.logo.width }}
          alt="Logo"
        />
      </div>
    )}
  </div>
);

export default withTheme(SplashImage);
