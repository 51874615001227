import { gql, useApolloClient, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { handleException } from 'utils/ErrorUtils';

import { useOrganizationPermission } from '../../../context/OrganizationPermissionContext';
import { CategoryNode } from './CategoryNode';

const CATEGORY_FRAGMENT = gql`
  fragment CategoryData on Category {
    id: _id
    name
    icon
    archived
    parents {
      id: _id
      name
    }
  }
`;

const FETCH_CATEGORY = gql`
  query fetchCategory($categoryId: MongoID!) {
    category(categoryId: $categoryId) {
      ...CategoryData
    }
  }
  ${CATEGORY_FRAGMENT}
`;

export const FETCH_CATEGORIES = gql`
  query fetchCategories($depth: Int!, $includeArchived: Boolean!) {
    categories(filter: { depth: $depth, includeArchived: $includeArchived }) {
      ...CategoryData
    }
  }
  ${CATEGORY_FRAGMENT}
`;

export function useCategories({
  depth = -1,
  includeArchived = false,
  usePermissionsFilter = true,
  flatten = false,
  isDisabled = false,
} = {}) {
  const [categories, setCategories] = useState();

  const { canAccessAllMatters, canAccessMatterCategories } =
    useOrganizationPermission();

  const queryResult = useQuery(FETCH_CATEGORIES, {
    fetchPolicy: 'cache-and-network',
    variables: { depth, includeArchived },
    onCompleted: (data) => {
      const rootNode = new CategoryNode({ id: 0, name: 'root' });
      let flattenedCategories = [];
      data.categories?.forEach((category) => {
        if (
          usePermissionsFilter &&
          !canAccessAllMatters &&
          !canAccessMatterCategories.includes(category.id) &&
          !category.parents.some((parent) =>
            canAccessMatterCategories.includes(parent.id),
          )
        ) {
          return;
        }

        rootNode.addCategoryNode(category);
      });
      rootNode.sort();

      if (flatten) {
        const flattenCategory = (category) => [
          category,
          ...(category.children?.flatMap(flattenCategory) ?? []),
        ];
        flattenedCategories = rootNode.children.flatMap(flattenCategory);
      }

      setCategories(flatten ? flattenedCategories : rootNode.children);
    },
    onError: (error) => {
      toast.error(
        'Oops! Something went wrong loading matter categories. Please try again later.',
      );
      handleException(error);
    },
    skip: isDisabled,
  });

  return [categories, queryResult];
}

export function useCategory(categoryId) {
  const client = useApolloClient();

  const [category, setCategory] = useState();

  const [fetchCategory] = useLazyQuery(FETCH_CATEGORY, {
    onCompleted: (data) => setCategory(data.category),
  });

  useEffect(() => {
    if (!categoryId) {
      setCategory(null);
      return;
    }

    // Read fragment directly as the cache may have been populated by
    // a categories list query.
    const cachedCategory = client.readFragment({
      id: `Category:${categoryId}`,
      fragment: CATEGORY_FRAGMENT,
    });

    if (!cachedCategory) {
      fetchCategory({ variables: { categoryId } });
    } else {
      setCategory(cachedCategory);
    }
  }, [categoryId, client, fetchCategory]);

  return category || {};
}
