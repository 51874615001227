import 'box-ui-elements/es/components/flyout/Flyout.scss';
import 'box-ui-elements/es/components/scroll-wrapper/ScrollWrapper.scss';
// https://github.com/box/box-ui-elements/issues/2078
import 'box-ui-elements/es/components/selector-dropdown/SelectorDropdown.scss';

import { ApolloProvider } from '@apollo/client';
import {
  createTheme,
  ThemeProvider as MaterialUiThemeProvider,
} from '@material-ui/core/styles';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import React, { useEffect } from 'react';
import Favicon from 'react-favicon';
import { HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components/macro';

import { OrganizationPermissionContextProvider } from '../context/OrganizationPermissionContext';
import { UserContextProvider } from '../context/UserContext';
import { ConfigureFeatureFlags } from '../featureFlags';
import GraphqlClient from '../graphqlClient';
import IsolatedTerms from '../scenes/IsolatedTerms/components/IsolatedTerms';
import AuthRedirect from '../scenes/LoginAndRegister/AuthRedirect';
import { ClientRegisterScreen } from '../scenes/LoginAndRegister/client/ClientRegisterScreen';
import ForgotPasswordScreen from '../scenes/LoginAndRegister/ForgotPasswordScreen';
import LoginScreen from '../scenes/LoginAndRegister/LoginScreen';
import SenderEmailVerificationScreen from '../scenes/SenderEmailVerification/SenderEmailVerificationScreen';
import { backendHost } from '../services/Config';
import { getThemeByDomain } from '../services/Themes/Themes';
import { reloadUserpilot } from '../services/Userpilot';
import { isE2ETest } from '../services/Utilities';
import BrowserLock from './BrowserLock';
import { GlobalErrorBoundary } from './GlobalErrorBoundary';
import { GlobalStyle } from './GlobalStyle';
import HelloSignRedirect from './hellosign/HelloSignRedirect';
import Hotjar from './Hotjar';
import LawPayRedirect from './lawpay/LawPayRedirect';
import Logout from './Logout';
import ServiceMenuWorkspace from './ServiceMenuWorkspace';
import ToastContainer from './standard/Toaster';
import Workspace from './Workspace';

console.log(`Frontend environment: ${process.env.NODE_ENV}`);

export function App() {
  const location = useLocation();
  const theme = getThemeByDomain();
  const { favicon, title, palette } = theme;
  const { destructive, ...remainingPalette } = palette;
  const muiTheme = createTheme({
    ...theme,
    typography: {
      fontFamily: `${
        theme.primaryFont?.family ?? ['Helvetica', 'sans-serif'].join(',')
      } !important`,
    },
    palette: { ...remainingPalette, error: destructive },
  });

  axios.defaults.baseURL = backendHost;
  axios.defaults.headers.common['is-rally-e2e-test'] = isE2ETest();
  axiosRetry(axios, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: (error) =>
      // Retry when the ALB is having issues finding a suitable backend target
      error.response?.status === 502 || // Bad Gateway
      error.response?.status === 503, // Service Unavailable
  });

  document.title = title;

  useEffect(() => {
    reloadUserpilot();
  }, [location]);

  return (
    <IntlProvider locale="en">
      <GlobalErrorBoundary>
        <ApolloProvider client={GraphqlClient}>
          <StyledComponentsThemeProvider theme={getThemeByDomain()}>
            <MaterialUiThemeProvider theme={muiTheme}>
              <HelmetProvider>
                <UserContextProvider>
                  <ConfigureFeatureFlags>
                    <OrganizationPermissionContextProvider>
                      <GlobalStyle />
                      <Favicon url={favicon} />
                      <BrowserLock />
                      <Hotjar />
                      <Switch>
                        <Route exact path="/login" component={LoginScreen} />
                        <Route exact path="/logout" component={Logout} />
                        <Route
                          path="/client/register"
                          component={ClientRegisterScreen}
                        />
                        <Redirect
                          from="/client/login"
                          to={{ ...location, pathname: '/login' }}
                        />
                        <Redirect
                          from="/client/forgotpassword"
                          to={{ ...location, pathname: '/forgotpassword' }}
                        />
                        <Route
                          exact
                          path="/senderemailverification/:result"
                          component={SenderEmailVerificationScreen}
                        />
                        <Route exact path="/terms" component={IsolatedTerms} />
                        <Route
                          path="/forgotpassword"
                          component={ForgotPasswordScreen}
                        />
                        <Route
                          exact
                          path="/redirect"
                          component={AuthRedirect}
                        />
                        <Route
                          path="/oauth/hellosign/redirect"
                          component={HelloSignRedirect}
                        />
                        <Route
                          path="/oauth/lawpay/redirect"
                          component={LawPayRedirect}
                        />
                        <Redirect
                          from="/public/firm/:firmId/service"
                          to={{
                            ...location,
                            pathname: '/firm/:firmId/servicemenu',
                          }}
                        />
                        <Redirect
                          from="/public/firm/:firmId/service/:serviceId"
                          to={{
                            ...location,
                            pathname: '/firm/:firmId/service/:serviceId',
                          }}
                        />
                        <Redirect
                          from="/client/firm/:firmId/service"
                          to={{
                            ...location,
                            pathname: '/firm/:firmId/servicemenu',
                          }}
                        />
                        <Redirect
                          from="/client/firm/:firmId/service/:serviceId"
                          to={{
                            ...location,
                            pathname: '/firm/:firmId/service/:serviceId',
                          }}
                        />
                        <Route
                          path="/firm/:firmId"
                          component={ServiceMenuWorkspace}
                        />
                        <Route component={Workspace} />
                      </Switch>
                    </OrganizationPermissionContextProvider>
                  </ConfigureFeatureFlags>
                </UserContextProvider>
              </HelmetProvider>
              <ToastContainer />
            </MaterialUiThemeProvider>
          </StyledComponentsThemeProvider>
        </ApolloProvider>
      </GlobalErrorBoundary>
    </IntlProvider>
  );
}
