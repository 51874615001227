import queryString from 'query-string';
import React from 'react';
import styled from 'styled-components/macro';

import ExcelIcon from '../img/icons/wopi/excel.svg';
import OfficeIcon from '../img/icons/wopi/office.svg';
import PowerPointIcon from '../img/icons/wopi/powerpoint.svg';
import VisioIcon from '../img/icons/wopi/visio.svg';
import WordIcon from '../img/icons/wopi/word.svg';
import { getFileExtension } from './Utilities';

export function getApplication(name, extension) {
  const fileExtension = extension || getFileExtension(name);

  if (!fileExtension) {
    return null;
  }

  switch (fileExtension.toLowerCase()) {
    case 'wopitest': {
      return 'WopiTest';
    }

    // case 'ods':
    // case 'csv':
    // case 'xls':
    case 'xlsx': {
      return 'Excel';
    }

    // case 'odp':
    // case 'potx':
    // case 'ppsx':
    // case 'ppt':
    case 'pptx': {
      return 'PowerPoint';
    }

    // case 'odt':
    // case 'doc':
    // case 'dotx':
    case 'docx': {
      return 'Word';
    }

    // case 'vstx':
    // case 'vssx':
    case 'vsdx': {
      return 'Visio';
    }

    default: {
      return null;
    }
  }
}

const APPLICATION_ICONS = {
  powerpoint: PowerPointIcon,
  word: WordIcon,
  excel: ExcelIcon,
  visio: VisioIcon,
};

const ApplicationIcon = styled.img`
  margin-right: 0.5em;

  height: 1.25em;
  width: 1.25em;
`;

function getApplicationIcon(application) {
  if (!application) {
    return OfficeIcon;
  }

  return APPLICATION_ICONS[application.toLowerCase()] || OfficeIcon;
}

function openWopiInNewTab(application, extension, fileId, origin, originId) {
  const query = queryString.stringify({
    origin,
    id: originId,
  });

  const newTab = window.open(
    `/wopi/${application}/edit/${extension}/${fileId}?${query}`,
    '_blank',
  );

  if (newTab != null) {
    newTab.focus();
  }
}

export function openInWopiHandler({
  file: { id: fileId, name, extension },
  origin,
  originId,
}) {
  const fileExtension = extension || getFileExtension(name);
  const application = getApplication(name, fileExtension);

  return () =>
    openWopiInNewTab(application, extension, fileId, origin, originId);
}

export function OpenInWopiLabel({ application }) {
  return (
    <>
      <ApplicationIcon
        src={getApplicationIcon(application)}
        alt={application}
      />
      Open in {application} for the web
    </>
  );
}
