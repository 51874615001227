import React from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';

export function MatterFieldValueInput({
  matterField,
  register,
  control,
  name,
  rules,
  ...props
}) {
  const resolvedType = matterField?.type?.type ?? matterField?.type;

  const prefix = ['Money', 'Currency'].includes(resolvedType) ? '$' : '';
  const decimalScale = ['Money', 'Currency'].includes(resolvedType)
    ? 2
    : undefined;
  const suffix = resolvedType === 'Percent' ? '%' : '';

  switch (resolvedType) {
    case 'Integer':
    case 'Currency':
    case 'Money':
    case 'Percent':
      return (
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field: { onChange, onBlur, ...field } }) => (
            <NumberFormat
              thousandSeparator
              prefix={prefix}
              suffix={suffix}
              decimalScale={decimalScale}
              onValueChange={({ floatValue }, { event }) => {
                onChange({
                  ...event,
                  target: { ...event.target, value: floatValue ?? '' },
                });
              }}
              isNumericString
              {...field}
              {...props}
            />
          )}
        />
      );
    case 'PhoneNumber':
      return (
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field: { onChange, ...field } }) => (
            <NumberFormat
              type="tel"
              format="(###) ###-#### ext.####"
              onValueChange={({ formattedValue }, { event }) => {
                onChange({
                  ...event,
                  target: {
                    ...event?.target,
                    value:
                      formattedValue?.replace(/\s*ext\.\s*$/, '').trim() ?? '',
                  },
                });
              }}
              {...field}
              {...props}
            />
          )}
        />
      );
    case 'Date': {
      return <input type="date" {...register(name, rules)} {...props} />;
    }
    case 'Email': {
      return (
        <input
          type="email"
          placeholder="user@example.com"
          {...register(name, {
            ...rules,
            setValueAs: (v) => v?.trim?.().toLowerCase() ?? v,
          })}
          {...props}
        />
      );
    }
    case 'LongString': {
      return <textarea {...register(name, rules)} {...props} />;
    }
    case 'String':
    default: {
      return <input type="text" {...register(name, rules)} {...props} />;
    }
  }
}
