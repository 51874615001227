import React, { forwardRef } from 'react';
import styled from 'styled-components/macro';

const ListDiv = styled.div`
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
`;

const ListView = forwardRef(({ children, ...props }, ref) => (
  <ListDiv data-provide="selectall" role="list" ref={ref} {...props}>
    {children}
  </ListDiv>
));

export default ListView;
