import { gql, useQuery } from '@apollo/client';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components/macro';

import { fakMatterFields } from '../../../../icons';
import { CardIcon, DashboardCard } from '../../../dashboard/DashboardStyles';
import { ConfirmationDialog } from '../../../standard/ConfirmationDialog';
import { AddMatterFieldButton } from './AddMatterFieldButton';
import { AddMatterFieldValuesModal } from './AddMatterFieldValuesModal';
import { EmptyMatterFields } from './EmptyMatterFields';
import { MatterFieldRow } from './MatterFieldRow';

const MatterFieldCardContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 1.875rem;

  max-width: 500px;
`;

const MatterFieldCardHeader = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  margin-bottom: 1rem;
`;

const FETCH_MATTER_FIELDS = gql`
  query fetchMatterFields {
    matterFields {
      _id
      name
      type
    }
  }
`;

export function MatterFieldCard({
  matterFieldValues = [],
  displayAddMatterFieldModal = false,
  setDisplayAddMatterFieldModal = (f) => f,
  addMatterFieldValues = (f) => f,
  deleteMatterFieldValues = (f) => f,
  updateMatterFieldValues = async (f) => f,
  ...props
}) {
  const [matterFields, setMatterFields] = useState([]);
  const [matterFieldIdToDelete, setMatterFieldIdToDelete] = useState(null);

  const availableMatterFields = useMemo(
    () =>
      matterFields.filter(
        (matterField) =>
          !matterFieldValues.some(
            (mfv) => mfv.matterField._id === matterField._id,
          ),
      ),
    [matterFields, matterFieldValues],
  );

  const handleDeleteMatterFieldValue = useCallback(() => {
    deleteMatterFieldValues({ matterFieldId: matterFieldIdToDelete });
    setMatterFieldIdToDelete(null);
  }, [matterFieldIdToDelete, deleteMatterFieldValues]);

  useQuery(FETCH_MATTER_FIELDS, {
    onCompleted: (data) => setMatterFields(data.matterFields),
    onError: (error) => Sentry.captureException(error),
  });

  return (
    <>
      <MatterFieldCardContainer {...props}>
        <MatterFieldCardHeader>
          <h4>
            <CardIcon icon={fakMatterFields} fixedWidth />
            Matter Fields
          </h4>
          {!!availableMatterFields.length && !!matterFieldValues.length && (
            <AddMatterFieldButton
              setDisplayAddMatterFieldModal={setDisplayAddMatterFieldModal}
            />
          )}
        </MatterFieldCardHeader>
        <DashboardCard style={{ padding: '1.5rem' }}>
          {!matterFieldValues.length && (
            <EmptyMatterFields
              setDisplayAddMatterFieldModal={setDisplayAddMatterFieldModal}
            />
          )}
          {matterFieldValues.map((matterFieldValue) => (
            <MatterFieldRow
              key={matterFieldValue._id}
              matterFieldValue={matterFieldValue}
              setMatterFieldIdToDelete={setMatterFieldIdToDelete}
              updateMatterFieldValue={updateMatterFieldValues}
            />
          ))}
        </DashboardCard>
      </MatterFieldCardContainer>
      <AddMatterFieldValuesModal
        display={displayAddMatterFieldModal}
        setDisplay={setDisplayAddMatterFieldModal}
        addMatterFieldValues={addMatterFieldValues}
        availableMatterFields={availableMatterFields}
      />
      <ConfirmationDialog
        open={!!matterFieldIdToDelete}
        onClose={() => setMatterFieldIdToDelete(null)}
        onConfirm={handleDeleteMatterFieldValue}
        confirmButtonPalette="destructive"
        title={
          <>
            <FontAwesomeIcon icon={faTrashAlt} /> Delete Matter Field
          </>
        }
        message={
          <>
            The Matter Field will be removed from this matter.
            <br />
            Deleting a Matter Field cannot be undone.
          </>
        }
        confirmText="Delete"
      />
    </>
  );
}

MatterFieldCard.propTypes = {
  matterFieldValues: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      matterField: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
      value: PropTypes.any.isRequired,
    }),
  ),
  displayAddMatterFieldModal: PropTypes.bool,
  setDisplayAddMatterFieldModal: PropTypes.func,
  addMatterFieldValues: PropTypes.func.isRequired,
  deleteMatterFieldValues: PropTypes.func.isRequired,
  updateMatterFieldValues: PropTypes.func.isRequired,
};
