import React from 'react';
import styled from 'styled-components/macro';

import Badge from '../standard/Badge';
import DebouncedSearchBar from '../standard/DebouncedSearchBar';
import ListView from '../standard/ListView';
import ListViewItem from '../standard/ListViewItem';
import Spinner from '../standard/Spinner';
import { NoTemplates } from './NoTemplates';

const SearchBarContainer = styled.div`
  margin-bottom: 30px;
`;

export function TemplatesListItem({
  template,
  onClick,
  right,
  menuItems,
  ...props
}) {
  return (
    <ListViewItem
      id={template._id}
      key={template._id}
      description={template.description}
      title={template.name}
      badge={
        template.status.indexOf('draft') !== -1 && (
          <Badge badgeStyle="destructive">Draft</Badge>
        )
      }
      onClick={onClick}
      right={right}
      menuItems={menuItems}
      {...props}
    />
  );
}

export function TemplatesList({
  searchQuery,
  setSearchQuery = (f) => f,
  isLoading = false,
  addTemplateHandler,
  children,
}) {
  const showNoTemplates = !isLoading && !React.Children.count(children);

  return (
    <div className="row mb-10">
      <div className="col-12">
        {isLoading && <Spinner />}
        {(!showNoTemplates || searchQuery) && (
          <SearchBarContainer>
            <DebouncedSearchBar
              className="mb-30"
              placeholder="Search templates"
              setSearchQuery={setSearchQuery}
              searchQuery={searchQuery}
            />
          </SearchBarContainer>
        )}
        <div className="row">
          {showNoTemplates ? (
            <NoTemplates
              searchQuery={searchQuery}
              addTemplateHandler={addTemplateHandler}
            />
          ) : (
            <div className="col-12">
              <ListView>{children}</ListView>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
