import React from 'react';
import styled from 'styled-components/macro';

import lawPayLogo from '../../img/LawPay-Logo.png';
import Button from '../standard/Button';

const LawPayButton = styled(({ href, children, className, ...props }) => (
  <div>
    <a href={href}>
      <Button className={className} outline {...props}>
        <img src={lawPayLogo} alt="LawPay Logo" />
        {children}
      </Button>
    </a>
  </div>
))`
  height: 45px;
  width: max-content;
  img {
    max-height: 100%;
    vertical-align: middle;
    padding-right: 10px;
  }
`;

export default LawPayButton;
