import { gql, useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';

import { useUser } from '../../context/UserContext';
import { getStrippedUserId } from '../../services/Utilities';

const CLIENT_GET_CLIENT_MATTERS = gql`
  query clientGetClientMatters($clientId: MongoID!, $searchQuery: String) {
    client(_id: $clientId) {
      organizationDetails {
        matters(sort: NAME_ASC, filter: { name: $searchQuery }) {
          _id
          name
          state
          matterVersion
          updatedAt
          createdAt
        }
      }
    }
  }
`;

const GET_FIRM_MATTERS = gql`
  query getFirmMatters($userId: String!, $searchQuery: String) {
    firmUser(id: $userId) {
      id
      firms {
        _id
        name
        matters(
          sort: NAME_ASC
          filter: {
            name: $searchQuery
            excludeInProgressLegacyQuestionnaires: true
          }
        ) {
          _id
          name
          updatedAt
          createdAt
          matterVersion
          organization {
            _id
            name
          }
        }
      }
    }
  }
`;

function getDescription(orgName, updatedAt, createdAt) {
  const dateString =
    updatedAt !== createdAt
      ? `Last Modified ${new Date(updatedAt).toLocaleString('en-US')}`
      : `Created on ${new Date(createdAt).toLocaleString('en-US')}`;

  return orgName ? `${orgName} - ${dateString}` : dateString;
}

export default () => {
  const userId = getStrippedUserId();
  const [user] = useUser();

  const [getClientMatters] = useLazyQuery(CLIENT_GET_CLIENT_MATTERS, {
    variables: {
      clientId: userId,
    },
  });

  const [getFirmMatters] = useLazyQuery(GET_FIRM_MATTERS, {
    variables: {
      userId,
    },
  });

  useEffect(() => {
    if (user.userType === 'client') {
      window.CommandBar.addRecords('matters', [], {
        onInputChange: async (searchQuery) => {
          const { data } = await getClientMatters({
            variables: { searchQuery },
          });
          return data?.client?.organizationDetails?.[0]?.matters.map(
            ({ _id: id, name: label, matterVersion, updatedAt, createdAt }) => {
              const dateString =
                updatedAt !== createdAt
                  ? `Last Modified ${new Date(updatedAt).toLocaleString(
                      'en-US',
                    )}`
                  : `Created on ${new Date(createdAt).toLocaleString('en-US')}`;
              return {
                id,
                label,
                url: `/client/${
                  matterVersion === 1 ? 'matter' : 'matters'
                }/${id}`,
                description: dateString,
              };
            },
          );
        },
      });
    }
    if (user.userType === 'firm') {
      window.CommandBar.addRecords('matters', [], {
        onInputChange: async (searchQuery) => {
          const { data } = await getFirmMatters({
            variables: { searchQuery },
          });

          return data?.firmUser?.firms?.[0]?.matters.map(
            ({
              _id: id,
              name: label,
              matterVersion,
              updatedAt,
              createdAt,
              organization,
            }) => ({
              id,
              label,
              url: organization
                ? `/clients/${organization._id}/${
                    matterVersion === 1 ? 'contracts' : 'matters'
                  }/${id}`
                : `/${matterVersion === 1 ? 'contracts' : 'matters'}/${id}`,
              description: getDescription(
                organization?.name,
                updatedAt,
                createdAt,
              ),
            }),
          );
        },
      });
    }
  }, [getClientMatters, getFirmMatters, user.userType]);

  return null;
};
