export const interceptCommandBarShortcut = (documentEditor) => {
  if (documentEditor) {
    // eslint-disable-next-line no-param-reassign
    documentEditor.keyDown = (args) => {
      const { event } = args;
      const keyCode = event.which || event.keyCode;
      const isCtrlKey = event.ctrlKey || event.metaKey ? true : keyCode === 17;
      if (isCtrlKey && keyCode === 75) {
        args.isHandled = true; // eslint-disable-line no-param-reassign
        window.CommandBar.open();
      }
    };
  }
};
