import React from 'react';
import styled from 'styled-components/macro';

import clipboardImage from '../../img/clipboard.png';

const StyledClipboardImage = styled.img`
  max-height: 300px;
`;

const ClipboardImage = () => <StyledClipboardImage src={clipboardImage} />;

export default ClipboardImage;
