import { Paper, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import React, { forwardRef } from 'react';
import { withTheme } from 'styled-components/macro';

import { StyledAutocomplete } from './FormStyles';

const defaultFilterOptions = createFilterOptions({
  matchFrom: 'start',
});

const createOptionStyles = makeStyles({
  listbox: {
    padding: '0',
  },
  paper: {
    zIndex: '1100',
    backgroundColor: 'rgb(255, 255, 255)',
    boxSizing: 'border-box',
    borderRadius: '4px',
    margin: '8px 0',
    fontSize: '16px',
    fontWeight: '300',
    lineHeight: '24px',
  },
  option: {
    backgroundColor: 'transparent',
    color: 'rgb(77, 82, 89)',
    display: 'block',
    width: '100%',
    userSelect: 'none',
    boxSizing: 'border-box',
    padding: '8px 12px',
    cursor: 'pointer',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    // Hover
    '&[data-focus="true"]': {
      backgroundColor: 'rgb(245, 246, 250)',
      borderColor: ({ theme }) => `${theme.palette.gray.dark}`,
    },
    // Selected
    '&[aria-selected="true"]': {
      backgroundColor: 'rgb(245, 246, 250)',
    },
  },
});

const createInputStyles = makeStyles({
  input: {
    '&::placeholder': {
      color: '#8b95a5',
      opacity: '1 !important',
    },
    '&::-webkit-input-placeholder': {
      color: '#8b95a5',
      opacity: '1 !important',
    },
  },
});

const Autocomplete = forwardRef(
  (
    {
      id,
      errors,
      placeholder,
      onChange,
      onInputChange,
      onFocus,
      onBlur,
      onSelect,
      getOptionLabel,
      renderOption,
      filterOptions,
      value,
      defaultValue,
      inputValue,
      options,
      labelledby,
      disabled,
      smallDropdown,
      loading,
      ...props
    },
    ref,
  ) => {
    const classes = createOptionStyles(props);
    const inputClasses = createInputStyles(props);

    const inputProps = {
      onFocus,
      placeholder: placeholder || '',
      'aria-labelledby': labelledby,
      type: 'search',
      autoComplete: 'chrome-off',
    };

    return (
      <StyledAutocomplete
        id={id}
        freeSolo
        fullWidth
        autoHighlight
        openOnFocus
        disableClearable
        classes={classes}
        value={value}
        defaultValue={defaultValue}
        inputValue={inputValue}
        onFocus={onFocus}
        PaperComponent={(paperProps) =>
          options?.length ? <Paper {...paperProps} elevation={3} /> : <></>
        }
        onBlur={onBlur}
        onSelect={onSelect}
        onChange={onChange}
        onInputChange={onInputChange}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        filterOptions={filterOptions ?? defaultFilterOptions}
        options={options}
        errors={errors}
        disabled={disabled}
        smallDropdown={smallDropdown}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            ref={ref}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              ...inputProps,
              classes: {
                ...params.InputProps.classes,
                ...inputProps.classes,
                ...inputClasses,
              },
            }}
          />
        )}
      />
    );
  },
);
export default withTheme(Autocomplete);
