import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import {
  MAX_SIDEBAR_WIDTH,
  SIDEBAR_ALWAYS_OPEN_WIDTH,
  SIDEBAR_WIDTH,
} from '../LayoutConstants';
import PullTab from './PullTab';

const StyledNavList = styled.div`
  display: flex;
  position: relative;
  width: ${SIDEBAR_WIDTH};
  @media (max-width: ${SIDEBAR_ALWAYS_OPEN_WIDTH}) {
    left: ${(props) => (props.showBar ? '0' : `-${MAX_SIDEBAR_WIDTH}`)};
    position: absolute;
    overflow-y: scroll;
  }
  flex-shrink: 0;
  background-color: #f9fafb;
  z-index: 1001;
  top: 0;
  overflow: hidden;
  height: 100%;
  border-right: 1px solid rgb(235, 235, 235);
  /* This seems weird but I believe it's the only way to make the viewport conditionally scroll to the bottom of the NavList
  or the main content section (depending on which is longer) while still expanding the bg color of the NavList all the way to
  the bottom of the document. - CL
  Source: https://stackoverflow.com/questions/791231/force-sidebar-height-100-using-css-with-a-sticky-bottom-image  */
  margin-bottom: -10000px;
  padding-bottom: 10000px;
  transition: left 0.3s ease;
  background-clip: padding-box;

  .NavList__List {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    top: 0;
    padding: 25px 0px 0px 25px;
    @media (max-width: 425px) {
      padding: 15px 0px 0px 15px;
    }
  }
`;

export const NavList = ({ children, collapseOnNav, ...props }) => {
  const [showBar, setShowBar] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (history) {
      return history.listen(() => {
        if (collapseOnNav) {
          setShowBar(false);
        }
      });
    }
    return undefined;
  }, [history, collapseOnNav]);

  return (
    <>
      <StyledNavList
        {...props}
        showBar={showBar}
        id="navList"
        aria-label="Categories"
        aria-hidden={!showBar}
      >
        <div className="NavList__List">{children}</div>
      </StyledNavList>
      <PullTab
        controls="navList"
        showBar={showBar}
        onClick={() => {
          setShowBar(!showBar);
        }}
      />
    </>
  );
};

NavList.propTypes = {
  collapseOnNav: PropTypes.bool,
};

export const NavListItem = styled.div`
  & {
    display: block;
    color: ${({ theme }) => theme.palette.gray.main};
    :hover {
      color: ${({ theme }) => theme.palette.primary.main};
    }
    margin: 4px 0;
    height: 48px;
    font-size: 18px;
    font-weight: 300;
    transition: none;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &.active {
    font-weight: 600;
  }
`;

const NavListSectionInner = styled.div`
  margin-left: 30px;
  height: 0;
  overflow: hidden;

  ${NavListItem}.active + & {
    height: auto;
  }
`;

export function NavListSection({ title, children, hideWhenInactive = false }) {
  return (
    <>
      {title}
      <NavListSectionInner className={hideWhenInactive ? '' : 'active'}>
        {children}
      </NavListSectionInner>
    </>
  );
}
