import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components/macro';

import submissionConfirmation from '../../img/submission-confirmation.png';
import Button from '../standard/Button';

const ReturnButton = styled(Button)`
  margin-top: 6px;
`;

const StyledConfirmation = styled.small`
  font-size: 14px;
  font-weight: 100;
  line-height: 2;
  a:hover {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

const SubmissionConfirmationImg = styled.img.attrs({
  src: submissionConfirmation,
  alt: 'Success',
})`
  margin-bottom: 40px;
  height: auto;
  max-width: 160px;
`;

const ThankYou = () => {
  const { clientSubmissionConfirmation } = useContext(ThemeContext);

  return (
    <div className="col-md-10 col-lg-8 col-xl-6 align-self-center mt-70 text-center">
      <SubmissionConfirmationImg />
      <h3>Thank you for your submission</h3>
      <p style={{ marginTop: '16px' }}>
        <StyledConfirmation
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: clientSubmissionConfirmation }}
        />
      </p>
      <NavLink to="/client/home">
        <ReturnButton className="d-inline-block">
          Return to Dashboard
        </ReturnButton>
      </NavLink>
    </div>
  );
};

export default ThankYou;
