import React from 'react';
import styled from 'styled-components/macro';

import { TeamMember } from './TeamMember';

const SectionHeader = styled.h5`
  margin-top: 30px;
`;

export function ClientFirmsDialogContent({ firms = [] }) {
  return (
    <>
      <SectionHeader>Name</SectionHeader>
      {firms.map(({ name }) => (
        <TeamMember name={name} key={name} />
      ))}
    </>
  );
}
