import React from 'react';
import styled from 'styled-components/macro';

import { BodyText, Note } from '../../../components/forms/FormStyles';

const Waiver = styled.div`
  p {
    padding-bottom: 20px;
  }
`;

const WaiverNote = styled(Note)`
  && {
    margin-bottom: 30px;
    padding-top: 25px;
    line-height: 168%;
    padding-bottom: 50px;
  }
`;
const MtFormWaiver = ({ children }) => (
  <Waiver>
    <WaiverNote>
      <div>
        <p style={{ fontWeight: 'bold' }}>
          By accessing the Platform, you agree as follows:
        </p>
        <p>
          {'First, that you understand and will be bound by our '}
          <a
            href="http://mt.rallynow.io/terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>
          .
        </p>
        <p>
          The Platform does not create a lawyer-client relationship between MT
          and you. Second, that the content on the Platform is provided for
          informational purposes only. Though you have entered into a
          lawyer-client relationship with Miller Thomson LLP (hereinafter “MT”),
          use of the Platform does not constitute legal advice or legal
          services. Rather, the Platform is used as an information-gathering
          tool to aid us in providing such advice and services.
        </p>
        <p className="mb-30">
          Finally, that we work with third party partners, and any information
          you provide on the Platform may be processed through servers that we
          don’t control. We will have no responsibility if there is any security
          breach or other failure of these servers or third parties.
        </p>
        {children}
      </div>
    </WaiverNote>
    <BodyText className="mb-30">
      {'If you do not understand and/or do not agree to these terms, please '}
      <a
        href="mailto:support-amplifi@millerthomson.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        contact us
      </a>
      .
    </BodyText>
  </Waiver>
);

export default MtFormWaiver;
