// Import bundled version which already has the fs polyfill.
// https://github.com/evidenceprime/html-docx-js/issues/48
import htmlDocx from 'html-docx-js/dist/html-docx';

import { DefaultWordStyle } from './ContractStyles';

export function htmlToDocX(body, style = DefaultWordStyle) {
  const htmlDoc = `
    <html xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office"
    xmlns:w="urn:schemas-microsoft-com:office:word"
    xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
    xmlns="http://www.w3.org/TR/REC-html40">
      <head><meta http-equiv=Content-Type content="text/html; charset=utf-8"><title></title>
        <style>${style}</style>
        <style>
        </style>
        <style>
        @page Section1 {
          mso-header: h1;
          mso-footer: f1;
        }
        div.Section1 { page:Section1; }
        table#hrdftrtbl
        {
          margin:0in 0in 0in 900in;
          width:1px;
          height:1px;
          overflow:hidden;
        }
          p.MsoFooter, li.MsoFooter, div.MsoFooter
            {
              margin:0in;
              margin-bottom:.0001pt;
              mso-pagination:widow-orphan;
              tab-stops:center 3.0in right 6.5in;
              font-size:12.0pt;
            }
        </style>
        </xml>
      </head>
      <body>
        <div class="contract">${body}</div>
      </body>
    </html>`;
  const converted = htmlDocx.asBlob(htmlDoc, {
    orientation: 'portrait',
  });
  return converted;
}

export default function contractToDocx(contract, style = DefaultWordStyle) {
  const { html } = contract;
  return htmlToDocX(html, style);
}
