import { faCloudDownload } from '@fortawesome/pro-light-svg-icons';
import { saveAs } from 'file-saver';
import React from 'react';

import ContractToDocx from '../../../../services/ContractToDocx';
import { PopperMenuItem } from '../../../standard/PopperMenu';

function DownloadAction({ name, html }) {
  return (
    <PopperMenuItem
      icon={faCloudDownload}
      title="Download"
      onClick={(event) => {
        event.preventDefault();
        saveAs(ContractToDocx({ html }), `${name}.docx`);
      }}
    />
  );
}

export default DownloadAction;
