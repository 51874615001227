import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components/macro';

import Button from './Button';

const CancelButton = styled.div`
  color: ${(props) => props.theme.palette.gray.dark};
  margin-right: 0px !important;
  cursor: pointer;
  font-size: 14px;
`;

const HeadingDivider = styled.div`
  border-bottom: 1px solid #f1f2f3;
  margin: 0 -30px;
`;

const Body = styled.div`
  ${({ fullWidth }) =>
    fullWidth
      ? css`
          margin: -14px -30px -32px;
          padding: 0;
        `
      : `
        padding: 1rem;
      `}
  & label {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: normal;
  }
  font-size: ${(props) => props.bodyFontSize};
`;

export default class Modal extends Component {
  constructor(props) {
    super(props);
    this.handleAccept = this.handleAccept.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleAccept(e) {
    const { onAccept } = this.props;
    if (onAccept) onAccept(e);
  }

  handleCancel(e) {
    const { onCancel } = this.props;
    if (onCancel) onCancel(e);
  }

  handleClose(e) {
    const { onClose } = this.props;
    if (onClose) onClose(e);
  }

  render() {
    const {
      title,
      message,
      acceptText,
      id = 'modal',
      display,
      footer,
      maxWidth,
      minWidth,
      children,
      cancelText,
      showCancel,
      hideClose,
      onClose,
      hideHeaderClose,
      hideAccept,
      hideHeader,
      acceptPaletteType,
      disabledAccept,
      bodyFontSize,
      centerVertically,
      fullWidth = false,
      portal,
    } = this.props;

    const defaultCloseButtonStyle = {
      cursor: 'pointer',
      padding: '15px 15px 0 0',
    };

    const hideHeaderCloseButtonStyle = {
      cursor: 'pointer',
      marginTop: '15px',
      marginRight: '20px',
      display: 'flex',
      justifyContent: 'flex-end',
    };

    let closeButton = null;
    if (!hideClose) {
      closeButton = (
        <button
          style={defaultCloseButtonStyle}
          type="button"
          className="close"
          onClick={onClose ? this.handleClose : this.handleCancel}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      );
    }

    if (display) {
      const output = (
        <div>
          <div
            style={{ display: 'block', overflowY: 'auto' }}
            className="modal show fade"
            id={id}
            tabIndex="-1"
          >
            <div
              className={`modal-dialog ${
                centerVertically && 'modal-dialog-centered'
              }`}
              style={{ maxWidth, minWidth }}
            >
              <div
                className="modal-content"
                style={{ padding: '23px 30px 30px 30px' }}
              >
                {!hideHeader && (
                  <React.Fragment>
                    <div
                      className="modal-header p-0 pb-20"
                      style={{ borderBottom: 'none' }}
                    >
                      <h4 className="modal-title" id="myModalLabel">
                        {title}
                      </h4>
                      {closeButton}
                    </div>
                    <HeadingDivider />
                  </React.Fragment>
                )}
                {hideHeader && !hideHeaderClose && (
                  <button
                    style={hideHeaderCloseButtonStyle}
                    type="button"
                    className="close"
                    onClick={this.handleCancel}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                )}
                <Body
                  fullWidth={fullWidth}
                  className="modal-body"
                  bodyFontSize={bodyFontSize}
                >
                  {!!message && <p>{message}</p>}
                  {children}
                </Body>
                <div className="modal-footer p-0">
                  {showCancel && (
                    <CancelButton onClick={this.handleCancel}>
                      {cancelText}
                    </CancelButton>
                  )}
                  {hideAccept || (
                    <Button
                      dataTestId="accept"
                      disabled={disabledAccept}
                      paletteType={acceptPaletteType}
                      onClick={this.handleAccept}
                      className="ml-20"
                    >
                      {acceptText}
                    </Button>
                  )}
                  {footer}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show" />
        </div>
      );
      return portal ? createPortal(output, portal) : output;
    }
    return '';
  }
}

Modal.propTypes = {
  display: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  acceptText: PropTypes.string,
  cancelText: PropTypes.string,
  acceptPaletteType: PropTypes.string,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  showCancel: PropTypes.bool,
  hideClose: PropTypes.bool,
  hideHeaderClose: PropTypes.bool,
  hideAccept: PropTypes.bool,
  maxWidth: PropTypes.string,
  minWidth: PropTypes.string,
  disabledAccept: PropTypes.bool,
  bodyFontSize: PropTypes.string,
  centerVertically: PropTypes.bool,
};

Modal.defaultProps = {
  title: 'Alert',
  message: '',
  acceptText: 'Accept',
  cancelText: 'Cancel',
  acceptPaletteType: 'primary',
  maxWidth: '600px',
  bodyFontSize: '14px',
};
