import { gql, useLazyQuery } from '@apollo/client';
import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { handleException } from 'utils/ErrorUtils';

import { useOrganizationPermission } from '../../context/OrganizationPermissionContext';
import Button from '../standard/Button';
import Spinner from '../standard/Spinner';

const GET_STRIPE_DASHBOARD_URL = gql`
  query getStripeDashboard($userId: MongoID!, $returnUrl: URL!) {
    client(_id: $userId) {
      organizationDetails {
        billingDashboardUrl(returnUrl: $returnUrl)
      }
    }
  }
`;

export function BillingDashboardButton() {
  const userId = localStorage.getItem('userId');

  const { canManageBilling } = useOrganizationPermission();

  const [getStripeDashboard, { loading }] = useLazyQuery(
    GET_STRIPE_DASHBOARD_URL,
    {
      variables: {
        userId: userId.replace(/^.*\|/, ''),
        returnUrl: window.location.href,
      },
    },
  );

  const openStripeDashboard = useCallback(async () => {
    try {
      const { data } = await getStripeDashboard();
      const { billingDashboardUrl } = data.client.organizationDetails[0];
      if (!billingDashboardUrl) {
        throw new Error('no stripe portal url found');
      }
      window.open(billingDashboardUrl);
    } catch (error) {
      toast.error(
        'Oops! Something went wrong opening your billing dashboard. Please try again later.',
      );
      handleException(error);
    }
  }, [getStripeDashboard]);

  return (
    <>
      {loading && <Spinner />}
      <Button
        outline
        onClick={openStripeDashboard}
        disabled={!canManageBilling}
        title={
          !canManageBilling
            ? "You don't have permission to manage billing"
            : undefined
        }
      >
        Go to Billing Dashboard
      </Button>
    </>
  );
}
