import axios from 'axios';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import { handleException } from 'utils/ErrorUtils';
import { v4 } from 'uuid';

import noMenus from '../../img/no-menus.png';
import { userIsClient, userIsFirmAdmin } from '../../services/Utilities';
import Button from '../standard/Button';
import { MenuOfServicesContainer } from './Common';

const CLIENT_MESSAGE = 'This firm has not yet created a Menu of Services';
const FIRM_MESSAGE = 'You have not yet created a Menu of Services';

const EmptyMenuOfServicesContainer = styled.div`
  @media (min-width: 1200px) {
    margin-left: 25%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  margin-top: 60px;
`;

const CardBodyContainer = styled.div`
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  align-items: center;
  text-align: center;
`;

const CardMessage = styled.h5`
  margin-bottom: 16px;
`;

const EmptyStateImage = styled.img`
  margin-bottom: 24px;
`;

const CreateMenuOfServicesButton = styled(Button)`
  display: inline-block;
`;

const buildEmptyMenuOfServices = () => ({
  categories: [
    {
      categoryId: v4(),
      templateVersions: [],
    },
  ],
});

const EmptyMenuOfServices = ({ firmId, onServiceMenuCreated = (f) => f }) => {
  const [isCreating, setIsCreating] = useState(false);

  const createServiceMenu = useCallback(
    async ({ serviceMenu }) => {
      if (isCreating) {
        return;
      }

      try {
        setIsCreating(true);
        const { data: newServiceMenu } = await axios.post(
          `/api/v1/firm/${firmId}/servicemenu`,
          serviceMenu || buildEmptyMenuOfServices(),
        );

        onServiceMenuCreated(newServiceMenu);
      } catch (error) {
        handleException(error);
      } finally {
        setIsCreating(false);
      }
    },
    [firmId, isCreating, onServiceMenuCreated],
  );

  return (
    <MenuOfServicesContainer>
      <EmptyMenuOfServicesContainer>
        <CardBodyContainer>
          <EmptyStateImage src={noMenus} alt="No Menus" height="200" />
          <CardMessage>
            {userIsClient() ? CLIENT_MESSAGE : FIRM_MESSAGE}
          </CardMessage>
          {userIsFirmAdmin() && (
            <CreateMenuOfServicesButton
              onClick={createServiceMenu}
              enabled={!isCreating}
            >
              Create Menu of Services
            </CreateMenuOfServicesButton>
          )}
        </CardBodyContainer>
      </EmptyMenuOfServicesContainer>
    </MenuOfServicesContainer>
  );
};

export default EmptyMenuOfServices;
