import React from 'react';
import styled from 'styled-components/macro';

const SettingsItemContainer = styled.div``;

const SettingsItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const SettingsName = styled.div``;

const SettingsRight = styled.div``;

const SettingsControls = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    margin-right: 3em;
    margin-bottom: 1em;
  }
`;

export const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 20rem;
  max-width: 65rem;
  margin-bottom: 5rem;

  > div {
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }
`;

export function SettingsItem({ name, helper, children, right }) {
  return (
    <SettingsItemContainer>
      <SettingsItemHeader>
        <SettingsName>
          <h3>
            {name}
            {!!helper && <small className="subtitle">{helper}</small>}
          </h3>
        </SettingsName>
        <SettingsRight>{right}</SettingsRight>
      </SettingsItemHeader>
      <SettingsControls>{children}</SettingsControls>
    </SettingsItemContainer>
  );
}
