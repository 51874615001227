/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import { withTheme } from 'styled-components/macro';

class PublicMessageBox extends Component {
  buildIcon() {
    const { iconColor, iconClass } = this.props;
    if (iconClass) {
      return (
        <span
          className={`${iconClass} pe-2x`}
          style={{
            color: iconColor || '#ffffff',
            fontSize: '5em',
            width: '100%',
            textAlign: 'center',
            paddingBottom: '25px',
          }}
        />
      );
    }
    return null;
  }

  render() {
    const { heading, message } = this.props;
    return (
      <div className="col-md-7 col-lg-6 col-xl-5 align-self-center">
        <div className="py-30 px-80">
          {this.buildIcon()}
          <h4 style={{ textAlign: 'center' }}>{heading}</h4>
          <p style={{ textAlign: 'center' }}>
            <small style={{ fontSize: '14px', fontWeight: '100' }}>
              {message}
            </small>
          </p>
          <br />
        </div>
      </div>
    );
  }
}

const PublicMessageScreen = ({ iconClass, iconColor, heading, message }) => (
  <PublicMessageBox
    iconClass={iconClass}
    iconColor={iconColor}
    heading={heading}
    message={message}
  />
);

export default withTheme(PublicMessageScreen);
