import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import styled from 'styled-components/macro';

export default styled(MuiFormControlLabel)`
  .MuiTypography-body1 {
    font-weight: 300;
    font-size: 13px;
    line-height: 24px;
  }
`;
