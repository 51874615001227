import PropTypes from 'prop-types';

export function MongoID(props, propName, componentName) {
  if (!/^[0-9a-f]{24}$/.test(props[propName])) {
    return new Error(
      `Invalid MongoID \`${propName}\` supplied to` +
        ` \`${componentName}\`. Validation failed.`,
    );
  }
}

export const PaletteType = PropTypes.oneOf([
  'primary',
  'secondary',
  'gray',
  'success',
  'destructive',
  'inactive',
  'text',
]);

export const MatterState = PropTypes.oneOf([
  'qSent',
  'qInProgress',
  'awaitingPayment',
  'open',
  'clientReview',
  'finalized',
  'finalizedFirmOnly',
  'closed',
]);

export const SignatureRequestStatus = PropTypes.oneOf([
  'AWAITING_SIGNATURES',
  'COMPLETE',
  'DECLINED',
  'CANCELLED',
  'ERROR',
]);

export const SignatureStatus = PropTypes.oneOf([
  'success',
  'on_hold',
  'signed',
  'awaiting_signature',
  'declined',
  'error_unknown',
  'error_file',
  'error_component_position',
  'error_text_tag',
  'on_hold_by_requester',
]);

export const Address = PropTypes.shape({
  city: PropTypes.string,
  country: PropTypes.string,
  line1: PropTypes.string,
  line2: PropTypes.string,
  postalCode: PropTypes.string,
  subnationalDivision1: PropTypes.string,
});
