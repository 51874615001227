import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DialogContent from '@material-ui/core/DialogContent';
import React from 'react';
import styled, { withTheme } from 'styled-components/macro';

import { SubscriptionCheckoutButton } from './SubscriptionCheckoutButton';

const TRIAL_FEATURES = [
  'Unlimited eSignatures',
  'Connections to vetted, affordable lawyers',
  'Unlimited legal document generation',
  'Unlimited file storage',
  'Intelligent autopopulation',
  'Business information management',
];

const PaywallDialogContainer = styled.div`
  position: absolute;

  @media (max-width: 768px) {
    top: 0;
  }
`;

const DialogHeading = styled.h4`
  font-weight: 400;
  font-size: 19px;
  color: #000000;
  text-align: center;
`;

const DialogSubheading = styled.h5`
  font-weight: 700;
  font-size: 14px;
  color: #000000;
  text-align: center;
`;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DialogList = styled.ul`
  padding: 0;
  margin: 0;
`;

const DialogListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 24px;
  margin-right: 8px;
`;

const StyledDialogItem = styled.p`
  margin: 0;
`;

const PaywallDialog = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-gap: 12px;
  justify-items: center;
  padding: 32px;
  border-radius: 0;
  border: 1px solid #000000;
  color: #000000;
  background-color: white;
  font-weight: 400;
  margin: 10px;
`;

const StyledLink = styled.a`
  font-size: 16px;
  margin-top: 16px;
  color: ${(props) => props.theme.palette.primary.main};
  :hover {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

function RallyForBusinessPaywallDialog({
  title = 'Get started with Rally! 🎉',
  checkoutAnalyticsMetadata = {},
}) {
  return (
    <PaywallDialogContainer>
      <PaywallDialog>
        <DialogHeading>{title}</DialogHeading>
        <DialogSubheading>
          Start your subscription to use your documents and access:
        </DialogSubheading>
        <StyledDialogContent>
          <DialogList>
            {TRIAL_FEATURES.map((feature) => (
              <DialogListItem key={feature}>
                <StyledFontAwesomeIcon icon={faCheck} />
                <StyledDialogItem>{feature}</StyledDialogItem>
              </DialogListItem>
            ))}
          </DialogList>
          <SubscriptionCheckoutButton
            analyticsMetadata={checkoutAnalyticsMetadata}
          />
          <StyledLink
            href="https://www.rallylegal.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn about Rally
          </StyledLink>
        </StyledDialogContent>
      </PaywallDialog>
    </PaywallDialogContainer>
  );
}

export default withTheme(RallyForBusinessPaywallDialog);
