import styled from 'styled-components/macro';

const LookupDiv = styled.div`
  position: relative;
  display: block;
  align-items: center;
  width: 100%;
  &:before {
    content: '\\E610';
    font-family: themify;
    font-size: 16px;
    position: absolute;
    top: 52%;
    left: 0;
    transform: translateY(-50%);
    color: #8b95a5;
    width: 36px;
    text-align: center;
    cursor: text;
  }
  &:focus {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

const LookupInput = styled.input`
  color: #4d5259;
  border: 1px solid #ebebeb;
  border-radius: 18px;
  height: 36px;
  width: 200px;
  max-width: 100%;
  padding-left: 36px;
  padding-right: 18px;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0.5px;
  outline: none !important;
  &:focus {
    box-shadow: 0 0 5px ${(props) => props.theme.palette.primary.main};
    border: none;
    margin: 0 1px;
  }
  &::placeholder {
    color: #8b95a5;
  }
`;

export { LookupDiv, LookupInput };
