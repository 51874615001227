import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { withTheme } from 'styled-components/macro';

import Button from '../../../components/standard/Button';
import StyledLink from '../../../components/standard/StyledLink';
import AuthUtility from '../../../services/AuthUtility';
import { getUserType } from '../Utilities';

const authUtility = new AuthUtility();

const ForgotPasswordBox = () => {
  const { search } = useLocation();
  const { email: initialEmail, ...others } = queryString.parse(search);

  const [loginLink, setLoginLink] = useState(`/login${search}`);
  const [email, setEmail] = useState(initialEmail);
  const [emailSent, setEmailSent] = useState(false);
  const [emailFailed, setEmailFailed] = useState(false);
  const [userType, setUserType] = useState(null);

  useEffect(
    () => setLoginLink(`/login?${queryString.stringify({ ...others, email })}`),
    [email, others],
  );

  const handleEmailSent = useCallback(
    (err) => {
      if (err) {
        setEmailSent(false);
        setEmailFailed(true);
        console.log(err);
      } else {
        setEmailSent(true);
        setEmailFailed(false);
      }
    },
    [setEmailSent, setEmailFailed],
  );

  const handleChangePassword = useCallback(
    async (event) => {
      event.preventDefault();
      if (!email) {
        setEmailFailed(true);
        return;
      }

      const retrievedUserType = await getUserType(email);
      setUserType(retrievedUserType);
      if (!retrievedUserType) {
        setEmailFailed(true);
      }

      const connection =
        retrievedUserType === 'client'
          ? process.env.REACT_APP_AUTH0_CLIENT_REALM
          : 'Username-Password-Authentication';

      authUtility.changePassword(email, handleEmailSent, connection);
    },
    [email, handleEmailSent],
  );

  return (
    <div className="col-md-6 col-lg-5 col-xl-4 align-self-center">
      <div className="py-30 px-80">
        <h4>Forgot your password?</h4>
        <p className="subtitle" style={{ fontSize: '14px' }}>
          Enter the email address associated with your account. Soon, you will
          receive a message containing a link directing you to reset your
          password.
        </p>
        <br />

        {emailSent && userType !== 'client' && (
          <div>
            <div className="alert alert-success" role="alert">
              If the email entered is associated with a valid account, the
              message was emailed to your inbox. Note that you may need to check
              your spam folder.
            </div>
            <br />
          </div>
        )}
        {emailSent && userType === 'client' && (
          <div>
            <div className="alert alert-success" role="alert">
              The message was successfully emailed to your inbox. Please confirm
              that the email address entered is associated with a valid account.
              Note that you may need to check your spam folder.
            </div>
            <br />
          </div>
        )}
        {emailFailed ? (
          <div>
            <div className="alert alert-danger" role="alert">
              There seems to be a problem. Ensure that you have entered a valid
              email address associated with your account.
            </div>
            <br />
          </div>
        ) : (
          ''
        )}

        <form className="form-type-material" onSubmit={handleChangePassword}>
          <div className={`form-group ${email ? 'do-float' : ''}`}>
            <input
              type="text"
              className="form-control"
              id="email"
              value={email}
              onChange={(event) => setEmail(event.target.value.trim())}
            />
            <label htmlFor="email">Email</label>
          </div>

          <div className="form-group" style={{ paddingTop: '21px' }}>
            <Button type="submit" width="100%">
              Send Link
            </Button>
          </div>
        </form>
        <div>
          <p style={{ fontSize: '14px' }}>
            <StyledLink to={loginLink}>Back to Login</StyledLink>
          </p>
        </div>
      </div>
    </div>
  );
};

export default withTheme(ForgotPasswordBox);
