import { faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';

export const PlusIcon = styled(FontAwesomeIcon).attrs({
  size: 'lg',
  icon: faPlusCircle,
})`
  margin-right: 0.5em;
`;
