import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { LookupDiv, LookupInput } from './SearchBarStyles';

const DebouncedSearchBar = ({
  searchQuery = '',
  setSearchQuery,
  placeholder,
  wait = 500,
}) => {
  const [value, setValue] = useState(searchQuery);
  useEffect(() => setValue(searchQuery), [searchQuery]);
  const debounced = useDebouncedCallback(setSearchQuery, wait);
  return (
    <LookupDiv>
      <LookupInput
        type="text"
        style={{ width: '100%' }}
        placeholder={placeholder}
        onChange={(e) => {
          setValue(e.target.value);
          debounced(e.target.value);
        }}
        value={value}
      />
    </LookupDiv>
  );
};

export default DebouncedSearchBar;
