import axios from 'axios';
import Cookie from 'js-cookie';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { handleException } from 'utils/ErrorUtils';

import Spinner from '../standard/Spinner';

const LawPayOAuth = () => {
  const { search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const showError = (error) => {
      history.push(`/settings?error=${encodeURIComponent(error)}`);
    };
    const authorize = async () => {
      const { code } = queryString.parse(search);
      if (!code) showError('Unexpected error connecting to LawPay.');

      try {
        await axios.post('/api/v1/lawpay/connect', { code });
        Cookie.remove('LAWPAY_REDIRECT');
        history.push(`/settings`);
      } catch (error) {
        if (error.response?.status === 404) {
          showError(error.response.data.message);
        } else {
          handleException(error);
          showError('Unexpected error connecting to LawPay.');
        }
      }
    };
    authorize();
  }, [search, history]);

  return <Spinner />;
};

export default LawPayOAuth;
