import MuiDialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

const DialogCloseButton = styled.button`
  border: none;
  background: inherit;

  padding: 0;

  color: ${(props) => props.theme.palette.text.secondary};
  font-size: 1.5rem;
  font-weight: 300;
`;

const DialogControl = styled.div`
  display: flex;
  justify-content: flex-end;

  grid-area: control;
`;

export const DialogHeader = styled.header`
  grid-area: header;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const DialogTitle = styled.h4.attrs({ id: 'modal-title' })`
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 1.5;

  margin: 0;
`;

export const DialogSubtitle = styled.span`
  margin-top: 0.25rem;

  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.02em;

  color: ${({ theme }) => theme.palette.shade.gray_1};
`;

export const DialogDescription = styled.div.attrs({
  id: 'modal-description',
  hidden: true,
})``;

export const DialogSeparator = styled.hr`
  grid-area: separator;

  width: 100%;
  margin: 0;
  color: #f1f2f3;
  margin-bottom: 10px;
`;

export const DialogContent = styled.div`
  grid-area: content;
`;

export const DialogFooter = styled.footer`
  grid-area: footer;
`;

export const DialogFooterControl = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 30px;
`;

const DialogContainer = styled.div`
  display: grid;
  height: 100%;

  grid-template-columns: 30px 5fr 1fr 30px;
  grid-template-rows: 60px 1px 1fr minmax(30px, max-content);
  grid-template-areas:
    '.          header    control      .'
    'separator separator separator separator'
    '.          content   content      .'
    'footer     footer    footer    footer';
  gap: 5px;
  padding-top: 5px;
`;

const DialogContainerWrapper = styled.div`
  display: block;
  height: 100%;
`;

export function Dialog({
  onClose,
  maxWidth = false,
  fullScreen = false,
  fullWidth,
  hideHeaderSeparator = false,
  scroll,
  children,
  ...props
}) {
  return (
    <MuiDialog
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      onClose={onClose}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      scroll={scroll}
      {...props}
    >
      <DialogContainerWrapper>
        <DialogContainer>
          {!!onClose && (
            <DialogControl>
              <DialogCloseButton aria-label="close" onClick={onClose}>
                &times;
              </DialogCloseButton>
            </DialogControl>
          )}
          {!hideHeaderSeparator && <DialogSeparator />}
          {children}
        </DialogContainer>
      </DialogContainerWrapper>
    </MuiDialog>
  );
}

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  fullScreen: PropTypes.bool,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
  fullWidth: PropTypes.bool,
  hideHeaderSeparator: PropTypes.bool,
  scroll: PropTypes.oneOf(['body', 'paper']),
};
