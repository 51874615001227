import { gql } from '@apollo/client';

export const CLIENT_TEAM_FRAGMENT = gql`
  fragment OrgTeamInformation on OrgTeam {
    _id
    accessPolicy
    teamMembers {
      id
      email
    }
  }
`;
