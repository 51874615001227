import { gql, useQuery } from '@apollo/client';
import { useFeatureToggle } from '@flopflip/react-broadcast';
import axios from 'axios';
import Dinero from 'dinero.js';
import React, { useContext, useEffect, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import styled, { ThemeContext, ThemeProvider } from 'styled-components/macro';
import { handleException } from 'utils/ErrorUtils';

import { useUser } from '../../context/UserContext';
import { flagNames } from '../../featureFlags';
import { getStrippedUserId } from '../../services/Utilities';
import ClientTemplateIcon from './icons/client_templates.svg';
import CreateBlankMatterIcon from './icons/create_blank_matter.svg';
import RallyWorkflowsIcon from './icons/rally_workflows_firm_services.svg';

const GET_CLIENT_TEMPLATES = gql`
  query getClientTemplates(
    $userId: MongoID!
    $public: Boolean
    $searchQuery: String
    $limit: Int
  ) {
    client(_id: $userId) {
      _id
      organizationDetails {
        templates(
          filter: {
            public: $public
            nameRegEx: $searchQuery
            archived: false
            statuses: [active, draft]
          }
          limit: $limit
          sort: NAME_ASC
        ) {
          _id
          name
        }
      }
    }
  }
`;

const fetchServiceMenu = async (firmId) => {
  try {
    const response = await axios.get(`/api/v1/firm/${firmId}/servicemenu`);

    if (response.data.length) {
      const [firstServiceMenu] = response.data;
      return firstServiceMenu;
    }
  } catch (error) {
    handleException(error);
  }

  return null;
};

const getDisplayPrice = (price) => {
  const dineroPrice = price
    ? Dinero({ amount: Number.parseInt(price, 10) })
    : null;
  return dineroPrice
    ? dineroPrice.toFormat(!dineroPrice.hasSubUnits() ? '$0,0' : '$0,0.00')
    : null;
};

const Price = styled.div`
  font-size: 19px;
  font-weight: bold;
  color: ${(props) => props.theme.palette.primary.main};
`;

const Icon = styled.img`
  width: 18px;
  height: 18px;
  min-width: 18px;
`;

const ServicePreview = ({ description, price }) => (
  <>
    <div>{description}</div>
    {price && <Price>{getDisplayPrice(price)}</Price>}
  </>
);

export default () => {
  const userId = getStrippedUserId();
  const [user] = useUser();

  const [clientTemplates, setClientTemplates] = useState([]);
  const [firmServices, setFirmServices] = useState([]);

  const enableClientMenuOfServices = useFeatureToggle(
    flagNames.ENABLE_CLIENT_MENU_OF_SERVICES,
  );
  const isRFB = useFeatureToggle(flagNames.RFB);
  const isClientCreateBlankMatterEnabled = useFeatureToggle(
    flagNames.CLIENT_CREATE_BLANK_MATTER,
  );

  const firmId = user.firm._id;

  const theme = useContext(ThemeContext);

  useQuery(GET_CLIENT_TEMPLATES, {
    variables: {
      userId,
      public: localStorage.getItem('hidePublicTemplates') !== 'true',
    },
    skip: user.userType !== 'client',
    onCompleted: (data) => {
      const templates = data?.client?.organizationDetails?.[0]?.templates.map(
        ({ _id: id, name: label }) => {
          const icon = <Icon src={ClientTemplateIcon} alt={label} />;
          return {
            id,
            label,
            url: `/client/home?open=true&page=1&templateId=${id}&type=template`,
            category: 'Custom Templates',
            icon: renderToStaticMarkup(icon),
          };
        },
      );
      setClientTemplates(templates ?? []);
    },
  });

  useEffect(() => {
    if (user.userType === 'client' && enableClientMenuOfServices) {
      const loadData = async () => {
        const serviceMenu = await fetchServiceMenu(firmId);
        const serviceOptions = serviceMenu?.categories?.flatMap(
          (category) =>
            category.templates
              ?.filter(({ status: [status] }) => status === 'active')
              ?.map(({ name, version: { id }, price, description }) => {
                const icon = <Icon src={RallyWorkflowsIcon} alt={name} />;
                const preview =
                  description || price
                    ? renderToStaticMarkup(
                        <ThemeProvider theme={theme}>
                          <ServicePreview
                            description={description}
                            price={price}
                          />
                        </ThemeProvider>,
                      )
                    : null;
                return {
                  id,
                  label: name,
                  category: isRFB ? 'Rally Templates' : 'Services',
                  url: `/firm/${firmId}/service/${id}`,
                  preview,
                  icon: renderToStaticMarkup(icon),
                };
              }) ?? [],
        );
        setFirmServices(serviceOptions ?? []);
      };
      loadData();
    }
  }, [user.userType, firmId, enableClientMenuOfServices, theme, isRFB]);

  useEffect(() => {
    if (user.userType === 'client') {
      const allOptions = [...clientTemplates, ...firmServices];
      if (isClientCreateBlankMatterEnabled) {
        const icon = <Icon src={CreateBlankMatterIcon} alt="Blank Matter" />;
        allOptions.unshift({
          id: 'blank_matter',
          label: 'Blank Matter',
          url: '/client/matters?open=true&type=blank',
          icon: renderToStaticMarkup(icon),
        });
      }

      window.CommandBar.addArgumentChoices('newMatterArguments', allOptions, {
        descriptionKey: 'description',
      });
    }
  }, [
    user.userType,
    clientTemplates,
    firmServices,
    isClientCreateBlankMatterEnabled,
  ]);

  return null;
};
