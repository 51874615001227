import { useFeatureToggle } from '@flopflip/react-broadcast';
import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components/macro';

import { useFirmSettings } from '../../../context/UserContext';
import { flagNames } from '../../../featureFlags';
import { Categories } from '../../client/categories/CategoryList';
import Header from '../../standard/Header';
import { MatterContacts } from './MatterContacts';
import MatterStateSelector from './MatterStateSelector';
import { SignatureStatusSubheaderRow } from './SignatureStatusSubheaderRow';
import {
  StateCell,
  SubheaderCell,
  SubheaderRow,
  SubheaderWrapper,
  TagsCell,
} from './SubheaderStyles';

const StateSpan = styled.span`
  margin-left: 10px;
  color: ${(props) => props.theme.palette.text.secondary};
`;

export default function FirmMatterViewHeader({
  matter,
  actions,
  toggleMatterState,
  updateMatterName,
  addMatterCategory,
  removeMatterCategory,
  addMatterContact,
  removeMatterContact,
  setDisplaySendForSigning,
  onSignatureReminder,
  onCancelSignatureRequest,
}) {
  const {
    name,
    organization,
    clientAccessible,
    legacyState,
    categories = [],
    contacts,
    signatureRequests,
  } = matter;
  const { name: organizationName } = organization || {};

  const [{ showCategories }] = useFirmSettings();

  const enableSignatureStatus = useFeatureToggle(
    flagNames.ENABLE_SIGNATURE_STATUS,
  );

  return (
    <>
      <Header
        title={name}
        updateTitle={updateMatterName}
        isEditable={!!updateMatterName}
        right={actions}
        rightSideAlign="top"
      >
        <SubheaderWrapper>
          {categories && showCategories && (
            <SubheaderRow>
              <SubheaderCell>Categories:</SubheaderCell>
              <TagsCell>
                <Categories
                  categories={categories.filter(
                    (category) => !category.archived,
                  )}
                  addCategory={addMatterCategory}
                  removeCategory={removeMatterCategory}
                />
              </TagsCell>
            </SubheaderRow>
          )}
        </SubheaderWrapper>
        <SubheaderWrapper>
          <SubheaderRow>
            {organization && (
              <>
                <SubheaderCell>Associated Contacts:</SubheaderCell>
                <TagsCell>
                  <MatterContacts
                    contacts={contacts}
                    organization={organization}
                    addMatterContact={addMatterContact}
                    removeMatterContact={removeMatterContact}
                  />
                </TagsCell>
              </>
            )}
          </SubheaderRow>
        </SubheaderWrapper>
        <SubheaderWrapper>
          <SubheaderRow>
            {!!legacyState && <SubheaderCell>Status:</SubheaderCell>}
            <StateCell>
              {!!legacyState && (
                <MatterStateSelector
                  state={legacyState}
                  toggleMatterState={toggleMatterState}
                />
              )}
              {!!organization && (
                <StateSpan>
                  {clientAccessible ? (
                    <>
                      <FontAwesomeIcon icon={faEye} />
                      &nbsp;{`This matter `}
                      <b>is </b>
                      {`accessible by ${organization.name}`}
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faEyeSlash} />
                      &nbsp;{`This matter `}
                      <b>is not </b>
                      {`accessible by ${organizationName}`}
                    </>
                  )}
                </StateSpan>
              )}
            </StateCell>
          </SubheaderRow>
        </SubheaderWrapper>
        {enableSignatureStatus && (
          <SignatureStatusSubheaderRow
            signatureRequests={signatureRequests}
            setDisplaySendForSigning={setDisplaySendForSigning}
            onSignatureReminder={onSignatureReminder}
            onCancelSignatureRequest={onCancelSignatureRequest}
          />
        )}
      </Header>
    </>
  );
}
