import { faDatabase } from '@fortawesome/pro-light-svg-icons';
import React from 'react';

import { downloadFile } from '../../../../services/Utilities';
import { PopperMenuItem } from '../../../standard/PopperMenu';

function exportData(fileName, data) {
  let csvData = 'id,value\n';
  csvData += Object.entries(data)
    .map((e) => {
      const key = e[0];
      let value = e[1];

      if (typeof value === 'object') {
        // Note that double quotes must be escaped in CSV
        // so that we can surround values containing commas in double quotes
        value = JSON.stringify(value).replaceAll('"', '""');
      }

      return `${key},"${value}"`;
    })
    .join('\n');

  downloadFile(
    fileName,
    `data:text/csv;charset=UTF-8,${encodeURIComponent(csvData)}`,
  );
}

function ExportData({ name, data }) {
  return (
    <PopperMenuItem
      icon={faDatabase}
      title="Export Data"
      onClick={() => exportData(`${name}.csv`, data)}
    />
  );
}

export default ExportData;
