import { gql } from '@apollo/client';

import { MatterViewFragment } from '../../matter/list/MatterListViewItem';

export const FULL_CONTACT_DATA = gql`
  fragment FullContactData on Contact {
    _contactId
    name
    address {
      city
      country
      line1
      line2
      postalCode
      subnationalDivision1
    }
    type
    phone
    email
    matters @include(if: $fetchMatters) {
      ...MatterView
    }
  }

  ${MatterViewFragment}
`;
