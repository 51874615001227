import { faInfoCircle, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import equal from 'fast-deep-equal/es6';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { FormContext } from '../../../../context/FormContext';
import Button from '../../../standard/Button';
import { useContact, useUpdateContact, useVisibleContact } from '../hooks';

const UpdateContactCardContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid rgba(77, 82, 89, 0.07);
`;

const CloseButton = styled((props) => (
  <button {...props} type="button">
    <FontAwesomeIcon icon={faTimes} />
  </button>
))`
  position: relative;
  top: -18px;
  left: 12px;
  border: none;
  background: inherit;
  padding: 0 2px;
  color: ${(props) => props.theme.palette.text.secondary};
`;

const InfoIconContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 3px;
  margin-top: -2px;
`;

const InfoIcon = styled(FontAwesomeIcon).attrs({
  size: 'lg',
  icon: faInfoCircle,
})`
  margin-right: 5px;
`;

const UpdateMessage = styled.div`
  display: flex;
  align-items: stretch;
  margin-bottom: 6px;
`;

const UpdateButtons = styled.div`
  padding-top: 12px;
  padding-left: 18px;
  justify-content: left;
  align-items: center;
`;

const UpdateButton = styled(Button).attrs({ outline: true })`
  margin: 0 10px;
  border-radius: 17px;
`;

const WarningMessage = styled.div`
  font-weight: 500;
  margin-left: 5px;
`;

const UpdateHelper = styled.div`
  margin: 6px 0px 24px 14px;
`;

const ReplaceHelper = styled.div`
  margin: 6px 0px 0px 14px;
`;

const omitEmptyFields = (_key, value) => (!value ? undefined : value);

export function UpdateContactCard({
  name,
  contact,
  fieldConfig,
  onChange,
  onBlur,
}) {
  const { org, setIsFormUpdating } = useContext(FormContext);
  const clientId = org?.getId();

  const [isUpdated, setIsUpdated] = useState(false);

  const [oldContact, , { loading }] = useContact({
    contactId: contact._contactId,
    clientId,
  });

  const visibleContact = useVisibleContact({ contact, fieldConfig });
  const oldVisibleContact = useVisibleContact({
    contact: oldContact,
    fieldConfig,
  });

  const [updateContact] = useUpdateContact({});

  useEffect(() => {
    if (loading) return; // Do not compare out of date contact.

    if (!oldVisibleContact || !visibleContact) {
      // Do not show card if we do not have data to compare.
      setIsUpdated(false);
    } else if (oldVisibleContact._contactId !== visibleContact._contactId) {
      // Do not show card if we are comparing different contacts
      setIsUpdated(false);
    } else {
      setIsUpdated(
        !equal(
          JSON.parse(JSON.stringify(oldVisibleContact), omitEmptyFields),
          JSON.parse(JSON.stringify(visibleContact), omitEmptyFields),
        ),
      );
    }
  }, [loading, visibleContact, oldVisibleContact]);

  const replaceContact = useCallback(() => {
    const updateEvent = {
      target: {
        name,
        value: { ...contact, ...oldVisibleContact },
        type: 'Contact',
      },
    };
    onChange(updateEvent);
    onBlur(updateEvent);
  }, [name, oldVisibleContact, contact, onChange, onBlur]);

  const updateStoredContact = useCallback(async () => {
    try {
      setIsFormUpdating(true);
      await updateContact(contact._contactId, visibleContact);
    } finally {
      setIsFormUpdating(false);
    }
  }, [setIsFormUpdating, updateContact, contact, visibleContact]);

  if (!isUpdated) return null;

  return (
    <UpdateContactCardContainer>
      <UpdateMessage>
        <InfoIconContainer>
          <InfoIcon />
        </InfoIconContainer>
        <WarningMessage>
          The information entered above does not match the saved contact
          information.
        </WarningMessage>
        <div>
          <CloseButton onClick={() => setIsUpdated(false)} />
        </div>
      </UpdateMessage>
      <UpdateButtons>
        <UpdateButton onClick={updateStoredContact}>
          Save Changes to Contact
        </UpdateButton>
        <UpdateHelper>
          Save the information above to your Contact List.
        </UpdateHelper>
        <UpdateButton onClick={replaceContact}>
          Restore with Last Saved
        </UpdateButton>
        <ReplaceHelper>
          Use the most recently saved information for this contact.
        </ReplaceHelper>
      </UpdateButtons>
    </UpdateContactCardContainer>
  );
}
