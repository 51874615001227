import { gql, useQuery } from '@apollo/client';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { handleException } from 'utils/ErrorUtils';

import { useQueryParam, useQueryParams } from '../../hooks/useQueryParam';
import {
  MatterListViewItem,
  MatterViewFragment,
} from '../matter/list/MatterListViewItem';
import Button from '../standard/Button';
import ClipboardImage from '../standard/ClipboardImage';
import DebouncedSearchBar from '../standard/DebouncedSearchBar';
import Header from '../standard/Header';
import { InfiniteListView } from '../standard/InfiniteListView';
import ListViewItem from '../standard/ListViewItem';
import NoResultsFound from '../standard/NoResultsFound';
import SortButton, { getMatterSortKey } from './SortButton';
import TemplateFilters from './TemplateFilters';

const DEFAULT_COUNT = 25;
const BATCH_SIZE = 25;

const GET_TEAM_MATTERS = gql`
  query getTeamMatters(
    $first: Int
    $after: String
    $sort: SortFirmMatterConnectionMatterEnum
    $userId: String!
    $owner: String
    $organization: MongoID
    $query: String
  ) {
    firmUser(id: $userId) {
      id
      firms {
        _id
        mattersConnection(
          first: $first
          after: $after
          sort: $sort
          filter: {
            name: $query
            owner: $owner
            organization: $organization
            excludeInProgressLegacyQuestionnaires: true
          }
        ) {
          count
          edges {
            cursor
            node {
              ...MatterView
            }
          }
        }
      }
    }
  }
  ${MatterViewFragment}
`;

const Contracts = ({ query, sort, ownedByMe, organization }) => {
  const userId = localStorage.getItem('userId');
  const history = useHistory();

  const hasMountedRef = useRef();
  const infiniteLoaderRef = useRef();

  /* eslint-disable no-param-reassign */
  const [connection, dispatch] = useImmerReducer(
    (draft, action = {}) => {
      const {
        type,
        index,
        connection: { count = 0, edges = [] } = {},
      } = action;

      switch (type) {
        case 'insert': {
          draft.count = count;
          draft.edges.splice(index, edges.length, ...edges);
          break;
        }
        case 'delete': {
          draft.count -= 1;
          draft.edges.splice(index, 1);
          break;
        }
        case 'clear': {
          draft.count = DEFAULT_COUNT;
          draft.edges = [];
          break;
        }
        default: {
          break;
        }
      }
    },
    {
      count: DEFAULT_COUNT,
      edges: [],
    },
  );
  /* eslint-enable no-param-reassign */

  useEffect(() => {
    // We only need to reset cached items when a query variable changes.
    // This effect will run on mount too; there's no need to reset in that case.
    if (hasMountedRef.current) {
      if (infiniteLoaderRef.current) {
        dispatch({ type: 'clear' });
        infiniteLoaderRef.current.resetLoadMoreRowsCache();
      }
    }
    hasMountedRef.current = true;
  }, [hasMountedRef, dispatch, userId, ownedByMe, organization, query, sort]);

  const { fetchMore, loading } = useQuery(GET_TEAM_MATTERS, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      first: BATCH_SIZE,
      sort: getMatterSortKey(sort),
      userId,
      owner: ownedByMe ? userId : undefined,
      organization,
      query,
    },
    onCompleted: (data) => {
      dispatch({
        type: 'insert',
        index: 0,
        connection: data?.firmUser?.firms?.[0]?.mattersConnection ?? {},
      });
    },
    onError: (error) => handleException(error),
  });

  return (
    <InfiniteListView
      loading={loading}
      connection={connection}
      infiniteLoaderRef={infiniteLoaderRef}
      fetchMore={async ({ first, after, index }) => {
        const { data } = await fetchMore({
          variables: {
            userId,
            owner: ownedByMe ? userId : undefined,
            organization,
            query,
            first,
            after,
            sort: getMatterSortKey(sort),
          },
        });

        dispatch({
          type: 'insert',
          index,
          connection: data?.firmUser?.firms?.[0]?.mattersConnection ?? {},
        });
      }}
      renderRow={({ index, key, style }) => {
        const matter = connection?.edges?.[index]?.node;

        if (!matter) {
          return (
            <ListViewItem
              key={key}
              style={style}
              title={
                <>
                  <FontAwesomeIcon icon={faSpinner} pulse />
                  &nbsp; loading...
                </>
              }
            />
          );
        }

        return (
          <MatterListViewItem
            key={key}
            style={style}
            matter={matter}
            onDelete={() => dispatch({ type: 'delete', index })}
            menuItems={[
              matter?.matterVersion === 1
                ? {
                    label: 'Review Questionnaire',
                    onClick: (e) => {
                      e.stopPropagation();
                      const clientPath = matter.organization
                        ? `/clients/${matter.organization._id}`
                        : '';
                      history.push(
                        `${clientPath}/contracts/${matter.matterId}/edit`,
                      );
                    },
                  }
                : null,
            ]}
          />
        );
      }}
      emptyList={
        !query && !ownedByMe && !organization ? (
          <div className="offset-xl-3 col-xl-6 mt-60">
            <div className="card-body align-items-center text-center">
              <ClipboardImage />
              <h5 className="mb-15">You haven&apos;t created any documents.</h5>
              <Link to="/new-contract">
                <Button className="d-inline-block">
                  Choose a Template to Get Started
                </Button>
              </Link>
            </div>
          </div>
        ) : (
          <NoResultsFound searchQuery={query} />
        )
      }
    />
  );
};

const Content = () => {
  const [query, setQuery] = useQueryParam('query');
  const [sort, setSort] = useQueryParam('sort');
  const [filters, setFilters] = useQueryParams(['ownedByMe', 'organization'], {
    queryParseOpts: { parseBooleans: true },
  });
  const { ownedByMe, organization } = filters;

  return (
    <div>
      <div className="row mb-30">
        <div className="col-xl-3 col-4 mt-3">
          <TemplateFilters filters={filters} onFilterChange={setFilters} />
        </div>
        <div className="col-xl-9 col-8">
          <div className="row">
            <div className="col-xl-9 col-lg-8 col-7">
              <DebouncedSearchBar
                placeholder="Search matters"
                setSearchQuery={setQuery}
                searchQuery={query}
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-5">
              <SortButton sort={sort} setSort={setSort} />
            </div>
          </div>
          <div className="row mt-30">
            <div className="col-12">
              <Contracts
                query={query}
                sort={sort}
                ownedByMe={ownedByMe}
                organization={organization}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function ContractRepository() {
  return (
    <div>
      <Header
        title="Team Matters"
        subtitle="Access matters that you and your team have created."
      />
      <Content />
    </div>
  );
}
