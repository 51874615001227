import { gql, useQuery } from '@apollo/client';
import queryString from 'query-string';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';
import { handleException } from 'utils/ErrorUtils';

import { useOrganizationPermission } from '../../context/OrganizationPermissionContext';
import { useQueryParam } from '../../hooks/useQueryParam';
import { trackMatterInitialized } from '../../services/Analytics';
import { getStrippedUserId } from '../../services/Utilities';
import Header from '../standard/Header';
import { TemplatesList, TemplatesListItem } from '../templates/TemplatesList';

const GET_CLIENT_TEMPLATES = gql`
  query getClientTemplates(
    $userId: MongoID!
    $public: Boolean
    $searchQuery: String
    $statuses: [EnumContractTemplateStatus]
  ) {
    client(_id: $userId) {
      _id
      organizationDetails {
        _id
        templates(
          filter: {
            public: $public
            nameRegEx: $searchQuery
            archived: false
            statuses: $statuses
          }
          sort: NAME_ASC
        ) {
          _id
          name
          description
          status
        }
      }
    }
  }
`;

const Container = styled.div`
  padding: 0 1rem;
`;

const StyledHeader = styled(Header)`
  margin-top: 0;
`;

export default () => {
  const userId = getStrippedUserId();
  const [dealname] = useQueryParam('dealname');
  const [templates, setTemplates] = useState([]);
  const [searchQuery, setSearchQuery] = useState(null);
  const { canCreateNewMatters } = useOrganizationPermission();
  const { loading } = useQuery(GET_CLIENT_TEMPLATES, {
    variables: {
      userId,
      searchQuery,
      public: localStorage.getItem('hidePublicTemplates') !== 'true',
      statuses: ['active', 'draft'],
      skip: !canCreateNewMatters,
    },
    onCompleted: (data) => {
      setTemplates(data?.client?.organizationDetails?.[0]?.templates ?? []);
    },
    onError: (error) => {
      toast.error(
        'Oops! Something went wrong loading the list of templates. Please try again later.',
      );
      handleException(error);
    },
  });

  const onSelect = useCallback(
    (template) => {
      const query = queryString.stringify({
        open: true,
        page: 1,
        templateId: template._id,
        type: 'template',
        defaultName: dealname,
      });
      window.open(`/client/matters?${query}`, '_blank');
      window.parent.postMessage(JSON.stringify({ action: 'DONE' }), '*');

      trackMatterInitialized({
        type: 'templated contract',
        source: 'hubspot',
        templateName: template.name,
        templateId: template._id,
      });
    },
    [dealname],
  );

  if (!canCreateNewMatters) {
    return <div>You are not authorized to create new matters.</div>;
  }

  return (
    <Container>
      <StyledHeader
        title="New Matter"
        subtitle="Select a matter template from the list below."
      />
      <TemplatesList
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        isLoading={loading}
      >
        {templates.map((template) => (
          <TemplatesListItem
            key={template._id}
            template={template}
            onClick={() => onSelect(template)}
          />
        ))}
      </TemplatesList>
    </Container>
  );
};
