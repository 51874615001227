import { gql, useQuery } from '@apollo/client';
import { useFeatureToggle } from '@flopflip/react-broadcast';
import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { flagNames } from '../../../featureFlags';
import { userIsClient } from '../../../services/Utilities';
import { PopperButton } from '../../standard/PopperButton';
import {
  AssignToClientAction,
  DeleteMatterAction,
  DownloadAction,
  EditQuestionnaireAction,
  ExportDataAction,
  RequestFirmReviewAction,
  SendForSigningAction,
  SendToDriveAction,
  ShareWithClientAction,
} from './actions';

const StyledPopperButton = styled(PopperButton)`
  min-width: 200px;
  margin-top: 1.5rem;
  @media (max-width: 575px) {
    margin-top: 0;
  }
`;

const GET_CLIENT_USERS = gql`
  query getFirmMatterHeaderClientUsers(
    $userId: String!
    $firmId: MongoID!
    $clientId: MongoID!
  ) {
    firmUser(id: $userId) {
      id
      firm(id: $firmId) {
        _id
        client(org: $clientId) {
          _id
          clientUsers {
            _id
          }
        }
      }
    }
  }
`;

export default function MatterViewActions({
  matter,
  allowMatterManagement,
  currentFile,
  revokeClientAccess,
  setDisplayReviewModal,
  updateMatterOrganization,
  deleteMatter,
  setDisplaySendForSigning,
  setDisplayShareModal,
}) {
  const { _id: clientId } = matter.organization || {};
  const { folderId, folderToken: { accessToken } = {} } =
    matter.workspaceFolder || {};
  const userId = localStorage.getItem('userId');
  const firmId = localStorage.getItem('firmId');
  const isFirmUser = !userIsClient();
  const enableExportToGoogleDrive = useFeatureToggle(
    flagNames.ENABLE_EXPORT_TO_GOOGLE_DRIVE,
  );
  const enableRequestFirmReview = useFeatureToggle(
    flagNames.ENABLE_REQUEST_FIRM_REVIEW,
  );

  const canDelete =
    allowMatterManagement ||
    (matter.owner === userId.replace(/^.*\|/, '') && matter.canRead);

  const [clientUsers, setClientUsers] = useState([]);

  useQuery(GET_CLIENT_USERS, {
    fetchPolicy: 'cache-first',
    skip: !clientId,
    variables: { userId, firmId, clientId },
    onCompleted: (getClientUsersData) => {
      setClientUsers(
        getClientUsersData?.firmUser?.firm?.client?.clientUsers ?? [],
      );
    },
  });

  return (
    <StyledPopperButton label="Actions">
      {isFirmUser && !!clientUsers.length && (
        <ShareWithClientAction
          matter={matter}
          setDisplayShareModal={setDisplayShareModal}
          revokeClientAccess={revokeClientAccess}
        />
      )}
      {!isFirmUser && enableRequestFirmReview && (
        <RequestFirmReviewAction
          setDisplayReviewModal={setDisplayReviewModal}
        />
      )}
      {!!currentFile?.id && (
        <SendForSigningAction
          setDisplaySendForSigning={setDisplaySendForSigning}
        />
      )}
      {allowMatterManagement && !!matter.hasQuestionnaire && (
        <EditQuestionnaireAction />
      )}
      {enableExportToGoogleDrive && !!currentFile?.id && (
        <SendToDriveAction
          matterId={matter.id}
          folderId={folderId}
          accessToken={accessToken}
        />
      )}
      {allowMatterManagement && !!matter.html && (
        <DownloadAction name={matter.name} html={matter.html} />
      )}
      {allowMatterManagement && !!matter.data && (
        <ExportDataAction name={matter.name} data={matter.data} />
      )}
      {isFirmUser && !matter.organization && (
        <AssignToClientAction
          updateMatterOrganization={updateMatterOrganization}
        />
      )}
      {canDelete && !!deleteMatter && (
        <DeleteMatterAction deleteMatter={deleteMatter} />
      )}
    </StyledPopperButton>
  );
}
