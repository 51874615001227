import { useFeatureToggle } from '@flopflip/react-broadcast';
import { faPenFancy } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components/macro';

import { flagNames } from '../../../featureFlags';
import ContractPreview from '../../contracts/ContractPreview';
import { NeutralButton } from '../../standard/Button';
import ClipboardImage from '../../standard/ClipboardImage';
import MatterFilePreview from './MatterFilePreview';

const EditButtonContainer = styled.div`
  float: right;
  margin-bottom: 1.5rem;
`;

const EmptyStateContainer = styled.div`
  border: 1px solid #ebebeb;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 444px;
`;

const EmptyState = styled.div`
  text-align: center;
`;

const SUPPORTED_EDITABLE_FILE_TYPES = ['docx', 'doc'];

function MatterPreview({
  html,
  fileId,
  accessToken,
  currentFile,
  setCurrentFile,
  setEditMode,
}) {
  const enableMatterDocumentEditor = useFeatureToggle(
    flagNames.MATTER_DOCUMENT_EDITOR,
  );

  if (html) {
    return (
      <div className="col-12">
        <ContractPreview contract={{ html }} />
      </div>
    );
  }

  if (currentFile && currentFile.extension === 'pdf') {
    return (
      <div className="col-12 col-xl-8">
        <EmptyStateContainer>
          <EmptyState>
            <ClipboardImage />
            <h5 className="mb-30">
              PDF preview is currently disabled. Please download the file for
              viewing.
            </h5>
          </EmptyState>
        </EmptyStateContainer>
      </div>
    );
  }

  return (
    <div className="col-12 col-xl-8">
      {currentFile ? (
        <>
          {enableMatterDocumentEditor &&
            SUPPORTED_EDITABLE_FILE_TYPES.includes(currentFile.extension) && (
              <EditButtonContainer>
                <NeutralButton
                  outline
                  icon={<FontAwesomeIcon icon={faPenFancy} />}
                  onClick={() => setEditMode(true)}
                >
                  Edit document
                </NeutralButton>
              </EditButtonContainer>
            )}
          <MatterFilePreview
            fileId={fileId}
            token={accessToken}
            onLoad={({ file }) => {
              // Fill in any missing data from the initial file load.
              setCurrentFile(file);
              // Close sidebar if open on initial load
              const sidebarToggleButtonActive =
                document.querySelector('.bcs-is-selected');
              if (sidebarToggleButtonActive) {
                sidebarToggleButtonActive.click();
              }
            }}
            contentSidebarProps={{
              detailsSidebarProps: {
                hasProperties: true,
                hasVersions: true,
              },
              hasVersions: true,
            }}
          />
        </>
      ) : (
        <EmptyStateContainer>
          <EmptyState>
            <ClipboardImage />
            <h5 className="mb-30">
              Select a file from Matter Files or upload one to preview it here.
            </h5>
          </EmptyState>
        </EmptyStateContainer>
      )}
    </div>
  );
}

export default MatterPreview;
