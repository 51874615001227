import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ClickAwayListener,
  MenuItem as MuiMenuItem,
  MenuList,
  Popper as MuiPopper,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

const MenuItem = styled(MuiMenuItem)`
  && {
    min-height: 2rem;
  }
`;

const MenuItemIcon = styled(FontAwesomeIcon)`
  margin-right: 1rem;
  font-size: 0.8125rem;
  width: 0.8125rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuItemTitle = styled.div.attrs({
  className: 'rally-typography rally-typography-p', // conditionally h6 if there's a subtitle?
})`
  margin-bottom: 0;
`;

const MenuItemSubtitle = styled.div.attrs({
  className: 'rally-typography rally-typography-p',
})`
  && {
    color: #60666f;
    font-weight: 300;
  }
`;

const MenuItemSpinner = styled(FontAwesomeIcon)`
  position: absolute;
  left: 50%;
  top: 30%;
`;

const MenuPopper = styled(MuiPopper)`
  min-width: ${({ anchorEl }) => anchorEl?.getBoundingClientRect().width}px;
  z-index: 999;
`;

const Menu = styled.div`
  color: #4d5259;
  border-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 13px;
  border-color: #f1f2f3;
  padding: 0;
  box-shadow: 0 3px 12px rgb(0 0 0 / 9%);
  z-index: 992;
  background-color: #fff;
`;

export function PopperMenuItem({
  title,
  subtitle,
  icon,
  onClick,
  loading,
  disabled,
  ...props
}) {
  return (
    <MenuItem
      onClick={onClick}
      disabled={loading || disabled}
      disableRipple
      {...props}
    >
      {loading && <MenuItemSpinner size="lg" icon={faSpinner} pulse />}
      {icon && <MenuItemIcon icon={icon} />}
      <TitleWrapper>
        <MenuItemTitle>{title}</MenuItemTitle>
        {subtitle && <MenuItemSubtitle>{subtitle}</MenuItemSubtitle>}
      </TitleWrapper>
    </MenuItem>
  );
}

export function PopperMenu({
  anchorEl,
  open = true,
  setOpen = () => {},
  placement = 'bottom-end',
  children,
  ...props
}) {
  const handleClose = (event) => {
    if (anchorEl?.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <MenuPopper
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      transition
      {...props}
    >
      <Menu>
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList disablePadding>{children}</MenuList>
        </ClickAwayListener>
      </Menu>
    </MenuPopper>
  );
}

PopperMenu.propTypes = {
  anchorEl: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  placement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top',
  ]),
};
