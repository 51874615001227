import { gql, useMutation } from '@apollo/client';
import * as Icons from '@fortawesome/pro-light-svg-icons';
import {
  faCopy,
  faPencil,
  faPlus,
  faPlusCircle,
  faShare,
  faTrash,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControlLabel } from '@material-ui/core';
import * as clipboard from 'clipboard-polyfill/text';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';
import { handleException } from 'utils/ErrorUtils';

import { useCategories } from '../../client/categories/hooks';
import Badge from '../../standard/Badge';
import { ConfirmationDialog } from '../../standard/ConfirmationDialog';
import Spinner from '../../standard/Spinner';
import Switch from '../../standard/Switch';
import { CreateMatterCategoryDialog } from './CreateMatterCategoryDialog';
import { EditMatterCategoryDialog } from './EditMatterCategoryDialog';
import { MoveMatterCategoryDialog } from './MoveMatterCategoryDialog';

const CREATE_MATTER_CATEGORY = gql`
  mutation CreateCategory($input: CreateCategoryInput) {
    createCategory(input: $input) {
      recordId
      record {
        _id
        name
        icon
        archived
      }
    }
  }
`;

const UPDATE_MATTER_CATEGORY = gql`
  mutation UpdateCategory($input: UpdateCategoryInput) {
    updateCategory(input: $input) {
      recordId
      record {
        _id
        name
        icon
        archived
      }
    }
  }
`;

const ARCHIVE_MATTER_CATEGORY = gql`
  mutation ArchiveCategory($input: ArchiveCategoryInput) {
    archiveCategory(input: $input) {
      recordId
      record {
        _id
        name
        icon
        archived
      }
    }
  }
`;

const MOVE_MATTER_CATEGORY = gql`
  mutation MoveCategory($input: MoveCategoryInput) {
    moveCategory(input: $input) {
      recordId
      record {
        _id
        name
      }
    }
  }
`;

const CategoryActionButton = styled.button`
  border: none;
  background-color: white;

  color: ${({ theme, palette = 'primary' }) => theme.palette?.[palette].light};

  &:hover {
    color: ${({ theme, palette = 'primary' }) => theme.palette?.[palette].dark};
  }
`;

const CardTitle = styled.div.attrs({ className: 'card-title' })`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export function Category({
  name,
  categoryId,
  icon = '',
  archived = false,
  childCategories = [],
  onClickAddSubCategory = () => {},
  onClickChangeParent = () => {},
  onClickEditCategory = () => {},
  onClickArchiveCategory = () => {},
  ...props
}) {
  return (
    <div {...props}>
      <span>
        {!!icon && !!Icons[icon] && (
          <>
            <FontAwesomeIcon icon={Icons[icon]} />
            &nbsp;
          </>
        )}
        {name} - {categoryId}
        <CategoryActionButton
          onClick={async () => {
            await clipboard.writeText(categoryId);
            toast.info('Category ID copied to clipboard');
          }}
          title="Copy ID to Clipboard"
        >
          <FontAwesomeIcon icon={faCopy} />
        </CategoryActionButton>
        {archived ? (
          <>
            &nbsp;<Badge badgeStyle="destructive">Archived</Badge>
          </>
        ) : (
          <>
            <CategoryActionButton
              onClick={() => onClickEditCategory({ categoryId, name, icon })}
              title="Edit Category"
            >
              <FontAwesomeIcon icon={faPencil} />
            </CategoryActionButton>
            <CategoryActionButton
              onClick={() => onClickChangeParent({ categoryId, name })}
              title="Change Parent"
            >
              <FontAwesomeIcon rotation={180} icon={faShare} />
            </CategoryActionButton>
            <CategoryActionButton
              onClick={() => onClickAddSubCategory(categoryId)}
              title="Add Sub-Category"
            >
              <FontAwesomeIcon icon={faPlusCircle} />
            </CategoryActionButton>
            <CategoryActionButton
              onClick={() => onClickArchiveCategory({ categoryId, name })}
              title="Archive Category"
              palette="destructive"
            >
              <FontAwesomeIcon icon={faTrash} />
            </CategoryActionButton>
          </>
        )}
      </span>
      <ul>
        {childCategories.map((child) => (
          <li key={child.id}>
            <Category
              id={child.id}
              name={child.name}
              categoryId={child.id}
              archived={child.archived}
              childCategories={child.children}
              onClickAddSubCategory={onClickAddSubCategory}
              onClickEditCategory={onClickEditCategory}
              onClickChangeParent={onClickChangeParent}
              onClickArchiveCategory={onClickArchiveCategory}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

export function MatterCategoryEditor() {
  const [showArchivedCategories, setShowArchivedCategories] = useState(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [addCategoryParentId, setAddCategoryParentId] = useState(undefined);
  const [categoryToEdit, setCategoryToEdit] = useState(undefined);
  const [categoryToMove, setCategoryToMove] = useState(undefined);
  const [categoryToArchive, setCategoryToArchive] = useState(undefined);

  const [categories, { refetch, loading }] = useCategories({
    includeArchived: showArchivedCategories,
    usePermissionsFilter: false,
  });

  const [createCategory, { loading: creatingCategory }] = useMutation(
    CREATE_MATTER_CATEGORY,
    {
      onCompleted: async () => {
        await refetch();
        toast.success('created new category');
      },
      onError: (error) => {
        toast.error(
          'Oops! Something went wrong creating the category. Please try again later.',
        );
        handleException(error);
      },
    },
  );

  const [updateCategory, { loading: updatingCategory }] = useMutation(
    UPDATE_MATTER_CATEGORY,
    {
      onCompleted: async () => toast.success('updated category'),
      onError: (error) => {
        toast.error(
          'Oops! Something went wrong updating the category. Please try again later.',
        );
        handleException(error);
      },
    },
  );

  const [moveCategory, { loading: movingCategory }] = useMutation(
    MOVE_MATTER_CATEGORY,
    {
      onCompleted: async () => {
        await refetch();
        toast.success('moved category');
      },
      onError: (error) => {
        toast.error(
          'Oops! Something went wrong moving the category. Please try again later.',
        );
        handleException(error);
      },
    },
  );

  const [archiveCategory, { loading: archivingCategory }] = useMutation(
    ARCHIVE_MATTER_CATEGORY,
    {
      onCompleted: async () => {
        toast.success('archived category');
        await refetch();
      },
      onError: (error) => {
        toast.error(
          'Oops! Something went wrong archiving the category. Please try again later.',
        );
        handleException(error);
      },
    },
  );

  const onCreateMatterCategorySubmit = useCallback(
    async ({ name }) => {
      await createCategory({
        variables: {
          input: {
            name,
            parentId: addCategoryParentId,
          },
        },
      });

      setIsCreateDialogOpen(false);
      setAddCategoryParentId(undefined);
    },
    [addCategoryParentId, createCategory],
  );

  const onEditMatterCategorySubmit = useCallback(
    async ({ categoryId, name, icon }) => {
      await updateCategory({
        variables: {
          input: {
            categoryId,
            update: {
              name,
              icon,
            },
          },
        },
      });

      setCategoryToEdit(undefined);
    },
    [updateCategory],
  );

  const onChangeParentMatterCategorySubmit = useCallback(
    async ({ categoryId, parentId }) => {
      await moveCategory({
        variables: {
          input: {
            categoryId,
            parentId,
          },
        },
      });

      setCategoryToMove(undefined);
    },
    [moveCategory],
  );

  const onArchiveMatterCategorySubmit = useCallback(
    async ({ categoryId }) => {
      await archiveCategory({
        variables: {
          input: {
            categoryId,
          },
        },
      });

      setCategoryToArchive(undefined);
    },
    [archiveCategory],
  );

  return (
    <div className="col-lg-10 mt-30">
      {(creatingCategory ||
        updatingCategory ||
        movingCategory ||
        archivingCategory ||
        loading) && <Spinner />}
      <div className="card">
        <CardTitle>
          <div>
            <h4>
              <strong>Admin: Matter Categories</strong>
            </h4>
          </div>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={showArchivedCategories}
                  onChange={(event) =>
                    setShowArchivedCategories(event.target.checked)
                  }
                />
              }
              label="Show Archived"
            />
          </div>
        </CardTitle>
        <div className="cardBody">
          <div>
            <CategoryActionButton onClick={() => setIsCreateDialogOpen(true)}>
              <FontAwesomeIcon icon={faPlus} /> Add Top-Level Category
            </CategoryActionButton>
          </div>
          <div>
            <ul>
              {categories?.map(({ id, name, icon, archived, children }) => (
                <li key={id}>
                  <Category
                    id={id}
                    name={name}
                    icon={icon}
                    archived={archived}
                    categoryId={id}
                    childCategories={children}
                    onClickAddSubCategory={(parentId) => {
                      setAddCategoryParentId(parentId);
                      setIsCreateDialogOpen(true);
                    }}
                    onClickEditCategory={setCategoryToEdit}
                    onClickChangeParent={setCategoryToMove}
                    onClickArchiveCategory={setCategoryToArchive}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <CreateMatterCategoryDialog
        open={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
        onSubmit={onCreateMatterCategorySubmit}
      />
      <EditMatterCategoryDialog
        open={!!categoryToEdit}
        onClose={() => setCategoryToEdit(undefined)}
        onSubmit={onEditMatterCategorySubmit}
        categoryId={categoryToEdit?.categoryId ?? ''}
        name={categoryToEdit?.name ?? ''}
        icon={categoryToEdit?.icon ?? ''}
      />
      <MoveMatterCategoryDialog
        open={!!categoryToMove}
        categories={categories}
        onClose={() => setCategoryToMove(undefined)}
        onSubmit={onChangeParentMatterCategorySubmit}
        categoryId={categoryToMove?.categoryId ?? ''}
        name={categoryToMove?.name ?? ''}
      />
      <ConfirmationDialog
        open={!!categoryToArchive}
        onClose={() => setCategoryToArchive(undefined)}
        title="Archive Category"
        message={`Are you sure you want to archive ${categoryToArchive?.name}`}
        confirmText="Archive"
        confirmButtonPalette="destructive"
        onConfirm={() =>
          onArchiveMatterCategorySubmit({
            categoryId: categoryToArchive?.categoryId,
          })
        }
      />
    </div>
  );
}
