import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { trackViewCheckout } from '../../services/Analytics';
import Header from '../standard/Header';
import LawPayForm from './LawPayForm';

const PaymentScreen = ({
  matter: {
    id: matterId,
    template: service,
    paymentDetails: { publicKey } = {},
    isPaid,
    canSkipPayment = true,
    payBeforeService = false,
  },
}) => {
  const history = useHistory();

  useEffect(trackViewCheckout, []);

  if (isPaid) {
    history.replace(`/client/thankyou`);
  }

  return (
    <>
      <Header title="Payment" />
      <LawPayForm
        matterId={matterId}
        service={service}
        publicKey={publicKey}
        canSkipPayment={canSkipPayment}
        payBeforeService={payBeforeService}
      />
    </>
  );
};
export default PaymentScreen;
