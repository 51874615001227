import { faShare } from '@fortawesome/pro-light-svg-icons';
import React from 'react';

import { PopperMenuItem } from '../../../standard/PopperMenu';

function RequestFirmReviewAction({ setDisplayReviewModal }) {
  return (
    <>
      <PopperMenuItem
        icon={faShare}
        title="Request Firm Review"
        onClick={() => setDisplayReviewModal(true)}
      />
    </>
  );
}

export default RequestFirmReviewAction;
