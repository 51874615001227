import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextField from '@material-ui/core/TextField';
import MaterialAutocomplete from '@material-ui/lab/Autocomplete';
import hexRgb from 'hex-rgb';
import React, { forwardRef, memo } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import ReactTooltip from 'react-tooltip';
import styled, { css, withTheme } from 'styled-components/macro';

import { SIDEBAR_ALWAYS_OPEN_WIDTH } from '../LayoutConstants';

const palette = withTheme({
  main: (props) => props.theme.palette.primary.main,
  mainFade: (props) =>
    hexRgb(props.theme.palette.primary.main, { format: 'css', alpha: 0.1 }),
  secondary: (props) => props.theme.palette.secondary.main,
  text: (props) => props.theme.palette.gray.dark,
  textLight: (props) => props.theme.palette.gray.light,
  textFade: (props) =>
    hexRgb(props.theme.palette.gray.dark, { format: 'css', alpha: 0.8 }),
  textContrast: (props) =>
    hexRgb(props.theme.palette.primary.contrastText, { format: 'css' }),
  destructive: (props) => props.theme.palette.destructive.main,
  destructiveFade: (props) =>
    hexRgb(props.theme.palette.destructive.main, { format: 'css', alpha: 0.1 }),
});

const bodyText = (props) => css`
  font-size: ${props.fontSize || '18px'};
  font-weight: normal;
  letter-spacing: 0.02em;
  line-height: 168%;
  color: ${props.theme.palette.gray.dark};
  margin-bottom: 0;
`;

const headerText = (props) => css`
  font-weight: bold;
  font-size: 24px;
  line-height: 168%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: ${props.theme.palette.gray.dark};
`;

const labelText = ({ variant }) => {
  if (variant === 'small')
    return css`
      font-size: 14px;
      font-weight: 500;
      -webkit-font-smoothing: unset;
    `;
  if (variant === 'medium')
    return css`
      font-size: 18px;
      font-weight: bold;
      -webkit-font-smoothing: antialiased;
    `;
  return css`
    font-size: 24px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
  `;
};

export const border = css`
  border: 0.25px solid #cacaca;
  box-sizing: border-box;
  border-radius: 5px;
`;

const errorBorder = (props) => css`
  border: 1px solid ${props.theme.palette.destructive.main};
  box-sizing: border-box;
  border-radius: 4px;
  && {
    &:hover {
      border: 1px solid ${props.theme.palette.destructive.main};
    }
    &:focus {
      border: 1px solid ${props.theme.palette.destructive.main};
      box-shadow: 0 0 0 7px
        ${hexRgb(props.theme.palette.destructive.main, {
          format: 'css',
          alpha: 0.1,
        })};
      outline: none;
    }
  }
`;

export const MainContent = styled.div`
  padding-bottom: -60px;
  flex: 1 0 auto;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  @media (max-width: 576px) {
    padding: 0px;
  }
`;

export const Input = styled.input`
  ${(props) => bodyText(props)};
  ${(props) => (props.errors?.length ? errorBorder(props) : border)};
  color: ${palette.textLight};
  height: ${(props) => (props.smallfield ? '48px' : '60px')};
  font-size: ${(props) => (props.smallfield ? '14px' : '18px')};
  width: 100%;
  margin-top: ${(props) => (props.smallfield ? '8px' : '15px')};
  padding: 15px;
  &:hover {
    border: 1px solid ${palette.main};
  }
  &:focus {
    border: 1px solid ${palette.main};
    box-shadow: 0 0 0 7px ${palette.mainFade};
    outline: none;
    color: ${palette.text};
  }
  &::placeholder {
    color: #8b95a5;
  }
`;

export const InputPrefix = styled.span`
  font-size: 30px;
  padding-top: 32px;
  padding-right: 10px;
`;

export const InputSuffix = styled.span`
  font-size: 30px;
  padding-top: 32px;
  padding-left: 10px;
  padding-right: 10px;
`;

export const Textarea = styled(Input)`
  height: auto;
`;

export const Label = styled.label`
  && {
    ${(props) => headerText(props)};
    margin-bottom: 0;
    display: inline;
    ${(props) => labelText(props)};
  }
  p {
    display: inline;
  }
  &.require::after {
    ${(props) => labelText(props)};
    ${({ hasTooltip }) => hasTooltip && 'margin-left: -2px;'};
  }
`;

export const Helper = styled.p`
  ${(props) => bodyText(props)};
  font-family: ${(props) =>
    props.theme.secondaryFont
      ? props.theme.secondaryFont.family
      : 'Georgia, serif'};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 5px;
  margin-top: 10px;
  line-height: 180%;
  strong {
    font-weight: bold;
  }
  b {
    font-weight: bold;
  }
  br {
    line-height: 3rem;
  }
  ol {
    margin-top: -1rem;
  }
`;

export const AutosuggestStyle = styled.div`
  .react-autosuggest__input {
    ${(props) => bodyText(props)};
    ${(props) => (props.errors?.length ? errorBorder(props) : border)};
    height: ${(props) =>
      props.smallDropdown || props.smallfield ? '48px' : '60px'};
    font-size: ${(props) => (props.smallfield ? '14px' : '18px')};
    ${(props) => props.smallDropdown && 'font-size: 14px '};
    width: 100%;
    padding: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-color: ${(props) => props.smallDropdown && '#ebebeb'};
    ::-webkit-input-placeholder {
      color: #8b95a5;
    }
    &:hover {
      border: 1px solid
        ${(props) =>
          props.errors?.length ? palette.destructive : palette.main};
      cursor: text;
    }
    &:focus {
      border: 1px solid
        ${(props) =>
          props.errors?.length ? palette.destructive : palette.main};
      box-shadow: 0 0 0 7px
        ${(props) =>
          props.errors?.length ? palette.destructiveFade : palette.mainFade};
      outline: none;
    }
    &:active {
      border: 1px solid
        ${(props) =>
          props.errors?.length ? palette.destructive : palette.main};
      box-shadow: 0 0 0 7px
        ${(props) =>
          props.errors?.length ? palette.destructiveFade : palette.mainFade};
      color: ${palette.text};
      outline: none;
      background-color: white;
    }
    ${(props) =>
      props.disabled &&
      css`
        background-color: #ebebeb;
        border: none;
        padding-right: 0;
        &:hover {
          border: none;
          outline: none;
          cursor: default;
        }
        &:focus {
          border: none;
          box-shadow: none;
          outline: none;
        }
        &:active {
          border: none;
          box-shadow: none;
          outline: none;
          background-color: #ebebeb;
        }
      `}
  }
  .react-autosuggest__container {
    position: relative;
  }
  .react-autosuggest__suggestions-container--open {
    position: absolute;
    width: 100%;
    z-index: 1100;
    ${(props) => props.dropUp && 'bottom: 100%'};
    ${(props) => props.maxHeight && 'max-height: 200px'};
    overflow-y: auto;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 1px, rgba(0, 0, 0, 0.1) 0 4px 11px;
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
  }
  .react-autosuggest__suggestions-list {
    top: 100%;
    padding-left: 0;
    margin-bottom: 8px;
    margin-top: 8px;
    box-sizing: border-box;
    padding-left: 0;
  }
  .react-autosuggest__suggestion {
    background-color: transparent;
    color: rgb(77, 82, 89);
    display: block;
    font-size: inherit;
    width: 100%;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    padding: 8px 12px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:hover {
      background-color: rgb(245, 246, 250);
      color: ${palette.text};
    }
  }
  .react-autosuggest__suggestion--highlighted {
    background-color: rgb(245, 246, 250);
  }
`;

export const FloatingTextField = memo(styled(
  forwardRef(({ InputProps, InputLabelProps, smallfield, ...props }, ref) => {
    const textFieldInputProps = {
      ...InputProps,
      classes: { root: 'root', focused: 'focused', input: 'input' },
      disableUnderline: true,
    };
    if (ref != null) {
      textFieldInputProps.inputProps = { ref };
    }
    return (
      <TextField
        InputProps={textFieldInputProps}
        InputLabelProps={{
          ...InputLabelProps,
          classes: {
            root: 'rootLabel',
            focused: 'focusedLabel',
            shrink: 'shrinkLabel',
          },
        }}
        {...props}
        autoComplete="no"
      />
    );
  }),
)`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    box-shadow: 0 0 0 1000px white inset !important;
  }

  color: ${palette.textLight};
  width: 100%;
  margin-top: 15px;

  .root {
    ${border};
    margin-top: 15px;
    padding: ${(props) =>
      props.smallfield ? '13px 15px 10px 15px' : '20px 15px 8px 15px'};
    &:hover {
      border: 1px solid ${palette.main};
    }
  }
  .root.focused {
    border: 1px solid ${palette.main};
    box-shadow: 0 0 0 7px ${palette.mainFade};
    outline: none;
  }
  .input {
    ${(props) => bodyText(props)};
    height: inherit;
    color: ${palette.textLight};
    padding: 0;
    font-size: ${(props) => (props.smallfield ? '14px' : '18px')};
    &:focus {
      color: ${palette.text};
    }
  }
  .rootLabel {
    ${(props) => bodyText(props)};
    color: ${palette.textLight};
    line-height: 1;
    margin: 0;
    padding: ${(props) =>
      props.smallfield ? '8px 0 0 15px' : '13px 0 0 15px'};
    font-size: ${(props) => (props.smallfield ? '14px' : '18px')};
  }
  .rootLabel.focusedLabel {
    color: ${palette.main};
  }
  .shrinkLabel {
    padding-top: 24px;
    padding-left: 22px;
  }
`);

export const Note = styled.div`
  && {
    background: #ffffff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    border-left: 10px solid ${palette.main};
    padding: 43px 55px 30px 50px;
    @media (max-width: 768px) {
      padding: 15px 12px 1px 17px;
    }
    margin-bottom: 120px;
    p,
    ol {
      ${bodyText};
      margin-top: 0px;
      line-height: 200%;
      letter-spacing: 0.02em;
      font-family: ${(props) =>
        props.theme.secondaryFont
          ? props.theme.secondaryFont.family
          : 'Georgia, serif'};
      padding-bottom: 0;
      margin-bottom: 20px;
    }
    h1 {
      ${headerText};
      color: ${palette.text};
      margin-bottom: 20px;
    }
    a {
      color: ${palette.main};
      font-weight: bold;
    }
    b,
    strong {
      font-weight: bold;
    }
    li {
      margin-bottom: 15px;
    }
  }
`;

export const TooltipIcon = styled(FontAwesomeIcon)`
  ${(props) => labelText(props)};
  color: ${palette.main};
  padding-left: ${({ required }) => (required ? '0.7em' : '0.2em')};
`;

export const Tooltip = styled(ReactTooltip)`
  &&& {
    ${(props) => bodyText(props)};
    opacity: 1;
    color: white;
    max-width: 40%;
    background-color: ${palette.text};
    position: fixed;
    text-align: left;
    transition: none;
    padding: 8px 15px;
    &.place-right {
      ::after {
        border-right-color: ${palette.text};
      }
    }
    &.place-top {
      ::after {
        border-top-color: ${palette.text};
      }
    }
    &.place-bottom {
      ::after {
        border-bottom-color: ${palette.text};
      }
    }
    p {
      margin: 0;
    }
    strong {
      font-weight: bold;
    }
  }
`;

export const ErrorMessage = styled.div`
  ${(props) => bodyText(props)};
  color: ${palette.destructive};
  font-size: ${(props) => (props.smallfield ? '14px' : '18px')};
  margin-top: 10px;
`;

export const BodyText = styled.p`
  ${(props) => bodyText(props)};
`;

export const IntroIcon = styled.i`
  padding-left: 0;
  margin-right: 5px;
  font-size: 30px;
  vertical-align: -15%;
  color: ${palette.main};
`;

export const IntroWrapper = styled.div`
  text-align: center;
  height: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 200px auto 0 auto;
`;

export const CreatableComboBox = styled(CreatableSelect).attrs({
  classNamePrefix: 'CreatableComboBox',
})`
  .CreatableComboBox__control {
    ${(props) => (props.errors?.length ? errorBorder(props) : border)};
    width: 100%;
    margin-top: 15px;
    ${({ variant }) => {
      if (variant === 'small')
        return css`
          height: 48px;
          padding: 0 5px 0 15px;
          .CreatableComboBox__placeholder {
            font-size: 14px;
          }
        `;
      if (variant === 'medium')
        return css`
          height: 60px;
          padding: 11px 15px 15px 15px;
        `;
      return css`
        height: 60px;
        padding: 11px 15px 15px 15px;
      `;
    }}

    &:hover {
      border: 1px solid ${palette.main};
      cursor: text;
    }

    .CreatableComboBox__value-container {
      ${(props) => bodyText(props)};
      padding: 0;
      .CreatableComboBox__placeholder {
        color: ${palette.textLight};
        width: 100%;
      }
      .CreatableComboBox__single-value {
        color: ${palette.textLight};
      }
    }

    .CreatableComboBox__indicators {
      display: ${(props) => (props.hideIndicator ? 'none' : '')};
      &:hover {
        cursor: pointer;
      }
    }
  }
  .CreatableComboBox__input > input {
    font-weight: 400;
  }
  .CreatableComboBox__control--is-focused {
    border: 1px solid ${palette.main};
    box-shadow: 0 0 0 7px ${palette.mainFade};
    outline: none;
    color: ${palette.text};

    .CreatableComboBox__value-container {
      .CreatableComboBox__placeholder {
        color: ${palette.text};
      }
      .CreatableComboBox__single-value {
        color: ${palette.text};
      }
    }
  }
  .CreatableComboBox__menu {
    z-index: 1001;
    .CreatableComboBox__menu-list {
      .CreatableComboBox__option {
        &:hover {
          background-color: ${palette.mainFade};
          color: ${palette.text};
        }
      }

      .CreatableComboBox__option--is-focused {
        background-color: ${palette.mainFade};
        color: ${palette.text};
        &:hover {
          background-color: ${palette.mainFade};
          color: ${palette.text};
        }
      }

      .CreatableComboBox__option--is-selected {
        background-color: ${palette.main};
        color: ${palette.textContrast};
        &:hover {
          background-color: ${palette.main};
          color: ${palette.textContrast};
        }
      }
    }
  }
`;

export const ComboBox = styled(Select)`
  &.Select--multi {
    .Select__control {
      ${(props) => (props.errors?.length ? errorBorder(props) : border)};
      height: 60px;
      width: 100%;
      margin-top: 15px;
      padding: 11px 15px 15px 15px;

      &:hover {
        border: 1px solid ${palette.main};
        cursor: text;
      }

      .Select__value-container {
        ${(props) => bodyText(props)};
        padding: 0;
        .Select__placeholder {
          color: ${palette.textLight};
        }
        .Select__single-value {
          color: ${palette.textLight};
        }
      }

      .Select__indicators {
        &:hover {
          cursor: pointer;
        }
      }
    }
    .Select__control--is-focused {
      border: 1px solid ${palette.main};
      box-shadow: 0 0 0 7px ${palette.mainFade};
      outline: none;
      color: ${palette.text};

      .Select__value-container {
        .Select__placeholder {
          color: ${palette.text};
        }
        .Select__single-value {
          color: ${palette.text};
        }
      }
    }
    .Select__menu {
      .Select__menu-list {
        .Select__option {
          &:hover {
            background-color: ${palette.mainFade};
            color: ${palette.text};
          }
        }

        .Select__option--is-focused {
          background-color: ${palette.mainFade};
          color: ${palette.text};
          &:hover {
            background-color: ${palette.mainFade};
            color: ${palette.text};
          }
        }

        .Select__option--is-selected {
          background-color: ${palette.main};
          color: ${palette.textContrast};
          &:hover {
            background-color: ${palette.main};
            color: ${palette.textContrast};
          }
        }
      }
    }
  }
`;

export const SmallComboBox = styled(Select)`
  &.Select--multi {
    .Select__control {
      &:hover {
        border: 1px solid ${palette.main};
        cursor: text;
      }

      .Select__value-container {
        .Select__placeholder {
          color: ${palette.textLight};
        }
        .Select__single-value {
          color: ${palette.textLight};
        }
      }
    }
    .Select__control--is-focused {
      border: 1px solid ${palette.main};
      box-shadow: 0 0 0 7px ${palette.mainFade};
      outline: none;
      color: ${palette.text};

      .Select__value-container {
        .Select__placeholder {
          color: ${palette.text};
        }
        .Select__single-value {
          color: ${palette.text};
        }
      }
    }
    .Select__menu {
      .Select__menu-list {
        .Select__option {
          &:hover {
            background-color: ${palette.mainFade};
            color: ${palette.text};
          }
        }

        .Select__option--is-focused {
          background-color: ${palette.mainFade};
          color: ${palette.text};
          &:hover {
            background-color: ${palette.mainFade};
            color: ${palette.text};
          }
        }

        .Select__option--is-selected {
          background-color: ${palette.main};
          color: ${palette.textContrast};
          &:hover {
            background-color: ${palette.main};
            color: ${palette.textContrast};
          }
        }
      }
    }
  }
`;

export const StyledPlacesAutocomplete = styled.div`
  padding: ${(props) => (props.suggestions.length > 1 ? '15px' : '0')};
  border: ${(props) =>
    props.suggestions.length > 1 &&
    `1px solid ${props.theme.palette.gray.light}`};
  border-radius: 4px;
  position: absolute;
  background: white;
  z-index: 999;
  width: calc(100% - 30px);
`;

export const QuestionnaireHeaderDivider = styled.div`
  width: 100vw;
  border-bottom: 1px solid rgba(77, 82, 89, 0.07);
  height: 1px;
  position: absolute;
  left: 0;
`;

export const FormWrapper = styled.div`
  ${(props) =>
    props.sections &&
    css`
      @media (min-width: ${SIDEBAR_ALWAYS_OPEN_WIDTH}) {
        margin-left: 200px;
      }
    `};
`;

export const Autocomplete = ({
  color = 'primary',
  label,
  // eslint-disable-next-line no-unused-vars
  smallDropdown = false,
  ...props
}) => (
  <MaterialAutocomplete
    color={color}
    renderInput={(params) => <FloatingTextField {...params} label={label} />}
    fullWidth
    {...props}
  />
);

export const StyledAutocomplete = styled(Autocomplete)`
  .MuiOutlinedInput-root {
    padding: 0 !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiAutocomplete-input {
    ${(props) => bodyText(props)};
    ${(props) => (props.errors?.length ? errorBorder(props) : border)};
    height: ${(props) => (props.smallDropdown ? '48px' : '60px')};
    ${(props) => props.smallDropdown && 'font-size: 14px '};
    width: 100%;
    padding: 15px !important;
    overflow: hidden;
    white-space: nowrap;
    border-color: ${(props) => props.smallDropdown && '#ebebeb'};
    box-sizing: border-box;
    &:hover {
      border: 1px solid
        ${(props) =>
          props.errors?.length ? palette.destructive : palette.main};
      cursor: text;
    }
    &:focus {
      border: 1px solid
        ${(props) =>
          props.errors?.length ? palette.destructive : palette.main};
      box-shadow: 0 0 0 7px
        ${(props) =>
          props.errors?.length ? palette.destructiveFade : palette.mainFade};
      outline: none;
    }
    &:active {
      border: 1px solid
        ${(props) =>
          props.errors?.length ? palette.destructive : palette.main};
      box-shadow: 0 0 0 7px
        ${(props) =>
          props.errors?.length ? palette.destructiveFade : palette.mainFade};
      color: ${palette.text};
      outline: none;
      background-color: white;
    }
    ${(props) =>
      props.disabled &&
      css`
        background-color: #ebebeb;
        border: none;
        padding-right: 0;
        &:hover {
          border: none;
          outline: none;
          cursor: default;
        }
        &:focus {
          border: none;
          box-shadow: none;
          outline: none;
        }
        &:active {
          border: none;
          box-shadow: none;
          outline: none;
          background-color: #ebebeb;
        }
      `}
  }
`;
