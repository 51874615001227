import { gql, useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';

import { useUser } from '../../context/UserContext';
import { getStrippedUserId } from '../../services/Utilities';

const FETCH_FIRM_CLIENTS = gql`
  query fetchFirmClients(
    $userId: String!
    $firmId: MongoID!
    $searchQuery: String
  ) {
    firmUser(id: $userId) {
      id
      firm(id: $firmId) {
        clients(filter: { nameRegEx: $searchQuery }, sort: NAME_ASC) {
          _id
          name
        }
      }
    }
  }
`;

export default () => {
  const userId = getStrippedUserId();
  const [user] = useUser();
  const firmId = localStorage.getItem('firmId');

  const [getClients] = useLazyQuery(FETCH_FIRM_CLIENTS, {
    variables: {
      userId,
      firmId,
      includeArchived: false,
    },
  });

  useEffect(() => {
    if (user.userType === 'firm') {
      window.CommandBar.addRecords('clients', [], {
        onInputChange: async (searchQuery) => {
          const { data } = await getClients({
            variables: { searchQuery },
          });
          return data?.firmUser?.firm?.clients.map(
            ({ _id: id, name: label }) => ({
              id,
              label,
            }),
          );
        },
      });
    }
  }, [getClients, user.userType]);

  return null;
};
