import React from 'react';

import ListView from '../../standard/ListView';
import ListViewItem from '../../standard/ListViewItem';
import Modal from '../../standard/Modal';

const AddServiceModal = ({
  onSelect = (f) => f,
  onCancel = (f) => f,
  title = 'Select a Service',
  showModal = false,
  templates = [],
}) => (
  <Modal
    display={showModal}
    title={title}
    onCancel={onCancel}
    onClose={onCancel}
    showCancel
    hideAccept
  >
    <ListView>
      {templates.map((template) => (
        <ListViewItem
          key={template._id}
          id={template._id}
          title={template.name}
          description={template.description}
          onClick={() => onSelect(template)}
        />
      ))}
    </ListView>
  </Modal>
);

export default AddServiceModal;
