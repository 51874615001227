export const MAX_SIDEBAR_WIDTH = '325px';

export const SIDEBAR_WIDTH = 'min(80vw, 325px)';

export const SIDEBAR_ALWAYS_OPEN_WIDTH = '1050px';

// Copied from Bootstrap (https://getbootstrap.com/docs/5.0/layout/breakpoints/)
export const SCREEN_SIZES = {
  X_SMALL: '(max-width: 576px)',
  SMALL: '(max-width: 767px)',
  MEDIUM: '(max-width: 991px)',
  LARGE: '(max-width: 1199px)',
  X_LARGE: '(max-width: 1399px)',
};
