import React from 'react';
import { Route, Switch } from 'react-router-dom';

const MatterViewScreen = React.lazy(() =>
  import(
    '../matter/view/MatterViewScreen' /* webpackChunkName: "matterViewScreen" */
  ),
);
const TemplatedContractEditor = React.lazy(() =>
  import('./TemplatedContractEditor' /* webpackChunkName: "contractedit" */),
);

const Contracts = ({ match }) => (
  <Switch>
    <Route exact path={match.path} component={MatterViewScreen} />
    <Route path={`${match.path}/edit`} component={TemplatedContractEditor} />
  </Switch>
);

export default Contracts;
