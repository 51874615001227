import { gql, useQuery } from '@apollo/client';
import { useFeatureToggle } from '@flopflip/react-broadcast';
import React, { useContext } from 'react';
import { matchPath, NavLink, useParams } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components/macro';

import { flagNames } from '../../featureFlags';
import { isE2ETest } from '../../services/Utilities';

const ClientNavBar = styled.header`
  && {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 10px 10%;
    border-bottom: 1px solid rgba(77, 82, 89, 0.07);
    background: #ffffff;
    z-index: 1000;
  }
`;

const ClientName = styled.h2`
  margin: 10px 0 10px 0;
  font-size: 23px;
`;

const MenuContainer = styled.div`
  && {
    margin-left: auto;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 0;
  }
`;

const Menu = styled.ul.attrs({ role: 'tablist' })`
  display: flex;
  flex-direction: row;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
`;

const MenuLink = styled(NavLink).attrs({ role: 'tab' })`
  padding: 10px 12px;
  border-radius: 20px;
  letter-spacing: 0.5px;
  font-size: 14px;
  margin-left: 8px;
  margin-right: 8px;
  font-weight: 400;
  transition: none;
  color: ${(props) => props.theme.palette.gray.dark};
  :hover {
    color: ${(props) => props.theme.palette.gray.dark};
  }
`;

const activeItem = (palette) => ({
  backgroundColor: palette.primary.main,
  color: palette.primary.contrastText,
});

const GET_FIRM_HEADER_DATA = gql`
  query getFirmClientData(
    $userId: String!
    $firmId: MongoID!
    $clientId: MongoID!
  ) {
    firmUser(id: $userId) {
      id
      firm(id: $firmId) {
        _id
        client(org: $clientId) {
          clientId: _id
          name
        }
      }
    }
  }
`;

const FirmClientHeader = () => {
  const { clientId } = useParams();
  const { palette } = useContext(ThemeContext);

  const isClientTemplatesTabEnabled = useFeatureToggle(
    flagNames.CLIENT_TEMPLATES_TAB,
  );

  const userId = localStorage.getItem('userId');
  const firmId = localStorage.getItem('firmId');

  const { data } = useQuery(GET_FIRM_HEADER_DATA, {
    variables: { userId, clientId, firmId },
  });
  const org = data?.firmUser?.firm?.client;

  return (
    <ClientNavBar data-testid="client-header">
      <ClientName>{org?.name}</ClientName>
      <MenuContainer>
        <Menu>
          <li>
            <MenuLink
              exact
              to={`/clients/${clientId}`}
              activeStyle={activeItem(palette)}
            >
              Dashboard
            </MenuLink>
          </li>
          <li>
            <MenuLink
              to={`/clients/${clientId}/matters`}
              activeStyle={activeItem(palette)}
              isActive={(match, location) => {
                const matterMatch =
                  match ||
                  matchPath(location.pathname, {
                    path: `/clients/${clientId}/contracts`,
                  });
                return matterMatch;
              }}
            >
              Matters
            </MenuLink>
          </li>
          <li>
            <MenuLink
              to={`/clients/${clientId}/contacts`}
              activeStyle={activeItem(palette)}
            >
              Contacts
            </MenuLink>
          </li>
          {!!isClientTemplatesTabEnabled && (
            <li>
              <MenuLink
                as={NavLink}
                to={`/clients/${clientId}/templates`}
                activeStyle={activeItem(palette)}
              >
                Templates
              </MenuLink>
            </li>
          )}
          {!isE2ETest() && (
            <li>
              <MenuLink
                to={`/clients/${clientId}/files`}
                activeStyle={activeItem(palette)}
              >
                Files
              </MenuLink>
            </li>
          )}
          <li>
            <MenuLink
              to={`/clients/${clientId}/entity`}
              activeStyle={activeItem(palette)}
            >
              Information
            </MenuLink>
          </li>
          <li>
            <MenuLink
              to={`/clients/${clientId}/settings`}
              activeStyle={activeItem(palette)}
            >
              Settings
            </MenuLink>
          </li>
        </Menu>
      </MenuContainer>
    </ClientNavBar>
  );
};

export default FirmClientHeader;
