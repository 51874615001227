import Cookie from 'js-cookie';
import queryString from 'query-string';
import React from 'react';

import LawPayButton from './LawPayButton';

const LawPayConnect = () => {
  const lawPayId = process.env.REACT_APP_LAWPAY_ID;
  if (!lawPayId) {
    return <div>No Rally LawPay Account Configured</div>;
  }

  const domain = window.location.hostname.split('.').slice(-2).join('.');
  Cookie.set(
    'LAWPAY_REDIRECT',
    `${window.location.protocol}//${window.location.hostname}/oauth/lawpay`,
    { domain },
  );

  const qs = queryString.stringify({
    redirect_uri: process.env.REACT_APP_LAWPAY_REDIRECT,
    client_id: lawPayId,
    scope: 'payments',
    response_type: 'code',
  });
  const authorizeUri = `https://secure.lawpay.com/oauth/authorize?${qs}`;

  return <LawPayButton href={authorizeUri}>Connect with LawPay</LawPayButton>;
};

export default LawPayConnect;
