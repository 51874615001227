import axios from 'axios';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { handleException } from 'utils/ErrorUtils';

import Spinner from '../standard/Spinner';

const SalesforceOAuth = () => {
  const { search } = useLocation();

  useEffect(() => {
    const domain = localStorage.getItem('SALESFORCE_DOMAIN');
    const salesforceRedirect = localStorage.getItem('SALESFORCE_REDIRECT');
    const redirectHref =
      salesforceRedirect || `${window.location.origin}/client/profile`;
    const redirectUrl = new URL(redirectHref);

    const showError = (error) => {
      redirectUrl.searchParams.set('error', error);
      localStorage.removeItem('SALESFORCE_REDIRECT');
      window.location.replace(redirectUrl.href);
    };
    const showSuccess = (success) => {
      redirectUrl.searchParams.set('success', success);
      localStorage.removeItem('SALESFORCE_REDIRECT');
      window.location.replace(redirectUrl.href);
    };

    const authorize = async () => {
      const { code } = queryString.parse(search);
      if (!code) showError('Unexpected error connecting to Salesforce.');

      try {
        const { status } = await axios.post('/salesforce/oauth', {
          code,
          domain,
        });
        if (status === 200) {
          showSuccess('Successfully connected to Salesforce.');
        } else {
          showError('Unexpected error connecting to Salesforce.');
        }
      } catch (error) {
        handleException(error);
        showError('Unexpected error connecting to Salesforce.');
      }
    };
    authorize();
  }, [search]);

  return <Spinner />;
};

export default SalesforceOAuth;
