import { useMutation, useQuery } from '@apollo/client';
import { useFeatureToggle } from '@flopflip/react-broadcast';
import { flagNames } from 'featureFlags';
import queryString from 'query-string';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { handleException } from 'utils/ErrorUtils';

import { useUser } from '../../context/UserContext';
import { trackSalesforceConnect } from '../../services/Analytics';
import {
  DISCONNECT_FROM_SALESFORCE,
  FETCH_SALESFORCE_CONNECTION,
} from './queries';

export default function useSalesforceConnection() {
  const clientId = process.env.REACT_APP_SALESFORCE_ID;
  const redirectUri = process.env.REACT_APP_SALESFORCE_REDIRECT;

  const isSalesforceEnabled = useFeatureToggle(flagNames.SALESFORCE_CONNECTION);

  const [connectionStatus, setConnectionStatus] = useState({
    loading: true,
  });
  const [user] = useUser();

  const [disconnectMutation] = useMutation(DISCONNECT_FROM_SALESFORCE, {
    onCompleted: () => {
      setConnectionStatus({ ...connectionStatus, connected: false });
      toast.success(`Successfully disconnected from Salesforce`);
    },
    onError: (error) => {
      toast.error(
        `Oops! Something went wrong disconnecting from Salesforce. Please try again later`,
      );
      handleException(error);
    },
    update: (cache, mutationResult) => {
      const deletedSalesforceConnection =
        mutationResult.data?.deleteSalesforceConnection?.record;
      if (deletedSalesforceConnection) {
        const connectionId = cache.identify(deletedSalesforceConnection);
        cache.evict({ id: connectionId });
      }
    },
  });

  const disconnect = useCallback(() => {
    setConnectionStatus({ loading: true });
    disconnectMutation();
  }, [disconnectMutation]);

  useQuery(FETCH_SALESFORCE_CONNECTION, {
    onCompleted: (data) => {
      const { instanceUrl } = data.salesforceConnection ?? {};
      setConnectionStatus({
        loading: false,
        connected: !!instanceUrl,
        instanceUrl,
      });
    },
    onError: (error) => {
      handleException(error);
      setConnectionStatus({
        loading: false,
      });
    },
    skip: !isSalesforceEnabled,
  });

  const connect = useCallback(
    ({ salesforceDomain }) => {
      trackSalesforceConnect();

      const domain = encodeURIComponent(salesforceDomain);
      const url = `https://${domain}/services/oauth2/authorize`;
      localStorage.setItem('SALESFORCE_REDIRECT', window.location.href);
      localStorage.setItem('SALESFORCE_DOMAIN', domain);

      const authUrl = queryString.stringifyUrl(
        {
          url,
          query: {
            client_id: clientId,
            redirect_uri: redirectUri,
            response_type: 'code',
            scope: 'api id refresh_token',
            login_hint: user?.email,
          },
        },
        { strict: true, encode: true },
      );

      window.location.assign(authUrl);
    },
    [user?.email, clientId, redirectUri],
  );

  return [{ connectionStatus }, { connect, disconnect }];
}
