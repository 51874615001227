import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled, { css } from 'styled-components/macro';

import Checkbox, { CheckboxLabel } from '../../standard/Checkbox';
import { TypographyStyles } from '../../Typography';

export const FormInputHeight = css`
  height: 30px;
  min-height: 30px;
`;

export const FormInputBorder = css`
  border: 0.5px solid ${({ theme }) => theme.palette.shade.gray_3};
  border-radius: 4px;
  :hover {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
  :focus {
    outline: none;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 8px;
`;

export const FormInputGroup = styled(FormGroup)`
  margin-bottom: 0;
`;

export const FormLabel = styled.label`
  ${TypographyStyles.Color}
  ${TypographyStyles.P}
  margin-bottom: 0;
`;

export const FormInputPrefix = styled.div`
  color: ${({ theme }) => theme.palette.shade.gray_1};
  margin-right: 0.5rem;
`;

export const FormInputSuffix = styled.div`
  color: ${({ theme }) => theme.palette.shade.gray_1};
  margin-left: 0.5rem;
`;

export const FormInput = styled.input`
  ${FormInputBorder}
  ${FormInputHeight}
  padding-left: 6px;
`;

export const FormTextBoxInput = styled.textarea`
  ${FormInputBorder}
  height: 81px;
  flex-basis: 100%;
  padding-left: 6px;
`;

export const FormCheckbox = styled(Checkbox)`
  ${CheckboxLabel} {
    ${TypographyStyles.P}
    ${TypographyStyles.Color}
    margin-bottom: 0;
    padding-left: 1rem;
  }
`;

export const FormSubtitleContainer = styled(FormGroup)`
  margin-bottom: 1rem;
  ${({ showVisibilityCondition }) =>
    !showVisibilityCondition &&
    css`
      margin-bottom: 0;
    `}
`;

export const FormSubtitle = styled.h6`
  font-weight: 700;
  margin-bottom: 0;
  ${({ showVisibilityCondition, theme }) =>
    !showVisibilityCondition &&
    css`
      color: ${theme.palette.shade.gray_4};
    `}
`;

export const AddFieldButton = styled.button.attrs({
  type: 'button',
  children: <FontAwesomeIcon icon={faPlus} size="lg" />,
})`
  color: ${({ theme }) => theme.palette.shade.gray_2};
  background-color: transparent;
  border-width: 0;
`;
