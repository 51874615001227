import fromPairs from 'lodash/fromPairs';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useQueryParams = (
  queryKeys,
  { defaultValues = {}, queryParseOpts = {} } = {},
) => {
  const { search } = useLocation();
  const queryArgs = queryString.parse(search, queryParseOpts);

  const [values, setValues] = useState(
    merge(defaultValues, pick(queryArgs, queryKeys)),
  );
  useEffect(() => {
    const newValues = merge(defaultValues, pick(queryArgs, queryKeys));
    if (!isEqual(values, newValues)) setValues(newValues);
  }, [queryArgs, queryKeys, defaultValues, values]);

  const history = useHistory();
  const setQueryValues = (newValues) => {
    history.push({
      search: queryString.stringify({
        ...omit(queryArgs, queryKeys),
        ...pick(newValues, queryKeys),
      }),
    });
  };

  return [values, setQueryValues];
};

export const useQueryParam = (
  paramName,
  { defaultValue, queryParseOpts = {} } = {},
) => {
  const [values, setValues] = useQueryParams([paramName], {
    defaultValues: fromPairs([[paramName, defaultValue]]),
    queryParseOpts,
  });

  const setValue = (newValue) => setValues(fromPairs([[paramName, newValue]]));
  return [values[paramName], setValue];
};
