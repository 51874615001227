import { gql, useMutation, useQuery } from '@apollo/client';
import { useFeatureToggle } from '@flopflip/react-broadcast';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';
import { handleException } from 'utils/ErrorUtils';

import { useUser } from '../../context/UserContext';
import { flagNames } from '../../featureFlags';
import { useQueryParams } from '../../hooks/useQueryParam';
import { userIsClient } from '../../services/Utilities';
import {
  ClientTeamMembersModal,
  TEAM_MODAL_TABS,
} from '../client/teammembers/ClientTeamMembersModal';
import HubspotConnect from '../hubspot/HubspotConnect';
import Button from '../standard/Button';
import Header from '../standard/Header';
import Spinner from '../standard/Spinner';
import { BillingDashboardButton } from '../subscription/BillingDashboardButton';
import { SubscriptionCheckoutButton } from '../subscription/SubscriptionCheckoutButton';
import { SettingsContainer, SettingsItem } from './Settings';

export const GET_CLIENT_SETTINGS = gql`
  query getClientSettings($userId: MongoID!) {
    client(_id: $userId) {
      organizationDetails {
        _id
        stripe {
          _id
          customerId
        }
      }
    }
  }
`;

const INITIATE_DATAROOM_EXPORT = gql`
  mutation intiateDataRoomExport($clientId: MongoID!) {
    intiateDataRoomExport(input: { clientId: $clientId }) {
      record {
        executionArn
      }
    }
  }
`;

const ExportContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0;
  button {
    width: fit-content;
  }
`;

const ExportInProgressMessage = styled.div.attrs({
  className: 'rally-typography rally-typography-h6',
})`
  color: ${({ theme }) => theme.palette.shade.gray_3};
`;

function ExportDataRoomButton({ clientId }) {
  const [exporting, setExporting] = useState(false);
  const [initiateDataroomExport, { loading }] = useMutation(
    INITIATE_DATAROOM_EXPORT,
    {
      onCompleted: () => {
        setExporting(true);
      },
      onError: (error) => {
        toast.error(
          'Oops! Something went wrong initializing your matter export',
        );
        handleException(error);
      },
      variables: { clientId },
    },
  );
  return (
    <ExportContainer>
      <Button
        outline
        onClick={initiateDataroomExport}
        disabled={loading || exporting || !clientId}
      >
        {loading && <FontAwesomeIcon icon={faSpinner} pulse />}
        Export
      </Button>
      {exporting && (
        <ExportInProgressMessage>
          The export process has been started. You will receive an email when
          the export is complete. Please check your “Spam” folder.
        </ExportInProgressMessage>
      )}
    </ExportContainer>
  );
}

export function ClientSettings() {
  const { clientId: clientIdParam } = useParams();
  const [{ organization }] = useUser();

  const clientId = clientIdParam || organization._id;

  const userId = localStorage.getItem('userId')?.replace(/^.*\|/, '');

  const [showMembersModal, setShowMembersModal] = useState(false);
  const [stripeCustomerId, setStripeCustomerId] = useState('');

  const isRFB = useFeatureToggle(flagNames.RFB);

  const accessMatterWorkspace = useFeatureToggle(
    flagNames.ACCESS_MATTER_WORKSPACE,
  );
  const hubspotConnectionEnabled = useFeatureToggle(
    flagNames.HUBSPOT_CONNECTION,
  );
  const enableDataRoom = useFeatureToggle(flagNames.ENABLE_DATAROOM);
  const [{ error, success }] = useQueryParams(['error', 'success']);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success(success);
    }
  }, [error, success]);

  const { loading } = useQuery(GET_CLIENT_SETTINGS, {
    variables: { userId },
    onCompleted: (data) => {
      setStripeCustomerId(
        data?.client?.organizationDetails?.[0]?.stripe?.customerId ?? '',
      );
    },
    onError: (err) => {
      toast.error(
        'Oops! Something went wrong fetching your subscription status',
      );
      handleException(err);
    },
    skip: !isRFB,
  });

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Header
        title="Team Settings"
        subtitle="Configure your team's settings."
      />
      <SettingsContainer>
        <SettingsItem
          name="Team Members"
          helper="Invite new, and manage existing team members."
        >
          <Button outline onClick={() => setShowMembersModal(true)}>
            <FontAwesomeIcon icon={faEdit} />
            {' Manage Members'}
          </Button>
        </SettingsItem>
        {isRFB && userIsClient() && (
          <>
            {stripeCustomerId && accessMatterWorkspace ? (
              <SettingsItem
                name="Billing"
                helper="Manage your subscription and billing information."
              >
                <BillingDashboardButton />
              </SettingsItem>
            ) : (
              <SettingsItem name="Rally Subscription">
                <SubscriptionCheckoutButton
                  analyticsMetadata={{ source: 'settings' }}
                />
              </SettingsItem>
            )}
          </>
        )}
        {hubspotConnectionEnabled && userIsClient() && (
          <SettingsItem
            name="HubSpot"
            helper="Create contracts from Hubspot deals in Rally."
          >
            <HubspotConnect />
          </SettingsItem>
        )}
        {enableDataRoom && (
          <SettingsItem
            name="Export Matters"
            helper="At any time you may download your categorized Matters from Rally. You will receive an email to your Rally account email with a link to your Matters."
          >
            <ExportDataRoomButton clientId={clientId} />
          </SettingsItem>
        )}
      </SettingsContainer>
      <ClientTeamMembersModal
        clientId={clientId}
        open={showMembersModal}
        onClose={() => setShowMembersModal(false)}
        defaultActiveTab={TEAM_MODAL_TABS.clients}
      />
    </>
  );
}
