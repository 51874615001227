import React from 'react';
import styled, { withTheme } from 'styled-components/macro';

const SpinnerContainer = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 99999;
  transform: translate(-50%, -50%);
`;

const StyledSpan = styled.span`
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

const Spinner = ({ theme }) => (
  <SpinnerContainer>
    <div className="spinner-dots" role="alert">
      <StyledSpan theme={theme} className="dot1" />
      <StyledSpan theme={theme} className="dot2" />
      <StyledSpan theme={theme} className="dot3" />
    </div>
  </SpinnerContainer>
);

export default withTheme(Spinner);
