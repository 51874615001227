import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components/macro';

import { TagSelect } from '../../standard/TagSelect';
import {
  SubheaderCell,
  SubheaderRow,
  SubheaderWrapper,
  TagsCell,
} from './SubheaderStyles';

function ReviewersSubheaderRow({
  reviewRequests,
  setDisplayAddReviewersModal,
  ...props
}) {
  const theme = useContext(ThemeContext);

  const activeRequests = reviewRequests.filter(
    ({ state }) => state === 'PENDING' || state === 'APPROVED',
  );
  const mapRequest = ({ reviewer, state }) => ({
    value: reviewer,
    label: reviewer.email,
    state,
  });

  return (
    <SubheaderWrapper {...props}>
      <SubheaderRow>
        <SubheaderCell>Reviewers:</SubheaderCell>
        <TagsCell>
          <TagSelect
            aria-label="Reviewers"
            isMulti
            value={activeRequests?.map(mapRequest)}
            placeholder="+ Add Reviewers"
            openMenuOnClick={false}
            onFocus={() => setDisplayAddReviewersModal(true)}
            customStyle
            styles={{
              multiValue: (provided, { data: { state } }) => {
                const color =
                  state === 'PENDING'
                    ? theme.palette.feedback.info.light
                    : theme.palette.feedback.success.extralight;
                return {
                  ...provided,
                  backgroundColor: color,
                  border: `1px solid ${color}`,
                };
              },
            }}
          />
        </TagsCell>
      </SubheaderRow>
    </SubheaderWrapper>
  );
}

export default ReviewersSubheaderRow;
