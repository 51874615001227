import React from 'react';
import styled from 'styled-components/macro';

import paywallImage from '../../../img/matter-paywall.png';
import RallyForBusinessPaywallDialog from '../../subscription/RallyForBusinessPaywallDialog';

const MatterViewScreenPaywallContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 75px;
  left: 0;
  width: 100%;
`;

const MatterViewScreenPaywall = ({ matterId, templateId, templateName }) => (
  <MatterViewScreenPaywallContainer>
    <img src={paywallImage} alt="paywall" />
    <RallyForBusinessPaywallDialog
      title="Almost done! 🎉"
      checkoutAnalyticsMetadata={{
        source: 'matter',
        matterId,
        templateId,
        templateName,
      }}
    />
  </MatterViewScreenPaywallContainer>
);

export default MatterViewScreenPaywall;
