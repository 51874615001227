import axios from 'axios';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { handleException } from 'utils/ErrorUtils';

import Spinner from '../standard/Spinner';

const HelloSignOAuth = () => {
  const { search } = useLocation();

  useEffect(() => {
    const authorize = async () => {
      const { state, code } = queryString.parse(search);
      const jsonState = atob(state);
      const localState = localStorage.getItem('HELLOSIGN_OAUTH_STATE');
      localStorage.removeItem('HELLOSIGN_OAUTH_STATE');
      let url;

      try {
        const parsedState = JSON.parse(localState);
        url = parsedState.url;
        if (code && jsonState === localState) {
          await axios.post('/api/v1/hellosign/connect', { code, state });
        }
      } catch (err) {
        handleException(err);
      }

      window.location.replace(
        url || `${window.location.protocol}//${window.location.hostname}`,
      );
    };
    authorize();
  }, [search]);

  return <Spinner />;
};

export default HelloSignOAuth;
