import { gql, useMutation } from '@apollo/client';
import { faLock, faUserFriends } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components/macro';

import { userIsFirmAdmin } from '../../../services/Utilities';
import Button from '../../standard/Button';
import Modal from '../../standard/Modal';
import Spinner from '../../standard/Spinner';
import { CLIENT_TEAM_FRAGMENT } from './Fragments';

const FooterControl = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
`;

const SET_ACCESS_POLICY = gql`
  mutation setClientTeamAccessPolicy(
    $teamId: MongoID!
    $accessPolicy: EnumOrgTeamAccessPolicy!
  ) {
    setOrgTeamAccessPolicy(teamId: $teamId, accessPolicy: $accessPolicy) {
      record {
        ...OrgTeamInformation
      }
    }
  }
  ${CLIENT_TEAM_FRAGMENT}
`;

export function LegalTeamFooter({
  firmName,
  clientName,
  teamMembers,
  teamId,
  accessPolicy,
  onClose,
}) {
  const userId = localStorage.getItem('userId');

  const [isLoading, setIsLoading] = useState(false);
  const [showSetPrivateConfirmationModal, setShowSetPrivateConfirmationModal] =
    useState(false);

  const [setClientTeamAccessPolicy] = useMutation(SET_ACCESS_POLICY);

  const toggleAccessPolicy = useCallback(async () => {
    const newAccessPolicy = accessPolicy === 'FIRM' ? 'PRIVATE' : 'FIRM';
    if (
      !userIsFirmAdmin() &&
      newAccessPolicy === 'PRIVATE' &&
      !teamMembers.some(({ id }) => id === userId)
    ) {
      setShowSetPrivateConfirmationModal(true);
    } else {
      setIsLoading(true);
      try {
        await setClientTeamAccessPolicy({
          variables: { teamId, accessPolicy: newAccessPolicy },
        });
      } finally {
        setIsLoading(false);
      }
    }
  }, [setClientTeamAccessPolicy, userId, teamMembers, teamId, accessPolicy]);

  const { statusIcon, statusCopy, buttonCopy } = {
    PRIVATE: {
      statusIcon: faLock,
      statusCopy: `Private to Members`,
      buttonCopy: `Make Public to ${firmName}`,
    },
    FIRM: {
      statusIcon: faUserFriends,
      statusCopy: `Public to ${firmName} and Members`,
      buttonCopy: `Make Private`,
    },
  }[accessPolicy];

  return (
    <>
      {isLoading && <Spinner />}
      <FooterControl>
        <div>
          <FontAwesomeIcon icon={statusIcon} />
          {` ${statusCopy}`}
        </div>
        <Button pure onClick={toggleAccessPolicy} className="pr-0">
          {buttonCopy}
        </Button>
      </FooterControl>
      <Modal
        title={`Make ${clientName} private?`}
        display={showSetPrivateConfirmationModal}
        showCancel
        onCancel={() => {
          setShowSetPrivateConfirmationModal(false);
        }}
        acceptPaletteType="destructive"
        acceptText="Make private"
        onAccept={async () => {
          setIsLoading(true);
          try {
            await setClientTeamAccessPolicy({
              variables: { teamId, accessPolicy: 'PRIVATE' },
            });
          } finally {
            setIsLoading(false);
            setShowSetPrivateConfirmationModal(false);
            onClose();
          }
        }}
      >
        This client profile is visible to all firm members. If you make it
        private, you will no longer be able to access it as you are not
        currently a Collaborator. To regain access, you will need to be added by
        a Collaborator or an administrator.
      </Modal>
    </>
  );
}
