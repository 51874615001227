import { faEllipsis } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';

import Button from '../../standard/Button';
import { ConfirmationDialog } from '../../standard/ConfirmationDialog';
import { PopperMenu } from '../../standard/PopperMenu';
import { TypographyStyles } from '../../Typography';
import { SignatureRequestStatusBadge } from './SignatureRequestStatusBadge';
import { SignatureStatusCard } from './SignatureStatusCard';
import {
  StateCell,
  SubheaderCell,
  SubheaderRow,
  SubheaderWrapper,
} from './SubheaderStyles';

const PlainButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.palette.shade.gray_2};
  padding: 0;
  margin: 0;
`;

const SendForSigningButton = styled(Button)`
  padding: 0;
  margin: 0;
  ${TypographyStyles.FontFamily}
  ${TypographyStyles.P}
`;

function getTopLevelStatus(signatureRequests = []) {
  const statuses = new Set();
  signatureRequests.forEach((request) => statuses.add(request.status));

  // If there's any outstanding signature requests show these first
  if (statuses.has('AWAITING_SIGNATURES')) {
    return 'AWAITING_SIGNATURES';
  }

  // If all the signature requests are either completed or errored, show
  // complete for now as the user can't do anything about the errored requests
  if (statuses.has('COMPLETE')) {
    return 'COMPLETE';
  }

  // If there's only errored, declined, or cancelled signature requests then
  // show those.
  if (statuses.has('ERROR')) {
    return 'ERROR';
  }

  if (statuses.has('DECLINED')) {
    return 'DECLINED';
  }

  if (statuses.has('CANCELLED')) {
    return 'CANCELLED';
  }

  // If we didn't get a status, return an error.
  return 'ERROR';
}

export function SignatureStatusSubheaderRow({
  signatureRequests = [],
  initialOpenState = false,
  setDisplaySendForSigning = () => {},
  onSignatureReminder,
  onCancelSignatureRequest,
  ...props
}) {
  const buttonRef = useRef(null);
  const [open, setOpen] = useState(initialOpenState);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [selectedSignatureRequestId, setSelectedSignatureRequestId] =
    useState();

  return (
    <>
      <SubheaderWrapper {...props}>
        <SubheaderRow>
          <SubheaderCell>Signature Requests:</SubheaderCell>
          <StateCell>
            {!signatureRequests?.length && (
              <SendForSigningButton
                onClick={() => setDisplaySendForSigning(true)}
                pure
              >
                Send for signing
              </SendForSigningButton>
            )}
            {!!signatureRequests?.length && (
              <>
                <PlainButton
                  type="button"
                  role="button"
                  onClick={() => setOpen(!open)}
                  ref={buttonRef}
                >
                  <SignatureRequestStatusBadge
                    status={getTopLevelStatus(signatureRequests)}
                  />
                  &nbsp;
                  <FontAwesomeIcon icon={faEllipsis} />
                </PlainButton>
                <PopperMenu
                  anchorEl={buttonRef.current}
                  open={open}
                  setOpen={setOpen}
                  placement="bottom-start"
                >
                  <SignatureStatusCard
                    signatureRequests={signatureRequests}
                    setDisplaySendForSigning={setDisplaySendForSigning}
                    onSignatureReminder={onSignatureReminder}
                    setShowCancelDialog={setShowCancelDialog}
                    setSelectedSignatureRequestId={
                      setSelectedSignatureRequestId
                    }
                  />
                </PopperMenu>
              </>
            )}
          </StateCell>
        </SubheaderRow>
      </SubheaderWrapper>
      <ConfirmationDialog
        open={showCancelDialog}
        onClose={() => setShowCancelDialog(false)}
        onConfirm={async () => {
          await onCancelSignatureRequest(selectedSignatureRequestId);
          setShowCancelDialog(false);
        }}
        title="Cancel signature request"
        message="Once cancelled, signers will no longer be able to sign. Partially signed documents will be cancelled as well. This cannot be undone and a new request will be required to collect signatures."
        confirmText="Cancel request"
        cancelText={null}
        confirmButtonPalette="destructive"
      />
    </>
  );
}

SignatureStatusSubheaderRow.propTypes = {
  initialOpenState: PropTypes.bool,
  signatureRequests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      createdAt: PropTypes.string,
      detailsUrl: PropTypes.string,
      title: PropTypes.string,
      status: PropTypes.string,
      signatures: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          email: PropTypes.string,
          status: PropTypes.string,
          signedAt: PropTypes.string,
          lastViewedAt: PropTypes.string,
          lastRemindedAt: PropTypes.string,
        }),
      ),
    }),
  ),
};
