import { useFeatureToggle } from '@flopflip/react-broadcast';
import PasswordValidator from 'password-validator';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components/macro';
import { handleException } from 'utils/ErrorUtils';

import Button from '../../../components/standard/Button';
import Checkbox from '../../../components/standard/Checkbox';
import Modal from '../../../components/standard/Modal';
import Spinner from '../../../components/standard/Spinner';
import StyledLink from '../../../components/standard/StyledLink';
import { flagNames } from '../../../featureFlags';
import AuthUtility from '../../../services/AuthUtility';
import { facebookTrackCompleteRegistration } from '../../../services/FacebookPixel';
import { googleTrackCompleteRegistration } from '../../../services/GoogleTagManager';
import { validateEmail } from '../../../services/Utilities';
import PoweredByRally from './PoweredByRally';

const BoxWrapper = styled.div`
  position: absolute;
  top: calc(50% - 10px);
  transform: translateY(-50%);
  left: 0;
  right: 0;
`;

const PasswordValidSymbol = styled.span`
  color: rgb(44, 201, 102);
  margin-right: 5px;
  font-weight: bold;
`;

const PasswordInvalidSymbol = styled.span`
  margin-right: 5px;
`;

const TermsBoxLink = styled.a`
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: 500;
  border-bottom: none;
`;

const Subtitle = styled.span`
  font-size: 14px;
`;

const passValidatorSchema = new PasswordValidator();
passValidatorSchema.is().min(10);

export function RegisterBox({
  email: initialEmail = '',
  firm,
  firmUserId,
  heading,
  invitation,
  loginLink,
  organizationId,
  redirectPath,
  registeringWithExistingOrganization,
  subheading,
  userType,
}) {
  const theme = useContext(ThemeContext);

  const history = useHistory();

  const [authUtility, setAuthUtility] = useState(undefined);
  const [email, setEmail] = useState(initialEmail);
  const [password, setPassword] = useState('');
  const [passwordValidation, setPasswordValidation] = useState([
    'min',
    'uppercase',
    'lowercase',
    'digits',
    'symbols',
  ]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [displayTerms, setDisplayTerms] = useState(false);
  const [organizationName, setOrganizationName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [passwordReqsHeight, setPasswordReqsHeight] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [agreeToTermsBox, setAgreeToTermsBox] = useState(false);
  const [isRegisterDisabled, setIsRegisterDisabled] = useState(false);

  const collectPhoneNumber = useFeatureToggle(
    flagNames.COLLECT_PHONE_NUMBER_ON_REGISTRATION,
  );

  useEffect(() => setAuthUtility(new AuthUtility()), []);

  const { registrationTerms: RegistrationTerms, showPoweredByRally } = theme;

  const validateInputs = useCallback(() => {
    const errors = [];
    if (!email) {
      errors.push('Please enter an email address.');
    }
    if (email && !validateEmail(email)) {
      errors.push('Email address is not valid.');
    }

    if (!organizationName && !registeringWithExistingOrganization) {
      errors.push('Please enter a name.');
    }

    if (collectPhoneNumber && phoneNumber?.length !== 10) {
      errors.push('Please enter a valid phone number.');
    }

    if (!agreeToTermsBox) {
      errors.push('Please confirm that you agree to the terms and conditions.');
    }

    if (!passValidatorSchema.validate(password)) {
      errors.push('Your password does not satisfy all requirements.');
    }

    if (
      userType === 'client' &&
      !firm &&
      !registeringWithExistingOrganization
    ) {
      errors.push(
        'You need a valid invitation from your lawyer to register as a client. Please ask them to re-send your invitation or email info@rallynow.io for more support.',
      );
    }

    setErrorMessages(errors);
    if (errors.length) {
      return false;
    }
    return true;
  }, [
    agreeToTermsBox,
    email,
    firm,
    organizationName,
    phoneNumber,
    collectPhoneNumber,
    password,
    registeringWithExistingOrganization,
    userType,
  ]);

  const validateThenDisplayTerms = useCallback(
    (event) => {
      event.preventDefault();
      setErrorMessages([]);
      setDisplayTerms(validateInputs());
    },
    [validateInputs],
  );

  const handleRegisterCallback = useCallback(
    (registrationError, data) => {
      if (registrationError) {
        setShowSpinner(false);
        setIsRegisterDisabled(false);

        if (registrationError.response?.data?.message) {
          // This is a formatted error from our API. Render it.
          setErrorMessages([registrationError.response.data.message]);
        } else {
          handleException(registrationError);
          setErrorMessages([
            'Oops! There was an unexpected error registering this user. Please wait a few seconds and try again.',
          ]);
        }
        return;
      }

      // update email based on api response
      setEmail(data.email);

      facebookTrackCompleteRegistration();
      googleTrackCompleteRegistration();

      // Log user in if successful
      authUtility.login(
        data.email,
        password,
        (error) => {
          handleException(error);
        },
        {
          redirectPath,
        },
        userType,
      );
    },
    [authUtility, password, redirectPath, userType],
  );

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      setIsRegisterDisabled(true);

      if (!theme.registrationTerms) {
        setErrorMessages([]);
        if (!validateInputs()) {
          setIsRegisterDisabled(false);
          return;
        }
      }

      const trimmedEmail = email.trim();
      const trimmedPassword = password.trim();
      if (!trimmedEmail || !trimmedPassword) {
        setIsRegisterDisabled(false);
        return;
      }

      setShowSpinner(true);

      if (userType === 'client') {
        authUtility.registerClient(
          trimmedEmail,
          trimmedPassword,
          phoneNumber,
          firm,
          firmUserId,
          organizationName,
          organizationId,
          invitation?._id,
          handleRegisterCallback,
        );
      } else {
        authUtility.register(
          trimmedEmail,
          trimmedPassword,
          handleRegisterCallback,
        );
      }
    },
    [
      authUtility,
      email,
      firm,
      firmUserId,
      handleRegisterCallback,
      invitation?._id,
      organizationId,
      organizationName,
      phoneNumber,
      password,
      theme.registrationTerms,
      userType,
      validateInputs,
    ],
  );

  const invitationAccepted = invitation?.state === 'accepted';
  const invitationRevoked = invitation?.state === 'revoked';
  const invitationExpired = invitation?.expiresAt
    ? new Date(invitation?.expiresAt) < new Date()
    : false;
  if (invitationAccepted || invitationRevoked || invitationExpired) {
    let errorHeading;
    let errorSubtitle;
    if (invitationAccepted && invitation.contract) {
      history.replace(
        `/login?${queryString.stringify({
          email,
          document: invitation.contract,
        })}`,
      );
    } else if (invitationAccepted) {
      errorHeading = 'Invitation Already Accepted';
      errorSubtitle = (
        <>
          Please
          <StyledLink to={`/login?${queryString.stringify({ email })}`}>
            {' sign in '}
          </StyledLink>
          to continue.
        </>
      );
    } else if (invitationRevoked) {
      errorHeading = 'Invitation Revoked';
      errorSubtitle =
        'Please ask the user who invited you to resend the invitation.';
    } else {
      errorHeading = 'Invitation Expired';
      errorSubtitle =
        'Please ask the user who invited you to resend the invitation.';
    }

    return (
      <div className="col-md-6 col-lg-5 col-xl-4">
        <BoxWrapper className="px-4 px-lg-5 py-30">
          <h4>{errorHeading}</h4>
          <p>
            <Subtitle className="subtitle">{errorSubtitle}</Subtitle>
          </p>
          <br />
        </BoxWrapper>
      </div>
    );
  }

  return (
    <div className="col-md-6 col-lg-5 col-xl-4 ">
      {!!showSpinner && <Spinner />}
      <BoxWrapper className="px-4 px-lg-5 py-30">
        <h4>{heading || 'Create an Account'}</h4>
        <p>
          <Subtitle className="subtitle">
            {subheading || 'All fields are required.'}
          </Subtitle>
        </p>
        <br />

        {errorMessages.map((message) => (
          <div key={message} className="alert alert-danger" role="alert">
            {` ${message} `}
          </div>
        ))}

        <form
          className="form-type-material"
          onSubmit={RegistrationTerms ? validateThenDisplayTerms : handleSubmit}
        >
          <div className={`form-group ${email ? 'do-float' : ''}`}>
            <input
              type="email"
              className="form-control"
              id="email"
              onChange={(event) => setEmail(event.target.value)}
              value={email ?? ''}
            />
            <label htmlFor="email">Email</label>
          </div>
          {!registeringWithExistingOrganization && (
            <div className="form-group ">
              <input
                type="text"
                className="form-control"
                id="organizationName"
                autoComplete="no"
                onChange={(event) => setOrganizationName(event.target.value)}
                value={organizationName}
              />
              <label htmlFor="organizationName">
                Company or Account Holder Name
              </label>
            </div>
          )}
          {collectPhoneNumber && (
            <div className="form-group ">
              <NumberFormat
                format="(###) ###-####"
                isNumericString
                onValueChange={({ value }) => setPhoneNumber(value)}
                type="tel"
                className="form-control"
                id="phoneNumber"
                value={phoneNumber}
              />
              <label htmlFor="phoneNumber">Phone Number</label>
            </div>
          )}
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              id="password"
              onChange={(event) => {
                setPassword(event.target.value);
                setPasswordValidation(
                  passValidatorSchema.validate(event.target.value, {
                    list: true,
                  }),
                );
              }}
              onFocus={() => setPasswordReqsHeight('auto')}
            />
            <label htmlFor="password">Password</label>
          </div>
          <AnimateHeight
            duration={600}
            height={passwordReqsHeight}
            className="mb-10"
          >
            <div>
              {!passwordValidation.includes('min') ? (
                <PasswordValidSymbol className="pe-7s-check pe-lg" />
              ) : (
                <PasswordInvalidSymbol className="pe-7s-close-circle pe-lg" />
              )}
              10 characters minimum
            </div>
          </AnimateHeight>
          <div className="form-group">
            <label className="custom-control custom-checkbox">
              <Checkbox
                label={
                  <>
                    {`I agree to the `}
                    <TermsBoxLink
                      href="https://drive.google.com/file/d/1e85W9UhZl-_I5sBXJ5WoN27d5Ylf4y5W/view?usp=sharing"
                      target="_blank"
                    >
                      terms and conditions
                    </TermsBoxLink>
                  </>
                }
                checked={agreeToTermsBox}
                onChange={(event) => setAgreeToTermsBox(!!event.target.checked)}
                testId="terms-checkbox"
              />
            </label>
          </div>
          <div className="form-group" style={{ paddingTop: '10px' }}>
            <Button type="submit" width="100%" disabled={isRegisterDisabled}>
              Continue
            </Button>
          </div>
        </form>
        {userType !== 'client' && (
          <div>
            <hr
              className="w-30px"
              style={{ marginTop: '42px', marginBottom: '34px' }}
            />
            <p className="subtitle" style={{ fontSize: '14px' }}>
              {'Already have an account? '}
              <StyledLink to="/login">Sign in</StyledLink>
            </p>
          </div>
        )}
        {userType === 'client' && (
          <div>
            <p className="subtitle" style={{ fontSize: '14px' }}>
              {'Already have an account? '}
              <StyledLink to={loginLink}>Sign in</StyledLink>
            </p>
          </div>
        )}
      </BoxWrapper>
      {!!displayTerms && (
        <Modal
          display="true"
          title="Terms of Service"
          onAccept={() => setDisplayTerms(false)}
          hideAccept
          hideHeader
          onCancel={() => setDisplayTerms(false)}
          maxWidth="1000px"
          bodyFontSize="13px"
        >
          <RegistrationTerms
            nextText="I Understand and Agree"
            finishText="I Agree, Finish Registration"
            handleSubmit={handleSubmit}
            closeTerms={() => setDisplayTerms(false)}
          />
        </Modal>
      )}
      {showPoweredByRally && <PoweredByRally />}
    </div>
  );
}

RegisterBox.propTypes = {
  email: PropTypes.string,
  firm: PropTypes.string,
  heading: PropTypes.string,
  invitation: PropTypes.object,
  loginLink: PropTypes.string,
  organizationId: PropTypes.string,
  redirectPath: PropTypes.string,
  registeringWithExistingOrganization: PropTypes.bool,
  subheading: PropTypes.string,
  userType: PropTypes.oneOf(['firm', 'client']).isRequired,
};
