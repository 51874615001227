import React from 'react';
import styled from 'styled-components/macro';

export const TeamMember = styled(({ className, name, badge, controls }) => (
  <div className={className} aria-label={name}>
    {name}
    <div>
      {badge}
      {controls}
    </div>
  </div>
))`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  min-height: 42px;
  border: dotted #f1f2f3;
  border-width: 1px 0;

  button {
    padding-right: 0;
    margin-right: 0;
    border-right: 0;
  }
`;
