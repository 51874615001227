// This class wraps the bootstrap-based date picker that comes with the TheAdmin theme
// to make it React-friendly.

import React, { Component } from 'react';

import { Input } from './FormStyles';

const { $ } = window;

export default class DatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      localValue: '',
    };
  }

  componentDidMount() {
    const { onChange } = this.props;
    if (onChange) {
      this.$inputElement = $(this.inputElement);
      this.$inputElement.on('changeDate', this.handleNewDate.bind(this));
    }
  }

  handleNewDate = () => {
    const { name, onChange, onBlur } = this.props;
    const newEvent = {
      target: {
        name,
        value: this.$inputElement[0].value,
      },
    };

    if (this.blurTimerId) clearTimeout(this.blurTimerId);
    onChange(newEvent);
    onBlur(newEvent);
    this.setState({
      localValue: newEvent.target.value,
    });
  };

  handleManualChange = (e) => {
    this.setState({
      localValue: e.target.value,
    });
  };

  delayedBlur = () => {
    this.blurTimerId = setTimeout(this.handleNewDate.bind(this), 500);
  };

  render() {
    const { format, value } = this.props;
    const { localValue } = this.state;
    return (
      <Input
        ref={(el) => {
          this.inputElement = el;
        }}
        type="text"
        data-provide="datepicker"
        data-date-today-highlight="true"
        data-date-format={format}
        onBlur={this.delayedBlur}
        value={localValue || value || ''}
        onChange={this.handleManualChange}
        placeholder="Select a date"
        autoComplete="chrome-off"
        {...this.props}
      />
    );
  }
}
