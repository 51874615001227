import React from 'react';
import styled, { css } from 'styled-components/macro';

export const Tabs = styled.div.attrs({ role: 'tablist' })`
  display: flex;
  align-items: flex-start;
`;

const TabButton = styled.button.attrs({ role: 'tab' })`
  margin-right: 30px;
  font-size: 16px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.text.disabled};
  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.palette.text.primary};
      font-weight: 500;
      border-bottom: 1px solid ${({ theme }) => theme.palette.text.primary};
    `};
`;

export function Tab({ id, controls, isActive, children, ...props }) {
  return (
    <TabButton
      isActive={isActive}
      aria-selected={isActive ? 'true' : 'false'}
      aria-controls={controls}
      id={id}
      key={id}
      tabindex={isActive ? '0' : '-1'}
      {...props}
    >
      {children}
    </TabButton>
  );
}

export function TabPanel({ id, labelledBy, isActive, children }) {
  return (
    <div
      role="tabpanel"
      aria-labelledby={labelledBy}
      id={id}
      key={id}
      tabIndex="0"
      hidden={!isActive}
    >
      {children}
    </div>
  );
}
