import { gql, useQuery } from '@apollo/client';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';
import { handleException } from 'utils/ErrorUtils';
import { v4 } from 'uuid';

import BottomBar from '../../forms/BottomBar';
import Button from '../../standard/Button';
import { ErrorMessageContainer } from '../Common';
import AddServiceModal from './AddServiceModal';
import CategoryEditor from './CategoryEditor';

const GET_TEMPLATES = gql`
  query getTemplates(
    $userId: String!
    $public: Boolean
    $searchQuery: String
    $status: EnumContractTemplateStatus
  ) {
    firmUser(id: $userId) {
      id
      firms {
        _id
        templates(
          filter: {
            public: $public
            nameRegEx: $searchQuery
            archived: false
            statuses: [$status]
            hasVersion: true
          }
        ) {
          _id
          name
          description
          price
          status
          displayPrice
          version {
            id
          }
        }
      }
    }
  }
`;

const MenuOfServicesContainer = SortableContainer(styled.div`
  margin-top: 2em;
  margin-bottom: 10em;
`);

const StyledButton = styled(Button)`
  margin-top: 24px;
`;

const MenuOfServicesBuilder = ({
  firmId,
  serviceMenu: initialServiceMenu,
  onUpdate = (f) => f,
  closeBuilder = (f) => f,
}) => {
  const [errors, setErrors] = useState({});
  const [serviceMenu, setServiceMenu] = useState(initialServiceMenu);
  const [isUpdating, setIsUpdating] = useState(false);
  const [currentCategoryId, setCurrentCategoryId] = useState(null);
  const [
    currentCategoryTemplateVersionIds,
    setCurrentCategoryTemplateVersionIds,
  ] = useState([]);
  const [showAddServiceModal, setShowAddServiceModal] = useState(false);

  const { data } = useQuery(GET_TEMPLATES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      userId: localStorage.getItem('userId'),
      public: localStorage.getItem('hidePublicTemplates') !== 'true',
      status: 'active',
    },
  });
  const templates = data?.firmUser?.firms?.[0].templates ?? [];

  const addCategory = () => {
    setServiceMenu({
      ...serviceMenu,
      categories: [
        ...serviceMenu.categories,
        {
          name: '',
          categoryId: v4(),
          templateVersions: [],
          templates: [],
        },
      ],
    });
  };

  const addService = (categoryId, template) => {
    const newServiceMenu = {
      ...serviceMenu,
      categories: serviceMenu.categories.map((category) =>
        category.categoryId === categoryId
          ? {
              ...category,
              templateVersions: [
                ...category.templateVersions,
                { versionId: template.version.id },
              ],
              templates: [...(category.templates || []), template],
            }
          : category,
      ),
    };

    setServiceMenu(newServiceMenu);
  };

  const validateServiceMenu = () => {
    const newErrors = {};

    if (!serviceMenu) {
      return false;
    }

    if (!serviceMenu.categories.length) {
      newErrors.categories = 'You must add at least one category';
    }

    serviceMenu.categories.forEach((category) => {
      const { categoryId, templateVersions } = category;

      if (!templateVersions.length) {
        newErrors[categoryId] = {
          ...newErrors[categoryId],
          templateVersions: 'You must add at least one service',
        };
      }
    });

    setErrors(newErrors);

    return isEmpty(newErrors);
  };

  const saveServiceMenu = async () => {
    setIsUpdating(true);
    try {
      if (!validateServiceMenu()) {
        toast.error('There are issues with your submission');
        return false;
      }

      const { data: newServiceMenu } = await axios.put(
        `/api/v1/firm/${firmId}/servicemenu/${serviceMenu._id}`,
        serviceMenu,
      );

      setServiceMenu(newServiceMenu);
      onUpdate(newServiceMenu);
      toast.success('Menu of services saved successfully');

      return true;
    } catch (error) {
      handleException(error);
      toast.error('Something went wrong while saving. Please try again later');
      return false;
    } finally {
      setIsUpdating(false);
    }
  };

  const onCategorySortEnd = ({ newIndex, oldIndex }) => {
    const newCategories = [...serviceMenu.categories];
    const [categoryToMove] = newCategories.splice(oldIndex, 1);
    newCategories.splice(newIndex, 0, categoryToMove);

    setServiceMenu({ ...serviceMenu, categories: newCategories });
  };

  return (
    <MenuOfServicesContainer
      axis="y"
      onSortEnd={onCategorySortEnd}
      helperClass="isDragging"
      useWindowAsScrollContainer
    >
      {serviceMenu.categories.map((category, index) => (
        <CategoryEditor
          key={category.categoryId}
          index={index}
          category={category}
          errors={errors[category.categoryId] || {}}
          onUpdate={(updatedCategory) =>
            setServiceMenu({
              ...serviceMenu,
              categories: serviceMenu.categories.map((c) =>
                c.categoryId === updatedCategory.categoryId
                  ? updatedCategory
                  : c,
              ),
            })
          }
          onDelete={() =>
            setServiceMenu({
              ...serviceMenu,
              categories: serviceMenu.categories.filter(
                (c) => c.categoryId !== category.categoryId,
              ),
            })
          }
          onAddServiceClick={(categoryId) => {
            setCurrentCategoryId(categoryId);
            setCurrentCategoryTemplateVersionIds(
              category.templateVersions.map(({ versionId }) => versionId),
            );
            setShowAddServiceModal(true);
          }}
        />
      ))}
      <StyledButton onClick={addCategory}>Add Category</StyledButton>
      {!serviceMenu.categories.length && !!errors.categories && (
        <ErrorMessageContainer>{errors.categories}</ErrorMessageContainer>
      )}
      <BottomBar
        nextButton={{
          copy: 'Save & Close',
          props: {
            disabled: isUpdating,
            onClick: async () => {
              const saveSuccessful = await saveServiceMenu();
              if (saveSuccessful) {
                await closeBuilder();
              }
            },
          },
        }}
        secondaryButton={{
          copy: 'Cancel',
          props: {
            disabled: isUpdating,
            onClick: closeBuilder,
            paletteType: 'secondary',
          },
        }}
      />
      <AddServiceModal
        firmId={firmId}
        onSelect={(template) => {
          addService(currentCategoryId, template);
          setCurrentCategoryId(null);
          setCurrentCategoryTemplateVersionIds([]);
          setShowAddServiceModal(false);
        }}
        onCancel={() => setShowAddServiceModal(false)}
        showModal={showAddServiceModal}
        templates={templates.filter(
          (t) => !currentCategoryTemplateVersionIds.includes(t.version.id),
        )}
      />
    </MenuOfServicesContainer>
  );
};

export default MenuOfServicesBuilder;
