import axios from 'axios';
import Dinero from 'dinero.js';
import marked from 'marked';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { handleException } from 'utils/ErrorUtils';

import Button from '../../../components/standard/Button';
import Header from '../../../components/standard/Header';
import Spinner from '../../../components/standard/Spinner';
import { useUser } from '../../../context/UserContext';
import { trackStartMOSQuestionnaire } from '../../../services/Analytics';
import AuthUtility from '../../../services/AuthUtility';
import { googleTrackViewServiceDetail } from '../../../services/GoogleTagManager';
import { userIsClient } from '../../../services/Utilities';

const HorizontalBar = styled.hr`
  border-top: 1px solid #edeff1;
  width: 100%;
  margin: 5% auto;
`;

const Price = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: ${(props) => props.theme.palette.primary.main};
`;
const Detail = styled.div`
  font-family: Georgia, 'Times New Roman', serif;
  font-size: 18px;
  line-height: 144.6%;

  && b,
  strong {
    font-weight: 600;
  }

  && a {
    color: ${(props) => props.theme.palette.primary.main};

    &:hover {
      color: ${(props) => props.theme.palette.primary.light};
    }
  }
`;

const renderer = new marked.Renderer();
renderer.link = function targetBlankLink(...rest) {
  const link = marked.Renderer.prototype.link.apply(this, rest);
  return link.replace('<a', "<a target='_blank'");
};

marked.setOptions({
  renderer,
});

const ServiceDetailScreen = ({ match }) => {
  const {
    params: { firmId, serviceId },
  } = match;

  const { search } = useLocation();
  const [user] = useUser();

  const history = useHistory();
  const [service, setService] = useState(null);
  const longDescriptionHtml = service?.longDescription
    ? marked(service.longDescription)
    : null;

  const dineroPrice = service?.price
    ? Dinero({ amount: Number.parseInt(service.price, 10) })
    : null;
  const displayPrice = service?.displayPrice;

  const templateId = service?._id;

  const handleClick = useCallback(async () => {
    trackStartMOSQuestionnaire({
      templateName: service?.name,
      templateId,
      isNewUser: !user.userId,
      firm: firmId,
    });

    const firmNewMatterPath = `/new-contract/${templateId}${search}`;
    const clientNewMatterPath = `/firm/${firmId}/service/${serviceId}/request${search}`;

    if (AuthUtility.isAuthenticated()) {
      history.push(userIsClient() ? clientNewMatterPath : firmNewMatterPath);
    } else {
      // Prompt unauthenticated users to register as a client.
      const { firmUserId } = queryString.parse(search);
      history.push({
        pathname: `/client/register`,
        search: queryString.stringify({
          redirectPath: clientNewMatterPath,
          firm: firmId,
          firmUserId,
        }),
      });
    }
  }, [
    search,
    firmId,
    templateId,
    serviceId,
    history,
    user.userId,
    service?.name,
  ]);

  useEffect(() => {
    const fetchServices = async () => {
      if (firmId && serviceId) {
        try {
          const response = await axios.get(
            `/api/v1/firm/${firmId}/service/${serviceId}`,
          );
          setService(response.data);
          googleTrackViewServiceDetail(serviceId);
        } catch (error) {
          if (error.response?.status < 500) {
            history.replace(`/firm/${firmId}/servicemenu`);
            return;
          }

          handleException(error);
        }
      }
    };

    fetchServices();
  }, [history, firmId, serviceId]);

  return !service ? (
    <Spinner />
  ) : (
    <>
      <Header title={service.name} />
      <div className="col-xl-8 p-0">
        <div className="row mt-1">
          {longDescriptionHtml && (
            <div className="col-12">
              <Detail
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: longDescriptionHtml }}
              />
              <HorizontalBar />
            </div>
          )}
        </div>
        <div className="row align-items-center">
          <div className="col-md-9">
            <Price>
              {displayPrice ||
                dineroPrice?.toFormat(
                  !dineroPrice.hasSubUnits() ? '$0,0' : '$0,0.00',
                )}
            </Price>
          </div>
          <div className="col-sm-4 col-md-3 mt-md-0 mt-3">
            <Button onClick={handleClick} width="100%">
              Get Started
            </Button>
          </div>
        </div>
      </div>
      <div className="col mb-40" />
    </>
  );
};

export default ServiceDetailScreen;
