import { useCallback, useEffect, useMemo, useState } from 'react';

export function useBroadcastChannel(name, initialState) {
  const [value, setValue] = useState(initialState);
  const channel = useMemo(
    // BroadcastChannel is not supported on older versions of safari
    () => (window.BroadcastChannel ? new BroadcastChannel(name) : null),
    [name],
  );

  if (channel) {
    channel.onmessage = (event) => {
      event.preventDefault();
      const { data } = event;
      setValue(data);
    };
  }

  useEffect(() => () => channel?.close(), [channel]);

  const setValueAndPostMessage = useCallback(
    (newValue) => {
      channel?.postMessage(newValue);
      setValue(newValue);
    },
    [channel],
  );

  return [value, setValueAndPostMessage];
}
