import { faFileSignature } from '@fortawesome/pro-light-svg-icons';
import PropTypes from 'prop-types';
import React from 'react';

import { PopperMenuItem } from '../../../standard/PopperMenu';

export function SendForSigningAction({
  setDisplaySendForSigning = () => {},
  ...props
}) {
  return (
    <PopperMenuItem
      icon={faFileSignature}
      title="Send for Signing"
      onClick={() => setDisplaySendForSigning(true)}
      {...props}
    />
  );
}

SendForSigningAction.propTypes = {
  setDisplaySendForSigning: PropTypes.func.isRequired,
};
