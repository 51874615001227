import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export default () => {
  const { clientId } = useParams();

  useEffect(() => {
    window.CommandBar.addMetadata('clientId', clientId);
    return () => window.CommandBar.addMetadata('clientId', undefined);
  }, [clientId]);

  return null;
};
