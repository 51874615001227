import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components/macro';

import { ErrorMessageContainer } from '../Common';
import { ClickableCard } from '../MenuOfServicesCard';

const Card = styled(ClickableCard)`
  display: flex;
  width: 370px;
  height: 19rem;
  padding: 0;
  margin: auto;
  border: 2px dotted ${(props) => props.theme.palette.gray.light};
  color: ${(props) => props.theme.palette.gray.light};

  @media (max-width: 1460px) {
    width: 320px;
  }
  @media (max-width: 1265px) {
    width: 370px;
  }
  @media (max-width: 425px) {
    width: 100%;
    max-height: 23em;
  }

  :hover {
    border: 2px dotted ${(props) => props.theme.palette.primary.main};
    color: ${(props) => props.theme.palette.primary.main};

    svg {
      color: ${(props) => props.theme.palette.primary.main};
    }
  }

  svg {
    color: ${(props) => props.theme.palette.gray.light};
  }
`;

const CardIconContainer = styled.div`
  margin: auto;
`;

const CardErrorContainer = styled.div`
  display: flex;
  justify-content: space-around;

  grid-column: 2;
  grid-row: 4;
`;

const AddServiceCard = ({ onClick = (f) => f, errorMessage, ...props }) => (
  <Card
    role="button"
    data-testid="add-service-card"
    onClick={onClick}
    {...props}
  >
    <CardIconContainer>
      <FontAwesomeIcon icon={faPlus} size="4x" />
    </CardIconContainer>
    {!!errorMessage && (
      <CardErrorContainer>
        <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
      </CardErrorContainer>
    )}
  </Card>
);

export default AddServiceCard;
