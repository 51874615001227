/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */
import './DataTableStyle.css';

import { faPlusCircle, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import { toast } from 'react-toastify';
import styled, { css, withTheme } from 'styled-components/macro';

import {
  FormContextProvider,
  withFormContext,
} from '../../context/FormContext';
import EntityManager from '../../services/EntityManager';
import {
  evaluateApplicabilityAll,
  updateErrors,
  validateAll,
} from '../../services/TemplatedContractUtilities';
import { objectFromEntries } from '../../services/Utilities';
import { addressToStr } from '../../utils/AddressUtils';
import TemplateGeneratedFields from '../contracts/TemplateGeneratedFields'; // eslint-disable-line import/no-cycle
import { SCREEN_SIZES } from '../LayoutConstants';
import Button from '../standard/Button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../standard/Dialog';
import { BodyText, Tooltip } from './FormStyles';

const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: 6px;
`;

const Table = styled.table`
  && {
    width: 100%;
    @media (min-width: 1024px) {
      table-layout: fixed;
    }
    margin-top: 15px;
    padding: 0;
  }
`;

const Header = styled.th`
  && {
    font-size: 14px;
    font-weight: 700;
    padding: 15px;
    border: 1px solid #e9ecef;
    height: 65px;
    background-color: #f9fafb;
  }
`;

const DeleteHeader = styled.th`
  && {
    border-bottom-style: hidden;
    border-top-style: hidden;
    padding-left: 0;
    padding-right: 0;
    width: 36px;
  }
`;

const DeleteColumn = styled.td`
  && {
    padding-left: 0;
    border-bottom-style: hidden;
    border-top-style: hidden;
    vertical-align: middle;
  }
`;

const DeleteButton = styled.button`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 35px;
  height: 35px;
  font-size: 1.5em;
  border: none;
  background-color: transparent;
  color: #9f9f9f;

  :hover {
    color: ${(props) => props.theme.palette.destructive.main};
  }
`;

const StyledRow = styled.tr`
  ${({ disabled }) =>
    !disabled &&
    css`
      :hover {
        & > :nth-child(2) {
          @media (min-width: 1024px) {
            padding-left: 13px !important;
            border-left: 5px solid
              ${(props) => props.theme.palette.primary.main};
          }
        }

        margin: -1px;
        box-shadow: 65px 0 5px #d8dadc;
        z-index: 2;
        padding-bottom: 13px;
        cursor: pointer;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
      }
    `}
`;

const Cell = styled.td`
  && {
    border: 1px solid #e9ecef;
    padding: 17px 15px !important;
    min-width: 200px;
  }
`;

const CellText = styled(BodyText)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledButton = styled(Button)`
  font-size: 18px;
  min-width: 120px;
  min-height: 50px;
  white-space: normal;
`;

const AddRowButton = styled(StyledButton)`
  padding-left: 0;
`;

const EmptyTableAddButton = styled(Button)`
  font-size: 18px;
  margin: 20px 0 0;
  width: 100%;
  border-radius: 0 !important;
  white-space: normal;
`;

const CancelButton = styled(StyledButton)`
  text-align: left;
  @media ${SCREEN_SIZES.X_SMALL} {
    text-align: center;
  }
`;

const getVisibleColumns = (elements) => {
  const unsupportedTypes = ['Note'];
  return elements.filter(({ showCol, type }) => {
    const dataType = type.type || type;
    return showCol !== false && !unsupportedTypes.includes(dataType);
  });
};

const MobileStylingWrapper = styled.div`
  overflow-x: hidden;
  @media (max-width: 1024px) {
    overflow-x: auto;
  }
`;

const DataTableDialog = styled(Dialog)`
  & .MuiDialog-paper {
    height: 800px;
    width: 100%;
    max-width: 900px;
    @media (max-width: 1024px) {
      height: calc(100% - 10px);
    }
  }
`;

const DataTableDialogContent = styled(DialogContent)`
  margin: 30px 0;
`;

const FooterControl = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 30px;
  @media ${SCREEN_SIZES.X_SMALL} {
    flex-direction: column-reverse;
  }
`;

class DataTable extends React.Component {
  constructor(props) {
    super(props);
    const { data = {}, elements, form = {} } = this.props;

    // Initialize state maps
    const elementsWithIds = elements.map((element, index) => ({
      _id: index,
      ...element,
    }));

    const errors = objectFromEntries(elementsWithIds.map((p) => [p._id, []]));
    const formData = {};
    this.state = {
      errors,
      displayModal: false,
      elements: elementsWithIds,
      dataTableItems: data,
      formData,
      isFormUpdating: false,
      formContext: {
        ...form,
        entities: new EntityManager(
          formData,
          elementsWithIds,
          this.updateFormData,
          form.entities?.entities,
        ),
      },
    };
  }

  componentDidUpdate({ data: prevData }) {
    const { data } = this.props;
    if (data !== prevData) this.onUpdate();
  }

  updateFormData = (name, value) => {
    this.setState(({ formData: oldFormData }) => ({
      formData: { ...oldFormData, [name]: value },
    }));
  };

  handleRowDel = (dataTableItem) => {
    const { name, onChange } = this.props;
    const { dataTableItems } = this.state;
    const indexToRemove = dataTableItems.indexOf(dataTableItem);
    const newDataTableItems = dataTableItems.filter(
      (_item, index) => index !== indexToRemove,
    );
    this.setState({ dataTableItems: newDataTableItems });
    const e = {
      target: {
        name,
        value: newDataTableItems,
      },
    };
    onChange(e);
  };

  handleRowEdit = (dataTableItem) => {
    const { dataTableItems } = this.state;
    const index = dataTableItems.indexOf(dataTableItem);
    this.showForm(dataTableItems[index], 'Update');
  };

  handleSubmit = async () => {
    const { name, onBlur, onChange, contract } = this.props;
    const {
      formData,
      elements,
      formContext: { entities },
      isFormUpdating,
    } = this.state;

    if (isFormUpdating) {
      // Try again in 10ms
      setTimeout(() => this.handleSubmit(), 10);
      return;
    }

    const formDataToValidate = Object.fromEntries(
      elements.map((element) => [
        element.identifier,
        formData[element.identifier],
      ]),
    );

    const errors = await validateAll(formDataToValidate, elements, contract);
    const hasError = Object.values(errors).some(
      (errorsList) => errorsList.length > 0,
    );

    if (hasError) {
      this.setState(
        {
          errors,
        },
        () =>
          toast.error(
            'There were invalid or incomplete fields in your submission. Please correct them and submit again.',
          ),
      );
      return;
    }

    this.setState(
      ({ dataTableItems, index }) => {
        const newDataTableItems =
          index == null
            ? [...dataTableItems, formData]
            : dataTableItems.map((dt, i) => (i === index ? formData : dt));
        return {
          dataTableItems: newDataTableItems,
          index: null,
        };
      },
      () => {
        const { form } = this.props;
        form?.entities?.mergeEntityContext(entities);
        const { dataTableItems } = this.state;
        const e = {
          target: {
            name,
            value: dataTableItems,
          },
        };
        onChange(e);
      },
    );
    // Differentiate between questionnaire and template.
    // Questionnaire requires update to table validation.
    if (contract) {
      const e = {
        target: {
          name,
          value: elements,
        },
      };
      onBlur(e);
    }
    this.closeForm();
  };

  showForm = (formData, verb = 'Add') => {
    this.setState(({ elements, dataTableItems }) => {
      const { form } = this.props;
      let index = null;
      if (Object.values(formData).length !== 0) {
        index = dataTableItems.indexOf(formData);
      }
      const applicability = evaluateApplicabilityAll(formData, elements);
      const newFormData = { ...formData };
      elements.forEach((element) => {
        if (!newFormData[element.identifier]) {
          newFormData[element.identifier] = element.defaultValue || '';
        }
      });
      return {
        displayModal: true,
        formData: newFormData,
        index, // eslint-disable-line react/no-unused-state
        verb,
        applicability,
        formContext: {
          ...form,
          entities: new EntityManager(
            newFormData,
            elements,
            this.updateFormData,
            form?.entities?.entities,
          ),
        },
      };
    });
  };

  closeForm = () => {
    const { elements } = this.state;
    const errors = objectFromEntries(elements.map((p) => [p._id, []]));
    this.setState({
      displayModal: false,
      errors,
      formData: {},
    });
  };

  onUpdate = () => {
    const { data } = this.props;
    this.setState(({ elements, formData }) => ({
      applicability: evaluateApplicabilityAll(formData, elements),
      dataTableItems: data,
    }));
  };

  handleGeneratedFieldsChange = (event) => {
    const { elements } = this.state;
    const { errors } = this.state;
    const { target } = event;
    const { name, type } = target;
    let value;
    if (type === 'checkbox') {
      value = target.checked;
    } else if (type === 'number') {
      value = Number.parseFloat(target.value);
    } else {
      value = target.value;
    }
    const applicability = evaluateApplicabilityAll(value, elements);
    // Clear any errors for non-applicable fields
    Object.entries(applicability).forEach(([key, isApplicable]) => {
      errors[key] = isApplicable ? errors[key] : [];
    });
    this.setState(
      ({ formData }) => ({
        formData: { ...formData, [name]: value },
      }),
      () => this.onUpdate(),
    );
  };

  onBlur = async (event) => {
    const { contract } = this.props;
    const { errors, elements, applicability } = this.state;
    const { relatedTarget } = event;
    if (relatedTarget && relatedTarget.getAttribute('type') === 'button') {
      event.preventDefault();
      return;
    }
    const newErrors = await updateErrors(
      event,
      elements,
      errors,
      applicability,
      contract,
    );
    this.setState({ errors: newErrors }, () => this.onUpdate());
  };

  generateTableHeaders() {
    const { elements } = this.state;
    const columns = getVisibleColumns(elements);
    return (
      <tr>
        <DeleteHeader />
        {Object.values(columns).map((column) => (
          <Header key={column.name}>{column.name}</Header>
        ))}
      </tr>
    );
  }

  render() {
    const {
      errors,
      elements,
      formData,
      dataTableItems,
      displayModal,
      applicability,
      verb,
      formContext,
      isFormUpdating,
    } = this.state;
    const {
      noun,
      name,
      disabled = false,
      disableAddButton = false,
    } = this.props;
    const rowNoun = noun || 'Item';

    // Generate blank first row if no data in table
    const dataTableItemRows = dataTableItems.map((dataTableItem, i) => {
      const rowApplicability = evaluateApplicabilityAll(
        dataTableItem,
        elements,
      );
      return (
        <TableRow
          key={`data-item-${i}`} /* eslint-disable-line react/no-array-index-key */
          dataTableItem={dataTableItem}
          elements={elements}
          onDelEvent={this.handleRowDel}
          onRowClick={this.handleRowEdit}
          onBlur={this.onBlur}
          errors={errors}
          applicability={rowApplicability}
          disabled={disabled}
        />
      );
    });

    const addButton =
      dataTableItemRows.length > 0 ? (
        <AddRowButton
          onClick={() => this.showForm({})}
          disabled={disabled}
          pure
        >
          <StyledIcon icon={faPlusCircle} />
          {`Add ${rowNoun}`}
        </AddRowButton>
      ) : (
        <EmptyTableAddButton
          outline
          onClick={() => this.showForm({})}
          className="col-sm-8"
          style={{ fontSize: '18px !important' }}
          disabled={disabled}
        >
          <StyledIcon icon={faPlusCircle} />
          {`Add ${rowNoun}`}
        </EmptyTableAddButton>
      );

    return (
      <React.Fragment>
        {dataTableItems.length !== 0 && (
          <MobileStylingWrapper>
            <Table className="table">
              <thead>{this.generateTableHeaders()}</thead>
              <tbody>{dataTableItemRows}</tbody>
            </Table>
          </MobileStylingWrapper>
        )}
        {!disableAddButton && <div>{addButton}</div>}
        <DataTableDialog
          id={`${name}-form`}
          open={displayModal}
          onClose={this.closeForm}
          disableScrollLock
        >
          <DialogHeader>
            <DialogTitle>{`${verb} ${rowNoun}`}</DialogTitle>
          </DialogHeader>
          <DataTableDialogContent>
            <FormContextProvider
              {...formContext}
              isFormUpdating={isFormUpdating}
              setIsFormUpdating={(newIsFormUpdating) =>
                this.setState({ isFormUpdating: newIsFormUpdating })
              }
            >
              <TemplateGeneratedFields
                onChange={this.handleGeneratedFieldsChange}
                onBlur={this.onBlur}
                paramSpec={elements}
                templateParams={formData}
                errors={errors}
                applicability={applicability}
                marginBottom="80px"
                inModal
              />
            </FormContextProvider>
          </DataTableDialogContent>
          <DialogFooter>
            <FooterControl>
              <CancelButton pure paletteType="gray" onClick={this.closeForm}>
                Cancel
              </CancelButton>
              <StyledButton dataTestId="add-button" onClick={this.handleSubmit}>
                {`${verb} ${rowNoun}`}
              </StyledButton>
            </FooterControl>
          </DialogFooter>
        </DataTableDialog>
      </React.Fragment>
    );
  }
}

class TableRow extends React.Component {
  constructor(props) {
    super(props);
    this.cellRefs = new Map();
    this.state = {
      showTooltip: [],
    };
    const { applicability } = this.props;
    this.applicability = applicability;
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      const { applicability } = this.props;
      this.applicability = applicability;
      const cellRefsArray = Array.from(this.cellRefs.values());
      cellRefsArray.forEach((ref, index) => this.isOverflown(ref, index));
    }
  }

  onDelEvent = () => {
    const { dataTableItem, onDelEvent } = this.props;
    onDelEvent(dataTableItem);
  };

  onRowClick = () => {
    const { dataTableItem, onRowClick } = this.props;
    onRowClick(dataTableItem);
  };

  isOverflown = (element, index) => {
    if (element && element.scrollWidth > element.clientWidth) {
      this.setState(({ showTooltip }) => {
        const newShowToolTip = showTooltip;
        newShowToolTip[index] = true;
        return newShowToolTip;
      });
    }
  };

  render() {
    const { elements, dataTableItem, disabled = false } = this.props;
    const cells = getVisibleColumns(elements).map((cell, index) => {
      const { identifier, type } = cell;
      const dataType = type.type || type;
      let value = dataTableItem[identifier];
      if (dataType === 'Address' && dataTableItem[identifier]) {
        value = addressToStr(dataTableItem[identifier]);
      }
      if (dataType === 'Jurisdiction' && dataTableItem[identifier]) {
        const { country, subnationalDivision, county } =
          dataTableItem[identifier];
        value = [county, subnationalDivision, country]
          .filter((v) => v)
          .join(', ');
      }
      if (dataType === 'MultiField' && dataTableItem[identifier]) {
        const multiFieldArray = Object.values(dataTableItem[identifier]);
        value = `${multiFieldArray[0]} ${multiFieldArray[1]}`;
      }
      if (dataType === 'MultiSelect' && dataTableItem[identifier]) {
        const multiSelectArray = Object.entries(dataTableItem[identifier]);
        const selectedOptions = [];
        multiSelectArray.forEach(([option, optionValue]) => {
          if (optionValue) {
            selectedOptions.push(option);
          }
        });
        value = `${selectedOptions.join(', ')}`;
      }

      if (dataType === 'DataTable' && !!dataTableItem[identifier]?.length) {
        const [firstRow] = dataTableItem[identifier];
        [value] = Object.values(firstRow);
      }

      if (dataType === 'Contact' && !!dataTableItem[identifier]?.name) {
        value = dataTableItem[identifier].name;
      }

      if (this.applicability && !this.applicability[cell._id]) {
        value = null;
      }

      const cellData = {
        name: identifier,
        value,
      };

      const { showTooltip } = this.state;
      return (
        <Cell
          key={cellData.name}
          name={cellData.name}
          autoComplete="no"
          onClick={
            !disabled && dataTableItem.length !== 0
              ? this.onRowClick
              : undefined
          }
        >
          <CellText
            as="div"
            ref={(cellRef) => {
              this.cellRefs.set(identifier, cellRef);
            }}
            data-tip={showTooltip[index] ? value : ''}
            data-for={`data-${identifier}`}
          >
            {dataType === 'Currency' && (
              <FormattedNumber
                value={cellData.value}
                style="currency" // eslint-disable-line react/style-prop-object
                currency="USD"
              />
            )}
            {dataType === 'Percent' && `${cellData.value}%`}
            {dataType === 'Integer' && (
              <FormattedNumber value={cellData.value} />
            )}
            {dataType !== 'Integer' &&
              dataType !== 'Currency' &&
              dataType !== 'Percent' &&
              cellData.value}
          </CellText>
          <Tooltip id={`data-${identifier}`} effect="solid" place="top" />
        </Cell>
      );
    });

    return (
      <StyledRow className="eachRow" disabled={disabled}>
        <DeleteColumn>
          <DeleteButton onClick={this.onDelEvent} disabled={disabled}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </DeleteButton>
        </DeleteColumn>
        {cells}
      </StyledRow>
    );
  }
}

DataTable.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  noun: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      identifier: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.any,
      required: PropTypes.bool,
    }),
  ),
  contract: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.object),
};

export default withFormContext(withTheme(DataTable));
