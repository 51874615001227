import { faCopy } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as clipboard from 'clipboard-polyfill/text';
import React from 'react';
import styled from 'styled-components/macro';

import Button from '../standard/Button';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 30vw;
`;

const MenuOfServicesLink = ({ onAfterCopy = (f) => f }) => {
  const firmId = localStorage.getItem('firmId');

  const { protocol, host } = window.location;

  const mosPath = `firm/${firmId}/servicemenu`;
  const mosLink = `${protocol}//${host}/${mosPath}`;

  return (
    <Container>
      <Button
        name="copy-mos-button"
        onClick={async () => {
          await clipboard.writeText(mosLink);
          onAfterCopy();
        }}
        icon={<FontAwesomeIcon icon={faCopy} />}
        type="button"
        paletteType="gray"
        pure
      >
        Copy Link to Clipboard
      </Button>
    </Container>
  );
};

export default MenuOfServicesLink;
