import { useFlagVariation } from '@flopflip/react-broadcast';
import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';

import { flagNames } from '../featureFlags';

const Hotjar = () => {
  const hotjarId = useFlagVariation(flagNames.HOTJAR);
  const history = useHistory();

  const userId = localStorage.getItem('userId');
  const email = localStorage.getItem('email');

  const identifyHotjar = useCallback(() => {
    window.hj?.('identify', userId, { Email: email });
  }, [userId, email]);

  useEffect(() => history.listen(identifyHotjar), [identifyHotjar, history]);

  useEffect(() => {
    document.addEventListener('onHotjarInitialized', identifyHotjar);
    return () =>
      document.removeEventListener('onHotjarInitialized', identifyHotjar);
  }, [identifyHotjar]);

  if (!hotjarId || hotjarId === 'none') {
    return null;
  }
  return (
    <Helmet>
      <script>
        {`(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${hotjarId},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
        const event = new Event('onHotjarInitialized');
        o.dispatchEvent(event);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
      </script>
    </Helmet>
  );
};

export default Hotjar;
