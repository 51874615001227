import PropTypes from 'prop-types';
import { React } from 'react';
import styled, { css } from 'styled-components/macro';

export const ChipLabelStyles = (props) => css`
  padding: 0 5px;
  color: ${props.theme.palette.shade.gray_1};
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05px;
`;

export const ChipLabel = styled.div`
  ${ChipLabelStyles}
`;

const ChipContainer = styled.div`
  display: inline-block;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ?? theme.palette.feedback.info.light};
  padding: 0.25rem;
  margin: 0.25rem 0.5rem 0.25rem 0;
  border-radius: 1.5rem;
`;

export const Chip = ({ label, backgroundColor, ...props }) => (
  <ChipContainer backgroundColor={backgroundColor} {...props}>
    <ChipLabel>{label}</ChipLabel>
  </ChipContainer>
);

Chip.propTypes = {
  label: PropTypes.string,
  backgroundColor: PropTypes.string,
};
