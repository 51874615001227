import * as Icons from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { useUser } from '../../context/UserContext';
import { useCategories } from '../client/categories/hooks';

const UNADDABLE_CATEGORIES = ['Minute Book'];

function getIconForCategory(category) {
  if (category.icon && Icons[category.icon]) {
    return <FontAwesomeIcon icon={Icons[category.icon]} />;
  }
  if (category.parent) {
    return getIconForCategory(category.parent);
  }
  return null;
}

export default () => {
  const [user] = useUser();
  const [categories] = useCategories({
    flatten: true,
    isDisabled: !user.userType,
  });

  useEffect(() => {
    if (user.userType === 'client' && categories) {
      const categoryRecords = categories.map((category) => {
        const icon = getIconForCategory(category);
        return {
          id: category.id,
          label: category.name,
          icon: icon ? renderToStaticMarkup(icon) : undefined,
        };
      });
      window.CommandBar.addRecords('categories', categoryRecords);
      window.CommandBar.addRecords(
        'addableCategories',
        categoryRecords.filter(
          ({ label }) => !UNADDABLE_CATEGORIES.includes(label),
        ),
      );
    }
  }, [user.userType, categories]);

  return null;
};
