import React from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import styled from 'styled-components/macro';

import { SCREEN_SIZES } from '../../../LayoutConstants';
import Spinner from '../../../standard/Spinner';
import UnsavedChangesModal from '../../../standard/UnsavedChangesModal';
import styles from './styles';

const DataContentInner = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  padding: ${styles.borderPadding};
  padding-bottom: 6.5rem;
  max-width: ${({ fullWidth }) => (fullWidth ? '' : '60rem')};

  @media ${SCREEN_SIZES.X_SMALL} {
    padding: ${styles.smallMobileBorderPadding};
    width: 100%;
  }
`;

export default function DataContent({
  children,
  loading,
  isDirty = false,
  fullWidth = false,
}) {
  return (
    <>
      {!!loading && <Spinner />}
      <NavigationPrompt when={isDirty}>
        {({ onConfirm, onCancel }) => (
          <UnsavedChangesModal
            display
            onCancel={onCancel}
            onAccept={onConfirm}
          />
        )}
      </NavigationPrompt>
      <DataContentInner fullWidth={fullWidth}>{children}</DataContentInner>
    </>
  );
}
