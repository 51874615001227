import axios from 'axios';
import QueryString from 'query-string';
import { handleException } from 'utils/ErrorUtils';

import { trackLogout } from './Analytics';
import Auth0Utility from './auth/Auth0Utility';
import LocalAuthUtility from './auth/LocalAuthUtility';
import { useLocalAuth } from './Config';

const AuthUtility = useLocalAuth ? LocalAuthUtility : Auth0Utility;
const ROOT_OR_LOGIN_PATHS = ['/', '', '/login', '/client/login', '/logout'];
const FIFTEEN_MINUTE_MS = 900000;

AuthUtility.clearStorage = () => {
  // Track logout if the user was previously logged in.
  if (localStorage.getItem('access_token')) {
    trackLogout();
  }
  // Clear access token and ID token from local storage
  localStorage.removeItem('access_token');
  localStorage.removeItem('expires_at');
  localStorage.removeItem('issued_at');
  localStorage.removeItem('userId');
  localStorage.removeItem('firmId');
  localStorage.removeItem('firmName');
  localStorage.removeItem('orgId');
  localStorage.removeItem('orgName');
  localStorage.removeItem('email');
  localStorage.removeItem('userType');
  localStorage.removeItem('rallyAdmin');
  localStorage.removeItem('firmAdmin');
  localStorage.removeItem('hidePublicTemplates');

  // remove default authorization header as we're now logged out
  delete axios.defaults.headers.common.Authorization;
};

AuthUtility.logout = () => {
  if (!AuthUtility.logoutTimeoutId) {
    AuthUtility.logoutTimeoutId = window.setTimeout(() => {
      AuthUtility.logoutTimeoutId = null;
      AuthUtility.clearStorage();

      let queryString = '';
      const { pathname, search } = window.location;
      const redirectPath = pathname + search;

      if (!ROOT_OR_LOGIN_PATHS.includes(pathname)) {
        queryString = `?${QueryString.stringify({ redirectPath })}`;
      }

      window.location.replace(`/login${queryString}`);
    }, 0);
  }
};

AuthUtility.isAuthenticated = () => {
  try {
    const accessToken = localStorage.getItem('access_token');
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    if (accessToken && expiresAt) {
      return new Date().getTime() < expiresAt;
    }
  } catch (error) {
    handleException(error);
  }
  return false;
};

AuthUtility.shouldRefreshToken = () => {
  try {
    const accessToken = localStorage.getItem('access_token');
    const issuedAt = JSON.parse(localStorage.getItem('issued_at'));
    return accessToken && new Date().getTime() - issuedAt >= FIFTEEN_MINUTE_MS;
  } catch (error) {
    handleException(error);
  }
  return false;
};

export default AuthUtility;
