import { gql } from '@apollo/client';

export const FETCH_ORGANIZATION_PERMISSION_FOR_USER = gql`
  query fetchOrganizationPermissionForUser(
    $userId: String!
    $organizationId: MongoID!
  ) {
    organizationPermission(userId: $userId, organizationId: $organizationId) {
      _id
      canAccessAllMatters
      canAccessMatterCategories
      canManageTeamMembers
      canManagePermissions
      canManageBilling
      canCreateNewMatters
      canModifyTemplates
      canAccessInformationTab
      canAccessFilesRepository
    }
  }
`;
