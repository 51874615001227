import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';

import { FloatingTextField } from '../../forms/FormStyles';
import Button from '../../standard/Button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../standard/Dialog';

const FooterControl = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 30px;
`;

const FooterButton = styled(Button)`
  font-size: 18px;
  min-width: 120px;
  height: 50px;
`;

export function EditMatterCategoryDialog({
  open,
  onClose,
  onSubmit,
  categoryId,
  name,
  icon,
}) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, isSubmitSuccessful },
  } = useForm({
    mode: 'onChange',
    defaultValues: { categoryId, name, icon },
  });

  useEffect(() => {
    reset({ categoryId, name, icon });
  }, [reset, categoryId, name, icon]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <Dialog open={open} maxWidth="sm" onClose={onClose} fullWidth>
      <DialogHeader>
        <DialogTitle>Edit Category</DialogTitle>
      </DialogHeader>
      <DialogContent>
        <form id="edit-category-form" onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" {...register('categoryId')} />
          <FloatingTextField
            label="Name"
            InputProps={{
              ...register('name', {
                value: name,
                setValueAs: (v) => v?.trim(),
                required: true,
              }),
            }}
            autoFocus
          />
          <FloatingTextField
            label="Icon Name"
            InputProps={{
              ...register('icon', {
                value: icon,
                setValueAs: (v) => v?.trim(),
                required: false,
              }),
            }}
          />
        </form>
      </DialogContent>
      <DialogFooter>
        <FooterControl>
          <FooterButton pure onClick={onClose} paletteType="gray">
            Cancel
          </FooterButton>
          <FooterButton
            type="submit"
            form="edit-category-form"
            disabled={!isValid || !isDirty}
          >
            Edit Category
          </FooterButton>
        </FooterControl>
      </DialogFooter>
    </Dialog>
  );
}
