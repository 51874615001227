import React, { forwardRef, useContext, useState } from 'react';
import styled from 'styled-components/macro';

import { FormContext } from '../../../../context/FormContext';
import { EditContactCard } from './EditContactCard';
import { UpdateContactCard } from './UpdateContactCard';

const ContactCardContainer = styled.div`
  margin-top: 18px;
  border: 1px solid rgba(77, 82, 89, 0.07);
  border-radius: 5px;
`;

export const ContactCard = forwardRef(
  (
    {
      name,
      contact,
      onChange,
      onBlur,
      required = false,
      fieldConfig,
      contactType,
      ...props
    },
    ref,
  ) => {
    const { autoUpdateContacts } = useContext(FormContext);

    const [isEditing, setIsEditing] = useState(!contact?.name);

    return (
      <div {...props} ref={ref}>
        <ContactCardContainer>
          <EditContactCard
            name={name}
            contact={contact}
            onChange={onChange}
            onBlur={onBlur}
            contactType={contactType}
            fieldConfig={fieldConfig}
            required={required}
            setIsEditing={setIsEditing}
          />
        </ContactCardContainer>
        {!isEditing && !autoUpdateContacts && contact._contactId && (
          <UpdateContactCard
            name={name}
            contact={contact}
            fieldConfig={fieldConfig}
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
      </div>
    );
  },
);
