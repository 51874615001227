import React, { forwardRef, useEffect } from 'react';

import { withFormContext } from '../../context/FormContext';
import { Input } from './FormStyles';

const CompanyName = forwardRef(
  (
    { form, name, helper, onChange, onBlur, value, required, errors, ...props },
    ref,
  ) => {
    // Initialize value with Company name if there is no data.
    useEffect(() => {
      if (!value) onChange({ target: { name, value: form.org.getName() } });
    }, [onChange, form.org, name, value]);

    return (
      <Input
        type="text"
        ref={ref}
        name={name}
        helper={helper}
        onChange={(e) => {
          form.org.updateName(e.target.value);
          onChange(e);
        }}
        onBlur={onBlur}
        value={value}
        required={required || false}
        errors={errors}
        autoComplete="no"
        {...props}
      />
    );
  },
);

export default withFormContext(CompanyName);
