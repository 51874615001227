import React from 'react';
import styled from 'styled-components/macro';

import { SCREEN_SIZES } from '../../../LayoutConstants';
import styles from './styles';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  padding: ${styles.borderPadding};
  padding-right: 10vw;
  border-bottom: 1px solid rgba(77, 82, 89, 0.07);
  align-items: center;
  height: 150px;

  @media ${SCREEN_SIZES.X_SMALL} {
    padding: ${styles.smallMobileBorderPadding};
    height: 7rem;
  }
`;

const RightContainer = styled.div`
  min-width: 200px;
  display: flex;
  justify-content: right;
`;

const Header = styled.h2`
  line-height: 1;
  margin: 0;
`;

export default function DataHeader({ title, right }) {
  return (
    <HeaderContainer>
      <Header>{title}</Header>
      {right && <RightContainer>{right}</RightContainer>}
    </HeaderContainer>
  );
}
