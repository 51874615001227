import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

import { TypographyStyles } from '../../../Typography';
import { MatterFieldValue } from './MatterFieldValue';

const MatterFieldRowContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  :hover {
    .rally-matter-field-value-kebab {
      visibility: visible;
    }
  }
`;

const MatterFieldName = styled.label`
  ${TypographyStyles.FontFamily}
  ${TypographyStyles.Color}

  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;

  letter-spacing: 0.05px;

  margin-bottom: 0.25rem;
`;

export function MatterFieldRow({
  matterFieldValue,
  setMatterFieldIdToDelete = (f) => f,
  updateMatterFieldValue = async (f) => f,
  ...props
}) {
  const {
    matterField: { _id: matterFieldId, name },
  } = matterFieldValue;

  return (
    <MatterFieldRowContainer {...props}>
      <MatterFieldName htmlFor={`${matterFieldId}-value`}>
        {name}
      </MatterFieldName>
      <MatterFieldValue
        matterFieldValue={matterFieldValue}
        setMatterFieldIdToDelete={setMatterFieldIdToDelete}
        updateMatterFieldValues={updateMatterFieldValue}
      />
    </MatterFieldRowContainer>
  );
}

MatterFieldRow.propTypes = {
  matterFieldValue: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    matterField: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      type: PropTypes.any,
      name: PropTypes.string,
    }),
    value: PropTypes.any,
  }),
  setMatterFieldIdToDelete: PropTypes.func,
  updateMatterFieldValue: PropTypes.func,
};
