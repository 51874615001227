import React from 'react';

import SplashImage from '../../components/SplashImage';
import ForgotPasswordBox from './components/ForgotPasswordBox';

const ForgotPasswordScreen = () => (
  <main>
    <div className="row no-gutters min-h-fullscreen bg-white">
      <SplashImage />
      <ForgotPasswordBox />
    </div>
  </main>
);

export default ForgotPasswordScreen;
