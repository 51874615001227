import React, { forwardRef } from 'react';
import NumberFormat from 'react-number-format';

import { Input } from './FormStyles';

function PhoneNumber(
  { name, helper, onBlur, onChange, value, required, errors, ...props },
  ref,
) {
  return (
    <Input
      as={NumberFormat}
      name={name}
      format="(###) ###-#### ext.####"
      type="tel"
      helper={helper}
      onBlur={onBlur}
      value={value}
      required={required || false}
      getInputRef={ref}
      errors={errors}
      autoComplete="no"
      onValueChange={(values) => {
        const phoneNumbers = values.formattedValue.split(' ext.');
        const event = {
          target: {
            name,
            value:
              values.value.length > 10
                ? values.formattedValue.trim()
                : phoneNumbers[0],
          },
        };
        onChange(event);
      }}
      {...props}
    />
  );
}

export default forwardRef(PhoneNumber);
