import React, { forwardRef } from 'react';
import styled from 'styled-components/macro';

import Address from '../Address';
import Dropdown from '../Dropdown';
import Email from '../Email';
import { ErrorMessage, Input, Label } from '../FormStyles';
import PhoneNumber from '../PhoneNumber';
import { ContactNameComboBox } from './ContactNameComboBox';

const SubFieldContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: 48px;
  }
`;

export const ContactSubField = forwardRef(
  (
    {
      fieldName,
      subFieldType,
      subfieldName,
      subfieldLabel,
      contactData,
      onChange,
      contactType,
      clientId,
      errors,
      required,
      autoCapitalize,
    },
    ref,
  ) => {
    const updateSubfield = ({ target: { value } }) => {
      onChange({ ...contactData, [subfieldName]: value });
    };

    const labelId = `${fieldName}-${subfieldName}`;

    const subfieldErrors = errors?.[subfieldName] ?? [];

    let input = null;
    if (subFieldType === 'PhoneNumber') {
      input = (
        <div className="col-sm-8 col-lg-8 col-xl-6">
          <PhoneNumber
            aria-labelledby={labelId}
            name={subfieldName}
            onChange={updateSubfield}
            value={contactData?.[subfieldName] ?? ''}
            errors={subfieldErrors}
            required={required}
            ref={ref}
          />
        </div>
      );
    } else if (subFieldType === 'ContactNameComboBox') {
      input = (
        <div className="col-sm-10 col-md-8 col-lg-8 col-xl-6">
          <ContactNameComboBox
            clientId={clientId}
            labelledby={labelId}
            contactData={contactData}
            updateContactData={onChange}
            contactType={contactType}
            ref={ref}
          />
        </div>
      );
    } else if (subFieldType === 'ContactType') {
      input = (
        <div className="col-sm-8 col-lg-8 col-xl-6 col-xl-4">
          <Dropdown
            aria-labelledby={labelId}
            name={subfieldName}
            onChange={updateSubfield}
            options={['Individual', 'Company']}
            value={contactData?.[subfieldName] ?? ''}
            errors={subfieldErrors}
            required={required}
            ref={ref}
          />
        </div>
      );
    } else if (subFieldType === 'Address') {
      input = (
        <div className="col-lg-10 col-xl-12">
          <Address
            aria-labelledby={labelId}
            name={subfieldName}
            onChange={updateSubfield}
            required={required}
            address={contactData?.[subfieldName] ?? {}}
            errors={subfieldErrors}
            ref={ref}
          />
        </div>
      );
    } else if (subFieldType === 'Text') {
      input = (
        <div className="col-sm-8 col-lg-8 col-xl-6">
          <Input
            aria-labelledby={labelId}
            name={subfieldName}
            value={contactData?.[subfieldName] ?? ''}
            errors={subfieldErrors}
            type="text"
            onChange={updateSubfield}
            required={required}
            autoComplete="off"
            ref={ref}
          />
        </div>
      );
    } else if (subFieldType === 'Email') {
      input = (
        <div className="col-sm-8 col-lg-8 col-xl-6">
          <Email
            aria-labelledby={labelId}
            name={subfieldName}
            value={contactData?.[subfieldName] ?? ''}
            errors={subfieldErrors}
            type="text"
            onChange={updateSubfield}
            required={required}
            ref={ref}
          />
        </div>
      );
    } else {
      input = (
        <div className="col-sm-8 col-lg-8 col-xl-6">
          <Input
            aria-labelledby={labelId}
            name={subfieldName}
            value={contactData?.[subfieldName] ?? ''}
            errors={subfieldErrors}
            type="text"
            onChange={updateSubfield}
            required={required}
            autoComplete="off"
            autoCapitalize={autoCapitalize}
            ref={ref}
          />
        </div>
      );
    }

    return (
      <SubFieldContainer>
        <Label
          id={labelId}
          variant="medium"
          className={required ? 'require' : ''}
        >
          {subfieldLabel}
        </Label>

        <div className="row">{input}</div>

        <div>
          {subfieldErrors.map((error) => (
            <ErrorMessage key={error} smallfield>
              {error}

              <br />
            </ErrorMessage>
          ))}
        </div>
      </SubFieldContainer>
    );
  },
);
