import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

import { SCREEN_SIZES } from '../../LayoutConstants';

const StyledBadgeContainer = styled.div`
  display: flex;
  width: fit-content;
  line-height: 14px;
  align-items: center;
  justify-content: center;
`;

const StyledBadge = styled.div.attrs({ className: 'state-badge-text' })`
  white-space: nowrap;
  color: ${(props) => props.theme.palette.shade.gray_2};
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 8px;
  margin-right: 6px;
  color: ${(props) => props.theme.palette.shade.gray_2};
  &.pending {
    color: ${(props) => props.theme.palette.feedback.warning.medium};
  }
  &.success {
    color: ${(props) => props.theme.palette.feedback.success.medium};
  }
  &.error {
    color: ${(props) => props.theme.palette.feedback.error.medium};
  }
  &.fa-check,
  &.fa-xmark {
    font-size: 12px;
  }
  &.fa-circle-dot {
    color: ${(props) => props.theme.palette.feedback.warning.medium};
    font-size: 14px;
  }
`;

export function StateBadge({ icon, iconClass, copy, ...props }) {
  const isMobile = useMediaQuery(SCREEN_SIZES.X_SMALL);

  return (
    <StyledBadgeContainer
      className={isMobile ? 'small' : ''}
      title={copy}
      {...props}
    >
      <Icon icon={icon} className={iconClass} />
      <StyledBadge>{copy}</StyledBadge>
    </StyledBadgeContainer>
  );
}

StateBadge.propTypes = {
  icon: PropTypes.object.isRequired,
  iconClass: PropTypes.oneOf(['error', 'pending', 'success', 'inactive']),
  copy: PropTypes.string.isRequired,
};
