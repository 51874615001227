import * as Sentry from '@sentry/react';
import React from 'react';
// import styled from 'styled-components/macro';

// const FallbackContainer = styled.div`
//   display: flex;
//   align-items: center;
// `;

// // TODO: Design a professional looking Fallback page for global errors with
// // next steps for the user (ex: Go Home, Reach out, etc.)
// function GlobalFallback() {
//   return (
//     <FallbackContainer>
//       <h1>Oops! You have encountered an error</h1>
//     </FallbackContainer>
//   );
// }

export function GlobalErrorBoundary({ children }) {
  return <Sentry.ErrorBoundary showDialog>{children}</Sentry.ErrorBoundary>;
}
