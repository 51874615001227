import { faCheck, faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled, { ThemeContext } from 'styled-components/macro';
import { isFQDN } from 'validator';

import Button from '../standard/Button';
import { TextField } from '../standard/form/TextField';
import Spinner from '../standard/Spinner';
import useSalesforceConnection from './useSalesforceConnection';

const StyledTextField = styled(TextField)`
  margin-bottom: 2.5rem;
`;

export function SalesforceConnect({ defaultValue = '', ...props }) {
  const [{ connectionStatus }, { connect, disconnect }] =
    useSalesforceConnection();
  const theme = useContext(ThemeContext);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues: { salesforceDomain: defaultValue } });

  const { connected: isConnected, instanceUrl, loading } = connectionStatus;

  useEffect(() => {
    if (isConnected && instanceUrl) {
      reset({ salesforceDomain: new URL(instanceUrl).host });
    }
  }, [isConnected, instanceUrl, reset]);

  return (
    <div {...props}>
      {loading && <Spinner />}
      <form onSubmit={handleSubmit(connect)}>
        <StyledTextField
          id="salesforce-domain"
          label="Salesforce Domain"
          disabled={loading || isConnected}
          suffix={
            isConnected ? (
              <FontAwesomeIcon
                icon={faCheck}
                color={theme.palette.feedback.success.medium}
                size="lg"
              />
            ) : undefined
          }
          error={errors?.salesforceDomain?.message}
          {...register('salesforceDomain', {
            onChange: (e) => {
              e.target.value = e.target.value.trim();
            },
            validate: (v) => isFQDN(v) || 'must be a valid domain',
            required: 'a valid domain is required to connect to Salesforce',
          })}
        />

        {isConnected ? (
          <Button
            type="button"
            style={{ float: 'right' }}
            disabled={loading}
            onClick={async () => {
              await disconnect();
              reset({ salesforceDomain: defaultValue });
            }}
            outline
          >
            <>
              <FontAwesomeIcon
                icon={faXmark}
                color={theme.palette.feedback.error.dark}
              />
              &nbsp;Disconnect
            </>
          </Button>
        ) : (
          <Button
            type="submit"
            style={{ float: 'right' }}
            disabled={loading}
            outline
          >
            Connect to Salesforce
          </Button>
        )}
      </form>
    </div>
  );
}
