import { useFlagVariation } from '@flopflip/react-broadcast';
import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/macro';

import Button from '../../../components/standard/Button';
import Modal from '../../../components/standard/Modal';
import StyledLink from '../../../components/standard/StyledLink';
import { flagNames } from '../../../featureFlags';
import AuthUtility from '../../../services/AuthUtility';
import { getUserType } from '../Utilities';
import PoweredByRally from './PoweredByRally';

const BoxWrapper = styled.div`
  position: absolute;
  top: calc(50% - 10px);
  transform: translateY(-50%);
  left: 0;
  right: 0;
`;

const StyledInput = styled.input`
  &&& {
    background-image: linear-gradient(
        ${(props) => props.theme.palette.primary.main},
        ${(props) => props.theme.palette.primary.main}
      ),
      linear-gradient(#ebebeb, #ebebeb);
  }
`;

const LoginBox = ({
  email: initialEmail,
  password: initialPassword,
  redirectPath,
  forgotPasswordLink,
}) => {
  const { registrationTerms: RegistrationTerms, showPoweredByRally } =
    useContext(ThemeContext);

  const [loginFailed, setLoginFailed] = useState(false);
  const [email, setEmail] = useState(initialEmail);
  const [password, setPassword] = useState(initialPassword);
  const [displayTerms, setDisplayTerms] = useState(false);
  const loginDangerBanner = useFlagVariation(flagNames.LOGIN_DANGER_BANNER);

  const handleLogin = async (e) => {
    e.preventDefault();
    const trimmedEmail = email?.trim();
    if (!trimmedEmail || !password) {
      return;
    }

    const options = {
      redirectPath,
    };

    const userType = await getUserType(email);
    if (!userType) {
      setLoginFailed(true);
      return;
    }

    new AuthUtility().login(
      trimmedEmail,
      password,
      () => setLoginFailed(true),
      options,
      userType,
    );
  };

  return (
    <div className="col-md-6 col-lg-5 col-xl-4">
      <BoxWrapper className="px-lg-5 py-30 px-4">
        <h4>Login</h4>
        <div>
          <p className="subtitle" style={{ fontSize: '14px' }}>
            Sign into your account.
          </p>
        </div>
        <br />
        {loginDangerBanner && loginDangerBanner !== 'null' && (
          <div className="alert alert-danger" role="alert">
            {loginDangerBanner}
          </div>
        )}
        {loginFailed ? (
          <div className="alert alert-danger" role="alert">
            Username or password incorrect.
          </div>
        ) : (
          ''
        )}

        <form className="form-type-material" onSubmit={handleLogin}>
          <div className={`form-group ${email ? 'do-float' : ''}`}>
            <StyledInput
              type="email"
              className="form-control"
              id="email"
              defaultValue={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="email">Email</label>
          </div>

          <div className={`form-group ${password ? 'do-float' : ''}`}>
            <StyledInput
              type="password"
              className="form-control"
              id="password"
              defaultValue={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label htmlFor="password">Password</label>
            <div>
              <p
                className="subtitle"
                style={{ fontSize: '13px', textAlign: 'right' }}
              >
                <StyledLink to={forgotPasswordLink}>
                  Forgot Password?
                </StyledLink>
              </p>
            </div>
          </div>

          <div className="form-group" style={{ paddingTop: '21px' }}>
            <Button type="submit" width="100%">
              Log In
            </Button>
          </div>
        </form>

        {RegistrationTerms && (
          <React.Fragment>
            <hr className="w-30px mt-30 mb-30" />
            <div style={{ textAlign: 'center' }}>
              <p
                onClick={() => setDisplayTerms(true)}
                style={{
                  cursor: 'pointer',
                  color: '#6EC4E8',
                  fontWeight: '500',
                }}
              >
                Terms of Service
              </p>
            </div>
          </React.Fragment>
        )}
      </BoxWrapper>
      {RegistrationTerms && (
        <Modal
          display={displayTerms}
          title="Terms of Service"
          onAccept={() => setDisplayTerms(false)}
          hideAccept
          hideClose
          hideHeader
          onCancel={() => setDisplayTerms(false)}
          maxWidth="1000px"
        >
          <RegistrationTerms
            handleSubmit={() => setDisplayTerms(false)}
            hideDisagree
          />
        </Modal>
      )}
      {showPoweredByRally && <PoweredByRally />}
    </div>
  );
};

export default LoginBox;
