import React from 'react';

import ContractPaper from './ContractPaper';

const ContractPreview = ({ contract: { html } }) => {
  if (html) {
    return <ContractPaper html={html} />;
  }

  return null;
};

export default ContractPreview;
