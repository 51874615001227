import { useFeatureToggle } from '@flopflip/react-broadcast';
import { faGift, faSearch, faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import queryString from 'query-string';
import React, { useContext, useEffect, useRef } from 'react';
import { matchPath, NavLink } from 'react-router-dom';
import styled, { ThemeContext, withTheme } from 'styled-components/macro';

import { useOrganizationPermission } from '../context/OrganizationPermissionContext';
import { useFirmSettings } from '../context/UserContext';
import { flagNames } from '../featureFlags';
import { trackKnowledgeBase, trackOpenBeamer } from '../services/Analytics';
import AuthUtility from '../services/AuthUtility';
import {
  isE2ETest,
  userIsClient,
  userIsFirmAdmin,
} from '../services/Utilities';

const TabMenu = styled.ul.attrs({ className: 'menu', role: 'tablist' })``;

const Tab = styled.li.attrs({ className: 'r-menu-item', role: 'tab' })``;

const MenuLink = styled.a`
  height: 75px;
  border-top: 3px solid transparent;
  color: ${(props) => props.theme.palette.gray.dark};
  padding: 0 ${({ lastItem }) => (lastItem ? `0` : `12px`)} 0 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  flex-grow: 1;
  -webkit-box-align: center;
  flex-shrink: 0;
  line-height: 1;
  letter-spacing: 0.5px;
  font-size: 14px;
  margin-left: 8px;
  margin-right: ${({ lastItem }) => (lastItem ? `0` : `8px`)};
  text-align: center;
  transition: none;

  :hover {
    color: ${(props) => props.theme.palette.gray.dark};
    cursor: pointer;
  }
`;

const MobileSearch = styled(MenuLink)`
  margin-right: 0;
  padding-right: 0;
`;

const SubMenu = styled.div`
  && {
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.09);
    padding: 6px;
    top: 60px !important;
    left: -126px !important;
  }
`;

const SubMenuItem = styled.div`
  font-weight: 400;
  font-size: 14px;
  padding: 6px 12px;
  display: block;

  :hover {
    cursor: pointer;
    background-color: #f5f6f7;
  }
`;

const StyledTopBar = styled.header`
  && {
    height: 75px;
    box-shadow: none;
    position: relative;
    border-bottom: 1px solid rgba(77, 82, 89, 0.07);
    padding: 0 10%;
    z-index: 1001;
    @media (max-width: 576px) {
      padding: 0 7%;
    }
    -webkit-app-region: drag;
  }
`;

const TopBarRight = styled.div`
  min-width: 60px;
`;

const BeamerWrapper = styled.div`
  &:focus {
    outline: none;
  }
`;

const Logo = styled.img.attrs(({ theme }) => ({
  src: theme.logo.dark,
  alt: 'loco-icon',
}))`
  height: ${({ theme }) => theme.logo.topBarHeight};
`;

const LoginNavItems = () => {
  const { pathname } = window.location;
  const redirectQueryString = queryString.stringify({
    redirectPath: pathname.replace('/public', '/client'),
  });
  const loginLink = `/login?${redirectQueryString}`;

  return AuthUtility.isAuthenticated() ? (
    userIsClient() && (
      <MenuLink href="/logout" className="mr-0">
        <span className="title">Log Out</span>
      </MenuLink>
    )
  ) : (
    <MenuLink href={loginLink} className="mr-0">
      <span className="title">Log In</span>
    </MenuLink>
  );
};

const hideMobileNavBar = () => {
  document.body.classList.remove('topbar-menu-open');
};

const showSearch = () => {
  window.CommandBar.open();
};

const useClickOutside = (ref) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        hideMobileNavBar();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};

const beamerId = isE2ETest() ? null : process.env.REACT_APP_BEAMER_ID;

const ClientNavItems = ({ activeStyle, customNavItem, enableSearch }) => {
  const salesforceConnectionEnabled = useFeatureToggle(
    flagNames.SALESFORCE_CONNECTION,
  );
  const isClientTemplatesTabEnabled = useFeatureToggle(
    flagNames.CLIENT_TEMPLATES_TAB,
  );
  const isClientProfileEnabled = salesforceConnectionEnabled;
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef);

  const { canAccessInformationTab, canAccessFilesRepository } =
    useOrganizationPermission();

  const [{ showEntityInformation, showClientCustomFields }] = useFirmSettings();
  const showInformationTab =
    canAccessInformationTab &&
    (showEntityInformation || showClientCustomFields);

  return (
    <nav className="topbar-navigation" ref={wrapperRef}>
      <TabMenu>
        <Tab onClick={hideMobileNavBar}>
          <MenuLink as={NavLink} to="/client/home" activeStyle={activeStyle}>
            Dashboard
          </MenuLink>
        </Tab>
        <Tab onClick={hideMobileNavBar}>
          <MenuLink
            as={NavLink}
            to="/client/matters"
            activeStyle={activeStyle}
            isActive={(match, location) => {
              const matterMatch =
                match ||
                matchPath(location.pathname, {
                  path: `/client/matter`,
                });
              return matterMatch;
            }}
          >
            Matters
          </MenuLink>
        </Tab>
        <Tab onClick={hideMobileNavBar}>
          <MenuLink
            as={NavLink}
            to="/client/contacts"
            activeStyle={activeStyle}
          >
            Contacts
          </MenuLink>
        </Tab>
        {!!isClientTemplatesTabEnabled && (
          <Tab onClick={hideMobileNavBar}>
            <MenuLink
              as={NavLink}
              to="/client/templates"
              activeStyle={activeStyle}
            >
              Templates
            </MenuLink>
          </Tab>
        )}
        {!isE2ETest() && !!canAccessFilesRepository && (
          <Tab onClick={hideMobileNavBar}>
            <MenuLink as={NavLink} to="/client/files" activeStyle={activeStyle}>
              Files
            </MenuLink>
          </Tab>
        )}
        {showInformationTab && (
          <Tab onClick={hideMobileNavBar}>
            <MenuLink
              as={NavLink}
              to="/client/entity"
              activeStyle={activeStyle}
            >
              Information
            </MenuLink>
          </Tab>
        )}
        {customNavItem && (
          <Tab onClick={hideMobileNavBar}>
            <MenuLink
              href={customNavItem.href}
              target="__blank"
              activeStyle={activeStyle}
            >
              {customNavItem.label}
            </MenuLink>
          </Tab>
        )}
        {enableSearch && (
          <span className="hidden-expand-down">
            <Tab onClick={showSearch}>
              <MenuLink>
                <FontAwesomeIcon icon={faSearch} size="lg" />
              </MenuLink>
            </Tab>
          </span>
        )}
        <span className="hidden-expand-down">
          <Tab>
            <div className="dropdown">
              <MenuLink
                data-toggle="dropdown"
                data-testid="profile-icon"
                lastItem
              >
                <FontAwesomeIcon icon={faUser} size="lg" />
              </MenuLink>
              <SubMenu className="dropdown-menu dropdown-menu-right">
                {!!isClientProfileEnabled && (
                  <SubMenuItem
                    as={NavLink}
                    role="menuitem"
                    to="/client/profile"
                  >
                    Profile Settings
                  </SubMenuItem>
                )}
                <SubMenuItem as={NavLink} role="menuitem" to="/client/settings">
                  Team Settings
                </SubMenuItem>
                <SubMenuItem as={NavLink} role="menuitem" to="/logout">
                  Log Out
                </SubMenuItem>
              </SubMenu>
            </div>
          </Tab>
        </span>
        <span className="hidden-expand-up">
          {!!isClientProfileEnabled && (
            <Tab onClick={hideMobileNavBar}>
              <MenuLink
                as={NavLink}
                to="/client/profile"
                activeStyle={activeStyle}
              >
                Profile Settings
              </MenuLink>
            </Tab>
          )}
          <Tab onClick={hideMobileNavBar}>
            <MenuLink
              as={NavLink}
              to="/client/settings"
              activeStyle={activeStyle}
            >
              Team Settings
            </MenuLink>
          </Tab>
          <Tab>
            <LoginNavItems />
          </Tab>
        </span>
      </TabMenu>
    </nav>
  );
};

const FirmNavItems = ({ activeStyle, enableSearch }) => (
  <nav className="topbar-navigation">
    <TabMenu>
      <Tab onClick={hideMobileNavBar}>
        <MenuLink as={NavLink} to="/new-contract" activeStyle={activeStyle}>
          New Matter
        </MenuLink>
      </Tab>
      <Tab onClick={hideMobileNavBar}>
        <MenuLink as={NavLink} to="/clients" activeStyle={activeStyle}>
          Clients
        </MenuLink>
      </Tab>
      <Tab onClick={hideMobileNavBar}>
        <MenuLink as={NavLink} to="/templates" activeStyle={activeStyle}>
          Templates
        </MenuLink>
      </Tab>
      <Tab onClick={hideMobileNavBar}>
        <MenuLink as={NavLink} to="/contracts" activeStyle={activeStyle}>
          Team Matters
        </MenuLink>
      </Tab>
      <Tab onClick={hideMobileNavBar}>
        <MenuLink
          href="https://newaccount1607346047303.freshdesk.com/support/solutions"
          target="_blank"
          activeStyle={activeStyle}
          onClick={trackKnowledgeBase}
        >
          Knowledge Base
        </MenuLink>
      </Tab>
      <Tab>
        <LoginNavItems />
      </Tab>
      {beamerId && (
        <Tab>
          <MenuLink>
            <BeamerWrapper
              id="beamerTrigger"
              tabIndex="0"
              role="button"
              onClick={trackOpenBeamer}
            >
              <FontAwesomeIcon icon={faGift} size="lg" />
            </BeamerWrapper>
          </MenuLink>
        </Tab>
      )}
      {enableSearch && (
        <span className="hidden-expand-down">
          <Tab onClick={showSearch}>
            <MenuLink>
              <FontAwesomeIcon icon={faSearch} size="lg" />
            </MenuLink>
          </Tab>
        </span>
      )}
      <Tab>
        <div className="dropdown">
          <MenuLink data-toggle="dropdown" data-testid="profile-icon" lastItem>
            <FontAwesomeIcon icon={faUser} size="lg" />
          </MenuLink>
          <SubMenu className="dropdown-menu dropdown-menu-right">
            {userIsFirmAdmin() && (
              <SubMenuItem as={NavLink} role="menuitem" to="/settings">
                Team Settings
              </SubMenuItem>
            )}
            <SubMenuItem as={NavLink} role="menuitem" to="/logout">
              Log Out
            </SubMenuItem>
          </SubMenu>
        </div>
      </Tab>
    </TabMenu>
  </nav>
);

const NavItems = ({ enableSearch }) => {
  const theme = useContext(ThemeContext);
  const { customNavItem } = theme;
  const activeStyle = {
    borderTop: `3px solid ${theme.palette.primary.main}`,
  };

  return userIsClient() ? (
    <ClientNavItems
      activeStyle={activeStyle}
      customNavItem={customNavItem}
      enableSearch={enableSearch}
    />
  ) : (
    <FirmNavItems activeStyle={activeStyle} enableSearch={enableSearch} />
  );
};

const TopBar = ({ className, theme, ...props }) => {
  const enableSearch = !isE2ETest();

  const homeLink = userIsClient() ? '/client/home' : '/';
  return (
    <StyledTopBar className={`topbar topbar-expand-lg ${className}`} {...props}>
      {AuthUtility.isAuthenticated() && (
        <span className="topbar-btn topbar-menu-toggler pl-0 text-left">
          <i>☰</i>
        </span>
      )}
      <NavLink to={homeLink}>
        <Logo theme={theme} />
      </NavLink>
      <TopBarRight className="topbar-right">
        <ul className="topbar-btns">
          <Tab>{!AuthUtility.isAuthenticated() && <LoginNavItems />}</Tab>
          {AuthUtility.isAuthenticated() && (
            <NavItems enableSearch={enableSearch} />
          )}
        </ul>
        {enableSearch && (
          <span className="hidden-expand-up">
            <MobileSearch onClick={showSearch}>
              <FontAwesomeIcon icon={faSearch} size="lg" />
            </MobileSearch>
          </span>
        )}
      </TopBarRight>
    </StyledTopBar>
  );
};

export default withTheme(TopBar);
