import { Userpilot } from 'userpilot';

import { isE2ETest } from './Utilities';

const userpilotToken = process.env.REACT_APP_USERPILOT_TOKEN;

export const initializeUserpilot = () => {
  if (isE2ETest()) {
    return;
  }

  Userpilot.initialize(userpilotToken);
};

export const identifyUserpilot = (userId, parameters) => {
  if (isE2ETest()) {
    return;
  }

  Userpilot.identify(userId, parameters);
};

export const reloadUserpilot = (url) => {
  if (isE2ETest()) {
    return;
  }

  Userpilot.reload(url);
};
