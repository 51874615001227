import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components/macro';

export const ActionIcon = styled(FontAwesomeIcon)`
  margin-right: 6px;
`;

const ActionBlock = styled.div`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Action = styled(({ children, ...props }) => (
  <div {...props}>
    <ActionBlock>{children}</ActionBlock>
  </div>
))`
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 4px 0;
  height: 48px !important;
  font-size: 14px;
  color: ${(props) => props.theme.palette.gray.main};
  cursor: pointer;

  :hover {
    color: ${(props) =>
      props.palette === 'destructive'
        ? props.theme.palette.destructive.main
        : props.theme.palette.primary.main};
  }
`;

export default Action;
