import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import styled from 'styled-components/macro';

import { FloatingTextField } from '../../forms/FormStyles';
import ServiceList from './ServiceList';

const Overlay = styled.div`
  position: absolute;
  background-color: transparent;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
`;

const CategoryEditorContainer = styled.div`
  margin: -24px -24px 24px -24px;
  padding: 24px;
  border-radius: 10px;
  background-color: white;
  border: 2px solid transparent;
  pointer-events: auto !important;

  &&&:hover {
    cursor: grab;
    border: 2px dotted ${(props) => props.theme.palette.gray.light};
  }

  &&&.isDragging {
    cursor: grabbing;
    box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.5);
    max-height: 25em;
    overflow: hidden;
  }

  &&&.isDragging > div.category-editor-overlay {
    height: 110%;
    background: linear-gradient(to bottom, transparent 50%, white);
  }
`;

const CategoryEditorTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CategoryNameTextField = styled(FloatingTextField)`
  max-width: 30em;
`;

const TrashIcon = styled.button`
  margin-right: -5px;
`;

const CategoryEditor = ({
  category,
  errors = {},
  onUpdate = (f) => f,
  onDelete = (f) => f,
  onAddServiceClick = (f) => f,
}) => {
  const { categoryId, name } = category;

  const onSortEnd = ({ newIndex, oldIndex }) => {
    const newTemplates = [...category.templates];
    const newTemplateVersions = [...category.templateVersions];

    const [templateToMove] = newTemplates.splice(oldIndex, 1);
    const [templateVersionToMove] = newTemplateVersions.splice(oldIndex, 1);

    newTemplates.splice(newIndex, 0, templateToMove);
    newTemplateVersions.splice(newIndex, 0, templateVersionToMove);

    onUpdate({
      ...category,
      templates: newTemplates,
      templateVersions: newTemplateVersions,
    });
  };

  return (
    <CategoryEditorContainer>
      <Overlay className="category-editor-overlay" />
      <CategoryEditorTitleContainer>
        <CategoryNameTextField
          id={`category-name-${categoryId}`}
          label="Category Name"
          className="mb-12"
          value={name}
          onChange={(event) => {
            onUpdate({ ...category, name: event.target.value });
          }}
          onBlur={(event) => {
            onUpdate({ ...category, name: event.target.value.trim() });
          }}
          placeholder="Please give this category a name"
        />
        <TrashIcon
          data-testid="category-name"
          type="button"
          onClick={onDelete}
          className="btn btn-pure btn-danger"
        >
          <FontAwesomeIcon icon={faTrashAlt} size="lg" />
        </TrashIcon>
      </CategoryEditorTitleContainer>
      <ServiceList
        category={category}
        errors={errors}
        onUpdate={onUpdate}
        onAddServiceClick={onAddServiceClick}
        axis="xy"
        onSortEnd={onSortEnd}
        helperClass="isDragging"
        useWindowAsScrollContainer
      />
    </CategoryEditorContainer>
  );
};

export default SortableElement(CategoryEditor);
