/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */
import './DataTableStyle.css';

import React from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components/macro';

import Button from '../standard/Button';
import Dropdown from './Dropdown';
import { Input } from './FormStyles';

const Header = styled.th`
  && {
    font-size: 14px;
  }
`;

const DeleteHeader = styled.th`
  && {
    border-right-style: hidden;
    border-bottom-style: hidden;
    border-top-style: hidden;
    width: 36px;
    padding-left: 0;
    padding-right: 0;
  }
`;

const DeleteColumn = styled.td`
  && {
    border-right-style: hidden;
    border-bottom-style: hidden;
    border-top-style: hidden;
    width: 36px;
    padding-left: 0;
    padding-right: 0;
    vertical-align: inherit;
  }
`;

const Cell = styled.td`
  && {
    padding: 0px 15px 15px;
    min-width: 200px;
  }
`;

const StyledButton = styled(Button)`
  font-size: 18px;
  padding-left: 15px;
`;

class DataTable extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = {
      dataTableItems: data,
    };
  }

  componentWillUnmount() {
    if (this.onBlurTimerId) clearTimeout(this.onBlurTimerId);
  }

  handleFocus = () => {
    clearTimeout(this.onBlurTimerId);
  };

  // We don't want to trigger "OnBlur" validation the millisecond one subfield is blurred,
  // as the user might just be clicking on the next subfield.
  handleSubfieldBlur = () => {
    this.onBlurTimerId = setTimeout(this.delayedOnBlur, 500);
  };

  delayedOnBlur = () => {
    const { name, onBlur } = this.props;
    const { dataTableItems } = this.state;
    const compoundEvent = {
      target: {
        name,
        value: dataTableItems,
        type: 'DataTable',
      },
    };
    onBlur(compoundEvent);
  };

  handleRowDel = (dataTableItem) => {
    const { name, onChange } = this.props;
    const { dataTableItems } = this.state;
    const index = dataTableItems.indexOf(dataTableItem);
    dataTableItems.splice(index, 1);
    this.setState(dataTableItems);
    const e = {
      target: {
        name,
        value: dataTableItems,
      },
    };
    onChange(e);
  };

  handleDataTable = (evt) => {
    const { name, onChange } = this.props;
    const { dataTableItems } = this.state;
    const item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value,
    };
    const newItems = dataTableItems.map((dataTableItem) => {
      const newItem = { ...dataTableItem };
      Object.keys(dataTableItem).forEach((key) => {
        if (key === item.name && dataTableItem.id === item.id) {
          newItem[key] = item.value;
        }
      });
      return newItem;
    });
    this.setState({ dataTableItems: newItems });
    const e = {
      target: {
        name,
        value: newItems,
      },
    };
    onChange(e);
  };

  handleAddEvent = () => {
    const { columns } = this.props;
    const { dataTableItems } = this.state;
    const id = (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
    const dataTableItem = {
      id,
    };
    Object.keys(columns).forEach((key) => {
      dataTableItem[key] = '';
    });
    const itemArray = dataTableItems;
    itemArray.push(dataTableItem);
    this.setState({
      dataTableItems: itemArray,
    });
  };

  generateTableHeaders() {
    const { columns } = this.props;
    const tableHeaderNames = [];
    Object.keys(columns).forEach((key) => {
      tableHeaderNames.push(columns[key].name);
    });
    const tableHeaders = (
      <tr>
        {tableHeaderNames.map((item) => (
          <Header>{item}</Header>
        ))}
        <DeleteHeader />
      </tr>
    );
    return tableHeaders;
  }

  render() {
    const { columns, noun, errors } = this.props;
    const { dataTableItems } = this.state;
    const rowNoun = noun || 'Row';

    if (dataTableItems.length === 0) {
      this.handleAddEvent();
    }

    let hideDelButton = true;
    if (dataTableItems.length === 1) {
      const dataArray = Object.values(dataTableItems[0]);
      for (let i = 1; i < dataArray.length; i++) {
        if (dataArray[i] !== '') {
          hideDelButton = false;
          break;
        }
      }
    }
    const dataTableItemRows = dataTableItems.map((dataTableItem) => (
      <TableRow
        onDataTableUpdate={this.handleDataTable}
        dataTableItem={dataTableItem}
        columns={columns}
        onDelEvent={this.handleRowDel}
        onBlur={this.handleSubfieldBlur}
        onFocus={this.handleFocus}
        key={dataTableItem.id}
        hideDelButton={hideDelButton}
        errors={errors}
      />
    ));

    const addButton = (
      <StyledButton onClick={this.handleAddEvent} pure>
        <i className="pe-7s-plus pe-lg mr-1" />
        {`Add ${rowNoun}`}
      </StyledButton>
    );

    return (
      <React.Fragment>
        <table
          style={{ width: '100%', tableLayout: 'fixed', marginTop: '15px' }}
          className="table-bordered table"
        >
          <thead>{this.generateTableHeaders()}</thead>
          <tbody>{dataTableItemRows}</tbody>
        </table>
        <div>{addButton}</div>
      </React.Fragment>
    );
  }
}

class TableRow extends React.Component {
  onDelEvent() {
    const { dataTableItem, onDelEvent } = this.props;
    onDelEvent(dataTableItem);
  }

  // eslint-disable-next-line class-methods-use-this
  generateEditableProp = (column) => {
    if (!column.type) {
      return {};
    }
    if (column.type === 'Boolean') {
      return { type: 'checkbox' };
    }
    if (column.type === 'Number') {
      return { type: 'number' };
    }
    if (column.type && column.type.type === 'Enum') {
      return { type: 'select', options: { values: column.type.set } };
    }
    return {};
  };

  render() {
    const {
      columns,
      dataTableItem,
      errors,
      hideDelButton,
      onBlur,
      onDataTableUpdate,
      onFocus,
    } = this.props;
    const cells = Object.keys(columns).map((cell) => {
      let editableProp = '';
      if (columns[cell].type) {
        editableProp = this.generateEditableProp(columns[cell]);
      }
      return (
        <EditableCell
          onDataTableUpdate={onDataTableUpdate}
          onBlur={onBlur}
          onFocus={onFocus}
          errors={errors}
          cellData={{
            name: cell,
            value: dataTableItem[cell],
            id: dataTableItem.id,
            editableProp,
          }}
        />
      );
    });
    return (
      <tr className="eachRow">
        {cells}
        {!hideDelButton && (
          <DeleteColumn>
            <button
              type="button"
              onClick={this.onDelEvent.bind(this)}
              className="btn btn-pure btn-danger"
            >
              <i className="pe-7s-trash pe-2x" />
            </button>
          </DeleteColumn>
        )}
      </tr>
    );
  }
}

const EditableCell = ({
  cellData,
  errors,
  onBlur,
  onDataTableUpdate,
  onFocus,
}) => {
  if (cellData.editableProp.type === 'select') {
    const options = cellData.editableProp.options.values;
    return (
      <Cell>
        <Dropdown
          name={cellData.name}
          options={options}
          value={cellData.value}
          onChange={onDataTableUpdate}
          onBlur={onBlur}
          onFocus={onFocus}
          errors={errors}
          id={cellData.id}
        />
      </Cell>
    );
  }
  if (cellData.editableProp.type === 'number') {
    return (
      <Cell>
        <Input
          as={NumberFormat}
          thousandSeparator
          value={cellData.value}
          onBlur={onBlur}
          errors={errors}
          onFocus={onFocus}
          autoComplete="no"
          onValueChange={(values) => {
            const { value } = values;
            const event = {
              target: {
                name: cellData.name,
                value,
                id: cellData.id,
              },
            };
            onDataTableUpdate(event);
          }}
        />
      </Cell>
    );
  }
  return (
    <Cell>
      <Input
        type={cellData.editableProp.type || 'text'}
        name={cellData.name}
        id={cellData.id}
        value={cellData.value}
        onChange={onDataTableUpdate}
        onBlur={onBlur}
        errors={errors}
        onFocus={onFocus}
        autoComplete="no"
      />
    </Cell>
  );
};

export default DataTable;
