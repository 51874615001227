import { css } from 'styled-components/macro';

import { SCREEN_SIZES } from './LayoutConstants';

export const TypographyStyles = {
  FontFamily: css`
    font-family: ${({ theme }) =>
      theme.primaryFont?.family || 'Helvetica, sans-serif'} !important;
  `,
  Color: css`
    color: ${({ theme }) => theme.palette.shade.gray_1};
  `,
  P: css`
    font-weight: 300;
    font-size: 13px;
    line-height: 24px;
  `,
  H1: css`
    font-weight: 300;
    font-size: 33px;
    line-height: 1.5;
    letter-spacing: 0.5px;
    @media ${SCREEN_SIZES.MEDIUM} {
      font-size: 31px;
    }
    @media ${SCREEN_SIZES.SMALL} {
      font-size: 24px;
    }
  `,
  H2: css`
    font-weight: 300;
    font-size: 28px;
    line-height: 1.5;
    letter-spacing: 0.5px;
    @media ${SCREEN_SIZES.MEDIUM} {
      font-size: 26px;
    }
    @media ${SCREEN_SIZES.SMALL} {
      font-size: 22px;
    }
  `,
  H3: css`
    font-weight: 300;
    font-size: 23px;
    line-height: 1.5;
    letter-spacing: 0.5px;
    @media ${SCREEN_SIZES.MEDIUM} {
      font-size: 22px;
    }
    @media ${SCREEN_SIZES.SMALL} {
      font-size: 20px;
    }
  `,
  H4: css`
    font-weight: 300;
    font-size: 19px;
    line-height: 1.5;
    letter-spacing: 0.5px;
    @media ${SCREEN_SIZES.MEDIUM} {
      font-size: 18px;
    }
    @media ${SCREEN_SIZES.SMALL} {
      font-size: 16px;
    }
  `,
  H5: css`
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.5px;
    @media ${SCREEN_SIZES.MEDIUM} {
      font-size: 15px;
    }
  `,
  H6: css`
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.5px;
    @media ${SCREEN_SIZES.MEDIUM} {
      font-size: 13px;
    }
  `,
};
