import { gql, useMutation, useQuery } from '@apollo/client';
import { useFeatureToggle } from '@flopflip/react-broadcast';
import React, { useCallback, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ThemeContext } from 'styled-components/macro';
import { handleException } from 'utils/ErrorUtils';

import { flagNames } from '../../../featureFlags';
import { trackMatterReviewRequest } from '../../../services/Analytics';
import { userIsClient } from '../../../services/Utilities';
import Spinner from '../../standard/Spinner';
import MatterView from './MatterView';
import MatterViewScreenPaywall from './MatterViewScreenPaywall';

const MATTER_FRAGMENT = gql`
  fragment MatterView on Matter {
    _id
    name
    owner
    canRead
    sharedWithClient
    organization {
      _id
      name
    }
    state
    categories {
      _id
      name
      archived
    }
    contacts {
      _contactId
      name
    }
    legacyQuestionnaireState
    signatureRequests {
      id
      requesterEmailAddress
      createdAt
      detailsUrl
      title
      status
      signatures {
        id
        name
        email
        status
        signedAt
        lastViewedAt
        lastRemindedAt
      }
    }
    reviewRequests {
      _id
      reviewer {
        id
        email
      }
      state
      description
    }
  }
`;

const GET_MATTER_QUERY = gql`
  query fetchMatter($matterId: MongoID!) {
    matter(matterId: $matterId) {
      ...MatterView
      workspaceFolder {
        folderId: boxFolderId
        accessToken: token
      }
      matterFieldValues {
        _id
        matterField {
          _id
          name
          type
        }
        value
      }
    }
  }
  ${MATTER_FRAGMENT}
`;

const GRANT_CLIENT_MATTER_ACCESS = gql`
  mutation grantClientMatterAccess($input: GrantClientMatterAccessInput!) {
    grantClientMatterAccess(input: $input) {
      record {
        ...MatterView
      }
    }
  }
  ${MATTER_FRAGMENT}
`;

const REVOKE_CLIENT_MATTER_ACCESS = gql`
  mutation revokeClientMatterAccess($matterId: MongoID!) {
    revokeClientMatterAccess(input: { matterId: $matterId }) {
      record {
        ...MatterView
      }
    }
  }
  ${MATTER_FRAGMENT}
`;

const UPDATE_MATTER_NAME = gql`
  mutation updateMatterName($matterId: MongoID!, $name: String!) {
    updateMatter(input: { matterId: $matterId, name: $name }) {
      record {
        ...MatterView
      }
    }
  }
  ${MATTER_FRAGMENT}
`;

const OPEN_MATTER = gql`
  mutation openMatter($matterId: MongoID!) {
    openMatter(input: { matterId: $matterId }) {
      record {
        ...MatterView
      }
    }
  }
  ${MATTER_FRAGMENT}
`;

const CLOSE_MATTER = gql`
  mutation closeMatter($matterId: MongoID!) {
    closeMatter(input: { matterId: $matterId }) {
      record {
        ...MatterView
      }
    }
  }
  ${MATTER_FRAGMENT}
`;

const ARCHIVE_MATTER = gql`
  mutation archiveMatter($matterId: MongoID!) {
    archiveMatter(input: { matterId: $matterId }) {
      record {
        ...MatterView
      }
    }
  }
  ${MATTER_FRAGMENT}
`;

const REQUEST_MATTER_REVIEW = gql`
  mutation requestMatterReview($input: RequestMatterReviewInput!) {
    requestMatterReview(input: $input) {
      record {
        ...MatterView
      }
    }
  }
  ${MATTER_FRAGMENT}
`;

const GENERATE_DOWNLOAD_ALL_URL = gql`
  mutation generateMatterWorkspaceDownloadUrl(
    $input: GenerateZippedMatterWorkspaceInput!
  ) {
    generateZippedMatterWorkspace(input: $input) {
      downloadUrl
    }
  }
`;

const GENERATE_CLAIM_URL = gql`
  mutation generateClaimUrl($matterId: MongoID!, $fileIds: [String!]!) {
    generateClaimUrl(input: { matterId: $matterId, fileIds: $fileIds }) {
      claimUrl
    }
  }
`;

const SEND_FOR_SIGNING = gql`
  mutation sendForSigning($matterId: MongoID!, $signatureRequestId: String!) {
    sendForSigning(
      input: { matterId: $matterId, signatureRequestId: $signatureRequestId }
    ) {
      record {
        ...MatterView
      }
    }
  }
  ${MATTER_FRAGMENT}
`;

const REMOVE_MATTER_CATEGORY = gql`
  mutation removeMatterCategory($input: RemoveMatterCategoryInput!) {
    removeMatterCategory(input: $input) {
      record {
        _id
        categories {
          _id
          name
          archived
        }
      }
    }
  }
`;

const ADD_MATTER_CATEGORY = gql`
  mutation addMatterCategory($input: AddMatterCategoryInput!) {
    addMatterCategory(input: $input) {
      record {
        _id
        categories {
          _id
          name
          archived
        }
      }
    }
  }
`;

const REMOVE_MATTER_CONTACT = gql`
  mutation removeMatterContact($input: RemoveMatterContactInput!) {
    removeMatterContact(input: $input) {
      record {
        _id
        contacts {
          _contactId
          name
        }
      }
    }
  }
`;

const ADD_MATTER_CONTACT = gql`
  mutation addMatterContact($input: AddMatterContactInput!) {
    addMatterContact(input: $input) {
      record {
        _id
        contacts {
          _contactId
          name
        }
      }
    }
  }
`;

const CREATE_REVIEW_REQUEST = gql`
  mutation createMatterReviewRequest($input: CreateReviewRequestInput!) {
    createReviewRequest(input: $input) {
      records {
        _id
        reviewer {
          id
          email
        }
        state
        description
      }
    }
  }
`;

const WITHDRAW_REVIEW_REQUEST = gql`
  mutation withdrawMatterReviewRequest($reviewRequestId: MongoID!) {
    withdrawReviewRequest(reviewRequestId: $reviewRequestId) {
      recordId
      record {
        matter {
          _id
          reviewRequests {
            _id
            reviewer {
              id
              email
            }
            state
            description
          }
        }
      }
    }
  }
`;

const APPROVE_REVIEW_REQUEST = gql`
  mutation approveMatterReviewRequest($reviewRequestId: MongoID!) {
    approveReviewRequest(reviewRequestId: $reviewRequestId) {
      recordId
      record {
        matter {
          _id
          reviewRequests {
            _id
            reviewer {
              id
              email
            }
            state
            description
          }
        }
      }
    }
  }
`;

const REVOKE_REVIEW_REQUEST = gql`
  mutation revokeMatterReviewRequest($reviewRequestId: MongoID!) {
    revokeReviewRequest(reviewRequestId: $reviewRequestId) {
      recordId
      record {
        matter {
          _id
          reviewRequests {
            _id
            reviewer {
              id
              email
            }
            state
            description
          }
        }
      }
    }
  }
`;

const ADD_MATTER_FIELD_VALUES_TO_MATTER = gql`
  mutation addMatterFieldValuesToMatter(
    $input: addMatterFieldValuesToMatterInput!
  ) {
    addMatterFieldValuesToMatter(input: $input) {
      recordId
      record {
        _id
        matterFieldValues {
          _id
          matterField {
            _id
            name
            type
          }
          value
        }
      }
    }
  }
`;

const DELETE_MATTER_FIELD_VALUES_FROM_MATTER = gql`
  mutation deleteMatterFieldValuesFromMatter(
    $input: deleteMatterFieldValuesFromMatterInput!
  ) {
    deleteMatterFieldValuesFromMatter(input: $input) {
      recordId
      record {
        _id
        matterFieldValues {
          _id
          matterField {
            _id
            name
            type
          }
          value
        }
      }
    }
  }
`;

const UPDATE_MATTER_FIELD_VALUE_ON_MATTER = gql`
  mutation updateMatterFieldValuesOnMatter(
    $input: updateMatterFieldValuesOnMatterInput!
  ) {
    updateMatterFieldValuesOnMatter(input: $input) {
      recordId
      record {
        _id
        matterFieldValues {
          _id
          matterField {
            _id
            name
            type
          }
          value
        }
      }
    }
  }
`;

const CANCEL_SIGNATURE_REQUEST = gql`
  mutation cancelSignatureRequest($input: CancelRequestInput) {
    cancelRequest(input: $input) {
      record {
        id
      }
    }
  }
`;

function NewMatterViewScreen() {
  const { matterId } = useParams();
  const history = useHistory();
  const theme = useContext(ThemeContext);

  const [matter, setMatter] = useState(null);

  const accessMatterWorkspace = useFeatureToggle(
    flagNames.ACCESS_MATTER_WORKSPACE,
  );

  const { refetch: refetchMatter, loading: queryLoading } = useQuery(
    GET_MATTER_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: { matterId },
      onCompleted: (data) => {
        setMatter(data.matter);
      },
      onError: (error) => {
        toast.error(
          'Oops! Something went wrong loading this matter. Please try again later.',
        );
        handleException(error);

        if (userIsClient()) {
          history.replace(`/client/matters`);
        } else {
          history.replace(`/contracts`);
        }
      },
    },
  );

  const [grantClientMatterAccess, { loading: grantClientMatterAccessLoading }] =
    useMutation(GRANT_CLIENT_MATTER_ACCESS, {
      onError: (error) => handleException(error),
      variables: { input: { matterId, palette: theme.palette } },
    });

  const grantClientMatterAccessWithMessage = useCallback(
    (notify, message) => {
      grantClientMatterAccess({
        variables: {
          input: {
            matterId,
            palette: theme.palette,
            notifyClient: notify,
            notifyClientMessage: message,
          },
        },
      });
    },
    [grantClientMatterAccess, matterId, theme.palette],
  );

  const [
    revokeClientMatterAccess,
    { loading: revokeClientMatterAccessLoading },
  ] = useMutation(REVOKE_CLIENT_MATTER_ACCESS, {
    onError: (error) => handleException(error),
    variables: { matterId },
  });

  const [updateMatterName, { loading: updateMatterNameLoading }] = useMutation(
    UPDATE_MATTER_NAME,
    {
      onError: (error) => handleException(error),
      variables: { matterId },
    },
  );

  const updateMatterNameCallback = useCallback(
    async (name) => {
      updateMatterName({ variables: { matterId, name } });
    },
    [matterId, updateMatterName],
  );

  const [openMatter, { loading: openMatterLoading }] = useMutation(
    OPEN_MATTER,
    {
      onError: (error) => handleException(error),
      variables: { matterId },
    },
  );

  const [closeMatter, { loading: closeMatterLoading }] = useMutation(
    CLOSE_MATTER,
    {
      onError: (error) => handleException(error),
      variables: { matterId },
    },
  );

  const toggleMatterState = useCallback(() => {
    if (matter?.state === 'open') {
      closeMatter();
    } else {
      openMatter();
    }
  }, [matter?.state, closeMatter, openMatter]);

  const [archiveMatter, { loading: archiveMatterLoading }] = useMutation(
    ARCHIVE_MATTER,
    {
      onError: (error) => handleException(error),
      variables: { matterId },
    },
  );

  const [requestMatterReview, { loading: requestMatterReviewLoading }] =
    useMutation(REQUEST_MATTER_REVIEW, {
      onError: (error) => handleException(error),
    });

  const requestMatterReviewWithMessage = useCallback(
    (message) => {
      requestMatterReview({
        variables: {
          input: {
            matterId,
            palette: theme.palette,
            message,
          },
        },
      });
    },
    [requestMatterReview, matterId, theme.palette],
  );

  const [generateDownloadAllUrl, { loading: generateDownloadAllUrlLoading }] =
    useMutation(GENERATE_DOWNLOAD_ALL_URL, {
      onError: (error) => handleException(error),
    });

  const fetchDownloadAllUrl = useCallback(async () => {
    const { data } = await generateDownloadAllUrl({
      variables: {
        input: {
          matterId,
        },
      },
    });
    return data.generateZippedMatterWorkspace.downloadUrl;
  }, [generateDownloadAllUrl, matterId]);

  const [generateClaimUrl, { loading: generateClaimUrlLoading }] = useMutation(
    GENERATE_CLAIM_URL,
    {
      onError: (error) => {
        throw error;
      },
      variables: { matterId },
    },
  );

  const generateClaimUrlCallback = useCallback(
    async ({ fileIds }) => {
      const response = await generateClaimUrl({
        variables: { fileIds },
      });

      return response?.data?.generateClaimUrl?.claimUrl;
    },
    [generateClaimUrl],
  );

  const [sendForSigning, { loading: sendForSigningLoading }] = useMutation(
    SEND_FOR_SIGNING,
    {
      onError: (error) => {
        handleException(error);
        toast.error(
          'Something went wrong sending your signature request. Please try again later.',
        );
      },
      variables: { matterId },
    },
  );

  const sendForSigningCallback = useCallback(
    async ({ signatureRequestId }) => {
      await sendForSigning({ variables: { signatureRequestId } });
      toast.success('Your signature request has been sent!');
    },
    [sendForSigning],
  );

  const [removeMatterCategory, { loading: removeMatterCategoryLoading }] =
    useMutation(REMOVE_MATTER_CATEGORY);

  const removeMatterCategoryCallback = useCallback(
    async (category) => {
      try {
        await removeMatterCategory({
          variables: {
            input: { matterId: matter._id, categoryId: category._id },
          },
        });
      } catch (error) {
        handleException(error);
        console.error(error);
        toast.error(
          'Oops! Something went wrong removing this category. Please try again later.',
        );
      }
    },
    [removeMatterCategory, matter?._id],
  );

  const [addMatterCategory, { loading: addMatterCategoryLoading }] =
    useMutation(ADD_MATTER_CATEGORY);

  const addMatterCategoryCallback = useCallback(
    async (category) => {
      try {
        await addMatterCategory({
          variables: {
            input: { matterId: matter._id, categoryId: category._id },
          },
        });
      } catch (error) {
        handleException(error);
        console.error(error);
        toast.error(
          'Oops! Something went wrong adding this category. Please try again later.',
        );
      }
    },
    [addMatterCategory, matter?._id],
  );

  const [removeMatterContact, { loading: removeMatterContactLoading }] =
    useMutation(REMOVE_MATTER_CONTACT);

  const removeMatterContactCallback = useCallback(
    async (contact) => {
      try {
        await removeMatterContact({
          variables: {
            input: { matterId: matter._id, contactId: contact._contactId },
          },
        });
      } catch (error) {
        handleException(error);
        console.error(error);
        toast.error(
          'Oops! Something went wrong removing this contact. Please try again later.',
        );
      }
    },
    [removeMatterContact, matter?._id],
  );

  const [addMatterContact, { loading: addMatterContactLoading }] =
    useMutation(ADD_MATTER_CONTACT);

  const addMatterContactCallback = useCallback(
    async (contact) => {
      try {
        await addMatterContact({
          variables: {
            input: { matterId: matter._id, contactId: contact._contactId },
          },
        });
      } catch (error) {
        handleException(error);
        console.error(error);
        toast.error(
          'Oops! Something went wrong adding this contact. Please try again later.',
        );
      }
    },
    [addMatterContact, matter?._id],
  );

  const [addReviewers, { loading: addReviewersLoading }] = useMutation(
    CREATE_REVIEW_REQUEST,
  );

  const addReviewersCallback = useCallback(
    async ({ reviewers, description }) => {
      try {
        const { data } = await addReviewers({
          variables: {
            input: {
              organizationId: matter.organization?._id,
              reviewers: reviewers.map(({ id }) => id),
              description,
              matterId,
            },
          },
        });
        setMatter({
          ...matter,
          reviewRequests: [
            ...matter.reviewRequests,
            ...data.createReviewRequest.records,
          ],
        });
        data.createReviewRequest.records.forEach((reviewRequest) =>
          trackMatterReviewRequest({
            matterId: matter._id,
            reviewRequestId: reviewRequest._id,
            action: 'create',
          }),
        );
        toast.success('Request sent successfully');
      } catch (error) {
        handleException(error);
        console.error(error);
        toast.error(
          'Oops! Something went wrong requesting this review. Please try again later.',
        );
      }
    },
    [addReviewers, matter, matterId],
  );

  const [withdrawReviewRequest, { loading: withdrawReviewRequestLoading }] =
    useMutation(WITHDRAW_REVIEW_REQUEST);

  const withdrawReviewRequestCallback = useCallback(
    async (reviewRequestId) => {
      try {
        const { data } = await withdrawReviewRequest({
          variables: {
            reviewRequestId,
          },
        });
        setMatter({
          ...matter,
          ...data.withdrawReviewRequest.record.matter,
        });
        trackMatterReviewRequest({
          matterId: matter._id,
          reviewRequestId,
          action: 'withdraw',
        });
      } catch (error) {
        handleException(error);
        console.error(error);
        toast.error(
          'Oops! Something went wrong withdrawing this review. Please try again later.',
        );
      }
    },
    [withdrawReviewRequest, matter],
  );

  const [approveReviewRequest, { loading: approveReviewRequestLoading }] =
    useMutation(APPROVE_REVIEW_REQUEST);

  const approveReviewRequestCallback = useCallback(
    async (reviewRequestId) => {
      try {
        const { data } = await approveReviewRequest({
          variables: {
            reviewRequestId,
          },
        });
        setMatter({
          ...matter,
          ...data.approveReviewRequest.record.matter,
        });
        trackMatterReviewRequest({
          matterId: matter._id,
          reviewRequestId,
          action: 'approve',
        });
      } catch (error) {
        handleException(error);
        console.error(error);
        toast.error(
          'Oops! Something went wrong approving this review. Please try again later.',
        );
      }
    },
    [approveReviewRequest, matter],
  );

  const [revokeReviewRequest, { loading: revokeReviewRequestLoading }] =
    useMutation(REVOKE_REVIEW_REQUEST);

  const revokeReviewRequestCallback = useCallback(
    async (reviewRequestId) => {
      try {
        const { data } = await revokeReviewRequest({
          variables: {
            reviewRequestId,
          },
        });
        setMatter({
          ...matter,
          ...data.revokeReviewRequest.record.matter,
        });
        trackMatterReviewRequest({
          matterId: matter._id,
          reviewRequestId,
          action: 'revoke',
        });
      } catch (error) {
        handleException(error);
        console.error(error);
        toast.error(
          'Oops! Something went wrong undoing this approval. Please try again later.',
        );
      }
    },
    [revokeReviewRequest, matter],
  );

  const [
    addMatterFieldValuesToMatter,
    { loading: addMatterFieldValuesToMatterLoading },
  ] = useMutation(ADD_MATTER_FIELD_VALUES_TO_MATTER);

  const addMatterFieldValuesToMatterCallback = useCallback(
    async ({ matterFieldValues }) => {
      try {
        await addMatterFieldValuesToMatter({
          variables: {
            input: {
              matterId,
              matterFieldValues,
            },
          },
        });
      } catch (error) {
        handleException(error);
        toast.error(
          'Oops! Something went wrong adding a matter field. Please try again later.',
        );
      }
    },
    [addMatterFieldValuesToMatter, matterId],
  );

  const [
    deleteMatterFieldValuesFromMatter,
    { loading: deleteMatterFieldValuesFromMatterLoading },
  ] = useMutation(DELETE_MATTER_FIELD_VALUES_FROM_MATTER, {
    onCompleted: () => {
      toast.success('Matter Field deleted successfully');
    },
  });

  const deleteMatterFieldValuesFromMatterCallback = useCallback(
    async ({ matterFieldId }) => {
      try {
        await deleteMatterFieldValuesFromMatter({
          variables: {
            input: {
              matterId,
              matterFieldIds: [matterFieldId],
            },
          },
        });
      } catch (error) {
        handleException(error);
        toast.error(
          'Oops! Something went wrong removing a matter field. Please try again later.',
        );
      }
    },
    [deleteMatterFieldValuesFromMatter, matterId],
  );

  const [
    updateMatterFieldValuesOnMatter,
    { loading: updateMatterFieldValuesOnMatterLoading },
  ] = useMutation(UPDATE_MATTER_FIELD_VALUE_ON_MATTER, {
    onCompleted: () => {
      toast.success('Matter Field updated successfully');
    },
  });

  const updateMatterFieldValuesOnMatterCallback = useCallback(
    async ({ matterFieldId, value }) => {
      try {
        await updateMatterFieldValuesOnMatter({
          variables: {
            input: {
              matterId,
              matterFieldValues: {
                matterFieldId,
                value,
              },
            },
          },
        });
      } catch (error) {
        handleException(error);
        toast.error(
          'Oops! Something went wrong updating a matter field. Please try again later.',
        );
      }
    },
    [updateMatterFieldValuesOnMatter, matterId],
  );

  const [cancelRequest] = useMutation(CANCEL_SIGNATURE_REQUEST, {
    onCompleted: async (data) => {
      const {
        cancelRequest: {
          record: { id: signatureRequestId },
        },
      } = data;

      await refetchMatter();
      toast.info('Signature request cancelled', signatureRequestId);
    },
  });

  const cancelSignatureRequestCallback = useCallback(
    async (signatureRequestId) => {
      try {
        await cancelRequest({
          variables: {
            input: {
              signatureRequestId,
              matterId,
              matterVersion: 2,
            },
          },
        });
      } catch (error) {
        handleException(error);
        toast.error(
          'Oops! Something went wrong cancelling the signature request. Please try again later',
        );
      }
    },
    [cancelRequest, matterId],
  );

  if (!matter) {
    return <Spinner />;
  }

  if (!accessMatterWorkspace) {
    return <MatterViewScreenPaywall matterId={matter?._id} />;
  }

  const { folderId, accessToken } = matter.workspaceFolder ?? {};

  const loading =
    queryLoading ||
    archiveMatterLoading ||
    closeMatterLoading ||
    generateClaimUrlLoading ||
    generateDownloadAllUrlLoading ||
    grantClientMatterAccessLoading ||
    openMatterLoading ||
    requestMatterReviewLoading ||
    revokeClientMatterAccessLoading ||
    sendForSigningLoading ||
    updateMatterNameLoading ||
    removeMatterCategoryLoading ||
    addMatterCategoryLoading ||
    removeMatterContactLoading ||
    addMatterContactLoading ||
    addReviewersLoading ||
    approveReviewRequestLoading ||
    revokeReviewRequestLoading ||
    withdrawReviewRequestLoading ||
    addMatterFieldValuesToMatterLoading ||
    deleteMatterFieldValuesFromMatterLoading ||
    updateMatterFieldValuesOnMatterLoading;

  return (
    <>
      {loading && <Spinner />}
      {!!matter && (
        <MatterView
          matter={{
            id: matterId,
            name: matter.name,
            owner: matter.owner,
            canRead: matter.canRead,
            hasQuestionnaire: false,
            state: matter.state,
            clientAccessible: matter.sharedWithClient,
            legacyState: matter.state || matter.legacyQuestionnaireState,
            signatureRequests: matter.signatureRequests,
            workspaceFolder: folderId
              ? {
                  folderId,
                  folderToken: { accessToken },
                }
              : undefined,
            organization: matter.organization,
            categories: matter.categories,
            contacts: matter.contacts,
            reviewRequests: matter.reviewRequests,
            matterFieldValues: matter.matterFieldValues,
          }}
          grantClientAccess={grantClientMatterAccessWithMessage}
          revokeClientAccess={revokeClientMatterAccess}
          toggleMatterState={toggleMatterState}
          deleteMatter={archiveMatter}
          requestFirmReview={requestMatterReviewWithMessage}
          fetchDownloadAllUrl={fetchDownloadAllUrl}
          generateClaimUrl={generateClaimUrlCallback}
          sendForSigning={sendForSigningCallback}
          updateMatterName={updateMatterNameCallback}
          addMatterCategory={addMatterCategoryCallback}
          removeMatterCategory={removeMatterCategoryCallback}
          addMatterContact={addMatterContactCallback}
          removeMatterContact={removeMatterContactCallback}
          addReviewers={addReviewersCallback}
          withdrawReviewRequest={withdrawReviewRequestCallback}
          approveReviewRequest={approveReviewRequestCallback}
          revokeReviewRequest={revokeReviewRequestCallback}
          onSignatureReminder={refetchMatter}
          addMatterFieldValues={addMatterFieldValuesToMatterCallback}
          deleteMatterFieldValues={deleteMatterFieldValuesFromMatterCallback}
          updateMatterFieldValues={updateMatterFieldValuesOnMatterCallback}
          onCancelSignatureRequest={cancelSignatureRequestCallback}
        />
      )}
    </>
  );
}

export default NewMatterViewScreen;
