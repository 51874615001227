import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Spinner from '../../components/standard/Spinner';
import { useUser } from '../../context/UserContext';
import { useQueryParam } from '../../hooks/useQueryParam';
import AuthUtility from '../../services/AuthUtility';
import { userIsClient } from '../../services/Utilities';
import { loginUser } from './Utilities';

const AuthRedirect = () => {
  const [redirectPath] = useQueryParam('redirectPath');
  const [user] = useUser();
  const history = useHistory();

  useEffect(() => {
    const authUtility = new AuthUtility();
    authUtility.setSessionFromURLFragment();
  }, []);

  useEffect(() => {
    if (user?.userId) {
      const redirectUserOnLogin = async () => {
        await loginUser(user);
        if (redirectPath) {
          history.replace(redirectPath);
        } else {
          history.replace(userIsClient() ? '/client/home' : 'new-contract');
        }
      };
      redirectUserOnLogin();
    }
  }, [redirectPath, user, history]);

  return <Spinner />;
};

export default AuthRedirect;
