import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

const Card = styled.div`
  margin-bottom: 2px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  padding: 12px 12px;
  border-radius: 8px;
  ${({ variant = 'default', theme }) => {
    switch (variant) {
      case 'flat-gray':
        return css`
          background-color: ${theme.palette.base.gray};
          padding: 2rem;
        `;
      case 'variant-one':
        return css`
          background-color: ${theme.palette.base.white};
          border: 0.25px solid ${theme.palette.shade.gray_4};
          box-shadow: 0 16px 16px rgba(207, 210, 217, 0.2);
        `;
      case 'default':
      default:
        return css`
          background-color: ${theme.palette.base.white};
          box-shadow: 0 2px 12px rgba(0, 0, 0, 0.06);
        `;
    }
  }}
`;

Card.propTypes = {
  variant: PropTypes.oneOf(['flat-gray', 'variant-one', 'default']),
};

export default Card;
