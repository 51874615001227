import { createGlobalStyle } from 'styled-components/macro';

const SyncfusionStyleOverrides = createGlobalStyle`
  .e-de-ctnr-properties-pane-btn {
    .e-btn {
      color: ${({ theme }) => theme.palette.primary.main} !important;
    }
  }

  .e-radio:checked + label::before {
    border-color: ${({ theme }) => theme.palette.primary.main} !important;
  }
  .e-radio:checked + label::after {
    background-color: ${({ theme }) => theme.palette.primary.main} !important;
    color: ${({ theme }) => theme.palette.primary.main} !important;
  }


  .e-tab {
    .e-tab-header {
        .e-toolbar-items {
          .e-tab-text {
            color: ${({ theme }) => theme.palette.primary.main} !important;
          }
        }
        .e-indicator {
          background: ${({ theme }) => theme.palette.primary.main} !important;
        }
      }
    }
  }

  .e-dialog  {
    .e-footer-content {
      display: flex;
      flex-direction: row-reverse;
      .e-btn {
        &.e-primary {
          color: ${({ theme }) => theme.palette.primary.main} !important;
        }
        :hover {
          background-color: ${({ theme }) =>
            theme.palette.shade.gray_5} !important;
        }
      }
    }
  }
`;

export default SyncfusionStyleOverrides;
