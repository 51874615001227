import axios from 'axios';
import equal from 'fast-deep-equal';
import jsonLogic from 'json-logic-js';
import isEmpty from 'lodash/isEmpty';

import { getCountryJurisdictionDetails } from '../components/forms/Jurisdiction';
import { isUnfilled, validateEmail as validateEmailPattern } from './Utilities';

jsonLogic.add_operation('equal', equal);

export const sectionState = {
  IN_PROGRESS: 'in_progress',
  WARNING: 'warning',
  INVALID: 'invalid',
  COMPLETE: 'complete',
};

export const removeBlankDataFromParams = (templateParams) => {
  const returnParams = { ...templateParams };
  Object.keys(templateParams).forEach((key) => {
    const param = templateParams[key];
    // Check if param is an array
    if (Array.isArray(param)) {
      let i = param.length;
      // Loop through elements of param array (e.g. rows of datatable)
      // eslint-disable-next-line no-plusplus
      while (i--) {
        const dataArray = Object.values(param[i]);
        // Loop through elements (columns) of row to see if row is blank.
        let emptyRow = true;
        for (let j = 0; j < dataArray.length; j++) {
          if (dataArray[j]) {
            emptyRow = false;
            break;
          }
        }
        // Remove blank row from param
        if (emptyRow) {
          param.splice(i, 1);
        }
      }
      returnParams[key] = param;
    }
  });
  return returnParams;
};

export const getFieldIdsFromParamName = (name, paramSpec) => {
  const fieldIds = [];
  paramSpec.forEach((param) => {
    if (param.identifier === name) fieldIds.push(param._id);
  });
  return fieldIds;
};

const validateAddress = (value = '', required) => {
  const fieldNames = {
    line1: 'Address line 1',
    city: 'City',
    subnationalDivision1: 'State/province',
    postalCode: 'Zip/postal code',
    country: 'Country',
  };

  if (required) {
    if (isUnfilled(value)) {
      return ['Valid address required.'];
    }

    return Object.keys(fieldNames)
      .filter((key) => !value[key])
      .map((key) => `${fieldNames[key]} required.`);
  }

  return [];
};

const validatePhoneNumber = (value, required) => {
  const phoneNumber = value || '';
  const tel = phoneNumber.split('ext.')[0].replace(/\D+/g, '');
  if (required) {
    if (tel.length !== 10) return ['Valid phone number required.'];
  }

  return [];
};

const validateEmail = (value = '', required = false) => {
  if (value) {
    const isInvalidEmail = !validateEmailPattern(value);
    if (isInvalidEmail) {
      return ['Valid email required.'];
    }
  } else if (required) {
    return ['Valid email required.'];
  }

  return [];
};

const validateDataTable = (value, required) => {
  const errors = [];
  const requiredFailMessage = 'At least one item required.';

  if (required) {
    if (value === undefined || value === '' || value === null) {
      return [requiredFailMessage];
    }

    let valueInParamsFormat = { datatable: value };
    valueInParamsFormat = removeBlankDataFromParams(valueInParamsFormat);
    if (valueInParamsFormat.datatable.length === 0) {
      return [requiredFailMessage];
    }
  }

  return errors;
};

const validateMultiSelect = (value = {}, required, options = {}) => {
  const errors = [];
  const numOptions = Object.keys(options).length;
  const requiredFailMessage =
    numOptions > 1
      ? 'At least one item must be selected.'
      : 'The checkbox above must be selected to continue';
  if (required) {
    const valueArray = Object.values(value);
    if (!valueArray.includes(true)) {
      return [requiredFailMessage];
    }
  }
  return errors;
};

const validateMultiField = (value = {}, required) => {
  const errors = [];
  const requiredFailMessage = 'At least one field is required.';

  if (required) {
    const fieldValues = Object.values(value);
    if (!fieldValues.some((val) => !!val)) return [requiredFailMessage];
    if (value.element && value.element.type === 'PhoneNumber') {
      return validatePhoneNumber(fieldValues[0], required);
    }
  }
  return errors;
};

const validateFileUpload = async (required, contractId, elementId) => {
  if (contractId && required) {
    const response = await axios.get(
      `/api/v1/templatedContracts/${contractId}/fileuploadstatus/${elementId}`,
    );

    return response.data.isEmpty
      ? ['At least one file is required. Did you forget to press "Upload"?']
      : [];
  }
  return [];
};

const validateJurisdiction = (value = {}, required, { requireCounty }) => {
  if (!required) return [];

  const requiredFieldNames = {
    country: 'Country',
    subnationalDivision: 'State/Province',
  };

  const jurisdictionDetails = getCountryJurisdictionDetails(value?.country);

  if (requireCounty && jurisdictionDetails.showCounty) {
    requiredFieldNames.county = 'County';
  }

  if (isUnfilled(value)) {
    return ['Valid jurisdiction required.'];
  }

  return Object.keys(requiredFieldNames)
    .filter((key) => !value[key])
    .map((key) => `${requiredFieldNames[key]} required.`);
};

const isFilled = (value, fieldName) => {
  if (value === '' || value === null || value === undefined) {
    return fieldName ? [`${fieldName} is required`] : ['Field is required.'];
  }
  return [];
};

export function validateContact(value = {}, required, options = {}) {
  const isTouched = !isUnfilled(value);
  const { fieldConfig = {} } = options ?? {};

  // If any of the subfields other than name or type have been touched, then
  // name and type default to being required.
  const contactFields = Object.fromEntries(
    [
      [
        'name',
        {
          validate: required || isTouched,
          validator: (v) => isFilled(v, 'Name'),
        },
      ],
      [
        'type',
        {
          validate: required || isTouched,
          validator: (v) => isFilled(v, 'Type'),
        },
      ],
      [
        'address',
        {
          validate: required || (isTouched && fieldConfig.address?.required),
          validator: (v) => validateAddress(v, true),
        },
      ],
      [
        'phone',
        {
          validate: required || (isTouched && fieldConfig.phone?.required),
          validator: (v) => validatePhoneNumber(v, true),
        },
      ],
      [
        'email',
        {
          validate: required || (isTouched && fieldConfig.email?.required),
          validator: (v) => validateEmail(v, 'Email'),
        },
      ],
    ].filter(([key]) => fieldConfig[key]?.visible !== false),
  );

  return Object.fromEntries(
    Object.entries(contactFields).map(([key, config]) =>
      config.validate || !isUnfilled(value[key])
        ? [key, config.validator(value[key])]
        : [key, []],
    ),
  );
}

export const validateOne = async (name, value, paramSpec, contract = {}) => {
  const param = paramSpec.find((x) => x.identifier === name);
  const { required, elementId } = param;
  const { _id: contractId } = contract;

  // Type is one level down for complex types
  const { type } = param.type.type ? param.type : param;
  const { options, requireCounty = false } = param.type || {};

  switch (type) {
    case 'Address':
    case 'CompanyAddress':
      return validateAddress(value, required);
    case 'PhoneNumber':
      return validatePhoneNumber(value, required);
    case 'Email':
      return validateEmail(value, required);
    case 'DataTable':
      return validateDataTable(value, required);
    case 'MultiSelect':
      return validateMultiSelect(value, required, options);
    case 'MultiField':
      return validateMultiField(value, required);
    case 'FileUpload':
      return validateFileUpload(required, contractId, elementId);
    case 'Jurisdiction':
      return validateJurisdiction(value, required, { requireCounty });
    case 'Contact':
      return Object.values(validateContact(value, required, options)).flat();
    default:
      return required ? isFilled(value) : [];
  }
};

export const updateErrors = async (
  event,
  elements,
  currentErrors,
  applicability,
  contract,
) => {
  const { target } = event;
  const { checked, name, type, value } = target;

  const paramErrors = await validateOne(
    name,
    type === 'checkbox' ? checked : value,
    elements,
    contract,
  );
  const errors = currentErrors;
  const fieldIds = getFieldIdsFromParamName(name, elements);
  fieldIds.forEach((id) => {
    errors[id] = applicability[id] ? paramErrors : [];
  });

  return errors;
};

function convertToJsonLogic(applicableCondition) {
  if (isEmpty(applicableCondition)) {
    return true;
  }

  return {
    and: Object.entries(applicableCondition).map(([key, value]) => ({
      equal: [{ var: key }, value],
    })),
  };
}

const evaluateApplicabilityOne = (
  { applicableCondition = {}, applicabilityRules = true },
  templateParams,
) =>
  jsonLogic.apply(applicabilityRules, templateParams) &&
  jsonLogic.apply(convertToJsonLogic(applicableCondition), templateParams);

export const evaluateApplicabilityAll = (templateParams, paramSpec) => {
  const applicability = {};
  paramSpec.forEach((singleParamSpec) => {
    const key = singleParamSpec._id;
    applicability[key] = evaluateApplicabilityOne(
      singleParamSpec,
      templateParams,
    );
  });
  return applicability;
};

export const validateAll = async (templateParams, paramSpec, contract) => {
  const errors = {};
  const applicability = evaluateApplicabilityAll(templateParams, paramSpec);
  await Promise.all(
    Object.keys(templateParams).map(async (name) => {
      const paramErrors = await validateOne(
        name,
        templateParams[name],
        paramSpec,
        contract,
      );
      const fieldIds = getFieldIdsFromParamName(name, paramSpec);
      fieldIds.forEach((id) => {
        errors[id] = applicability[id] ? paramErrors : [];
      });
    }),
  );
  return errors;
};

export const getValueFromEvent = (event) => {
  const { target: { type, value, checked } = {} } = event;
  switch (type) {
    case 'checkbox': {
      return checked;
    }
    case 'number': {
      return !value
        ? undefined
        : parseFloat(value.toString().replace(/[^.\d]/g, ''));
    }
    default: {
      return value;
    }
  }
};
