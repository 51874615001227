import axios from 'axios';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled, { ThemeContext } from 'styled-components/macro';
import { handleException } from 'utils/ErrorUtils';

import useHostedFields, {
  HostedFieldsError,
} from '../../hooks/useHostedFields';
import { trackPayment } from '../../services/Analytics';
import Address from '../forms/Address';
import BottomBar from '../forms/BottomBar';
import { FloatingTextField } from '../forms/FormStyles';
import Spinner from '../standard/Spinner';
import SummarySection from './SummarySection';

const SectionHeader = styled.span`
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
  color: #4d5259;
`;

const Container = styled.div`
  iframe {
    border: none;
    width: 100%;
    height: 1em;
  }
`;

const fieldStyle = (theme) => ({
  'font-size': '18px',
  'font-weight': 'normal',
  'letter-spacing': '0.02em',
  'line-height': '168%',
  ':focus': { color: theme.palette.gray.dark },
  'margin-bottom': 0,
  color: theme.palette.gray.main,
  padding: 0,
});

const fields = (theme) => [
  {
    selector: '#credit_card',
    input: {
      type: 'credit_card_number',
      css: fieldStyle(theme),
    },
  },
  {
    selector: '#cvv',
    input: {
      type: 'cvv',
      css: fieldStyle(theme),
    },
  },
];

const HostedField = ({ inputRef, ...props }) => (
  <div ref={inputRef} {...props} />
);

const LawPayForm = ({
  className,
  matterId,
  service,
  publicKey,
  canSkipPayment,
  payBeforeService,
}) => {
  const history = useHistory();
  const theme = useContext(ThemeContext);
  const fieldStyles = useMemo(() => fields(theme), [theme]);

  const [isLoading, setIsLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [name, setName] = useState('');
  const [expiry, setExpiry] = useState('');
  const [address, setAddress] = useState({});
  const [creditCardState, setCreditCardState] = useState({
    focused: false,
    shrink: false,
  });
  const [cvvState, setCvvState] = useState({ focused: false, shrink: false });

  const handleAddressChange = useCallback(
    (event) => setAddress({ ...address, ...event.target.value }),
    [address],
  );

  const handleNameChange = useCallback(
    (event) =>
      event.type === 'blur'
        ? setName(event.target.value.trim())
        : setName(event.target.value),
    [],
  );

  const handleExpiryChange = useCallback(
    (event) => setExpiry(event.target.value),
    [],
  );

  const handleStateChange = useCallback((state) => {
    setIsReady(state.isReady);
    const newState = {
      focused: state.target.focus,
      shrink:
        state.target.focus || state.target.error !== 'Input field is empty',
      error: state.target.error,
    };

    if (state.target.selector === '#credit_card') {
      setCreditCardState(newState);
    }

    if (state.target.selector === '#cvv') {
      setCvvState(newState);
    }
  }, []);

  const { hostedFields, parseException } = useHostedFields(
    publicKey,
    fieldStyles,
    handleStateChange,
  );

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setIsLoading(true);
      if (!hostedFields) return;
      if (!hostedFields.getState()) return;

      try {
        const [expiryMonth, expiryYear] = expiry.split(/\s*\/\s*/);

        let paymentToken;
        try {
          paymentToken = await hostedFields.getPaymentToken({
            exp_year: expiryYear,
            exp_month: expiryMonth,
            name,
            email: localStorage.getItem('email'),
            address1: address.line1,
            address2: address.line2,
            // state: address.subnationalDivision1, // TODO (noseworthy) - Make this 2 character ISO code
            // country: address.country,  // TODO (noseworthy) - Make this 2 character ISO code
            city: address.city,
            postal_code: address.postalCode,
          });
        } catch (err) {
          const error = parseException(err);
          throw new HostedFieldsError(
            error.message,
            error.messages.map((message) => ({ message, level: 'error' })),
          );
        }

        let result;
        try {
          const { data } = await axios.post(
            `/api/v1/templatedContracts/${matterId}/payment`,
            {
              paymentToken,
              palette: theme.palette,
            },
          );
          result = data.result || {};
        } catch (err) {
          if (err.response?.data?.result?.messages?.length > 0) {
            throw new HostedFieldsError(
              'error processing payment',
              err.response.data.result.messages,
            );
          } else {
            throw err;
          }
        }

        if (result.status !== 'AUTHORIZED') {
          throw new Error(result);
        } else {
          trackPayment(true, 'Questionnaire', 'Flat Fee');
          window.location.assign(`/client/matter/${matterId}`);
        }
      } catch (err) {
        trackPayment(false, 'Questionnaire', 'Flat Fee');
        if (err instanceof HostedFieldsError) {
          err.messages.forEach(({ message, level }) => toast[level](message));
        } else {
          handleException(err);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [hostedFields, parseException, name, address, expiry, matterId, theme],
  );

  const skipPaymentClickHandler = useCallback(async () => {
    try {
      setIsLoading(true);
      await axios.post(`/api/v1/templatedContracts/${matterId}/payment`, {
        skipPayment: true,
        palette: theme.palette,
      });
      window.location.assign(`/client/matter/${matterId}`);
    } catch (err) {
      handleException(err);
    } finally {
      setIsLoading(false);
    }
  }, [matterId, theme]);

  const backButtonClickHandler = useCallback(() => {
    if (payBeforeService) {
      history.push('/client/home');
    } else {
      history.push(`/client/matter/${matterId}/questionnaire`);
    }
  }, [history, matterId, payBeforeService]);

  const backButton = {
    props: {
      onClick: backButtonClickHandler,
    },
  };

  const nextButton = {
    copy: 'Pay',
    props: {
      type: 'submit',
      disabled: isLoading || !isReady,
    },
  };

  return (
    <Container>
      {isLoading && <Spinner />}
      <form id="payment-form" className={className} onSubmit={handleSubmit}>
        <div className="d-flex flex-lg-row align-items-lg-start jusifty-content-lg-between flex-column-reverse align-items-center">
          <div className="col mr-lg-4 col-lg-5 p-0">
            <div className="row">
              <div className="col">
                <SectionHeader>Billing Address</SectionHeader>
              </div>
            </div>
            <div className="row mb-50">
              <div className="col">
                <Address
                  name="address"
                  onChange={handleAddressChange}
                  onBlur={handleAddressChange}
                  address={address}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <SectionHeader>Credit Card</SectionHeader>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <FloatingTextField
                  id="cardholder_name"
                  placeholder="John Doe"
                  label="Cardholder Name"
                  autoComplete="no"
                  value={name}
                  onChange={handleNameChange}
                  onBlur={handleNameChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <FloatingTextField
                  id="credit_card"
                  label="Credit Card Number"
                  error={!!creditCardState.error && !creditCardState.focused}
                  helperText={
                    !creditCardState.focused ? creditCardState.error : ''
                  }
                  InputProps={{
                    inputComponent: HostedField,
                  }}
                  InputLabelProps={{
                    shrink: creditCardState.shrink,
                  }}
                  focused={creditCardState.focus}
                />
              </div>
            </div>
            <div className="row mb-100">
              <div className="col">
                <NumberFormat
                  id="expiry"
                  name="expiry"
                  label="Expiry"
                  placeholder="MM / YY"
                  format="## / ##"
                  mask="_"
                  onChange={handleExpiryChange}
                  customInput={FloatingTextField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  isNumericString
                />
              </div>
              <div className="col">
                <FloatingTextField
                  label="CVV"
                  id="cvv"
                  error={!!cvvState.error && !cvvState.focused}
                  helperText={!cvvState.focused ? cvvState.error : ''}
                  InputProps={{
                    inputComponent: HostedField,
                  }}
                  InputLabelProps={{
                    shrink: cvvState.shrink,
                  }}
                  focused={cvvState.focus}
                />
              </div>
            </div>
          </div>
          <div className="col p-0">
            <SummarySection
              service={service}
              canSkipPayment={canSkipPayment}
              skipPaymentClickHandler={skipPaymentClickHandler}
            />
          </div>
        </div>
        <BottomBar backButton={backButton} nextButton={nextButton} />
      </form>
    </Container>
  );
};

export default LawPayForm;
