import { gql, useMutation, useQuery } from '@apollo/client';
import equal from 'fast-deep-equal';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
  isUnfilled,
  stripTypenames,
  userIsClient,
} from '../../../../services/Utilities';
import BottomBar from '../../../forms/BottomBar';
import DataContainer from '../components/DataContainer';
import DataContent from '../components/DataContent';
import DataHeader from '../components/DataHeader';
import Form from '../components/forms/Form';
import TemplateGeneratedFieldInput from '../components/forms/TemplateGeneratedFieldInput';

const ADDRESS_FIELDS = gql`
  fragment AddressFields on Address {
    line1
    line2
    city
    subnationalDivision1
    country
    postalCode
  }
`;

const JURISDICTION_FIELDS = gql`
  fragment JurisdictionFields on Jurisdiction {
    country
    subnationalDivision
    county
  }
`;

const ENTITY_INFORMATION_DETAILS = gql`
  fragment EntityInformationDetails on EntityInformation {
    name
    tradeNames {
      name
      expirationDate
    }
    descriptionOfBusiness
    homeJurisdiction {
      ...JurisdictionFields
    }
    operatingJurisdictions {
      jurisdiction {
        ...JurisdictionFields
      }
    }
    entityType
    entityRegistrationNumber
    registeredAgentName
    registeredAgentAddress {
      ...AddressFields
    }
    primaryAddress {
      ...AddressFields
    }
    mailingAddress {
      ...AddressFields
    }
    primaryPhone
    primaryEmail
    taxId
    formationDate
    fiscalYearEnd
    entityStatus
    agmDeadline
    accountantName
    accountantAddress {
      ...AddressFields
    }
    minimumNumberOfDirectors
    maximumNumberOfDirectors
    authorizedShareCapital
    restrictionsOnShareCapital
    transferRestrictions
  }

  ${ADDRESS_FIELDS}
  ${JURISDICTION_FIELDS}
`;

const CLIENT_GET_CLIENT_ORGANIZATION = gql`
  query clientGetClientOrganization($userId: MongoID!) {
    client(_id: $userId) {
      _id
      organizationDetails {
        _id
        entityInformation {
          ...EntityInformationDetails
        }
      }
    }
  }

  ${ENTITY_INFORMATION_DETAILS}
`;

const FIRM_GET_CLIENT_ORGANIZATION = gql`
  query firmGetClientOrganization(
    $userId: String!
    $firmId: MongoID!
    $clientId: MongoID!
  ) {
    firmUser(id: $userId) {
      id
      firm(id: $firmId) {
        _id
        client(org: $clientId) {
          _id
          entityInformation {
            ...EntityInformationDetails
          }
        }
      }
    }
  }

  ${ENTITY_INFORMATION_DETAILS}
`;

const UPDATE_ENTITY_INFORMATION = gql`
  mutation updateEntityInformation(
    $orgId: MongoID!
    $entityInformation: EntityInformationInput!
  ) {
    updateOrgEntityInformation(
      orgId: $orgId
      entityInformation: $entityInformation
    ) {
      record {
        ...EntityInformationDetails
      }
    }
  }

  ${ENTITY_INFORMATION_DETAILS}
`;

function EntityInformationFormFields({ entityInformation }) {
  const [showMailingAddress, setShowMailingAddress] = useState(
    !entityInformation.mailingAddressSameAsBusinessAddress,
  );

  const { watch, setValue } = useFormContext();

  const watchSameAsPrimaryAddress = watch(
    'mailingAddressSameAsBusinessAddress',
  );
  const watchPrimaryAddress = watch('primaryAddress');

  useEffect(() => {
    if (watchSameAsPrimaryAddress) {
      setValue('mailingAddress', watchPrimaryAddress);
    }
    setShowMailingAddress(!watchSameAsPrimaryAddress);
  }, [setValue, watchSameAsPrimaryAddress, watchPrimaryAddress]);

  return (
    <>
      <TemplateGeneratedFieldInput
        label="Entity Name"
        name="name"
        defaultValue={entityInformation.name}
        required
      />
      <TemplateGeneratedFieldInput
        label="Trade Names"
        name="tradeNames"
        type={{
          type: 'DataTable',
          noun: 'Trade Name',
          elements: [
            {
              identifier: 'name',
              name: 'Trade Name',
              type: 'String',
              required: true,
            },
            {
              identifier: 'expirationDate',
              name: 'Expiration Date',
              type: 'Date',
              required: false,
            },
          ],
        }}
        defaultValue={entityInformation.tradeNames}
      />
      <TemplateGeneratedFieldInput
        label="Description of Business"
        name="descriptionOfBusiness"
        type="LongString"
        defaultValue={entityInformation.descriptionOfBusiness}
      />
      <TemplateGeneratedFieldInput
        label="Home Jurisdiction"
        name="homeJurisdiction"
        type={{ type: 'Jurisdiction', requireCounty: true }}
        defaultValue={entityInformation.homeJurisdiction}
      />
      <TemplateGeneratedFieldInput
        label="Operating Jurisdictions"
        name="operatingJurisdictions"
        type={{
          type: 'DataTable',
          noun: 'Jurisdiction',
          elements: [
            {
              identifier: 'jurisdiction',
              name: 'Jurisdiction',
              type: 'Jurisdiction',
              required: true,
            },
          ],
        }}
        defaultValue={entityInformation.operatingJurisdictions}
      />
      <TemplateGeneratedFieldInput
        label="Entity Type"
        name="entityType"
        defaultValue={entityInformation.entityType}
        type={{
          type: 'Enum',
          set: [
            'C Corporation',
            'Cooperative',
            'Corporation',
            'General Partnership',
            'Individual',
            'Limited Liability Company (LLC)',
            'Limited Partnership',
            'Non-Profit Corporation',
            'S Corporation',
            'Sole Proprietorship',
            'Other',
          ],
        }}
      />
      <TemplateGeneratedFieldInput
        label="Entity Registration Number"
        name="entityRegistrationNumber"
        defaultValue={entityInformation.entityRegistrationNumber}
      />
      <TemplateGeneratedFieldInput
        label="Registered Agent Name"
        name="registeredAgentName"
        defaultValue={entityInformation.registeredAgentName}
      />
      <TemplateGeneratedFieldInput
        label="Registered Office/Registered Agent Address"
        name="registeredAgentAddress"
        defaultValue={entityInformation.registeredAgentAddress}
        type="Address"
      />
      <TemplateGeneratedFieldInput
        label="Primary Business Address"
        name="primaryAddress"
        defaultValue={entityInformation.primaryAddress}
        type="Address"
      />
      <TemplateGeneratedFieldInput
        label="Mailing Address Same as Primary Business Address"
        name="mailingAddressSameAsBusinessAddress"
        defaultValue={entityInformation.mailingAddressSameAsBusinessAddress}
        type="Boolean"
      />
      {showMailingAddress && (
        <TemplateGeneratedFieldInput
          label="Mailing Address"
          name="mailingAddress"
          defaultValue={entityInformation.mailingAddress}
          type="Address"
        />
      )}
      <TemplateGeneratedFieldInput
        label="Primary Phone Number"
        name="primaryPhone"
        defaultValue={entityInformation.primaryPhone}
        type="PhoneNumber"
      />
      <TemplateGeneratedFieldInput
        label="Primary Email"
        name="primaryEmail"
        type="Email"
        defaultValue={entityInformation.primaryEmail}
      />
      <TemplateGeneratedFieldInput label="Tax ID/EIN" name="taxId" />
      <TemplateGeneratedFieldInput
        label="Formation Date"
        name="formationDate"
        defaultValue={entityInformation.formationDate}
        type={{ type: 'Date' }}
      />
      <TemplateGeneratedFieldInput
        label="Fiscal Year-End"
        name="fiscalYearEnd"
        defaultValue={entityInformation.fiscalYearEnd}
        type={{ type: 'Date', noYear: true }}
      />
      <TemplateGeneratedFieldInput
        label="Entity Status"
        name="entityStatus"
        defaultValue={entityInformation.entityStatus}
        type={{
          type: 'Enum',
          set: [
            'Active',
            'Dissolved',
            'Inactive',
            'Amalgamated/Merged',
            'Discontinued',
            'Unknown',
          ],
        }}
      />
      <TemplateGeneratedFieldInput
        label="Annual General Meeting Deadline"
        name="agmDeadline"
        defaultValue={entityInformation.agmDeadline}
        type={{ type: 'Date', noYear: true }}
      />
      <TemplateGeneratedFieldInput
        label="Accountant Name"
        name="accountantName"
        defaultValue={entityInformation.accountantName}
      />
      <TemplateGeneratedFieldInput
        label="Accountant Address"
        name="accountantAddress"
        defaultValue={entityInformation.accountantAddress}
        type="Address"
      />
      <TemplateGeneratedFieldInput
        label="Minimum Number of Directors"
        name="minimumNumberOfDirectors"
        defaultValue={entityInformation.minimumNumberOfDirectors}
        type="Integer"
      />
      <TemplateGeneratedFieldInput
        label="Maximum Number of Directors"
        name="maximumNumberOfDirectors"
        helper="Leave blank for unlimited"
        defaultValue={entityInformation.maximumNumberOfDirectors}
        type="Integer"
      />
      <TemplateGeneratedFieldInput
        label="Authorized Share Capital"
        name="authorizedShareCapital"
        defaultValue={entityInformation.authorizedShareCapital}
        type="LongString"
      />
      <TemplateGeneratedFieldInput
        label="Restrictions on Share Capital"
        name="restrictionsOnShareCapital"
        defaultValue={entityInformation.restrictionsOnShareCapital}
        type="LongString"
      />
      <TemplateGeneratedFieldInput
        label="Transfer Restrictions"
        name="transferRestrictions"
        defaultValue={entityInformation.transferRestrictions}
        type="LongString"
      />
    </>
  );
}

function areAddressesEqual(information) {
  return (
    !isUnfilled(information?.primaryAddress) &&
    equal(information?.primaryAddress, information?.mailingAddress)
  );
}

export default function EntityInformation() {
  const { clientId } = useParams();
  const userId = localStorage.getItem('userId');
  const firmId = localStorage.getItem('firmId');

  const [entityInformation, setEntityInformation] = useState(null);
  const [orgId, setOrgId] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const { loading } = useQuery(
    userIsClient()
      ? CLIENT_GET_CLIENT_ORGANIZATION
      : FIRM_GET_CLIENT_ORGANIZATION,
    {
      fetchPolicy: 'cache-and-network',
      variables: userIsClient()
        ? {
            userId: userId.replace(/^.*\|/, ''),
          }
        : { userId, firmId, clientId },
      onCompleted: (data) => {
        const organizationDetails = userIsClient()
          ? data?.client?.organizationDetails?.[0]
          : data?.firmUser?.firm?.client;

        if (organizationDetails?._id) {
          setOrgId(organizationDetails._id);
        }

        const newEntityInformation = organizationDetails?.entityInformation;

        if (newEntityInformation) {
          const mailingAddressSameAsBusinessAddress =
            areAddressesEqual(newEntityInformation);

          setEntityInformation({
            mailingAddressSameAsBusinessAddress,
            ...stripTypenames(newEntityInformation),
          });
        }
      },
    },
  );

  const [updateEntityInformation] = useMutation(UPDATE_ENTITY_INFORMATION, {
    onCompleted: (data) => {
      const { record } = data?.updateOrgEntityInformation || {};

      if (record) {
        const mailingAddressSameAsBusinessAddress = areAddressesEqual(record);

        setEntityInformation({
          mailingAddressSameAsBusinessAddress,
          ...stripTypenames(record),
        });
      }
    },
  });

  return (
    <DataContainer>
      <DataHeader title="Entity Information" />
      <DataContent loading={loading || isSaving} isDirty={isDirty}>
        {!!entityInformation && (
          <Form
            id="entity-information-form"
            onIsDirty={(dirtyStatus) => setIsDirty(dirtyStatus)}
            onSubmit={async ({
              mailingAddressSameAsBusinessAddress,
              ...formData
            }) => {
              setIsSaving(true);
              try {
                await updateEntityInformation({
                  variables: { orgId, entityInformation: formData },
                });
              } finally {
                setIsSaving(false);
              }
            }}
            defaultValues={entityInformation}
          >
            <EntityInformationFormFields
              entityInformation={entityInformation}
            />
          </Form>
        )}
      </DataContent>
      <BottomBar
        nextButton={{
          copy: 'Save',
          props: {
            form: 'entity-information-form',
            type: 'submit',
            disabled: loading || isSaving || !isDirty,
          },
        }}
      />
    </DataContainer>
  );
}
