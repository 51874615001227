import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ThemeContext } from 'styled-components/macro';
import { handleException } from 'utils/ErrorUtils';

import { useUser } from '../../../context/UserContext';
import { stripTypenames } from '../../../services/Utilities';
import * as RallyPropTypes from '../../../utils/RallyPropTypes';
import { AddContactModal } from '../../client/contacts/AddContactModal';
import { TextButton } from '../../standard/Button';
import { TagSelect } from '../../standard/TagSelect';
import { ToastContent } from '../../standard/Toaster';

export const CONTACT_DATA_FRAGMENT = gql`
  fragment ContactName on Contact {
    _contactId
    name
  }
`;

export const FIRM_GET_CONTACTS = gql`
  query firmGetContacts(
    $userId: String!
    $firmId: MongoID!
    $clientId: MongoID!
  ) {
    firmUser(id: $userId) {
      id
      firm(id: $firmId) {
        _id
        client(org: $clientId) {
          _id
          contacts {
            ...ContactName
          }
        }
      }
    }
  }

  ${CONTACT_DATA_FRAGMENT}
`;

export const CLIENT_GET_CONTACTS = gql`
  query clientGetContact($userId: MongoID!) {
    client(_id: $userId) {
      _id
      organizationDetails {
        _id
        contacts {
          ...ContactName
        }
      }
    }
  }

  ${CONTACT_DATA_FRAGMENT}
`;

function getContactOption(contact) {
  return {
    label: contact.name,
    value: contact,
  };
}

export const MatterContacts = ({
  contacts,
  organization,
  addMatterContact = async () => {},
  removeMatterContact = async () => {},
}) => {
  const theme = useContext(ThemeContext);
  const [orgContacts, setOrgContacts] = useState([]);
  const [newMatterContact, setNewMatterContact] = useState({});
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const { clientId } = useParams();
  const [{ firm, userId, userType }] = useUser();

  const { refetch } = useQuery(
    userType === 'client' ? CLIENT_GET_CONTACTS : FIRM_GET_CONTACTS,
    {
      fetchPolicy: 'cache-and-network',
      variables:
        userType === 'client'
          ? {
              userId: userId.replace(/^.*\|/, ''),
            }
          : { userId, firmId: firm._id, clientId },
      onCompleted: (data) => {
        const organizationDetails =
          userType === 'client'
            ? data?.client?.organizationDetails?.[0]
            : data?.firmUser?.firm?.client;

        const newContacts = organizationDetails?.contacts;
        if (newContacts) {
          setOrgContacts(stripTypenames(newContacts));
        }
      },
      onError: (error) => handleException(error),
    },
  );

  const onChange = useCallback(
    async (_contacts, event) => {
      switch (event.action) {
        case 'pop-value':
        case 'remove-value': {
          const { removedValue } = event;
          if (removedValue?.value) {
            await removeMatterContact(removedValue.value);
            toast.info(
              <ToastContent>
                <span>{removedValue.value.name} has been removed</span>
                <TextButton
                  className="rally-typography rally-typography-h6"
                  onClick={() => addMatterContact(removedValue.value)}
                >
                  Undo
                </TextButton>
              </ToastContent>,
            );
          }
          break;
        }
        case 'select-option': {
          const { option } = event;
          await addMatterContact(option.value);
          break;
        }
        default: {
          break;
        }
      }
    },
    [addMatterContact, removeMatterContact],
  );

  return (
    <div data-testid="associated-contacts">
      <TagSelect
        aria-label="Associated Contacts"
        isMulti
        value={contacts?.map(getContactOption)}
        placeholder="+ Add Associated Contacts"
        options={orgContacts
          ?.filter(
            ({ _contactId: orgContactId }) =>
              !contacts?.some(({ _contactId }) => orgContactId === _contactId),
          )
          .map(getContactOption)}
        onChange={onChange}
        onCreateOption={(name) => {
          setNewMatterContact({ name });
          setShowAddContactModal(true);
        }}
        backgroundColor={theme.palette.feedback.info.light}
        borderColor={theme.palette.feedback.info.light}
      />
      <AddContactModal
        open={showAddContactModal}
        onClose={() => setShowAddContactModal(false)}
        onAdd={async (newContact) => {
          await refetch();
          await addMatterContact(newContact);
        }}
        organization={organization?._id}
        contact={newMatterContact}
        fieldConfig={{
          phone: { visible: false },
          email: { visible: false },
          address: { visible: false },
        }}
      />
    </div>
  );
};

MatterContacts.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      address: RallyPropTypes.Address,
      archived: PropTypes.bool,
      email: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
      organization: PropTypes.object,
      phone: PropTypes.string,
      type: PropTypes.string,
      _contactId: PropTypes.string,
      _id: PropTypes.string,
    }),
  ),
};
