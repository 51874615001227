import React from 'react';
import styled, { css } from 'styled-components/macro';

import { ContentPicker } from '../proxy-box';
import Modal from '../standard/Modal';

const StyledPicker = styled(ContentPicker)`
  &&& {
    height: 60vh;
    display: inline-block;
    min-width: unset;
    .be-logo,
    .btn.be-btn-sort {
      display: none;
    }
    .be-header,
    .bcp-footer {
      background: white;
    }
    .be-breadcrumb:first-child button {
      text-indent: -9999px;
      line-height: 0;
      &:after {
        content: 'Workspace';
        text-indent: 0;
        display: block;
        line-height: initial;
      }
    }
    .be-app-element {
      outline: none !important;
    }
    .btn-group > .btn.btn-primary:last-of-type {
      border-right-color: ${(props) => props.theme.palette.primary.main};
    }
    .btn {
      background-color: transparent;
      color: ${(props) => props.theme.palette.primary.main};
      font-weight: 400;
      font-size: 14px;
      padding: 5px 16px;
      line-height: inherit;
      border-radius: 2px;
      outline: none !important;
      transition: 0.15s linear;

      width: ${(props) => props.width || 'auto'};
      text-align: ${(props) => props.textAlign || 'center'};
      white-space: nowrap;
      border: 1px solid transparent;
      margin-top: ${(props) => props.marginTop};

      &:hover {
        background-color: transparent;
        border-color: transparent;
        cursor: pointer;
        color: ${(props) => props.theme.palette.primary.dark};
      }

      &:active {
        background-color: transparent;
        border-color: transparent;
        color: ${(props) => props.theme.palette.primary.dark};
      }

      &:focus {
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
        color: ${(props) => props.theme.palette.primary.dark};
      }

      &:disabled {
        background-color: transparent;
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
    .btn.btn-primary {
      background-color: ${(props) => props.theme.palette.primary.main};
      border-color: ${(props) => props.theme.palette.primary.main};
    }
    .btn.btn-primary:hover {
      background-color: ${(props) => props.theme.palette.primary.light};
      border-color: ${(props) => props.theme.palette.primary.light};
      border-right-color: ${(props) => props.theme.palette.primary.light};
    }
    .bce-item-row:hover,
    .bce-item-row:active,
    .bce-item-row:focus {
      background-color: #ffffff;
      border-left-color: ${(props) => props.theme.palette.primary.light};
      box-shadow: 0 0 5px #d8dadc;
    }
    .bce-item-row.bce-item-row-selected {
      background-color: #f2f2f2;
      border-bottom-color: #f2f2f2;
    }
    ${({ showHeader = true }) =>
      !showHeader &&
      css`
        && .be-header {
          display: none;
        }
      `}
  }
`;

const FilePickerModal = ({
  token,
  rootFolderId,
  onCancel,
  onChoose,
  cancelButtonLabel,
  chooseButtonLabel,
  canUpload = false,
  canSetShareAccess = false,
  canCreateNewFolder = false,
  showHeader = true,
  type = 'file',
  display = false,
  title = 'Select some files',
  onClose,
  portal,
}) => (
  <Modal
    display={display}
    title={title}
    onClose={onClose}
    hideAccept
    fullWidth
    portal={portal}
  >
    <StyledPicker
      token={token}
      rootFolderId={rootFolderId}
      onCancel={onCancel}
      onChoose={onChoose}
      cancelButtonLabel={cancelButtonLabel}
      chooseButtonLabel={chooseButtonLabel}
      canUpload={canUpload}
      canSetShareAccess={canSetShareAccess}
      canCreateNewFolder={canCreateNewFolder}
      type={type}
      showHeader={showHeader}
    />
  </Modal>
);

export default FilePickerModal;
