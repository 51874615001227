import { useEffect, useState } from 'react';
import useScript from 'react-script-hook';
import { handleException } from 'utils/ErrorUtils';

class HostedFieldsError extends Error {
  constructor(message, messages) {
    super(message);
    this.name = 'HostedFieldsError';
    this.messages = messages;
  }
}

const useHostedFields = (publicKey, fields, onStateChange = (f) => f) => {
  const [hostedFields, setHostedFields] = useState(null);
  const [parseException, setParseException] = useState(null);

  const [loading, error] = useScript({
    src: 'https://cdn.affinipay.com/hostedfields/1.1.1/fieldGen_1.1.1.js',
    integrity:
      'sha384-eHBjEP83qHo1WozZ8XhhBRpSoko3Ub+dHGYK4A1KEMkGhd23Byo67vB2+P6Q9JQZ',
    crossorigin: 'anonymous',
    checkForExisting: true,
  });

  useEffect(() => {
    try {
      if (error) throw error;
      if (!loading && publicKey && fields) {
        const { initializeFields, parseException: newParseException } =
          window.AffiniPay.HostedFields;

        setHostedFields(initializeFields({ publicKey, fields }, onStateChange));
        setParseException(() => newParseException);
      }
    } catch (err) {
      handleException(err);
    }
  }, [loading, error, publicKey, fields, onStateChange]);

  return {
    hostedFields,
    parseException,
  };
};

export default useHostedFields;
export { HostedFieldsError };
