import { dom, library } from '@fortawesome/fontawesome-svg-core';

export const prefix = 'fak';

export const iconName = 'matter-fields';

export const width = 512;

export const height = 512;

export const aliases = [];

export const unicode = 'e000';

export const svgPathData =
  'M239 2.93C250-.978 262-.978 273 2.93L481 79.2C499 86.1 512 104 512 124V384C512 404 499 422 481 429L273 504C262 509 250 509 239 504L31.5 429C12.6 422 0 404 0 384V124C0 104 12.6 86.1 31.5 79.2L239 2.93zM262 33C257 31.7 254 31.7 250 33L44.1 109L256 191L468 109L262 33zM42.5 399L240 471V220L32 138V384C32 390 36.2 396 42.5 399zM272 471L470 399C476 396 480 390 480 384V138L272 220V471zM78.1 247L186 288C196 291 207 288 210 279C213 271 207 261 197 258L89.1 218C79.1 214 68.6 218 65.5 226C62.5 234 68.1 244 78.1 247zM74.1 308L182 349C192 352 203 349 206 340C209 332 203 322 193 319L85.1 279C75.1 275 64.6 279 61.5 287C58.5 295 64.1 305 74.1 308zM71.1 369L179 410C189 413 200 410 203 401C206 393 200 383 190 380L82.1 340C72.1 336 61.6 340 58.5 348C55.5 356 61.1 366 71.1 369z';

export const fakMatterFields = {
  prefix,
  iconName,
  icon: [width, height, aliases, unicode, svgPathData],
};

library.add(fakMatterFields);

dom.watch();
