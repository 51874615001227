export default class EntityManager {
  constructor(
    templateParams,
    paramSpec = [],
    updateTemplateParams,
    entities = {},
  ) {
    this.updateTemplateParams = updateTemplateParams;
    this.entities = { ...entities };
    this.entityFields = {};

    // Find the entity fields
    paramSpec.forEach(({ identifier, type }) => {
      if (type === 'Entity') {
        this.entityFields[identifier] = { key: null, linkedParams: [] };
      }
    });

    // Update entities with current values
    paramSpec.forEach(({ entityIdentifier, identifier, type }) => {
      if (type === 'Entity') {
        const name = templateParams[identifier];
        if (name) this.setEntity(identifier, name);
      } else if (type === 'Address') {
        const entity = this.entityFields[entityIdentifier];
        if (!entity) return;
        entity.linkedParams.push({ id: identifier, type });
        this.updateEntity(entityIdentifier, {
          address: templateParams[identifier],
        });
      } else if (type.type === 'DataTable') {
        const dataRows = templateParams[identifier] || [];
        dataRows.forEach((row) => {
          const entity = {};
          type.elements.forEach((el) => {
            if (el.type === 'Entity') entity.name = row[el.identifier];
            else if (el.type === 'Address') entity.address = row[el.identifier];
          });
          if (!entity.name) return;
          this.upsertEntity(entity.name.trim().toLowerCase(), entity);
        });
      }
    });
  }

  setEntity = (identifier, name) => {
    const sanitizedKey = name.trim().toLowerCase();
    if (!sanitizedKey) return;
    this.entityFields[identifier].key = sanitizedKey;
    const entity = this.updateEntity(identifier, { name });
    this.entityFields[identifier].linkedParams.forEach(({ id, type }) => {
      if (type === 'Address' && entity.address) {
        this.updateTemplateParams(id, entity.address);
      }
    });
  };

  updateEntity = (identifier, entity) => {
    const { key } = this.entityFields[identifier];
    return this.upsertEntity(key, entity);
  };

  mergeEntityContext = (context) => {
    Object.values(context.entityFields).forEach(({ key }) => {
      this.upsertEntity(key, context.entities[key]);
    });
  };

  upsertEntity = (key, entity) => {
    if (!key) return null;
    this.entities[key] = { ...(this.entities[key] || {}), ...entity };
    return this.entities[key];
  };
}
