import HelloSign from 'hellosign-embedded';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { handleException } from 'utils/ErrorUtils';
import { v4 } from 'uuid';

import {
  trackSendForSigning,
  trackSentForSigning,
} from '../../../services/Analytics';
import FilePickerModal from '../../contracts/FilePickerModal';
import Spinner from '../../standard/Spinner';

export async function handleHelloSignAuth({ fileIds }) {
  const { protocol, hostname, href } = window.location;
  localStorage.setItem('selectedFilesToSign', JSON.stringify(fileIds));
  const state = JSON.stringify({
    url: `${href}`,
    callback: `${protocol}//${hostname}/oauth/hellosign`,
    nonce: v4(),
  });
  localStorage.setItem('HELLOSIGN_OAUTH_STATE', state);
  const qs = queryString.stringify({
    response_type: 'code',
    client_id: process.env.REACT_APP_HELLOSIGN_ID,
    state: window.btoa(state),
  });
  window.location.assign(`https://app.hellosign.com/oauth/authorize?${qs}`);
}

export async function openSendForSigningModal({
  fileIds,
  matterId,
  helloSignRef,
  generateClaimUrl,
  sendForSigning,
}) {
  if (!helloSignRef.current) {
    return;
  }

  trackSendForSigning();

  const clientId = process.env.REACT_APP_HELLOSIGN_ID;
  try {
    const claimUrl = await generateClaimUrl({ matterId, fileIds });
    if (claimUrl) {
      helloSignRef.current.open(claimUrl, {
        clientId,
        skipDomainVerification: true,
        claimUrl,
      });

      if (!helloSignRef.current.e?.send?.length) {
        helloSignRef.current.on('send', (...args) => {
          sendForSigning(...args);
          trackSentForSigning();
        });
      }
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message ?? error.message;
    if (errorMessage?.match(/email not verified/i)) {
      toast.error(
        'Your email address must be verified before you can send a signature request. Please check your email for a verification link.',
      );
    } else if (
      error.response?.status === 403 ||
      errorMessage?.match(/no hellosign account connected\./i) ||
      errorMessage?.match(/unable to refresh hellosign token/i)
    ) {
      await handleHelloSignAuth({ fileIds });
    } else if (error.response?.status === 400) {
      toast.error(errorMessage);
    } else {
      handleException(error);
      toast.error(
        'Something went wrong sending your signature request. Please try again',
      );
    }
  }
}

export async function doSignOnLoad({
  matterId,
  helloSignRef,
  generateClaimUrl,
  sendForSigning,
}) {
  const filesToSign = JSON.parse(localStorage.getItem('selectedFilesToSign'));

  if (filesToSign) {
    await openSendForSigningModal({
      fileIds: filesToSign,
      matterId,
      helloSignRef,
      generateClaimUrl,
      sendForSigning,
    });
    localStorage.removeItem('selectedFilesToSign');
  }
}

export function SendForSigning({
  display,
  setDisplay = () => {},
  folderId,
  accessToken,
  matterId,
  generateClaimUrl,
  sendForSigning,
  ...props
}) {
  const helloSignRef = useRef(new HelloSign());
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!!matterId && !!sendForSigning && !!generateClaimUrl && !!helloSignRef)
      doSignOnLoad({
        matterId,
        helloSignRef,
        generateClaimUrl,
        sendForSigning,
      });
  }, [helloSignRef, generateClaimUrl, matterId, sendForSigning]);

  return (
    <div {...props}>
      {isLoading && <Spinner />}
      <FilePickerModal
        token={accessToken}
        rootFolderId={folderId}
        display={display}
        title="Select Files to Send for Signing"
        onClose={() => setDisplay(false)}
        onChoose={async (files) => {
          setIsLoading(true);
          await openSendForSigningModal({
            fileIds: files.map(({ id }) => id),
            matterId,
            helloSignRef,
            generateClaimUrl,
            sendForSigning,
          });
          setDisplay(false);
          setIsLoading(false);
        }}
        chooseButtonLabel="Send for Signing"
        showHeader={false}
        portal={document.body}
      />
    </div>
  );
}

SendForSigning.propTypes = {
  display: PropTypes.bool.isRequired,
  setDisplay: PropTypes.func.isRequired,
  folderId: PropTypes.string.isRequired,
  accessToken: PropTypes.string.isRequired,
  matterId: PropTypes.string.isRequired,
  generateClaimUrl: PropTypes.func.isRequired,
  sendForSigning: PropTypes.func.isRequired,
};
