import { useFeatureToggle } from '@flopflip/react-broadcast';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { flagNames } from '../../../featureFlags';
import { getUserId } from '../../../services/Utilities';
import Button from '../../standard/Button';
import { TypographyStyles } from '../../Typography';

const StyledBanner = styled.div`
  ${TypographyStyles.H6}
  background-color: ${({ state, theme }) =>
    state === 'PENDING'
      ? 'rgba(199, 236, 245, 0.3)'
      : theme.palette.feedback.success.extralight};
  padding: 7px 16px;
  margin-top: 0.5rem;
  border-radius: 4px;
  .approval-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
  }
`;

const ApproveButton = styled(Button)`
  font-size: 13px;
  font-weight: 300;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
  padding: 5px 11px;
`;

const UndoButton = styled(Button)`
  padding: 5px;
`;

const ViewMessageButton = styled(Button)`
  padding: 5px;
  margin-left: auto;
`;

const MessageBox = styled.div`
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.palette.shade.gray_4};
  border-radius: 4px;
  margin-top: 8px;
`;

function ReviewBanner({
  reviewRequests,
  approveReviewRequest,
  revokeReviewRequest,
}) {
  const userId = getUserId();
  const [pendingReview, setPendingReview] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const enableMatterReviews = useFeatureToggle(flagNames.MATTER_REVIEWS);

  useEffect(() => {
    setPendingReview(
      reviewRequests.find(
        ({ state, reviewer: { id: reviewerId } }) =>
          (state === 'PENDING' || state === 'APPROVED') &&
          reviewerId === userId,
      ),
    );
  }, [userId, reviewRequests]);

  if (!enableMatterReviews || !pendingReview) {
    return null;
  }

  const { _id: reviewRequestId, state, description: message } = pendingReview;

  const stateMessage =
    state === 'PENDING'
      ? 'Your review of this Matter has been requested.'
      : 'You have approved this Matter.';

  return (
    <StyledBanner state={state} data-testid="review-banner">
      <div className="approval-controls">
        <span>{stateMessage}</span>
        {state === 'PENDING' && (
          <ApproveButton onClick={() => approveReviewRequest(reviewRequestId)}>
            <FontAwesomeIcon icon={faCheck} />
            Approve
          </ApproveButton>
        )}
        {state === 'APPROVED' && (
          <UndoButton pure onClick={() => revokeReviewRequest(reviewRequestId)}>
            Undo
          </UndoButton>
        )}
        {message && (
          <ViewMessageButton pure onClick={() => setShowMessage(!showMessage)}>
            {showMessage ? 'Hide message' : 'View message'}
          </ViewMessageButton>
        )}
      </div>
      {message && showMessage && <MessageBox>{message}</MessageBox>}
    </StyledBanner>
  );
}

export default ReviewBanner;
