import { ReconfigureFlopFlip } from '@flopflip/react-broadcast';
import axios from 'axios';
import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeContext } from 'styled-components/macro';

import SplashImage from '../../../components/SplashImage';
import Spinner from '../../../components/standard/Spinner';
import AuthUtility from '../../../services/AuthUtility';
import { RegisterBox } from '../components/RegisterBox';
import SmallScreenLogo from '../components/SmallScreenLogo';

const buildRedirectLink = (firm, document) => {
  const onboardingQueryString = {
    onboardingFlow: true,
    firm,
  };

  return document
    ? `/client/matter/${document}`
    : `/new-contract?${queryString.stringify(onboardingQueryString)}`;
};

export function ClientRegisterScreen() {
  const { search } = useLocation();
  const {
    email: queryEmail,
    firm,
    firmUserId,
    document,
    organization: queryOrganization,
    redirectPath,
    // TODO: Remove `registerPrimaryContact` eventually. It's not used in new invite urls
    registerPrimaryContact,
    invitation: invitationId,
  } = queryString.parse(search);

  const [loading, setLoading] = useState(!!invitationId);
  const [invitation, setInvitation] = useState();

  useEffect(() => {
    async function fetchInvitation() {
      if (!invitationId) return;
      try {
        setLoading(true);
        const response = await axios.get(
          `/api/v1/organizationuserinvitations/${invitationId}`,
        );
        setInvitation(response.data.organizationUserInvitation);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
    fetchInvitation();
  }, [invitationId]);

  const invitationEmail = invitation?.invitationContact.email;
  const invitationOrganization = invitation?.organization;
  const invitationContract = invitation?.contract;

  const loginParams = {
    document,
    email: invitationEmail || queryEmail,
    firm,
    redirectPath,
  };
  const loginLink = `/login?${queryString.stringify(loginParams)}`;
  const redirectLink = redirectPath
    ? encodeURIComponent(redirectPath)
    : encodeURIComponent(
        buildRedirectLink(firm, document ?? invitationContract),
      );

  const theme = useContext(ThemeContext);
  // Clear localstorage in case a lawyer/full user is testing client registration
  useEffect(() => AuthUtility.clearStorage(), []);

  if (loading || (invitationId && !invitation)) {
    return <Spinner />;
  }

  return (
    <main>
      {firm && (
        <ReconfigureFlopFlip
          user={{
            key: `client-registration-${firm}`,
            custom: { firm, userType: 'client' },
          }}
        />
      )}
      <SmallScreenLogo />
      <div className="row no-gutters min-h-fullscreen bg-white">
        <SplashImage />
        <RegisterBox
          userType="client"
          email={invitationEmail || queryEmail}
          heading="First, Let's Set Up Your Account"
          subheading={theme.registrationSubheading}
          firm={firm}
          firmUserId={firmUserId}
          invitation={invitation}
          loginLink={loginLink}
          redirectPath={redirectLink}
          organizationId={invitationOrganization || queryOrganization}
          registeringWithExistingOrganization={
            registerPrimaryContact || !!invitationId
          }
        />
      </div>
    </main>
  );
}
