import { useFeatureToggle } from '@flopflip/react-broadcast';
import React, { useState } from 'react';

import { flagNames } from '../../../featureFlags';
import { trackShareWithClient } from '../../../services/Analytics';
import { Textarea } from '../../forms/FormStyles';
import Checkbox from '../../standard/Checkbox';
import Modal from '../../standard/Modal';

const ShareWithClientModal = ({
  display,
  onClose,
  contract,
  grantClientAccess,
  portal,
}) => {
  const [notifyByEmail, setNotifyByEmail] = useState(true);
  const [matterSharedSent, setMatterSharedSent] = useState(false);
  const [shareWithClientMessage, setShareWithClientMessage] = useState('');

  const handleNotifyByEmailChange = () => setNotifyByEmail(!notifyByEmail);
  const enableClientMatterManagement = useFeatureToggle(
    flagNames.ENABLE_CLIENT_MATTER_MANAGEMENT,
  );

  if (!contract.organization) {
    return null;
  }

  return (
    <>
      <Modal
        display={display}
        title="Share With Client"
        showCancel
        onClose={onClose}
        onCancel={() => {
          onClose();
          setShareWithClientMessage('');
        }}
        onAccept={async () => {
          trackShareWithClient();
          await grantClientAccess(notifyByEmail, shareWithClientMessage);
          setMatterSharedSent(true);
          onClose();
        }}
        acceptText="Confirm"
        maxWidth="700px"
        portal={portal}
      >
        <p>
          {enableClientMatterManagement
            ? 'By sharing this Matter with your client, they will be permitted to make modifications and resubmit questionnaire responses which may overwrite manual revisions.'
            : 'By sharing this Matter with your client, they will be granted the ability to view, download, and upload associated files.'}
        </p>
        <Checkbox
          label="Notify client by email"
          checked={notifyByEmail}
          onChange={handleNotifyByEmailChange}
        />
        {notifyByEmail && (
          <>
            <p className="mt-3">Message (optional):</p>
            <Textarea
              as="textarea"
              rows="4"
              autoComplete="no"
              style={{ fontSize: '14px', marginTop: 0 }}
              value={shareWithClientMessage}
              onChange={(e) => setShareWithClientMessage(e.target.value)}
            />
          </>
        )}
      </Modal>
      <Modal
        display={matterSharedSent}
        title={`Matter Shared with ${contract.organization.name}`}
        message={
          notifyByEmail
            ? `Your client has been notified and granted access to the matter.`
            : `Your client has been granted access to the matter.`
        }
        cancelText="Close"
        hideAccept
        showCancel
        onCancel={() => setMatterSharedSent(false)}
        portal={portal}
      />
    </>
  );
};

export default ShareWithClientModal;
