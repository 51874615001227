import $ from 'jquery';
import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { DefaultWebStyle } from '../../services/ContractStyles';
import Card from '../standard/Card';

const Paper = styled(Card)`
  padding: 100px;
  margin-bottom: 30px;
  border: 1px solid #f5f6fa;
  min-height: 1032px;
`;

const splitPages = (contractBody) => {
  const pages = [];
  let currentPage = '';
  const doc = $.parseHTML(contractBody);
  doc.forEach((element) => {
    if (element.className) {
      if (element.className.includes('Section')) {
        const section = $.parseHTML(element.innerHTML);
        section.forEach((sectionElement) => {
          if (
            sectionElement.className &&
            sectionElement.className.includes('html2pdf__page-break')
          ) {
            pages.push(currentPage);
            currentPage = '';
          }
          const sectionContent =
            sectionElement.outerHTML || sectionElement.wholeText;
          if (sectionContent) currentPage += sectionContent;
        });
      }
      if (element.className.includes('html2pdf__page-break')) {
        pages.push(currentPage);
        currentPage = '';
      }
    }
    const content = element.outerHTML || element.wholeText;
    if (content) currentPage += content;
  });
  pages.push(currentPage);
  return pages;
};

const ContractPaper = ({ html }) => {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    if (html) setPages(splitPages(html));
  }, [html]);

  return (
    <div className="contract" id="contract" data-testid="contract">
      <style>{DefaultWebStyle}</style>
      {pages.map((page, index) => (
        // Disable no-array-index-key since these elements will not be updated.
        // eslint-disable-next-line react/no-array-index-key
        <Paper key={index} dangerouslySetInnerHTML={{ __html: page }} />
      ))}
    </div>
  );
};

export default memo(ContractPaper);
