import axios from 'axios';
import isEmpty from 'lodash/isEmpty';

export default class OrgManager {
  constructor(org) {
    this.org = org == null ? {} : org;
    this.newOrg = {};
  }

  getId = () => this.org._id;

  getName = () => this.newOrg.name ?? this.org.name;

  getMailingAddress = () =>
    this.newOrg.mailingAddress ?? this.org.mailingAddress;

  updateName = (name) => {
    this.newOrg.name = name;
  };

  updateMailingAddress = (address) => {
    this.newOrg.mailingAddress = address;
  };

  saveOrgUpdates = () => {
    if (isEmpty(this.newOrg)) return Promise.resolve();
    if (!this.org._id) return Promise.resolve();
    const newCompanyInformation = this.newOrg;
    return axios.post('/graphql', {
      operationName: 'updateCompanyInformationFromDocument',
      variables: { newCompanyInformation, companyId: this.org._id },
      query: `mutation updateCompanyInformationFromDocument(
          $newCompanyInformation: UpdateByIdOrganizationInput!
          $companyId: MongoID!
        ) {
          updateOrganization(_id: $companyId, record: $newCompanyInformation) {
            record {
              _id
            }
          }
        }
      `,
    });
  };
}
