import React from 'react';
import { withTheme } from 'styled-components/macro';

import SplashImage from '../../../components/SplashImage';

const IsolatedTerms = ({ theme }) => {
  const Terms = theme.registrationTerms;
  return (
    <div className="row no-gutters min-h-fullscreen bg-white">
      <SplashImage className="col-lg-4 col-xl-4 d-md-block bg-img" />
      <div className="col-lg-8 col-xl-8 mb-10 mt-20">
        <Terms hideFinish hideDisagree />
      </div>
    </div>
  );
};

export default withTheme(IsolatedTerms);
