import React from 'react';
import styled from 'styled-components/macro';

import Button from './Button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from './Dialog';

const FooterControl = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 30px;
`;

const FooterButton = styled(Button)`
  font-size: 14px;
  min-width: 96px;
  height: 40px;
`;

const StyledDialogContent = styled(DialogContent)`
  margin-top: 1rem;
  font-size: 14px;
`;

export function ConfirmationDialog({
  open,
  onClose,
  onConfirm,
  confirmButtonPalette,
  title = 'Confirm',
  message = 'Are you sure you want to continue?',
  confirmText = 'Yes',
  cancelText = 'Cancel',
  maxWidth = 'sm',
}) {
  return (
    <Dialog open={open} maxWidth={maxWidth} onClose={onClose} fullWidth>
      <DialogHeader>
        <DialogTitle>{title}</DialogTitle>
      </DialogHeader>
      <StyledDialogContent>{message}</StyledDialogContent>
      <DialogFooter>
        <FooterControl>
          {!!cancelText && (
            <FooterButton pure onClick={onClose} paletteType="gray">
              {cancelText}
            </FooterButton>
          )}
          <FooterButton onClick={onConfirm} paletteType={confirmButtonPalette}>
            {confirmText}
          </FooterButton>
        </FooterControl>
      </DialogFooter>
    </Dialog>
  );
}
