import isEmpty from 'lodash/isEmpty';

const EMAIL_PATTERN_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function disableBackButton() {
  // eslint-disable-next-line no-restricted-globals
  history.pushState(null, null, location.href);
  window.onpopstate = () => {
    // eslint-disable-next-line no-restricted-globals
    history.go(1);
  };
}

export function toBool(value) {
  if (typeof value === 'boolean') {
    return value;
  }

  if (typeof value === 'string') {
    return !!value.match(/^(y|t|yes|true|on)/i);
  }

  return !!value;
}

export function isUnfilled(value) {
  return (
    value === '' ||
    value == null ||
    isEmpty(value) ||
    Object.values(value).every(isEmpty)
  );
}

export function isE2ETest() {
  return !!window.Cypress;
}

export function userIsClient() {
  return localStorage.getItem('userType') === 'client';
}

export function userIsRallyAdmin() {
  return localStorage.getItem('rallyAdmin') === 'true';
}

export function userIsFirmAdmin() {
  return localStorage.getItem('firmAdmin') === 'true';
}

export function getUserEmail() {
  return localStorage.getItem('email');
}

export function stripIdpFromUserId(userId) {
  return userId.replace(/^.*\|/, '');
}

export function getUserId() {
  return localStorage.getItem('userId');
}

export function getStrippedUserId() {
  const userId = getUserId();
  return userIsClient() ? stripIdpFromUserId(userId) : userId;
}

export function getFirmName() {
  return localStorage.getItem('firmName');
}

export function objectFromEntries(entries) {
  const returnObject = {};
  entries.forEach(([k, v]) => {
    returnObject[k] = v;
  });
  return returnObject;
}

export function downloadFile(fileName, urlData) {
  const aLink = document.createElement('a');
  aLink.download = fileName;
  aLink.href = urlData;

  const event = new MouseEvent('click');
  aLink.dispatchEvent(event);
}

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// From https://stackoverflow.com/a/12900504
export const getFileExtension = (name) =>
  // eslint-disable-next-line no-bitwise
  name?.slice(((name.lastIndexOf('.') - 1) >>> 0) + 2);

export const readFileAsArrayBuffer = (file) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
    fileReader.readAsArrayBuffer(file);
  });

export function validateEmail(email) {
  return EMAIL_PATTERN_REGEX.test(email);
}

export function stripTypenames(graphqlData) {
  const omitTypename = (key, value) =>
    key === '__typename' ? undefined : value;
  return JSON.parse(JSON.stringify(graphqlData), omitTypename);
}
