import React, {
  forwardRef,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import Autosuggest from 'react-autosuggest';

import useThrottledOnScroll from '../../hooks/useThrottledOnScroll';
import { AutosuggestStyle } from './FormStyles';

const AutoSuggest = forwardRef(
  (
    {
      errors,
      placeholder,
      onChange,
      onFocus,
      onBlur,
      onSelect,
      value,
      getSuggestions,
      smallDropdown,
      labelledby,
      disabled,
      maxHeightPx = '200',
      smallfield,
    },
    ref,
  ) => {
    const styleRef = useRef();

    const [suggestions, setSuggestions] = useState([]);
    const [navBottom, setNavBottom] = useState();
    const updateNavBottom = useCallback(() => {
      const { bottom } = styleRef.current.getBoundingClientRect();
      const newBottom = Math.max(0, bottom);
      if (newBottom !== navBottom) {
        setNavBottom(newBottom);
      }
    }, [navBottom]);

    useLayoutEffect(updateNavBottom, [updateNavBottom]);
    useThrottledOnScroll(updateNavBottom, 0);

    const inputProps = {
      placeholder: placeholder || '',
      onChange,
      onFocus,
      onBlur,
      value,
      'aria-labelledby': labelledby,
      type: 'search',
      disabled,
      autoComplete: 'chrome-off',
    };
    return (
      <AutosuggestStyle
        ref={styleRef}
        errors={errors}
        smallDropdown={smallDropdown}
        disabled={disabled}
        dropUp={window.innerHeight - navBottom <= maxHeightPx}
        maxHeight={`${maxHeightPx}px`}
        smallfield={smallfield}
      >
        <Autosuggest
          ref={ref}
          placeholder={placeholder}
          suggestions={suggestions}
          onSuggestionsFetchRequested={({ value: input }) => {
            setSuggestions(getSuggestions(input));
          }}
          onSuggestionsClearRequested={() => setSuggestions([])}
          onSuggestionSelected={onSelect}
          getSuggestionValue={(suggestion) => suggestion.label}
          shouldRenderSuggestions={() => true}
          renderSuggestion={(suggestion) => <>{suggestion.label}</>}
          inputProps={inputProps}
          highlightFirstSuggestion
          disabled={disabled}
        />
      </AutosuggestStyle>
    );
  },
);

export default AutoSuggest;
