import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

const ThemedLink = styled(Link)`
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: 500 !important;
  border-bottom: none !important;
  &:hover {
    color: ${(props) => props.theme.palette.primary.light};
  }
`;

const StyledLink = ({ to, children }) => (
  <ThemedLink to={to}>{children}</ThemedLink>
);

export default StyledLink;
