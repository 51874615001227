import launchDarklyAdapter from '@flopflip/launchdarkly-adapter';
import localAdapter from '@flopflip/localstorage-adapter';
import {
  ConfigureFlopFlip,
  useAdapterReconfiguration,
} from '@flopflip/react-broadcast';
import get from 'lodash/get';
import React, { useEffect, useMemo, useState } from 'react';

import { useUser } from './context/UserContext';
import { useQueryParam } from './hooks/useQueryParam';
import { isE2ETest, objectFromEntries } from './services/Utilities';

const flags = {
  // ---------------
  // Entitlements
  // ---------------
  ACCESS_MATTER_WORKSPACE: {
    name: 'entitleAccessMatterWorkspace',
    default: true,
    envDefaults: {},
    description: 'Entitlement that allows users to open the matter workspace',
  },
  // ---------------
  // Permanent Flags
  // ---------------
  LOGIN_DANGER_BANNER: {
    name: 'loginDangerBanner',
    default: 'null',
    envDefaults: {},
    description: 'A text blob to show in a danger banner on the login page.',
  },
  HOTJAR: {
    name: 'hotjarId',
    default: 'none',
    envDefaults: {},
    description: 'The ID to connect to hotjar for analytics.',
  },
  RFB_PRICE_DATA: {
    name: 'rfbPriceData',
    default: {},
    description: 'The price data for RFB signup.',
  },
  ALLOW_SKIP_PAYMENT: {
    name: 'allowSkipPayment',
    default: true,
    envDefaults: {},
    description:
      'Allow clients to skip payment when completing service questionnaires.',
  },
  BOX_APP_HOSTNAME: {
    name: 'boxAppHostname',
    default: 'https://app.box.com',
    envDefaults: {},
    description: 'Protocol and hostname to use to connect to box app',
  },
  BOX_API_HOSTNAME: {
    name: 'boxApiHostname',
    default: 'https://api.box.com',
    envDefaults: {},
    description: 'Protocol and hostname to use to connect to box api',
  },
  BOX_STATIC_HOSTNAME: {
    name: 'boxStaticHostname',
    default: 'https://cdn01.boxcdn.net',
    envDefaults: {},
    description: 'Protocol and hostname to use to connect to box static cdn',
  },
  BOX_UPLOAD_HOSTNAME: {
    name: 'boxUploadHostname',
    default: 'https://upload.box.com',
    envDefaults: {},
    description: 'Protocol and hostname to use to connect to box upload api',
  },
  COLLECT_PHONE_NUMBER_ON_REGISTRATION: {
    name: 'collectPhoneNumberOnRegistration',
    default: false,
    envDefaults: {},
    description: 'Collect client users phone numbers on registration',
  },
  CLIENT_CREATE_BLANK_MATTER: {
    name: 'clientCreateBlankMatter',
    default: true,
    envDefaults: { development: true, review: true },
    description: 'Allows clients to create new blank matters',
  },
  ENABLE_REQUEST_LAWYER: {
    name: 'enableRequestLawyer',
    default: false,
    description: 'Enable the request lawyer button for client users',
  },
  ENABLE_REQUEST_FIRM_REVIEW: {
    name: 'enableRequestFirmReview',
    default: true,
    description: 'Enable the request firm review button for client users.',
  },
  CLIENT_TEMPLATES_TAB: {
    name: 'clientTemplatesTab',
    default: true,
    description: 'Enable client users to see the templates tab.',
  },
  // ---------------
  // Temporary Flags
  // ---------------
  ENABLE_FILES_SHORTCUT: {
    name: 'enableFilesShortcut',
    default: false,
    envDefaults: {},
    description: 'Display Files shortcut on dashboard',
  },
  ENABLE_CLIENT_MENU_OF_SERVICES: {
    name: 'enableClientMenuOfServices',
    default: true,
    envDefaults: {},
    description:
      'Allow clients to initiate a service through the New Matter button',
  },
  RFB: {
    name: 'rfb',
    default: false,
    envDefaults: { development: false, review: false },
    description: 'Display content relevant to RFB.',
  },
  ENABLE_CLIENT_MATTER_MANAGEMENT: {
    name: 'enableClientMatterManagement',
    default: false,
    envDefaults: { development: true, review: true },
    description: 'Allow clients to manage matters',
  },
  ENABLE_TEMPLATES_DOCUMENT_EDITOR: {
    name: 'enableTemplatesDocumentEditor',
    default: false,
    envDefaults: { development: true, review: true },
    description:
      'Show new SyncFusion Document Editor component in Template Editor',
  },
  TEMPLATE_EDITOR_ADVANCED_FIELDS_CREATOR: {
    name: 'templateEditorAdvancedFieldCreator',
    default: false,
    envDefaults: {},
    description:
      'Show advanced configuration (visibility conditions, type-specific inputs) for field creator in template editor',
  },
  ENABLE_EXPORT_TO_GOOGLE_DRIVE: {
    name: 'enableExportToGoogleDrive',
    default: false,
    envDefaults: { development: true, review: true },
    description: 'Show export to google drive action in matter actions list',
  },
  HUBSPOT_CONNECTION: {
    name: 'hubspotConnection',
    default: false,
    envDefaults: { development: true, review: true },
    description: 'Show "Connect to Hubspot" in client settings',
  },
  SALESFORCE_CONNECTION: {
    name: 'salesforceConnection',
    default: false,
    envDefaults: { development: true },
    description:
      'Show "Connect to Salesforce" in client (or personal?) settings',
  },
  SALESFORCE_CONTACT_IMPORT: {
    name: 'salesforceContactImport',
    default: false,
    envDefaults: { development: true },
    description:
      'Enables importing of Salesforce contact data when creating matters from Salesforce',
  },
  SALESFORCE_DATA_TABLES: {
    name: 'salesforceDataTables',
    default: false,
    envDefaults: { development: true },
    description: 'Enables linking of Data Table questions to Salesforce data',
  },
  MATTER_DOCUMENT_EDITOR: {
    name: 'matterDocumentEditor',
    default: false,
    envDefaults: { development: true, review: true },
    description: 'Show Syncfusion document editor on matter workspace',
  },
  ENABLE_SIGNATURE_STATUS: {
    name: 'enableSignatureStatus',
    default: false,
    envDefaults: { development: true, review: true },
    description: 'Show signature status in matter workspace',
  },
  ENABLE_DATAROOM: {
    name: 'enableDataroom',
    default: false,
    envDefaults: { development: true, review: true },
    description: 'Allow access to dataroom features.',
  },
  MATTER_REVIEWS: {
    name: 'matterReviews',
    default: false,
    envDefaults: { development: true, review: true },
    description: 'Show matter review / request approval features.',
  },
  DISPLAY_PRICE: {
    name: 'displayPrice',
    default: false,
    envDefaults: { development: true, review: true },
    description: 'Show the display price field for templates',
  },
  MATTER_FIELDS: {
    name: 'matterFields',
    default: false,
    envDefaults: { development: true, review: true },
    description: 'Enable Matter Fields',
  },
  PENDING_MATTER_SIGNATURE_STATUS: {
    name: 'pendingMatterSignatureStatus',
    default: false,
    envDefaults: { development: true, review: true },
    description: 'Enable Matter Pending Signature Status',
  },
  DOWNLOAD_ALL_FILES: {
    name: 'downloadAllFiles',
    default: false,
    envDefaults: { development: true, review: true },
    description: 'Enable "Download All" button in file repo',
  },
};

export const defaultFlags = objectFromEntries(
  Object.values(flags).map((flag) => [
    flag.name,
    get(flag.envDefaults, process.env.REACT_APP_ENVIRONMENT, flag.default),
  ]),
);

export const flagNames = objectFromEntries(
  Object.entries(flags).map(([key, flag]) => [key, flag.name]),
);

function getAdapter(bootstrap) {
  return process.env.REACT_APP_LAUNCH_DARKLY_ID && !isE2ETest()
    ? {
        adapter: launchDarklyAdapter,
        adapterArgs: {
          sdk: {
            clientSideId: process.env.REACT_APP_LAUNCH_DARKLY_ID,
            clientSideOptions: { bootstrap },
          },
        },
      }
    : { adapter: localAdapter, adapterArgs: { user: {} } };
}

function ReconfigureFlopFlip({ context, children }) {
  const reconfigure = useAdapterReconfiguration();

  useEffect(() => {
    reconfigure({ context });
  }, [context, reconfigure]);

  return <>{children}</>;
}

export function ConfigureFeatureFlags({ children }) {
  const [
    { initialFlags, organization, userId, email, userType, firm: userFirm },
  ] = useUser();

  const [firm] = useQueryParam('firm');
  const [utmCampaign] = useQueryParam('utm_campaign');
  const [lastUtmCampaign, setLastUtmCampaign] = useState(
    utmCampaign || localStorage.getItem('lastUtmCampaign'),
  );

  useEffect(() => {
    if (utmCampaign) {
      setLastUtmCampaign(utmCampaign);
      localStorage.setItem('lastUtmCampaign', utmCampaign);
    }
  }, [utmCampaign]);

  const context = useMemo(
    () => ({
      kind: 'user',
      key: userId || 'anonymousUser',
      email,
      firm: userFirm?._id || firm,
      organization: organization?._id,
      userType,
      activeSubscriptions:
        organization?.subscriptions
          ?.filter(({ status }) => status === 'ACTIVE')
          .map(({ product }) => product) ?? [],
      trialSubscriptions:
        organization?.subscriptions
          ?.filter(({ status }) => status === 'TRIAL')
          .map(({ product }) => product) ?? [],
      stripeRegion: organization?.stripe?.region,
      ...(lastUtmCampaign ? { lastUtmCampaign } : {}),
    }),
    [
      userId,
      email,
      userFirm?._id,
      firm,
      organization?._id,
      organization?.subscriptions,
      organization?.stripe?.region,
      userType,
      lastUtmCampaign,
    ],
  );

  const { adapter, adapterArgs } = getAdapter(initialFlags);
  return (
    <ConfigureFlopFlip
      defaultFlags={defaultFlags}
      adapter={adapter}
      adapterArgs={{
        ...adapterArgs,
        context,
      }}
    >
      {({ isAdapterConfigured }) =>
        isAdapterConfigured && (
          <ReconfigureFlopFlip context={context}>
            {children}
          </ReconfigureFlopFlip>
        )
      }
    </ConfigureFlopFlip>
  );
}
