import { gql, useMutation } from '@apollo/client';
import { useFlagVariation } from '@flopflip/react-broadcast';
import queryString from 'query-string';
import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';
import { handleException } from 'utils/ErrorUtils';

import { useOrganizationPermission } from '../../context/OrganizationPermissionContext';
import { flagNames } from '../../featureFlags';
import { trackViewRfbCheckout } from '../../services/Analytics';
import { googleTrackViewCheckout } from '../../services/GoogleTagManager';
import { ActionButton } from '../standard/Button';
import Spinner from '../standard/Spinner';

const StyledButton = styled(ActionButton)`
  font-size: 16px;
  padding: 11.5px 72px;
  margin-top: 12px;
`;

const CREATE_CHECKOUT_SESSION = gql`
  mutation createCheckoutSession(
    $input: CreateOrganizationSubscriptionCheckoutSessionInput!
  ) {
    createOrganizationSubscriptionCheckoutSession(input: $input) {
      record {
        url
      }
    }
  }
`;

export function SubscriptionCheckoutButton({
  label = 'Get Started',
  analyticsMetadata = {},
}) {
  const [createCheckoutSession, { loading }] = useMutation(
    CREATE_CHECKOUT_SESSION,
  );
  const { priceId, region, coupon } = useFlagVariation(
    flagNames.RFB_PRICE_DATA,
  );

  const { canManageBilling } = useOrganizationPermission();

  const onClick = useCallback(() => {
    const fetchUrlAndRedirect = async () => {
      try {
        trackViewRfbCheckout({ ...analyticsMetadata, priceId });
        googleTrackViewCheckout();

        const successUrl = new URL(
          `${window.location.protocol}//${
            window.location.host
          }/client/subscription/success?${queryString.stringify({
            redirectUrl: window.location.href,
          })}&session_id={CHECKOUT_SESSION_ID}`,
        );

        if (!priceId) {
          throw new Error('No price specified for RFB');
        }

        const { data } = await createCheckoutSession({
          variables: {
            input: {
              priceId,
              organizationId: localStorage.getItem('orgId'),
              successUrl,
              cancelUrl: window.location.href,
              coupon,
              region,
            },
          },
        });

        const url =
          data?.createOrganizationSubscriptionCheckoutSession?.record?.url;

        if (!url) {
          throw new Error('No checkout URL returned');
        }

        window.location.assign(url);
      } catch (error) {
        toast.error(
          'Oops! Something went wrong generating your checkout session. Please try again later.',
        );
        handleException(error);
      }
    };

    fetchUrlAndRedirect();
  }, [priceId, createCheckoutSession, analyticsMetadata, coupon, region]);

  return (
    <>
      {loading && <Spinner />}
      <StyledButton
        onClick={onClick}
        disabled={!canManageBilling}
        title={
          !canManageBilling
            ? "You don't have permission to manage billing"
            : undefined
        }
      >
        {label}
      </StyledButton>
    </>
  );
}
