import axios from 'axios';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { handleException } from 'utils/ErrorUtils';

import Autocomplete from '../forms/Autocomplete';
import { ErrorMessage } from '../forms/FormStyles';
import Spinner from '../standard/Spinner';

export function OrganizationDropdown({
  id,
  organizationId,
  setOrganizationId,
  showErrors,
  smallDropdown,
  disabled = false,
}) {
  const { search } = useLocation();
  const { organizationId: paramOrgId } = queryString.parse(search);

  const [isLoaded, setIsLoaded] = useState(false);
  const [defaultValue, setDefaultValue] = useState(undefined);
  const [organizations, setOrganizations] = useState([]);
  const [organization, setOrganization] = useState(null);
  const [usingOrgInQuery, setUsingOrgInQuery] = useState(false);
  const [error, setError] = useState(undefined);

  // Load organizations
  useEffect(() => {
    if (isLoaded) {
      return;
    }

    async function getOrganizations() {
      try {
        const results = await axios.get('/api/v1/firms/mine/organizations');
        setOrganizations(results?.data?.organizations ?? []);
      } catch (err) {
        if (err?.response?.status === 404) {
          // Do nothing
          return;
        }

        handleException(err);
      } finally {
        setIsLoaded(true);
      }
    }

    getOrganizations();
  }, [isLoaded]);

  // Set organization based on organizationId if different
  useEffect(() => {
    if (
      !organizationId ||
      !organizations?.length ||
      organization?._id === organizationId
    ) {
      return;
    }

    setOrganization(organizations.find(({ _id }) => _id === organizationId));
  }, [organizations, organization, organizationId]);

  // Set organization id based on organization
  useEffect(() => {
    if (!organization || organization?._id === organizationId) {
      return;
    }

    setOrganizationId(organization._id);
  }, [organization, organizationId, setOrganizationId]);

  useEffect(() => {
    if (!paramOrgId || !organizations?.length) {
      return;
    }

    const org = organizations.find(({ _id }) => _id === paramOrgId);
    setOrganization(org);
    setDefaultValue(org?.name);
  }, [paramOrgId, organizations]);

  useEffect(() => {
    setUsingOrgInQuery(!!paramOrgId && paramOrgId === organizationId);
  }, [paramOrgId, organizationId]);

  const handleChange = useCallback(
    (event, name) => {
      const newOrg = organizations.find((org) => org.name === name);
      if (newOrg) {
        setOrganization(newOrg);
        setError(undefined);
      } else if (!name) {
        setOrganization(null);
        setError(undefined);
      } else {
        setOrganization(undefined);
        setError(
          showErrors &&
            'This client is not yet registered. Select an existing client or clear the field above to continue.',
        );
      }
    },
    [organizations, showErrors],
  );

  if (isLoaded) {
    return (
      <div className="row">
        <div className="col-12">
          <Autocomplete
            id={id}
            placeholder={
              !organizations?.length
                ? 'No clients registered'
                : 'Enter client...'
            }
            defaultValue={defaultValue}
            options={organizations.map((org) => org.name)}
            onChange={handleChange}
            onBlur={(event) => handleChange(event, event.target.value)}
            errors={error && [error]}
            disabled={disabled || !organizations?.length || usingOrgInQuery}
            smallDropdown={smallDropdown}
          />
          {error && (
            <ErrorMessage>
              {
                'This client is not yet registered. Select an existing client or clear the field above to continue.'
              }
            </ErrorMessage>
          )}
        </div>
      </div>
    );
  }
  return <Spinner />;
}

OrganizationDropdown.propTypes = {
  organizationId: PropTypes.string,
  setOrganizationId: PropTypes.func.isRequired,
};
