import { useLazyQuery } from '@apollo/client';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { handleException } from 'utils/ErrorUtils';

import Spinner from '../components/standard/Spinner';
import { FETCH_ORGANIZATION_PERMISSION_FOR_USER } from '../graphql/organization-permission/queries';
import AuthUtility from '../services/AuthUtility';
import { useUser } from './UserContext';

const DEFAULT_CLIENT_USER_PERMISSIONS = {
  canAccessAllMatters: false,
  canAccessMatterCategories: [],
  canManageTeamMembers: false,
  canManagePermissions: false,
  canManageBilling: false,
  canCreateNewMatters: false,
  canModifyTemplates: false,
  canAccessInformationTab: false,
  canAccessFilesRepository: false,
};

const DEFAULT_FIRM_USER_PERMISSIONS = {
  canAccessAllMatters: true,
  canAccessMatterCategories: [],
  canManageTeamMembers: true,
  canManagePermissions: true,
  canManageBilling: true,
  canCreateNewMatters: true,
  canModifyTemplates: true,
  canAccessInformationTab: true,
  canAccessFilesRepository: true,
};

const DEFAULT_INACCESSIBLE_PERMISSIONS = {
  canAccessAllMatters: false,
  canAccessMatterCategories: [],
  canManageTeamMembers: false,
  canManagePermissions: false,
  canManageBilling: false,
  canCreateNewMatters: false,
  canModifyTemplates: false,
  canAccessInformationTab: false,
  canAccessFilesRepository: false,
};

export const OrganizationPermissionContext = createContext(
  DEFAULT_CLIENT_USER_PERMISSIONS,
);

export function OrganizationPermissionContextProvider({ children }) {
  const [permissions, setPermissions] = useState();

  const [{ userId, organization, userType }] = useUser();

  const [fetchOrganizationPermissionForUser] = useLazyQuery(
    FETCH_ORGANIZATION_PERMISSION_FOR_USER,
    {
      variables: {
        userId: userId?.replace(/^.*\|/, ''),
        organizationId: organization?._id,
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        setPermissions(data?.organizationPermission);
      },
      onError: (error) => handleException(error),
    },
  );

  useEffect(() => {
    if (!AuthUtility.isAuthenticated()) {
      setPermissions(DEFAULT_INACCESSIBLE_PERMISSIONS);
      return;
    }

    if (userType === 'client') {
      fetchOrganizationPermissionForUser();
      return;
    }

    setPermissions(DEFAULT_FIRM_USER_PERMISSIONS);
  }, [fetchOrganizationPermissionForUser, userType]);

  if (!permissions) {
    return <Spinner />;
  }

  return (
    <OrganizationPermissionContext.Provider value={permissions}>
      {children}
    </OrganizationPermissionContext.Provider>
  );
}

export function useOrganizationPermission() {
  return useContext(OrganizationPermissionContext);
}
