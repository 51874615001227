import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelect } from 'downshift';
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components/macro';

import { TypographyStyles } from '../../Typography';
import { FormInputBorder, FormInputHeight } from './Styles';

const StyledDropdownButton = styled.button.attrs({
  className: 'rally-dropdown-button',
})`
  ${FormInputBorder}
  ${FormInputHeight}
  ${TypographyStyles.P}
  ${TypographyStyles.Color}

  display: flex;
  justify-content: left;
  align-items: center;

  background-color: ${({ theme }) => theme.palette.base.white};
  min-width: 145px;

  :hover {
    border-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
  }

  :focus,
  :focus-visible {
    outline: ${({ theme }) => theme.palette.primary.main} solid;
  }

  .dropdown-icon {
    margin-left: auto;
  }
`;

const DropdownButton = forwardRef(({ children, isOpen, ...props }, ref) => (
  <StyledDropdownButton type="button" ref={ref} {...props}>
    {children}
    <FontAwesomeIcon
      className="dropdown-icon"
      icon={isOpen ? faChevronUp : faChevronDown}
    />
  </StyledDropdownButton>
));

const DropdownCard = styled.div.attrs({
  className: 'rally-dropdown-card',
})`
  position: absolute;
  right: 0;
  z-index: 1000;
  width: 100%;
  word-wrap: break-word;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.06);
  padding: 0 0 8px 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: ${({ theme }) => theme.palette.base.white};

  ${({ isOpen }) =>
    !isOpen &&
    css`
      display: none;
    `}
`;

const DropdownContainer = styled.div.attrs({
  clasName: 'rally-dropdown-container',
})`
  position: relative;
`;

const DropdownMenu = styled.ul.attrs({
  className: 'rally-dropdown-menu',
})`
  max-height: 200px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  list-style: none;

  :focus,
  :focus-visible {
    outline: ${({ theme }) => theme.palette.primary.main} solid;
  }
`;

const DropdownMenuItem = styled.li.attrs({
  className: 'rally-dropdown-menu-item',
})`
  ${TypographyStyles.Color}
  ${TypographyStyles.P}
  padding: 8px;
  background-color: ${({ theme, highlighted }) =>
    highlighted ? theme.palette.shade.gray_5 : theme.palette.base.white};
  padding-left: ${({ variant }) => (variant === 'narrow' ? '8px' : '16px')};
`;

function Dropdown({
  id,
  onChange,
  items,
  initialSelectedItem,
  itemToString = (i) => i,
  ...props
}) {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    id,
    items,
    itemToString,
    initialSelectedItem,
    onSelectedItemChange: ({ selectedItem: value }) => onChange(value),
  });
  return (
    <DropdownContainer id={id} {...props}>
      <DropdownButton isOpen={isOpen} {...getToggleButtonProps()}>
        {selectedItem && itemToString(selectedItem)}
      </DropdownButton>

      <DropdownCard isOpen={isOpen}>
        <DropdownMenu {...getMenuProps()}>
          {items.map((item, index) => (
            <DropdownMenuItem
              key={itemToString(item)}
              highlighted={highlightedIndex === index}
              {...getItemProps({ item, index, variant: item.variant })}
            >
              {itemToString(item)}
            </DropdownMenuItem>
          ))}
        </DropdownMenu>
      </DropdownCard>
    </DropdownContainer>
  );
}

export default Dropdown;
