// https://gist.github.com/iDVB/a041da210605f05e0b36ac03ed403c00
import throttle from 'lodash/throttle';
import { useEffect, useMemo } from 'react';

const noop = () => {};

const useThrottledOnScroll = (callback, delay) => {
  const throttledCallback = useMemo(
    () => (callback ? throttle(callback, delay) : noop),
    [callback, delay],
  );

  useEffect(() => {
    if (throttledCallback === noop) return undefined;

    window.addEventListener('scroll', throttledCallback);
    return () => {
      window.removeEventListener('scroll', throttledCallback);
      throttledCallback.cancel();
    };
  }, [throttledCallback]);
};

export default useThrottledOnScroll;
