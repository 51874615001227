import { gql, useApolloClient, useQuery } from '@apollo/client';
import { useFeatureToggle } from '@flopflip/react-broadcast';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';
import { handleException } from 'utils/ErrorUtils';

import { flagNames } from '../../../featureFlags';
import { userIsClient } from '../../../services/Utilities';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '../../standard/Dialog';
import Spinner from '../../standard/Spinner';
import { Tab, TabPanel, Tabs } from '../../standard/Tabs';
import { ClientFirmsDialogContent } from './ClientFirmsDialogContent';
import { ClientMembersDialogContent } from './ClientMembersDialogContent';
import { CLIENT_TEAM_FRAGMENT } from './Fragments';
import { LegalTeamDialogContent } from './LegalTeamDialogContent';

const StyledTabs = styled(Tabs)`
  margin: 10px 0;
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-scrollPaper {
    align-items: flex-start;
  }
`;

const StyledDialogHeader = styled(DialogHeader)`
  overflow: hidden;
  h4 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const FIRM_GET_CLIENT_COLLABORATORS = gql`
  query getClientCollaborators(
    $userId: String!
    $firmId: MongoID!
    $clientId: MongoID!
  ) {
    firmUser(id: $userId) {
      id
      firm(id: $firmId) {
        _id
        name
        firmUsers {
          id
          email
        }
        clientTeam(org: $clientId) {
          ...OrgTeamInformation
        }
        client(org: $clientId) {
          _id
          name
          clientUsers {
            _id
            email
          }
          pendingUserInvitations {
            _id
            expiresAt
            invitationContact {
              email
            }
          }
        }
      }
    }
  }
  ${CLIENT_TEAM_FRAGMENT}
`;

export const CLIENT_GET_CLIENT_COLLABORATORS = gql`
  query getClientCollaborators($userId: MongoID!) {
    client(_id: $userId) {
      organizationDetails {
        _id
        name
        clientUsers {
          _id
          email
        }
        clientFirms {
          _id
          name
        }
        pendingUserInvitations {
          _id
          expiresAt
          invitationContact {
            email
          }
        }
      }
    }
  }
`;

export const TEAM_MODAL_TABS = {
  clients: 0,
  legalTeam: 1,
};

export const ClientTeamMembersModal = ({
  clientId: clientIdProp,
  open,
  onClose = () => {},
  defaultActiveTab = TEAM_MODAL_TABS.legalTeam,
}) => {
  const firmId = localStorage.getItem('firmId');
  const userId = localStorage.getItem('userId');

  const [initialFetchComplete, setInitialFetchComplete] = useState(false);
  const [clientId, setClientId] = useState(clientIdProp);
  const [clientTeam, setClientTeam] = useState();
  const [firmName, setFirmName] = useState();
  const [firmUsers, setFirmUsers] = useState([]);
  const [clientName, setClientName] = useState([]);
  const [clientUsers, setClientUsers] = useState([]);
  const [clientFirms, setClientFirms] = useState([]);
  const [pendingClientInvitations, setPendingClientInvitations] = useState([]);
  const [activeTab, setActiveTab] = useState(defaultActiveTab);

  const isRFB = useFeatureToggle(flagNames.RFB);

  const apolloClient = useApolloClient();

  const { refetch: firmRefetch } = useQuery(FIRM_GET_CLIENT_COLLABORATORS, {
    variables: { userId, firmId, clientId },
    onCompleted: (data) => {
      setClientTeam(data?.firmUser?.firm?.clientTeam);
      setFirmName(data?.firmUser?.firm?.name);
      setFirmUsers(data?.firmUser?.firm?.firmUsers ?? []);
      setClientName(data?.firmUser?.firm?.client?.name);
      setClientUsers(data?.firmUser?.firm?.client?.clientUsers ?? []);
      setPendingClientInvitations(
        data?.firmUser?.firm?.client?.pendingUserInvitations ?? [],
      );
      setInitialFetchComplete(true);
    },
    onError: (error) => {
      toast.error(
        'Oops! Something went wrong fetching the client team members. Please try again later.',
      );
      onClose();
      handleException(error);
    },
    skip: userIsClient(),
  });

  const { refetch: clientRefetch } = useQuery(CLIENT_GET_CLIENT_COLLABORATORS, {
    variables: { userId: userId.replace(/^.*\|/, '') },
    onCompleted: (data) => {
      setClientId(data?.client?.organizationDetails?.[0]?._id);
      setClientName(data?.client?.organizationDetails?.[0]?.name);
      setClientUsers(data?.client?.organizationDetails?.[0]?.clientUsers ?? []);
      setClientFirms(data?.client?.organizationDetails?.[0]?.clientFirms ?? []);
      setPendingClientInvitations(
        data?.client?.organizationDetails?.[0]?.pendingUserInvitations ?? [],
      );
      setInitialFetchComplete(true);
    },
    onError: (error) => {
      toast.error(
        'Oops! Something went wrong fetching the client team members. Please try again later.',
      );
      onClose();
      handleException(error);
    },
    skip: !userIsClient(),
  });

  useEffect(() => {
    if (apolloClient && clientTeam && clientId) {
      apolloClient.cache.modify({
        id: apolloClient.cache.identify({
          _id: clientId,
          __typename: 'Organization',
        }),
        fields: {
          firmTeam() {
            return clientTeam;
          },
        },
      });
    }
  }, [apolloClient, clientTeam, clientId]);

  if (!initialFetchComplete) {
    return <Spinner />;
  }

  const showLegalTeam = !isRFB;

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <StyledDialogHeader>
        <DialogTitle>Team Members</DialogTitle>
      </StyledDialogHeader>
      <DialogContent>
        {showLegalTeam && (
          <StyledTabs>
            <Tab
              id="clients-tab"
              controls="clients-tab-panel"
              isActive={activeTab === TEAM_MODAL_TABS.clients}
              onClick={() => setActiveTab(TEAM_MODAL_TABS.clients)}
            >
              Organization Members
            </Tab>
            <Tab
              id="legal-team-tab"
              controls="legal-team-tab-panel"
              isActive={activeTab === TEAM_MODAL_TABS.legalTeam}
              onClick={() => setActiveTab(TEAM_MODAL_TABS.legalTeam)}
            >
              Legal Team
            </Tab>
          </StyledTabs>
        )}
        <TabPanel
          id="clients-tab-panel"
          labelledBy="clients-tab"
          isActive={activeTab === TEAM_MODAL_TABS.clients}
        >
          <ClientMembersDialogContent
            clientId={clientId}
            clientName={clientName}
            clientUsers={clientUsers}
            pendingInvitations={pendingClientInvitations}
            refetch={userIsClient() ? clientRefetch : firmRefetch}
          />
        </TabPanel>
        {showLegalTeam && (
          <TabPanel
            id="legal-team-tab-panel"
            labelledBy="legal-team-tab"
            isActive={activeTab === TEAM_MODAL_TABS.legalTeam}
          >
            {clientTeam && firmName && (
              <LegalTeamDialogContent
                clientId={clientId}
                clientName={clientName}
                clientTeam={clientTeam}
                firmName={firmName}
                firmUsers={firmUsers}
                clientUsers={[]}
                onClose={onClose}
              />
            )}
            {!!clientFirms.length && (
              <ClientFirmsDialogContent firms={clientFirms} />
            )}
          </TabPanel>
        )}
      </DialogContent>
    </StyledDialog>
  );
};

ClientTeamMembersModal.propTypes = {
  open: PropTypes.bool,
  clientId: PropTypes.string,
  onClose: PropTypes.func,
  defaultActiveTab: PropTypes.oneOf(Object.values(TEAM_MODAL_TABS)),
};
