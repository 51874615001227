export const Rally2018 = `
/*** Original Rally Styles ***/
.contract-card {
  padding: 10%;
}

.contract h1 {
  font-size: 18px;
  font-family: Arial, serif;
  font-weight: normal;
  margin-bottom: 24px;
}

.contract h2, .contract ol, .contract ul {
  font-size: 14px;
  font-family: Arial, serif;
  font-weight: bold;
  margin-bottom: 0;
}

.contract h2 {
  margin-top: 20px;
  letter-spacing: 0;
}

.contract h3 {
  font-size: 14px;
  font-family: Arial, serif;
  font-weight: bold;
  font-style: italic;
  margin-bottom: 6px;
  margin-top: 24px;
}

.contract h4 {
  margin-top: 0;
  font-size: 14px;
  font-family: Arial, serif;
  font-weight: bold;
}

.contract ol {
  margin-left: 24px;
  padding-left: 0;
  padding-right: 18px;
}

.contract p {
  font-size: 14px;
  font-family: Arial, serif;
  line-height: 25px;
  font-weight: normal;
  margin-bottom: 16px;
  margin-top: 6px;
}

.contract li {
  margin-bottom: 24px;
  margin-left: 0;
  padding-left: 6px;
  line-height: 25px;
}

ol.terms {
  list-style-type: lower-alpha;
  counter-reset: alpha;
  font-size: 14px;
  font-family: Arial, serif;
  line-height: 25px;
  font-weight: normal;
  padding-right: 16px;
  padding-left: 0;
}

ol.terms li {
  list-style: none;
  position: relative;
  margin-bottom: 6px;
  margin-top: 6px;
}

ol.numbered {
  font-size: 14px;
  font-family: Arial, serif;
  line-height: 25px;
  font-weight: normal;
  padding-right: 16px;
  padding-left: 0;
}

ol.numbered li {
  list-style-type: decimal;
  position: relative;
  margin-bottom: 6px;
  margin-top: 6px;
}

ol.sub-terms {
  list-style-type: lower-roman;
}

li.sub-terms {
  font-weight: normal !important;
  font-family: Arial, serif;
}

table.signature-block {
  border: 0;
  padding: 0;
  width: 500px;
}

td {
  font-family: Arial, serif;
  font-size: 14px;
  padding: 0;
  border: 0;
}

td.signature-field-top {
  border: 0;
  border-bottom: 1px solid black;
  padding: 0;
  width: 230px;
}

td.signature-field-bottom {
  border: 0;
  padding: 0;
  width: 230px;
}

td.signature-field-spacer {
  border: 0;
  width: 40px;
}

.contract .field { /* Old signature fields don't convert to Word - remove eventually */
  border:0;
  border-top: 1px solid #000;
  margin-top: 48px;
  width: 100%;
  display: inline-block;
  font-family: Arial, serif;
  font-size: 14px;
  margin-bottom:48px;
}

.representative { /* Old signature fields don't convert to Word - remove eventually */
  font-size: 14px !important;
}

.highlight {
  color: fuchsia;
}

/*** Styles Developed to Support Todd's Documents ***/
.page-break {
  page-break-before: always;
}

h1.page-break-document-heading-small {
  page-break-before: always;
  font-size: 14px;
  font-family: Arial, serif;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 16px;
}

h1.document-heading-small {
  font-size: 14px;
  font-family: Arial, serif;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 16px;
}

h1.document-heading-large {
  font-size: 18px;
  font-family: Arial, serif;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 16px;
}

h2.document-subheading-small {
  font-family: Arial, serif;
  font-weight: normal;
  text-align: center;
  margin-top: 0px;
}

h2.article-heading-small {
  font-size: 14px;
  font-family: Arial, serif;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0px;
  margin-top:0px;
}

h2.article-heading-small-underlined {
  font-size: 14px;
  font-family: Arial, serif;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0px;
  margin-top:0px;
  text-decoration: underline;
}

h2.section-heading {
  text-transform: uppercase;
}

h2.section-heading-page-break {
  text-transform: uppercase;
  page-break-before: always;
}

p.indented {
  text-indent: 46pt;
}

p.indented-paragraph {
  margin-left: 46pt;
}

p.numbered-list-indent {
  margin-left: 32pt;
  text-align:justify;
  text-justify: inter-ideograph;
  text-indent:-32.0pt;
}

h2.numbered-list-indent {
  margin-left: 32pt;
  text-align:justify;
  text-justify: inter-ideograph;
  text-indent:-32.0pt;
  text-transform: uppercase;
  font-weight: bold; 
}

ol.sections {
  font-family: Arial, serif;
  font-weight: normal;
  font-size: 14px;
}

ul.sections {
  font-family: Arial, serif;
  font-weight: normal;
  font-size: 14px;
}

ol.subsections {
  font-weight: normal;
  list-style-type: lower-alpha;
  counter-reset: alpha;
  margin-bottom: 6px;
  margin-top: 6px;
  font-size: 14px;
  margin-left: 46pt;
}

ol.subsections li {
  margin-bottom: 16px;
  font-family: Arial, serif;
  list-style-type: lower-alpha;
}

h2.subsection-heading-1 {
  font-weight: bold;
  font-family: Arial, serif;
  margin-top: 16px;
}

ol.roman-numerals {
  font-weight: normal;
  list-style-type: lower-roman;
}

ol.roman-numerals li {
  margin-bottom: 16px;
  font-family: Arial, serif;
  list-style-type: lower-roman;
}

table.data-table {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 3px;
  font-family: Arial, serif;
}

table.top-aligned tbody {
  vertical-align: top;
}

td.data-table-cell {
  border: 1px solid black;
  padding: 3px;
  text-align: left;
}

td.data-table-cell-top-align {
  border: 1px solid black;
  padding: 3px;
  vertical-align: top;
  text-align: left;
}

th.data-table-cell {
  border: 1px solid black;
  padding: 3px;
  font-size: 14px;
  background-color: #EEEEEE;
  text-align: left;
}

th.data-table-cell-top-align {
  border: 1px solid black;
  padding: 3px;
  font-size: 14px;
  background-color: #EEEEEE;
  vertical-align: top;
  text-align: left;
}

td.recipient-heading {
  width: 75px;
}

table.single-signature-block {
  border: 0;
  padding: 0;
  width: 230px;
}

.contract {
  color: #000; !important;
  text-align: justify;
  text-justify: inter-word;
}

p.blank { 
  text-align: center;
    font-style: italic;
}

table.data-table-centered {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 3px;
  font-family: Arial, serif;
  margin-left:auto; 
  margin-right:auto;
  width: 100%;
  text-align: left;
}

p.tight-right {
  text-align:right;
  margin-bottom: 6px;
}

p.tight-left {
  margin-bottom: 6px;
}

/*** Styles Developed to Support Jordan Barris's Documents ***/
.line-break {
  margin: 24px;
  border-bottom: 1px solid black;
}

h2.section-heading-underlined {
  text-transform: uppercase;
  text-decoration: underline;
  font-family: Arial, serif;
}

h2.section-heading-underlined-page-break {
  text-transform: uppercase;
  text-decoration: underline;
  font-family: Arial, serif;
  page-break-before: always;
}

.underlined {
  text-decoration: underline;
}

/*** START These work in web but not Word ***/
/*** Word will still use fonts if they are available ***/
ol.terms > li:before {
  counter-increment: alpha;
  content:'(' counter(alpha, lower-alpha)')  '
}

ol.numbered >li:before {
  content:'' !important;
} 

.contract b {
  font-weight: bold;
}
/*** END These work in web but not Word ***/`;

const WordOverrides = `
/*** Word Document Style Overrides ***/

/*** Original Rally Styles ***/
.contract h1 {
  font-size: 18px;
}
.contract h2, ol {
  font-size: 14.5px;
}

.contract h3 {
  font-size: 14.5px;
}
.contract h4 {
  font-size: 14.5px;
}

ol.sections {
  font-size: 14.5px;
}

ol.subsections {
  font-size: 14.5px;
}

ol.roman-numerals {
  font-size: 14.5px;
}

ul.sections {
  font-size: 14.5px;
}

.contract p {
  font-size: 14.5px;
}

ol.terms {
  font-size: 14.5px;
}

ol.numbered {
  font-size: 14.5px;
}

td {
  font-family: Arial, serif;
  font-size: 14.5px;
  text-align: justify;
  text-justify: inter-word;
}

td.recipient-heading {
  font-family: Arial, serif;
  font-size: 14.5px;
  width: 75px;
}


.contract .field { /* Old signature fields don't convert to Word - remove eventually */
  font-size: 14.5px;
}

.representative { /* Old signature fields don't convert to Word - remove eventually */
  font-size: 14.5px !important;
}

/*** Styles Developed to Support Todd's Documents ***/

h1.document-heading-small {
  font-size: 14.5px;
}

h1.page-break-document-heading-small {
  page-break-before: always;
  font-size: 14.5px;
  font-family: Arial, serif;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 14.5px;
}

h2.article-heading-small {
  font-size: 14.5px;
}

th.data-table-cell {
  font-size: 14.5px;
  text-align: left;
}
`;

export const DefaultWebStyle = Rally2018;
export const DefaultWordStyle = Rally2018 + WordOverrides;
