import React, { useEffect } from 'react';

import Spinner from '../standard/Spinner';

const WopiClose = () => {
  useEffect(() => {
    window.close();
  }, []);

  return <Spinner />;
};

export default WopiClose;
