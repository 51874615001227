import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { SentryLink } from 'apollo-link-sentry';

import { backendHost } from './services/Config';
import { isE2ETest } from './services/Utilities';

const graphqlURI = `${backendHost}/graphql`;

const httpLink = createHttpLink({ uri: graphqlURI });

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('access_token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'is-rally-e2e-test': isE2ETest(),
    },
  };
});

const sentryLink = new SentryLink({
  uri: graphqlURI,
  attachBreadcrumbs: {
    includeError: true,
  },
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Contact: {
        keyFields: ['_contactId'],
      },
      Matter: {
        fields: {
          matterFieldValues: {
            merge: false,
          },
        },
      },
    },
  }),
  link: ApolloLink.from([sentryLink, authLink.concat(httpLink)]),
  headers: {
    authorization: `Bearer ${localStorage.getItem('access_token')}`,
  },
});

export default client;
