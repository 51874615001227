import { useFeatureToggle } from '@flopflip/react-broadcast';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useFirmSettings } from '../../../context/UserContext';
import { flagNames } from '../../../featureFlags';
import { userIsClient } from '../../../services/Utilities';
import * as RallyPropTypes from '../../../utils/RallyPropTypes';
import CommandBarMatterActions from '../../commandbar/CommandBarMatterActions';
import Spinner from '../../standard/Spinner';
import AddReviewersModal from './AddReviewersModal';
import ClientMatterViewHeader from './ClientMatterViewHeader';
import FirmMatterViewHeader from './FirmMatterViewHeader';
import MatterDocumentEditor from './MatterDocumentEditor';
import { MatterFieldCard } from './matterfields/MatterFieldCard';
import MatterFileExplorerCard from './MatterFileExplorerCard';
import MatterPreview from './MatterPreview';
import MatterViewActions from './MatterViewActions';
import RequestReviewModal from './RequestReviewModal';
import ReviewBanner from './ReviewBanner';
import { SendForSigning } from './SendForSigning';
import ShareWithClientModal from './ShareWithClientModal';

function MatterView({
  matter,
  grantClientAccess,
  revokeClientAccess,
  requestFirmReview,
  toggleMatterState,
  updateMatterName,
  updateMatterOrganization,
  fetchDownloadAllUrl,
  onSignatureReminder,
  onCancelSignatureRequest,
  deleteMatter,
  generateClaimUrl,
  sendForSigning,
  addMatterCategory,
  removeMatterCategory,
  removeMatterContact,
  addMatterContact,
  addReviewers,
  withdrawReviewRequest,
  approveReviewRequest,
  revokeReviewRequest,
  addMatterFieldValues,
  deleteMatterFieldValues,
  updateMatterFieldValues,
}) {
  const { folderId, folderToken: { accessToken } = {} } =
    matter.workspaceFolder || {};
  const organizationName = matter.organization?.name;

  const { search, state: { showSendForSigning } = {} } = useLocation();

  const [currentFile, setCurrentFile] = useState({});
  const [displaySendForSigning, setDisplaySendForSigning] = useState(
    (!!showSendForSigning && !matter?.signatureRequests.length) || false,
  );
  const [displayReviewModal, setDisplayReviewModal] = useState(false);
  const [displayShareModal, setDisplayShareModal] = useState(false);
  const [displayAddReviewersModal, setDisplayAddReviewersModal] =
    useState(false);
  const [displayAddMatterFieldModal, setDisplayAddMatterFieldModal] =
    useState(false);

  const { currentFolderId } = queryString.parse(search);

  const isFirmUser = !userIsClient();
  const isClientUser = !isFirmUser;
  const [{ allowClientEditMatterName }, { loading }] = useFirmSettings(); // remove this and the toggle on the firm settings page when client matter management is enabled
  const enableClientMatterManagement = useFeatureToggle(
    flagNames.ENABLE_CLIENT_MATTER_MANAGEMENT,
  );
  const allowMatterManagement = isFirmUser || enableClientMatterManagement;
  const enableMatterReviews = useFeatureToggle(flagNames.MATTER_REVIEWS);
  const areMatterFieldsEnabled = useFeatureToggle(flagNames.MATTER_FIELDS);
  const [editMode, setEditMode] = useState(false);

  if (loading) {
    return <Spinner />;
  }

  const actions = (
    <MatterViewActions
      matter={matter}
      allowMatterManagement={allowMatterManagement}
      deleteMatter={deleteMatter}
      grantClientAccess={grantClientAccess}
      revokeClientAccess={revokeClientAccess}
      setDisplaySendForSigning={setDisplaySendForSigning}
      setDisplayReviewModal={setDisplayReviewModal}
      toggleMatterState={toggleMatterState}
      updateMatterOrganization={updateMatterOrganization}
      displayShareModal={displayShareModal}
      setDisplayShareModal={setDisplayShareModal}
      currentFile={currentFile}
    />
  );

  return (
    <div>
      <CommandBarMatterActions
        matter={matter}
        setDisplaySendForSigning={setDisplaySendForSigning}
        setDisplayReviewModal={setDisplayReviewModal}
        addMatterCategory={addMatterCategory}
        addMatterContact={addMatterContact}
        toggleMatterState={toggleMatterState}
        revokeClientAccess={revokeClientAccess}
        setDisplayShareModal={setDisplayShareModal}
        setDisplayAddReviewersModal={setDisplayAddReviewersModal}
        setDisplayAddMatterFieldModal={setDisplayAddMatterFieldModal}
      />
      <SendForSigning
        generateClaimUrl={generateClaimUrl}
        sendForSigning={sendForSigning}
        setDisplay={setDisplaySendForSigning}
        display={displaySendForSigning}
        accessToken={accessToken}
        matterId={matter.id}
        folderId={folderId}
      />
      {!isFirmUser && (
        <RequestReviewModal
          display={displayReviewModal}
          onClose={() => setDisplayReviewModal(false)}
          matterId={matter.id}
          organizationName={organizationName}
          requestFirmReview={requestFirmReview}
          portal={document.body}
        />
      )}
      {isFirmUser && (
        <ShareWithClientModal
          display={displayShareModal}
          onClose={() => setDisplayShareModal(false)}
          contract={matter}
          grantClientAccess={grantClientAccess}
          portal={document.body}
        />
      )}
      {enableMatterReviews && isClientUser && (
        <AddReviewersModal
          display={displayAddReviewersModal}
          onClose={() => setDisplayAddReviewersModal(false)}
          portal={document.body}
          addReviewers={addReviewers}
          withdrawReviewRequest={withdrawReviewRequest}
          reviewRequests={matter.reviewRequests}
        />
      )}
      <ReviewBanner
        reviewRequests={matter.reviewRequests}
        approveReviewRequest={approveReviewRequest}
        revokeReviewRequest={revokeReviewRequest}
      />
      {isFirmUser ? (
        <FirmMatterViewHeader
          matter={matter}
          actions={actions}
          toggleMatterState={toggleMatterState}
          addMatterCategory={addMatterCategory}
          removeMatterCategory={removeMatterCategory}
          updateMatterName={updateMatterName}
          addMatterContact={addMatterContact}
          removeMatterContact={removeMatterContact}
          setDisplaySendForSigning={setDisplaySendForSigning}
          onSignatureReminder={onSignatureReminder}
          onCancelSignatureRequest={onCancelSignatureRequest}
        />
      ) : (
        <ClientMatterViewHeader
          matter={matter}
          actions={actions}
          allowMatterManagement={allowMatterManagement}
          toggleMatterState={toggleMatterState}
          addMatterCategory={addMatterCategory}
          removeMatterCategory={removeMatterCategory}
          updateMatterName={
            enableClientMatterManagement || allowClientEditMatterName
              ? updateMatterName
              : undefined
          }
          addMatterContact={addMatterContact}
          removeMatterContact={removeMatterContact}
          setDisplaySendForSigning={setDisplaySendForSigning}
          setDisplayAddReviewersModal={setDisplayAddReviewersModal}
          onSignatureReminder={onSignatureReminder}
          onCancelSignatureRequest={onCancelSignatureRequest}
        />
      )}
      <div className="row flex-column-reverse flex-xl-row">
        {!editMode && (
          <MatterPreview
            html={matter.html}
            fileId={currentFile?.id}
            accessToken={accessToken}
            currentFile={currentFile}
            setCurrentFile={setCurrentFile}
            setEditMode={setEditMode}
          />
        )}
        {editMode && (
          <MatterDocumentEditor
            currentFile={currentFile}
            setEditMode={setEditMode}
          />
        )}
        {(!!accessToken || areMatterFieldsEnabled) && (
          <div className="col-12 col-xl-4">
            {!!accessToken && (
              <MatterFileExplorerCard
                matterId={matter.id}
                accessToken={accessToken}
                rootFolderId={folderId}
                currentFolderId={currentFolderId}
                currentFile={currentFile}
                setCurrentFile={setCurrentFile}
                fetchDownloadAllUrl={fetchDownloadAllUrl}
                setEditMode={setEditMode}
              />
            )}
            {areMatterFieldsEnabled && (
              <MatterFieldCard
                matterFieldValues={matter.matterFieldValues}
                displayAddMatterFieldModal={displayAddMatterFieldModal}
                setDisplayAddMatterFieldModal={setDisplayAddMatterFieldModal}
                addMatterFieldValues={addMatterFieldValues}
                deleteMatterFieldValues={deleteMatterFieldValues}
                updateMatterFieldValues={updateMatterFieldValues}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

MatterView.propTypes = {
  matter: PropTypes.shape({
    id: RallyPropTypes.MongoID,
    state: PropTypes.oneOf(['open', 'closed']).isRequired,
    legacyState: PropTypes.oneOf([
      'qSent',
      'qInProgress',
      'awaitingPayment',
      'open',
      'clientReview',
      'finalized',
      'finalizedFirmOnly',
      'closed',
    ]),
    name: PropTypes.string.isRequired,
    data: PropTypes.object,
    clientAccessible: PropTypes.bool.isRequired,
    organization: PropTypes.shape({
      _id: RallyPropTypes.MongoID,
      name: PropTypes.string.isRequired,
    }),
    hasQuestionnaire: PropTypes.bool.isRequired,
    workspaceFolder: PropTypes.shape({
      folderId: PropTypes.string.isRequired,
      folderToken: PropTypes.shape({
        accessToken: PropTypes.string.isRequired,
      }).isRequired,
    }),
    html: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        _id: RallyPropTypes.MongoID,
        name: PropTypes.string,
        parents: PropTypes.arrayOf(
          PropTypes.shape({
            _id: RallyPropTypes.MongoID,
            name: PropTypes.string,
          }),
        ),
      }),
    ),
    matterFieldValues: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        matterField: PropTypes.shape({
          _id: PropTypes.string,
          name: PropTypes.string,
          type: PropTypes.any,
        }),
        value: PropTypes.any,
      }),
    ),
  }).isRequired,
  grantClientAccess: PropTypes.func,
  revokeClientAccess: PropTypes.func,
  requestFirmReview: PropTypes.func,
  toggleMatterState: PropTypes.func,
  updateMatterName: PropTypes.func,
  updateMatterOrganization: PropTypes.func,
  fetchDownloadAllUrl: PropTypes.func,
  deleteMatter: PropTypes.func,
  generateClaimUrl: PropTypes.func,
  sendForSigning: PropTypes.func,
  addMatterCategory: PropTypes.func,
  removeMatterCategory: PropTypes.func,
  addMatterContact: PropTypes.func,
  removeMatterContact: PropTypes.func,
  addReviewers: PropTypes.func,
  withdrawReviewRequest: PropTypes.func,
  addMatterFieldValues: PropTypes.func.isRequired,
  deleteMatterFieldValues: PropTypes.func.isRequired,
  updateMatterFieldValues: PropTypes.func.isRequired,
};

export default MatterView;
