import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { InfiniteLoader, List, WindowScroller } from 'react-virtualized';

import ListView from './ListView';
import { ListViewItemHeight } from './ListViewItem';

export function InfiniteListView({
  connection,
  loading,
  rowHeight = ListViewItemHeight,
  fetchMore = async () => {},
  renderRow = () => {},
  emptyList = <div>No Items Found!</div>,
  infiniteLoaderRef,
  ...props
}) {
  const [listWidth, setListWidth] = useState();

  const listViewRef = useRef();
  const listRef = useRef();

  useEffect(() => {
    if (!listViewRef.current) {
      return () => {};
    }

    setListWidth(listViewRef.current.offsetWidth);

    const handleResize = () => {
      setListWidth(listViewRef.current.offsetWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [listViewRef]);

  if (!connection?.count && !loading) {
    return <>{emptyList}</>;
  }

  return (
    <ListView {...props} ref={listViewRef}>
      <WindowScroller>
        {({ height, isScrolling, onChildScroll, scrollTop }) => (
          <InfiniteLoader
            isRowLoaded={({ index }) => !!connection.edges[index]}
            rowCount={connection.count}
            ref={infiniteLoaderRef}
            loadMoreRows={async ({ startIndex, stopIndex }) => {
              const { edges } = connection;

              const previousLoadedIndex = edges[startIndex - 1]
                ? startIndex - 1
                : edges.findIndex(
                    (edge, index, array) => !!edge && !array[index + 1],
                  );
              const first = stopIndex - previousLoadedIndex;
              const after = edges[previousLoadedIndex]?.cursor;

              await fetchMore({
                first,
                after,
                index: previousLoadedIndex + 1,
              });
            }}
          >
            {({ onRowsRendered, registerChild }) => (
              <List
                autoHeight
                height={height}
                isScrolling={isScrolling}
                onScroll={onChildScroll}
                rowCount={connection.count}
                onRowsRendered={onRowsRendered}
                ref={(listElement) => {
                  // eslint-disable-next-line no-param-reassign
                  listRef.current = listElement;
                  // eslint-disable-next-line no-param-reassign
                  registerChild.current = listElement;
                }}
                rowHeight={rowHeight}
                width={listWidth ?? listViewRef.current?.offsetWidth ?? 800}
                rowRenderer={renderRow}
                scrollTop={scrollTop}
              />
            )}
          </InfiniteLoader>
        )}
      </WindowScroller>
    </ListView>
  );
}

InfiniteListView.propTypes = {
  connection: PropTypes.shape({
    count: PropTypes.number,
    edges: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  fetchMore: PropTypes.func.isRequired,
  renderRow: PropTypes.func.isRequired,
  infiniteLoaderRef: PropTypes.object.isRequired,
  rowHeight: PropTypes.number,
  loading: PropTypes.bool,
  emptyList: PropTypes.node,
};
