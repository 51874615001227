import React, { useEffect, useState } from 'react';

import { SmallComboBox } from '../../forms/FormStyles';
import Button from '../../standard/Button';

export function AddTeamMemberDropdown({ options = [], onSubmit, inputId }) {
  const [newCollaborator, setNewCollaborator] = useState();

  useEffect(() => {
    setNewCollaborator(null);
  }, [options]);

  return (
    <>
      <div className="row">
        <div className="col-10 pr-0">
          <SmallComboBox
            inputId={inputId}
            name={inputId}
            options={options}
            isDisabled={!options.length}
            className="Select--multi"
            classNamePrefix="Select"
            placeholder="Enter an email address"
            value={newCollaborator}
            onChange={(value) => setNewCollaborator(value)}
            required={false}
            title="Enter an email address"
          />
        </div>
        <div className="col-2 pl-0">
          <Button
            className="float-right"
            type="submit"
            disabled={!newCollaborator}
            onClick={() => onSubmit(newCollaborator)}
            outline
          >
            Add
          </Button>
        </div>
      </div>
    </>
  );
}
