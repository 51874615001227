import React from 'react';
import styled from 'styled-components/macro';

import {
  trackFileDownload,
  trackFileUpload,
} from '../../../services/Analytics';
import SmallExplorer from '../../filerepo/SmallExplorer';
import MatterCard from './MatterCard';

const StyledExplorer = styled(SmallExplorer)`
  display: inline-block;
  .be-breadcrumb:first-child button {
    text-indent: -9999px;
    line-height: 0;
    &:after {
      content: 'Workspace';
      text-indent: 0;
      display: block;
      line-height: initial;
    }
  }
  .be-sub-header {
    flex-direction: row-reverse;
  }
`;

const MatterFileExplorer = ({
  onUpload = (f) => f,
  onDownload = (f) => f,
  ...props
}) => (
  <MatterCard>
    <StyledExplorer
      canPreview={false}
      {...props}
      onUpload={(...args) => {
        trackFileUpload('Matter Workspace');
        onUpload(...args);
      }}
      onDownload={(...args) => {
        trackFileDownload('Matter Workspace');
        onDownload(...args);
      }}
    />
  </MatterCard>
);

export default MatterFileExplorer;
