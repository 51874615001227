import React from 'react';

import { ConfirmationDialog } from '../standard/ConfirmationDialog';

export const DeleteCustomFieldDialog = ({ open, onClose, onConfirm }) => (
  <ConfirmationDialog
    open={open}
    onClose={onClose}
    onConfirm={onConfirm}
    title="Delete Custom Field"
    message="Are you sure you want to delete this custom field?"
    confirmText="Delete Custom Field"
    confirmButtonPalette="destructive"
  />
);
