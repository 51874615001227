import 'react-toastify/dist/ReactToastify.css';

import {
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleXmark,
  faXmark,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SCREEN_SIZES } from 'components/LayoutConstants';
import React from 'react';
import { Link } from 'react-router-dom';
import { Slide, toast, ToastContainer } from 'react-toastify';
import styled, { css } from 'styled-components/macro';

export const ToastContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IconsForType = {
  info: faCircleInfo,
  success: faCircleCheck,
  error: faCircleXmark,
  warning: faCircleExclamation,
};

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 24px;
  align-self: top;
`;

const Icon = ({ type }) => {
  if (IconsForType[type]) {
    return <StyledIcon icon={IconsForType[type]} />;
  }
  return null;
};

const CloseButtonContainer = styled.div.attrs({
  className: 'close-button',
})`
  display: flex;
  align-items: flex-start;
  font-size: 16px;
`;

const CloseButtonIcon = styled(FontAwesomeIcon)`
  width: 16px;
  height: 16px;
`;

const CloseButton = () => (
  <CloseButtonContainer>
    <CloseButtonIcon icon={faXmark} data-testid="toast-close" />
  </CloseButtonContainer>
);

const StyledToast = styled(ToastContainer)`
  ${({ theme }) => css`
    .Toastify__toast {
      color: ${theme.palette.shade.gray_1};
      border-radius: 8px;
      padding: 16px;
      min-height: 48px;
    }
    .Toastify__toast-icon {
      align-self: flex-start;
      margin-right: 16px;
    }
    .Toastify__toast--success {
      background-color: ${theme.palette.feedback.success.light};
      border: 0.5px solid ${theme.palette.feedback.success.medium};
      .Toastify__toast-icon,
      .toaster-link,
      .close-button {
        color: ${theme.palette.feedback.success.medium};
      }
    }
    .Toastify__toast--error {
      background-color: ${theme.palette.feedback.error.extralight};
      border: 0.5px solid ${theme.palette.feedback.error.medium};
      .Toastify__toast-icon,
      .close-button {
        color: ${theme.palette.feedback.error.dark};
      }
      .toaster-link {
        color: ${theme.palette.feedback.error.dark};
      }
    }
    .Toastify__toast--warning {
      background-color: ${theme.palette.feedback.warning.light};
      border: 0.5px solid ${theme.palette.feedback.warning.dark};
      .Toastify__toast-icon,
      .toaster-link,
      .close-button {
        color: ${theme.palette.feedback.warning.dark};
      }
    }
    .Toastify__toast--info,
    .Toastify__toast--default {
      background-color: ${theme.palette.feedback.info.light};
      border: 0.5px solid ${theme.palette.feedback.info.medium};
      .Toastify__toast-icon,
      .toaster-link,
      .close-button {
        color: ${theme.palette.feedback.info.medium};
      }
    }
    &&&.Toastify__toast-container {
      padding: 0;
      width: min(90vw, 523px);
      @media ${SCREEN_SIZES.X_SMALL} {
        margin: 0 5vw 1rem 5vw;
      }
    }
    .Toastify__toast-body {
      width: 95%;
      letter-spacing: 0.02em;
      font-size: 14px;
      line-height: 16px;
      font-weight: normal;
      word-wrap: break-word;
      padding: 0px 16px 0px 0px;
    }

    .Toastify__close-button:focus {
      outline: none;
    }
  `}
`;

export const ToasterLink = styled(Link).attrs({ className: 'toaster-link' })`
  font-weight: 700;
`;

const Toaster = (props) => (
  <StyledToast
    zIndex={1301}
    position={toast.POSITION.BOTTOM_LEFT}
    transition={Slide}
    autoClose={7000}
    hideProgressBar
    newestOnTop={false}
    closeOnClick
    rtl={false}
    icon={Icon}
    enableMultiContainer
    pauseOnFocusLoss={false}
    draggable
    pauseOnHover
    closeButton={<CloseButton />}
    {...props}
  />
);

export default Toaster;
