import axios from 'axios';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import Spinner from '../standard/Spinner';

const FrameHolder = styled.span`
  body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    -ms-content-zooming: none;
  }

  #office_frame {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    border: none;
    display: block;
  }
`;

const WopiHost = () => {
  const { fileId, action, application, extension } = useParams();
  const { search } = useLocation();

  const [accessToken, setAccessToken] = useState(null);
  const [accessTokenTtl, setAccessTokenTtl] = useState(null);
  const [actionUrl, setActionUrl] = useState(null);
  const [favIconUrl, setFavIconUrl] = useState(null);
  const [ready, setReady] = useState(false);
  const [officeFormSubmitted, setOfficeFormSubmitted] = useState(false);
  const officeFormRef = useRef(null);

  useEffect(() => {
    const getAccessToken = async () => {
      const response = await axios.post('/api/v1/generateAccessToken', {
        application,
        fileId,
        hostname: window.location.hostname,
      });
      setAccessToken(response.data.access_token);
      setAccessTokenTtl(new Date().getTime() + response.data.expires_in * 1000);
    };

    getAccessToken();
  }, [application, fileId]);

  useEffect(() => {
    const getActionUrl = async () => {
      const { origin, id, ...queryParams } = queryString.parse(search);
      const query = {
        application,
        action,
        extension,
        fileId,
        origin,
        id,
      };
      const response = await axios.get(
        `/api/v1/getActionUrl?${queryString.stringify(query)}`,
      );

      // need to append wd* parameters from host page to the actionUrl
      const { url: actionUrlBase, query: actionUrlQueryParams } =
        queryString.parseUrl(response.data.actionUrl);

      const wdParams = Object.fromEntries(
        Object.entries(queryParams).filter(([key]) => key.startsWith('wd')),
      );

      const newActionUrl = `${actionUrlBase}?${queryString.stringify({
        ...actionUrlQueryParams,
        ...wdParams,
      })}`;

      setActionUrl(newActionUrl);
      setFavIconUrl(response.data.favIconUrl);
      setReady(true);
    };

    if (accessToken) {
      getActionUrl();
    }
  }, [accessToken, action, application, extension, fileId, search]);

  useEffect(() => {
    if (!officeFormSubmitted || !window.history?.replaceState) {
      return;
    }

    // need to remove wdPreviousSession and wdPreviousCorrelation from host
    // url after passing action url to the iframe if history.replaceState is
    // available
    const { wdPreviousSession, wdPreviousCorrelation, ...newQueryString } =
      queryString.parse(search);

    if (wdPreviousSession || wdPreviousCorrelation) {
      window.history.replaceState(
        window.history.state,
        '',
        `?${queryString.stringify(newQueryString)}`,
      );
    }
  }, [officeFormSubmitted, search]);

  useEffect(() => {
    if (ready && officeFormRef.current) {
      officeFormRef.current.submit();
      setOfficeFormSubmitted(true);
    }
  }, [ready, officeFormRef]);

  return !ready ? (
    <Spinner />
  ) : (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta httpEquiv="Cache-Control" content="no-cache, no-store" />
        <meta httpEquiv="Expires" content="-1" />
        <meta httpEquiv="Pragma" content="no-cache" />
        <meta httpEquiv="Date" content={new Date().toUTCString()} />
        <meta httpEquiv="Vary" content="*" />

        <title />
        <meta name="description" content="" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no"
        />

        <link rel="shortcut icon" href={favIconUrl} />
      </Helmet>
      <form
        id="office_form"
        name="office_form"
        target="office_frame"
        action={actionUrl}
        method="post"
        ref={officeFormRef}
      >
        <input name="access_token" value={accessToken} type="hidden" />
        <input name="access_token_ttl" value={accessTokenTtl} type="hidden" />
      </form>
      <FrameHolder id="frameholder">
        <iframe
          id="office_frame"
          name="office_frame"
          title="Office Frame"
          // This attribute allows true fullscreen mode in slideshow view when
          // using PowerPoint's 'view' action.
          allowFullScreen
          // The sandbox attribute is needed to allow automatic redirection to
          // the O365 sign-in page in the business user flow
          sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation allow-popups-to-escape-sandbox"
        />
      </FrameHolder>
    </>
  );
};

export default WopiHost;
