import { faCheck, faCircle } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import styled from 'styled-components/macro';

import { SignatureRequestStatus } from '../../../utils/RallyPropTypes';
import { SCREEN_SIZES } from '../../LayoutConstants';
import { StateBadge } from './StateBadge';

const StyledStateBadge = styled(StateBadge)`
  @media ${SCREEN_SIZES.SMALL} {
    .state-badge-text {
      display: none;
    }
  }
`;

const DEFAULT_BADGE_PROPS = {
  iconClass: 'inactive',
  icon: faCircle,
  copy: 'Unknown',
};

const STATUS_VIEW_MAP = {
  AWAITING_SIGNATURES: {
    iconClass: 'pending',
    icon: faCircle,
    copy: 'Awaiting Signatures',
  },
  COMPLETE: {
    iconClass: 'success',
    icon: faCheck,
    copy: 'Complete',
  },
  DECLINED: {
    iconClass: 'error',
    icon: faCircle,
    copy: 'Declined',
  },
  CANCELLED: {
    iconClass: 'inactive',
    icon: faCircle,
    copy: 'Cancelled',
  },
  ERROR: {
    iconClass: 'error',
    icon: faCircle,
    copy: 'Error',
  },
};

export function SignatureRequestStatusBadge({ status, ...props }) {
  const badgeProps = STATUS_VIEW_MAP[status] || DEFAULT_BADGE_PROPS;

  return <StyledStateBadge {...badgeProps} {...props} />;
}

SignatureRequestStatusBadge.propTypes = {
  status: SignatureRequestStatus,
};
