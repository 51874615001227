import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components/macro';

import { PaletteType } from '../../utils/RallyPropTypes';

const StyledBadge = styled.span`
  border: 1px solid ${(props) => props.color};
  border-radius: 3px;
  font-size: 12px;
  color: ${(props) => props.color};
  padding: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Badge = ({ className, badgeStyle, children }) => {
  const { palette } = useContext(ThemeContext);
  const color = palette[badgeStyle]?.main;
  return (
    <StyledBadge className={className} color={color}>
      {children}
    </StyledBadge>
  );
};

Badge.propTypes = {
  className: PropTypes.string,
  badgeStyle: PaletteType,
  children: PropTypes.node,
};

export default Badge;
