import React, { useState } from 'react';
import BrowserDetection from 'react-browser-detection';

import Modal from './standard/Modal';

const CustomDomainSafariHandler = () => {
  const [display, setDisplay] = useState(true);
  return (
    window.location.href.includes('rennoco.app/client/login') && (
      <Modal
        display={display}
        hideAccept
        hideClose
        title="Safari Alert"
        onCancel={() => setDisplay(false)}
      >
        <div>
          <p>
            {`This service is not yet compatible with Safari. To continue, use `}
            <b>Chrome</b> or another browser.
          </p>
        </div>
      </Modal>
    )
  );
};

const InternetExplorerHandler = () => {
  const [display, setDisplay] = useState(true);
  return (
    <Modal
      display={display}
      hideAccept
      hideClose
      title="Internet Explorer Alert"
      onCancel={() => setDisplay(false)}
    >
      <div>
        <p>
          This service is not compatible with Internet Explorer. To continue,
          please use <b>Chrome</b>, <b>Microsoft Edge</b>, or another browser.
        </p>
      </div>
    </Modal>
  );
};

const browserHandler = {
  default: () => null,
  ie: () => <InternetExplorerHandler />,
  safari: () => <CustomDomainSafariHandler />,
};

const BrowserLock = () => {
  const [showAlert, setShowAlert] = useState(true);

  return (
    <BrowserDetection showAlert={showAlert} setShowAlert={setShowAlert}>
      {browserHandler}
    </BrowserDetection>
  );
};
export default BrowserLock;
