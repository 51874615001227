import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import marked from 'marked';
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { withTheme } from 'styled-components/macro';

import Address from '../forms/Address';
import CompanyAddress from '../forms/CompanyAddress';
import CompanyName from '../forms/CompanyName';
import { ContactCard } from '../forms/contact/ContactCard';
// eslint-disable-next-line import/no-cycle
import DataTable from '../forms/DataTable';
import DataTableV1 from '../forms/DataTableV1';
import DatePicker from '../forms/DatePicker';
import Dropdown from '../forms/Dropdown';
import Email from '../forms/Email';
import Entity from '../forms/Entity';
import FileUpload from '../forms/FileUpload';
import {
  ErrorMessage,
  Helper,
  Input,
  InputPrefix,
  InputSuffix,
  Label,
  Note,
  Textarea,
  Tooltip,
  TooltipIcon,
} from '../forms/FormStyles';
import Jurisdiction from '../forms/Jurisdiction';
import MultiField from '../forms/MultiField';
import MultiSelect from '../forms/MultiSelect';
import PhoneNumber from '../forms/PhoneNumber';

const renderer = new marked.Renderer();
renderer.link = function targetBlankLink(...rest) {
  const link = marked.Renderer.prototype.link.apply(this, rest);
  return link.replace('<a', "<a target='_blank'");
};

marked.setOptions({
  renderer,
});

const setWidth = (inModal, narrowWidth, wideWidth) =>
  inModal ? wideWidth : narrowWidth;

class TemplateGeneratedField extends Component {
  componentDidMount() {
    const { hidden } = this.props;
    if (this.fieldRef && hidden) {
      this.hideField();
    } else {
      this.formGroupRef.style.display = 'block';
    }
  }

  componentDidUpdate() {
    const { hidden } = this.props;
    if (this.fieldRef && hidden) {
      this.hideField();
    } else {
      this.formGroupRef.style.display = 'block';
    }
  }

  generateInputElement() {
    const {
      contract,
      elementId,
      entityIdentifier,
      errors,
      helper,
      identifier,
      inModal,
      onBlur,
      onChange,
      required,
      type,
      placeholder,
      value,
      smallfield,
    } = this.props;

    const labelledBy = `${elementId ?? identifier}-label`;

    switch (type.type || type) {
      case 'String':
        return (
          <div
            className={setWidth(
              inModal,
              'col-12 col-lg-6',
              smallfield ? 'col-12' : 'col-10',
            )}
          >
            <Input
              type="text"
              aria-labelledby={labelledBy}
              name={identifier}
              helper={helper}
              onChange={onChange}
              onBlur={onBlur}
              placeholder={placeholder}
              value={value ?? ''}
              required={required || false}
              ref={(thisField) => {
                this.fieldRef = thisField;
              }}
              errors={errors}
              autoComplete="no"
              smallfield={smallfield}
            />
          </div>
        );

      case 'LongString':
        return (
          <div className="col-12 col-lg-8">
            <Textarea
              as="textarea"
              rows="2"
              aria-labelledby={labelledBy}
              name={identifier}
              helper={helper}
              onChange={onChange}
              onBlur={onBlur}
              value={value || ''}
              required={required || false}
              ref={(thisField) => {
                this.fieldRef = thisField;
              }}
              errors={errors}
              autoComplete="no"
            />
          </div>
        );

      case 'Integer':
        return (
          <div className="col-sm-6 col-lg-3">
            <Input
              as={NumberFormat}
              aria-labelledby={labelledBy}
              name={identifier}
              thousandSeparator
              helper={helper}
              onBlur={onBlur}
              value={value}
              required={required || false}
              ref={(thisField) => {
                this.fieldRef = thisField;
              }}
              errors={errors}
              autoComplete="no"
              onValueChange={(values) =>
                onChange({
                  target: {
                    name: identifier,
                    type: 'number',
                    value: values.value,
                  },
                })
              }
            />
          </div>
        );

      case 'PhoneNumber':
        return (
          <div className="col-sm-6 col-lg-4">
            <PhoneNumber
              aria-labelledby={labelledBy}
              name={identifier}
              helper={helper}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              required={required || false}
              ref={(thisField) => {
                this.fieldRef = thisField;
              }}
              errors={errors}
            />
          </div>
        );

      case 'Email':
        return (
          <div
            className={setWidth(
              inModal,
              'col-12 col-lg-6',
              smallfield ? 'col-12' : 'col-10',
            )}
          >
            <Email
              aria-labelledby={labelledBy}
              name={identifier}
              helper={helper}
              onChange={onChange}
              onBlur={onBlur}
              placeholder={placeholder}
              value={value}
              required={required}
              ref={(thisField) => {
                this.fieldRef = thisField;
              }}
              errors={errors}
              autoComplete="no"
              smallfield={smallfield}
            />
          </div>
        );

      case 'Currency':
        return (
          <div
            className="input-group col-sm-6 col-lg-3"
            style={{ flexWrap: 'nowrap' }}
          >
            <InputPrefix>$</InputPrefix>
            <Input
              as={NumberFormat}
              aria-labelledby={labelledBy}
              name={identifier}
              thousandSeparator
              step=".00001"
              helper={helper}
              onBlur={onBlur}
              value={value}
              required={required || false}
              ref={(thisField) => {
                this.fieldRef = thisField;
              }}
              errors={errors}
              autoComplete="no"
              onValueChange={(values) =>
                onChange({
                  target: {
                    name: identifier,
                    type: 'number',
                    value: values.value,
                  },
                })
              }
              onWheel={(e) => e.target.blur()}
            />
          </div>
        );

      case 'Percent':
        return (
          <div
            className="input-group col-sm-6 col-lg-3"
            style={{ flexWrap: 'nowrap' }}
          >
            <Input
              type="number"
              step=".001"
              aria-labelledby={labelledBy}
              name={identifier}
              helper={helper}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              required={required || false}
              ref={(thisField) => {
                this.fieldRef = thisField;
              }}
              errors={errors}
              autoComplete="no"
              onWheel={(e) => e.target.blur()}
            />
            <InputSuffix>%</InputSuffix>
          </div>
        );

      case 'Date':
        return (
          <div className="col-12 col-lg-4">
            <DatePicker
              aria-labelledby={labelledBy}
              name={identifier}
              helper={helper}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              required={required || false}
              format={type.noYear ? 'MM d' : 'MM d, yyyy'}
              ref={(thisField) => {
                this.fieldRef = thisField;
              }}
              errors={errors}
            />
          </div>
        );
      case 'Money':
        return (
          <Input
            type="text"
            aria-labelledby={labelledBy}
            name={identifier}
            helper={helper}
            onChange={onChange}
            onBlur={onBlur}
            value={value || '$0.00'}
            required={required || false}
            ref={(thisField) => {
              this.fieldRef = thisField;
            }}
            errors={errors}
            autoComplete="no"
          />
        );

      case 'Boolean':
        return (
          <div className="col-12">
            <label className="custom-control custom-checkbox custom-control-lg">
              <Input
                type="checkbox"
                className="custom-control-input "
                aria-labelledby={labelledBy}
                name={identifier}
                helper={helper}
                onChange={onChange}
                onBlur={onBlur}
                checked={value}
                required={required || false}
                errors={errors}
                autoComplete="no"
              />
              <span className="custom-control-indicator" />
            </label>
          </div>
        );

      case 'Note':
        return null;

      case 'Jurisdiction':
        return (
          <div
            className={setWidth(
              inModal,
              'col-12 col-lg-10 col-xl-12',
              'col-10',
            )}
          >
            <Jurisdiction
              aria-labelledby={labelledBy}
              name={identifier}
              onChange={onChange}
              onBlur={onBlur}
              required={required || false}
              jurisdiction={value || {}}
              requireCounty={type.requireCounty}
              ref={(thisField) => {
                this.fieldRef = thisField;
              }}
              errors={errors}
            />
          </div>
        );

      case 'Contact':
        return (
          <div
            className={setWidth(inModal, 'col-12 col-lg-8 col-xl-12', 'col-10')}
          >
            <ContactCard
              aria-labelledby={labelledBy}
              name={identifier}
              onChange={onChange}
              onBlur={onBlur}
              required={required || false}
              contact={value || {}}
              fieldConfig={type.options.fieldConfig}
              contactType={type.options.contactType}
              ref={(thisField) => {
                this.fieldRef = thisField;
              }}
              errors={errors}
            />
          </div>
        );

      case 'Address':
        return (
          <div
            className={setWidth(
              inModal,
              'col-12 col-lg-10 col-xl-12',
              'col-10',
            )}
          >
            <Address
              aria-labelledby={labelledBy}
              name={identifier}
              onChange={onChange}
              onBlur={onBlur}
              required={required || false}
              address={value || {}}
              ref={(thisField) => {
                this.fieldRef = thisField;
              }}
              errors={errors}
              entityIdentifier={entityIdentifier}
            />
          </div>
        );

      case 'Entity':
        return (
          <Entity
            labelledBy={labelledBy}
            identifier={identifier}
            type={type}
            value={value}
            required={required}
            errors={errors}
            ref={(thisField) => {
              this.fieldRef = thisField;
            }}
            onChange={onChange}
            onBlur={onBlur}
          />
        );

      case 'CompanyName':
        return (
          <div className="col-12 col-lg-6">
            <CompanyName
              aria-labelledby={labelledBy}
              name={identifier}
              helper={helper}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              required={required}
              errors={errors}
              ref={(thisField) => {
                this.fieldRef = thisField;
              }}
            />
          </div>
        );

      case 'CompanyAddress':
        return (
          <div
            className={setWidth(
              inModal,
              'col-12 col-lg-10 col-xl-12',
              'col-10',
            )}
          >
            <CompanyAddress
              aria-labelledby={labelledBy}
              name={identifier}
              onChange={onChange}
              onBlur={onBlur}
              required={required || false}
              address={value}
              errors={errors}
              ref={(thisField) => {
                this.fieldRef = thisField;
              }}
            />
          </div>
        );

      case 'Enum':
        return (
          <div className="col-md-6 col-xl-4">
            <Dropdown
              aria-labelledby={labelledBy}
              name={identifier}
              onChange={onChange}
              onBlur={onBlur}
              options={type.set}
              value={value}
              required={required || false}
              ref={(thisField) => {
                this.fieldRef = thisField;
              }}
              errors={errors}
            />
          </div>
        );

      case 'DataTable':
        if (type.elements) {
          return (
            <div className="col-xl-12">
              <DataTable
                name={identifier}
                onChange={onChange}
                onBlur={onBlur}
                noun={type.noun}
                data={value || []}
                elements={type.elements}
                contract={contract}
                ref={(thisField) => {
                  this.fieldRef = thisField;
                }}
                errors={errors}
              />
            </div>
          );
        }
        return (
          <div className="col-12">
            <DataTableV1
              aria-labelledby={labelledBy}
              name={identifier}
              onChange={onChange}
              onBlur={onBlur}
              noun={type.noun}
              data={value}
              columns={type.columns}
              ref={(thisField) => {
                this.fieldRef = thisField;
              }}
              errors={errors}
            />
          </div>
        );

      case 'MultiField':
        return (
          <div className="col-12" aria-labelledby={labelledBy}>
            <MultiField
              name={identifier}
              onChange={onChange}
              onBlur={onBlur}
              subfields={type.subfields}
              elements={type.elements || []}
              data={value || {}}
              ref={(thisField) => {
                this.fieldRef = thisField;
              }}
              errors={errors}
            />
          </div>
        );

      case 'MultiSelect':
        return (
          <div className="col-12" aria-labelledby={labelledBy}>
            <MultiSelect
              name={identifier}
              onChange={onChange}
              onBlur={onBlur}
              options={type.options}
              selectedOptions={value || {}}
              ref={(thisField) => {
                this.fieldRef = thisField;
              }}
              errors={errors}
            />
          </div>
        );

      case 'FileUpload':
        return (
          <div className="col-12 col-lg-8">
            <FileUpload
              contractId={contract?._id}
              elementId={elementId}
              ref={(thisField) => {
                this.fieldRef = thisField;
              }}
              identifier={identifier}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
          </div>
        );
      default:
        return (
          <div className="col-6">
            <Input
              aria-labelledby={labelledBy}
              name={identifier}
              helper={helper}
              type="text"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              required={required || false}
              ref={(thisField) => {
                this.fieldRef = thisField;
              }}
              errors={errors}
              autoComplete="no"
            />
          </div>
        );
    }
  }

  // Hide form group. Force a focus and blur, and remove any validation requirements. This
  // needs to be done so that the validator doesn't get stuck in an invalid state after an invalid
  // field is dynamically hidden.
  hideField() {
    // If field is a basic text input and not a more complex object like a bootstrap dropdown
    // if(this.fieldRef.localName === 'input' || this.fieldRef.localName === 'textarea') {
    //   this.fieldRef.removeAttribute('required');
    //   this.fieldRef.focus();
    //   this.fieldRef.blur();
    // }
    this.formGroupRef.style.display = 'none';
  }

  render() {
    const {
      elementId,
      required,
      tooltip,
      errors,
      identifier,
      type,
      name,
      hidden,
      helper,
      marginBottom = '120px',
      inModal,
      smallfield,
    } = this.props;
    const input = this.generateInputElement();
    const labelClass = required ? ' require ' : '';
    const tooltipHtml = tooltip ? marked(tooltip) : '';
    const markedName = marked(name);
    const errorList = errors.map((error) => (
      <ErrorMessage key={error} smallfield={smallfield}>
        {error}
        <br />
      </ErrorMessage>
    ));

    if (type === 'Note') {
      let field = (
        <div
          ref={(el) => {
            this.formGroupRef = el;
          }}
        />
      );
      if (!hidden) {
        field = (
          <div className="row">
            <div className="col-xl-12">
              <Note
                ref={(el) => {
                  this.formGroupRef = el;
                }}
              >
                <div
                  className={labelClass}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: markedName }}
                />
              </Note>
            </div>
          </div>
        );
      }
      return field;
    }

    const markedHelper = helper && marked(helper, { breaks: true });

    return (
      <div
        className="form-group"
        ref={(el) => {
          this.formGroupRef = el;
        }}
        style={{ marginBottom, position: 'relative' }}
      >
        <Tooltip id={`tip-${identifier}`} effect="solid" place="right" html />
        <div className="row">
          <div className="col-xl-12">
            <Label
              id={`${elementId ?? identifier}-label`}
              className={labelClass}
              dangerouslySetInnerHTML={{
                __html: markedName,
              }}
              variant={smallfield ? 'small' : 'large'}
              hasTooltip={!!tooltip}
            />
            {tooltip && (
              <TooltipIcon
                data-tip={tooltipHtml}
                data-for={`tip-${identifier}`}
                icon={faQuestionCircle}
                variant={smallfield ? 'small' : 'large'}
                required={required}
              />
            )}
          </div>
        </div>
        {markedHelper && (
          <div className="row">
            <div className={setWidth(inModal, 'col-xl-12', 'col-xl-10')}>
              <Helper dangerouslySetInnerHTML={{ __html: markedHelper }} />
            </div>
          </div>
        )}
        <div className="row">{input}</div>
        <div>{errorList}</div>
      </div>
    );
  }
}

export default withTheme(TemplateGeneratedField);
