import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';

import { ActionButton } from './Button';
import { PopperMenu } from './PopperMenu';

const StyledLabel = styled.span`
  margin-left: auto;
  margin-right: auto;
`;

const StyledCaretIcon = styled(FontAwesomeIcon)`
  margin-left: 1rem;
`;

export function PopperButton({ label, children, ...props }) {
  const buttonRef = useRef(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <ActionButton
        onClick={() => setOpen(!open)}
        ref={buttonRef}
        role="button"
        {...props}
      >
        <StyledLabel>{label}</StyledLabel>
        <StyledCaretIcon icon={open ? faCaretUp : faCaretDown} />
      </ActionButton>
      <PopperMenu anchorEl={buttonRef.current} open={open} setOpen={setOpen}>
        {children}
      </PopperMenu>
    </>
  );
}

PopperButton.propTypes = {
  label: PropTypes.node,
};
