import { useFlagVariations } from '@flopflip/react-broadcast';
import {
  ContentExplorer as BoxContentExplorer,
  ContentPicker as BoxContentPicker,
  ContentPreview as BoxContentPreview,
  ContentUploader as BoxContentUploader,
} from 'box-ui-elements';
import React from 'react';

import { flagNames } from '../../featureFlags';

function withProxy(BoxComponent) {
  return React.forwardRef((props, ref) => {
    const [
      boxAppHostname,
      boxApiHostname,
      boxStaticHostname,
      boxUploadHostname,
    ] = useFlagVariations([
      flagNames.BOX_APP_HOSTNAME,
      flagNames.BOX_API_HOSTNAME,
      flagNames.BOX_STATIC_HOSTNAME,
      flagNames.BOX_UPLOAD_HOSTNAME,
    ]);

    return (
      <BoxComponent
        apiHost={boxApiHostname}
        appHost={boxAppHostname}
        staticHost={boxStaticHostname}
        uploadHost={boxUploadHostname}
        ref={ref}
        {...props}
      />
    );
  });
}

export const ContentExplorer = withProxy(BoxContentExplorer);
export const ContentPicker = withProxy(BoxContentPicker);
export const ContentPreview = withProxy(BoxContentPreview);
export const ContentUploader = withProxy(BoxContentUploader);
