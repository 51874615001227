import hexRgb from 'hex-rgb';
import PropTypes from 'prop-types';
import React, { forwardRef, memo } from 'react';
import styled from 'styled-components/macro';

function setColor(props, colorType, attr) {
  const paletteType = props.theme.palette[props.paletteType];
  if (props.neutral) {
    if (attr === 'backgroundColor') return 'transparent';
    if (attr === 'color') {
      return props.outline
        ? props.theme.palette.shade.black
        : props.theme.palette.shade.gray_2;
    }
    if (attr === 'borderColor')
      return props.outline ? props.theme.palette.shade.gray_2 : 'transparent';
    if (attr === 'hoverBackground') return 'transparent';
    if (attr === 'hoverBorder')
      return props.outline ? props.theme.palette.shade.black : 'transparent';
    if (attr === 'hoverColor') return props.theme.palette.shade.black;
  }
  if (props.outline) {
    if (attr === 'backgroundColor') return 'transparent';
    if (attr === 'color') return paletteType.main;
  }
  if (props.pure) {
    if (attr === 'backgroundColor') return 'transparent';
    if (attr === 'hoverBackground' || attr === 'activeBackground')
      return 'transparent';
    if (attr === 'hoverBorder' || attr === 'activeBorder') return 'transparent';
    if (attr === 'hoverColor' || attr === 'activeColor')
      return paletteType.dark;
    if (attr === 'borderColor') return 'transparent';
    if (attr === 'color') return paletteType.main;
  }
  if (paletteType) return paletteType[colorType];
  return props.theme.palette.primary[colorType];
}

const StyledButton = styled.button`
  background-color: ${(props) => setColor(props, 'main', 'backgroundColor')};
  color: ${(props) => setColor(props, 'contrastText', 'color')};
  font-weight: 400;
  font-size: 14px;
  padding: 5px 16px;
  line-height: inherit;
  border-radius: 2px;
  outline: none !important;
  transition: 0.15s linear;
  display: block;
  width: ${(props) => props.width || 'auto'};
  text-align: ${(props) => props.textAlign || 'center'};
  white-space: nowrap;
  border: 1px solid ${(props) => setColor(props, 'main', 'borderColor')};
  margin-top: ${(props) => props.marginTop};
  min-width: fit-content;

  &:hover {
    background-color: ${(props) => setColor(props, 'light', 'hoverBackground')};
    border-color: ${(props) => setColor(props, 'light', 'hoverBorder')};
    cursor: pointer;
    color: ${(props) => setColor(props, 'contrastText', 'hoverColor')};
  }

  &:active {
    background-color: ${(props) => setColor(props, 'dark', 'activeBackground')};
    border-color: ${(props) => setColor(props, 'dark', 'activeBorder')};
    color: ${(props) => setColor(props, 'contrastText', 'activeColor')};
  }

  &:disabled {
    background-color: ${(props) => !props.pure && setColor(props, 'main')};
    color: ${(props) => !props.pure && setColor(props, 'contrastText')};
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const ButtonIcon = styled.div`
  display: inline-block;
  margin-right: 0.5em;
`;

const Button = forwardRef(
  (
    {
      children,
      className,
      disabled,
      marginTop,
      onClick,
      outline,
      paletteType,
      pure,
      textAlign,
      type,
      width,
      dataTestId,
      icon,
      ...props
    },
    ref,
  ) => (
    <StyledButton
      data-testid={dataTestId}
      disabled={disabled}
      onClick={onClick}
      outline={outline}
      pure={pure}
      type={type || 'button'}
      paletteType={paletteType || 'primary'}
      width={width}
      // eslint-disable-next-line react/destructuring-assignment
      data-wizard={props['data-wizard']}
      // eslint-disable-next-line react/destructuring-assignment
      data-toggle={props['data-toggle']}
      className={className}
      marginTop={marginTop}
      textAlign={textAlign}
      ref={ref}
      {...props}
    >
      {!!icon && <ButtonIcon>{icon}</ButtonIcon>}
      {children}
    </StyledButton>
  ),
);

const ActionButtonWrapper = styled.div`
  position: relative;
`;

const StyledActionButton = styled(Button)`
  height: 48px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 0 1rem;

  :hover {
    box-shadow: -5px 5px 20px
      ${(props) =>
        hexRgb(props.theme.palette.primary.main, {
          format: 'css',
          alpha: 0.3,
        })};
    cursor: pointer;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
`;

const StyledNeutralButton = styled(Button)`
  border-radius: 4px;
  font-size: 0.8125rem;
  line-height: 1rem;
  font-weight: 300;
  padding: 8px 24px;
`;

const StyledIcon = styled.i`
  margin: 2px 6px -6px 0;
`;

export const ActionButton = forwardRef(
  ({ children, action, iconClass, ...props }, ref) => (
    <ActionButtonWrapper>
      <StyledActionButton onClick={action} width="100%" ref={ref} {...props}>
        {!!iconClass && <StyledIcon className={iconClass} />}
        {children}
      </StyledActionButton>
    </ActionButtonWrapper>
  ),
);

export const NeutralButton = forwardRef((props, ref) => (
  <StyledNeutralButton ref={ref} neutral {...props} />
));

export const TextButton = styled.button.attrs({
  className: 'rally-typography rally-typography-h5',
})`
  && {
    color: ${({ theme }) => theme.palette.primary.main};
    border: none;
    width: fit-content;
    padding: 0;
    background: none;

    :hover {
      color: ${({ theme }) => theme.palette.primary.light};
    }
  }
`;

Button.propTypes = {
  onClick: PropTypes.func,
  enabled: PropTypes.bool,
  outline: PropTypes.bool,
  pure: PropTypes.bool,
  icon: PropTypes.node,
  paletteType: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.string,
  textAlign: PropTypes.string,
  genericProps: PropTypes.string,
};

export default memo(Button);
