import styled from 'styled-components/macro';

const ExternalLink = styled.a`
  color: #4d5259;
  border-bottom: dotted 1px ${(props) => props.theme.palette.primary.main};
  :hover {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

export default ExternalLink;
