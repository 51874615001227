import {
  faEllipsisVertical,
  faTrashAlt,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClickAwayListener, Popper } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';

import { TextButton } from '../../../standard/Button';
import Card from '../../../standard/Card';
import { TypographyStyles } from '../../../Typography';
import { MatterFieldValueEditor } from './MatterFieldValueEditor';

const MatterFieldRowValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-left: -1.1875rem;

  ${TypographyStyles.FontFamily}
  ${TypographyStyles.Color}
  ${TypographyStyles.H6}
  
  border-radius: 2px;

  ${({ isEditing }) =>
    isEditing
      ? css``
      : css`
          cursor: pointer;
          :hover {
            background-color: ${({ theme }) => theme.palette.shade.gray_5};
          }
        `}
`;

const MatterFieldValueKebab = styled.button.attrs({
  className: 'rally-matter-field-value-kebab',
})`
  ${TypographyStyles.FontFamily}
  ${TypographyStyles.H6}
  
  margin-right: 0.1875rem;

  color: ${({ theme }) => theme.palette.shade.gray_2};

  border: none;
  border-radius: 2px;
  background: transparent;

  :hover {
    background: ${({ theme }) => theme.palette.shade.gray_5};
  }

  :focus-visible {
    outline-color: ${({ theme }) => theme.palette.primary.main};
  }

  visibility: hidden;
`;

const MatterFieldValueMenuCard = styled(Card)`
  margin-right: 6px;
  padding: 0;
`;

const RemoveMatterFieldValueButton = styled(TextButton).attrs({
  className: 'rally-typography rally-typography-p',
})`
  && {
    padding: 0.5rem 3rem 0.5rem 1rem;
    color: ${({ theme }) => theme.palette.shade.gray_1};
  }
`;

export function MatterFieldValue({
  matterFieldValue,
  setMatterFieldIdToDelete = (f) => f,
  updateMatterFieldValues = (f) => f,
  ...props
}) {
  const { matterField } = matterFieldValue;

  const [isEditing, setIsEditing] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <ClickAwayListener onClickAway={() => setIsEditing(false)}>
      <MatterFieldRowValueContainer isEditing={isEditing} {...props}>
        <MatterFieldValueKebab
          type="button"
          onClick={(event) =>
            setAnchorEl(anchorEl ? null : event.currentTarget)
          }
        >
          <FontAwesomeIcon icon={faEllipsisVertical} size="lg" />
        </MatterFieldValueKebab>
        <Popper
          open={!!anchorEl}
          anchorEl={anchorEl}
          placement="left"
          disablePortal
        >
          <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <MatterFieldValueMenuCard>
              <RemoveMatterFieldValueButton
                type="button"
                onClick={() => {
                  setMatterFieldIdToDelete(matterField._id);
                  setAnchorEl(null);
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt} /> Delete
              </RemoveMatterFieldValueButton>
            </MatterFieldValueMenuCard>
          </ClickAwayListener>
        </Popper>
        <MatterFieldValueEditor
          onClick={() => setIsEditing(true)}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          matterFieldValue={matterFieldValue}
          updateMatterFieldValues={updateMatterFieldValues}
        />
      </MatterFieldRowValueContainer>
    </ClickAwayListener>
  );
}

MatterFieldValue.propTypes = {
  matterFieldValue: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    matterField: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      type: PropTypes.any,
      name: PropTypes.string,
    }),
    value: PropTypes.any,
  }),
  setMatterFieldIdToDelete: PropTypes.func,
  updateMatterFieldValues: PropTypes.func,
};
