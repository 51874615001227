import TagManager from 'react-gtm-module';

import { getThemeByDomain } from './Themes/Themes';
import { isE2ETest } from './Utilities';

export function initializeGTM() {
  if (isE2ETest()) {
    return;
  }

  const { gtm } = getThemeByDomain();

  const args = gtm || {
    gtmId: process.env.REACT_APP_GTM_ID,
    auth: process.env.REACT_APP_GTM_AUTH,
    preview: process.env.REACT_APP_GTM_PREVIEW,
  };

  TagManager.initialize(args);
}

export function googleTrackCompleteRegistration() {
  TagManager.dataLayer({
    dataLayer: {
      event: 'completeRegistration',
    },
  });
}

export function googleTrackViewCheckout() {
  TagManager.dataLayer({
    dataLayer: {
      event: 'viewCheckout',
    },
  });
}

export function googleTrackViewServiceDetail(serviceId) {
  TagManager.dataLayer({
    dataLayer: {
      event: 'viewServiceDetail',
      serviceId,
    },
  });
}

export function googleTrackSubscribe() {
  TagManager.dataLayer({
    dataLayer: {
      event: 'subscribe',
    },
  });
}
