import { gql, useMutation } from '@apollo/client';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dateformat from 'dateformat';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';
import { handleException } from 'utils/ErrorUtils';

import { friendlyDuration } from '../../../utils/DateUtils';
import { SCREEN_SIZES } from '../../LayoutConstants';
import Button, { TextButton } from '../../standard/Button';
import Card from '../../standard/Card';
import DropdownMenu from '../../standard/DropdownMenu';
import { TypographyStyles } from '../../Typography';
import { SignatureRequestStatusBadge } from './SignatureRequestStatusBadge';

const SEND_SIGNATURE_REQUEST_REMINDER = gql`
  mutation sendSignatureRequestReminder($input: SendRequestReminderInput) {
    sendRequestReminder(input: $input) {
      recordId
      record {
        id
        requesterEmailAddress
        createdAt
        detailsUrl
        title
        status
        signatures {
          id
          name
          email
          status
          signedAt
          lastViewedAt
          lastRemindedAt
        }
      }
    }
  }
`;

const ONE_HOUR_IN_MILLISECONDS = 60 * 60 * 1_000;

const SignatureStatusCardContainer = styled(Card)`
  padding: 0;
  overflow: hidden;

  max-width: 60vw;

  @media ${SCREEN_SIZES.SMALL} {
    max-width: 80vw;
  }
`;

const SignatureRequestStatusItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 2rem 0.5rem 1rem;

  &:hover {
    background-color: ${({ theme }) => theme.palette.shade.gray_5};
  }

  &:not(:last-child) {
    border-bottom: solid ${({ theme }) => theme.palette.shade.gray_4} 0.25px;
    padding-bottom: 0.5rem;
  }

  &:first-child {
    padding-top: 1rem;
  }

  &:last-child {
    padding-bottom: 1rem;
  }
`;

const NewSignatureRequestButtonContainer = styled(SignatureRequestStatusItem)`
  justify-content: space-around;
`;

const NewSignatureRequestButton = styled(Button)`
  padding: 0;
  margin: 0;
`;

const SignatureRequestStatusRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SignatureRequestEllipsisSpan = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SignaturesListContainer = styled.div`
  margin-left: 1.25rem;
  height: ${({ expanded }) => (expanded ? 'auto' : '0')};
  overflow: hidden;
`;

const SenderContainer = styled.div`
  color: ${({ theme }) => theme.palette.shade.gray_2};

  ${TypographyStyles.FontFamily}
  ${TypographyStyles.P}
`;

const SignatureItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: 0.5rem;

  max-width: 100%;

  ${TypographyStyles.Color}
  ${TypographyStyles.FontFamily}
  ${TypographyStyles.P}
`;

const SignatureInfo = styled.div`
  display: flex;
  flex-direction: column;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 0.5rem;

  & a {
    color: ${({ theme }) => theme.palette.primary.main};

    &:hover {
      color: ${({ theme }) => theme.palette.primary.dark};
    }
  }
`;

const SignatureStatus = styled.div`
  white-space: nowrap;
  color: ${({ theme }) => theme.palette.shade.gray_2};
`;

const ToggleButton = styled.button`
  display: flex;

  border: none;
  background-color: transparent;

  padding: 0;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${TypographyStyles.Color}
  ${TypographyStyles.FontFamily}
  ${TypographyStyles.H6}

  margin-right: 0.5rem;
`;

const StyledIconContainer = styled.div`
  transform: ${({ expanded }) =>
    expanded ? `rotate(-180deg)` : `rotate(0deg)`};
  color: ${({ theme, expanded }) =>
    expanded ? theme.palette.shade.gray_1 : theme.palette.shade.gray_2};

  transition: all 100ms ease-in;

  margin-right: 0.5rem;
`;

const DropdownMenuContainer = styled.div`
  position: absolute;
  top: 2px;
  right: -22px;
  z-index: 999;
`;

const MenuItemLabelIcon = styled.span`
  font-size: 1rem;
`;

export function SignatureStatusCard({
  initialExpandedState = false,
  signatureRequests = [],
  setDisplaySendForSigning,
  onSignatureReminder,
  setShowCancelDialog,
  setSelectedSignatureRequestId,
  ...props
}) {
  const [now, setNow] = useState(new Date());
  const [expanded, setExpanded] = useState(
    Object.fromEntries(
      signatureRequests.map(({ id }) => [id, initialExpandedState]),
    ),
  );

  useEffect(() => {
    const intervalId = window.setInterval(() => setNow(new Date()), 5000);
    return () => {
      window.clearInterval(intervalId);
    };
  });

  const [sendRequestReminder, { loading }] = useMutation(
    SEND_SIGNATURE_REQUEST_REMINDER,
    {
      onCompleted: (data) => {
        const {
          sendRequestReminder: {
            record: { id: signatureRequestId, signatures },
          },
        } = data;
        onSignatureReminder(signatureRequestId, signatures);
        toast.info('Reminder sent');
        setNow(new Date());
      },
      onError: (error) => {
        toast.error(
          'Oops! Something went wrong sending your reminder. Please try again later',
        );
        handleException(error);
      },
    },
  );

  return (
    <>
      <SignatureStatusCardContainer variant="variant-one" {...props}>
        {signatureRequests?.map(
          ({
            id,
            requesterEmailAddress,
            title,
            status,
            createdAt,
            signatures = [],
          }) => {
            const createdAtDate = new Date(createdAt);

            return (
              <SignatureRequestStatusItem key={id}>
                <SignatureRequestStatusRow>
                  <ToggleButton
                    type="button"
                    onClick={() =>
                      setExpanded({
                        ...expanded,
                        [id]: !expanded[id],
                      })
                    }
                  >
                    <StyledIconContainer expanded={expanded[id]}>
                      <FontAwesomeIcon icon={faChevronDown} />
                    </StyledIconContainer>
                    <SignatureRequestEllipsisSpan title={title}>
                      {title}
                    </SignatureRequestEllipsisSpan>
                  </ToggleButton>
                  <SignatureRequestStatusBadge status={status} />
                  {status === 'AWAITING_SIGNATURES' && (
                    <DropdownMenuContainer>
                      <DropdownMenu
                        placement="bottom-start"
                        variant="small"
                        id={id}
                        menuItems={[
                          {
                            labelIcon: (
                              <MenuItemLabelIcon>&times;</MenuItemLabelIcon>
                            ),
                            label: 'Cancel Awaiting Signatures',

                            onClick: () => {
                              setSelectedSignatureRequestId(id);
                              setShowCancelDialog(true);
                            },
                          },
                        ]}
                      />
                    </DropdownMenuContainer>
                  )}
                </SignatureRequestStatusRow>
                <SignaturesListContainer expanded={!!expanded[id]}>
                  <SenderContainer title={createdAtDate.toLocaleString()}>
                    Created by {requesterEmailAddress ?? 'unknown'} on{' '}
                    {dateformat(createdAtDate, 'mmmm d, yyyy')}
                  </SenderContainer>
                  {signatures.map(
                    ({ id: sigId, name, email, signedAt, lastRemindedAt }) => {
                      const signedAtDate = signedAt
                        ? new Date(signedAt)
                        : undefined;
                      const lastRemindedAtDate = lastRemindedAt
                        ? new Date(lastRemindedAt)
                        : undefined;

                      const createdOrRemindedDate =
                        lastRemindedAtDate ?? createdAtDate;
                      const isOverAnHourOld =
                        now.getTime() - createdOrRemindedDate.getTime() >
                        ONE_HOUR_IN_MILLISECONDS;

                      return (
                        <SignatureItem key={sigId}>
                          <SignatureInfo>
                            <SignatureRequestEllipsisSpan
                              title={`${name} <${email}>`}
                            >
                              {name}&nbsp;&lt;
                              <a href={`mailto:${email}`}>{email}</a>&gt;
                            </SignatureRequestEllipsisSpan>
                          </SignatureInfo>
                          {!signedAtDate &&
                            status === 'AWAITING_SIGNATURES' && (
                              <SignatureStatus>
                                {!!createdOrRemindedDate && (
                                  <span
                                    title={createdOrRemindedDate.toLocaleString()}
                                  >
                                    Last sent{' '}
                                    {friendlyDuration(
                                      createdOrRemindedDate,
                                      now,
                                    )}
                                    .{' '}
                                  </span>
                                )}
                                {isOverAnHourOld && (
                                  <TextButton
                                    className="rally-typography rally-typography-p"
                                    type="button"
                                    disabled={!!loading}
                                    onClick={async () =>
                                      sendRequestReminder({
                                        variables: {
                                          input: {
                                            signatureRequestId: id,
                                            emailAddress: email,
                                          },
                                        },
                                      })
                                    }
                                  >
                                    Remind
                                  </TextButton>
                                )}
                              </SignatureStatus>
                            )}
                          {!!signedAtDate && (
                            <SignatureStatus>
                              <span title={signedAtDate.toLocaleString()}>
                                signed&nbsp;
                                {dateformat(signedAtDate, 'mmmm d, yyyy')}
                              </span>
                            </SignatureStatus>
                          )}
                        </SignatureItem>
                      );
                    },
                  )}
                </SignaturesListContainer>
              </SignatureRequestStatusItem>
            );
          },
        )}
        {setDisplaySendForSigning && (
          <NewSignatureRequestButtonContainer>
            <NewSignatureRequestButton
              onClick={() => setDisplaySendForSigning(true)}
              pure
            >
              New signature request
            </NewSignatureRequestButton>
          </NewSignatureRequestButtonContainer>
        )}
      </SignatureStatusCardContainer>
    </>
  );
}

SignatureStatusCard.propTypes = {
  initialExpandedState: PropTypes.bool,
  setDisplaySendForSigning: PropTypes.func,
  signatureRequests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      createdAt: PropTypes.string,
      detailsUrl: PropTypes.string,
      title: PropTypes.string,
      status: PropTypes.string,
      signatures: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          email: PropTypes.string,
          status: PropTypes.string,
          signedAt: PropTypes.string,
          lastViewedAt: PropTypes.string,
          lastRemindedAt: PropTypes.string,
        }),
      ),
    }),
  ),
};
