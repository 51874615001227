import React, { forwardRef, useEffect, useRef, useState } from 'react';

import AutoSuggest from './AutoSuggest';
import { CANADA_PROVINCES, COUNTRIES, US_STATES } from './Constants';
import { FloatingTextField } from './FormStyles';

const COUNTRY_JURISDICTIONS = {
  'United States of America': {
    subnationalDivisionLabel: 'State',
    subnationalDivisions: US_STATES,
    showCounty: true,
  },
  Canada: {
    subnationalDivisionLabel: 'Federal/Provincial',
    subnationalDivisions: ['Federal', ...CANADA_PROVINCES],
  },
};

export function getCountryJurisdictionDetails(country) {
  return (
    COUNTRY_JURISDICTIONS[country] || {
      subnationalDivisionLabel: 'State/Province',
    }
  );
}

function getSuggestionsFunction(possibleValues) {
  return function getSuggestions(input) {
    const inputValue = input.trim().toLowerCase();
    return possibleValues
      .filter((value) => value.toLowerCase().startsWith(inputValue))
      .map((label) => ({ label }));
  };
}

function Jurisdiction(
  { name, jurisdiction, requireCounty = false, onChange, onBlur, ...props },
  ref,
) {
  const [shrinkLabel, setShrinkLabel] = useState({});

  const onBlurTimerIdRef = useRef();

  const delayedOnBlur = () => {
    if (onBlur) {
      onBlur({
        target: {
          name,
          value: jurisdiction,
          type: 'Jurisdiction',
        },
      });
    }
  };
  const clearBlurTimeout = () => clearTimeout(onBlurTimerIdRef.current);
  useEffect(() => () => clearBlurTimeout(), []);

  const handleFocus = (element) => {
    setShrinkLabel({ ...shrinkLabel, [element]: true });
    clearBlurTimeout();
  };

  const handleSubfieldOnBlur = (element) => {
    if (element) {
      setShrinkLabel({ ...shrinkLabel, [element]: false });
    }
    onBlurTimerIdRef.current = setTimeout(delayedOnBlur, 500);
  };

  const updateJurisdiction = (newJurisdictionValue) =>
    onChange({
      target: { name, value: { ...jurisdiction, ...newJurisdictionValue } },
    });

  const jurisdictionDetails = getCountryJurisdictionDetails(
    jurisdiction?.country,
  );

  return (
    <div {...props}>
      <div className="row">
        <div className="col-12 col-lg-6 mt-15">
          <AutoSuggest
            value={jurisdiction?.country || ''}
            placeholder="Country"
            ref={ref}
            getSuggestions={getSuggestionsFunction(COUNTRIES)}
            onChange={(_event, { newValue }) =>
              updateJurisdiction({
                country: newValue,
                subnationalDivision: null,
                county: null,
              })
            }
          />
        </div>
      </div>
      {jurisdiction.country && (
        <>
          <div className="row">
            {jurisdictionDetails.subnationalDivisions ? (
              <div className="col-12 col-lg-6 mt-15">
                <AutoSuggest
                  value={jurisdiction?.subnationalDivision || ''}
                  placeholder={jurisdictionDetails.subnationalDivisionLabel}
                  getSuggestions={getSuggestionsFunction(
                    jurisdictionDetails.subnationalDivisions,
                  )}
                  onChange={(_event, { newValue }) =>
                    updateJurisdiction({ subnationalDivision: newValue })
                  }
                  onBlur={() => handleSubfieldOnBlur('country')}
                />
              </div>
            ) : (
              <div className="col-12 col-lg-6">
                <FloatingTextField
                  label={jurisdictionDetails.subnationalDivisionLabel}
                  name="subnationalDivision"
                  value={jurisdiction?.subnationalDivision}
                  type="text"
                  onChange={({ target: { value } }) =>
                    updateJurisdiction({ subnationalDivision: value })
                  }
                  onBlur={() => handleSubfieldOnBlur('subnationalDivision')}
                  onFocus={() => handleFocus('subnationalDivision')}
                  InputLabelProps={{
                    shrink:
                      jurisdiction.subnationalDivision ||
                      shrinkLabel.subnationalDivision,
                    classes: {
                      root: 'rootLabel',
                      focused: 'focusedLabel',
                      shrink: 'shrinkLabel',
                    },
                  }}
                />
              </div>
            )}
          </div>
          {requireCounty && jurisdictionDetails.showCounty && (
            <div className="row">
              <div className="col-12 col-lg-6">
                <FloatingTextField
                  label="County"
                  name="county"
                  value={jurisdiction?.county}
                  type="text"
                  onChange={({ target: { value } }) =>
                    updateJurisdiction({ county: value })
                  }
                  onBlur={() => handleSubfieldOnBlur('county')}
                  onFocus={() => handleFocus('county')}
                  InputLabelProps={{
                    shrink: jurisdiction.county || shrinkLabel.county,
                    classes: {
                      root: 'rootLabel',
                      focused: 'focusedLabel',
                      shrink: 'shrinkLabel',
                    },
                  }}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default forwardRef(Jurisdiction);
