import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ContactSubField } from './ContactSubField';

function getFieldVisibility(fieldConfig, fieldName) {
  if (fieldConfig == null) {
    // If no field config is available, assume all fields are visible.
    return true;
  }
  return fieldConfig[fieldName]?.visible ?? false;
}

export function ContactForm({
  name,
  contact,
  onChange,
  onBlur,
  errors,
  fieldConfig,
  contactType,
  required,
  clientId,
  focusInitialField,
  hideFieldsForEmptyContact = false,
}) {
  const formRef = useRef();

  const nameRef = useRef();
  const typeRef = useRef();
  const phoneRef = useRef();
  const emailRef = useRef();
  const addressRef = useRef();

  const [contactData, setContactData] = useState({});
  const [isBlurring, setIsBlurring] = useState(false);

  const updateContactData = useCallback(
    (newContactData) => {
      const updatedContactData =
        contactType && newContactData?.name
          ? { ...newContactData, type: contactType }
          : newContactData;
      setContactData(updatedContactData || {});
      return updatedContactData;
    },
    [setContactData, contactType],
  );

  // Ensure the latest contact data is propagated to the local state.
  useEffect(() => {
    updateContactData(contact);
  }, [updateContactData, contact]);

  useEffect(() => {
    switch (focusInitialField) {
      case 'name':
        nameRef.current?.focus();
        break;
      case 'type':
        typeRef.current?.focus();
        break;
      case 'email':
        emailRef.current?.focus();
        break;
      case 'phone':
        phoneRef.current?.focus();
        break;
      case 'address':
        addressRef.current?.focus();
        break;
      default:
        break;
    }
  }, [focusInitialField]);

  // onChange is too expensive to do on every field change,
  // so just do it on name and type change
  const onContactChange = useCallback(
    (newContactData) => {
      const updatedContactData = updateContactData(newContactData);
      onChange({
        target: { name, value: updatedContactData, type: 'Contact' },
      });
    },
    [updateContactData, onChange, name],
  );

  const onContactFormBlur = useCallback(
    (event) => {
      if (!onBlur || isBlurring) return;
      setIsBlurring(true);
      event.stopPropagation();
      const { currentTarget } = event;
      // Check the newly focused element in the next tick of the event loop
      setTimeout(async () => {
        if (!currentTarget?.contains(document.activeElement)) {
          onBlur({
            target: { name, value: contactData, type: 'Contact' },
          });
        }
        setIsBlurring(false);
      }, 0);
    },
    [name, onBlur, contactData, isBlurring],
  );

  return (
    <div ref={formRef} onBlur={onContactFormBlur}>
      <ContactSubField
        contactData={contactData}
        onChange={clientId ? onContactChange : updateContactData}
        errors={errors}
        fieldName={name}
        subfieldName="name"
        subfieldLabel="Name"
        subFieldType={clientId ? 'ContactNameComboBox' : 'Text'}
        contactType={contactType}
        clientId={clientId}
        required={required}
        autoCapitalize="words"
        ref={nameRef}
      />
      {(contactData.name || !hideFieldsForEmptyContact) && (
        <>
          {!contactType && (
            <ContactSubField
              contactData={contactData}
              onChange={onContactChange}
              errors={errors}
              fieldName={name}
              subfieldName="type"
              subfieldLabel="Type"
              subFieldType="ContactType"
              required={required}
              ref={typeRef}
            />
          )}
          {getFieldVisibility(fieldConfig, 'address') && (
            <ContactSubField
              contactData={contactData}
              onChange={updateContactData}
              errors={errors}
              fieldName={name}
              subfieldName="address"
              subfieldLabel="Address"
              subFieldType="Address"
              required={required || fieldConfig?.address?.required}
              ref={addressRef}
            />
          )}
          {getFieldVisibility(fieldConfig, 'phone') && (
            <ContactSubField
              contactData={contactData}
              onChange={updateContactData}
              errors={errors}
              fieldName={name}
              subfieldName="phone"
              subfieldLabel="Phone Number"
              subFieldType="PhoneNumber"
              required={required || fieldConfig?.phone?.required}
              ref={phoneRef}
            />
          )}
          {getFieldVisibility(fieldConfig, 'email') && (
            <ContactSubField
              contactData={contactData}
              onChange={updateContactData}
              errors={errors}
              fieldName={name}
              subfieldName="email"
              subfieldLabel="Email"
              subFieldType="Email"
              required={required || fieldConfig?.email?.required}
              ref={emailRef}
            />
          )}
        </>
      )}
    </div>
  );
}
