import { gql, useMutation } from '@apollo/client';
import { useFeatureToggle } from '@flopflip/react-broadcast';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { faFileSignature, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { forwardRef, useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { handleException } from 'utils/ErrorUtils';

import { flagNames } from '../../../featureFlags';
import { userIsClient } from '../../../services/Utilities';
import { ConfirmationDialog } from '../../standard/ConfirmationDialog';
import ListViewItem from '../../standard/ListViewItem';
import { PopperMenu } from '../../standard/PopperMenu';
import DeleteMatterModal from '../DeleteMatterModal';
import MarkMatterComplete from '../view/MarkMatterComplete';
import MatterStateBadge from '../view/MatterStateBadge';
import { SignatureStatusCard } from '../view/SignatureStatusCard';

const IN_PROGRESS_STATES = ['qSent', 'qInProgress'];

export const MatterViewFragment = gql`
  fragment MatterView on Matter {
    matterId: _id
    name
    state
    updatedAt
    createdAt
    canRead
    sharedWithClient
    legacyQuestionnaireState
    matterVersion
    owner
    organization {
      _id
      name
    }
    signatureRequests {
      id
      requesterEmailAddress
      createdAt
      detailsUrl
      title
      status
      signatures {
        id
        name
        email
        status
        signedAt
        lastViewedAt
        lastRemindedAt
      }
    }
  }
`;

const CANCEL_SIGNATURE_REQUEST = gql`
  mutation cancelSignatureRequest($input: CancelRequestInput) {
    cancelRequest(input: $input) {
      record {
        id
      }
    }
  }
`;

function ClientMatterDescription({
  updatedAt,
  createdAt,
  sharedWithClient,
  state,
  signatureStatusDateString,
}) {
  const dateString =
    updatedAt !== createdAt
      ? `Last Modified ${new Date(updatedAt).toLocaleString('en-US')}`
      : `Created on ${new Date(createdAt).toLocaleString('en-US')}`;

  if (!sharedWithClient) {
    return state === 'open'
      ? 'This matter is being reviewed by your service provider.'
      : 'This matter has not been shared by your service provider.';
  }
  return signatureStatusDateString ?? dateString;
}

function FirmMatterDescription({
  updatedAt,
  createdAt,
  sharedWithClient,
  organization,
  signatureStatusDateString,
}) {
  const { name } = organization ?? {};
  const dateString =
    updatedAt !== createdAt
      ? `Last Modified ${new Date(updatedAt).toLocaleString('en-US')}`
      : `Created on ${new Date(createdAt).toLocaleString('en-US')}`;

  return (
    <>
      {!!name && <>{`${name} | `}</>}
      {sharedWithClient ? (
        <>
          <FontAwesomeIcon icon={faEye} /> {' Shared | '}
        </>
      ) : (
        <>
          <FontAwesomeIcon icon={faEyeSlash} /> {'Not Shared | '}
        </>
      )}
      {signatureStatusDateString ?? dateString}
    </>
  );
}

export const MatterListViewItem = forwardRef(
  (
    {
      matter,
      disableMenu = false,
      menuItems = [],
      refetch,
      onDelete,
      signatureStatus,
      onMarkComplete,
      selectedSignatureRequestId,
      setSelectedSignatureRequestId,
      onCancelSignatureRequest,
      ...props
    },
    ref,
  ) => {
    const userType = localStorage.getItem('userType');
    const userId = localStorage.getItem('userId');

    const dropdownMenuRef = useRef(null);

    const history = useHistory();

    const [matterToDelete, setMatterToDelete] = useState(null);
    const [showSignatureStatusCard, setShowSignatureStatusCard] =
      useState(false);
    const [showCancelDialog, setShowCancelDialog] = useState(false);
    const [currentMatter, setCurrentMatter] = useState(matter);

    const enableClientMatterManagement = useFeatureToggle(
      flagNames.ENABLE_CLIENT_MATTER_MANAGEMENT,
    );

    const {
      canRead,
      createdAt,
      legacyQuestionnaireState,
      matterId,
      matterVersion,
      name,
      sharedWithClient,
      state,
      updatedAt,
      organization,
      owner,
      signatureRequests,
    } = currentMatter;

    const isCompletable =
      !!onMarkComplete &&
      (!userIsClient() || (sharedWithClient && enableClientMatterManagement)) &&
      signatureStatus === 'COMPLETE';

    let signatureStatusDateString;
    if (signatureStatus === 'AWAITING_SIGNATURES') {
      const latestSignatureReqRemindDate = Math.max(
        ...signatureRequests
          .flatMap((signatureRequest) => signatureRequest.signatures)
          .map((signature) => new Date(signature.lastRemindedAt)),
      );

      const latestSignatureReqCreatedDate = Math.max(
        ...signatureRequests.map(
          (signatureRequest) => new Date(signatureRequest.createdAt),
        ),
      );

      signatureStatusDateString = latestSignatureReqRemindDate
        ? `Request for signatures sent on ${new Date(
            latestSignatureReqRemindDate,
          ).toLocaleString('en-US')}`
        : `Request for signatures sent on ${new Date(
            latestSignatureReqCreatedDate,
          ).toLocaleString('en-US')}`;
    }
    if (signatureStatus === 'COMPLETE') {
      const latestSignatureReqSignedDate = Math.max(
        ...signatureRequests
          .flatMap((signatureRequest) => signatureRequest.signatures)
          .map((signature) => new Date(signature.signedAt)),
      );

      signatureStatusDateString = latestSignatureReqSignedDate
        ? `Signatures signed on ${new Date(
            latestSignatureReqSignedDate,
          ).toLocaleString('en-US')}`
        : undefined;
    }

    const description =
      userType === 'client' ? (
        <ClientMatterDescription
          updatedAt={updatedAt}
          createdAt={createdAt}
          sharedWithClient={sharedWithClient}
          state={state}
          signatureStatusDateString={signatureStatusDateString}
        />
      ) : (
        <FirmMatterDescription
          updatedAt={updatedAt}
          createdAt={createdAt}
          sharedWithClient={sharedWithClient}
          organization={organization}
          signatureStatusDateString={signatureStatusDateString}
        />
      );

    const onClick = useCallback(() => {
      if (userIsClient()) {
        history.push(
          `/client/${matterVersion === 1 ? 'matter' : 'matters'}/${matterId}`,
        );
      } else {
        const clientPath = organization ? `/clients/${organization._id}` : '';
        const matterPath = matterVersion === 1 ? 'contracts' : 'matters';
        const matterSuffix = IN_PROGRESS_STATES.includes(
          legacyQuestionnaireState,
        )
          ? '/edit'
          : '';
        history.push(`${clientPath}/${matterPath}/${matterId}${matterSuffix}`);
      }
    }, [
      matterId,
      matterVersion,
      legacyQuestionnaireState,
      organization,
      history,
    ]);

    const onSignatureReminder = async (signatureRequestId, signatures) => {
      const updatedSignatureRequests =
        await currentMatter.signatureRequests.map((signatureRequest) => {
          if (signatureRequest.id === signatureRequestId) {
            return {
              ...signatureRequest,
              signatures,
            };
          }
          return signatureRequest;
        });
      setCurrentMatter({
        ...currentMatter,
        signatureRequests: updatedSignatureRequests,
      });
    };

    const [cancelRequest] = useMutation(CANCEL_SIGNATURE_REQUEST, {
      onCompleted: async (data) => {
        const {
          cancelRequest: {
            record: { id: signatureRequestId },
          },
        } = data;

        refetch();
        toast.info('Signature request cancelled', signatureRequestId);
      },
    });

    const cancelSignatureRequestCallback = useCallback(
      async (signatureRequestId) => {
        try {
          await cancelRequest({
            variables: {
              input: {
                signatureRequestId,
                matterId: currentMatter.matterId,
                matterVersion: currentMatter.matterVersion,
              },
            },
          });
        } catch (error) {
          handleException(error);
          toast.error(
            'Oops! Something went wrong cancelling the signature request. Please try again later',
          );
        }
      },
      [cancelRequest, currentMatter],
    );

    const canDelete =
      (!userIsClient() ||
        enableClientMatterManagement ||
        owner === userId.replace(/^.*\|/, '')) &&
      canRead;

    const enableSignatureStatus = signatureStatus && canDelete;

    return (
      <>
        <ListViewItem
          {...props}
          id={matterId}
          key={matterId}
          title={name}
          description={description}
          onClick={canRead ? onClick : undefined}
          isActive={!!canRead}
          maintainMenuSpace={!disableMenu}
          dropdownMenuRef={dropdownMenuRef}
          menuItems={
            !disableMenu &&
            [
              ...menuItems,
              enableSignatureStatus && {
                labelIcon: <FontAwesomeIcon icon={faFileSignature} />,
                label: 'View signature requests',
                onClick: () => {
                  setShowSignatureStatusCard(true);
                  setCurrentMatter(matter);
                },
              },
              canDelete && {
                labelIcon: <FontAwesomeIcon icon={faTrashAlt} />,
                label: 'Delete Matter',
                onClick: (e) => {
                  e.stopPropagation();
                  setMatterToDelete(matter);
                },
              },
            ].filter((menuItem) => !!menuItem)
          }
          right={
            <>
              <MatterStateBadge
                collapseOnMobile
                state={legacyQuestionnaireState || state}
                isActive={!!canRead}
                signatureStatus={signatureStatus}
              />
              {isCompletable && (
                <MarkMatterComplete
                  matter={matter}
                  onMarkComplete={onMarkComplete}
                  refetch={refetch}
                />
              )}
            </>
          }
          ref={ref}
        />
        <DeleteMatterModal
          matterToDelete={matterToDelete}
          onClose={() => setMatterToDelete(null)}
          onDelete={onDelete}
        />
        <PopperMenu
          anchorEl={dropdownMenuRef.current}
          open={showSignatureStatusCard}
          setOpen={setShowSignatureStatusCard}
          placement="bottom-start"
        >
          <SignatureStatusCard
            signatureRequests={signatureRequests}
            setSelectedSignatureRequestId={setSelectedSignatureRequestId}
            setShowCancelDialog={setShowCancelDialog}
            initialExpandedState
            onSignatureReminder={onSignatureReminder}
          />
        </PopperMenu>
        <ConfirmationDialog
          open={showCancelDialog}
          onClose={() => setShowCancelDialog(false)}
          onConfirm={async () => {
            await cancelSignatureRequestCallback(selectedSignatureRequestId);
            setShowCancelDialog(false);
            setShowSignatureStatusCard(false);
          }}
          title="Cancel signature request"
          message="Once cancelled, signers will no longer be able to sign. Partially signed documents will be cancelled as well. This cannot be undone and a new request will be required to collect signatures."
          confirmText="Cancel request"
          cancelText={null}
          confirmButtonPalette="destructive"
        />
      </>
    );
  },
);
