import {
  faCloudDownload,
  faFolderOpen,
} from '@fortawesome/pro-light-svg-icons';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';

import { trackFileDownload } from '../../../services/Analytics';
import {
  getApplication,
  openInWopiHandler,
  OpenInWopiLabel,
} from '../../../services/WopiUtilities';
import {
  CardIcon,
  CardTitle,
  DashboardCard,
} from '../../dashboard/DashboardStyles';
import Action, { ActionIcon } from './actions/Action';
import MatterFileExplorer from './MatterFileExplorer';

const ActionRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

function MatterFileExplorerCard({
  accessToken,
  currentFile,
  currentFolderId,
  fetchDownloadAllUrl,
  matterId,
  rootFolderId,
  setCurrentFile,
}) {
  const [application, setApplication] = useState(null);
  const [currentFiles, setCurrentFiles] = useState([]);

  useEffect(() => {
    if (!currentFile) return;
    const { name, extension } = currentFile;
    setApplication(getApplication(name, extension));
  }, [currentFile]);

  const onDownloadAllClick = useCallback(async () => {
    toast.success('Preparing files for download');
    trackFileDownload('Matter Workspace (All)');
    const downloadUrl = await fetchDownloadAllUrl();
    if (downloadUrl) {
      window.location.assign(`${downloadUrl}`);
      toast.dismiss();
    } else {
      toast.dismiss();
      toast.error(
        'Something went wrong downloading the zip file. Please try again',
      );
    }
  }, [fetchDownloadAllUrl]);

  return (
    <div className="mb-30">
      <CardTitle>
        <CardIcon icon={faFolderOpen} fixedWidth />
        Matter Files
      </CardTitle>
      <DashboardCard noPadding>
        <MatterFileExplorer
          rootFolderId={rootFolderId}
          currentFolderId={currentFolderId}
          token={accessToken}
          onSelect={(files) => {
            if (files.length && files[0].type === 'file') {
              setCurrentFile(files[0]);
            }
          }}
          onUpload={async ([file]) => {
            setCurrentFiles([...currentFiles, file.id]);

            if (file?.id) {
              // if uploading a file with the same id, clear currentFile state first
              if (currentFiles.some((currFile) => currFile === file.id)) {
                await setCurrentFile();
              }
              setCurrentFile(file);
            }
          }}
          onNavigate={(file) => {
            const files = file?.item_collection?.entries.filter((fileId) =>
              fileId.includes('file'),
            );
            const fileIds = files.map((fileId) => fileId.split('_')[1]);
            setCurrentFiles(fileIds);
            if (files?.length) {
              setCurrentFile({ id: fileIds[0] });
            } else {
              setCurrentFile(undefined);
            }
          }}
          onDelete={(file) => {
            const remainingFiles = currentFiles.filter(
              (fileId) => fileId !== file[0].id,
            );
            setCurrentFiles(remainingFiles);
            if (remainingFiles.length) {
              setCurrentFile({ id: remainingFiles[0] });
            } else {
              setCurrentFile(undefined);
            }
          }}
        />
      </DashboardCard>
      <ActionRow>
        <Action onClick={onDownloadAllClick}>
          <ActionIcon icon={faCloudDownload} />
          Download All
        </Action>
        {application && (
          <Action
            onClick={openInWopiHandler({
              file: currentFile || {},
              origin: 'matter',
              originId: matterId,
            })}
          >
            <OpenInWopiLabel application={application} />
          </Action>
        )}
      </ActionRow>
    </div>
  );
}

export default MatterFileExplorerCard;
