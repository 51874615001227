import { useEffect } from 'react';
import { withTheme } from 'styled-components/macro';

const THEME_SLUG = 'rallynow.io-theme';

export default withTheme(({ theme }) => {
  useEffect(() => {
    window.CommandBar.setTheme(THEME_SLUG, theme.palette.primary.main);
  }, [theme.palette.primary.main]);

  return null;
});
