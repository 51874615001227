import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import TemplateGeneratedField from '../../../../contracts/TemplateGeneratedField';

export default function TemplateGeneratedFieldInput({
  label,
  name,
  identifier,
  defaultValue,
  type = 'String',
  required,
  minLength,
  maxLength,
  max,
  min,
  pattern,
  validate,
  valueAsNumber,
  valueAsDate,
  setValueAs,
  value,
  shouldUnregister,
  children,
  ...props
}) {
  const { control } = useFormContext();

  const options = Object.fromEntries(
    Object.entries({
      required:
        required && typeof required === 'boolean'
          ? { value: true, message: 'This field is required' }
          : required,
      minLength,
      maxLength,
      max,
      min,
      pattern,
      validate,
      valueAsNumber,
      valueAsDate,
      setValueAs,
      value,
      shouldUnregister,
    }).filter(([, v]) => v != null),
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ required, ...options }}
      render={({
        field: { name: fieldName, ...field },
        fieldState: { error },
      }) => (
        <TemplateGeneratedField
          errors={error ? [error?.message] : []}
          identifier={fieldName}
          name={label}
          type={type}
          marginBottom="4rem"
          required={!!required}
          {...field}
          {...props}
        />
      )}
    />
  );
}
