import { gql, useMutation } from '@apollo/client';
import React, { useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import styled, { ThemeContext } from 'styled-components/macro';
import { handleException } from 'utils/ErrorUtils';

import { useOrganizationPermission } from '../../../context/OrganizationPermissionContext';
import { trackInviteUser } from '../../../services/Analytics';
import { validateEmail } from '../../../services/Utilities';
import { Input } from '../../forms/FormStyles';
import Button from '../../standard/Button';
import Spinner from '../../standard/Spinner';
import ClientTeamMember from './ClientTeamMember';
import { SetPermissionsModal } from './SetPermissionsModal';

const ClientMembersContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SectionHeader = styled.h5`
  margin-top: 30px;
`;

const AddMemberContainer = styled.div`
  display: flex;
  align-items: center;
`;

const EmailInput = styled(Input).attrs({ smallfield: true })`
  margin: 0;
  height: 38px;
  flex-grow: 1;
`;

const InputContainer = styled.div`
  flex-grow: 1;
  margin-right: 20px;
`;

const INVITE_CLIENT_TEAM_MEMBER = gql`
  mutation inviteClientTeamMember(
    $input: SendOrganizationUserInvitationInput!
  ) {
    sendOrganizationUserInvitation(input: $input) {
      recordId
      record {
        invitationContact {
          email
        }
      }
    }
  }
`;

export function ClientMembersDialogContent({
  clientId,
  clientName,
  refetch = async () => {},
  clientUsers = [],
  pendingInvitations = [],
}) {
  const { palette } = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(false);
  const [newClientEmailAddress, setNewClientEmailAddress] = useState('');

  const { canManagePermissions, canManageTeamMembers } =
    useOrganizationPermission();

  const [showInvitePermissionsModal, setShowInvitePermissionsModal] =
    useState(false);

  const [inviteClientTeamMember] = useMutation(INVITE_CLIENT_TEAM_MEMBER, {
    onCompleted: async (data) => {
      const { email } =
        data?.sendOrganizationUserInvitation?.record?.invitationContact;
      trackInviteUser('client team member', email);
    },
  });

  const sendClientInvitation = useCallback(
    async ({ email, permissions }) => {
      setIsLoading(true);
      try {
        const { primary, secondary, gray } = palette;
        await inviteClientTeamMember({
          variables: {
            input: {
              organizationId: clientId,
              userEmail: email,
              palette: { primary, secondary, gray },
              permissions,
            },
          },
        });
        setShowInvitePermissionsModal(false);
        setNewClientEmailAddress('');
        await refetch();
      } catch (error) {
        handleException(error);
        toast.error(
          error.graphQLErrors?.[0]?.message ??
            'Oops! Something went wrong sending the user invitation. Please try again later.',
        );
      } finally {
        setIsLoading(false);
      }
    },
    [inviteClientTeamMember, clientId, refetch, palette],
  );

  return (
    <ClientMembersContainer>
      {isLoading && <Spinner />}
      <SectionHeader>Email</SectionHeader>
      {!clientUsers.length &&
        !pendingInvitations.length &&
        'No members of this organization have been granted access. Invite a member below.'}
      {clientUsers.map(({ _id, email }) => (
        <ClientTeamMember
          clientUserId={_id}
          key={email}
          email={email}
          clientId={clientId}
          clientName={clientName}
          setIsLoading={setIsLoading}
          refetch={refetch}
        />
      ))}
      {pendingInvitations.map(
        ({ expiresAt, invitationContact: { email }, _id: invitationId }) => (
          <ClientTeamMember
            invitationId={invitationId}
            key={email}
            email={email}
            clientId={clientId}
            clientName={clientName}
            invitation
            expiresAt={expiresAt}
            setIsLoading={setIsLoading}
            refetch={refetch}
          />
        ),
      )}
      {canManageTeamMembers && (
        <>
          <label htmlFor="inviteClientTeamMemberEmail">
            <SectionHeader>Invite Member</SectionHeader>
          </label>
          <form
            name="inviteClientUserForm"
            onSubmit={async (event) => {
              event.preventDefault();
              if (canManagePermissions) {
                setShowInvitePermissionsModal(true);
                return;
              }
              await sendClientInvitation({ email: newClientEmailAddress });
            }}
          >
            <AddMemberContainer>
              <InputContainer>
                <EmailInput
                  id="inviteClientTeamMemberEmail"
                  name="inviteClientTeamMemberEmail"
                  type="email"
                  placeholder="Enter an email address"
                  value={newClientEmailAddress}
                  onChange={(event) => {
                    setNewClientEmailAddress(event.target.value);
                  }}
                  required={false}
                  title="Enter an email address"
                />
              </InputContainer>
              <Button
                className="float-right"
                type="submit"
                disabled={
                  !newClientEmailAddress ||
                  !validateEmail(newClientEmailAddress)
                }
              >
                Add
              </Button>
            </AddMemberContainer>
          </form>
        </>
      )}
      {showInvitePermissionsModal && (
        <SetPermissionsModal
          open={showInvitePermissionsModal}
          email={newClientEmailAddress}
          onClose={() => setShowInvitePermissionsModal(false)}
          onSubmit={({
            permissionId,
            canManageTeamMembersPermissionsAndBilling,
            ...permissions
          }) => {
            sendClientInvitation({
              email: newClientEmailAddress,
              permissions: {
                ...permissions,
              },
            });
          }}
        />
      )}
    </ClientMembersContainer>
  );
}
