import { faHubspot } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { trackHubspotConnect } from '../../services/Analytics';
import Button from '../standard/Button';

const ConnectHubspotButton = ({ href, children, className, ...props }) => (
  <a href={href}>
    <Button
      className={className}
      outline
      {...props}
      onClick={() => trackHubspotConnect()}
    >
      <FontAwesomeIcon icon={faHubspot} />
      {' Connect with HubSpot'}
    </Button>
  </a>
);

const HubspotConnect = () => {
  const clientId = process.env.REACT_APP_HUBSPOT_ID;
  const redirectUri = process.env.REACT_APP_HUBSPOT_REDIRECT;
  const authUrl =
    'https://app.hubspot.com/oauth/authorize' +
    `?client_id=${encodeURIComponent(clientId)}` +
    `&scope=crm.objects.deals.read` +
    `&redirect_uri=${encodeURIComponent(redirectUri)}`;
  return <ConnectHubspotButton href={authUrl} />;
};

export default HubspotConnect;
