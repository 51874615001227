import { useFeatureToggle } from '@flopflip/react-broadcast';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { handleException } from 'utils/ErrorUtils';

import { flagNames } from '../../../featureFlags';
import { trackRequestReview } from '../../../services/Analytics';
import { Textarea } from '../../forms/FormStyles';
import Modal from '../../standard/Modal';

const RequestReviewModal = ({
  display,
  onClose,
  requestFirmReview,
  portal,
}) => {
  const [requestReviewSent, setRequestReviewSent] = useState(false);
  const [requestReviewMessage, setRequestReviewMessage] = useState('');
  const isRFB = useFeatureToggle(flagNames.RFB);

  return (
    <>
      <Modal
        display={display}
        title="Request Review"
        showCancel
        onClose={onClose}
        onCancel={() => {
          onClose();
          setRequestReviewMessage('');
        }}
        onAccept={async () => {
          trackRequestReview();
          try {
            requestFirmReview(requestReviewMessage);
            setRequestReviewSent(true);
            onClose();
          } catch (error) {
            handleException(error);
            toast.error(
              'Something went wrong requesting the matter review. Please try again',
            );
          }
        }}
        acceptText={isRFB ? 'Yes' : 'Confirm'}
        maxWidth="700px"
        portal={portal}
      >
        {isRFB ? (
          <p>
            Would you like to be connected to a qualified lawyer in your
            jurisdiction?
          </p>
        ) : (
          <>
            <p>Your service provider will be notified of your request.</p>

            <p className="mt-3">Message (optional):</p>
            <Textarea
              as="textarea"
              rows="4"
              autoComplete="no"
              style={{ fontSize: '14px', marginTop: 0 }}
              value={requestReviewMessage}
              onChange={(e) => setRequestReviewMessage(e.target.value)}
            />
          </>
        )}
      </Modal>
      <Modal
        display={requestReviewSent}
        title="Review Request Sent"
        message={
          isRFB
            ? 'Thank you for your request. We will contact you by email within 24 hours.'
            : 'Your service provider has been notified.'
        }
        cancelText="Close"
        hideAccept
        showCancel
        onCancel={() => setRequestReviewSent(false)}
        portal={portal}
      />
    </>
  );
};
export default RequestReviewModal;
