import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import styled, { css, withTheme } from 'styled-components/macro';

import { SCREEN_SIZES } from '../LayoutConstants';
import DropdownMenu from './DropdownMenu';

export const ListViewItemHeight = 103;

const ListItemContainer = styled.div`
  display: grid;

  ${({ variant }) => {
    switch (variant) {
      case 'compact':
        return css``;
      case 'default':
      default:
        return css`
          padding-left: 20px;
          padding-right: 20px;
        `;
    }
  }}

  grid-template-columns: auto 1.25fr 0.75fr max-content;
  grid-template-areas: 'icon left right menu';

  @media ${SCREEN_SIZES.X_SMALL} {
    padding-left: 10px;
    padding-right: 10px;

    grid-template-columns: auto 1fr max-content;
    grid-template-areas:
      'icon left menu'
      '. right menu';
  }
`;

const LeftContainer = styled.div`
  grid-area: left;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  overflow: hidden;
`;

const RightContainer = styled.div`
  grid-area: right;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media ${SCREEN_SIZES.X_SMALL} {
    justify-content: flex-start;
  }
`;

const MenuContainer = styled.div`
  grid-area: menu;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 45px;
`;

const ListItem = styled.div`
  margin-bottom: 2px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  ${({ theme }) => css`
    background-color: ${theme.palette.base.white};
  `}
  ${({ variant }) => {
    switch (variant) {
      case 'compact':
        return css`
          padding: 16px 24px;
          @media ${SCREEN_SIZES.X_SMALL} {
            padding: 12px;
          }
        `;
      case 'default':
      default:
        return css`
          padding: 12px;
          height: ${ListViewItemHeight}px;
        `;
    }
  }}
  ${({ onClick, theme, isActive = true }) => css`
    justify-content: center;
    &:first-child {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }
    &:last-child {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    border-bottom: 1px solid rgb(242, 242, 242);
    margin-bottom: 0;
    box-shadow: none;
    ${!isActive &&
    css`
      background-color: #f9fafa;
    `};
    ${!!onClick &&
    css`
      :hover {
        z-index: 1;
        border-bottom: none;
        box-shadow: 0 0 5px #d8dadc;
        background: linear-gradient(
              to right,
              ${theme.palette.primary.main},
              ${theme.palette.primary.main}
            )
            left/5px 100% border-box no-repeat,
          #ffffff;
        border-left: 5px solid transparent;
        cursor: pointer;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        ${({ variant }) => {
          switch (variant) {
            case 'compact':
              return css`
                padding-bottom: 17px;
                padding-left: 19px;
                @media ${SCREEN_SIZES.X_SMALL} {
                  padding-left: 7px;
                }
              `;
            case 'default':
            default:
              return css`
                padding-bottom: 13px;
                padding-left: 7px;
              `;
          }
        }}
      }
    `};
  `}
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
`;

const Icon = styled(FontAwesomeIcon)`
  grid-area: icon;
  margin-right: 16px;
  font-size: 18px;
  align-self: center;
  justify-self: center;
`;

const Title = styled.h6`
  margin-right: 5px;
  margin-bottom: 0;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Badge = styled.div`
  min-width: fit-content;
`;

const Description = styled.small`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ListViewItem = forwardRef(
  (
    {
      onClick,
      id,
      menuItems,
      title,
      description,
      right,
      icon,
      badge,
      isActive,
      maintainMenuSpace = false,
      variant = 'default',
      dropdownMenuRef,
      ...props
    },
    ref,
  ) => (
    <ListItem
      onClick={onClick}
      isActive={isActive}
      role="listitem"
      variant={variant}
      ref={ref}
      {...props}
    >
      <ListItemContainer data-testid={id} variant={variant}>
        {icon && <Icon icon={icon} />}
        <LeftContainer>
          <TitleContainer>
            <Title>{title}</Title>
            <Badge>{badge}</Badge>
          </TitleContainer>
          <Description>{description}</Description>
        </LeftContainer>
        <RightContainer>{right}</RightContainer>
        {(menuItems?.length || maintainMenuSpace) && (
          <MenuContainer maintainMenuSpace={maintainMenuSpace}>
            <DropdownMenu
              id={id}
              menuItems={menuItems}
              dropdownMenuRef={dropdownMenuRef}
            />
          </MenuContainer>
        )}
      </ListItemContainer>
    </ListItem>
  ),
);

ListViewItem.propTypes = {
  onClick: PropTypes.func,
  id: PropTypes.string,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, onClick: PropTypes.func }),
  ),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.node,
  right: PropTypes.node,
  badge: PropTypes.node,
  isActive: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'compact']),
};

export default withTheme(ListViewItem);
