import { gql, useQuery } from '@apollo/client';
import { useFeatureToggle } from '@flopflip/react-broadcast';
import { faCheck, faCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components/macro';

import { flagNames } from '../../../featureFlags';
import { getStrippedUserId } from '../../../services/Utilities';
import { Textarea } from '../../forms/FormStyles';
import Modal from '../../standard/Modal';
import Spinner from '../../standard/Spinner';
import { TagSelect } from '../../standard/TagSelect';
import { StateBadge } from './StateBadge';

const GET_CLIENT_USERS = gql`
  query getClientUsers($userId: MongoID!) {
    client(_id: $userId) {
      organizationDetails {
        clientUsers {
          _id
          email
        }
      }
    }
  }
`;

const STATE_VIEW_MAP = {
  PENDING: {
    iconClass: 'pending',
    icon: faCircle,
    copy: 'Pending',
  },
  APPROVED: {
    iconClass: 'success',
    icon: faCheck,
    copy: 'Approved',
  },
};

const ReviewersHeader = styled.div`
  font-size: 19px;
  weight: 300;
  color: ${({ theme }) => theme.palette.shade.gray_1};
  margin-top: 32px;
  margin-bottom: 18px;
`;

const ReviewerRow = styled.div`
  display: flex;
  height: 42px;
  flex-direction: row;
  border-radius: 4px;
  align-items: center;
  color: ${({ theme }) => theme.palette.shade.gray_2};

  :hover {
    background-color: ${({ theme }) => theme.palette.shade.gray_5};
  }
`;

const ReviewerEmail = styled.div`
  flex-grow: 1;
  padding: 0 8px;
  & a {
    color: ${({ theme }) => theme.palette.primary.main};

    &:hover {
      color: ${({ theme }) => theme.palette.primary.dark};
    }
  }
`;

const ReviewState = styled.div`
  flex-grow: 0;
  padding: 0 8px;
  text-transform: capitalize;
`;

const WithdrawButton = styled.button`
  font-size: 17px;
  flex-grow: 0;
  padding: 0 8px;
  color: ${({ theme }) => theme.palette.shade.gray_2};
`;

const AddReviewersModal = ({
  display,
  onClose,
  reviewRequests,
  addReviewers,
  withdrawReviewRequest,
  portal,
}) => {
  const activeReviewRequests = reviewRequests.filter(
    ({ state }) => state === 'PENDING' || state === 'APPROVED',
  );
  const theme = useContext(ThemeContext);
  const [users, setUsers] = useState([]);
  const [reviewers, setReviewers] = useState([]);
  const [message, setMessage] = useState('');

  const enableMatterReviews = useFeatureToggle(flagNames.MATTER_REVIEWS);
  const userId = getStrippedUserId();

  const updateReviewers = useCallback((data) => {
    setUsers(
      data.client.organizationDetails?.[0]?.clientUsers.map((user) => ({
        id: user._id,
        email: user.email,
      })),
    );
  }, []);

  const { loading } = useQuery(GET_CLIENT_USERS, {
    variables: {
      userId,
    },
    skip: !enableMatterReviews,
    onCompleted: updateReviewers,
  });

  const onChange = useCallback(
    (newValue) => {
      setReviewers(newValue.map(({ value }) => value));
    },
    [setReviewers],
  );

  const clearState = useCallback(() => {
    setReviewers([]);
    setMessage('');
  }, [setReviewers, setMessage]);

  if (!enableMatterReviews) {
    return null;
  }

  const getUserOption = (user) => ({
    value: user,
    label: user.email,
  });

  return (
    <Modal
      display={display}
      title="Request Review"
      onClose={() => {
        onClose();
        clearState();
      }}
      onAccept={async () => {
        if (reviewers.length > 0) {
          addReviewers({ reviewers, description: message });
        }
        onClose();
        clearState();
      }}
      acceptText={reviewers.length > 0 ? 'Send' : 'Done'}
      maxWidth="700px"
      portal={portal}
    >
      <p>Select reviewer(s):</p>
      {loading ? (
        <Spinner />
      ) : (
        <TagSelect
          aria-label="Select Reviewers"
          isMulti
          autoFocus
          value={reviewers.map(getUserOption)}
          placeholder="+ Add Reviewers"
          isValidNewOption={() => false}
          onChange={onChange}
          options={users
            ?.filter(
              ({ id: optionId }) =>
                !reviewers?.some(
                  ({ id: reviewerId }) => reviewerId === optionId,
                ),
            )
            .filter((user) =>
              activeReviewRequests.every(
                (reviewRequest) => reviewRequest.reviewer.email !== user.email,
              ),
            )
            .map(getUserOption)}
          backgroundColor={theme.palette.feedback.info.light}
          data-testid="tagselect"
        />
      )}
      {reviewers.length > 0 && (
        <>
          <p className="mt-3">Message (optional):</p>
          <Textarea
            as="textarea"
            rows="4"
            autoComplete="no"
            style={{ fontSize: '14px', marginTop: 0 }}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            data-testid="message"
          />
        </>
      )}
      {activeReviewRequests.length > 0 && (
        <>
          <ReviewersHeader>Reviewers</ReviewersHeader>
          {activeReviewRequests.map(
            ({ _id: reviewRequestId, state, reviewer }) => (
              <ReviewerRow key={reviewRequestId}>
                <ReviewerEmail>
                  &lt;
                  <a href={`mailto:${reviewer.email}`}>{reviewer.email}</a>
                  &gt;
                </ReviewerEmail>
                <ReviewState>
                  <StateBadge
                    icon={STATE_VIEW_MAP[state].icon}
                    iconClass={STATE_VIEW_MAP[state].iconClass}
                    copy={STATE_VIEW_MAP[state].copy}
                  />
                </ReviewState>
                <WithdrawButton
                  type="button"
                  aria-label={`Withdraw review request to ${reviewer.email}`}
                  onClick={() => withdrawReviewRequest(reviewRequestId)}
                  className="btn-pure"
                  title={`Withdraw review request to ${reviewer.email}`}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </WithdrawButton>
              </ReviewerRow>
            ),
          )}
        </>
      )}
    </Modal>
  );
};

export default AddReviewersModal;
