import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import SplashImage from '../../components/SplashImage';
import AuthUtility from '../../services/AuthUtility';
import LoginBox from './components/LoginBox';
import SmallScreenLogo from './components/SmallScreenLogo';

const buildRedirectLink = (firm, document, service, redirectPath) => {
  if (redirectPath) {
    return redirectPath;
  }

  if (document) {
    return `/client/matter/${document}`;
  }

  if (service) {
    return `/firm/${firm}/service/${service}`;
  }

  return null;
};

const LoginScreen = () => {
  const { search } = useLocation();
  const forgotPasswordLink = `/forgotpassword${search}`;
  const { email, document, firm, service, redirectPath } =
    queryString.parse(search);

  const redirectLink = buildRedirectLink(firm, document, service, redirectPath);
  const encodedRedirectLink = redirectLink
    ? encodeURIComponent(redirectLink)
    : null;

  useEffect(() => AuthUtility.clearStorage(), []);

  return (
    <main>
      <SmallScreenLogo />
      <div className="row no-gutters min-h-fullscreen bg-white">
        <SplashImage />
        <LoginBox
          email={email}
          redirectPath={encodedRedirectLink}
          forgotPasswordLink={forgotPasswordLink}
        />
      </div>
    </main>
  );
};

export default LoginScreen;
