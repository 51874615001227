import { useFeatureToggle } from '@flopflip/react-broadcast';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

import MenuOfServicesContent from '../../../components/menuofservices/MenuOfServicesContent';
import { ActionButton } from '../../../components/standard/Button';
import Header from '../../../components/standard/Header';
import { flagNames } from '../../../featureFlags';
import { userIsFirmAdmin } from '../../../services/Utilities';

const MenuOfServicesScreen = ({ match }) => {
  const {
    params: { firmId },
  } = match;
  const [isEditing, setIsEditing] = useState(false);

  const ownFirmId = localStorage.getItem('firmId');
  const userCanEdit = userIsFirmAdmin() && firmId === ownFirmId;

  const isRFB = useFeatureToggle(flagNames.RFB);

  const EditButton = userCanEdit && !isEditing && (
    <ActionButton
      action={() => setIsEditing(true)}
      icon={<FontAwesomeIcon icon={faEdit} />}
    >
      Edit
    </ActionButton>
  );

  return (
    <>
      <Header
        title={!isRFB ? 'Our Services' : 'Select a Workflow'}
        right={EditButton}
      />
      <MenuOfServicesContent
        firmId={firmId}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    </>
  );
};

export default MenuOfServicesScreen;
