import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import styled from 'styled-components/macro';

import { TypographyStyles } from '../../Typography';

const Suffix = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1rem;
`;

const InputContainer = styled.div`
  display: flex;

  height: 3rem;
  width: 458px;

  margin-bottom: 0.25rem;

  background-color: ${({ theme }) => theme.palette.base.white};

  border: 1px solid ${({ theme }) => theme.palette.shade.gray_4};
  border-radius: 4px;

  overflow: hidden;

  &:hover,
  &:focus,
  &:focus-visible {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    outline: none;
  }

  &:invalid,
  &.hasError {
    border: 1px solid ${({ theme }) => theme.palette.feedback.error.dark};
  }

  &.disabled {
    border: 1px solid ${({ theme }) => theme.palette.shade.gray_4};
    background-color: ${({ theme }) => theme.palette.base.gray};
  }
`;

const Input = styled.input`
  height: 100%;
  width: 100%;

  padding-left: 1rem;

  border: none;
  outline: none;

  &:hover,
  &:focus,
  &:focus-visible {
    border: none;
    outline: none;
  }

  color: ${({ theme }) => theme.palette.shade.gray_1};
  ${TypographyStyles.H5};

  &:disabled {
    color: ${({ theme }) => theme.palette.shade.gray_4};
    background-color: ${({ theme }) => theme.palette.base.gray};
  }
`;

const TextFieldContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > label {
    ${TypographyStyles.FontFamily}
    ${TypographyStyles.Color}
    ${TypographyStyles.H6}
    
    margin-bottom: 0.25rem;

    &.disabled {
      color: ${({ theme }) => theme.palette.shade.gray_2};
    }
  }
`;

const ErrorMessage = styled.div`
  ${TypographyStyles.P};
  color: ${({ theme }) => theme.palette.feedback.error.dark};
`;

export const TextField = forwardRef(
  (
    {
      id,
      label,
      name,
      type,
      value,
      error,
      onChange,
      onBlur,
      disabled,
      min,
      max,
      maxLength,
      minLength,
      pattern,
      required,
      suffix,
      ...props
    },
    ref,
  ) => (
    <TextFieldContainer {...props}>
      {!!label && (
        <label htmlFor={id} className={disabled ? `disabled` : ''}>
          {label}
        </label>
      )}
      <InputContainer
        className={[error ? `hasError` : ``, disabled ? `disabled` : ``].join(
          ' ',
        )}
      >
        <Input
          id={id}
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          min={min}
          max={max}
          maxLength={maxLength}
          pattern={pattern}
          required={required}
          ref={ref}
        />
        {!!suffix && <Suffix>{suffix}</Suffix>}
      </InputContainer>
      {!!error && !disabled && <ErrorMessage>{error}</ErrorMessage>}
    </TextFieldContainer>
  ),
);

TextField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'password', 'search', 'url']),
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  pattern: PropTypes.instanceOf(RegExp),
  required: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
