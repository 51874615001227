import { gql, useMutation } from '@apollo/client';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';
import { handleException } from 'utils/ErrorUtils';

import { userIsClient } from '../../../services/Utilities';
import { ToasterLink } from '../../standard/Toaster';

const StyledChip = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.palette.feedback.success.medium};
  background-color: ${({ theme }) => theme.palette.feedback.success.light};
  padding: 2px 11px;
  margin-left: 17px;
  font-size: 14px;
  line-height: 16px;
  border-radius: 16px;
  height: 22px;
  font-weight: 400;
  cursor: pointer;
  white-space: nowrap;
  border: 0.25px solid transparent;
  box-sizing: border-box;
  :hover {
    border: 0.25px solid ${({ theme }) => theme.palette.feedback.success.medium};
  }
`;

const StyledToasterLink = styled(ToasterLink)`
  margin-left: 5px;
  white-space: nowrap;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin-top: 1px;
  margin-left: 5px;
`;

const CLOSE_CONTRACT = gql`
  mutation closeContract($contractId: MongoID!) {
    closeContract(input: { contractId: $contractId }) {
      recordId
      record {
        _id
        name
      }
    }
  }
`;

const CLOSE_MATTER = gql`
  mutation closeMatter($matterId: MongoID!) {
    closeMatter(input: { matterId: $matterId }) {
      recordId
      record {
        _id
        name
      }
    }
  }
`;

const MarkMatterComplete = ({ matter, onMarkComplete, refetch }) => {
  const { matterId, matterVersion, name, organization } = matter;
  let viewMatterUrl;
  if (userIsClient()) {
    viewMatterUrl = `/client/${
      matterVersion === 1 ? 'matter' : 'matters'
    }/${matterId}`;
  } else {
    const clientPath = organization ? `/clients/${organization._id}` : '';
    const matterPath = matterVersion === 1 ? 'contracts' : 'matters';
    viewMatterUrl = `${clientPath}/${matterPath}/${matterId}`;
  }

  const toastSuccess = useCallback(() => {
    toast.success(
      <>
        <span>{name} marked complete</span>
        <StyledToasterLink to={viewMatterUrl}>View Matter</StyledToasterLink>
      </>,
    );
  }, [name, viewMatterUrl]);

  const toastError = useCallback(() => {
    toast.error(
      'Oops! There was an error completing this matter. Please try again later.',
    );
  }, []);

  const [closeContractMutation] = useMutation(CLOSE_CONTRACT, {
    onCompleted: () => {
      toastSuccess();
      refetch();
    },
    onError: (error) => {
      handleException(error);
      toastError();
    },
    variables: { contractId: matterId },
  });

  const closeContract = useCallback(() => {
    onMarkComplete(matter);
    closeContractMutation();
  }, [matter, onMarkComplete, closeContractMutation]);

  const [closeMatterMutation] = useMutation(CLOSE_MATTER, {
    onCompleted: () => {
      toastSuccess();
      refetch();
    },
    onError: (error) => {
      handleException(error);
      toastError();
    },
    variables: { matterId },
  });

  const closeMatter = useCallback(() => {
    onMarkComplete(matter);
    closeMatterMutation();
  }, [matter, onMarkComplete, closeMatterMutation]);

  const handleClick = useCallback(
    (event) => {
      event.stopPropagation();
      if (matterVersion === 1) {
        closeContract();
      } else if (matterVersion === 2) {
        closeMatter();
      } else {
        console.warn(`Unknown matter version: ${matterVersion}`);
      }
    },
    [closeContract, closeMatter, matterVersion],
  );

  return (
    <StyledChip onClick={handleClick}>
      Mark as complete
      <StyledIcon icon={faCheck} />
    </StyledChip>
  );
};

export default MarkMatterComplete;
