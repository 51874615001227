export class CategoryNode {
  constructor({ id, name, archived, parent, children = [], icon = null }) {
    this.id = id;
    this.name = name;
    this.icon = icon;
    this.archived = archived;
    this.parent = parent ?? null;
    this.children = children || [];
  }

  sort() {
    this.children.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
    );

    this.children.forEach((child) => child.sort());
  }

  addCategoryNode(categoryNode) {
    const { id, name, icon, archived, parents = [] } = categoryNode;

    if (!parents.length) {
      const existingChildNode = this.children.find((node) => node.id === id);
      const childNode =
        existingChildNode ??
        new CategoryNode({ id, name, icon, archived, parent: this });
      if (!existingChildNode) {
        this.children.push(childNode);
      }

      if (childNode.name !== name) {
        childNode.name = name;
      }

      if (childNode.archived !== archived) {
        childNode.archived = archived;
      }

      return childNode;
    }

    const [
      {
        id: parentId,
        name: parentName = null,
        icon: parentIcon = null,
        archived: parentArchived = false,
      },
      ...remainingParents
    ] = parents;
    let parentNode = this.children.find((node) => node.id === parentId);
    if (!parentNode) {
      parentNode = new CategoryNode({
        id: parentId,
        name: parentName,
        icon: parentIcon,
        archived: parentArchived,
        parent: this,
      });
      this.children.push(parentNode);
    }

    return parentNode.addCategoryNode({
      id,
      name,
      archived,
      icon,
      parents: remainingParents,
    });
  }
}
