import { gql } from '@apollo/client';

export const FETCH_SALESFORCE_CONNECTION = gql`
  query fetchSalesforceConnection {
    salesforceConnection {
      _id
      instanceUrl
    }
  }
`;

export const DISCONNECT_FROM_SALESFORCE = gql`
  mutation disconnectFromSalesforce {
    deleteSalesforceConnection {
      record {
        _id
        instanceUrl
      }
    }
  }
`;
