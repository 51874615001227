import React from 'react';

import Dropdown from '../forms/Dropdown';

const OPTIONS = {
  FIRM: 'Public - All firm users can access',
  PRIVATE: 'Private - Only collaborators and firm administrators can access',
};

const ClientVisibilityDropdown = ({ name, value: initialValue, onChange }) => (
  <Dropdown
    mappedOptions={Object.entries(OPTIONS).map(([value, label]) => ({
      label,
      value,
    }))}
    value={{ value: initialValue, label: OPTIONS[initialValue] }}
    onChange={onChange}
    name={name ?? 'ClientVisibilityDropdown'}
  />
);

export default ClientVisibilityDropdown;
