import { gql, useApolloClient, useMutation } from '@apollo/client';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { userIsFirmAdmin } from '../../../services/Utilities';
import Badge from '../../standard/Badge';
import Modal from '../../standard/Modal';
import Spinner from '../../standard/Spinner';
import { AddTeamMemberDropdown } from './AddTeamMemberDropdown';
import { CLIENT_TEAM_FRAGMENT } from './Fragments';
import { LegalTeamFooter } from './LegalTeamFooter';
import { TeamMember } from './TeamMember';

const SectionHeader = styled.h5`
  margin-top: 30px;
`;

const ADD_CLIENT_COLLABORATOR = gql`
  mutation addClientCollaborator($teamId: MongoID!, $userId: String!) {
    addOrgTeamMember(teamId: $teamId, userId: $userId) {
      record {
        ...OrgTeamInformation
      }
    }
  }
  ${CLIENT_TEAM_FRAGMENT}
`;

const REMOVE_CLIENT_COLLABORATOR = gql`
  mutation removeClientCollaborator($teamId: MongoID!, $userId: String!) {
    removeOrgTeamMember(teamId: $teamId, userId: $userId) {
      record {
        ...OrgTeamInformation
      }
    }
  }
  ${CLIENT_TEAM_FRAGMENT}
`;

export function LegalTeamDialogContent({
  clientId,
  clientName,
  clientTeam,
  firmName,
  firmUsers,
  clientUsers = [],
  onClose = () => {},
}) {
  const userId = localStorage.getItem('userId');

  const apolloClient = useApolloClient();

  const [teamMemberIdToRemove, setTeamMemberIdToRemove] = useState(null);
  const [showRemoveUserConfirmationModal, setShowRemoveUserConfirmationModal] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [addClientCollaborator] = useMutation(ADD_CLIENT_COLLABORATOR);
  const [removeClientCollaborator] = useMutation(REMOVE_CLIENT_COLLABORATOR);

  const { _id: teamId, accessPolicy, teamMembers = [] } = clientTeam;

  return (
    <>
      {isLoading && <Spinner />}
      <SectionHeader>Email</SectionHeader>
      {teamMembers.map(({ email, id: teamMemberId }) => (
        <TeamMember
          name={email}
          key={email}
          badge={<Badge>Service Provider</Badge>}
          controls={
            <>
              <button
                type="button"
                aria-label={`Remove ${email} from legal team`}
                onClick={async () => {
                  if (
                    !userIsFirmAdmin() &&
                    accessPolicy === 'PRIVATE' &&
                    teamMemberId === userId
                  ) {
                    setTeamMemberIdToRemove(teamMemberId);
                    setShowRemoveUserConfirmationModal(true);
                  } else {
                    setIsLoading(true);
                    try {
                      await removeClientCollaborator({
                        variables: { userId: teamMemberId, teamId },
                      });
                    } finally {
                      setIsLoading(false);
                    }
                  }
                }}
                className="btn-pure btn-danger"
                title={`Remove ${email} from legal team`}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </>
          }
        />
      ))}
      {clientUsers.map(({ email }) => (
        <TeamMember
          name={email}
          key={email}
          badge={<Badge>Account Holder</Badge>}
        />
      ))}
      <label htmlFor="collaborators">
        <SectionHeader>Add Legal Team Member</SectionHeader>
      </label>
      <AddTeamMemberDropdown
        inputId="collaborators"
        label="Add Legal Team Member"
        options={firmUsers
          .filter(
            ({ id, email }) =>
              !email.includes('rallynow.io') &&
              !teamMembers.some(({ id: existingId }) => id === existingId),
          )
          .map(({ email, id }) => ({
            value: id,
            label: email,
            name: email,
          }))}
        onSubmit={async ({ value: teamMemberId }) => {
          setIsLoading(true);
          try {
            await addClientCollaborator({
              variables: { userId: teamMemberId, teamId },
            });
          } finally {
            setIsLoading(false);
          }
        }}
      />
      <LegalTeamFooter
        firmName={firmName}
        clientName={clientName}
        teamMembers={teamMembers}
        teamId={teamId}
        accessPolicy={accessPolicy}
        onClose={onClose}
      />
      <Modal
        title={`Remove yourself from ${clientName}?`}
        display={showRemoveUserConfirmationModal}
        showCancel
        onCancel={() => {
          setShowRemoveUserConfirmationModal(false);
        }}
        acceptPaletteType="destructive"
        acceptText="Remove Me"
        onAccept={async () => {
          setIsLoading(true);
          try {
            await removeClientCollaborator({
              variables: { userId: teamMemberIdToRemove, teamId },
            });
            apolloClient.cache.evict({
              id: apolloClient.cache.identify({
                _id: clientId,
                __typename: 'Organization',
              }),
            });
          } finally {
            setIsLoading(false);
            setShowRemoveUserConfirmationModal(false);
            onClose();
          }
        }}
      >
        This client profile is private. If you remove yourself as a
        Collaborator, you will no longer be able to access it. To regain access,
        you will need to be added by a Collaborator or an administrator.
      </Modal>
    </>
  );
}
