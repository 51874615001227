import axios from 'axios';
import { handleException } from 'utils/ErrorUtils';

import { trackLogin } from '../../services/Analytics';

export async function getUserType(email) {
  try {
    const response = await axios.post(`/api/v1/getUserType`, { email });
    return response.data.userType;
  } catch (err) {
    handleException(err);
  }

  return null;
}

export async function loginUser(user) {
  trackLogin(user);
  try {
    await axios.post(
      `/api/v1/login`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      },
    );
  } catch (err) {
    handleException(err);
  }
}
