import { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import slugify from 'slugify';
import styled from 'styled-components/macro';

import { userIsClient } from '../../services/Utilities';
import MenuOfServicesCard from './MenuOfServicesCard';

const MenuContainer = styled.div`
  margin-top: 1.5em;
`;

const MenuOfServicesCategoryContainer = styled.div`
  margin-bottom: 6em;
`;

const MenuOfServicesCategoryHeader = styled.h2`
  margin-bottom: 1em;

  & > a {
    padding-left: 0.5em;
    visibility: hidden;
  }

  &:hover > a {
    visibility: inherit;
  }
`;

const MenuOfServicesCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  grid-gap: 3em 1em;
  @media (max-width: 1460px) {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }
  @media (max-width: 1265px) {
    grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
    grid-gap: 1em;
  }
  @media (max-width: 425px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    grid-gap: 1em;
  }
  justify-items: center;
  grid-auto-rows: 1fr;
`;

const MenuOfServices = ({ firmId, serviceMenu }) => {
  const { hash } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!hash) {
      return;
    }

    const elementId = hash.substring(1);
    const elementToScrollTo = document.getElementById(elementId);

    if (!elementToScrollTo) {
      return;
    }

    elementToScrollTo.scrollIntoView({ behavior: 'smooth' });
  }, [hash]);

  return (
    <MenuContainer>
      {serviceMenu.categories.map(
        ({ categoryId: id, name, templates = [] }) => (
          <MenuOfServicesCategoryContainer key={id}>
            {!!name && (
              <MenuOfServicesCategoryHeader id={slugify(name, { lower: true })}>
                {name}
                {!userIsClient() && (
                  <Link
                    to={`#${slugify(name, { lower: true })}`}
                    title="Permalink to this category"
                  >
                    <FontAwesomeIcon icon={faLink} size="xs" />
                  </Link>
                )}
              </MenuOfServicesCategoryHeader>
            )}
            <MenuOfServicesCardContainer>
              {templates
                .filter(({ status: [status] }) => status === 'active')
                .map(
                  ({
                    version: { id: serviceId },
                    name: serviceName,
                    description,
                    price,
                    displayPrice,
                  }) => (
                    <MenuOfServicesCard
                      key={serviceId}
                      name={serviceName}
                      description={description}
                      price={price}
                      displayPrice={displayPrice}
                      onClick={() =>
                        history.push({
                          pathname: `/firm/${firmId}/service/${serviceId}`,
                          search: history.location.search,
                        })
                      }
                    />
                  ),
                )}
            </MenuOfServicesCardContainer>
          </MenuOfServicesCategoryContainer>
        ),
      )}
    </MenuContainer>
  );
};

export default MenuOfServices;
