import equal from 'fast-deep-equal/es6';
import React, { useCallback, useContext } from 'react';
import styled from 'styled-components/macro';

import { FormContext } from '../../../../context/FormContext';
import { validateContact } from '../../../../services/TemplatedContractUtilities';
import { isUnfilled } from '../../../../services/Utilities';
import { ContactForm } from '../ContactForm';
import { useContact, useCreateContact, useUpdateContact } from '../hooks';

const EditCardWrapper = styled.div`
  border-left: 5px solid ${(props) => props.theme.palette.primary.main};
  padding: 26px 36px 36px;
  @media (max-width: 576px) {
    padding: 8px 12px 18px;
  }
  border-radius: 5px;
`;

export function EditContactCard({
  name,
  contact,
  onChange,
  onBlur,
  fieldConfig,
  contactType,
  required,
  setIsEditing,
}) {
  const {
    org,
    setIsFormUpdating,
    autoUpdateContacts = false,
  } = useContext(FormContext);
  const clientId = org?.getId();

  const [oldContact] = useContact({
    contactId: contact._contactId,
    clientId,
  });

  const [createContact] = useCreateContact({});

  const [updateContact] = useUpdateContact({});

  const getContactUpdate = useCallback(
    (newContact) => {
      if (autoUpdateContacts && !equal(newContact, oldContact)) {
        const { _contactId, ...contactUpdate } = newContact;
        return contactUpdate;
      }
      const updatableFields = oldContact
        ? Object.entries(oldContact)
            .filter(([, oldValue]) => !oldValue)
            .map(([key]) => key)
        : Object.keys(newContact);
      const newEntries = updatableFields
        .map((key) => [key, newContact[key]])
        .filter(([, value]) => !isUnfilled(value));
      return newEntries.length ? Object.fromEntries(newEntries) : null;
    },
    [oldContact, autoUpdateContacts],
  );

  const validateFormContact = useCallback(
    (contactToValidate) => {
      const contactErrors = validateContact(contactToValidate, required, {
        fieldConfig,
      });
      return !Object.values(contactErrors).flat().length;
    },
    [fieldConfig, required],
  );

  const onContactBlur = useCallback(
    async (event) => {
      const {
        target: { value: newContact },
      } = event;
      setIsFormUpdating(true);
      const isEmptyContact = !newContact.name;
      const isValidContact = validateFormContact(newContact);
      const isNewContact = clientId && !newContact._contactId;
      const isUpdatingContact =
        !!newContact._contactId &&
        newContact._contactId === oldContact?._contactId;

      let newContactData = newContact;

      try {
        if (isEmptyContact) {
          newContactData = {};
        } else if (isValidContact && isNewContact) {
          newContactData = await createContact(clientId, newContact);
        } else if (isValidContact && isUpdatingContact) {
          const contactUpdate = getContactUpdate(newContact);
          if (contactUpdate) {
            newContactData = await updateContact(
              newContact._contactId,
              contactUpdate,
            );
          }
        }
      } finally {
        const updateEvent = {
          target: { name, value: newContactData, type: 'Contact' },
        };
        onChange(updateEvent);
        onBlur(updateEvent);
        setIsEditing(!isValidContact);
        setIsFormUpdating(false);
      }
    },
    [
      clientId,
      setIsFormUpdating,
      name,
      onBlur,
      onChange,
      oldContact,
      createContact,
      updateContact,
      getContactUpdate,
      setIsEditing,
      validateFormContact,
    ],
  );

  return (
    <EditCardWrapper>
      <ContactForm
        name={name}
        contact={contact}
        onBlur={onContactBlur}
        onChange={onChange}
        contactType={contactType}
        fieldConfig={fieldConfig}
        required={required}
        clientId={clientId}
        hideFieldsForEmptyContact
      />
    </EditCardWrapper>
  );
}
