import { gql, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { handleException } from 'utils/ErrorUtils';

import { useUser } from '../../context/UserContext';
import { useQueryParams } from '../../hooks/useQueryParam';
import { trackSubscribe } from '../../services/Analytics';
import { googleTrackSubscribe } from '../../services/GoogleTagManager';
import Spinner from '../standard/Spinner';

const FETCH_CHECKOUT_SESSION = gql`
  query fetchCheckoutSession($sessionId: String!, $organizationId: MongoID!) {
    checkoutSession(sessionId: $sessionId, organizationId: $organizationId) {
      id
      productIds
    }
  }
`;

export function SubscriptionSuccess() {
  const history = useHistory();

  const [userFetchCount, setUserFetchCount] = useState(0);
  const [productIds, setProductIds] = useState(undefined);

  const [{ redirectUrl, session_id: sessionId }] = useQueryParams([
    'redirectUrl',
    'session_id',
  ]);

  const [{ organization }, { refetch }] = useUser();

  useEffect(() => {
    const pollInterval = setInterval(() => {
      refetch();
      setUserFetchCount(userFetchCount + 1);
    }, 1000);

    return () => clearInterval(pollInterval);
  }, [userFetchCount, refetch]);

  useQuery(FETCH_CHECKOUT_SESSION, {
    variables: { sessionId, organizationId: organization._id },
    onCompleted: (data) => setProductIds(data?.checkoutSession?.productIds),
    onError: (error) => {
      handleException(error);
      toast.error(
        'Oops! Something went wrong fetching your subscription status.',
      );
      history.replace('/client/home');
    },
    skip: !sessionId,
  });

  useEffect(() => {
    if (
      productIds?.every((productId) =>
        organization?.subscriptions?.some(
          ({ product, status }) =>
            product === productId &&
            (status === 'ACTIVE' || status === 'TRIAL'),
        ),
      )
    ) {
      trackSubscribe();
      googleTrackSubscribe();
      if (!redirectUrl) {
        history.replace('/client/home');
      } else {
        window.location.replace(redirectUrl);
      }
    }
  }, [history, redirectUrl, organization, productIds]);

  useEffect(() => {
    if (userFetchCount > 100) {
      toast.error('Oops! Something went wrong verifying your purchase');
      history.replace('/client/home');
    }
  }, [userFetchCount, history]);

  return <Spinner />;
}
