import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components/macro';

export const CheckboxLabel = styled.span`
  font-size: 14px;
  font-weight: 400;
  && {
    padding-left: ${(props) => (props.noLabelPadding ? 0 : '8px')};
  }
  ${(props) =>
    props.disabled &&
    css`
      background-color: transparent;
      opacity: 0.5;
      cursor: not-allowed;
    `};
`;

const StyledInput = styled.input`
  &&.custom-control-input:checked ~ .custom-control-indicator,
  .custom-control-input:active:not(:disabled) ~ .custom-control-indicator {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const Indicator = styled.span`
  &&.custom-control-indicator {
    position: relative;
    border-color: ${({ theme }) => theme.palette.shade.gray_2};
    background-color: transparent;
    border-radius: 2px;

    &::after {
      color: ${({ theme }) => theme.palette.base.white};
      font-size: 10px;
      position: absolute;
      top: 0;
    }
  }
`;

const Checkbox = forwardRef(
  (
    {
      className,
      label,
      testId,
      disabled = false,
      noLabelPadding = false,
      ...props
    },
    ref,
  ) => (
    <div className={className}>
      <div className="custom-controls-stacked">
        <label className="custom-control custom-checkbox">
          <StyledInput
            type="checkbox"
            className="custom-control-input"
            disabled={!!disabled}
            ref={ref}
            {...props}
          />
          <Indicator
            className="custom-control-indicator"
            data-testid={testId}
            {...props}
          />
          <CheckboxLabel
            className="custom-control-description"
            disabled={!!disabled}
            noLabelPadding={noLabelPadding}
          >
            {label}
          </CheckboxLabel>
        </label>
      </div>
    </div>
  ),
);

Checkbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  name: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  noLabelPadding: PropTypes.bool,
  testId: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default Checkbox;
