import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { trackAddContact } from '../../../services/Analytics';
import { validateContact } from '../../../services/TemplatedContractUtilities';
import { isUnfilled } from '../../../services/Utilities';
import { ContactForm } from '../../forms/contact/ContactForm';
import { useCreateContact } from '../../forms/contact/hooks';
import { SCREEN_SIZES } from '../../LayoutConstants';
import Button from '../../standard/Button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../standard/Dialog';

const FooterControl = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 30px;
`;

const FooterButton = styled(Button)`
  font-size: 18px;
  min-width: 120px;
  height: 50px;
`;

const ContactDialogContent = styled(DialogContent)`
  margin: 30px 0;
  min-width: 600px;
  @media ${SCREEN_SIZES.SMALL} {
    min-width: 425px;
  }
  @media ${SCREEN_SIZES.X_SMALL} {
    min-width: 0;
  }
`;

export function AddContactModal({
  open,
  onAdd,
  onClose,
  organization,
  fieldConfig,
  contact: propsContact,
  ...props
}) {
  const [contact, setContact] = useState({});
  const [errors, setErrors] = useState({});
  const [hasErrors, setHasErrors] = useState(false);

  const [createContact] = useCreateContact({});

  useEffect(
    () => setHasErrors(!!Object.values(errors).flat().length),
    [errors],
  );

  useEffect(() => {
    if (propsContact) {
      setContact(propsContact);
    }
  }, [propsContact]);

  const updateContact = useCallback(({ target: { value } }) => {
    setContact(value);

    if (!isUnfilled(value)) {
      setErrors(validateContact(value, false));
    }
  }, []);

  const onCloseContactModal = useCallback(() => {
    setContact({});
    setErrors({});
    setHasErrors(false);
    onClose();
  }, [onClose]);

  const onAccept = useCallback(async () => {
    const newContact = await createContact(organization, contact);
    trackAddContact();
    onCloseContactModal();
    onAdd(newContact);
  }, [createContact, organization, contact, onCloseContactModal, onAdd]);

  return (
    <Dialog open={open} onClose={onCloseContactModal} maxWidth="md" {...props}>
      <DialogHeader>
        <DialogTitle>Add Contact</DialogTitle>
      </DialogHeader>
      <ContactDialogContent>
        <ContactForm
          name="new-contact"
          contact={contact}
          onChange={updateContact}
          onBlur={updateContact}
          fieldConfig={fieldConfig}
          errors={errors}
        />
      </ContactDialogContent>
      <DialogFooter>
        <FooterControl>
          <FooterButton pure onClick={onCloseContactModal} paletteType="gray">
            Cancel
          </FooterButton>
          <FooterButton
            onClick={onAccept}
            disabled={isUnfilled(contact) || hasErrors}
          >
            Add Contact
          </FooterButton>
        </FooterControl>
      </DialogFooter>
    </Dialog>
  );
}
