/*
 Utility used for logging in users, setting up their session and parsing their basic information.
 Basic login requires: calling login(), and then setSessionFromURLFragment() on the subsequent page load.
 Login() uses auth0, which then redirects the user with the tokens in a URL fragment.
 */

import auth0 from 'auth0-js';
import axios from 'axios';
import { handleException } from 'utils/ErrorUtils';

import { promisify } from '../../utils/PromiseUtils';
import { trackRegistration } from '../Analytics';
import { auth0Client, auth0Domain } from '../Config';

const namespace = 'https://app.rallynow.io';
class AuthUtility {
  constructor(domain = auth0Domain, clientID = auth0Client) {
    this.webAuth = new auth0.WebAuth({
      domain,
      clientID,
      audience: 'api.rallynow.io',
      responseType: 'token id_token',
      scope: 'openid email profile',
    });
    this.register = this.register.bind(this);
    this.login = this.login.bind(this);
    this.setSessionFromURLFragment = this.setSessionFromURLFragment.bind(this);
    this.getUserInfo = this.getUserInfo.bind(this);
  }

  register(email, password, cb) {
    this.webAuth.signup(
      {
        connection: 'Username-Password-Authentication',
        email,
        password,
      },
      cb,
    );
  }

  // eslint-disable-next-line class-methods-use-this
  registerClient = (
    email,
    password,
    phoneNumber,
    firmId,
    firmUserId,
    organizationName,
    organizationId,
    invitationId,
    cb,
  ) => {
    const registerRequestBody = {
      email,
      password,
      phoneNumber,
      userType: 'client',
      organization: organizationName,
      organizationId,
      firm: firmId,
      firmUserId,
      invitationId,
    };

    axios
      .post(`/api/v1/users`, registerRequestBody)
      .then((res) => {
        trackRegistration('client', email, true, organizationId, firmId);
        cb(res.data.error, res.data);
      })
      .catch((error) => {
        cb(error);
      });
  };

  login(username, password, errorCallback, options, userType) {
    let redirectUri = `${window.location.origin}/redirect`;

    if (options && options.redirectPath)
      redirectUri = `${redirectUri}?redirectPath=${options.redirectPath}`;

    this.webAuth.login(
      {
        realm:
          userType === 'client'
            ? process.env.REACT_APP_AUTH0_CLIENT_REALM
            : 'Username-Password-Authentication',
        username,
        password,
        redirect_uri: redirectUri,
      },
      errorCallback,
    );
  }

  changePassword(email, callback, connection) {
    this.webAuth.changePassword(
      {
        connection,
        email,
      },
      callback,
    );
  }

  // eslint-disable-next-line class-methods-use-this
  setAuthResultAndExpiry = (authResult) => {
    if (authResult && authResult.accessToken && authResult.idToken) {
      // Set the time that the access token will expire at
      const expiresAt = JSON.stringify(
        authResult.expiresIn * 1000 + new Date().getTime(),
      );
      const issuedAt = JSON.stringify(new Date().getTime());
      localStorage.setItem('access_token', authResult.accessToken);
      localStorage.setItem('userId', authResult.idTokenPayload.sub);
      localStorage.setItem('email', authResult.idTokenPayload.email);
      localStorage.setItem('expires_at', expiresAt);
      localStorage.setItem('issued_at', issuedAt);
      localStorage.setItem(
        'product',
        authResult.idTokenPayload[`${namespace}/product`],
      );
      localStorage.setItem(
        'userType',
        authResult.idTokenPayload[`${namespace}/userType`],
      );
      localStorage.setItem(
        'hidePublicTemplates',
        authResult.idTokenPayload[`${namespace}/hidePublicTemplates`],
      );

      // Note that the following has nothing to do with 'real security' that happens on the backend
      // this is just to hide admin frontend components from ordinary users.
      const roles = authResult.idTokenPayload[`${namespace}/roles`];
      if (roles.includes('Rally Admin')) {
        localStorage.setItem('rallyAdmin', true);
        localStorage.setItem('firmAdmin', true);
      }
      if (roles.includes('Firm Admin')) {
        localStorage.setItem('firmAdmin', true);
      }
    }
  };

  refreshSession = async () => {
    const redirectUri = `${window.location.origin}/redirect`;
    const checkSessionPromise = promisify(
      this.webAuth.checkSession.bind(this.webAuth),
    );
    const authResult = await checkSessionPromise({
      redirectUri,
      audience: 'api.rallynow.io',
      scope: 'openid email profile offline_access',
    });
    this.setAuthResultAndExpiry(authResult);
  };

  // Call this function when loading a page after login() has been called.
  setSessionFromURLFragment() {
    this.webAuth.parseHash((err, authResult) => {
      this.setAuthResultAndExpiry(authResult);
      if (err) {
        const sentryError = new Error(JSON.stringify(err));
        handleException(sentryError);
      }
    });
  }

  getUserInfo(accessToken, cb) {
    this.webAuth.client.userInfo(accessToken, (_err, user) => {
      cb(user);
    });
  }
}

export default AuthUtility;
