/* eslint-disable class-methods-use-this */

import axios from 'axios';

import { trackRegistration } from '../Analytics';
import { validateEmail } from '../Utilities';

let FIRM_USERS = JSON.parse(localStorage.getItem('FIRM_USERS'));
if (!FIRM_USERS) {
  FIRM_USERS = {
    'test@rallynow.io': {
      id: 'auth0|610989d4644383b92e22df7d',
      email: 'test@rallynow.io',
      password: 'fakepassword',
      userType: 'firm',
      hidePublicTemplates: false,
      roles: ['Rally Admin'],
      permissions: [],
    },
    'admin@testfirm.com': {
      id: 'auth0|61098e48ef5135e4640627c0',
      email: 'admin@testfirm.com',
      password: 'fakepassword',
      userType: 'firm',
      hidePublicTemplates: false,
      roles: ['Firm Admin'],
      permissions: [],
    },
    'user@testfirm.com': {
      id: 'auth0|61098e635d8181f4bc0bebf7',
      email: 'user@testfirm.com',
      password: 'fakepassword',
      userType: 'firm',
      hidePublicTemplates: false,
      roles: [],
      permissions: [],
    },
    'admin@firm2.com': {
      id: 'auth0|61098e6fa0b39f64cdafdfef',
      email: 'admin@firm2.com',
      password: 'fakepassword',
      userType: 'firm',
      hidePublicTemplates: false,
      roles: ['Firm Admin'],
      permissions: [],
    },
    'user@firm2.com': {
      id: 'auth0|61098e80ab18d57bc9d5c957',
      email: 'user@firm2.com',
      password: 'fakepassword',
      userType: 'firm',
      hidePublicTemplates: false,
      roles: [],
      permissions: [],
    },
    'admin@privatefirm.com': {
      id: 'auth0|61098e89cea99c1d06c7befb',
      email: 'admin@privatefirm.com',
      password: 'fakepassword',
      userType: 'firm',
      hidePublicTemplates: false,
      roles: ['Firm Admin'],
      permissions: [],
    },
    'user1@privatefirm.com': {
      id: 'auth0|61098e93426f9606cd9b3396',
      email: 'user1@privatefirm.com',
      password: 'fakepassword',
      userType: 'firm',
      hidePublicTemplates: false,
      roles: [],
      permissions: [],
    },
    'user2@privatefirm.com': {
      id: 'auth0|61098e9adc569e1ce95693b1',
      email: 'user2@privatefirm.com',
      password: 'fakepassword',
      userType: 'firm',
      hidePublicTemplates: false,
      roles: [],
      permissions: [],
    },
    'rfb-firm-admin@example.com': {
      id: 'auth0|620d17435ac54f0069f1a548',
      email: 'rfb-firm-admin@example.com',
      password: 'fakepassword',
      userType: 'firm',
      hidePublicTemplates: false,
      roles: ['Firm Admin'],
      permissions: [],
    },
    'rfb-rally-admin@example.com': {
      id: 'auth0|620d17435ac54f0069f1a547',
      email: 'rfb-rally-admin@example.com',
      password: 'fakepassword',
      userType: 'firm',
      hidePublicTemplates: false,
      roles: ['Rally Admin'],
      permissions: [],
    },
  };
  localStorage.setItem('FIRM_USERS', JSON.stringify(FIRM_USERS));
}

let CLIENT_USERS = JSON.parse(localStorage.getItem('CLIENT_USERS'));
if (!CLIENT_USERS) {
  CLIENT_USERS = {
    'client@acmemotors.com': {
      id: 'auth0|5dd7df55292bbff8c36c41a5',
      email: 'client@acmemotors.com',
      password: 'fakepassword',
      userType: 'client',
      roles: [],
    },
    'client@coinbase.com': {
      id: 'auth0|5dd7df75292bbff8c36c41a9',
      email: 'client@coinbase.com',
      password: 'fakepassword',
      userType: 'client',
      roles: [],
    },
    'client@cruise.com': {
      id: 'auth0|5dd7df8e292bbff8c36c41ad',
      email: 'client@cruise.com',
      password: 'fakepassword',
      userType: 'client',
      roles: [],
    },
    'client@firm1client.com': {
      id: 'auth0|5ecd07db0374a50027d41838',
      email: 'client@firm1client.com',
      password: 'fakepassword',
      userType: 'client',
      roles: [],
    },
    'client@firm2client.com': {
      id: 'auth0|5ebed238de3bfd9e485d33b1',
      email: 'client@firm2client.com',
      password: 'fakepassword',
      userType: 'client',
      roles: [],
    },
    'multi-client-org-client-1@example.com': {
      id: 'auth0|619be4c148c94d7e08607ceb',
      email: 'multi-client-org-client-1@example.com',
      password: 'fakepassword',
      userType: 'client',
      roles: [],
    },
    'multi-client-org-client-2@example.com': {
      id: 'auth0|619be50fe03bd9e23e9ce58d',
      email: 'multi-client-org-client-2@example.com',
      password: 'fakepassword',
      userType: 'client',
      roles: [],
    },
    'free-client@example.com': {
      id: 'auth0|620d295f6fd2f4256d621b40',
      email: 'free-client@example.com',
      password: 'fakepassword',
      userType: 'client',
      roles: [],
    },
    'trial-client@example.com': {
      id: 'auth0|620d2a716fd2f4256d621e09',
      email: 'trial-client@example.com',
      password: 'fakepassword',
      userType: 'client',
      roles: [],
    },
    'subscribed-client@example.com': {
      id: 'auth0|620d2a876fd2f4256d621ec3',
      email: 'subscribed-client@example.com',
      password: 'fakepassword',
      userType: 'client',
      roles: [],
    },
  };
  localStorage.setItem('CLIENT_USERS', JSON.stringify(CLIENT_USERS));
}

class LocalAuthUtility {
  register() {
    // TODO: Add local registration
  }

  async registerClient(
    email,
    password,
    phoneNumber,
    firmId,
    firmUserId,
    organizationName,
    organizationId,
    invitationId,
    cb,
  ) {
    const registerRequestBody = {
      email,
      password,
      phoneNumber,
      userType: 'client',
      organization: organizationName,
      organizationId,
      firm: firmId,
      firmUserId,
      invitationId,
    };

    try {
      const { data } = await axios.post(`/api/v1/users`, registerRequestBody);
      CLIENT_USERS[data.email] = {
        id: `auth0|${data._id}`,
        email: data.email,
        password,
        userType: 'client',
        roles: [],
      };

      localStorage.setItem('CLIENT_USERS', JSON.stringify(CLIENT_USERS));
      trackRegistration('client', email, true, organizationId, firmId);

      cb(data.error, data);
    } catch (error) {
      cb(error);
    }
  }

  loginUser(user, password, errorCallback, options) {
    if (!user || user.password !== password) {
      errorCallback(new Error('invalid password'));
      return;
    }

    const expiresAt = JSON.stringify(86400000 + new Date().getTime());
    const token = JSON.stringify({
      sub: user.id,
      permissions: user.permissions,
      'https://app.rallynow.io/userType': user.userType,
      'https://app.rallynow.io/roles': user.roles,
      'https://app.rallynow.io/email': user.email,
    });
    localStorage.setItem('userId', user.id);
    localStorage.setItem('email', user.email);
    localStorage.setItem('expires_at', expiresAt);
    localStorage.setItem('issued_at', new Date().getTime());
    localStorage.setItem('access_token', token);
    localStorage.setItem('userType', user.userType);
    localStorage.setItem('hidePublicTemplates', user.hidePublicTemplates);
    if (user.roles.includes('Rally Admin')) {
      localStorage.setItem('rallyAdmin', true);
      localStorage.setItem('firmAdmin', true);
    }
    if (user.roles.includes('Firm Admin')) {
      localStorage.setItem('firmAdmin', true);
    }

    let redirectUri = `${window.location.origin}/redirect`;
    if (options?.redirectPath)
      redirectUri = `${redirectUri}?redirectPath=${options.redirectPath}`;
    window.location.replace(redirectUri);
  }

  login(username, password, errorCallback, options, userType) {
    if (userType === 'client') {
      this.loginUser(
        CLIENT_USERS[username.toLowerCase()],
        password,
        errorCallback,
        options,
      );
    } else {
      this.loginUser(
        FIRM_USERS[username.toLowerCase()],
        password,
        errorCallback,
        options,
      );
    }
  }

  changePassword(email, callback) {
    // TODO: Test change password functionality
    const validEmail = validateEmail(email);
    callback(!validEmail, { emailSent: validEmail, emailFailed: !validEmail });
  }

  setSessionFromURLFragment() {}

  refreshSession() {
    const accessToken = localStorage.getItem('access_token');

    if (!accessToken) {
      return;
    }

    localStorage.setItem('issued_at', new Date().getTime());
    localStorage.setItem(
      'expires_at',
      JSON.stringify(86400000 + new Date().getTime()),
    );
  }

  getUserInfo(accessToken, cb) {
    cb(JSON.parse(accessToken));
  }
}

export default LocalAuthUtility;
