import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';
import 'cross-fetch/polyfill';
// Add polyfills for Box UI Elements
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-unified-numberformat/polyfill';
// Add polyfills for LaunchDarkly
import 'es6-promise/auto';
import 'event-source-polyfill';
import 'polyfill-queryselector';
import './fonts/themify.ttf';
import 'bootstrap/dist/css/bootstrap.css';
import '@icon/themify-icons/themify-icons.css';
import './css/scss/app.scss';
import './css/style.css';
import './css/fonts.css';
// add css styles for syncfusion editor
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-popups/styles/material.css';
import '@syncfusion/ej2-lists/styles/material.css';
import '@syncfusion/ej2-navigations/styles/material.css';
import '@syncfusion/ej2-splitbuttons/styles/material.css';
import '@syncfusion/ej2-dropdowns/styles/material.css';
import '@syncfusion/ej2-react-documenteditor/styles/material.css';

import * as Sentry from '@sentry/react';
import { registerLicense } from '@syncfusion/ej2-base';
import { init as initializeCommandBar } from 'commandbar';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
// Add smoothScroll polyfill for smooth window scroll
import smoothScroll from 'smoothscroll-polyfill';

import { App } from './components/App';
import { unregister } from './registerServiceWorker';
import {
  facebookPageView,
  initializeFacebookPixel,
} from './services/FacebookPixel';
import { initializeGTM } from './services/GoogleTagManager';
import { initializeUserpilot } from './services/Userpilot';

// Registering Syncfusion license key
registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY);

smoothScroll.polyfill();

const IGNORE_ERRORS = [
  // Seeing these errors from pingdom checks. See https://github.com/launchdarkly/js-client-sdk/issues/147
  /Failed to execute 'send' on 'XMLHttpRequest': Failed to load 'https:\/\/events\.launchdarkly\.com\/events\/bulk\/[a-f0-9]{24}': Synchronous XHR in page dismissal\. See https:\/\/www\.chromestatus\.com\/feature\/4664843055398912 for more details\./,
  /Failed to execute 'send' on 'XMLHttpRequest': Failed to load 'https:\/\/www.facebook.com\/tr\//,
];

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
  release: process.env.REACT_APP_RELEASE,
  ignoreErrors: IGNORE_ERRORS,
});

/* eslint-disable no-undef, no-constant-condition */
if (typeof newrelic !== 'undefined') {
  newrelic.setErrorHandler((err) =>
    IGNORE_ERRORS.some((errRegex) => err.message.match(errRegex)),
  );
}
/* eslint-enable */

initializeUserpilot();
initializeFacebookPixel();
facebookPageView();
initializeGTM();

initializeCommandBar(process.env.REACT_APP_COMMANDBAR_ORG);

render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root'),
);

unregister(); // Unregister service worker, too many caching problems
