import { gql, useMutation } from '@apollo/client';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import queryString from 'query-string';
import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { handleException } from 'utils/ErrorUtils';

import { useFirmSettings } from '../../context/UserContext';
import LawPaySettings from '../lawpay/LawPaySettings';
import MenuOfServicesLink from '../menuofservices/MenuOfServicesLink';
import Button from '../standard/Button';
import Header from '../standard/Header';
import Spinner from '../standard/Spinner';
import Switch from '../standard/Switch';
import ClientVisibilityDropdown from './ClientVisibilityDropdown';
import { SettingsContainer, SettingsItem } from './Settings';

const UPDATE_FIRM_SETTINGS = gql`
  mutation updateFirmSettings($input: UpdateFirmSettingsInput!) {
    updateFirmSettings(input: $input) {
      recordId
      record {
        _id
        name
        settings {
          payBeforeService
          defaultOrgTeamAccessPolicy
          showClientCustomFields
          allowClientEditMatterName
          showEntityInformation
          showCategories
        }
      }
    }
  }
`;

const FirmSettings = () => {
  const history = useHistory();
  const [settings, { loading }] = useFirmSettings();
  const { search } = useLocation();

  const firmId = localStorage.getItem('firmId');

  const [updateSettings] = useMutation(UPDATE_FIRM_SETTINGS, {
    onCompleted: () => toast.success('Settings changed'),
    onError: (error) => {
      handleException(error);
      toast.error('Error saving settings');
    },
  });

  useEffect(() => {
    const { error } = queryString.parse(search);
    if (error) {
      toast.error(error);
    }
  }, [search]);

  const handleSettingsUpdate = useCallback(
    async (event) =>
      updateSettings({
        variables: {
          input: {
            firmId,
            update: {
              [event.target.name]: event.target.checked ?? event.target.value,
            },
          },
        },
      }),
    [firmId, updateSettings],
  );

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Header
        title="Team Settings"
        subtitle="Configure your team's settings. This page is only accessible by team administrators."
      />
      <SettingsContainer>
        <SettingsItem name="LawPay Accounts">
          <LawPaySettings />
        </SettingsItem>
        <SettingsItem
          name="Require Client Payment Before Questionnaire"
          helper="By default, payment will occur upon completion of the questionnaire."
          right={
            <Switch
              checked={settings?.payBeforeService}
              onChange={handleSettingsUpdate}
              color="primary"
              name="payBeforeService"
            />
          }
        />
        <SettingsItem name="Menu of Services">
          <div>
            <Button
              icon={<FontAwesomeIcon icon={faEdit} />}
              onClick={() => history.push(`/firm/${firmId}/servicemenu`)}
              outline
            >
              Edit and Arrange
            </Button>
          </div>
          <MenuOfServicesLink
            onAfterCopy={() => {
              toast.success('Link copied to clipboard');
            }}
          />
        </SettingsItem>
        <SettingsItem
          name="Default Client Visibility"
          helper="Control who has access to newly created clients"
        >
          <ClientVisibilityDropdown
            name="defaultOrgTeamAccessPolicy"
            value={settings?.defaultOrgTeamAccessPolicy}
            onChange={handleSettingsUpdate}
          />
        </SettingsItem>
        <SettingsItem
          name="Client Custom Fields Visibility"
          helper="By default, only firm users will be able to view and edit custom field values."
          right={
            <Switch
              checked={settings?.showClientCustomFields}
              onChange={handleSettingsUpdate}
              color="primary"
              name="showClientCustomFields"
            />
          }
        />
        <SettingsItem
          name="Allow Clients to Edit Matter Names"
          right={
            <Switch
              checked={settings?.allowClientEditMatterName}
              onChange={handleSettingsUpdate}
              color="primary"
              name="allowClientEditMatterName"
            />
          }
        />
        <SettingsItem
          name="Show Entity Information Tab for Clients"
          right={
            <Switch
              checked={settings?.showEntityInformation}
              onChange={handleSettingsUpdate}
              color="primary"
              name="showEntityInformation"
            />
          }
        />
        <SettingsItem
          name="Show Categories"
          right={
            <Switch
              checked={settings?.showCategories}
              onChange={handleSettingsUpdate}
              color="primary"
              name="showCategories"
            />
          }
        />
      </SettingsContainer>
    </>
  );
};

export default FirmSettings;
