/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */
import './DataTableStyle.css';

import isEmpty from 'lodash/isEmpty';
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components/macro';

import CompanyName from './CompanyName';
import DatePicker from './DatePicker';
import Dropdown from './Dropdown';
import {
  FloatingTextField,
  Input,
  InputPrefix,
  InputSuffix,
} from './FormStyles';

const SubfieldContainer = styled.td`
  && {
    padding: 0 15px 0 0;
    border: none;
    flex-wrap: nowrap;
  }
`;

const unrollSubfieldsToElements = (subfields) =>
  Object.entries(subfields).map(([identifier, value]) => ({
    identifier,
    ...value,
  }));

const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

class MultiField extends Component {
  constructor(props) {
    super(props);
    const { data, subfields = {}, elements: initialElements } = this.props;
    const elements = initialElements?.length
      ? initialElements
      : unrollSubfieldsToElements(subfields);
    this.state = {
      data,
      elements,
    };
  }

  componentDidMount() {
    const { data } = this.state;
    if (isEmpty(data)) {
      this.initializeBlankFields();
    }
  }

  componentWillUnmount() {
    if (this.onBlurTimerId) clearTimeout(this.onBlurTimerId);
  }

  initializeBlankFields = () => {
    const { elements } = this.state;
    const data = elements.reduce(
      (prev, element) => ({ ...prev, [element.identifier]: '' }),
      {},
    );
    this.setState({ data });
  };

  handleSubfieldChange = (e) => {
    const { name, onChange } = this.props;
    const { data, elements } = this.state;
    const input = {
      name: e.target.name,
      value: e.target.value,
    };

    elements.forEach(({ identifier }) => {
      if (input.name === identifier) {
        data[identifier] = input.value;
      }
    });

    this.setState({ data });

    const newEvent = {
      target: {
        name,
        value: data,
      },
    };

    onChange(newEvent);
  };

  // We don't want to trigger "OnBlur" validation the millisecond one subfield is blurred,
  // as the user might just be clicking on the next subfield.
  handleSubfieldBlur = (e) => {
    const { name, value } = e.target;
    this.onBlurTimerId = setTimeout(this.delayedOnBlur, 500, name, value);
  };

  delayedOnBlur = () => {
    const { name, onBlur } = this.props;
    const { data } = this.state;

    const compoundEvent = {
      target: {
        name,
        value: { ...data },
      },
    };

    onBlur(compoundEvent);
  };

  handleFocus = () => {
    clearTimeout(this.onBlurTimerId);
  };

  render() {
    const { name } = this.props;
    const { data, elements } = this.state;

    return (
      <>
        <table className="table">
          <tbody>
            <Subfields
              name={name}
              onChange={this.handleSubfieldChange}
              data={data}
              elements={elements}
              onBlur={this.handleSubfieldBlur}
              onFocus={this.handleFocus}
            />
          </tbody>
        </table>
      </>
    );
  }
}

class Subfields extends React.Component {
  // eslint-disable-next-line class-methods-use-this
  generateEditableProp = (element) => {
    if (!element.type) {
      return {};
    }
    if (element.type === 'Boolean') {
      return { type: 'checkbox' };
    }
    if (element.type === 'Integer') {
      return { type: 'number' };
    }
    if (element.type === 'PhoneNumber') {
      return { type: 'phonenumber' };
    }
    if (element.type === 'Currency') {
      return { type: 'number', inputPrefix: <InputPrefix>$</InputPrefix> };
    }
    if (element.type === 'Percent') {
      return { type: 'number', inputSuffix: <InputSuffix>%</InputSuffix> };
    }
    if (element.type && element.type.type === 'Enum') {
      return { type: 'select', options: { values: element.type.set } };
    }
    if (element.type === 'CompanyName') {
      return { type: 'companyname' };
    }
    if (element.type && element.type.type === 'Date') {
      return {
        type: 'date',
        noYear: element.type.noYear,
      };
    }
    return {};
  };

  render() {
    const { name, elements, onChange, onBlur, onFocus, data } = this.props;

    const cells = elements.map((element) => {
      const label = capitalizeFirstLetter(element.name);
      let editableProp = '';
      if (element.type) {
        editableProp = this.generateEditableProp(element);
      }
      return (
        <SubfieldContainer
          key={element.identifier}
          className={`input-group col-md-6 col-xl-3 ${
            element.type === 'PhoneNumber' ? 'col-md-5 col-lg-4' : 'col-md-4'
          }`}
        >
          {editableProp.inputPrefix}
          <Subfield
            id={`${name}-${element.identifier}`}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            data={{
              name: element.identifier,
              label,
              value: data[element.identifier],
              editableProp,
            }}
          />
          {editableProp.inputSuffix}
        </SubfieldContainer>
      );
    });
    return <tr className="row ml-0">{cells}</tr>;
  }
}

const Subfield = ({ id, data, onChange, onBlur, onFocus }) => {
  if (data.editableProp.type === 'select') {
    const options = data.editableProp.options.values;
    return (
      <Dropdown
        id={id}
        name={data.name}
        noun={data.label}
        options={options}
        value={data.value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        inheritWidth
      />
    );
  }
  if (data.editableProp.type === 'companyname') {
    return (
      <CompanyName
        id={id}
        name={data.name}
        value={data.value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    );
  }
  if (data.editableProp.type === 'number') {
    return (
      <Input
        id={id}
        label={data.label}
        as={NumberFormat}
        thousandSeparator
        name={data.name}
        autoComplete="no"
        placeholder={data.label}
        value={data.value == null ? '' : data.value}
        onValueChange={(values) => {
          const newValue = values.value;
          const event = {
            target: {
              name: data.name,
              value: parseFloat(newValue),
            },
          };
          onChange(event);
        }}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    );
  }
  if (data.editableProp.type === 'phonenumber') {
    return (
      <>
        <Input
          id={id}
          label={data.label}
          as={NumberFormat}
          format="(###) ###-#### ext.####"
          type="tel"
          name={data.name}
          autoComplete="no"
          placeholder={data.label}
          value={data.value || ''}
          onValueChange={(values) => {
            const phoneNumbers = values.formattedValue.split(' ext.');
            const event = {
              target: {
                name: data.name,
                value:
                  values.value.length > 10
                    ? values.formattedValue
                    : phoneNumbers[0],
              },
            };
            onChange(event);
          }}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </>
    );
  }
  if (data.editableProp.type === 'date') {
    const format = data.editableProp.noYear ? 'MM d' : 'MM d, yyyy';
    return (
      <DatePicker
        name={data.name}
        onChange={onChange}
        onBlur={onBlur}
        value={data.value}
        format={format}
      />
    );
  }
  return (
    <FloatingTextField
      id={id}
      label={data.label}
      type={data.editableProp.type || 'text'}
      name={data.name}
      value={data.value || ''}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
};

export default MultiField;
