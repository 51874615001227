export function addressToStr({
  line1,
  line2,
  city,
  subnationalDivision1,
  postalCode,
  country,
}) {
  return [line1, line2, city, subnationalDivision1, postalCode, country]
    .filter((i) => i)
    .join(', ');
}
