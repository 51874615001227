import React, { forwardRef } from 'react';

import { Input } from './FormStyles';

function Email({ onChange, value, required, placeholder, ...props }, ref) {
  return (
    <Input
      type="email"
      onChange={(event) => {
        onChange({
          ...event,
          target: {
            ...event.target,
            name: event.target.name,
            value: event.target.value?.toLowerCase(),
          },
        });
      }}
      placeholder={placeholder ?? 'user@example.com'}
      value={value ?? ''}
      required={required || false}
      autoComplete="no"
      ref={ref}
      {...props}
    />
  );
}

export default forwardRef(Email);
