import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';

import { TypographyStyles } from '../../../Typography';
import { MatterFieldValueInput } from './MatterFieldValueInput';
import { convertInputValueToRallyValue } from './utils';

const MatterFieldValueEditorContainer = styled.div`
  width: 100%;

  border-radius: 2px;
`;

const MatterFieldValueInputContainer = styled.div`
  input,
  textarea {
    ${TypographyStyles.FontFamily}
    ${TypographyStyles.H6}
    ${TypographyStyles.Color}

    padding: 0;
    border: none;
    outline: none;
    background: transparent;

    width: 100%;

    :read-only {
      cursor: pointer;
    }

    :focus,
    :focus-visible {
      outline: 1px solid ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

export function MatterFieldValueEditor({
  isEditing = false,
  setIsEditing = (f) => f,
  matterFieldValue,
  updateMatterFieldValues = async (f) => f,
  ...props
}) {
  const { matterField, value: initialValue } = matterFieldValue;
  const { _id: matterFieldId } = matterField;

  const resolvedType = matterField.type?.type ?? matterField.type;

  const value =
    resolvedType === 'Date'
      ? new Date(initialValue).toISOString().substring(0, 10)
      : initialValue;

  const formRef = useRef(null);

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitSuccessful, isSubmitting },
  } = useForm({
    defaultValues: { matterFieldId, value },
  });

  const onSubmit = useCallback(
    async (data) => {
      if (data.value !== value) {
        const newValue = convertInputValueToRallyValue(matterField, data.value);
        await updateMatterFieldValues({ ...data, value: newValue });
      }

      setIsEditing(false);
    },
    [matterField, setIsEditing, updateMatterFieldValues, value],
  );

  const onInvalid = useCallback(
    (errors) => {
      reset({ matterFieldId, value });
      setIsEditing(false);
      toast.error(`Unable to update Matter Field. ${errors.value.message}`);
    },
    [setIsEditing, reset, matterFieldId, value],
  );

  useEffect(() => {
    if (!isSubmitting && !isEditing && !!isDirty) {
      formRef.current?.requestSubmit();
    }
  }, [isEditing, isDirty, isSubmitting]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ matterFieldId, value });
    }
  }, [isSubmitSuccessful, matterFieldId, value, reset]);

  return (
    <MatterFieldValueEditorContainer {...props}>
      <form onSubmit={handleSubmit(onSubmit, onInvalid)} ref={formRef}>
        <input type="hidden" {...register('matterFieldId')} />
        <MatterFieldValueInputContainer>
          <MatterFieldValueInput
            id={`${matterFieldId}-value`}
            name="value"
            matterField={matterFieldValue.matterField}
            register={register}
            control={control}
            onFocus={() => setIsEditing(true)}
            onBlur={() => setIsEditing(false)}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && !isEditing) {
                event.preventDefault();
                setIsEditing(true);
              }
            }}
            readOnly={!isEditing}
            rules={{
              setValueAs: (v) => v?.trim?.() ?? v,
              required: 'A value is required.',
            }}
          />
        </MatterFieldValueInputContainer>
      </form>
    </MatterFieldValueEditorContainer>
  );
}

MatterFieldValueEditor.propTypes = {
  isEditing: PropTypes.bool,
  setIsEditing: PropTypes.func,
  matterFieldValue: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    matterField: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      type: PropTypes.any,
      name: PropTypes.string,
    }),
    value: PropTypes.any,
  }),
  updateMatterFieldValues: PropTypes.func,
};
