import { gql, useQuery } from '@apollo/client';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { components, createFilter } from 'react-select';
import styled from 'styled-components/macro';

import { stripTypenames, userIsClient } from '../../../services/Utilities';
import { CreatableComboBox } from '../FormStyles';

const CONTACT_DATA_FRAGMENT = gql`
  fragment ContactData on Contact {
    _contactId
    name
    address {
      city
      country
      line1
      line2
      postalCode
      subnationalDivision1
    }
    type
    phone
    email
    source {
      salesforce {
        recordId
      }
    }
  }
`;

const FIRM_GET_CONTACTS = gql`
  query firmGetContacts(
    $userId: String!
    $firmId: MongoID!
    $clientId: MongoID!
    $contactType: EnumContactType
  ) {
    firmUser(id: $userId) {
      id
      firm(id: $firmId) {
        _id
        client(org: $clientId) {
          _id
          contacts(filter: { type: $contactType }) {
            ...ContactData
          }
        }
      }
    }
  }

  ${CONTACT_DATA_FRAGMENT}
`;

const CLIENT_GET_CONTACTS = gql`
  query clientGetContact($userId: MongoID!, $contactType: EnumContactType) {
    client(_id: $userId) {
      _id
      organizationDetails {
        _id
        contacts(filter: { type: $contactType }, sort: sourceNameAsc) {
          ...ContactData
        }
      }
    }
  }

  ${CONTACT_DATA_FRAGMENT}
`;

const Input = (props) => (
  <components.Input {...props} isHidden={false} autoCapitalize="words" />
);

const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SourceTag = styled.div`
  color: ${({ theme }) => theme.palette.shade.gray_3};
  margin-left: auto;
`;

export const ContactNameComboBox = forwardRef(
  (
    { clientId, labelledby, contactData, updateContactData, contactType },
    ref,
  ) => {
    const userId = localStorage.getItem('userId');
    const firmId = localStorage.getItem('firmId');

    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState(contactData?.name || '');

    const { loading, refetch } = useQuery(
      userIsClient() ? CLIENT_GET_CONTACTS : FIRM_GET_CONTACTS,
      {
        fetchPolicy: 'cache-and-network',
        variables: userIsClient()
          ? {
              userId: userId.replace(/^.*\|/, ''),
              contactType,
            }
          : { userId, firmId, clientId, contactType },
        onCompleted: (data) => {
          const organizationDetails = userIsClient()
            ? data?.client?.organizationDetails?.[0]
            : data?.firmUser?.firm?.client;
          const newContacts = organizationDetails?.contacts ?? [];
          setOptions(
            stripTypenames(newContacts).map((contact) => ({
              label: contact.name,
              value: contact,
              source: contact.source,
            })),
          );
        },
      },
    );

    const onInputChange = useCallback(
      (newInputValue, { action }) => {
        const oldContactData = contactData || {};

        if (action === 'input-change') {
          setInputValue(newInputValue);
          updateContactData({ ...oldContactData, name: newInputValue });
        }
      },
      [contactData, updateContactData],
    );

    const onChange = useCallback(
      (newValue) => {
        updateContactData(newValue?.value ?? {});
      },
      [updateContactData],
    );

    useEffect(() => setInputValue(contactData?.name || ''), [contactData]);

    return (
      <CreatableComboBox
        aria-labelledby={labelledby}
        placeholder={null}
        inputValue={inputValue}
        value={{ value: contactData, label: contactData?.name ?? '' }}
        onChange={onChange}
        onInputChange={onInputChange}
        blurInputOnSelect={false}
        options={options}
        isClearable
        isLoading={loading}
        onCreateOption={(name) => updateContactData({ name })}
        onFocus={() => refetch()}
        filterOption={createFilter({ stringify: (option) => option.label })}
        isValidNewOption={(value) => !!value}
        noOptionsMessage={() => null}
        loadingMessage={() => null}
        menuPlacement="auto"
        controlShouldRenderValue={false}
        formatCreateLabel={() => `Add contact "${inputValue}"`}
        formatOptionLabel={({ label, source }) => {
          const sourceName = source?.salesforce ? 'Salesforce' : null;
          return (
            <OptionContainer>
              {label}
              {sourceName && <SourceTag>{sourceName}</SourceTag>}
            </OptionContainer>
          );
        }}
        components={{
          Input,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        ref={ref}
      />
    );
  },
);
