import styled, { css } from 'styled-components/macro';

import { ContentExplorer } from '../proxy-box';

const SmallExplorer = styled(ContentExplorer)`
  && {
    height: initial;
    outline: none !important;
    .be-app-element {
      outline: none !important;
    }
    .be-logo {
      display: none;
    }
    .bce-item-row:hover,
    .bce-item-row:active,
    .bce-item-row:focus {
      background-color: #ffffff;
      border-left-color: ${(props) => props.theme.palette.primary.light};
      box-shadow: 0 0 5px #d8dadc;
    }
    .bce-item-row.bce-item-row-selected {
      background-color: #f2f2f2;
      border-bottom-color: #f2f2f2;
    }
    .bce-item-row {
      border-left-width: 3px;
    }
    .be-modal-dialog-content {
      top: 30%;
    }
    .be-item-name:hover {
      cursor: pointer;
    }
    ${({ showSearchBar = true }) =>
      !showSearchBar &&
      css`
        && .be-search {
          display: none;
        }
      `}
    ${({ showHeader = true }) =>
      !showHeader &&
      css`
        && .be-header {
          display: none;
        }
      `}
    .bcu-footer {
      background: white;
    }
    && .btn-group > .btn.btn-primary:last-of-type {
      border-right-color: ${(props) => props.theme.palette.primary.main};
    }
    && .btn,
    && .be-modal-buttons > .btn {
      background-color: transparent;
      color: ${(props) => props.theme.palette.primary.main};
      font-weight: 400;
      font-size: 14px;
      padding: 5px 16px;
      line-height: inherit;
      border-radius: 2px;
      outline: none !important;
      transition: 0.15s linear;

      width: ${(props) => props.width || 'auto'};
      text-align: ${(props) => props.textAlign || 'center'};
      white-space: nowrap;
      border: 1px solid transparent;
      margin-top: ${(props) => props.marginTop};

      &:hover {
        background-color: transparent;
        border-color: transparent;
        cursor: pointer;
        color: ${(props) => props.theme.palette.primary.dark};
      }

      &:active {
        background-color: transparent;
        border-color: transparent;
        color: ${(props) => props.theme.palette.primary.dark};
      }

      &:focus {
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
        color: ${(props) => props.theme.palette.primary.dark};
      }

      &:disabled {
        background-color: transparent;
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
    && .btn.btn-primary {
      background-color: ${(props) => props.theme.palette.primary.main};
      border-color: ${(props) => props.theme.palette.primary.main};
      color: ${(props) => props.theme.palette.primary.contrastText};
    }
    && .btn.btn-primary:hover {
      background-color: ${(props) => props.theme.palette.primary.light};
      border-color: ${(props) => props.theme.palette.primary.light};
      border-right-color: ${(props) => props.theme.palette.primary.light};
      color: ${(props) => props.theme.palette.primary.contrastText};
    }
    && .bcu-upload-state {
      .fill-color {
        fill: ${(props) => props.theme.palette.primary.main};
      }
      .stroke-color {
        stroke: ${(props) => props.theme.palette.primary.main};
      }
    }
    && .be-input-link {
      color: ${(props) => props.theme.palette.primary.main};
    }
    && .bce-item-row {
      &:hover,
      &:active,
      &:focus {
        .btn-plain {
          color: ${(props) => props.theme.palette.primary.main};
          outline: none;
        }
      }
    }
    && .bce-item-row.bce-item-row-selected {
      .btn-plain {
        color: ${(props) => props.theme.palette.primary.main};
        outline: none;
      }
    }
    && .bcu-progress {
      background: ${(props) => props.theme.palette.primary.main};
    }
    && .crawler div {
      background-color: ${(props) => props.theme.palette.primary.main};
    }
  }
`;

export default SmallExplorer;
