/* eslint-disable react/no-string-refs */
/* eslint-disable react/jsx-one-expression-per-line */
import Radium from 'radium';
import React, { Component } from 'react';
import styled, { withTheme } from 'styled-components/macro';

const ProgressBarItem = styled.li`
  @media (max-width: 880px) {
    &&& {
      padding: 0;
    }
  }
`;

class MTTerms extends Component {
  render() {
    const {
      closeTerms,
      finishText,
      handleSubmit,
      hideDisagree,
      hideFinish,
      nextText,
      theme,
    } = this.props;
    const { palette } = theme;
    const primary = {
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,
      borderColor: palette.primary.main,
      ':hover': {
        backgroundColor: palette.primary.light,
        borderColor: palette.primary.light,
      },
      ':disabled': {
        backgroundColor: palette.primary.light,
        borderColor: palette.primary.light,
      },
    };
    const secondary = {
      backgroundColor: palette.secondary.main,
      color: palette.secondary.contrastText,
      borderColor: palette.secondary.main,
      ':hover': {
        backgroundColor: palette.secondary.light,
        borderColor: palette.secondary.light,
      },
    };

    const tertiary = {
      backgroundColor: '#e4e7ea',
      color: '#4d5259',
      borderColor: '#e4e7ea',
      ':hover': {
        backgroundColor: '#edeff1',
        borderColor: '#edeff1',
      },
    };

    const buttonFlexBox = {
      display: 'flex',
      textAlign: 'center',
    };

    const disagree = {
      display: 'inline-block',
      marginRight: '24px',
      marginBottom: '0px',
      color: palette.secondary.main,
      cursor: 'pointer',
    };

    return (
      <div style={{ margin: '0px 30px 0px 30px' }}>
        <div>
          <div data-provide="wizard">
            <ul
              className="nav nav-process nav-process-circle"
              style={{ marginLeft: '-24px', marginRight: '-34px' }}
            >
              <ProgressBarItem className="nav-item">
                <span className="nav-title d-none d-sm-block">
                  Introduction
                </span>
                <a
                  className="nav-link d-none d-sm-block d-none d-sm-block"
                  style={{ cursor: 'default' }}
                  data-toggle="tab"
                  href="#wizard-basic-1"
                >
                  {' '}
                </a>
              </ProgressBarItem>

              <ProgressBarItem className="nav-item">
                <span className="nav-title d-none d-sm-block">Engagement</span>
                <a
                  className="nav-link d-none d-sm-block"
                  style={{ cursor: 'default' }}
                  data-toggle="tab"
                  href="#wizard-basic-2"
                >
                  {' '}
                </a>
              </ProgressBarItem>

              <ProgressBarItem className="nav-item">
                <span className="nav-title d-none d-sm-block">Platform</span>
                <a
                  className="nav-link d-none d-sm-block"
                  style={{ cursor: 'default' }}
                  data-toggle="tab"
                  href="#wizard-basic-3"
                >
                  {' '}
                </a>
              </ProgressBarItem>

              <ProgressBarItem className="nav-item">
                <span className="nav-title d-none d-sm-block">Termination</span>
                <a
                  className="nav-link d-none d-sm-block"
                  style={{ cursor: 'default' }}
                  data-toggle="tab"
                  href="#wizard-basic-4"
                >
                  {' '}
                </a>
              </ProgressBarItem>

              <ProgressBarItem className="nav-item">
                <span className="nav-title d-none d-sm-block">Providers</span>
                <a
                  className="nav-link d-none d-sm-block"
                  style={{ cursor: 'default' }}
                  data-toggle="tab"
                  href="#wizard-basic-5"
                >
                  {' '}
                </a>
              </ProgressBarItem>

              <ProgressBarItem className="nav-item">
                <span className="nav-title d-none d-sm-block">IP</span>
                <a
                  className="nav-link d-none d-sm-block"
                  style={{ cursor: 'default' }}
                  data-toggle="tab"
                  href="#wizard-basic-6"
                >
                  {' '}
                </a>
              </ProgressBarItem>

              <ProgressBarItem className="nav-item">
                <span className="nav-title d-none d-sm-block">Disclaimer</span>
                <a
                  className="nav-link d-none d-sm-block"
                  style={{ cursor: 'default' }}
                  data-toggle="tab"
                  href="#wizard-basic-7"
                >
                  {' '}
                </a>
              </ProgressBarItem>

              <ProgressBarItem className="nav-item">
                <span className="nav-title d-none d-sm-block">CASL</span>
                <a
                  className="nav-link d-none d-sm-block"
                  style={{ cursor: 'default' }}
                  data-toggle="tab"
                  href="#wizard-basic-8"
                >
                  {' '}
                </a>
              </ProgressBarItem>
            </ul>

            <div
              className="alert"
              style={{
                backgroundColor: '#f7fafc',
                color: '#4d5259',
              }}
              role="alert"
            >
              If you have any questions, concerns or comments regarding these
              Terms, please{' '}
              <a
                href="mailto:support-amplifi@millerthomson.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                contact us
              </a>
              .
            </div>

            <div
              className="tab-content"
              style={{ minHeight: '275px', margin: '30px 0px' }}
            >
              <div className="tab-pane fade active show" id="wizard-basic-1">
                <h6>
                  <center>TERMS OF SERVICE</center>
                </h6>
                <h6>SECTION 1: INTRODUCTION</h6>
                <p>
                  Welcome to MT AMPLIFI. The following are the terms and
                  conditions (the “<b>Terms</b>
                  ”) of the MT AMPLIFI platform and all connected websites and
                  portals (collectively, the “<b>Platform</b>
                  ”), made available by Miller Thomson LLP (“
                  <b>MT</b>” “<b>we</b>
                  ”, “<b>our</b>
                  ”, “<b>us</b>
                  ”). We may change the Terms at any time in our discretion and
                  without notice. Any changes will be identified in the Terms
                  and will be effective four (4) weeks after being posted on the
                  Platform. As such, please refer back to the Terms.
                </p>
                <p>
                  You may not use the Platform for any purpose other than those
                  covered by the Terms or for any purpose that is prohibited by
                  the Terms or applicable law. If you agree below, you will be
                  bound by these Terms in connection with all use of the
                  Platform. If you do not agree to these Terms, you may not
                  access or make use of the Platform. We may, in our discretion
                  and without notice, terminate your access to the Platform.
                </p>
                <p>
                  You agree to make MT and its partners, employees and agents
                  (and those of its affiliates) whole for any and all losses,
                  liabilities and expenses (including attorneys’ fees) arising
                  from your use of the Platform or any violation of these Terms.
                </p>
                <p>
                  The Terms represent a binding and final agreement between you
                  and MT, governed by the laws of Ontario to the exclusion of
                  all other laws and without regard to conflicts of law
                  principles. The Terms supersede any prior communication
                  regarding the topics set out here, and if any portion is found
                  to be unenforceable then that portion will be severed and will
                  not impact the enforceability of any other portion. Any waiver
                  or failure to enforce any provision of the Terms on one
                  occasion is not a waiver of any other provision or of the same
                  provision on any other occasion. All disputes and matters
                  whatsoever arising under, in connection with, or in any way
                  related to the Terms or any legal services provided by MT,
                  whether arising under contract, tort, other legal theories, or
                  specific statutes of any jurisdiction, shall be litigated, if
                  at all, in and before a superior court located in Ontario to
                  the exclusion of the courts of any other province, state or
                  country.
                </p>
              </div>

              <div className="tab-pane fade" id="wizard-basic-2">
                <h6>SECTION 2: ENGAGEMENT OF MT; CONFLICT WAIVER</h6>
                <p>
                  By agreeing to these Terms and using the Platform, and subject
                  to our review of the information you have provided or will
                  provide to determine any conflict we may have in representing
                  you, you have retained MT to provide limited legal services
                  through the MT AMPLIFI program. However, your retainer of MT
                  is not effective unless we, in our discretion, confirm that we
                  are able to act on your behalf and choose to accept you as a
                  client. And in addition, the specific scope of any services we
                  will provide are not set out in the Terms and must be
                  separately agreed between you and MT.
                </p>
                <p>
                  Any formal legal advice will come only when you speak, meet or
                  correspond directly with MT lawyers in connection with a scope
                  of services, and associated fees, disbursements and other
                  charges, you and MT have separately agreed to (each, an “
                  <b>Agreed Project</b>
                  ”).
                </p>
                <p>
                  You hereby consent to MT acting adverse to you – on matters
                  which predate the date a first Agreed Project is accepted by
                  you and MT (which may include litigation matters) and on
                  future non-contentious matters – on the basis that we do not
                  have confidential information from you that is relevant to
                  such adverse matters. Examples of matters on which MT could
                  act adverse to you include, but are not limited to: acting for
                  an employee, supplier, business associate, investor, lender,
                  etc. of yours. If we determine that we have confidential
                  information that may be relevant, we will only act for such
                  clients adverse in interest to you with your consent. Doing so
                  may involve establishing temporary institutional mechanisms to
                  ensure your confidentiality is maintained.
                </p>
              </div>

              <div className="tab-pane fade" id="wizard-basic-3">
                <h6>SECTION 3: PLATFORM NOT LEGAL ADVICE</h6>
                <p>
                  The Platform is an online legal education and
                  information-gathering portal that allows you to access general
                  information and to provide information we may use in providing
                  legal services. However, the Platform does not provide legal
                  or any other advice, and you may not rely on it as providing
                  advice. The content of the Platform is general information
                  about the legal and business environment, may not be
                  appropriate for your circumstances, and may not be complete or
                  up to date. Any opinions expressed on the Platform are those
                  of the particular author(s) and may not reflect those of MT or
                  any individual lawyer.
                </p>
                <p>
                  Please note that documentation we prepare and services we
                  provide will be based upon the information you submit through
                  the Platform. You are responsible for the accuracy of this
                  information. If you enter incorrect information and/or request
                  changes that require additional work then there may be
                  additional fees. Similarly, the Platform interface is designed
                  to accommodate a range of options that you may select within
                  what is considered customary for the type of services we
                  offer. We will attempt to include adjustments you may require,
                  but additional work may result in additional fees.
                </p>
                <p>
                  We will not undertake any additional work, or charge any
                  additional fees beyond those provided for in an Agreed
                  Project, unless we have first notified you and you have
                  accepted any new arrangements.
                </p>
              </div>

              <div className="tab-pane fade" id="wizard-basic-4">
                <h6>SECTION 4: TERMINATION OR AGREED PROJECT(S)</h6>
                <p>
                  You have the right to terminate our representation at any
                  time, on written notice to us. If applicable, we will provide
                  a final accounting for any amounts owing to us and applicable
                  taxes.
                </p>
                <p>
                  We have the right to terminate our representation for any
                  reason not prohibited by applicable rules of professional
                  conduct, or if we are not paid as set out in any Agreed
                  Project.
                </p>
                <p>
                  Unless previously terminated, our representation of you with
                  respect to any Agreed Project will end on the later of our
                  completion of the services provided for in an already fully
                  paid Agreed Project, or when we send our final statement of
                  fees, charges, disbursements and applicable taxes in respect
                  of the Agreed Project.
                </p>
                <p>
                  We will retain your file for a period of at least 15 years.
                  The file may be destroyed at the end of that period without
                  any further notice to you, unless you send us a written
                  request to have the file returned, prior to its destruction.
                </p>
                <p>
                  After our representation ends with respect to an Agreed
                  Project, there may be changes in laws, regulations or
                  deadlines that might affect your future rights and
                  liabilities. We do not have an obligation to continue to
                  advise you about future legal developments or deadlines
                  following the termination of any Agreed Project unless you
                  separately engage us to do so.
                </p>
              </div>

              <div className="tab-pane fade" id="wizard-basic-5">
                <h6>SECTION 5: THIRD PARTY SERVICE PROVIDERS</h6>
                <p>
                  The Platform and its elements are hosted or may be hosted by
                  third parties including Dialog Enterprises Inc. (“
                  <b>Rally</b>
                  ”). Rally and other third parties are not lawyers or law firms
                  and therefore information you provide may not be protected by
                  solicitor-client privilege and may not be subject to other
                  protections that may apply to solicitor-client communications.
                  Such information may be subject to summons, disclosure or
                  other release if required by applicable law.
                </p>
                <p>
                  MT enters into agreements with its third party partners,
                  including Rally, requiring that they employ at least industry
                  standard security to protect your information and that they
                  will only use confidential information to assist us with
                  providing our legal services. We do not guarantee the security
                  of the Platform, and MT is not responsible for any third
                  party’s failures or non-compliance.
                </p>
                <p>
                  We may hyperlink to, or otherwise make available, third party
                  information on the Platform. This is only for convenience, and
                  if you decide to access linked sites you do so at your own
                  risk and should review any terms of use, privacy policies and
                  other rules those third parties maintain. We do not endorse
                  any third parties, and we are not responsible for their
                  actions. If you have concerns regarding third party sites, you
                  should deal with the applicable third parties directly.
                </p>
              </div>

              <div className="tab-pane fade" id="wizard-basic-6">
                <h6>
                  SECTION 6: INTELLECTUAL PROPERTY, CONTENT, PRIVACY & SECURITY
                </h6>
                <p>
                  The Platform, all intellectual property associated with it,
                  and everything that appears on it (collectively, the “
                  <b>Materials</b>
                  ”) is owned and legally protected by us and/or third parties
                  we work with. You have no rights to the Materials except with
                  our express agreement.
                </p>
                <p>
                  All information of any sort that you or others enter, submit
                  or otherwise make available through the Platform (“
                  <b>Content</b>
                  ”) is provided for our use, and you agree to our use of your
                  Content to provide services to you. MT’s Privacy Policy (which
                  is accessible{' '}
                  <a
                    href="https://www.millerthomson.com/en/our-services/services/privacy-information-protection/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                  ) will apply to all applicable Content. Except with respect to
                  personal information as set out in the Privacy Policy, you
                  have no expectation of privacy regarding your Content. Any
                  comments, ideas or other information regarding the Platform or
                  our services that you provide (“
                  <b>Feedback</b>
                  ”) will belong to us and we may use Feedback for any purpose
                  without acknowledgement or compensation to you. In addition,
                  subject to our Privacy Policy and applicable law, we may
                  anonymize and aggregate Content, and we have the right to use
                  that anonymized and aggregated Content for any purpose.
                </p>
                <p>
                  You are responsible for all Content you provide, and other
                  users (and not MT), are responsible for their own Content. We
                  have no obligation to approve Content, but we may pre-screen,
                  refuse or remove any Content at any time and for any reason.
                  Unless you have retained us, we have no obligation to store
                  any Content for any length of time, and we have no
                  responsibility for maintenance of any Content; the failure to
                  store, transmit or receive Content; or the security, privacy,
                  storage or transmission of other communications in any way
                  related to the Platform.
                </p>
                <p>
                  You are responsible for keeping your username, password and
                  any other credentials confidential. You may not permit any
                  third party to use your credentials to access the Platform,
                  and you will be responsible if you break this rule.
                </p>
              </div>

              <div className="tab-pane fade" id="wizard-basic-7">
                <h6>SECTION 7: DISCLAIMER & LIMITATION OF LIABILITY</h6>
                <p>
                  YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE EXTENT
                  PERMITTED UNDER APPLICABLE LAW, YOUR USE OF THE PLATFORM IS AT
                  YOUR SOLE RISK, AND THE PLATFORM IS PROVIDED ON AN “AS IS” AND
                  “AS AVAILABLE” BASIS, WITH ALL FAULTS. MT AND ITS PARTNERS,
                  EMPLOYEES AND AGENTS (AND THOSE OF ITS AFFILIATES) EXPRESSLY
                  DISCLAIM ALL WARRANTIES, REPRESENTATIONS AND CONDITIONS OF ANY
                  KIND, WHETHER EXPRESS OR IMPLIED, RELATED TO THE PLATFORM,
                  INCLUDING BUT NOT LIMITED TO, THE IMPLIED TERMS, WARRANTIES OR
                  CONDITIONS OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS
                  FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
                </p>
                <p>
                  YOU UNDERSTAND AND AGREE THAT IN NO EVENT SHALL MT AND ITS
                  PARTNERS, EMPLOYEES AND AGENTS (AND THOSE OF ITS AFFILIATES)
                  BE LIABLE FOR ANY DAMAGES RESULTING FROM LOSS OF USE, DATA, OR
                  PROFITS WHETHER DIRECT OR INDIRECT OR FOR ANY INDIRECT,
                  INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES,
                  ARISING OUT OF OR IN CONNECTION WITH THE PLATFORM WHETHER OR
                  NOT WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                  THE AGGREGATE CUMULATIVE LIABILITY OF MT AND ITS PARTNERS,
                  EMPLOYEES AND AGENTS (AND THOSE OF ITS AFFILIATES) IN
                  CONNECTION WITH ANY CLAIM ARISING OUT OF OR RELATING TO THE
                  PLATFORM SHALL NOT EXCEED $100 (CAD), AND THAT AMOUNT SHALL BE
                  IN LIEU OF ALL OTHER REMEDIES WHICH YOU MAY HAVE AGAINST SUCH
                  PARTIES.
                </p>
              </div>

              <div className="tab-pane fade" id="wizard-basic-8">
                <h6>SECTION 8: USE OF COMMERCIAL ELECTRONIC MESSAGES</h6>
                <p>
                  Pursuant to Canada’s anti-spam legislation (“
                  <b>CASL</b>
                  ”), we seek your consent to send you commercial electronic
                  messages, such as emails, in connection with the use of the
                  Platform and/or the delivery of our legal services to you. We
                  are seeking your consent on behalf of Miller Thomson LLP,
                  Miltom Management Inc. and Miltom Services Inc., our companies
                  that, among other matters, provide our electronic systems, as
                  well as all of our other affiliated MT corporations.
                </p>
                <p>
                  If you wish to withdraw your consent to receive our legal
                  services by electronic means, you may do so by contacting us
                  using the contact information in these Terms. If you do not
                  consent, or if you later withdraw your consent, you will no
                  longer be able to make use of the Platform and we will deliver
                  any legal services to you only by means of phone, fax, mail or
                  courier, or however else you instruct us.{' '}
                </p>
              </div>
            </div>
            <div>
              <div style={buttonFlexBox}>
                <button
                  className="btn btn-secondary"
                  style={tertiary}
                  ref="back"
                  type="button"
                  data-wizard="prev"
                >
                  Back
                </button>
                <div style={{ marginLeft: 'auto' }}>
                  {!hideDisagree && (
                    <p style={disagree} onClick={closeTerms}>
                      I don’t understand and/or agree
                    </p>
                  )}
                  <button
                    className="btn"
                    data-wizard="next"
                    style={secondary}
                    type="button"
                  >
                    {nextText || 'Next'}
                  </button>
                  {!hideFinish && (
                    <button
                      className="btn d-none btn-primary"
                      ref="enabledFinish"
                      data-wizard="finish"
                      style={primary}
                      type="submit"
                      onClick={handleSubmit}
                    >
                      {finishText || 'Close'}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTheme(Radium(MTTerms));
