import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

import { TextButton } from '../../../standard/Button';

export function AddMatterFieldButton({
  setDisplayAddMatterFieldModal,
  ...props
}) {
  return (
    <TextButton
      className="rally-typography rally-typography-p"
      onClick={() => setDisplayAddMatterFieldModal(true)}
      {...props}
    >
      <FontAwesomeIcon icon={faPlus} /> Matter Field
    </TextButton>
  );
}

AddMatterFieldButton.propTypes = {
  setDisplayAddMatterFieldModal: PropTypes.func,
};
