import React from 'react';

import Modal from './Modal';

const UnsavedChangesModal = ({ display, onAccept, onCancel }) => (
  <Modal
    title="Unsaved Changes"
    display={display}
    showCancel
    onClose={onCancel}
    onCancel={onAccept}
    onAccept={onCancel}
    acceptText="Cancel"
    cancelText="Leave without Saving"
    acceptPaletteType="destructive"
  >
    <p>
      <b>
        There are unsaved changes! If you leave before saving, your changes will
        be lost.
      </b>
    </p>
  </Modal>
);

export default UnsavedChangesModal;
