import { useFeatureToggle } from '@flopflip/react-broadcast';
import { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { flagNames } from '../../featureFlags';
import { userIsClient } from '../../services/Utilities';

export default ({
  matter,
  setDisplaySendForSigning,
  setDisplayReviewModal,
  addMatterCategory,
  addMatterContact,
  toggleMatterState,
  revokeClientAccess,
  setDisplayShareModal,
  setDisplayAddReviewersModal,
  setDisplayAddMatterFieldModal,
}) => {
  const {
    id: matterId,
    state,
    legacyState,
    clientAccessible,
    hasQuestionnaire,
  } = matter;
  const isClientUser = userIsClient();
  const history = useHistory();
  const { url } = useRouteMatch();
  const enableClientMatterManagement = useFeatureToggle(
    flagNames.ENABLE_CLIENT_MATTER_MANAGEMENT,
  );
  const allowMatterManagement = !isClientUser || enableClientMatterManagement;
  const enableMatterReviews = useFeatureToggle(flagNames.MATTER_REVIEWS);
  const areMatterFieldsEnabled = useFeatureToggle(flagNames.MATTER_FIELDS);

  useEffect(() => {
    window.CommandBar.addMetadata('matterId', matterId);
    window.CommandBar.addCallback('sendForSigning', () => {
      setDisplaySendForSigning(true);
    });
    if (enableMatterReviews && isClientUser) {
      window.CommandBar.addCallback('addReviewer', () => {
        setDisplayAddReviewersModal(true);
      });
    }
    window.CommandBar.addCallback(
      'addCategory',
      ({ category: { id: categoryId } }) => {
        addMatterCategory({ _id: categoryId });
      },
    );
    window.CommandBar.addCallback(
      'addAssociatedContact',
      ({ contact: { id: contactId } }) => {
        addMatterContact({ _contactId: contactId });
      },
    );
    if (isClientUser) {
      window.CommandBar.addCallback('requestFirmReview', () => {
        setDisplayReviewModal(true);
      });
    }
    if (!isClientUser) {
      if (clientAccessible) {
        if (legacyState === 'awaitingPayment') {
          window.CommandBar.addCallback('cancelPayment', revokeClientAccess);
        } else {
          window.CommandBar.addCallback(
            'revokeClientAccess',
            revokeClientAccess,
          );
        }
      } else {
        window.CommandBar.addCallback('shareWithClient', () =>
          setDisplayShareModal(true),
        );
      }
    }
    if (allowMatterManagement) {
      if (state === 'open') {
        window.CommandBar.addCallback('closeMatter', toggleMatterState);
      } else if (state === 'closed') {
        window.CommandBar.addCallback('openMatter', toggleMatterState);
      }
      if (hasQuestionnaire) {
        window.CommandBar.addCallback('editQuestionnaire', () => {
          history.push(`${url}/${isClientUser ? `questionnaire` : `edit`}`);
        });
      }
      if (areMatterFieldsEnabled) {
        window.CommandBar.addCallback('addMatterField', () => {
          setDisplayAddMatterFieldModal(true);
        });
      }
    }
    return () => {
      window.CommandBar.addMetadata('matterId', undefined);
      window.CommandBar.removeCallback('sendForSigning');
      window.CommandBar.removeCallback('addMatterField');
      window.CommandBar.removeCallback('addCategory');
      window.CommandBar.removeCallback('requestFirmReview');
      window.CommandBar.removeCallback('addAssociatedContact');
      window.CommandBar.removeCallback('closeMatter');
      window.CommandBar.removeCallback('openMatter');
      window.CommandBar.removeCallback('shareWithClient');
      window.CommandBar.removeCallback('revokeClientAccess');
      window.CommandBar.removeCallback('cancelPayment');
      window.CommandBar.removeCallback('editQuestionnaire');
    };
  }, [
    matterId,
    state,
    legacyState,
    clientAccessible,
    setDisplaySendForSigning,
    setDisplayReviewModal,
    addMatterCategory,
    addMatterContact,
    toggleMatterState,
    revokeClientAccess,
    setDisplayShareModal,
    setDisplayAddMatterFieldModal,
    isClientUser,
    allowMatterManagement,
    enableMatterReviews,
    areMatterFieldsEnabled,
    setDisplayAddReviewersModal,
    hasQuestionnaire,
    history,
    url,
  ]);

  return null;
};
