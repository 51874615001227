import { useQuery } from '@apollo/client';
import { useFeatureToggle } from '@flopflip/react-broadcast';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled, { css } from 'styled-components/macro';

import { useFirmSettings } from '../../../context/UserContext';
import { flagNames } from '../../../featureFlags';
import { FETCH_ORGANIZATION_PERMISSION_FOR_USER } from '../../../graphql/organization-permission/queries';
import Button from '../../standard/Button';
import Checkbox from '../../standard/Checkbox';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogSubtitle,
  DialogTitle,
} from '../../standard/Dialog';
import { LabelledRadio, RadioGroup } from '../../standard/Radio';
import Spinner from '../../standard/Spinner';
import { useCategories } from '../categories/hooks';

const StyledDialogHeader = styled(DialogHeader)`
  overflow: hidden;

  h4 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const FooterControl = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 2rem 1.5rem 2rem;
`;

const FooterButton = styled(Button)`
  font-size: 18px;
  min-width: 120px;
  height: 50px;
`;

const StyledEmail = styled.span`
  font-weight: bold;
`;

const SelectAllButton = styled.button`
  background-color: transparent;
  border: none;

  color: ${({ theme }) => theme.palette.primary.main};

  &:hover {
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`;

const PermissionsContainer = styled.div`
  display: flex;

  flex-direction: column;
  margin-bottom: 1rem;
`;

const StyledCategoryList = styled.div`
  border-top: solid ${({ theme }) => theme.palette.shade.gray_3} 0.5px;
  padding-top: 1rem;
  padding-left: 2rem;
  width: 85%;

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 0;
  margin: 0 0 0.5rem 0;

  .custom-control-description {
    font-weight: 300;
    font-size: 13px;
    line-height: 24px;
  }
`;

const FormContent = styled.div`
  margin-top: 2rem;
`;

const PermissionSection = styled.section`
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

const SectionLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 1rem;
  border-bottom: solid ${({ theme }) => theme.palette.shade.gray_3} 0.5px;
`;

const AccessContainer = styled.div`
  margin-left: 3.125rem;
`;

const CategoryList = ({ hidden, register }) => {
  const [categories, { loading }] = useCategories({
    depth: 0,
    usePermissionsFilter: false,
  });

  return (
    <StyledCategoryList hidden={hidden}>
      {loading && <Spinner />}
      {categories?.map((category) => (
        <StyledCheckbox
          label={category.name}
          key={category.id}
          value={category.id}
          {...register('canAccessMatterCategories')}
        />
      ))}
    </StyledCategoryList>
  );
};

export function SetPermissionsModal({
  open,
  email,
  clientId,
  clientUserId,
  onClose = () => {},
  onSubmit = () => {},
}) {
  const isRFB = useFeatureToggle(flagNames.RFB);

  const { handleSubmit, watch, control, register, reset, setValue } = useForm({
    defaultValues: {
      canAccessAllMatters: true,
      canAccessMatterCategories: [],
      canManageTeamMembersPermissionsAndBilling: true,
      canManageTeamMembers: true,
      canManagePermissions: true,
      canManageBilling: true,
      canCreateNewMatters: true,
      canModifyTemplates: true,
      canAccessInformationTab: true,
      canAccessFilesRepository: true,
    },
  });
  const [{ showCategories }] = useFirmSettings();

  const { loading } = useQuery(FETCH_ORGANIZATION_PERMISSION_FOR_USER, {
    variables: { userId: clientUserId, organizationId: clientId },
    onCompleted: (data) => {
      const {
        _id: permissionId,
        canAccessAllMatters,
        canAccessMatterCategories,
        canManageTeamMembers,
        canManagePermissions,
        canManageBilling,
        canCreateNewMatters,
        canModifyTemplates,
        canAccessInformationTab,
        canAccessFilesRepository,
      } = data?.organizationPermission ?? {};

      reset({
        permissionId,
        canAccessAllMatters,
        canAccessMatterCategories,
        canManageTeamMembersPermissionsAndBilling:
          canManageTeamMembers && canManagePermissions && canManageBilling,
        canManageTeamMembers,
        canManagePermissions,
        canManageBilling,
        canCreateNewMatters,
        canModifyTemplates,
        canAccessInformationTab,
        canAccessFilesRepository,
      });
    },
    skip: !clientUserId || !clientId,
  });

  const watchCanAccessAllMatters = watch('canAccessAllMatters');
  const watchCanManageTeamMembersPermissionsAndBilling = watch(
    'canManageTeamMembersPermissionsAndBilling',
  );

  useEffect(() => {
    setValue(
      'canManageTeamMembers',
      watchCanManageTeamMembersPermissionsAndBilling,
    );
    setValue(
      'canManagePermissions',
      watchCanManageTeamMembersPermissionsAndBilling,
    );
    setValue(
      'canManageBilling',
      watchCanManageTeamMembersPermissionsAndBilling,
    );
  }, [watchCanManageTeamMembersPermissionsAndBilling, setValue]);

  const allPermissionsChecked = !watch([
    'canManageTeamMembersPermissionsAndBilling',
    'canCreateNewMatters',
    'canModifyTemplates',
    'canAccessInformationTab',
    'canAccessFilesRepository',
  ]).some((value) => !value);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <StyledDialogHeader>
        <DialogTitle>
          {clientId ? 'Edit Permissions' : 'Set Permissions'}
        </DialogTitle>
        <DialogSubtitle>
          Set custom permissions for <StyledEmail>{email}</StyledEmail>
        </DialogSubtitle>
      </StyledDialogHeader>
      <DialogContent>
        <form id="set-permissions-form" onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" {...register('permissionId')} />
          <input type="hidden" {...register('canManageTeamMembers')} />
          <input type="hidden" {...register('canManagePermissions')} />
          <input type="hidden" {...register('canManageBilling')} />
          <FormContent>
            {showCategories && (
              <PermissionSection>
                <SectionLabelContainer>
                  <div>Allow access to:</div>
                </SectionLabelContainer>
                <AccessContainer>
                  <Controller
                    control={control}
                    name="canAccessAllMatters"
                    render={({ field: { value, onChange } }) => (
                      <RadioGroup
                        value={value ? 'allMatters' : 'specificMatters'}
                        onChange={(event) =>
                          onChange(event.target.value === 'allMatters')
                        }
                      >
                        <LabelledRadio
                          value="allMatters"
                          label="All Matters and Templates"
                        />
                        <LabelledRadio
                          value="specificMatters"
                          label="Specific Categories"
                        />
                      </RadioGroup>
                    )}
                  />
                  <CategoryList
                    hidden={!!watchCanAccessAllMatters}
                    register={register}
                  />
                </AccessContainer>
              </PermissionSection>
            )}
            <PermissionSection>
              <SectionLabelContainer>
                <div>Give permission to:</div>
                <SelectAllButton
                  onClick={(event) => {
                    event.preventDefault();
                    const value = !allPermissionsChecked;

                    setValue(
                      'canManageTeamMembersPermissionsAndBilling',
                      value,
                    );
                    setValue('canCreateNewMatters', value);
                    setValue('canModifyTemplates', value);
                    setValue('canAccessInformationTab', value);
                    setValue('canAccessFilesRepository', value);
                  }}
                >
                  {allPermissionsChecked ? 'Deselect All' : 'Select All'}
                </SelectAllButton>
              </SectionLabelContainer>
              <PermissionsContainer>
                <StyledCheckbox
                  label={
                    isRFB
                      ? 'Can manage team members, permissions, and billing'
                      : 'Can manage team members and permissions'
                  }
                  {...register('canManageTeamMembersPermissionsAndBilling')}
                />
                <StyledCheckbox
                  label="Create New Matters"
                  {...register('canCreateNewMatters')}
                />
                <StyledCheckbox
                  label="Modify Templates"
                  {...register('canModifyTemplates')}
                />
                <StyledCheckbox
                  label="Access Information tab"
                  {...register('canAccessInformationTab')}
                />
                <StyledCheckbox
                  label="Access Files repository"
                  {...register('canAccessFilesRepository')}
                />
              </PermissionsContainer>
            </PermissionSection>
          </FormContent>
        </form>
      </DialogContent>
      <DialogFooter>
        <FooterControl>
          <FooterButton pure onClick={onClose} paletteType="gray">
            Back
          </FooterButton>
          <FooterButton type="submit" form="set-permissions-form">
            {clientUserId ? 'Update' : 'Send Invite'}
          </FooterButton>
        </FooterControl>
      </DialogFooter>
    </Dialog>
  );
}

SetPermissionsModal.propTypes = {
  open: PropTypes.bool,
  email: PropTypes.string,
  clientUserId: PropTypes.string,
  clientId: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
