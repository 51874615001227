import { useEffect } from 'react';

import { trackCommandBar } from '../../services/Analytics';

export default () => {
  useEffect(() => {
    window.CommandBar.addEventHandler(trackCommandBar);
  }, []);

  return null;
};
