import { faGoogleDrive } from '@fortawesome/free-brands-svg-icons';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import useDrivePicker from 'react-google-drive-picker';
import { toast } from 'react-toastify';
import { handleException } from 'utils/ErrorUtils';

import FilePickerModal from '../../../contracts/FilePickerModal';
import { PopperMenuItem } from '../../../standard/PopperMenu';
import Spinner from '../../../standard/Spinner';

function SendToDriveAction({ folderId, accessToken, matterId, ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [openPicker, authResult] = useDrivePicker();

  useEffect(() => {
    if (authResult) {
      localStorage.setItem('gapiAccessToken', authResult.access_token);
    }
  }, [authResult]);

  const uploadFilesToDrive = async (selectedFolderId, files) => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem('gapiAccessToken');
      await axios.post(`/api/v2/googledrive/uploadFiles`, {
        token,
        selectedFiles: files,
        selectedFolderId,
      });

      toast.success('Files sent to Google Drive');
    } catch (err) {
      handleException(err);
      toast.error(
        'Oops! Something went wrong sending files to Google Drive. Please try again later.',
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenPicker = async (files) => {
    openPicker({
      clientId: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
      developerKey: process.env.REACT_APP_GOOGLE_API_KEY,
      viewId: 'FOLDERS',
      showUploadView: false,
      showUploadFolders: false,
      supportDrives: true,
      multiselect: false,
      setSelectFolderEnabled: true,
      customScopes: ['https://www.googleapis.com/auth/drive.file'],
      callbackFunction: async (data) => {
        if (data.action === 'picked') {
          const { id: selectedFolderId } = data?.docs?.[0];
          uploadFilesToDrive(selectedFolderId, files);
        }
      },
    });
  };

  return (
    <>
      <div {...props}>
        {isLoading && <Spinner />}
        <PopperMenuItem
          icon={faGoogleDrive}
          title="Send to Google Drive"
          onClick={() => setDisplayModal(true)}
        />
        <FilePickerModal
          token={accessToken}
          rootFolderId={folderId}
          display={displayModal}
          title="Select Files to Send to Google Drive"
          onClose={() => setDisplayModal(false)}
          onChoose={async (files) => {
            setIsLoading(true);
            handleOpenPicker(files);
            setDisplayModal(false);
            setIsLoading(false);
          }}
          chooseButtonLabel="Send to Google Drive"
          showHeader={false}
          portal={document.body}
        />
      </div>
    </>
  );
}

SendToDriveAction.propTypes = {
  folderId: PropTypes.string.isRequired,
  accessToken: PropTypes.string.isRequired,
  matterId: PropTypes.string.isRequired,
};

export default SendToDriveAction;
