import React, { forwardRef, useEffect, useRef, useState } from 'react';
import PlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-places-autocomplete';
import titleCase from 'title';
import { handleException } from 'utils/ErrorUtils';

import { withFormContext } from '../../context/FormContext';
import AutoSuggest from './AutoSuggest';
import { CANADA_PROVINCES, COUNTRIES, US_STATES } from './Constants';
import {
  ComboBox,
  FloatingTextField,
  StyledPlacesAutocomplete,
} from './FormStyles';

const Address = forwardRef(
  (
    {
      onChange,
      entityIdentifier,
      form,
      name,
      address: addressProp,
      onBlur,
      ...props
    },
    ref,
  ) => {
    const address = addressProp ?? {};
    const [shrinkLabel, setShrinkLabel] = useState({});
    const onBlurTimerIdRef = useRef();
    const handleChange = (event) => {
      if (entityIdentifier)
        form.entities.updateEntity(entityIdentifier, {
          address: event.target.value,
        });
      onChange(event);
    };

    const handleInputChange = (event) => {
      const { value, name: eventName } = event.target;

      const e = {
        target: {
          name,
          value: {
            ...address,
            [eventName]: value,
          },
        },
      };
      handleChange(e);
    };

    const handleSelectChange = (selectedOption) => {
      const e = {
        target: {
          name,
          value: {
            ...address,
            subnationalDivision1: selectedOption.value,
          },
        },
      };
      handleChange(e);
    };

    const delayedOnBlur = () => {
      const compoundEvent = {
        target: {
          name,
          value: address,
          type: 'Address',
        },
      };

      if (onBlur) {
        onBlur(compoundEvent);
      }
    };

    // We don't want to trigger "OnBlur" validation the millisecond one subfield is blurred,
    // as the user might just be clicking on the next subfield.
    const handleSubfieldOnBlur = (element) => {
      if (element) {
        setShrinkLabel({ ...shrinkLabel, [element]: false });
      }
      onBlurTimerIdRef.current = setTimeout(delayedOnBlur, 500);
    };

    const clearBlurTimeout = () => clearTimeout(onBlurTimerIdRef.current);

    useEffect(() => () => clearBlurTimeout(), []);

    const handleFocus = (element) => {
      setShrinkLabel({ ...shrinkLabel, [element]: true });
      clearBlurTimeout();
    };

    const getSuggestions = (input) => {
      const inputValue = input.trim().toLowerCase();
      const possibleValues = COUNTRIES.filter((country) => {
        const lowerCaseCountry = country.toLowerCase();
        return lowerCaseCountry.startsWith(inputValue);
      });
      return possibleValues.map((country) => ({
        label: country,
      }));
    };

    const generateLocalElements = () => {
      let key = '';
      let title = '';
      let codeType = '';
      let subnationalDivisionsSelectList = '';
      let subnationalDivision1Value = null;
      if (address.country === 'Canada') {
        subnationalDivisionsSelectList = CANADA_PROVINCES.map((e) => ({
          name: 'selectedState',
          value: e,
          label: e,
        }));
        key = 'canada';
        title = 'Province/territory';
        codeType = 'Postal code';
      } else if (address.country === 'United States of America') {
        subnationalDivisionsSelectList = US_STATES.map((e) => ({
          name: 'selectedState',
          value: e,
          label: e,
        }));
        key = 'usa';
        title = 'State';
        codeType = 'Zip code';
      }
      if (address.subnationalDivision1) {
        subnationalDivision1Value = {
          value: address.subnationalDivision1,
          label: address.subnationalDivision1,
        };
      }
      if (
        address.country === 'United States of America' ||
        address.country === 'Canada'
      ) {
        return {
          subnationalDivision1: (
            <ComboBox
              options={subnationalDivisionsSelectList}
              className="Select--multi"
              classNamePrefix="Select"
              placeholder={title}
              value={subnationalDivision1Value}
              onChange={handleSelectChange}
              onBlur={() => handleSubfieldOnBlur('subnationalDivision1')}
              onFocus={() => handleFocus('subnationalDivision1')}
              required={false}
              key={key}
              title={title}
            />
          ),
          codeType,
        };
      }
      codeType = 'Zip code';
      return {
        subnationalDivision1: (
          <FloatingTextField
            label="State"
            name="subnationalDivision1"
            id="subnationalDivision1"
            value={address.subnationalDivision1 || ''}
            type="text"
            onChange={handleInputChange}
            onBlur={() => handleSubfieldOnBlur('subnationalDivision1')}
            onFocus={() => handleFocus('subnationalDivision1')}
            required={false}
            InputLabelProps={{
              shrink: !!(address.subnationalDivision1 || shrinkLabel.state),
              classes: {
                root: 'rootLabel',
                focused: 'focusedLabel',
                shrink: 'shrinkLabel',
              },
            }}
          />
        ),
        codeType,
      };
    };

    const handleSelect = (_, placeId) => {
      geocodeByPlaceId(placeId)
        .then((results) => {
          const addressComponents = results[0].address_components;
          const formattedAddress = results[0].formatted_address;
          const newAddress = {
            line1: formattedAddress?.split(',')[0],
          };
          addressComponents.forEach((item) => {
            if (
              item.types.includes('postal_town') ||
              item.types.includes('locality') ||
              item.types.includes('sublocality')
            ) {
              newAddress.city = item.long_name;
            }
            if (item.types.includes('administrative_area_level_1'))
              newAddress.subnationalDivision1 = item.long_name;
            if (item.types.includes('country')) {
              newAddress.country =
                item.long_name === 'United States'
                  ? 'United States of America'
                  : item.long_name;
            }
            if (item.types.includes('postal_code'))
              newAddress.postalCode = item.long_name;
          });

          newAddress.line2 = '';
          const e = {
            target: {
              name,
              value: newAddress,
            },
          };
          handleChange(e);
        })
        .catch((error) => {
          handleException(error);
        });
    };

    const handleLine1Change = (googleAddress) => {
      const e = {
        target: {
          name,
          value: {
            ...address,
            line1: googleAddress,
          },
        },
      };
      handleChange(e);
    };

    const handleError = (error) => {
      if (error.includes('ZERO_RESULTS')) return;
      handleException(error);
    };

    let googlePlacesRestrictions = null;
    if (address.country === 'Canada')
      googlePlacesRestrictions = { country: ['ca'] };
    else if (address.country === 'United States of America')
      googlePlacesRestrictions = { country: ['us'] };

    return (
      <div {...props}>
        <div className="row">
          <div className="col-12">
            <PlacesAutocomplete
              value={address.line1 || ''}
              onChange={handleLine1Change}
              onError={handleError}
              onSelect={handleSelect}
              searchOptions={{
                types: ['address'],
                componentRestrictions: googlePlacesRestrictions,
              }}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <FloatingTextField
                    {...getInputProps({
                      label: 'Address line 1',
                      id: 'line1',
                      name: 'line1',
                      type: 'text',
                      onBlur: () => handleSubfieldOnBlur('line1'),
                      onFocus: () => handleFocus('line1'),
                      required: false,
                      className: 'location-search-input',
                      inputProps: {
                        autoCapitalize: 'words',
                      },
                    })}
                    ref={ref}
                    InputLabelProps={{
                      id: `${name}-line1`,
                      shrink: !!(address.line1 || shrinkLabel.line1),
                      classes: {
                        root: 'rootLabel',
                        focused: 'focusedLabel',
                        shrink: 'shrinkLabel',
                      },
                    }}
                  />
                  {shrinkLabel.line1 && (
                    <StyledPlacesAutocomplete
                      className="autocomplete-dropdown-container"
                      suggestions={suggestions}
                    >
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const style = suggestion.active
                          ? {
                              backgroundColor: '#fafafa',
                              cursor: 'pointer',
                              paddingTop: '5px',
                            }
                          : {
                              backgroundColor: '#ffffff',
                              cursor: 'pointer',
                              paddingTop: '5px',
                            };

                        return (
                          <div
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...getSuggestionItemProps(suggestion, {
                              style,
                            })}
                            key={suggestion.id || suggestion.placeId}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </StyledPlacesAutocomplete>
                  )}
                </div>
              )}
            </PlacesAutocomplete>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <FloatingTextField
              label="Address line 2"
              id="line2"
              name="line2"
              value={address.line2 || ''}
              type="text"
              onChange={handleInputChange}
              onBlur={() => handleSubfieldOnBlur('line2')}
              onFocus={() => handleFocus('line2')}
              required={false}
              inputProps={{
                autoCapitalize: 'words',
              }}
              InputLabelProps={{
                shrink: !!(address.line2 || shrinkLabel.line2),
                classes: {
                  root: 'rootLabel',
                  focused: 'focusedLabel',
                  shrink: 'shrinkLabel',
                },
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4">
            <FloatingTextField
              label="City"
              name="city"
              id="city"
              value={address.city || ''}
              type="text"
              onChange={handleInputChange}
              onBlur={() => handleSubfieldOnBlur('city')}
              onFocus={() => handleFocus('city')}
              required={false}
              inputProps={{
                autoCapitalize: 'words',
              }}
              InputLabelProps={{
                shrink: !!(address.city || shrinkLabel.city),
                classes: {
                  root: 'rootLabel',
                  focused: 'focusedLabel',
                  shrink: 'shrinkLabel',
                },
              }}
            />
          </div>
          <div className="col-sm-4">
            {generateLocalElements().subnationalDivision1}
          </div>
          <div className="col-sm-4">
            <FloatingTextField
              label={generateLocalElements().codeType}
              name="postalCode"
              id="postalCode"
              value={address.postalCode || ''}
              type="text"
              onChange={handleInputChange}
              onBlur={() => handleSubfieldOnBlur('postalCode')}
              onFocus={() => handleFocus('postalCode')}
              required={false}
              inputProps={{
                autoCapitalize: 'characters',
              }}
              InputLabelProps={{
                shrink: !!(address.postalCode || shrinkLabel.postalCode),
                classes: {
                  root: 'rootLabel',
                  focused: 'focusedLabel',
                  shrink: 'shrinkLabel',
                },
              }}
            />
          </div>
          <div className="col-12 mt-15">
            <AutoSuggest
              value={address.country || ''}
              placeholder="Country"
              onChange={(_event, { newValue }) => {
                const e = {
                  target: {
                    value: newValue,
                    name: 'country',
                  },
                };
                handleInputChange(e);
              }}
              getSuggestions={getSuggestions}
              onFocus={() => handleFocus('country')}
              onBlur={() => {
                const { country } = address;
                const e = {
                  target: {
                    value: country && titleCase(country),
                    name: 'country',
                  },
                };
                handleInputChange(e);
                handleSubfieldOnBlur('country');
              }}
              required={false}
              inputProps={{
                autoCapitalize: 'words',
              }}
            />
          </div>
        </div>
      </div>
    );
  },
);

export default withFormContext(Address);
