import styled from 'styled-components/macro';

import { ContentPreview } from '../../proxy-box';

const MatterFilePreview = styled(ContentPreview)`
  &&& {
    height: 810px;
    margin-bottom: 10px;
    flex: none;

    .bcs-is-selected::before {
      background-color: ${(props) => props.theme.palette.primary.main};
    }

    .bcs-is-selected {
      svg {
        path {
          fill: ${(props) => props.theme.palette.primary.main};
        }
      }
    }

    .bdl-SidebarToggleButton {
      &.bdl-is-collapsed,
      &.bdl-is-collapsed:hover {
        background-color: ${(props) => props.theme.palette.primary.main};
      }

      svg {
        vertical-align: initial;
      }
    }

    .item-properties {
      dt:nth-child(3),
      dd:nth-child(4) {
        display: none;
      }
    }
  }
`;

export default MatterFilePreview;
