import { useFeatureToggle } from '@flopflip/react-broadcast';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { useOrganizationPermission } from '../../context/OrganizationPermissionContext';
import { flagNames } from '../../featureFlags';
import { SCREEN_SIZES } from '../LayoutConstants';
import Button from '../standard/Button';
import ClipboardImage from '../standard/ClipboardImage';
import { PlusIcon } from '../standard/Icons';
import NoResultsFound from '../standard/NoResultsFound';

const NoTemplatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1.25rem;

  > * {
    margin-bottom: 15px;
  }
`;

const CreateTemplateButton = styled(Button)`
  @media ${SCREEN_SIZES.X_SMALL} {
    width: 100%;
  }
`;

const UseRallyTemplateButton = styled(Button).attrs({
  outline: true,
})`
  width: 250px;
  @media ${SCREEN_SIZES.X_SMALL} {
    width: 100%;
  }
`;

const TextContainer = styled.h5`
  text-align: center;
`;

export function NoTemplates({ searchQuery, addTemplateHandler }) {
  const firmId = localStorage.getItem('firmId');

  const history = useHistory();
  const isRFB = useFeatureToggle(flagNames.RFB);

  const { canModifyTemplates } = useOrganizationPermission();

  if (searchQuery) {
    return <NoResultsFound searchQuery={searchQuery} />;
  }
  return (
    <NoTemplatesContainer>
      <ClipboardImage />
      <TextContainer>
        {canModifyTemplates
          ? `You haven't added any ${isRFB ? 'custom templates' : 'templates'}.`
          : "You don't have access to any templates."}
      </TextContainer>
      {!!addTemplateHandler && !!canModifyTemplates && (
        <CreateTemplateButton onClick={addTemplateHandler}>
          <PlusIcon />
          Create Template
        </CreateTemplateButton>
      )}
      {isRFB && firmId && (
        <UseRallyTemplateButton
          onClick={() => history.push(`/firm/${firmId}/servicemenu/`)}
        >
          Use Rally Template
        </UseRallyTemplateButton>
      )}
    </NoTemplatesContainer>
  );
}
