import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled, { withTheme } from 'styled-components/macro';

import { SIDEBAR_ALWAYS_OPEN_WIDTH, SIDEBAR_WIDTH } from '../LayoutConstants';

const StyledPullTab = styled.div`
  display: none;
  @media (max-width: ${SIDEBAR_ALWAYS_OPEN_WIDTH}) {
    display: block;
  }
  background-color: #f9fafb;
  border: 1px solid rgb(235, 235, 235);
  border-left: 1px solid #f9fafb;
  position: fixed;
  height: 50px;
  width: 30px;
  left: ${(props) => (props.showBar ? SIDEBAR_WIDTH : '0')};
  top: calc(50% - 25px);
  z-index: 1;
  border-radius: 0px 20px 20px 0px;
  transition: left 0.3s ease;
  padding-left: 8px;
  padding-top: 13px;
  color: ${(props) => props.hasError && props.theme.palette.destructive.main};
  cursor: pointer;
`;

const PullTab = ({ showBar, controls, onClick }) => (
  <StyledPullTab
    role="button"
    tabindex={-1}
    aria-expanded={showBar}
    aria-controls={controls}
    onClick={onClick}
    showBar={showBar}
    data-testid="pull-tab"
  >
    <FontAwesomeIcon icon={showBar ? faChevronLeft : faChevronRight} />
  </StyledPullTab>
);

export default withTheme(PullTab);
