import Cookie from 'js-cookie';
import { useLocation } from 'react-router-dom';

const LawPayRedirect = () => {
  const { search } = useLocation();
  const redirect = Cookie.get('LAWPAY_REDIRECT');
  window.location.replace(redirect + search);
  return null;
};

export default LawPayRedirect;
