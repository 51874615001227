import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { handleException } from 'utils/ErrorUtils';

export default function Form({
  id,
  onIsDirty = (f) => f,
  onSubmit = (f) => f,
  submitSuccessMessage = 'Submitted Successfully',
  submitInvalidMessage = 'There were invalid or incomplete fields in your submission. Please correct them and submit again.',
  submitErrorMessage = 'Something went wrong, please try again later',
  mode,
  reValidateMode,
  resolver,
  context,
  defaultValues,
  shouldFocusError,
  shouldUnregister,
  criteriaMode,
  children,
  ...props
}) {
  const options = Object.fromEntries(
    Object.entries({
      mode,
      reValidateMode,
      resolver,
      context,
      defaultValues,
      shouldFocusError,
      shouldUnregister,
      criteriaMode,
    }).filter(([, v]) => v != null),
  );
  const methods = useForm(options);
  const {
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitSuccessful },
  } = methods;

  useEffect(() => onIsDirty(isDirty), [onIsDirty, isDirty]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(defaultValues);
    }
  }, [reset, defaultValues, isSubmitSuccessful]);

  const onSubmitHandler = useCallback(
    async (event) => {
      const onInvalid = () => {
        toast.error(submitInvalidMessage);
      };

      const submitHandler = handleSubmit(onSubmit, onInvalid);

      try {
        await submitHandler(event);
        toast.success(submitSuccessMessage);
      } catch (error) {
        toast.error(submitErrorMessage);
        handleException(error);
      }
    },
    [
      handleSubmit,
      onSubmit,
      submitSuccessMessage,
      submitInvalidMessage,
      submitErrorMessage,
    ],
  );

  return (
    <FormProvider {...methods}>
      <form id={id} onSubmit={onSubmitHandler} {...props}>
        {children}
      </form>
    </FormProvider>
  );
}
