import styled from 'styled-components/macro';

import Card from '../../standard/Card';

const MatterCard = styled(Card)`
  min-width: unset;
  height: fit-content;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.09);
  padding: 0;
  border-radius: 10px;
`;

export default MatterCard;
