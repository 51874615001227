import React, { forwardRef } from 'react';
import styled from 'styled-components/macro';

import { ComboBox } from './FormStyles';

const VOWELS = ['a', 'e', 'i', 'o', 'u'];

const DropdownContainer = styled.div`
  max-width: 100%;
  min-width: 50%;
  width: ${({ inheritWidth, contentLength }) =>
    inheritWidth
      ? 'inherit'
      : `${
          contentLength * 14 + 68
        }px`}; // @TODO: replace with constants from a styling library
`;

const DropdownBox = styled(ComboBox)`
  width: 100%;
  .Select__placeholder {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .Select__value-container {
    width: auto;
  }
  .Select__indicator-separator {
    display: none;
  }
  .Select__menu {
    z-index: 1100;
  }
  &&& {
    .Select__control:hover {
      cursor: pointer;
    }
  }
`;

const getTitle = (noun) => {
  if (noun) {
    const lowerNoun = noun.toLowerCase();
    return VOWELS.includes(lowerNoun.charAt(0))
      ? `Select an ${lowerNoun}`
      : `Select a ${lowerNoun}`;
  }

  return 'Select an option';
};

const Dropdown = forwardRef(
  (
    {
      id,
      name,
      noun,
      options,
      mappedOptions,
      value,
      className = '',
      onChange,
      inheritWidth,
      onBlur = (f) => f,
      ...props
    },
    ref,
  ) => {
    const title = getTitle(noun);
    return (
      <DropdownContainer
        className={className}
        contentLength={title.length}
        inheritWidth={inheritWidth}
      >
        <DropdownBox
          id={id || name}
          ref={ref}
          className={`Select--multi ${className}`}
          options={
            mappedOptions || options.map((o) => ({ label: o, value: o }))
          }
          classNamePrefix="Select"
          isSearchable
          menuPlacement="auto"
          placeholder={value || title}
          value={value}
          onChange={({ value: newValue }) => {
            onChange({ target: { name, value: newValue } });
            onBlur({ target: { name, value: newValue } });
          }}
          onBlur={() => {
            if (!onBlur || typeof onBlur !== 'function') {
              return;
            }

            onBlur({ target: { name, value } });
          }}
          blurInputOnSelect={false}
          {...props}
        />
      </DropdownContainer>
    );
  },
);

export default Dropdown;
