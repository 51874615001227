import React from 'react';
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components/macro';

import { useFirmSettings } from '../../../context/UserContext';
import { userIsClient } from '../../../services/Utilities';
import { NavList, NavListItem } from '../../standard/NavList';
import Spinner from '../../standard/Spinner';
import DataFields from './pages/DataFields';
import EntityInformation from './pages/EntityInformation';

const DataDashboardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`;

function DataNavList({ url, showCustomFields, showEntityInformation }) {
  return (
    <NavList collapseOnNav>
      {showEntityInformation && (
        <NavListItem as={NavLink} to={`${url}/information`}>
          Entity Information
        </NavListItem>
      )}
      {showCustomFields && (
        <NavListItem as={NavLink} to={`${url}/customfields`}>
          Custom Fields
        </NavListItem>
      )}
    </NavList>
  );
}

export default function DataDashboard() {
  const isClient = userIsClient();
  const { url, path } = useRouteMatch();
  const [{ showClientCustomFields, showEntityInformation }, { loading }] =
    useFirmSettings();
  const showCustomFields = !isClient || showClientCustomFields;

  if (loading) {
    return <Spinner />;
  }

  let fallbackRedirect;
  if (showEntityInformation) {
    fallbackRedirect = `${url}/information`;
  } else if (showCustomFields) {
    fallbackRedirect = `${url}/customfields`;
  } else {
    fallbackRedirect = '/client/home';
  }

  return (
    <DataDashboardContainer marginTop={isClient ? '11px' : '0px'}>
      <DataNavList
        url={url}
        showEntityInformation={showEntityInformation}
        showCustomFields={showCustomFields}
      />
      <Switch>
        <Route path={`${path}`}>
          <Switch>
            {showEntityInformation && (
              <Route path={`${path}/information`}>
                <EntityInformation />
              </Route>
            )}
            {showCustomFields && (
              <Route path={`${path}/customfields`}>
                <DataFields />
              </Route>
            )}
            <Redirect to={fallbackRedirect} />
          </Switch>
        </Route>
      </Switch>
    </DataDashboardContainer>
  );
}
