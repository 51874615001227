import styled from 'styled-components/macro';

export const MenuOfServicesContainer = styled.div`
  margin-bottom: 10em;
`;

export const ErrorMessageContainer = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme.palette.destructive.main};
`;

export default {
  MenuOfServicesContainer,
  ErrorMessageContainer,
};
