import axios from 'axios';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { handleException } from 'utils/ErrorUtils';

import Spinner from '../../../components/standard/Spinner';
import { trackMatterInitialized } from '../../../services/Analytics';

const ServiceRequestScreen = ({ match }) => {
  const {
    params: { firmId, serviceId },
  } = match;
  const history = useHistory();
  const { search } = useLocation();

  useEffect(() => {
    const createNewQuestionnaire = async () => {
      try {
        const { firmUserId } = queryString.parse(search);
        const reqBody = {
          serviceId,
          firmId,
          firmUserId,
        };

        if (firmId && serviceId) {
          const response = await axios.post(
            '/api/v1/new-client-service-request',
            reqBody,
          );
          const { contractId, templateId, templateName } = response.data;

          trackMatterInitialized({
            type: 'templated contract',
            source: 'client service request',
            matterId: contractId,
            templateName,
            templateId,
          });

          history.replace(`/client/matter/${contractId}`);
        }
      } catch (error) {
        handleException(error);
      }
    };

    createNewQuestionnaire();
  }, [firmId, search, serviceId, history]);

  return <Spinner />;
};

export default ServiceRequestScreen;
