import { useFeatureToggle } from '@flopflip/react-broadcast';
import React from 'react';
import styled from 'styled-components/macro';

import { flagNames } from '../../featureFlags';
import Button from '../standard/Button';
import SummaryCard from './SummaryCard';

const SkipPaymentButton = styled(Button)`
  font-size: 16px;
  line-height: 22px;
  padding: 5px 0px;
  @media (min-width: 991px) {
    margin-left: auto;
  }
`;

const SummaryCardContainer = styled.div`
  margin-bottom: 50px;
`;

const SummarySection = ({
  service,
  canSkipPayment,
  skipPaymentClickHandler = (f) => f,
  ...props
}) => {
  const allowSkipPayment = useFeatureToggle(flagNames.ALLOW_SKIP_PAYMENT);
  return (
    <div {...props}>
      <div className="row">
        <div className="col d-flex justify-content-center">
          <SummaryCardContainer className="d-flex flex-column">
            <SummaryCard service={service} />
            {allowSkipPayment && canSkipPayment && (
              <div>
                <SkipPaymentButton
                  pure
                  paletteType="gray"
                  onClick={skipPaymentClickHandler}
                >
                  Skip payment, I have an alternative arrangement
                </SkipPaymentButton>
              </div>
            )}
          </SummaryCardContainer>
        </div>
      </div>
    </div>
  );
};

export default SummarySection;
