import { useFeatureToggle } from '@flopflip/react-broadcast';
import React, { Suspense, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import styled, { ThemeContext } from 'styled-components/macro';

import { useOrganizationPermission } from '../context/OrganizationPermissionContext';
import { flagNames } from '../featureFlags';
import {
  getFirmName,
  getUserEmail,
  isE2ETest,
  userIsClient,
  userIsFirmAdmin,
  userIsRallyAdmin,
} from '../services/Utilities';
import AdminDashboard from './admin/AdminDashboard';
import { ClientProfile } from './admin/ClientProfile';
import { ClientSettings } from './admin/ClientSettings';
import FirmSettings from './admin/FirmSettings';
import { MatterCategoryEditor } from './admin/matter-categories/MatterCategoryEditor';
import DataDashboard from './client/data/DataDashboard';
import ThankYou from './client/ThankYou';
import CommandBar from './CommandBar';
import ClientMatterViewport from './contracts/ClientMatterViewport';
import ContractRepository from './contracts/ContractRepository';
import Contracts from './contracts/Contracts';
import HelloSignOAuth from './hellosign/HelloSignOAuth';
import HubspotOAuth from './hubspot/HubspotOAuth';
import LawPayOAuth from './lawpay/LawPayOAuth';
import NewMatterViewScreen from './matter/view/NewMatterViewScreen';
import SalesforceOAuth from './salesforce/SalesforceOAuth';
import Spinner from './standard/Spinner';
import { SubscriptionSuccess } from './subscription/SubscriptionSuccess';

const AddTemplate = React.lazy(() =>
  import('./templates/AddTemplate' /* webpackChunkName: "addtemplate" */),
);
const AddClientTemplate = React.lazy(() =>
  import(
    './client/AddClientTemplate' /* webpackChunkName: "addclienttemplate" */
  ),
);
const ClientFileRepo = React.lazy(() =>
  import('./filerepo/ClientFileRepo' /* webpackChunkName: "clientfiles" */),
);
const ClientHome = React.lazy(() =>
  import('./client/ClientHome' /* webpackChunkName: "clienthome" */),
);

const ClientMatters = React.lazy(() =>
  import('./client/ClientMatters' /* webpackChunkName: "clientmatters" */),
);
const ClientContacts = React.lazy(() =>
  import('./client/ClientContacts' /* webpackChunkName: "clientcontacts" */),
);
const Contact = React.lazy(() =>
  import('./client/contacts/Contact' /* webpackChunkName: "contact" */),
);
const ClientTemplates = React.lazy(() =>
  import('./client/ClientTemplates' /* webpackChunkName: "clienttemplates" */),
);
const ClientList = React.lazy(() =>
  import('./client/ClientList' /* webpackChunkName: "clientlist" */),
);
const EditTemplate = React.lazy(() =>
  import('./templates/EditTemplate' /* webpackChunkName: "edittemplate" */),
);
const FirmClientTemplates = React.lazy(() =>
  import(
    './client/FirmClientTemplates' /* webpackChunkName: "firmclienttemplates" */
  ),
);
const FirmClientFileRepo = React.lazy(() =>
  import(
    './filerepo/FirmClientFileRepo' /* webpackChunkName: "firmclientfiles" */
  ),
);
const FirmClientPortal = React.lazy(() =>
  import('./client/FirmClientPortal' /* webpackChunkName: "firmclientp" */),
);
const NewTemplatedContract = React.lazy(() =>
  import(
    './contracts/NewTemplatedContract' /* webpackChunkName: "newcontract" */
  ),
);
const TemplateSelector = React.lazy(() =>
  import('./contracts/TemplateSelector' /* webpackChunkName: "tempselector" */),
);
const FirmTemplates = React.lazy(() =>
  import('./templates/FirmTemplates' /* webpackChunkName: "firmtemplates" */),
);

const SalesforceMatter = React.lazy(() =>
  import(
    './salesforce/SalesforceMatter' /* webpackChunkName: "salesforcematter" */
  ),
);

const Main = styled.main`
  margin: 0 10%;
  @media (max-width: 576px) {
    margin: 0 7%;
  }
`;

const ClientViewport = () => {
  const location = useLocation();

  const { clientCustomCode, clientIntercomId } = useContext(ThemeContext);

  const { canAccessInformationTab, canAccessFilesRepository } =
    useOrganizationPermission();

  const salesforceConnectionEnabled = useFeatureToggle(
    flagNames.SALESFORCE_CONNECTION,
  );
  const isClientProfileEnabled = salesforceConnectionEnabled;

  return (
    <>
      <Helmet>{clientCustomCode}</Helmet>
      <CommandBar />
      <IntercomProvider
        appId={clientIntercomId}
        shouldInitialize={!!clientIntercomId && clientIntercomId !== 'none'}
        autoBootProps={{
          email: getUserEmail(),
          customAttributes: { firm: getFirmName(), user_type: 'client' },
          alignment: 'right',
        }}
        autoBoot
      >
        <Switch>
          <Route exact path="/client/home" component={ClientHome} />
          <Route exact path="/client/thankyou" component={ThankYou} />
          <Route exact path="/client/matters" component={ClientMatters} />
          <Route
            exact
            path="/client/subscription/success"
            component={SubscriptionSuccess}
          />
          <Route
            path="/client/matter/:matterId"
            component={ClientMatterViewport}
          />
          <Redirect from="/matters/:matterId" to="/client/matters/:matterId" />
          <Route
            exact
            path="/client/matters/:matterId"
            component={NewMatterViewScreen}
          />
          {!isE2ETest() && !!canAccessFilesRepository && (
            <Route
              path="/client/files/:folderId?/:fileId?"
              component={ClientFileRepo}
            />
          )}
          <Route exact path="/oauth/hellosign" component={HelloSignOAuth} />
          <Route path="/oauth/hubspot" component={HubspotOAuth} />
          <Route path="/oauth/salesforce" component={SalesforceOAuth} />
          {!!canAccessInformationTab && (
            <Route path="/client/entity" component={DataDashboard} />
          )}
          {!!isClientProfileEnabled && (
            <Route path="/client/profile" component={ClientProfile} />
          )}
          <Route path="/client/settings" component={ClientSettings} />
          <Route exact path="/client/contacts" component={ClientContacts} />
          <Route exact path="/client/contacts/:contactId" component={Contact} />
          <Route exact path="/client/templates" component={ClientTemplates} />
          <Route path="/client/templates/new" component={AddClientTemplate} />
          <Route path="/client/templates/:id">
            <EditTemplate templateGroupType="client" />
          </Route>
          <Route
            exact
            path="/client/salesforce-matter/:recordId"
            component={SalesforceMatter}
          />
          <Redirect
            exact
            from="/client/questionnaire/:id"
            to={{ ...location, pathname: `/client/matter/:id/questionnaire` }}
          />
          <Redirect
            exact
            from="/client/questionnaire/:id/payment"
            to={{ ...location, pathname: `/client/matter/:id/payment` }}
          />
          <Redirect to="/client/home" />
        </Switch>
      </IntercomProvider>
    </>
  );
};

const FirmViewport = () => {
  const isFirmAdmin = userIsFirmAdmin();
  const isRallyAdmin = userIsRallyAdmin();

  const intercomId = process.env.REACT_APP_INTERCOM_ID;
  const beamerId = isE2ETest() ? null : process.env.REACT_APP_BEAMER_ID;
  const profitWellToken = process.env.REACT_APP_PROFITWELL_TOKEN;

  return (
    <>
      <Helmet>
        {beamerId && (
          <script>
            {`var beamer_config = {
                  product_id : '${beamerId}',
                  selector: 'beamerTrigger',
                  top: -10,
                  left: 10
                };`}
          </script>
        )}
        {beamerId && (
          <script
            type="text/javascript"
            src="https://app.getbeamer.com/js/beamer-embed.js"
            defer="defer"
          />
        )}
        {profitWellToken && (
          <script id="profitwell-js" data-pw-auth={profitWellToken}>
            {`(function(i,s,o,g,r,a,m){
                i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
                a=s.createElement(g);
                m=s.getElementsByTagName(g)[0];
                a.async=1;
                a.src=r+'?auth='+ s.getElementById(o+'-js').getAttribute('data-pw-auth');
                m.parentNode.insertBefore(a,m);
              })(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');
              profitwell('start', { 'user_email': '${getUserEmail()}' });`}
          </script>
        )}
      </Helmet>
      <CommandBar />
      <IntercomProvider
        appId={intercomId}
        shouldInitialize={!!intercomId && intercomId !== 'none'}
        autoBootProps={{
          email: getUserEmail(),
          customLauncherSelector: '.intercom-launcher',
          customAttributes: { firm: getFirmName(), user_type: 'firm' },
          alignment: 'right',
        }}
        autoBoot
      >
        <Switch>
          <Route exact path="/new-contract" component={TemplateSelector} />
          <Route path="/new-contract/:id" component={NewTemplatedContract} />
          <Route exact path="/contracts" component={ContractRepository} />
          <Route path="/contracts/:matterId" component={Contracts} />
          <Route
            path="/clients/:clientId/contracts/:matterId"
            component={Contracts}
          />
          {isFirmAdmin && <Route path="/settings" component={FirmSettings} />}
          <Route exact path="/clients/:clientId" component={FirmClientPortal} />
          <Route
            exact
            path="/clients/:clientId/matters/:matterId"
            component={NewMatterViewScreen}
          />
          <Route
            exact
            path="/clients/:clientId/matters"
            component={ClientMatters}
          />
          <Route
            exact
            path="/clients/:clientId/contacts"
            component={ClientContacts}
          />
          <Route
            exact
            path="/clients/:clientId/contacts/:contactId"
            component={Contact}
          />
          <Route
            exact
            path="/clients/:clientId/templates"
            component={FirmClientTemplates}
          />
          <Route
            path="/clients/:clientId/templates/new"
            component={AddClientTemplate}
          />
          <Route path="/clients/:clientId/templates/:id">
            <EditTemplate templateGroupType="client" />
          </Route>
          <Route path="/clients/:clientId/settings">
            <ClientSettings />
          </Route>
          {!isE2ETest() && (
            <Route
              path="/clients/:clientId/files/:folderId?/:fileId?"
              component={FirmClientFileRepo}
            />
          )}
          <Route path="/clients/:clientId/entity" component={DataDashboard} />
          <Route exact path="/clients" component={ClientList} />
          <Route exact path="/templates" component={FirmTemplates} />
          {isFirmAdmin && (
            <Route path="/templates/new" component={AddTemplate} />
          )}
          <Route path="/templates/:id">
            <EditTemplate templateGroupType="firm" />
          </Route>
          {isRallyAdmin && (
            <Route exact path="/admin" component={AdminDashboard} />
          )}
          {isRallyAdmin && (
            <Route
              exact
              path="/admin/edit-categories"
              component={MatterCategoryEditor}
            />
          )}
          {isFirmAdmin && (
            <Route path="/oauth/lawpay" component={LawPayOAuth} />
          )}
          <Route path="/oauth/hellosign" component={HelloSignOAuth} />
          <Route path="/oauth/hubspot" component={HubspotOAuth} />
          <Redirect to="/new-contract" />
        </Switch>
      </IntercomProvider>
    </>
  );
};

// If user reaches this component - they are logged in. Redirect paths like /login, /register
// etc. to main viewport screen (new contract)
const Viewport = () => (
  <Main>
    <Suspense fallback={<Spinner />}>
      {userIsClient() ? <ClientViewport /> : <FirmViewport />}
    </Suspense>
  </Main>
);

export default Viewport;
