import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

import { AddMatterFieldButton } from './AddMatterFieldButton';

const EmptyMatterFieldContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
`;

export function EmptyMatterFields({ setDisplayAddMatterFieldModal, ...props }) {
  return (
    <EmptyMatterFieldContainer {...props}>
      <div>No Matter Fields have been created.</div>
      <AddMatterFieldButton
        setDisplayAddMatterFieldModal={setDisplayAddMatterFieldModal}
      />
    </EmptyMatterFieldContainer>
  );
}

EmptyMatterFields.propTypes = {
  setDisplayAddMatterFieldModal: PropTypes.func.isRequired,
};
