import React from 'react';

import noResults from '../../img/no-results.png';

export default function NoResultsFound(props) {
  const { searchQuery } = props;
  return (
    <div className="offset-xl-3 col-xl-6 mt-30">
      <div className="card-body align-items-center text-center">
        <img src={noResults} alt="No Results" height="300" />
        <h5 className="mb-15">
          {`Sorry, we couldn't find any results ${
            searchQuery ? `matching '${searchQuery}'.` : ''
          }`}
        </h5>
      </div>
    </div>
  );
}
