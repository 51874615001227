import { Object } from 'core-js';
import React, { Component } from 'react';

import { Input } from './FormStyles';

export default class MultiSelect extends Component {
  constructor(props) {
    super(props);
    const { options, selectedOptions } = this.props;
    if (Object.keys(selectedOptions).length === 0) {
      Object.keys(options).forEach((option) => {
        selectedOptions[option] = false;
      });
    }
    this.state = {
      selectedOptions,
    };
  }

  handleMultiSelectChange = (e) => {
    const { name, onChange, onBlur } = this.props;
    const { selectedOptions } = this.state;

    const { target } = e;
    const value = target.checked;
    const key = target.name;
    if (value) {
      selectedOptions[key] = true;
    } else {
      selectedOptions[key] = false;
    }
    this.setState({ selectedOptions });
    const newEvent = {
      target: {
        name,
        value: selectedOptions,
      },
    };

    onChange(newEvent);
    if (onBlur) onBlur(newEvent);
  };

  render() {
    const { options } = this.props;
    const { selectedOptions } = this.state;

    const generatedOptions = Object.keys(options).map((key) => (
      <div key={key}>
        <label className="custom-control custom-checkbox custom-control-lg mt-15">
          <Input
            id={key}
            type="checkbox"
            className="custom-control-input"
            name={key}
            checked={selectedOptions[key]}
            onChange={this.handleMultiSelectChange}
            autoComplete="no"
          />
          <span
            className="custom-control-indicator position-absolute"
            style={{ top: '0' }}
          />
          <span
            className="custom-control-description pl-30"
            style={{ fontSize: '18px', fontWeight: '400' }}
          >
            {options[key]}
          </span>
        </label>
      </div>
    ));
    return generatedOptions;
  }
}
