import { useFeatureToggle } from '@flopflip/react-broadcast';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { handleException } from 'utils/ErrorUtils';

import { flagNames } from '../featureFlags';
import { registerAnalyticsProperties } from '../services/Analytics';
import AuthUtility from '../services/AuthUtility';
import { identifyUserpilot } from '../services/Userpilot';
import FirmClientHeader from './client/FirmClientHeader';
import ClientCommandBarContext from './commandbar/ClientCommandBarContext';
import HubspotCreateMatter from './hubspot/HubspotCreateMatter';
import TopBar from './TopBar';
import Viewport from './Viewport';
import WopiClose from './wopi/WopiClose';
import WopiHost from './wopi/WopiHost';

const authUtility = new AuthUtility();

const TWO_SECONDS_IN_MS = 2000;

const objectIdToDate = (objectId) =>
  new Date(parseInt(objectId.substring(0, 8), 16) * 1000);

const WorkspaceTopBar = styled(TopBar)`
  z-index: ${(props) => (props.isEditing ? 995 : 1)};
`;

const Workspace = () => {
  const [userId] = useState(localStorage.getItem('userId'));
  const firmId = localStorage.getItem('firmId');
  const firmName = localStorage.getItem('firmName');
  const userType = localStorage.getItem('userType');
  const email = localStorage.getItem('email');
  const accessToken = localStorage.getItem('access_token');

  const accessMatterWorkspace = useFeatureToggle(
    flagNames.ACCESS_MATTER_WORKSPACE,
  );

  const location = useLocation();
  // Check if editing the questionnaire as Firm or Client
  const editingQN = ['edit', 'new-contract', 'questionnaire'];
  const isEditing = editingQN.some((word) =>
    location?.pathname?.includes(word),
  );

  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

  useEffect(() => {
    const ensureValidToken = async (clientUserId) => {
      if (AuthUtility.shouldRefreshToken()) {
        try {
          await authUtility.refreshSession();
        } catch (error) {
          if (error.code !== 'login_required' && error.code !== 'timeout') {
            handleException(error);
          }
        }
      }
      if (!AuthUtility.isAuthenticated()) {
        AuthUtility.logout();
      }
      // Refreshing Auth0 tokens changes the token/user across all subdomains.
      // This can have unintended consequences since other user state in local
      // storage is not refreshed.
      // Just log users out if the user gets switched to avoid these consequences.
      const newUserId = localStorage.getItem('userId');
      if (newUserId !== clientUserId) {
        AuthUtility.logout();
      }
    };

    // Periodically check to ensure the user is logged in.
    ensureValidToken(userId);
    const intervalId = setInterval(
      () => ensureValidToken(userId),
      TWO_SECONDS_IN_MS,
    );
    return () => clearInterval(intervalId);
  }, [userId]);

  useEffect(() => {
    Sentry.configureScope((scope) => {
      scope.setUser({ id: userId, email });
      scope.setTag('firm', firmId);
      scope.setTag('userType', userType);
    });
  }, [userId, email, firmId, userType]);

  useEffect(() => {
    if (userId && firmId && firmName) {
      const { mongoId } =
        userId.match(/auth0\|(?<mongoId>[a-f0-9]{24})/)?.groups || {};
      if (!mongoId) {
        // Likely a test environment. Skip userpilot logging.
        return;
      }
      const createdAt = objectIdToDate(mongoId);
      const firmCreatedAt = objectIdToDate(firmId);

      identifyUserpilot(userId, {
        email,
        userType,
        company: {
          id: firmId,
          name: firmName,
          created_at: firmCreatedAt.toISOString(),
        },
        entitlement_AccessMatterWorkspace: accessMatterWorkspace,
        created_at: createdAt.toISOString(),
      });
    }
  }, [accessMatterWorkspace, userId, email, userType, firmId, firmName]);

  useEffect(() => {
    registerAnalyticsProperties({
      'User Subscribed': accessMatterWorkspace,
    });
  }, [accessMatterWorkspace]);

  // Ensure the user is logged in before rendering anything.
  if (!AuthUtility.isAuthenticated()) {
    return null;
  }

  return (
    <Switch>
      <Route
        path="/wopi/:application/:action/:extension/:fileId"
        component={WopiHost}
      />
      <Route path="/wopi/close" component={WopiClose} />
      <Route path="/hubspot/create-matter" component={HubspotCreateMatter} />
      <Route>
        <WorkspaceTopBar isEditing={isEditing} />
        <Route path="/clients/:clientId" component={FirmClientHeader} />
        <Route path="/clients/:clientId" component={ClientCommandBarContext} />
        <Viewport />
      </Route>
    </Switch>
  );
};

export default Workspace;
