import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DocumentEditorContainerComponent,
  Editor,
  Selection,
  Toolbar,
  WordExport,
} from '@syncfusion/ej2-react-documenteditor';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';
import { handleException } from 'utils/ErrorUtils';

import { wordProcessorServerHost } from '../../../services/Config';
import { getDocxBase64 } from '../../../utils/SyncfusionUtils';
import { NeutralButton } from '../../standard/Button';
import Spinner from '../../standard/Spinner';
import SyncfusionStyleOverrides from '../../syncfusion/SyncfusionStyleOverrides';
import { interceptCommandBarShortcut } from '../../syncfusion/SyncfusionUtils';

DocumentEditorContainerComponent.Inject(Toolbar, Editor, Selection, WordExport);

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
`;

const EditorContainer = styled.div`
  margin-bottom: 80px;
`;

const MatterDocumentEditor = ({ currentFile: { id: fileId }, setEditMode }) => {
  const serviceUrl = `${wordProcessorServerHost}/api/documenteditor/`;
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [sfdt, setSfdt] = useState(null);
  const [documentEditor, setDocumentEditor] = useState();

  useEffect(() => {
    async function getSfdt() {
      if (!fileId) {
        return;
      }
      try {
        const { data } = await axios.get(`/api/v2/syncfusion/${fileId}`);
        setSfdt(data);
      } catch (error) {
        handleException(error);
        toast.error(
          'There was an error opening this document. Please try again later.',
        );
        setEditMode(false);
      } finally {
        setIsLoading(false);
      }
    }
    getSfdt();
  }, [setEditMode, fileId]);

  useEffect(() => {
    if (sfdt && documentEditor) {
      try {
        documentEditor.open(sfdt);
      } catch (error) {
        handleException(error);
        toast.error(
          'There was an error opening this document. Please try again later.',
        );
        setEditMode(false);
      }
    }
  }, [sfdt, documentEditor, setEditMode]);

  useEffect(() => {
    if (documentEditor) {
      interceptCommandBarShortcut(documentEditor);
    }
  }, [documentEditor]);

  const saveDocument = useCallback(async () => {
    try {
      setIsSaving(true);
      const docx = await getDocxBase64(documentEditor);
      await axios.put(`/api/v2/document/${fileId}`, { docx });
      toast.success('The document was successfully saved.');
    } catch (error) {
      handleException(error);
      toast.error(
        'There was an error updating this document. Please try again later.',
      );
    } finally {
      setIsSaving(false);
    }
    return true;
  }, [fileId, documentEditor]);

  const cancelEdit = useCallback(() => {
    setEditMode(false);
  }, [setEditMode]);

  return (
    <div className="col-12 col-xl-8 mb-30">
      {isLoading && <Spinner />}
      {!isLoading && (
        <>
          <ButtonContainer>
            <NeutralButton onClick={cancelEdit}>Cancel</NeutralButton>
            <NeutralButton
              outline
              icon={isSaving && <FontAwesomeIcon icon={faSpinner} pulse />}
              onClick={async () => {
                const saveSuccessful = await saveDocument();
                if (saveSuccessful) {
                  setEditMode(false);
                }
              }}
            >
              Save document
            </NeutralButton>
          </ButtonContainer>
          <SyncfusionStyleOverrides />
          <EditorContainer>
            <DocumentEditorContainerComponent
              id="container"
              serviceUrl={serviceUrl}
              enableToolbar
              enableSelection
              enableEditor
              enableWordExport
              enableTextExport
              height="calc(95vh - 64px)"
              ref={(ref) => {
                setDocumentEditor(ref?.documentEditor);
              }}
              toolbarItems={[
                'Separator',
                'Undo',
                'Redo',
                'Separator',
                'Image',
                'Table',
                'Hyperlink',
                'Bookmark',
                'TableOfContents',
                'Separator',
                'Header',
                'Footer',
                'PageSetup',
                'PageNumber',
                'Break',
                'InsertFootnote',
                'InsertEndnote',
                'Separator',
                'Find',
                'Separator',
                'Comments',
                'TrackChanges',
                'LocalClipboard',
                'RestrictEditing',
                'Separator',
              ]}
            />
          </EditorContainer>
        </>
      )}
    </div>
  );
};

export default MatterDocumentEditor;
