import { gql, useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';

import { useUser } from '../../context/UserContext';
import { getStrippedUserId } from '../../services/Utilities';

const CONTACT_DATA_FRAGMENT = gql`
  fragment ContactName on Contact {
    _contactId
    name
  }
`;

const CLIENT_GET_CONTACTS = gql`
  query clientGetContact($userId: MongoID!, $searchQuery: String) {
    client(_id: $userId) {
      _id
      organizationDetails {
        _id
        contacts(filter: { name: $searchQuery }) {
          ...ContactName
        }
      }
    }
  }

  ${CONTACT_DATA_FRAGMENT}
`;

const FIRM_GET_CONTACTS = gql`
  query getFirmContacts($userId: String!, $searchQuery: String) {
    firmUser(id: $userId) {
      firms {
        clients {
          _id
          name
          contacts(filter: { name: $searchQuery }) {
            ...ContactName
          }
        }
      }
    }
  }

  ${CONTACT_DATA_FRAGMENT}
`;

const FIRM_GET_CLIENT_CONTACTS = gql`
  query getFirmContactsForClient(
    $userId: String!
    $clientId: MongoID!
    $searchQuery: String
  ) {
    firmUser(id: $userId) {
      firms {
        client(org: $clientId) {
          _id
          name
          contacts(filter: { name: $searchQuery }) {
            ...ContactName
          }
        }
      }
    }
  }

  ${CONTACT_DATA_FRAGMENT}
`;

export default () => {
  const userId = getStrippedUserId();
  const [user] = useUser();

  const [getClientContacts] = useLazyQuery(CLIENT_GET_CONTACTS, {
    variables: {
      userId,
    },
  });

  const [getFirmContacts] = useLazyQuery(FIRM_GET_CONTACTS, {
    variables: {
      userId,
    },
  });

  const [getFirmContactsForClient] = useLazyQuery(FIRM_GET_CLIENT_CONTACTS, {
    variables: {
      userId,
    },
  });

  useEffect(() => {
    if (user.userType === 'client') {
      window.CommandBar.addRecords('contacts', [], {
        onInputChange: async (searchQuery) => {
          const { data } = await getClientContacts({
            variables: { searchQuery },
          });
          return data?.client?.organizationDetails?.[0]?.contacts.map(
            ({ _contactId: id, name: label }) => ({
              id,
              label,
              url: `/client/contacts/${id}`,
            }),
          );
        },
      });
    }
    if (user.userType === 'firm') {
      window.CommandBar.addRecords('contacts', [], {
        onInputChange: async (searchQuery) => {
          const { clientId: clientContext } = window.CommandBar.shareContext();
          if (clientContext) {
            const { data } = await getFirmContactsForClient({
              variables: { searchQuery, clientId: clientContext },
            });
            const clientName = data?.firmUser?.firms?.[0]?.client?.name;
            return data?.firmUser?.firms?.[0]?.client?.contacts.map(
              ({ _contactId: contactId, name: contactName }) => ({
                id: contactId,
                label: contactName,
                description: clientName,
                url: `/clients/${clientContext}/contacts/${contactId}`,
              }),
            );
          }
          const { data } = await getFirmContacts({
            variables: { searchQuery },
          });
          return data?.firmUser?.firms?.[0]?.clients?.flatMap(
            ({ _id: clientId, name: clientName, contacts }) =>
              contacts.map(({ _contactId: contactId, name: contactName }) => ({
                id: contactId,
                label: contactName,
                description: clientName,
                url: `/clients/${clientId}/contacts/${contactId}`,
              })),
          );
        },
      });
    }
  }, [
    getClientContacts,
    getFirmContacts,
    getFirmContactsForClient,
    user.userType,
  ]);

  return null;
};
