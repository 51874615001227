import { gql, useMutation } from '@apollo/client';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { ThemeContext } from 'styled-components/macro';
import { handleException } from 'utils/ErrorUtils';

import { useOrganizationPermission } from '../../../context/OrganizationPermissionContext';
import Badge from '../../standard/Badge';
import Modal from '../../standard/Modal';
import { SetPermissionsModal } from './SetPermissionsModal';
import { TeamMember } from './TeamMember';

const REVOKE_CLIENT_TEAM_MEMBER_INVITATION = gql`
  mutation revokeClientTeamMemberInvitation(
    $input: RevokeOrganizationUserInvitationInput!
  ) {
    revokeOrganizationUserInvitation(input: $input) {
      recordId
    }
  }
`;

const RESEND_CLIENT_TEAM_MEMBER_INVITATION = gql`
  mutation resendClientTeamMemberInvitation(
    $input: ResendOrganizationUserInvitationInput!
  ) {
    resendOrganizationUserInvitation(input: $input) {
      recordId
      record {
        _id
        expiresAt
        state
      }
    }
  }
`;

const REMOVE_CLIENT_USER = gql`
  mutation removeClientUser($input: RemoveUserFromOrganizationInput!) {
    removeUserFromOrganization(input: $input) {
      record {
        users
        admins
      }
    }
  }
`;

const EDIT_PERMISSION_CLIENT_USER = gql`
  mutation UpdateOrganizationPermission(
    $input: updateOrganizationPermissionInput!
  ) {
    updateOrganizationPermission(input: $input) {
      record {
        _id
        canAccessAllMatters
        canAccessMatterCategories
        canCreateNewMatters
        canModifyTemplates
        canAccessInformationTab
        canAccessFilesRepository
        canManageTeamMembers
        canManageBilling
        canManagePermissions
      }
    }
  }
`;

const ClientTeamMember = ({
  clientUserId,
  invitationId,
  email,
  clientId,
  clientName,
  expiresAt,
  setIsLoading,
  refetch,
}) => {
  const userId = localStorage.getItem('userId')?.replace(/auth0\|/i, '');
  const history = useHistory();
  const { palette } = useContext(ThemeContext);

  const { canManagePermissions, canManageTeamMembers } =
    useOrganizationPermission();

  const [showPermissionsModal, setShowPermissionsModal] = useState(false);
  const [invitationIdToRevoke, setInvitationIdToRevoke] = useState(undefined);
  const [clientUserEmailToRemove, setClientUserEmailToRemove] =
    useState(undefined);
  const [showRemoveSelfConfirmationModal, setShowRemoveSelfConfirmationModal] =
    useState(false);
  const [showRemoveUserConfirmationModal, setShowRemoveUserConfirmationModal] =
    useState(false);
  const [
    showRevokeInvitationConfirmationModal,
    setShowRevokeInvitationConfirmationModal,
  ] = useState(false);

  const [revokeClientTeamMemberInvitation] = useMutation(
    REVOKE_CLIENT_TEAM_MEMBER_INVITATION,
  );
  const [resendClientTeamMemberInvitation] = useMutation(
    RESEND_CLIENT_TEAM_MEMBER_INVITATION,
  );
  const [removeClientUser] = useMutation(REMOVE_CLIENT_USER);

  const removeClientUserCallback = useCallback(
    async (onCompleteCallback = () => {}) => {
      setIsLoading(true);
      try {
        await removeClientUser({
          variables: { input: { orgId: clientId, clientUserId } },
        });
        await onCompleteCallback();
      } catch (error) {
        handleException(error);
        console.error(error);
        toast.error(
          'Oops! Something went wrong deleting the user. Please try again later.',
        );
      } finally {
        setIsLoading(false);
      }
    },
    [removeClientUser, clientUserId, clientId, setIsLoading],
  );

  const [updateUserPermissions] = useMutation(EDIT_PERMISSION_CLIENT_USER, {
    onCompleted: () => {
      setShowPermissionsModal(false);
      toast.success('User permissions modified');
    },
    onError: (error) => {
      toast.error('Failed to update user permissions');
      handleException(error);
    },
  });

  const ownPermission = clientUserId?.endsWith(userId);
  const editTooltipId = `edit-${clientUserId}-tooltip`;

  return (
    <>
      <TeamMember
        name={email}
        key={email}
        controls={
          <>
            {!!clientUserId && !!canManagePermissions && (
              <>
                <span
                  data-tip={"You can't edit your own permissions."}
                  data-for={editTooltipId}
                  aria-describedby={editTooltipId}
                >
                  <button
                    type="button"
                    onClick={() => setShowPermissionsModal(true)}
                    className="btn-pure btn-primary"
                    hidden={!canManagePermissions}
                    disabled={ownPermission}
                  >
                    Edit
                  </button>
                </span>
                <ReactTooltip
                  id={editTooltipId}
                  role="tooltip"
                  delayShow={500}
                  type="light"
                  disable={!ownPermission}
                />
              </>
            )}
            {!!clientUserId && !!canManageTeamMembers && (
              <button
                type="button"
                aria-label={`Remove ${email} from list`}
                onClick={async () => {
                  setClientUserEmailToRemove(email);
                  if (clientUserId === userId) {
                    setShowRemoveSelfConfirmationModal(true);
                  } else {
                    setShowRemoveUserConfirmationModal(true);
                  }
                }}
                className="btn-pure btn-danger"
                title={`Remove ${email} from list`}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            )}
            {!!invitationId && !!canManageTeamMembers && (
              <button
                type="button"
                aria-label={`Resend invitation to ${email}`}
                onClick={async () => {
                  setIsLoading(true);
                  try {
                    const { primary, secondary, gray } = palette;
                    await resendClientTeamMemberInvitation({
                      variables: {
                        input: {
                          invitationId,
                          palette: { primary, secondary, gray },
                        },
                      },
                    });
                  } catch (error) {
                    handleException(error);
                    toast.error(
                      'Oops! Something went wrong resending the user invitation. Please try again later.',
                    );
                  } finally {
                    setIsLoading(false);
                    toast.info(`Invitation resent to ${email}`);
                  }
                }}
                className="btn-pure btn-primary"
                title={`Resend invitation to ${email}`}
              >
                Resend
              </button>
            )}
            {!!invitationId && !!canManageTeamMembers && (
              <button
                type="button"
                aria-label={`Revoke invitation to ${email}`}
                onClick={() => {
                  setInvitationIdToRevoke(invitationId);
                  setClientUserEmailToRemove(email);
                  setShowRevokeInvitationConfirmationModal(true);
                }}
                className="btn-pure btn-danger"
                title={`Revoke invitation to ${email}`}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            )}
          </>
        }
        badge={
          invitationId &&
          (new Date() > new Date(expiresAt) ? (
            <Badge badgeStyle="destructive">Expired</Badge>
          ) : (
            <Badge>Invitation Pending</Badge>
          ))
        }
      />
      {showRemoveSelfConfirmationModal && (
        <Modal
          title={`Remove Yourself From ${clientName}?`}
          display={showRemoveSelfConfirmationModal}
          showCancel
          onCancel={() => {
            setShowRemoveSelfConfirmationModal(false);
            setClientUserEmailToRemove(undefined);
          }}
          acceptPaletteType="destructive"
          acceptText="Remove Me"
          onAccept={async () => {
            await removeClientUserCallback(() => history.push('/login'));
          }}
        >
          If you remove yourself as a team member, you will no longer be able to
          access this organization. To regain access, you will need to be
          re-invited by an administrator.
        </Modal>
      )}
      {showRemoveUserConfirmationModal && (
        <Modal
          title={`Remove ${clientUserEmailToRemove} From ${clientName}?`}
          display={showRemoveUserConfirmationModal}
          showCancel
          onCancel={() => setShowRemoveUserConfirmationModal(false)}
          acceptPaletteType="destructive"
          acceptText="Remove Member"
          onAccept={async () =>
            removeClientUserCallback(async () => {
              setClientUserEmailToRemove(undefined);
              setShowRemoveUserConfirmationModal(false);
              return refetch();
            })
          }
        >
          {`Are you sure you want to remove ${clientUserEmailToRemove} from ${clientName}?`}
        </Modal>
      )}
      {showRevokeInvitationConfirmationModal && (
        <Modal
          title={`Revoke Invite to ${clientUserEmailToRemove}?`}
          display={showRevokeInvitationConfirmationModal}
          showCancel
          onCancel={() => setShowRevokeInvitationConfirmationModal(false)}
          acceptPaletteType="destructive"
          acceptText="Revoke Invitation"
          onAccept={async () => {
            setIsLoading(true);
            try {
              await revokeClientTeamMemberInvitation({
                variables: { input: { invitationId: invitationIdToRevoke } },
              });
              await refetch();
            } catch (error) {
              handleException(error);
              toast.error(
                'Oops! Something went wrong revoking the user invitation. Please try again later.',
              );
            } finally {
              setIsLoading(false);
              setInvitationIdToRevoke(undefined);
              setClientUserEmailToRemove(undefined);
              setShowRevokeInvitationConfirmationModal(false);
            }
          }}
        >
          {`Are you sure you want to revoke the invitation to ${clientUserEmailToRemove}?`}
        </Modal>
      )}
      {showPermissionsModal && (
        <SetPermissionsModal
          open={showPermissionsModal}
          email={email}
          clientId={clientId}
          clientUserId={clientUserId}
          onClose={() => setShowPermissionsModal(false)}
          onSubmit={({
            permissionId,
            canManageTeamMembersPermissionsAndBilling,
            ...permissions
          }) => {
            updateUserPermissions({
              variables: {
                input: {
                  permissionId,
                  permissions: {
                    ...permissions,
                  },
                },
              },
            });
          }}
        />
      )}
    </>
  );
};

export default ClientTeamMember;
