import MuiRadio from '@material-ui/core/Radio';
import MuiRadioGroup from '@material-ui/core/RadioGroup';
import React, { memo } from 'react';
import styled from 'styled-components/macro';

import FormControlLabel from './FormControlLabel';

export const Radio = memo(
  styled(({ color = 'primary', ...props }) => (
    <MuiRadio color={color} {...props} />
  ))``,
);

export const RadioGroup = MuiRadioGroup;

export const LabelledRadio = ({ value, label, ...props }) => (
  <FormControlLabel
    value={value}
    control={<Radio {...props} />}
    label={label}
  />
);
