import { gql, useMutation, useQuery } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { handleException } from 'utils/ErrorUtils';

import { stripTypenames, userIsClient } from '../../../services/Utilities';
import { ToasterLink } from '../../standard/Toaster';
import { FULL_CONTACT_DATA } from './queries';

export const CLIENT_GET_CONTACT = gql`
  query clientGetContact(
    $userId: MongoID!
    $contactId: MongoID!
    $fetchMatters: Boolean!
  ) {
    client(_id: $userId) {
      _id
      organizationDetails {
        _id
        contact(contact: $contactId) {
          ...FullContactData
        }
      }
    }
  }

  ${FULL_CONTACT_DATA}
`;

export const FIRM_GET_CONTACT = gql`
  query firmGetContact(
    $userId: String!
    $firmId: MongoID!
    $clientId: MongoID!
    $contactId: MongoID!
    $fetchMatters: Boolean!
  ) {
    firmUser(id: $userId) {
      id
      firm(id: $firmId) {
        _id
        client(org: $clientId) {
          _id
          contact(contact: $contactId) {
            ...FullContactData
          }
        }
      }
    }
  }

  ${FULL_CONTACT_DATA}
`;

const CREATE_CONTACT = gql`
  mutation createContact(
    $organization: MongoID!
    $contact: CreateContactInput!
    $fetchMatters: Boolean!
  ) {
    createContact(organization: $organization, contact: $contact) {
      record {
        ...FullContactData
      }
    }
  }

  ${FULL_CONTACT_DATA}
`;

const UPDATE_CONTACT = gql`
  mutation updateContact(
    $contactId: MongoID!
    $contact: UpdateContactInput!
    $fetchMatters: Boolean!
  ) {
    updateContact(contactId: $contactId, contact: $contact) {
      record {
        ...FullContactData
      }
    }
  }

  ${FULL_CONTACT_DATA}
`;

export function useContact({
  contactId,
  clientId,
  onCompleted = () => {},
  fetchMatters = false,
}) {
  const userId = localStorage.getItem('userId');
  const firmId = localStorage.getItem('firmId');

  const [contact, setContact] = useState();

  const queryResult = useQuery(
    userIsClient() ? CLIENT_GET_CONTACT : FIRM_GET_CONTACT,
    {
      fetchPolicy: 'cache-and-network',
      skip: !contactId || !(clientId || userIsClient()),
      variables: userIsClient()
        ? {
            userId: userId.replace(/^.*\|/, ''),
            contactId,
            fetchMatters,
          }
        : { userId, firmId, clientId, contactId, fetchMatters },
      onCompleted: (data) => {
        const organizationDetails = userIsClient()
          ? data.client.organizationDetails[0]
          : data.firmUser.firm.client;
        const newContact = stripTypenames(organizationDetails.contact);
        setContact(newContact);
        onCompleted(newContact);
      },
    },
  );

  return [contact, setContact, queryResult];
}

export function useCreateContact({ onCompleted = () => {} }) {
  const [createContact, mutationResult] = useMutation(CREATE_CONTACT, {
    variables: { fetchMatters: false },
  });

  const createContactCallback = useCallback(
    async (organization, contactData) => {
      try {
        const { data } = await createContact({
          variables: { organization, contact: contactData },
        });
        const newContact = stripTypenames(data.createContact.record);
        const pathname = userIsClient()
          ? `/client/contacts/${newContact._contactId}`
          : `/clients/${organization}/contacts/${newContact._contactId}`;
        toast.success(
          <div>
            <ToasterLink
              to={pathname}
              target="_blank"
              rel="noopener noreferrer"
            >
              {newContact.name}
            </ToasterLink>
            {` has been added to your contact list.`}
          </div>,
        );
        onCompleted(newContact);
        return newContact;
      } catch (error) {
        handleException(error);
        toast.error(
          'Oops! There was an error adding this contact. Please wait a few seconds and try again.',
        );
        return contactData;
      }
    },
    [createContact, onCompleted],
  );

  return [createContactCallback, mutationResult];
}

export function useUpdateContact({
  onCompleted = () => {},
  fetchMatters = false,
}) {
  const [updateContact, mutationResult] = useMutation(UPDATE_CONTACT, {
    variables: { fetchMatters },
  });

  const updateContactCallback = useCallback(
    async (contactId, contactData) => {
      try {
        const { _contactId, matters, ...contactFields } = contactData;
        const { data } = await updateContact({
          variables: { contactId, contact: contactFields },
        });
        const updatedContact = stripTypenames(data.updateContact.record);
        toast.success('Contact information has been saved.');
        onCompleted(updatedContact);
        return updatedContact;
      } catch (error) {
        handleException(error);
        toast.error(
          'Oops! There was an error updating this contact. Please wait a few seconds and try again.',
        );
        return contactData;
      }
    },
    [updateContact, onCompleted],
  );

  return [updateContactCallback, mutationResult];
}

export function useVisibleContact({ contact, fieldConfig }) {
  const [visibleContact, setVisibleContact] = useState();

  useEffect(() => {
    if (!contact || !fieldConfig || !Object.values(fieldConfig).length) {
      setVisibleContact(contact);
      return;
    }
    const visibleFields = [
      ['name', 'type', '_contactId'],
      Object.entries(fieldConfig)
        .filter(([, value]) => value?.visible)
        .map(([key]) => key),
    ].flat();
    setVisibleContact(
      Object.fromEntries(visibleFields.map((key) => [key, contact[key]])),
    );
  }, [contact, fieldConfig]);

  return visibleContact;
}
