import React, { useEffect } from 'react';

import AuthUtility from '../services/AuthUtility';
import Spinner from './standard/Spinner';

const Logout = () => {
  useEffect(() => {
    AuthUtility.logout();
  }, []);
  return <Spinner />;
};

export default Logout;
